import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import {
  capitalizeFirstLetterOfEachWord,
  extractYearFromDate,
} from "../../services/email_utils";
import { Icon } from "@iconify/react/dist/iconify.js";
import Swal from "sweetalert2";
import moment from "moment";

const TonnageTemplate = () => {
  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddTonnageModalOpen, setIsAddTonnageModalOpen] = useState(false);
  const pageSize = 6;
  const [formData, setFormData] = useState({
    to: "",
    templateName: "",
    from: "",
    subject: "",
    id: "",
    imo_no: "",
    // templatename: '',
    openFromDate: "",
    openToDate: new Date(),
    openPosition: "",
    bunkerOnDelivery: "",
    loa: null,
    beam: "",
    hatches: null,
    speed: null,
    cons: "",
    vesselName: "",
    dwt: null,
    flag: "",
    builtYear: "",
    otherVesselDetails: "",
  });
  const [errors, setErrors] = useState({});

  function formatDateColumn(dateString) {
    // Split the input string to get the start and end dates
    const [startDateString, endDateString] = dateString?.split("-");

    // Function to format a single date string
    const formatSingleDate = (dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Format the start and end dates
    const formattedStartDate = formatSingleDate(startDateString);
    const formattedEndDate = formatSingleDate(endDateString);

    // Combine the formatted dates into a single string
    return `${formattedStartDate}/${formattedEndDate}`;
  }
  const columns = [
    {
      title: "Id",
      sortable: true,
      render: (text, row, index) => (
        <div className="text-center">
          {(currentPage - 1) * pageSize + index + 1}
        </div>
      ),
      width: "3rem", //RDT provides index by default,
      alignItems: "center",
      fixed: "left",
    },

    {
      label: "templateName",
      title: "Template Name",
      fixed: "left",
      selector: (row) => row.templateName,
      sortable: true,
      reorder: true,
      // width: '8rem',
      render: (row) => (
        <div
          className="overflowText"
          style={{ padding: "10px", width: "10rem" }}
        >
          {row.templateName}
        </div>
      ),
    },

    {
      label: "imo",
      title: "IMO",
      selector: (row) => row.imo,
      sortable: true,
      reorder: true,

      render: (row) => (
        <div style={{ padding: "10px", width: "7rem" }}>{row.imo}</div>
      ),
    },
    {
      label: "openFromDate",
      title: "Open Date",
      // selector: (row: { openFromDate }) => row.openFromDate,
      sortable: true,
      reorder: true,
      width: "10rem",

      render: (row) => (
        <Tooltip
          title={formatDateColumn(row.openFromDate)}
          arrow
          placement="top"
        >
          <div className="overflowText2">
            {" "}
            {formatDateColumn(row.openFromDate)}{" "}
          </div>
        </Tooltip>
      ),
    },

    {
      label: "openPosition",
      title: "Open Area",
      selector: (row) => row.openPosition,
      sortable: true,
      reorder: true,

      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {capitalizeFirstLetterOfEachWord(row.openPosition)}
        </div>
      ),
    },
    {
      label: "bunkerOnDelivery",
      title: "Bunker On Delivery",
      selector: (row) => row.bunkerOnDelivery,
      sortable: true,
      reorder: true,

      render: (row) => (
        <Tooltip title={row.bunkerOnDelivery} arrow placement="top">
          <div className="overflowText2">{row.bunkerOnDelivery}</div>
        </Tooltip>
      ),
    },
    {
      label: "loa",
      title: "LOA",
      selector: (row) => row.loa,
      sortable: true,
      reorder: true,

      render: (row) => <div style={{ padding: "10px" }}>{row.loa}</div>,
    },
    {
      label: "beam",
      title: "Beam",
      selector: (row) => row.beam,
      sortable: true,
      reorder: true,

      render: (row) => <div style={{ padding: "10px" }}>{row.beam}</div>,
    },
    {
      label: "hatches",
      title: "Hatches",
      selector: (row) => row.hatches,
      sortable: true,
      reorder: true,

      render: (row) => <div style={{ padding: "10px" }}>{row.hatches}</div>,
    },
    {
      label: "speed",
      title: "Speed",
      selector: (row) => row.speed,
      sortable: true,
      reorder: true,

      render: (row) => <div style={{ padding: "10px" }}>{row.speed}</div>,
    },
    {
      label: "cons",
      title: "Cons",
      selector: (row) => row.cons,
      sortable: true,
      reorder: true,

      render: (row) => <div style={{ padding: "10px" }}>{row.cons}</div>,
    },
    {
      title: "Vessel Name",
      selector: (row) => row.vesselName,
      label: "vesselName",
      reorder: true,
      width: "9rem",
      sortable: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.vesselName}</div>,
    },
    {
      title: "DWT",
      selector: (row) => row.dwt,
      label: "dwt",
      reorder: true,

      sortable: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.dwt}</div>,
    },
    {
      title: "Flag",
      selector: (row) => row.flag,
      label: "flag",
      reorder: true,

      sortable: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.flag}</div>,
    },
    {
      title: "Built Year",
      selector: (row) => row.builtYear,
      label: "builtYear",
      reorder: true,

      sortable: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>
          {extractYearFromDate(row.builtYear)}
        </div>
      ),
    },
    {
      title: "Other Vessel Details",
      selector: (row) => row.otherVesselDetails,
      label: "otherVesselDetails",
      // center: true,
      reorder: true,

      sortable: true,
      render: (row) => (
        <Tooltip title={row.otherVesselDetails} arrow placement="top">
          <div className="overflowText2" style={{ padding: "10px" }}>
            {row.otherVesselDetails}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      title: "Actions",
      label: "Actions",
      reorder: true,
      fixed: "right",
      render: (row) => (
        // <Button onClick={() => handleRowDelete(row)}></Button>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <div
              onClick={() => handleRowDelete(row)}
              style={{ padding: "4px", cursor: "pointer" }}
              className="iconButton-bg"
            >
              <Icon
                icon="tabler:trash"
                className="bg-icon-color"
                fontSize={16}
              />
            </div>

            <div
              onClick={() => {
                handleEditData(row)
              }}
              style={{ padding: "4px", cursor: "pointer" }}
              className="iconButton-bg"
            >
              <Icon
                icon="tabler:edit"
                className="bg-icon-color"
                fontSize={16}
              />
            </div>

            {/* <div
              onClick={() => {
                handleEditData(row)
              }}
              style={{ padding: '4px' }}
              className='iconButton-bg'
            >
              <Icon icon='tabler:eye' className='bg-icon-color' fontSize={16} />
            </div> */}
          </div>
        </>
      ),
      width: "6rem",
    },
  ];

  useEffect(() => {
    getAllTonnageTemplates();
  }, []);

  const getAllTonnageTemplates = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/tonnages`;
      const response = await getAPICall(url);
      //   if (response) {
      //     dispatch(setSpinner(false));
      //   }
      if (response.success) {
        const formattedData = response?.data.map((tonnagedata) => ({
          id: tonnagedata.id,
          templateName: tonnagedata.templatename,
          imo_no: tonnagedata.imo_no,
          openFromDate: tonnagedata.open_from,
          openToDate: tonnagedata.open_to,
          openPosition: tonnagedata.open_position,
          bunkerOnDelivery: tonnagedata.bunker_on_delivery,
          loa: tonnagedata.loa,
          beam: tonnagedata.beam,
          hatches: tonnagedata.hatches,
          speed: tonnagedata.speed,
          cons: String(tonnagedata.cons),
          vesselName: tonnagedata.vessel_name,
          dwt: tonnagedata.dwt,
          flag: tonnagedata.flag,
          imo: tonnagedata.imo_no,
          builtYear: tonnagedata.built_year,
          otherVesselDetails: tonnagedata.vessel_other_details,
        }));
        setFormattedData(formattedData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const [isEditDataClicked, setIsEditDataClicked] = useState("save");

  const handleDateChange = (dates, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");
      const dateRangeString = `${formattedStartDate} - ${formattedEndDate}`;
      //   setDateRange(dateRangeString)
      handleChange({
        target: { name: "openFromDate", value: dateRangeString },
      });
    }
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      handleChange({
        target: { name: "openFromDate", value: dateRangeString },
      });
    }
  };
  const handleSingleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(new Date(date)).format("YYYY/MM/DD");
      handleChange({ target: { name: "builtYear", value: formattedDate } });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.templateName)
      newErrors.templateName = "Template name is required";
    if (!formData.openPosition)
      newErrors.openPosition = "Open Position is required";

    return newErrors;
  };

  const handleSubmitData = async () => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/tonnages`;
        const payload = {
          send_to: "",
          send_from: "",
          subject: "",
          imo_no: formData.imo_no,
          templatename: formData.templateName,
          draft: "",
          open_position: formData.openPosition,
          loa: formData.loa,
          beam: formData.beam,
          hatches: formData.hatches,
          open_from: formData.openFromDate,
          open_to: formData.openToDate,
          vessel_name: formData.vesselName,
          dwt: formData.dwt,
          flag: formData.flag,
          built_year: formData.builtYear,
          cons: formData.cons,
          speed: formData.speed,
          bunker_on_delivery: formData.bunkerOnDelivery,
          vessel_other_details: formData.otherVesselDetails,
        };
        const response = await postAPICall(url, payload, "post");
        if (response?.success) {
          openNotificationWithIcon('success',"Template created successfully.")
          setIsAddTonnageModalOpen(false);
          getAllTonnageTemplates();
          setFormData({});
        }
        // postTonageOrder();
        // setErrors(validationErrors);
        // setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async() => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/tonnage/${formData?.id}`;
        const payload = {
          send_to: "",
          send_from: "",
          subject: "",
          imo_no: formData.imo_no,
          templatename: formData.templateName,
          draft: "",
          open_position: formData.openPosition,
          loa: formData.loa,
          beam: formData.beam,
          hatches: formData.hatches,
          open_from: formData.openFromDate,
          open_to: formData.openToDate,
          vessel_name: formData.vesselName,
          dwt: formData.dwt,
          flag: formData.flag,
          built_year: formData.builtYear,
          cons: formData.cons,
          speed: formData.speed,
          bunker_on_delivery: formData.bunkerOnDelivery,
          vessel_other_details: formData.otherVesselDetails,
        };
        const response = await postAPICall(url, payload, "put");
        if (response.status=="1") {
          openNotificationWithIcon('success',"Template updated successfully.")
          setIsAddTonnageModalOpen(false);
          getAllTonnageTemplates();
          setFormData({});
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDelete = (rowIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this template!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/tonnage/${rowIndex?.id}`;
        const response = await postAPICall(url, {}, "delete");
        if (response.status === "1") {
          openNotificationWithIcon("success", "Template deleted successfully.");
          getAllTonnageTemplates();
        }
      }
    });
  };

  const handleEditData = (row) =>{
    setFormData({
      to: '',
      from: '',
      subject: '',
      id: row?.id,
      imo_no: row?.imo_no,
      templateName: row?.templateName,
      openFromDate: row?.openFromDate,
      openToDate: new Date(row?.openToDate),
      openPosition: row?.openPosition,
      bunkerOnDelivery: row?.bunkerOnDelivery,
      loa: row?.loa,
      beam: row?.beam,
      hatches: parseInt(row?.hatches),
      speed: parseInt(row?.speed),
      cons: String(row?.cons),
      vesselName: row?.vesselName,
      dwt: parseInt(row?.dwt),
      flag: row?.flag,
      builtYear: row?.builtYear,
      otherVesselDetails: row?.otherVesselDetails
    })
    setIsEditDataClicked("edit")
    setIsAddTonnageModalOpen(true);
  }

  const handleClose = () => {};
  

  return (
    <>
      <div>
        <div style={{ textAlign: "end", marginBottom: "10px" }}>
          <Button onClick={() => {
            setFormData({})
            setIsEditDataClicked(false)
            setIsAddTonnageModalOpen(true)
            }}>
            <Icon
              icon="ic:baseline-plus"
              className="bg-icon-color"
              fontSize={20}
            />
            <span>Add New </span>
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={formattedData}
          pagination={{ pageSize }}
          scroll={{ x: "max-content" }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Tonage Template Form"
        footer={false}
        open={isAddTonnageModalOpen}
        onCancel={() => setIsAddTonnageModalOpen(false)}
      >
        <div style={{ width: "100%" }}>
          <Row gutter={16} style={{ rowGap: "10px" }}>
            <Col span={10}>
              <Typography.Text>
                Template Name:
                <span
                  style={{
                    color: "red",
                    verticalAlign: "sub",
                    padding: "10px",
                  }}
                >
                  *
                </span>
              </Typography.Text>
              {/* {errors.templateName && <p style={errorStyle}>{errors.templateName}</p>} */}
            </Col>
            <Col span={14}>
              <Input
                name="templateName"
                fullWidth
                value={formData.templateName}
                onChange={handleChange}
                placeholder="Enter Template Name"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>
                Open Date:
                <span
                  style={{
                    color: "red",
                    verticalAlign: "sub",
                    padding: "10px",
                  }}
                >
                  *
                </span>
              </Typography.Text>
            </Col>

            {isEditDataClicked === "edit" ? (
              <Col span={14}>
                <Popover
                  content={
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      onChange={handleDateChange}
                    />
                  }
                  trigger="click"
                  placement="bottomRight"
                >
                  <Input
                    value={formData.openFromDate}
                    placeholder="open Date"
                    onChange={handleChange}
                    suffix={<CalendarOutlined />}
                  />
                </Popover>
              </Col>
            ) : (
              <Col span={14}>
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  onChange={(event, dateString) =>
                    onChange(event, "OpenFrom/To", dateString)
                  }
                />
              </Col>
            )}

            <Col span={10}>
              <Typography.Text>
                Open Area:
                <span
                  style={{
                    color: "red",
                    verticalAlign: "sub",
                    padding: "10px",
                  }}
                >
                  *
                </span>
              </Typography.Text>
              {/* {errors.openPosition && <p style={errorStyle}>{errors.openPosition}</p>} */}
            </Col>
            <Col span={14}>
              <Input
                name="openPosition"
                fullWidth
                value={formData.openPosition}
                onChange={handleChange}
                placeholder="Enter open position"
              />
            </Col>
            <Col span={10}>
              <Typography.Text>Bunker On Delivery:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="bunkerOnDelivery"
                fullWidth
                required
                value={formData.bunkerOnDelivery}
                onChange={handleChange}
                placeholder="Enter bunker on Delivery"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>LOA:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="loa"
                fullWidth
                value={formData.loa}
                onChange={handleChange}
                type="number"
                placeholder="0.0"
                addonAfter="M"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Beam:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="beam"
                fullWidth
                value={formData.beam}
                onChange={handleChange}
                placeholder="0.0"
                addonAfter="M"
                type="number"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Hatches:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="hatches"
                fullWidth
                value={formData.hatches}
                onChange={handleChange}
                type="number"
                placeholder="0.0"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Speed:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="speed"
                fullWidth
                value={formData.speed}
                onChange={handleChange}
                type="number"
                placeholder="0.0"
                addonAfter="KT"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Cons:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="cons"
                fullWidth
                value={formData.cons}
                onChange={handleChange}
                placeholder="0.0"
                addonAfter="MT"
                type="number"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>IMO:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="imo_no"
                fullWidth
                required
                value={formData.imo_no}
                onChange={handleChange}
                placeholder="Enter IMO number"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Vessel Name:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="vesselName"
                fullWidth
                value={formData.vesselName}
                onChange={handleChange}
                placeholder="Enter Vessel Name"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>DWT:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="dwt"
                fullWidth
                value={formData.dwt}
                onChange={handleChange}
                type="number"
                placeholder="0.0"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Flag:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="flag"
                fullWidth
                value={formData.flag}
                onChange={handleChange}
                placeholder="Enter Flag"
              />
            </Col>

            <Col span={10}>
              <Typography.Text>Built Year:</Typography.Text>
            </Col>
            {isEditDataClicked === "edit" ? (
              <Col span={14}>
                <Popover
                  content={
                    <DatePicker
                      style={{ width: "100%" }}
                      onChange={handleSingleDateChange}
                      picker="year"
                    />
                  }
                  trigger="click"
                  placement="bottomRight"
                >
                  <Input
                    value={formData.builtYear}
                    name="builtYear"
                    onChange={handleChange}
                    suffix={<CalendarOutlined />}
                  />
                </Popover>
              </Col>
            ) : (
              <Col span={14}>
                <Input
                  value={formData.builtYear}
                  type="date"
                  name="builtYear"
                  onChange={handleChange}
                />
              </Col>
            )}

            <Col span={10}>
              <Typography.Text>Other Vessel Details:</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                name="otherVesselDetails"
                fullWidth
                value={formData.otherVesselDetails}
                onChange={handleChange}
                placeholder="Enter Other Vessel Details"
              />
            </Col>
          </Row>

          <div style={{ textAlign: "right", marginTop: "16px" }}>
            {isEditDataClicked === "edit" ? (
              <Button type="primary" onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmitData}>
                Save
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TonnageTemplate;
