import { DeleteOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { postAPICall } from "../../../shared";
import moment from "moment";
import { formatTimestamp } from "../constants";
import { Icon } from "@iconify/react/dist/iconify.js";

const EmailComments = ({ currentMail }) => {
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fetchComment = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/UpdateComments`;
      const payload = {
        current_mail: currentMail?.client,
        _id: currentMail?._id,
      };
      setIsLoading(true);
      const response = await postAPICall(url, payload, "put");
      if (response?.msg && response?.data !== null) {
        setCommentList(response?.data);
      } else {
        setCommentList([]);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteComment = async (comment) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/UpdateComments`;
      const payload = {
        _id: currentMail?._id,
        uuid: comment?.uuid,
      };
      const response = await postAPICall(url, payload, "delete");
      if (response?.msg && response?.data !== null) {
        message.success(response?.msg);
        fetchComment();
      } else {
        message?.error(response?.msg);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchComment();
  }, [currentMail]);

  const handleAddComment = async () => {
    if (comment?.trim()) {
      try {
        const url = `${process.env.REACT_APP_MT_URL}mail/UpdateComments`;
        const payload = {
          _id: currentMail?._id,
          comment,
          current_mail: currentMail?.client,
        };
        const response = await postAPICall(url, payload, "post");
        if (response?.status && response?.data !== null) {
          message.success("Comment added successfully");
          setComment("");
          fetchComment();
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  return (
    <div style={{ width: 300 }}>
      <div style={{ marginBottom: 8 }}>
        <strong>Add Comment</strong>
      </div>
      <div
        style={{ minHeight: "150px", maxHeight: "200px", overflowY: "auto" }}
      >
        {isLoading
          ? "Loading..."
          : commentList?.map((comment) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Avatar style={{ marginRight: "12px" }}>
                  <Icon className="current_mail_icons" icon={"ph:user"} />
                </Avatar>
                <div style={{ flex: 1 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {comment?.user_name}
                    </span>
                    <span style={{ color: "gray", fontSize: "12px" }}>
                      {formatTimestamp(comment?.time)}
                    </span>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{comment?.msg}</div>
                    <DeleteOutlined
                      style={{
                        marginLeft: "auto",
                        marginRight: "10px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => handleDeleteComment(comment)}
                    />
                  </div>
                </div>
                {/* <Button
            type="link"
            icon={<DeleteOutlined />}
            style={{ color: "red", marginLeft: "12px" }}
          /> */}
              </div>
            ))}
      </div>
      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <input
          type="text"
          placeholder="Write comment"
          style={{ width: "100%", padding: "5px 40px 5px 5px" }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          style={{ position: "absolute", right: "0" }}
          onClick={handleAddComment}
        ></Button>
      </div>
    </div>
  );
};

export default EmailComments;
