import { Button, Checkbox, Dropdown, Menu } from "antd";
import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import URL_WITH_VERSION, { getAPICall } from "../../shared";
import "../../styles/mapstyle.css";
import "../../styles/toolbar.css";
import { CustomSpinner } from "./portUtil";

const ToolBar = ({
  isPortDistModal,
  setIsPortDistModal,
  map,
  openGlobalSearch,
  distTable,
  toggleDrawer,
  removeMarkersFromMap,
  addMarkersToMap,
  showTrackFleet,
  scrollFleetTable,
  ports,
  isbunkerPrice,
  bunkerPrice,
}) => {
  const [isPortNameVisible, setPortNameVisible] = useState(false);
  // const [ports, setports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMarkShow, setIsMarkShow] = useState(false);

  // useEffect(() => {
  //   getportdata();
  // }, []);

  // const getportdata = async () => {

  //   try {
  //     const data = await getAPICall(
  //       `${process.env.REACT_APP_ATTACHMENT}/port-bunker-activity/spotprice`
  //     );
  //     const respdata = await data;

  //     let allPorts = {};
  //     for (let item of respdata.BunkerExSpotPrices) {
  //       allPorts[item.Port] = {
  //         portname: item.Port,
  //         fuelType: "",
  //         fuelprice: "",
  //         portCoordinates: [item.Longitude, item.Latitude],
  //       };
  //     }

  //     for (let item of respdata.BunkerExSpotPrices) {
  //       if (allPorts[item.Port]) {
  //         allPorts[item.Port].fuelType += item.Grade + " ";
  //         allPorts[item.Port].fuelprice += item.Price + " ";
  //       }
  //     }

  //     let PortNameAndCoordinateArr = [];
  //     for (let key in allPorts) {
  //       PortNameAndCoordinateArr.push(allPorts[key]);
  //     }
  //     setports(PortNameAndCoordinateArr);
  //     setLoading(false)
  //   } catch (err) {
  //     console.log("something went wrong", err);
  //   }
  // };

  const showBunkerPrice = (PortData) => {
    if (!map.current) return;

    const bunkerPriceSource = map.current.getSource("bunkerPrice");
    if (!bunkerPriceSource) {
      map.current.addSource("bunkerPrice", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: PortData.map((point, index) => {
            let fuelType = point.fuelType
              .trimEnd()
              .split(" ")
              .map((type) => `<span class='fuel'>${type}</span>`)
              .join("");
            // let fuelTypeJsx = `<div>${FuelType}</span>`
            let fuelPrice = point.fuelprice
              .trimEnd()
              .split(" ")
              .map((price) => `<span class='price'>${price}</span>`)
              .join("");
            // let fuelPriceJsx = `<span>${fuelPrice}</span>`
            // console.log(fuelType);
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: point.portCoordinates,
              },
              properties: {
                id: index,
                description: `<div class = 'bunker-price'>
                  <h5 class='port-name'> <b> Port Name </b>   <span>${point.portname}</span></h5>
                  
                  <h5 class='fuel-type'> <b>Fuel</b>   ${fuelType}</h5>
                  <h5 class="fuel-price"> <b>Price</b>  ${fuelPrice}</h5>
                </div>`,
              },
              popupHoverStyle: {
                backgroundColor: "#f0f0f0",
                border: "1px solid #ccc",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              },
            };
          }),
        },
      });
    }

    map.current.addLayer({
      id: "bunkerPrice",
      type: "symbol",
      source: "bunkerPrice",
      layout: {
        "icon-image": "orangeIcon", // You can customize the marker icon
        "text-field": ["get", "name"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 0.6],
        "text-anchor": "top",
        "text-size": 8,
      },
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.current.on("mouseenter", "bunkerPrice", (e) => {
      map.current.getCanvas().style.cursor = "pointer";
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
    });

    map.current.on("mouseleave", "bunkerPrice", () => {
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    });
    setPortNameVisible(true);
  };

  const hideBunkerPrice = () => {
    map.current.removeLayer("bunkerPrice");
    if (!map.current.getSource("bunkerPrice")) return;
    map.current.removeSource("bunkerPrice");
    setPortNameVisible(false);
  };

  const toggleBunkerPrice = (e) => {
    e.stopPropagation();
    if (map?.current) {
      if (isPortNameVisible) {
        hideBunkerPrice();
        addMarkersToMap();
        setIsMarkShow(false);
      } else {
        setIsMarkShow(true);
        showBunkerPrice(ports);
        removeMarkersFromMap();
      }
    }
  };

  useEffect(() => {
    if (
      bunkerPrice?.BunkerExFuturePrices?.length > 0 &&
      isbunkerPrice &&
      isMarkShow
    ) {
      if (map?.current) {
        hideBunkerPrice();
        addMarkersToMap();
        showBunkerPrice(ports);
        removeMarkersFromMap();
      }
    }
  }, [isbunkerPrice, bunkerPrice]);

  const openBunkerPrice = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {loading === false ? (
        <ul className="toolbar">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      openGlobalSearch();
                    }}
                  >
                    Global Search
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    onClick={() => {
                      showTrackFleet();
                      scrollFleetTable();
                    }}
                  >
                    My Fleet For Tracking
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <li>Global Ships Search</li>
          </Dropdown>
          <li onClick={() => setIsPortDistModal(!isPortDistModal)}>
            Port Distance
          </li>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Checkbox onChange={toggleBunkerPrice}>Bunker Price</Checkbox>
                </Menu.Item>
              </Menu>
            }
          >
            <li className="ant-dropdown-link" onClick={openBunkerPrice}>
              Bunker Price
            </li>
          </Dropdown>
        </ul>
      ) : (
        <CustomSpinner />
      )}
      {!distTable.length ? null : (
        <button onClick={toggleDrawer} className="show-port-distance-btn">
          Show Port to Port details
        </button>
      )}
    </div>
  );
};

export default ToolBar;
