import React from "react";
import { Tabs } from "antd";
import TonnageTemplate from "./TonnageTemplate";
import CargoTemplate from "./CargoTemplate";
import TonnageFirmOffers from "./TonnageFirmOffers";
import CargoFirmOffers from "./CargoFirmOffers";

const TEMPLATE_ARRAY = [
  "Tonnage Template",
  "Cargo Template",
  "Tonnage Firm Offer",
  "Cargo Firm Offer",
];

const COMPONENT_MAP = {
  1: <TonnageTemplate />,
  2: <CargoTemplate />,
  3: <TonnageFirmOffers />,
  4: <CargoFirmOffers />,
};

const EmailTemplate = () => {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div style={{ margin: "20px" }}>
      <Tabs onChange={onChange} type="card">
        {TEMPLATE_ARRAY.map((item, index) => {
          const id = String(index + 1);
          return (
            <Tabs.TabPane
              tab={<div style={{ fontWeight: "bold" }}>{item}</div>}
              key={id}
            >
              {COMPONENT_MAP[id]}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default EmailTemplate;
