import { EditOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import React from 'react'
const suggestionColsCargo = [
    {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        align: 'center'
    },
    {
        title: "DWT",
        dataIndex: "dwt",
        key: "dwt",
        width: 150,
        align: 'center'
    },
    {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "dwt",
        width: 150,
        align: 'center'
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 250,
        align: 'center'
    },
    {
        title: "Open Area",
        dataIndex: "open_area",
        key: "open_area",
        width: 250,
        align: 'center'
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 250,
        align: 'center',
        render: (text, record) => {
            return (
                <div className="editable-row-operations">
                    <Tooltip title="Edit">
                        <span
                            className="iconWrapper"
                        // onClick={(e) => redirectToAdd(e, record)}
                        >
                            <EditOutlined />
                        </span>
                    </Tooltip>
                </div>
            );
        },
    },
];
const SuggestionTable = ({ data }) => {
    return (
        <div style={{ display: "flex" }}>
            <Table title={() => <h3 style={{ margin: 0 }}>Arrived Vessels</h3>} className="inlineTable resizeableTable" bordered columns={suggestionColsCargo} dataSource={data} />
        </div>
    )
}

export default SuggestionTable