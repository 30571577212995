import React, { useState, useEffect, useRef } from "react";
import { CustomSpinner } from "../../routes/port-to-port/portUtil";
import moment from "moment";
import { events } from "../mail-folder/constants";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import "./internalChat.css";
import {
  Popover,
  Typography,
  Tooltip,
  Button,
  Input,
  List,
  Avatar,
  Menu,
  Dropdown,
  message,
  Modal,
  Checkbox,
  Input as AntInput,
} from "antd";
import { Icon } from "@iconify/react";
import noChatImage from "./chat_image-removebg-preview.png";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  postAPICallService,
} from "../../shared";
import EmojiPicker from "emoji-picker-react";
// import { Picker } from 'emoji-picker-react';
import { Drawer } from "antd";
import contactImage from "./picture-removebg-preview.png";
import { method } from "lodash";
import { logDOM } from "@testing-library/react";

const Chat = () => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const [isEditModalVisible, setisEditModalVisible] = useState(false);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchChats, setSearchChats] = useState('');
  const [isSearchPopoverVisible, setIsSearchPopoverVisible] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [chatMessages, setChatMessages] = useState([]) // for chat 
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userDeatils, SetuserDetails] = useState({});
  const [isAddMemberModal, setAddMemberModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [people, setPeople] = useState([]);
  const [userList, setUserList] = useState([]); // for storing users
  const [searchTerm, setSearchTerm] = useState(""); // Left-side search
  const [modalSearchTerm, setModalSearchTerm] = useState(""); // Modal search term
  const [activeChat, setActiveChat] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [groupChatMessages, setGroupChatMessages] = useState([]);
  const [groupChatState, setGroupChatState] = useState({
    user: [],
    userChat: [],
    userMsg: [],
    currentUser: "",
  });
  const [editContent, setEditContent] = useState(""); //for editing chat
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  const fileInputRef = React.useRef(null);
  const [mailState, setMailState] = useState({
    userMail: [],
    userChat: [],
    userMsg: [],
    currentUser: "",
  });

  const onEmojiClick = (emojiObject) => {
    // Append the selected emoji to the current input value
    setMessageInput((prevMsg) => prevMsg + emojiObject.emoji);
    setShowPicker(false); // Close the picker after emoji selection
  }; 
  const emojiPicker = (e) => {
    e.stopPropagation(); // Prevent click from bubbling up
    setShowPicker((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const reOrderList = (mail) => {
    // console.log("mail", mail);
    setUserList((prevUserList) => {
      const updatedList = prevUserList.filter((user) => user.email !== mail);
      const userToMove = prevUserList.find((user) => user.email === mail);
      if (userToMove) {
        updatedList.unshift(userToMove);
      }
      return updatedList;
    });

    // console.log("mail------", mail);
    // console.log("current user-------", mailState.currentUser);

    if (mail === mailState?.currentUser) {
      setActiveChat(0); // why trhis is written.
    }
  }


  // will solve this
  const createWebsocketConnection = () => {
    try {
      const token = localStorage.getItem('oceanMailToken')
      const userData = token ? JSON.parse(atob(token.split('.')[1])) : null
      const groupSocketUrl = `${process.env.NEXT_PUBLIC_SOCKET_BASE_URL}/ws/group/${userData.company_name}/?token=${token}`
      const groupSocket = new WebSocket(groupSocketUrl)
      groupSocket?.addEventListener('message', event => {
        const eventData = JSON.parse(event.data)
        // console.log('eventdata', eventData)
        groupEventHandler(eventData)
      })
    } catch (error) {
      console.error('Error handling socket message:', error)
    }
  }

  const handleInternalChatSearch = async (e) => {
    const query = e.target.value;
    setSearchChats(query);
    if (userDeatils?.is_group == false) {

      if (query === "") {
        setChatMessages(filteredMessages); // Reset to original messages
      } else {
        // Filter messages based on the search query
        const filtered = chatMessages.filter((message) =>
          message.content.toLowerCase().includes(query.toLowerCase())
        );
        setChatMessages(filtered); // Update chat messages state
      }
    } else {
      if (query === "") {
        setGroupChatMessages(filteredMessages);
      } else {
        const filtered = groupChatMessages.filter((message) =>
          message.content.toLowerCase().includes(query.toLowerCase())
        );
        setGroupChatMessages(filtered);
      }
    }
  }


  const fetchGroupChat = async (user) => {

    // if (!hasData) {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/get-group-chat/${user?.id}`;
      const payload = {};
      const response = await postAPICall(url, payload, "post");
      // console.log("response", response);
      if (response?.status == true) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      const modifiedData = response?.data?.map((item) => ({
        ...item,
        first_name: item.sender?.first_name,
      }));

      setGroupChatMessages(modifiedData);
      setFilteredMessages(modifiedData)

      setGroupChatState((prev) => ({
        ...prev,
        userMail: {
          ...prev.user, // Changed from prev.user to prev.userMail to match the state key
          [user.email]: modifiedData,
        },
      }));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setGroupChatMessages([...groupChatState?.user[user.email]])
    // }
  };

  useEffect(() => {
    try {
      window.myGroupSocket?.addEventListener("message", (event) => {
        const eventData = JSON.parse(event.data);
        // console.log("eventdata", eventData);
        groupEventHandler(eventData);
      });
    } catch (error) {
      console.error("Error handling socket message:", error);
    }
  }, [groupChatState.currentUser]);

  const groupEventHandler = (eventData) => {
    const { content, event, id, placeholder, first_name } = eventData;
    const { sender, username } = eventData;
    const userData = JSON.parse(
      atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
    );

    // Handle 'GROUP_CHAT_MESSAGE' event
    if (sender !== userData?.email) {
      if (event === "GROUP_CHAT_MESSAGE") {
        const { msg, receiver_email } = content;
        const newChatMessage = {
          id: id,
          content: msg,
          created_at: new Date().toISOString(),
          place_holder: placeholder,
          sender: sender,
          receiver: receiver_email,
          first_name: first_name,
        };

        setGroupChatMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage?.id !== id) {
            return [...prevMessages, newChatMessage];
          }
          return prevMessages;
        });
      }
    }

    // Handle 'Group_Chat_Edit' event
    if (event === "Group_Chat_Edit" || event === "GroupChatEdit") {
      const { msg, receiver_email } = content;
      const updatedChatMessage = {
        id: id,
        content: msg,
        created_at: new Date().toISOString(),
        place_holder: placeholder,
        sender: sender,
        receiver: receiver_email,
        first_name: first_name,
      };

      setGroupChatMessages((prevMessages) => {
        return prevMessages.map((message) => {
          if (message.id === id) {
            return { ...message, content: msg }; // Update the message content
          }
          return message;
        });
      });
    }

    // Handle 'chat_acknowledgment' event
    if (event === "chat_acknowledgment") {
      const { id } = eventData;

      setGroupChatMessages((prevMessages) => {
        const latestMessage = prevMessages[prevMessages.length - 1];
        if (latestMessage) {
          latestMessage.id = id; // Update the id of the latest message
        }
        return [...prevMessages];
      });
    }
  };
  //eventhadler for one on one chat
  const eventHandler = (eventData) => {
    // console.log("eventdata---------", eventData);

    const { content, event,id } = eventData;
    if (event === "CHAT_MESSAGE") {
      // console.log("event of chat is triggered-----");
      const { msg, receiver_email } = content;
      const { sender } = eventData;

      // const newChatMessage = {
      //   // id: chatMessages.length + 1,
      //   id:id,
      //   content: msg,
      //   timestamp: new Date().toISOString(),
      //   placeholder: mailState.currentUser,
      //   sender: sender,
      //   receiver: receiver_email,
      // };

      // // reOrderList(sender);

      // setMailState((prev) => ({
      //   ...prev,
      //   userMail: [...(prev.userMail[sender] || []), newChatMessage],
      // }));

      // if (mailState.currentUser === sender) {
      //   // chatMessages.push(newChatMessage);
      //   setChatMessages((prevMessages) => [...prevMessages, newChatMessage]);
      // }

       // Check if the message with the same id already exists
    const messageExists = chatMessages.some((message) => message.id === id);

    if (!messageExists) {
      const newChatMessage = {
        id: id,
        content: msg,
        timestamp: new Date().toISOString(),
        placeholder: mailState.currentUser,
        sender: sender,
        receiver: receiver_email,
      };

      setMailState((prev) => ({
        ...prev,
        userMail: [...(prev.userMail[sender] || []), newChatMessage],
      }));

      if (mailState.currentUser === sender) {
        setChatMessages((prevMessages) => [...prevMessages, newChatMessage]);
      }
    }
    } else if (event === "chat_acknowled") {
      const { id } = eventData;

      setChatMessages((prevMessages) => {
        const latestMessage = prevMessages[prevMessages.length - 1];
        if (latestMessage) {
          latestMessage.id = id; // Update the id of the latest message
        }
        return [...prevMessages];
      });
    } else if (event === "EDIT_CHAT_MESSAGE") {
      const { id, msg, attachment_urls } = content; // Extract the id, msg, and attachment_urls from content

      setChatMessages((prevMessages) => {
        return prevMessages.map((message) => {
          if (message.id === id) {
            return { ...message, content: msg }; // Update the message content
          }
          return message;
        });
      });
    } else if (event == "NOW_ONLINE") {
      const { email } = eventData;
      if (email) {
        // setUserOnline(true)
        // Update your state to show the user is online
      } else {
        // setUserOnline(false)
        // Update your state to show the user is offline
      }
    }
  };
  // console.log("chatmessages888",chatMessages)

  const websocketListning = (event) => {
    // console.log("hiiiiii2")
    // console.log("------------------>>>>>  ", event);
    try {
      const data = JSON.parse(event.data); // Parse the incoming data
      // console.log(data)
      eventHandler(data);
    } catch (error) {
      console.error("Error processing WebSocket message:", error);
    }
  };
  useEffect(() => {
    if (isAddMemberModal) {
      resetSelections(); // Reset selections when modal is opened
    }
  }, [isAddMemberModal]);

  useEffect(() => {
    // console.log("hiiiiiiii");
    const socket = window.mySocketMail;
    // Setup WebSocket listener for the "message" event
    socket.addEventListener("message", websocketListning);
  }, [mailState?.currentUser]);

  const chatEndRef = React.useRef(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages, groupChatMessages]);

  const submitChat = () => {
    const url = JSON.parse(
      atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
    );
    // console.log("user data", url);

    if (userDeatils.is_group === false) {
      // reOrderList(userDeatils.email)

      try {
        // Check messageInput is not empty
        if (messageInput.trim()) {
          // console.log("Message input is valid:", messageInput);

          const newMessage = {
            content: messageInput,
            sender: mailState.currentUser,
            timestamp: new Date().toISOString(),
            placeholder: usermail,
          };

          events.chatEvent(newMessage);
          const curr = mailState?.currentUser;
          const currentMessages = mailState.userMail[curr] || [];
          // console.log("current messages", currentMessages);

          const isDuplicate = currentMessages.some(
            (msg) =>
              msg.timestamp === newMessage.timestamp &&
              msg.content === newMessage.content
          );

          if (!isDuplicate) {
            // Update chatMessages state

            setChatMessages((prevMessages) => [...prevMessages, newMessage]);

            // Update mailState
            setMailState((prev) => {
              const currentUser = prev.currentUser;
              const updatedUserMail = {
                ...prev.userMail,
                [currentUser]: [
                  ...(prev.userMail[currentUser] || []),
                  newMessage,
                ],
              };

              return {
                ...prev,
                userMail: updatedUserMail,
              };
            });
            // Clear inputs
            setMessageInput("");
          } else {
            // console.log("Duplicate message detected, not adding to state.");
          }

          const updatedMessages = [...chatMessages, newMessage];
          setChatMessages(updatedMessages);
          // console.log("Chat messages updated:", updatedMessages);

          setMessageInput("");

          const payload = {
            event: "CHAT_MESSAGE",
            receiver_email: userDeatils.email,
            msg: messageInput,
            context: "omsai.thor@",
            attachment_urls: attachments,
          };

          // console.log("Payload to be sent:", payload);
          events.chatEvent(payload);
          // console.log("Chat event triggered");
        } else {
          message.warning("Please enter a message.");
          console.warn("Message input was empty.");
        }
      } catch (error) {
        console.error("Error sending chat:", error);
      }
    } else {
      let payload = {
        type: "group.chat.message",
        event: "GROUP_CHAT_MESSAGE",
        group_id: userDeatils?.id,
        receiver_email: userDeatils?.email,
        msg: messageInput,
      };

      let thisMessage = {
        content: messageInput,
        created_at: new Date().toISOString(),
        place_holder: url.email,
        is_read: false,
        attachment_urls: [],
        first_name: url?.first_name,
      };

      events.groupChatEvent(payload);
      setMessageInput("");
      setGroupChatMessages((prevMessages) => [...prevMessages, thisMessage]);
    }
  };
  // console.log("mailstateeee555",mailState)

  //for time in chat
  const timeStampFunc = (date) => {
    const msgDate = new Date(date);
    if (isNaN(msgDate.getTime())) {
      // Check for invalid date
      console.error("Invalid date provided:", date);
      return "Invalid date";
    }
    const currentDate = new Date();

    // Check if the message date is today
    if (
      msgDate.getDate() === currentDate.getDate() &&
      msgDate.getMonth() === currentDate.getMonth() &&
      msgDate.getFullYear() === currentDate.getFullYear()
    ) {
      return moment(msgDate).format("hh:mm A");
    }
    return moment(msgDate).format("DD-MM-YYYY");
  };

  const userData = localStorage.getItem("oceanToken");
  // console.log("userData-------------", userData);
  let usermail;
  if (userData) {
    //oceanToken is a JWT (JSON Web Token)
    try {
      const userdata = JSON.parse(atob(userData.split(".")[1]));
      usermail = userdata.email; // Set usermail from token
      // console.log("userdata------", userdata);
      // console.log("usermail------", usermail);
    } catch (error) {
      console.error("Decoding failed-------:", error.message);
    }
  } else {
    console.log("No user data found.");
  }

  const getAvatar = (first_name) => {
    if (!first_name) return null;
    const firstLetter = first_name.charAt(0).toUpperCase();
    const colors = [
      "#f56a00",
      "#00a2ae",
      "#7265e6",
      "#ffbf00",
      "#ff4d4f",
      "#87d068",
    ];
    const colorIndex = first_name.length % colors.length;
    return (
      <Avatar style={{ backgroundColor: colors[colorIndex] }}>
        {firstLetter}
      </Avatar>
    );
  };

  // delete single chats
  const handleChatDelete = async (message) => {
    // console.log("Attempting to delete message:", message);

    const url = `${process.env.REACT_APP_MT_URL}mail/delete-chat`;
    // console.log("Delete chat URL:", url);


    try {
      const payload = { id: message?.id, is_group: false };
      const method = "DELETE";
      const response = await postAPICall(url, payload, method);
      // console.log("Delete response:", response);

      if (response) {
        // console.log("Message deleted successfully, updating state...");

        setChatMessages((prevMessages) => {
          const updatedMessages = prevMessages.filter(
            (ele) => ele.id !== message.id
          );
          // console.log("Updated chat messages:", updatedMessages);
          return updatedMessages;
        });

        // Update mailState
        setMailState((prev) => {
          const currentUser = prev.currentUser;
          const updatedUserMail = {
            ...prev.userMail,
            [currentUser]: prev.userMail[currentUser].filter(
              (ele) => ele.id !== message.id
            ),
          };

          return {
            ...prev,
            userMail: updatedUserMail,
          };
        });
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
      message.error("An error occurred while deleting the chat.");
    }
  };

  // edit chats
  const handleEditChat = async (message, index) => {
    // console.log("message", message);
    const payload = {
      event: "EDIT_CHAT_MESSAGE",
      receiver_email: userDeatils?.email,
      msg: editContent,
      id: message.id,
      attachment_urls: [],
    };
    // Create a new array with the updated message content
    const updatedChatMessages = [...chatMessages];
    updatedChatMessages[index] = {
      ...updatedChatMessages[index],
      content: editContent,
    };

    // Update the state with the new chat messages
    setChatMessages(updatedChatMessages);
    setEditMode(null);
    setEditContent("");

    // console.log('payload', payload)
    events.chatEvent(payload);
    const currentUser = mailState.currentUser;
    const updatedUserMail = {
      ...mailState.userMail,
      [currentUser]: updatedChatMessages, // Update the messages for the current user in state
    };

    setMailState((prev) => ({
      ...prev,
      userMail: updatedUserMail,
    }));
  };

  const editGroupChat = (message, index) => {
    // console.log("message", message)
    let payload = {
      event: "Group_Chat_Edit",
      message_id: message.id,
      msg: editContent,
    };

    const updatedChatMessages = [...groupChatMessages];
    updatedChatMessages[index] = {
      ...updatedChatMessages[index],
      content: editContent,
    };

    // Update the state with the new chat messages
    setGroupChatMessages(updatedChatMessages);
    setEditMode(null);

    // console.log("payload", payload);
    events.groupChatEvent(payload);
    const currentUser = groupChatState.currentUser;
    const updatedUserMail = {
      ...groupChatState.user,
      [currentUser]: updatedChatMessages, // Update the messages for the current user in state
    };

    setGroupChatState((prev) => ({
      ...prev,
      user: updatedUserMail,
    }));
  };

  const handleDeleteGroupChat = async (message, index) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/delete-chat`;
    const method = "DELETE";
    const payload = {
      id: message?.id,
      is_group: true,
    };

    try {
      const response = await postAPICall(url, payload, method);
      // console.log('response', response)

      if (response) {
        const remainingChats = groupChatMessages.filter(
          (ele) => ele.id !== message.id
        );
        // console.log('remainingmessages', remainingChats)
        setGroupChatMessages(remainingChats);

        //updating the mailstate
        const currentUser = mailState.currentUser;
        const updatedUserMail = {
          ...groupChatState.user,
          [currentUser]: remainingChats, // Update the messages for the current user in state
        };

        setGroupChatState((prev) => ({
          ...prev,
          user: updatedUserMail,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handledeletegroupuser = async (user) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/delete-member/${user?.id}`;
    try {
      const payload = {};
      const method = "POST";
      const response = await postAPICall(url, payload, method);
      // console.log("response got it------", response);
      if (response?.status === true) {
        message.success("user is removed");
        handlegroupusers(user);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleWholeChatDelete = async (user) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/delete-group-and-members/${user?.id}?is_group=${user?.is_group} `;
    try {
      // console.log("user object---", user);
      const isToken = true;
      const method = "POST";
      const response = await postAPICall(url, isToken, method);
      if (response?.status == true) {
        setPeople((prev) => prev.filter((p) => p.id !== user.id));
        message.success(`User '${user.first_name}' deleted successfully.`);
      } else {
        message.error(`Failed to delete user: ${response.message}`);
      }
      // console.log("response ----:", response);
    } catch (error) {
      console.error("getting error", error);
      message.error("An error occurred while deleting the user.");
    }
  };

  const handlechats = async (user) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/getChat`;
    setLoading(true);
    try {
      const payload = { chat_email: user.email };
      const response = await postAPICall(url, payload, "POST");

      if (response?.status === true) {
        setChatMessages(response.chats);
        setFilteredMessages(response.chats)
        setMailState(prev => ({
          ...prev,
          userMail: {
            ...prev.userMail,
            [user.email]: response.chats,
          },
          currentUser: user.email,
        }));
      } else {
        setChatMessages([]); // Reset if no chats
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
      setChatMessages([]); // Reset on error
    } finally {
      setLoading(false);
    }
  };

  // const handlechats = async (user) => {
  //   const url = `${process.env.REACT_APP_MT_URL}mail/getChat`;
  //   setLoading(true);
  //   try {
  //     const payload = { chat_email: user.email }
  //     const method = 'POST';
  //     const response = await postAPICall(url, payload, method);

  //     if (response?.status == true) {
  //       // console.log("response chatss ------------------------------------------------",response);

  //       if (response.chats && response.chats.length > 0) {
  //         setChatMessages(response.chats);

  //         setMailState(prev => ({
  //           ...prev,
  //           userMail: {
  //             ...prev.userMail,
  //             [user.email]: response.chats
  //           },
  //           currentUser: user.email
  //         }))

  //       } else {
  //         setChatMessages([]); // Set to empty if no chats
  //         // message.info("No chat history available for this user.");
  //       }
  //     } else {
  //       console.log("errorr");
  //       setChatMessages([]);

  //     }
  //   } catch (error) {
  //     console.error('getting error', error);
  //     setChatMessages([]);
  //   } finally {
  //     setLoading(false); // Set loading to false at the end
  //   }

  // }

  // const handleEmojiClick = (emojiData) => {
  //   setMessageInput((prev) => prev + emojiData.emoji);
  //   setShowEmojiPicker(false);
  // };

  // const handleAttachFile = () => {
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   const fileNames = files.map((file) => file.name);
  //   setAttachments(fileNames);
  // };

  const handleUserSelection = (user) => {
    setSelectedUsers((prev) =>
      prev.includes(user) ? prev.filter((u) => u !== user) : [...prev, user]
    );
  };
  const resetSelections = () => {
    setSelectedUsers([]); // Reset selected users
    setGroupName(""); // Reset group name or any other states you want to clear
    // Reset other related states as needed
  };

  const handlegroupusers = async (user) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/group-info/${user?.id}`;
    try {
      const response = await getAPICall(url);
      // console.log("API Response:", response);

      const responsedata = response.data?.[0];
      // console.log("responsedata-----------------------", responsedata);

      if (response?.status == true) {
        // console.log("Memberships--------------------:", responsedata.memberships);
        setGroupMembers(responsedata.memberships);
      }
    } catch (error) {
      console.error("Error getting group info:", error);
    }
  };

  const handleaddmembers = async () => {
    const groupid = userDeatils.id;
    const filteredUsers = selectedUsers.map((user) => ({
      email: user.email,
      is_admin: false,
    }));

    // Prepare the payload with selected users
    const payload = filteredUsers;

    let url = `${process.env.REACT_APP_MT_URL}mail/add-member-to-group/${groupid}`;

    try {
      const method = "POST";
      const response = await postAPICall(url, payload, method);
      if (response?.status == true) {
        message.success("Members added successfully");
        setAddMemberModal(false);
        handlegroupusers(groupid);
      } else {
        message.error("Failed to add members");
      }
    } catch (error) {
      console.error("Error adding members:", error);
      message.error("An error occurred while adding members.");
    }
  };

  const getGroupUsers = async () => {
    const url = `${process.env.REACT_APP_MT_URL}accounts/get-users`;
    try {
      const response = await getAPICall(url);
      // console.log("my group response----------------------------",response);
      setUserList(response);
      setPeople(response);

      const userEmails = {};
      response.forEach((user) => {
        if (user.email) {
          userEmails[user.email] = user.email; // Use email as both key and value
        }
      });

      setMailState((prev) => ({
        ...prev,
        userMail: {
          ...prev.userMail,
          ...userEmails, // Merge  userMail with new emails
        },
        currentUser: response.length > 0 ? response[0].email : null, //  current user to the first user's email
      }));
    } catch (error) {
      console.error("getting error", error);
    }
  };

  useEffect(() => {
    getGroupUsers();
  }, []);

  const handleCreateGroup = async () => {
    const userData = JSON.parse(
      atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
    );
    const filteredUsers = selectedUsers.map((user) => ({
      email: user.email,
      is_admin: false,
    }));

    filteredUsers.push({
      email: userData.email,
      is_admin: true,
    });
    const method = "POST";
    const payload = {
      name: groupName,
      icon: "",
      users: filteredUsers,
    };
    try {
      if (groupName !== "") {
        let url = `${process.env.REACT_APP_MT_URL}mail/add-group-and-members`;

        const response = await postAPICall(url, payload, method);


        if (response?.status == true) {

          message.success("Group has been created");
          setSelectedUsers(""); // to empty the checkbox
          setGroupName(""); // Optionally reset the group name
          setIsModalVisible(false); // clode the modal
          getGroupUsers();
        } else {
          message.error("Failed to create group");
        }
      } else {
        message.warning("Add Group name first");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };


  const renamegroup = async () => {
    const url = `${process.env.REACT_APP_MT_URL}mail/rename-group/${activeChat?.id}`
    try {
      const method = "POST";
      const payload = { name: groupName, }
      const response = await postAPICall(url, payload, method);
      // console.log("response of group change", response);

      if (response?.status == true) {
        console.log("groupname changed");
        setisEditModalVisible(false)
        setActiveChat(prevUser => ({
          ...prevUser,
          first_name: groupName // Update the new group name
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const menu = (user) => (
    <Menu
      style={{
        width: "110px",
        height: "50px",
        alignItems: "center",
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <Menu.Item
          key="delete"
          onClick={() => HandleWholeChatDelete(user)}
          style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
        >
          Delete
          <Icon
            icon="mdi:delete"
            style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
          />
        </Menu.Item>
      </div>
    </Menu>
  );


  const filteredPeople = people?.filter(person =>
    person?.first_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredModalUsers = people?.filter(
    (person) =>
      person?.first_name
        ?.toLowerCase()
        .includes(modalSearchTerm.toLowerCase()) && !person.is_group
  );

  const toshowchatsearch = (
    <div>
      <Input
        value={searchChats}
        placeholder="Search chats..."
        onChange={handleInternalChatSearch}// Define handleSearch to filter chats
        className="input"
        style={{ width: '200px' }} // Adjust width as needed
      />
    </div>
  );

  return (
    <div style={{ display: "flex", width: "100%", maxHeight: "700px" }}>
      {/* Left Side */}
      <div style={{ width: "30%", padding: "0.5rem" }}>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            color: "rgba(47, 43, 61, 0.78)",
          }}
        >
          <Typography
            style={{
              fontWeight: "500",
              color: "rgba(47, 43, 61, 0.78)",
              fontSize: "22px",
            }}
          >
            Oceann Chat
          </Typography>
          <Tooltip title="Create Group">
            <button
              style={{
                borderRadius: "50%",
                background: "white",
                border: "none",
              }}
            >
              <Icon
                style={{
                  cursor: "pointer",
                  color: "rgba(47, 43, 61, 0.78)",
                  fontSize: "2rem",
                }}
                onClick={() => setIsModalVisible(true)}
                icon="typcn:group"
              />
            </button>
          </Tooltip>
        </div>

        <Input
          style={{
            width: "100%",
            borderRadius: "8px",
            border: "2px solid rgb(186, 229, 215)",
          }}
          prefix={
            <Icon
              icon="tabler:search"
              style={{
                color: "rgba(47, 43, 61, 0.78)",
                cursor: "pointer",
              }}
            />
          }
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div
          style={{
            maxHeight: "450px",
            overflowY: "auto",
            marginTop: "1rem",
            scrollbarWidth: "thin",
          }}
          className="custom-scroll"
        >
          <List
            itemLayout="horizontal"
            dataSource={filteredPeople}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  // console.log("items------------",item);

                  if (item.is_group) {
                    fetchGroupChat(item);
                    handlegroupusers(item);
                  } else {
                    handlechats(item);
                  }

                  SetuserDetails(item);
                  setActiveChat(item);
                }}
                style={{
                  fontWeight: "500",
                  color: "rgba(47, 43, 61, 0.78)",
                  height: "60px",
                  marginTop: "4px",
                  marginRight: "0.5rem",
                  padding: "0.5rem",
                  borderRadius: "8px",
                  border:
                    index % 2 === 0
                      ? "rgb(186, 229, 215)"
                      : "rgb(230, 235, 242)",
                  backgroundColor:
                    index % 2 === 0
                      ? "rgb(186, 229, 215)"
                      : "rgb(230, 235, 242)", // Alternate colors
                }}
                actions={[
                  <Dropdown key="more" overlay={menu(item)} trigger={["click"]}>
                    <Icon
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: "black", fontSize: "24px" }}
                      icon="tabler:dots-vertical"
                    />
                  </Dropdown>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    item.is_group ? (
                      <Icon
                        style={{
                          color: "rgba(47, 43, 61, 0.78)",
                          marginLeft: "0.2rem",
                          marginTop: "0.3rem",
                          fontSize: "1.6rem",
                          borderRadius: "50%",
                        }}
                        icon="heroicons:user-group-solid"
                      />
                    ) : (
                      getAvatar(item.first_name)
                    )
                  }
                  title={
                    <span
                      style={{
                        textDecoration: "none",
                        marginLeft: "6px",
                        fontSize: "20px",
                        color: "rgba(47, 43, 61, 0.78)",
                      }}
                    >
                      {item.first_name}
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>

      {/* Right Side */}
      <div
        style={{
          width: isDrawerVisible ? "40%" : "70%",
          borderRadius: "8px",
          maxHeight: "570px",
          display: "flex",
          flexDirection: "column",
          height: "570px",
          overflowY: "auto",

          backgroundPosition: "center",
          fontWeight: "500",
        }}
      >
        {/* Chat Section */}
        {activeChat ? (
          <>
            <div
              style={{
                height: "3.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "rgb(186, 229, 215)",
                width: "100%",
                padding: "1rem",
              }}
            >
              <div
                className="custom-scroll"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setIsDrawerVisible(true)}
              >
                {activeChat.is_group ? (
                  <Icon
                    style={{
                      color: "rgba(47, 43, 61, 0.78)",
                      fontSize: "1.8rem",
                      borderRadius: "50%",
                    }}
                    icon="heroicons:user-group-solid"
                  />
                ) : (
                  getAvatar(activeChat.first_name)
                )}
              </div>
              <Typography.Title
                style={{
                  margin: "0 0 0 10px",
                  color: "rgba(47, 43, 61, 0.78)",
                  fontSize: "1.3rem",
                }}
              >
                {activeChat.first_name}
              </Typography.Title>

              <Drawer
                className="custom-scroll"
                title="Contact Info"
                placement="right"
                closable={true}
                mask={false}
                onClose={() => setIsDrawerVisible(false)}
                visible={isDrawerVisible}
                style={{
                  maxHeight: "520px",
                  marginTop: "5.8rem",
                  marginLeft: "29.6rem",
                  width: "400px",
                  borderRadius: "10px 0px 10px 0px",
                  position: "fixed", // Fix position
                  // zIndex: 1,
                  // pointerEvents: 'auto' // Allows interaction
                }}
                bodyStyle={{ padding: "0" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  {!activeChat.is_group ? (
                    <img
                      src={contactImage}
                      alt="Contact"
                      style={{ width: "47%", height: "8.2rem" }}
                    />
                  ) : (
                    <Icon
                      style={{
                        width: "47%",
                        height: "8.2rem",
                        color: "rgba(47, 43, 61, 0.78)",
                      }}
                      icon="heroicons:user-group-20-solid"
                    />
                  )}
                  <Typography.Title
                    style={{ textAlign: "center", fontSize: "1.3rem" }}
                  >
                    {activeChat?.first_name}
                    {activeChat.is_group && (
                      <span
                        style={{
                          marginLeft: "0.3rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setisEditModalVisible(true);
                          setGroupName(userDeatils.first_name); // Set the current group name in the TextField
                        }}
                      >
                        <Icon style={{ fontSize: "1rem" }} icon='solar:pen-bold-duotone' />
                      </span>
                    )}
                  </Typography.Title>
                  <div
                    style={{
                      width: "90%",
                      height: "1px",
                      backgroundColor: "grey",
                      margin: "10px 0",
                    }}
                  />
                  <div>
                    {activeChat.is_group ? (
                      <div>
                        <div
                          style={{
                            // marginTop: "0.5rem",
                            display: "flex",
                          }}
                        >
                          <Typography.Text
                            style={{
                              marginRight: "12rem",
                              fontWeight: "500",
                              fontSize: "1.2rem",
                              color: "rgba(47, 43, 61, 0.78)",
                            }}
                          >
                            Participants
                          </Typography.Text>
                        </div>
                        <div style={{
                          maxheight: "200px"
                        }}>
                          <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                            <Typography.Title level={5} style={{ fontWeight: '600', marginTop: "0.4rem", }}>
                              {groupMembers.length} Participants
                            </Typography.Title>
                            <Icon style={{
                              fontSize: "1.5rem",
                            }} onClick={() => setAddMemberModal(true)} icon="wpf:add-user" />
                          </div>
                          <List
                            className="custom-scroll"
                            style={{
                              maxHeight: "250px",
                              overflowY: "auto",
                              scrollbarWidth: "thin",
                            }}
                            itemLayout="horizontal"
                            dataSource={groupMembers}
                            renderItem={(item, index) => (
                              <List.Item
                                style={{
                                  marginTop: "0.2rem",
                                  fontWeight: "400",
                                  color: "rgba(47, 43, 61, 0.78)",
                                  height: "60px",
                                  borderRadius: "8px",
                                  border:
                                    index % 2 === 0
                                      ? "rgb(186, 229, 215)"
                                      : "rgb(230, 235, 242)",
                                  backgroundColor:
                                    index % 2 === 0
                                      ? "rgb(186, 229, 215)"
                                      : "rgb(230, 235, 242)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Popover
                                    content={
                                      <Button
                                        onClick={() =>
                                          handledeletegroupuser(item)
                                        }
                                      >
                                        Delete
                                      </Button>
                                    }
                                    trigger="click" // Opens on click
                                    placement="top"
                                  >
                                    <Icon
                                      // onClick={(e) => e.stopPropagation()} // Prevents the click from closing the popover
                                      style={{
                                        color: "black",
                                        fontSize: "24px",
                                        marginRight: "0.5rem",
                                      }}
                                      icon="tabler:dots-vertical"
                                    />
                                  </Popover>
                                  <Tooltip
                                    title={item.user.email}
                                    placement="top"
                                  >
                                    <List.Item.Meta
                                      avatar={getAvatar(item.user.first_name)}
                                      title={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <p style={{
                                            textDecoration: 'none',
                                            fontSize: "18px",
                                            color: 'rgba(47, 43, 61, 0.78)',
                                            margin: 0, // Remove default margin
                                            whiteSpace: 'nowrap', // Prevent wrapping
                                            // overflow: 'hidden', // Hide overflow
                                            textOverflow: 'ellipsis', // Add ellipsis for long names
                                          }}>
                                            {item.user.first_name}
                                          </p>
                                        </div>
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </List.Item>
                            )}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Drawer>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                <Popover
                  content={toshowchatsearch}
                  title="Search Chats"
                  trigger="click"
                  visible={isSearchPopoverVisible}
                  onVisibleChange={(visible) => setIsSearchPopoverVisible(visible)}
                  placement="bottom"
                  overlayStyle={{
                    left: 'auto', // Reset the default left position
                    right: '20px', // Position it 20px from the right
                    marginTop: '10px', // Optional: Add margin on top
                  }}
                >
                  <Icon
                    icon='bxs:search'
                    style={{ cursor: 'pointer', fontSize: '24px', color: 'rgba(47, 43, 61, 0.78)' }}
                  />
                </Popover>
                <Icon
                  icon="mdi:close"
                  onClick={() => setActiveChat(null)}
                  style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '24px', color: 'rgba(47, 43, 61, 0.78)' }}
                />
              </div>
            </div>
            <div
              className="custom-scroll"
              style={{
                flexGrow: "1",
                padding: "1rem",
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}shreya.jpg)`,
              }}
            >
              {loading ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <CustomSpinner />{" "}
                </span>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column", // Keep messages in a column
                    justifyContent: "flex-start", // Default to start
                  }}
                >
                  {userDeatils?.is_group == false
                    ? chatMessages.map((message, index) => (
                      <div
                        key={index}
                        style={{
                          borderRadius:
                            message.placeholder === usermail
                              ? "0.55rem 0.55rem 0rem 0.55rem"
                              : "0.55rem 0.55rem 0.55rem 0rem",
                          backgroundColor:
                            message.placeholder === usermail
                              ? "rgb(102, 205, 170)"
                              : "#7783c52b",
                          alignSelf:
                            message.placeholder === usermail
                              ? "flex-end"
                              : "flex-start",
                          width: "auto",
                          display: "inline-block",
                          maxWidth: "80%",
                          wordWrap: "break-word",
                          fontSize: "0.9rem",
                          margin: "0.8rem 0.3rem",
                          padding: "0.1rem 0.6rem",
                          backdropFilter: "blur(0.3px)",
                          boxShadow: "0 4px 10px rgba(0, 0, 255, 0.1)",
                        }}
                      >
                        {editMode == index ? (
                          <Input
                            style={{
                              borderRadius: "8px",
                              border: "2px solid rgb(186, 229, 215)",
                            }}
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEditChat(message, index);
                              }
                            }}
                            onBlur={() => handleEditChat(message, index)}
                          />
                        ) : (
                          <></>
                        )}
                        <p
                          ref={chatEndRef}
                          style={{
                            color: "black",
                          }}
                        >
                          {message.content}{" "}
                          <span
                            style={{
                              fontSize: "0.7rem",
                              color: "black",
                              marginLeft: "4px",
                            }}
                          >
                            {timeStampFunc(message.timestamp)}
                          </span>
                          <Popover
                            style={{
                              width: 0,
                              height: 0,
                              borderLeft: "6px solid transparent",
                              borderRight: "6px solid transparent",
                              borderBottom: "6px solid white", // Match with popover background
                              position: "absolute",
                              top: "-6px", // Adjust position
                              left: "50%", // Center it
                              transform: "translateX(-50%)",
                            }}
                            content={
                              <div
                                style={{
                                  width: "6rem",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  onClick={() => handleChatDelete(message)}
                                  style={{
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "red",
                                  }}
                                  type="link"
                                >
                                  Delete{" "}
                                  <DeleteOutlined
                                    style={{
                                      marginBottom: "0.2rem",
                                      fontSize: "0.8rem",
                                    }}
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setEditMode(index);
                                    setEditContent(message?.content);
                                  }}
                                  style={{
                                    fontSize: "1rem",
                                    marginLeft: "0.5rem",
                                    marginTop: "0.5rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="link"
                                >
                                  Edit{" "}
                                  <EditOutlined
                                    style={{
                                      marginBottom: "0.2rem",
                                      marginLeft: "1rem",
                                    }}
                                  />
                                </button>
                              </div>
                            }
                            trigger="hover"
                          >
                            <DownOutlined
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                                fontSize: "0.8rem",
                                color: "black",
                                display:
                                  message.placeholder === usermail
                                    ? ""
                                    : "none",
                              }}
                            />
                          </Popover>
                        </p>
                      </div>
                    ))
                    : groupChatMessages.map((message, index) => (
                      <div
                        key={index}
                        style={{
                          borderRadius:
                            message.place_holder === usermail
                              ? "0.55rem 0.55rem 0rem 0.55rem"
                              : "0.55rem 0.55rem 0.55rem 0rem",
                          backgroundColor:
                            message.place_holder === usermail
                              ? "rgb(102, 205, 170)"
                              : "#7783c52b",
                          alignSelf:
                            message.place_holder === usermail
                              ? "flex-end"
                              : "flex-start",
                          width: "auto",
                          display: "inline-block",
                          maxWidth: "80%",
                          wordWrap: "break-word",
                          fontSize: "0.9rem",
                          margin: "0.8rem 0.3rem",
                          padding: "0.1rem 0.6rem",
                          backdropFilter: "blur(0.2px)",
                          boxShadow: "0 4px 10px rgba(0, 0, 255, 0.1)",
                        }}
                      >
                        <Typography
                          variant='caption'
                          sx={{
                            fontWeight: '300',
                            color: 'black',
                            // marginBottom: '0.5rem',
                            fontSize: "8px",
                            alignSelf: message.place_holder === usermail ? 'flex-end' : 'flex-start'
                          }}
                        >
                          {message.first_name}
                        </Typography>
                        {editMode == index ? (
                          <Input
                            style={{
                              borderRadius: "8px",
                              border: "2px solid rgb(186, 229, 215)",
                            }}
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                editGroupChat(message, index);
                              }
                            }}
                            onBlur={() => editGroupChat(message, index)}
                          />
                        ) : (
                          <></>
                        )}
                        <p
                          ref={chatEndRef}
                          style={{
                            color: "black",
                          }}
                        >
                          {message.content}{" "}
                          <span
                            style={{
                              fontSize: "0.7rem",
                              color: "black",
                              marginLeft: "4px",
                            }}
                          >
                            {timeStampFunc(message.created_at)}
                          </span>
                          <Popover
                            style={{
                              width: 0,
                              height: 0,
                              borderLeft: "6px solid transparent",
                              borderRight: "6px solid transparent",
                              borderBottom: "6px solid white", // Match with popover background
                              position: "absolute",
                              top: "-6px", // Adjust position
                              left: "50%", // Center it
                              transform: "translateX(-50%)",
                            }}
                            content={
                              <div
                                style={{
                                  width: "6rem",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  onClick={() =>
                                    handleDeleteGroupChat(message, index)
                                  }
                                  style={{
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "red",
                                  }}
                                  type="link"
                                >
                                  Delete{" "}
                                  <DeleteOutlined
                                    style={{
                                      marginBottom: "0.2rem",
                                      fontSize: "0.8rem",
                                    }}
                                  />
                                </button>
                                <button
                                  onClick={() => {

                                    setEditMode(index);
                                    setEditContent(message?.content);
                                  }}
                                  style={{
                                    fontSize: "1rem",
                                    marginLeft: "0.5rem",
                                    marginTop: "0.5rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="link"
                                >
                                  Edit{" "}
                                  <EditOutlined
                                    style={{
                                      marginBottom: "0.2rem",
                                      marginLeft: "1rem",
                                    }}
                                  />
                                </button>
                              </div>
                            }
                            trigger="hover"
                          >
                            <DownOutlined
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                                fontSize: "0.8rem",
                                color: "black",
                                display:
                                  message.place_holder === usermail
                                    ? ""
                                    : "none",
                              }}
                            />
                          </Popover>
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div
              style={{
                padding: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                prefix={
                  <div style={{ marginTop: "6px", position: "relative" }}>
                      {/* <iconify-icon
                        icon="tdesign:attach"
                        onClick={handleAttachFile}
                        style={{
                          fontWeight: "400",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "rgb(102, 205, 170);",
                          marginRight: "8px",
                        }}
                      /> */}
                      <Icon
                        icon="mdi:emoji"
                        onClick={emojiPicker}
                        style={{ cursor: 'pointer', fontSize: "22px", marginLeft: "3px", marginBottom: "5px" }}
                      />
                    {showPicker && (
                      <div
                        ref={pickerRef}
                        style={{
                          position: "absolute",
                          bottom: '100%', // Position above the icon
                          zIndex: 10, // Ensure it appears above other elements
                        }}
                      >
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                      </div>
                    )}
                  </div>
                }
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    // e.stopPropagation(); // Prevents bubbling
                    submitChat();
                  }
                }}
                style={{
                  borderRadius: "8px",
                  height: "3rem",
                  fontSize: "16px",
                  width: "100%",
                  borderRadius: "8px",
                  border: "2px solid rgb(186, 229, 215)",
                }}
                placeholder="Type a message..."

                suffix={
                  <Tooltip title="Send Message">
                    <Icon
                      icon="mdi:send"
                      onClick={submitChat}
                      style={{
                        cursor: "pointer",
                        fontWeight: "400",
                        fontSize: "22px",
                        color: "rgb(102, 205, 170);",
                        marginLeft: "8px",
                      }}
                    />
                  </Tooltip>
                }

              />
              <Input
                type="file"
                ref={fileInputRef}
                // onChange={handleFileChange}
                style={{ display: "none" }} // Hide the input
                multiple
              />
              {/* {attachments.length > 0 && (
                <div style={{ color: 'rgb(186, 229, 215)', fontSize: '12px' }}>
                  {attachments.join(', ')}
                </div>
              )} */}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <img
              src={noChatImage}
              alt="No active chat"
              style={{ marginTop: "15rem", height: "7rem", width: "auto" }}
            />
            <Typography.Text
              style={{
                color: "rgba(47, 43, 61, 0.78)",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "500",
                marginBottom: "0",
              }}
            >
              Select a user to start chatting
            </Typography.Text>
          </div>
        )}
      </div>
      {/* Create Group Modal */}
      <Modal
        title="Create Group"
        visible={isModalVisible}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={handleCreateGroup}>
            OK
          </Button>,
        ]}
        onCancel={() => {
          setIsModalVisible(false);
          resetSelections();
        }}
        bodyStyle={{ maxHeight: "600px", overflowY: "hidden", color: "black" }}
      >
        <p>Enter a name for the group:</p>
        <AntInput
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)} // Update state for groupName
          placeholder="Enter Your Group Name"
          style={{
            fontWeight: "500",
          }}
        />
        <p style={{ marginTop: "16px", color: "black" }}>Select Users:</p>
        <AntInput // a search input for user search
          placeholder="Search Users..."
          value={modalSearchTerm}
          onChange={(e) => setModalSearchTerm(e.target.value)}
          style={{
            marginBottom: "10px",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        />
        <div
          className="custom-scroll"
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={filteredModalUsers}
            renderItem={(item, index) => (
              <List.Item
                style={{
                  fontWeight: "500",
                  padding: "1rem",
                  marginTop: "5px",
                  borderRadius: "10px",
                  border:
                    index % 2 === 0
                      ? "rgb(186, 229, 215)"
                      : "rgb(230, 235, 242)",
                  backgroundColor:
                    index % 2 === 0
                      ? "rgb(186, 229, 215)"
                      : "rgb(230, 235, 242)",
                }}
              >
                <List.Item.Meta
                  avatar={getAvatar(item.first_name)}
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => handleUserSelection(item)}
                        style={{ flex: 1 }}
                      >
                        {" "}
                        {item.first_name}{" "}
                      </span>
                      <Checkbox
                        style={{ marginRight: "30px", cursor: "pointer" }}
                        checked={selectedUsers.includes(item)}
                        onChange={() => handleUserSelection(item)}
                      />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>


      {/* add participants fo group drawer */}
      <Modal
        title="Add Participants"
        visible={isAddMemberModal}
        footer={[
          <Button key="ok" type="primary" onClick={handleaddmembers}>
            Add
          </Button>,
        ]}
        onCancel={() => {
          setAddMemberModal(false);
          resetSelections();
        }}
        bodyStyle={{ maxHeight: '600px', overflowY: 'hidden', color: "black" }} >
        <AntInput
          className="input"  // a search input for user search
          placeholder="Search Users..."
          value={modalSearchTerm}
          onChange={(e) => setModalSearchTerm(e.target.value)}
        />
        <div
          className="custom-scroll"
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={filteredModalUsers}
            renderItem={(item, index) => (
              <List.Item
                style={{
                  fontWeight: "500",
                  padding: "1rem",
                  marginTop: "5px",
                  borderRadius: "10px",
                  border:
                    index % 2 === 0
                      ? "rgb(186, 229, 215)"
                      : "rgb(230, 235, 242)",
                  backgroundColor:
                    index % 2 === 0
                      ? "rgb(186, 229, 215)"
                      : "rgb(230, 235, 242)",
                }}
              >
                <List.Item.Meta
                  avatar={getAvatar(item.first_name)}
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => handleUserSelection(item)}
                        style={{ flex: 1 }}
                      >
                        {" "}
                        {item.first_name}{" "}
                      </span>
                      <Checkbox
                        style={{ marginRight: "30px", cursor: "pointer" }}
                        checked={selectedUsers.includes(item)}
                        onChange={() => handleUserSelection(item)}
                      />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </Modal >

      {/* edit group name modal */}
      <Modal
        visible={isEditModalVisible}
        title="Edit Group Name"
        onCancel={() => setisEditModalVisible(false)}  // Close the modal on cancel
        footer={null} // You can customize the footer if needed
      >
        <Input
          className="input"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)} // Update group name
          placeholder="Enter group name"
        />
        <Button onClick={renamegroup} type="primary">OK</Button>
      </Modal>


    </div >
  );
};
export default Chat;
