import React, { Component, useEffect } from "react";
import { Table, Row, Col, Spin, Alert, Modal } from "antd";
import { ES_TABLE_DATA } from "./ESTableInfo";
import URL_WITH_VERSION, {
  getAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../../../shared";
import MakePayment from "../../../../../components/MakePayment";
import TcoMakePayment from "../../../../../components/MakePayment/TcoMakepayment";

const EstimateSummary = (props) => {
  const [state, setState] = useStateCallback({
    tciID: props.tciID || 0,
    tableColumns: [
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
    ],
    tableData: ES_TABLE_DATA,
    hirePayment: [],
    showSummary: false,
    showMakePayment: false,
    makePayment: {},
    tciformData: props.datatci || {},
    type: props.type || {},
    isUpdatePayment: true,
    lastInvoiceId: null,
  });

  useEffect(() => {
    getTableData();
  }, []);

  const refreshTable = () => {
    setState(
      (prevstate) => ({ ...prevstate, showSummary: false }),
      async () => {
        await getTableData();
      }
    );
  };

  const getTableData = async () => {
    const { tciID, tableData, type } = state;
    let request = null;
    if (type == "TCI") {
      request = await getAPICall(`${URL_WITH_VERSION}/make_payment/list?l=0`, {
        id: { where: "`tci_id`='" + tciID + "'", order_by: "id DESC" },
      });
    }

    if (type == "TCO") {
      request = await getAPICall(
        `${URL_WITH_VERSION}/make_payment/tco-list?l=0`,
        { id: { where: "`tco_id`='" + tciID + "'", order_by: "id DESC" } }
      );
    }
    const response = await request?.["data"];
    // invoice_list = await response;
    const lastInvoiceId =
      response.length > 0 ? response[response.length - 1]?.id : null;

    if (
      response ||
      typeof response === "object" ||
      typeof response === "boolean"
    ) {
      if (response.length > 0) {
        let repID = [];
        response.map((e, i) => {
          repID.push({
            key: "hp_" + i,
            description: (
              <p
                className="indent-label"
                onClick={() => onInvoiceClick(e["invoice_no"], type)}
              >
                <span>
                  {e["invoice_no"]},{" "}
                  <span className="amount">$ {e["amount_usd"]}</span>
                </span>
                <span className="dt">{e["created_on"]}</span>
              </p>
            ),
          });
        });
        tableData[0]["children"] = repID;
        setState((prevState) => ({
          ...prevState,
          tableData: tableData,
          showSummary: true,
          lastInvoiceId: lastInvoiceId,
        }));
      } else if (response == false) {
        tableData[0]["children"] = [];
        setState((prevstate) => ({
          ...prevstate,
          tableData: tableData,
          showSummary: true,
          lastInvoiceId: lastInvoiceId,
        }));
      }
    } else {
      setState((prevstate) => ({
        ...prevstate,
        showSummary: true,
        lastInvoiceId: lastInvoiceId,
      }));
    }
  };

  const onInvoiceClick = async (invoice_no, type) => {
    setState((prevstate) => ({
      ...prevstate,

      showMakePayment: false,
    }));
    const { tciformData } = state;
    let bunker = [];
    let request = null;

    if (
      tciformData &&
      tciformData.hasOwnProperty("bunkers") &&
      tciformData["bunkers"].length > 0
    ) {
      bunker = [
        { dr_name: "Delivery Cost" },
        { dr_name: "Adj on delivery" },
        { dr_name: "Redelivery Cost" },
        { dr_name: "Adj on redelivery" },
      ];

      tciformData["bunkers"].map((e) => {
        if (e.description === 3 || e.description === 6) {
          let index = e.description === 3 ? 0 : 2;
          let cDesc = JSON.parse(e.c_fields_values);
          let item = { ifo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0, mgo: 0 };
          let iv = {
            IFO: "ifo",
            VLSFO: "vlsfo",
            ULSFO: "ulsfo",
            MGO: "mgo",
            LSMGO: "lsmgo",
          };
          cDesc.map(
            (_e) => (item[iv[_e.name]] = _e.consumption + " X " + _e.price)
          );
          bunker[index] = Object.assign(item, bunker[index]);
        }
      });
    }
    try {
      if (type == "TCI") {
        request = await getAPICall(
          `${URL_WITH_VERSION}/make_payment/edit?e=${invoice_no}&frm=time_charter_in_form`
        );
      }
      if (type == "TCO") {
        request = await getAPICall(
          `${URL_WITH_VERSION}/make_payment/edit?e=${invoice_no}&frm=tco_make_payment`
        );
      }

      const response = await request["data"];
      
      localStorage.setItem(
        "savemakepayment",
        JSON.stringify(true)
      );
      
      if (response) {
        response["bunkerdeliveryredeliveryterm"] = bunker;
        response["vessel_code"] = tciformData["vessel_code"];
        response["company_lob"] = tciformData["company_lob"];
        response["vessel_id"] = tciformData["vessel_id"];
      }
      // bellow code is added by vikas to disable all the checkboxes as delete
      // button not existing in edit mode
      response["---------------"]["disablefield"] = [
        "add_tc_hire",
        "gratuity",
        "other_exp",
        "broker_comm",
        "victualling",
        "other_revenue",
        "ballast_bonus",
        "ilohc",
        "ihc",
        "exp_allowance",
        "lashing_stow",
        "bad_vlsfo",
        "bad_ifo",
        "bad_mgo",
        "bad_lsmgo",
        "bad_ulsfo",
        "bor_vlsfo",
        "bor_mgo",
        "bor_ifo",
        "bor_lsmgo",
        "bor_ulsfo",

        "off_hire",
        "vessel_off_hire",
      ];

      setState((prevstate) => ({
        ...prevstate,
        makePayment: response,
        makepaymentid: response.id,
        showMakePayment: true,
        isUpdatePayment: true,
      }));
    } catch (error) {
      console.error("Error fetching invoice data:", error);
    }
  };

  const onUpdateMakePayment = (eID, type) => {
    setState(
      (prevstate) => ({
        ...prevstate,
        makePayment: {},
        isUpdatePayment: false,
      }),
      () => {
        onInvoiceClick(eID, type);
      }
    );
  };

  const onCancelMakePayment = () => {
    setState(
      (prevstate) => ({
        ...prevstate,
        makePayment: {},
        showMakePayment: false,
        isUpdatePayment: false,
      }),
      () => getTableData()
    );
  };

  useEffect(() => {
    if (state.showMakePayment) {
      if (state.type == "TCI") {
        const newWindow = window.open(
          `#/make-payment/${state.makePayment.invoice_no}`,
          "_blank"
        );
        newWindow.state = {
          formData: state.makePayment,
          disableEdit: true,
          lastInvoiceId: state.lastInvoiceId,
        };
      } else if (state.type == "TCO") {
        const newWindow = window.open(
          `#/issue-bill/${state.makePayment.invoice_no}`,
          "_blank"
        );
        newWindow.state = {
          formData: state.makePayment,
          disableEdit: true,
          lastInvoiceId: state.lastInvoiceId,
        };
      }
    }
  }, [state.showMakePayment]);

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {state.showSummary ? (
            <Table
              columns={state.tableColumns}
              dataSource={state.tableData}
              pagination={false}
              bordered
            />
          ) : (
            <div className="col col-lg-12">
              <Spin tip="Loading...">
                <Alert message=" " description="Please wait..." type="info" />
              </Spin>
            </div>
          )}
        </Col>
      </Row>

      {/*     






      {state.showMakePayment && state.type == "TCI" ? (
        <Modal
          title="Make Payment"
          // open={state.showMakePayment}
          width="80%"
          onCancel={onCancelMakePayment}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          {" "}
          {state.isUpdatePayment && (
            <MakePayment
              formData={state.makePayment}
              modalCloseEvent={() => onCancelMakePayment()}
              disableEdit={true}
              onUpdateMakePayment={onUpdateMakePayment}
              lastInvoiceId={state.lastInvoiceId}
              refreshTable={refreshTable}
            />
          )}{" "}
        </Modal>
      ) : state.showMakePayment && state.type == "TCO" ? (
        <Modal
          title="Issue Bill"
          open={state.showMakePayment}
          width="80%"
          onCancel={onCancelMakePayment}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          {" "}
          {state.isUpdatePayment && (
            <TcoMakePayment
              formData={state.makePayment}
              modalCloseEvent={() => onCancelMakePayment()}
              disableEdit={true}
              onUpdateMakePayment={onUpdateMakePayment}
              lastInvoiceId={state.lastInvoiceId}
              refreshTable={refreshTable}
            />
          )}{" "}
        </Modal>
      ) : undefined}


*/}
    </>
  );
};

export default EstimateSummary;
