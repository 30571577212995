import React from 'react'
import PopToolTip from '../../services/Tooltip'
import { Popover } from 'antd'

const TableHeaderToolTip = ({ currentRow }) => {
    if (currentRow.f_tool_tip && (currentRow.group_name !== "Port Itinerary"  && currentRow.group_name !== "Bunker Details" && currentRow.group_name !== "Port Date Details" && currentRow.group_name !== "CII Dynamics" && currentRow.group_name !== "EU ETS")) {
        return currentRow.name
    }
    if (currentRow.group_name == "." || currentRow.group_name == "Cargos") {
            return currentRow.name
         }
    return (
        <Popover
            placement="right"
            destroyTooltipOnHide={true}
            content={<PopToolTip text={currentRow.f_tool_tip} title={currentRow.name} />}
            overlayClassName="custom-tooltip"
        >
            {currentRow.name}
        </Popover>
    )
}

export default TableHeaderToolTip