import React, { useState, useEffect } from 'react';
import { Table, Button, Spin } from 'antd';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const AiCargoSuggestion = ({ cargoData,getCargoFromAI }) => {
  const [data, setData] = useState(cargoData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let timeout;

    if (cargoData && cargoData.length > 0) {
      setData(cargoData);
      setLoading(false);
    } else {
      timeout = setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [cargoData]);

  const columns = [
    {
      title: 'Cargo Name',
      dataIndex: 'cargo_name',
      key: 'cargo_name',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'Cargo Size',
      dataIndex: 'cargo_size',
      key: 'cargo_size',
      align: 'center',
    },
    {
      title: 'Cargo Type',
      dataIndex: 'cargo_type',
      key: 'cargo_type',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'Discharge Port',
      dataIndex: 'discharge_port',
      key: 'discharge_port',
      render: (port) => <p style={{textTransform:"capitalize"}}>{port.port} {port.country?`, ${port.country}`:"-"}</p>,
      align: 'center',
    },
    {
      title: 'Load Port',
      dataIndex: 'load_port',
      key: 'load_port',
      render: (port) => <p style={{textTransform:"capitalize"}}>{port.port}, {port.country}</p>,
      align: 'center',
    },
    {
      title: 'Laycan',
      dataIndex: 'laycan',
      key: 'laycan',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleImport(record)}>Import</Button>
      ),
      align: 'center',
    },
  ];

  const handleImport = (record) => {
      getCargoFromAI(record);
    // console.log('Importing record:', record);
    // let data = {
    //     cargo_name:record?.cargo_name||"",
    //     cp_qty:record?.cargo_size||"",
    //     laycan_from:"",
    //     laycan_to:""
    // }
    // return record; 
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="_id"
      style={{ textAlign: 'center' }}
      className="custom-table"
      loading={loading && <Spin />}
    />
  );
};

export default AiCargoSuggestion;
