import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { getAPICall, openNotificationWithIcon, postAPICall } from "../../shared";
import {
  capitalizeFirstLetterOfEachWord,
  extractYearFromDate,
} from "../../services/email_utils";
import { Icon } from "@iconify/react/dist/iconify.js";
import Swal from "sweetalert2";
import moment from "moment";

const TonnageFirmOffers = () => {
  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddTonnageFirmModalOpen, setIsAddTonnageFirmModalOpen] =
    useState(false);
    const [isEditDataClicked, setIsEditDataClicked] = useState("save");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  const columns = [
    {
      title: "Id",
      sortable: true,
      fixed: "left",
      render: (text, row, index) => (
        <div className="text-center">
          {(currentPage - 1) * pageSize + index + 1}
        </div>
      ),
      width: "3rem", //RDT provides index by default,
    },

    {
      label: "Template Name",
      title: "Template Name",
      fixed: "left",
      selector: (row) => row.templateName,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.templateName}</div>
      ),
      width: "10rem",
    },
    {
      label: "Vessel Name",
      title: "Vessel Name",
      selector: (row) => row.vesselName,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.vesselName}</div>,
      width: "10rem",
    },
    {
      label: "Open Area",
      title: "Open Area",
      selector: (row) => row.openArea,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.openArea}</div>,
      width: "10rem",
    },
    {
      label: "Open Date",
      title: "Open Date",
      selector: (row) => row.openDate,
      sortable: true,
      reorder: true,
      render: (row) => (
        <Tooltip title={row.openDate} arrow placement="top">
          <div className="overflowText2" style={{ padding: "10px" }}>
            {row.openDate}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      label: "BOD",
      title: "BOD",
      selector: (row) => row.bod,
      sortable: true,
      reorder: true,
      render: (row) => (
        <Tooltip title={row.bod} arrow placement="top">
          <div className="overflowText2" style={{ padding: "10px" }}>
            {row.bod}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      label: "Last 5 Cargo",
      title: "Last 5 Cargo",
      selector: (row) => row.last5Cargo,
      sortable: true,
      reorder: true,
      render: (row) => (
        <Tooltip title={row.last5Cargo} arrow placement="top">
          <div className="overflowText2" style={{ padding: "10px" }}>
            {row.last5Cargo}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      label: "ETA",
      title: "ETA",
      selector: (row) => row.eta,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>
          {new Date(row.eta).toLocaleDateString()}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "LOA",
      title: "LOA",
      selector: (row) => row.loa,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.loa}</div>,
      width: "10rem",
    },
    {
      label: "Beam",
      title: "Beam",
      selector: (row) => row.beam,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.beam}</div>,
      width: "10rem",
    },
    {
      label: "Beam",
      title: "Beam",
      selector: (row) => row.dwt,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.dwt}</div>,
      width: "10rem",
    },
    {
      label: "Hatches",
      title: "Hatches",
      selector: (row) => row.hatches,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.hatches}</div>,
      width: "10rem",
    },
    {
      label: "GRT",
      title: "GRT",
      selector: (row) => row.grt,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.grt}</div>,
      width: "10rem",
    },
    {
      label: "NRT",
      title: "NRT",
      selector: (row) => row.nrt,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.nrt}</div>,
      width: "10rem",
    },
    {
      label: "Sea Consumption",
      title: "Sea Consumption",
      selector: (row) => row.seaConsumption,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.seaConsumption}</div>
      ),
      width: "10rem",
    },
    {
      label: "Port Consumption",
      title: "Port Consumption",
      selector: (row) => row.portConsumption,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.portConsumption}</div>
      ),
      width: "10rem",
    },
    {
      label: "Holds Cubic Feet Capacity",
      title: "Holds Cubic Feet Capacity",
      selector: (row) => row.holdsCubicFeetCapacity,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.holdsCubicFeetCapacity}</div>
      ),
      width: "10rem",
    },
    {
      label: "Sizes of Hatches",
      title: "Sizes of Hatches",
      selector: (row) => row.sizesOfHatches,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.sizesOfHatches}</div>
      ),
      width: "10rem",
    },
    {
      label: "Number of Cranes",
      title: "Number of Cranes",
      selector: (row) => row.numOfCranes,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.numOfCranes}</div>,
      width: "10rem",
    },
    {
      label: "Number of Grabs",
      title: "Number of Grabs",
      selector: (row) => row.numOfGrabs,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.numOfGrabs}</div>,
      width: "10rem",
    },
    {
      label: "Agent Details",
      title: "Agent Details",
      selector: (row) => row.agentDetails,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.agentDetails}</div>
      ),
      width: "10rem",
    },
    {
      label: "Account Details",
      title: "Account Details",
      selector: (row) => row.accountDetails,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.accountDetails}</div>
      ),
      width: "10rem",
    },
    {
      label: "Hire Rate",
      title: "Hire Rate",
      selector: (row) => row.hireRate,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.hireRate}</div>,
      width: "10rem",
    },
    {
      label: "Bunker Rates",
      title: "Bunker Rates",
      selector: (row) => row.bunkerRates,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.bunkerRates}</div>,
      width: "10rem",
    },
    {
      label: "ILOHC Rates",
      title: "ILOHC Rates",
      selector: (row) => row.ilohcRates,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.ilohcRates}</div>,
      width: "10rem",
    },
    {
      label: "Other Rates",
      title: "Other Rates",
      selector: (row) => row.otherRates,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.otherRates}</div>,
      width: "10rem",
    },
    {
      label: "Address Commission",
      title: "Address Commission",
      selector: (row) => row.addressCommission,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.addressCommission}</div>
      ),
      width: "10rem",
    },
    {
      label: "Broker Commission",
      title: "Broker Commission",
      selector: (row) => row.brokerCommission,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.brokerCommission}</div>
      ),
      width: "10rem",
    },
    {
      label: "Last 3 Cargo",
      title: "Last 3 Cargo",
      selector: (row) => row.last3Cargo,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.last3Cargo}</div>,
      width: "10rem",
    },
    {
      label: "Last 3 Charterers",
      title: "Last 3 Charterers",
      selector: (row) => row.last3Charterers,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.last3Charterers}</div>
      ),
      width: "10rem",
    },
    {
      label: "last3Ports",
      title: "last 3 Ports",
      selector: (row) => row.last3Ports,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.last3Ports}</div>,
      width: "10rem",
    },
    {
      label: "hullOrHullNo",
      title: "Hull Or Hull No",
      selector: (row) => row.hullOrHullNo,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.hullOrHullNo}</div>
      ),
      width: "10rem",
    },
    {
      label: "Class",
      title: "Class",
      selector: (row) => row.Class,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.Class}</div>,
      width: "10rem",
    },
    {
      label: "Last Dry Dock",
      title: "Last Dry Dock",
      selector: (row) => row.lastDrydock,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.lastDrydock}</div>,
      width: "10rem",
    },
    {
      label: "lastSpecialSurvey",
      title: "last Special Survey",
      selector: (row) => row.lastSpecialSurvey,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.lastSpecialSurvey}</div>
      ),
      width: "10rem",
    },
    {
      label: "nextDD",
      title: "Next DD",
      selector: (row) => row.nextDD,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.nextDD}</div>,
      width: "10rem",
    },
    {
      label: "ssDue",
      title: "SS Due",
      selector: (row) => row.ssDue,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.ssDue}</div>,
      width: "10rem",
    },
    {
      label: "collisionsLast12Months",
      title: "Collisions Last 12 Months",
      selector: (row) => row.collisionsLast12Months,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.collisionsLast12Months}</div>
      ),
      width: "10rem",
    },
    {
      label: "pscDetentionLast12Months",
      title: "PSC Detention Last 12 Months",
      selector: (row) => row.pscDetentionLast12Months,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.pscDetentionLast12Months}</div>
      ),
      width: "10rem",
    },
    {
      label: "otherDetails",
      title: "Other Details",
      selector: (row) => row.otherDetails,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px" }}>{row.otherDetails}</div>
      ),
      width: "10rem",
    },
    {
      label: "company",
      title: "Company",
      selector: (row) => row.company,
      sortable: true,
      reorder: true,
      render: (row) => <div style={{ padding: "10px" }}>{row.company}</div>,
      width: "10rem",
    },
    {
      title: "Actions",
      label: "Actions",
      left: true,
      fixed: "right",

      reorder: true,
      minWidth: "150px",

      render: (row) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <div
             style={{ padding: "4px", cursor: "pointer" }}
              onClick={() => handleRowDelete(row)}
            >
              <Icon
                className="bg-icon-color"
                icon="tabler:trash"
                fontSize={16}
              />
            </div>
            <div
              style={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                handleEditData(row)
              }}
            >
              <Icon
                icon="tabler:edit"
                fontSize={16}
                className="bg-icon-color"
              />
            </div>
            {/* <div
              style={{ padding: "4px" }}
              onClick={() => {
                // handleEditData(row)
              }}
            >
              <Icon icon="tabler:eye" fontSize={16} className="bg-icon-color" />
            </div> */}
          </div>
        </>
      ),
      width: "6rem",
    },
  ];

  const [formData, setFormData] = useState({
    vesselName: "",
    templateName: "",
    openArea: "",
    openDate: null,
    bod: "",
    last5Cargo: "",
    eta: new Date(),
    loa: null,
    beam: null,
    dwt: null,
    hatches: null,
    grt: null,
    nrt: null,
    seaConsumption: null,
    portConsumption: null,
    holdsCubicFeetCapacity: null,
    sizesOfHatches: "",
    numOfCranes: null,
    numOfGrabs: null,
    agentDetails: "",
    accountDetails: null,
    hireRate: null,
    bunkerRates: null,
    ilohcRates: null,
    otherRates: null,
    addressCommission: null,
    brokerCommission: "",
    last3Cargo: "",
    last3Charterers: "",
    last3Ports: "",
    hullOrHullNo: "",
    Class: "",
    lastDrydock: "",
    lastSpecialSurvey: "",
    nextDD: "",
    ssDue: "",
    collisionsLast12Months: "",
    pscDetentionLast12Months: "",
    otherDetails: "",
  });

  
  useEffect(() => {
    getAllTonnageFirm();
  }, []);

  const getAllTonnageFirm = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/get-tonnage-firms`;
      const response = await getAPICall(url);
      //   if (response) {
      //     dispatch(setSpinner(false));
      //   }
      if (response.status) {
        setFormattedData(response.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");
      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      handleInputChangeData("openDate", dateRangeString);
    }
  };

  const handleInputChangeData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.templateName) newErrors.templateName = 'Template name is required';
    if (!formData.vesselName) newErrors.vesselName = 'Vessel Name is required';
    if (!formData.openArea) newErrors.openArea = 'Open Area is required';
    if (!formData.openDate) newErrors.openDate = 'Open Date is required';

    return newErrors;
  };

  const handleSubmitData = async() => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/add-tonnage-firm`;
          const response = await postAPICall(url,formData,"post");
          if(response?.status=="1"){
            openNotificationWithIcon('success',"Template created successfully.")
            setIsAddTonnageFirmModalOpen(false);
            getAllTonnageFirm();
            setFormData({})
            setErrors({})
          }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async() =>{
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/update-tonnage-firm/${formData.id}`;
          const response = await postAPICall(url,formData,"put");
          if(response?.status=="1"){
            openNotificationWithIcon('success',"Template updated successfully.")
            setIsAddTonnageFirmModalOpen(false);
            getAllTonnageFirm();
            setFormData({})
            setErrors({})
          }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRowDelete = (rowIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this template!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/delete-tonnage-firm/${rowIndex?.id}`;
        const response = await postAPICall(url, {}, "delete");
        if (response.status === "1") {
          openNotificationWithIcon("success", "Template deleted successfully.");
          getAllTonnageFirm();
        }
      }
    });
  };

  const handleEditData = (row) =>{
    setFormData(row)
    setIsEditDataClicked("edit")
    setIsAddTonnageFirmModalOpen(true);
  }

  const handleClose = () => {};
  

  return (
    <>
      <div>
        <div style={{ textAlign: "end", marginBottom: "10px" }}>
          <Button onClick={() => {
            setFormData({})
            setIsEditDataClicked("save")
            setIsAddTonnageFirmModalOpen(true)
          }}>
            <Icon
              icon="ic:baseline-plus"
              className="bg-icon-color"
              fontSize={20}
            />
            <span>Add New </span>
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={formattedData}
          pagination={{ pageSize }}
          scroll={{ x: "max-content" }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Tonnage Firm Offer"
        width={1200}
        style={{
          top: 50,
        }}
        footer={false}
        open={isAddTonnageFirmModalOpen}
        onCancel={() => setIsAddTonnageFirmModalOpen(false)}
      >
        <Form layout="vertical">
          <Row gutter={[16, 0]} style={{ padding: "0 1.2rem" }}>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Template Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="templateName"
                  value={formData.templateName}
                  onChange={handleInputChange}
                  placeholder="Enter template name"
                />
                {/* {errors.templateName && <p style={errorStyle}>{errors.templateName}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Vessel Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="vesselName"
                  value={formData.vesselName}
                  onChange={handleInputChange}
                  placeholder="Enter Vessel Name"
                />
                {/* {errors.vesselName && <p style={errorStyle}>{errors.vesselName}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Open Area <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="openArea"
                  value={formData.openArea}
                  onChange={handleInputChange}
                  placeholder="Enter Open Area"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Open Date <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  onChange={(event, dateString) =>
                    onChange(event, "openDate", dateString)
                  }
                />
                {/* {errors.openDate && <p style={errorStyle}>{errors.openDate}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="BOD">
                <Input
                  name="bod"
                  value={formData.bod}
                  onChange={handleInputChange}
                  placeholder="Enter Value For BOD"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Last 5 Cargo">
                <Input
                  name="last5Cargo"
                  value={formData.last5Cargo}
                  onChange={handleInputChange}
                  placeholder="Enter Last 5 Cargo"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="ETA">
                <DatePicker
                  style={{ width: "100%" }}
                  //   value={formData.eta}
                  onChange={(date, dateString) =>
                    handleInputChange({
                      target: { name: "eta", value: dateString },
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.2)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
              padding: "1rem 1.2rem",
              margin: "1rem 0",
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Typography.Title level={5}>
                  Vessel Particulars
                </Typography.Title>
              </Col>
              <Col span={8}>
                <Form.Item label="LOA">
                  <Input
                    name="loa"
                    type="number"
                    value={formData.loa}
                    onChange={handleInputChange}
                    placeholder="Enter Value For LOA"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="BEAM">
                  <Input
                    value={formData.beam}
                    type="number"
                    name="beam"
                    onChange={handleInputChange}
                    placeholder="Enter Value for BEAM"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="DWT">
                  <Input
                    value={formData.dwt}
                    type="number"
                    name="dwt"
                    onChange={handleInputChange}
                    placeholder="Enter Value for DWT"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Hatches">
                  <Input
                    value={formData.hatches}
                    type="number"
                    name="hatches"
                    onChange={handleInputChange}
                    placeholder="Enter No. Of HATCHES"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="GRT">
                  <Input
                    value={formData.grt}
                    name="grt"
                    onChange={handleInputChange}
                    placeholder="Enter Value For GRT"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="NRT">
                  <Input
                    value={formData.nrt}
                    name="nrt"
                    onChange={handleInputChange}
                    placeholder="Enter Value For NRT"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Sea Consumption">
                  <Input
                    value={formData.seaConsumption}
                    type="number"
                    name="seaConsumption"
                    onChange={handleInputChange}
                    placeholder="Enter value for Sea Consumption"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Port Consumption">
                  <Input
                    value={formData.portConsumption}
                    type="number"
                    name="portConsumption"
                    onChange={handleInputChange}
                    placeholder="Enter value for Port Consumption"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div style={{ padding: "1rem 1.2rem" }}>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <Form.Item label="Holds Cubic Feet Capacity">
                  <Input
                    type="number"
                    name="holdsCubicFeetCapacity"
                    value={formData.holdsCubicFeetCapacity}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Holds Capacity"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Sizes of Hatches LxB">
                  <Input
                    type="number"
                    name="sizesOfHatches"
                    value={formData.sizesOfHatches}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Hatches"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Numbers of Cranes">
                  <Input
                    type="number"
                    name="numOfCranes"
                    value={formData.numOfCranes}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Cranes"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Numbers of Grabs">
                  <Input
                    type="number"
                    name="numOfGrabs"
                    value={formData.numOfGrabs}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Grabs"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Agent Details">
                  <Input
                    name="agentDetails"
                    value={formData.agentDetails}
                    onChange={handleInputChange}
                    placeholder="Enter Agent Details"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Account Details">
                  <Input
                    type="number"
                    name="accountDetails"
                    value={formData.accountDetails}
                    onChange={handleInputChange}
                    placeholder="Enter Account Details"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Hire Rate">
                  <Input
                    type="number"
                    name="hireRate"
                    value={formData.hireRate}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Hire Rate"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Bunker Rates">
                  <Input
                    type="number"
                    name="bunkerRates"
                    value={formData.bunkerRates}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Bunker Rates"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="ILOHC Rates">
                  <Input
                    type="number"
                    name="ilohcRates"
                    value={formData.ilohcRates}
                    onChange={handleInputChange}
                    placeholder="Enter Value for ILOHC Rates"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Other Rates">
                  <Input
                    type="number"
                    name="otherRates"
                    value={formData.otherRates}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Other Rates"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Address Comission">
                  <Input
                    type="number"
                    name="addressCommission"
                    value={formData.addressCommission}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Address Comission"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Broker Comission">
                  <Input
                    type="number"
                    name="brokerCommission"
                    value={formData.brokerCommission}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Broker Comission"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Last 3 Cargo">
                  <Input
                    name="last3Cargo"
                    value={formData.last3Cargo}
                    onChange={handleInputChange}
                    placeholder="Enter Last 3 Cargo"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Last 3 Charterers">
                  <Input
                    name="last3Charterers"
                    value={formData.last3Charterers}
                    onChange={handleInputChange}
                    placeholder="Enter Last 3 Charterers"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Last 3 Ports">
                  <Input
                    name="last3Ports"
                    value={formData.last3Ports}
                    onChange={handleInputChange}
                    placeholder="Enter Last 3 Ports"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Name of Hull or Hull No.">
                  <Input
                    name="hullOrHullNo"
                    value={formData.hullOrHullNo}
                    onChange={handleInputChange}
                    placeholder="Enter Name / No. for Hull"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Class">
                  <Input
                    name="Class"
                    value={formData.Class}
                    onChange={handleInputChange}
                    placeholder="Enter Class"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Last Drydock">
                  <Input
                    name="lastDrydock"
                    value={formData.lastDrydock}
                    onChange={handleInputChange}
                    placeholder="Enter Last Drydock"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Last Special Survey">
                  <Input
                    name="lastSpecialSurvey"
                    value={formData.lastSpecialSurvey}
                    onChange={handleInputChange}
                    placeholder="Enter Last Special Survey"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Next DD">
                  <Input
                    name="nextDD"
                    value={formData.nextDD}
                    onChange={handleInputChange}
                    placeholder="Enter Value of Next DD"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="SS DUE">
                  <Input
                    name="ssDue"
                    value={formData.ssDue}
                    onChange={handleInputChange}
                    placeholder="Enter Value for SS DUE"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Collisions in the last 12 months">
                  <Input
                    name="collisionsLast12Months"
                    value={formData.collisionsLast12Months}
                    onChange={handleInputChange}
                    placeholder="Enter Value for Last Collisions"
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="Details of any PSC detention in the last 12 months">
                  <Input
                    name="pscDetentionLast12Months"
                    value={formData.pscDetentionLast12Months}
                    onChange={handleInputChange}
                    placeholder="Enter Value for PSC Details"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Other Details">
                  <Input
                    name="otherDetails"
                    value={formData.otherDetails}
                    onChange={handleInputChange}
                    placeholder="Enter Other Details"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div style={{ textAlign: "right", marginTop: "16px" }}>
            {isEditDataClicked === "edit" ? (
              <Button type="primary" onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmitData}>
                Save
              </Button>
            )}
            {/* <Button onClick={handleClose} style={{ marginLeft: "8px" }}>
              Close
            </Button> */}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TonnageFirmOffers;
