import { Spin } from "antd";
import React from "react";

const MarineSpin = () => {
  return (
    <Spin
      tip={
        <p
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: "white", // Ant Design primary color
            textAlign: "center",
            margin: "10px 0",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "#12406a", // Light gray background
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          }}
        >
          Please Wait. Fetching vessel data from Marine Traffic...
        </p>
      }
      fullscreen
    />
  );
};

export default MarineSpin;
