import { SearchOutlined } from "@ant-design/icons";
import { Alert, Input, Spin, Tooltip } from "antd";
import { useEffect } from "react";
import URL_WITH_VERSION, { getAPICall, useStateCallback } from "../../index";

const SideList = (props) => {
  const [state, setState] = useStateCallback({
    title: props.title || "",
    //'icon': props.icon,
    columns: props.columns || "",
    rowClick: props.rowClick || (() => {}),
    listData: [],
    showList: false,
    toolbarKey: props.toolbarKey || undefined,
    icon: props.icon ? props.icon : true || false,
    showSearchField: false,
    searchString: props.searchString || "",
    selectedID: props.selectedID || null,
  });

  useEffect(() => {
    _getAPICall();
    if (state.toolbarKey) {
    }
  }, []);

  const _getAPICall = async (headers = null) => {
    // headers = { order_by: { id: "desc" } };
    try {
      if (props.uri && props.uri !== "") {
        let _url = `${URL_WITH_VERSION}${props.uri}`;

        if (!headers && props.statusList) {
          headers = { where: { AND: props.statusList } };
        } else if (headers && props.statusList) {
          headers["where"]["AND"] = props.statusList;
        }
        // else if( headers && props.selectedID){
        //   headers = { where: { AND: props.selectedID} };
        // }

        if (props.filterBy && props.selectedID) {
          headers = {
            ...headers,
            order_by: { id: "desc" },
            where: { [props.filterBy]: { l: props.selectedID } },
          };
        }

        const response = await getAPICall(_url, headers);
        const respData = await response.data;
        if (respData["data"] !== false) {
          setState(
            (prevState) => ({ ...prevState, listData: respData }),
            () => setState((prevState) => ({ ...prevState, showList: true }))
          );
        }
      } else {
        setState((prevState) => ({ ...prevState, showList: true }));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onIconClick = () => {
    setState((prevState) => ({
      ...prevState,
      showSearchField: !state.showSearchField,
    }));
  };

  const onClickButton = (val, type) => {
    let { columns } = props;

    if (columns && columns.length > 0) {
      let headers = {},
        wc = { OR: {} };
      columns.map((e) => {
        if (typeof e === "object" && e.length > 0) {
          e.map((e1) => (e1 ? (wc["OR"][e1] = { l: val }) : undefined));
        } else {
          wc["OR"][e] = { l: val };
        }
        return true;
      });
      headers["where"] = wc;
      _getAPICall(headers);
    }
  };

  const defaultSetStyle = (type) => {
    if (type === "ENQUIRY") {
      return { color: "#01bcd4" };
    } else if (type === "CLOSED") {
      return { color: "#9e9e9e" };
    } else if (type === "FIXED") {
      return { color: "#81d742" };
    } else if (type === "SCHEDULED") {
      return { color: "#28a745" };
    } else if (type === "DELIVERED") {
      return { color: "#01bcd4" };
    } else if (type === "REDELIVERED") {
      return { color: "#0726ff" };
    } else if (type === "COMPLETED") {
      return { color: "#ff0000" };
    } else if (type === "FIX") {
      return { color: "#81d742" };
    } else if (type === "Actual") {
      return { color: "#28a745" };
    } else if (type === "PAID") {
      return { color: "#0726ff" };
    } else if (type === "ACTUAL") {
      return { color: "#0726ff" };
    } else if (type === "PENDING" || type === "Pending") {
      return { color: "#01bcd4" };
    } else if (type === "POSTED") {
      return { color: "#007bff" };
    } else if (type === "Paid") {
      return { color: "#28a745" };
    } else if (type === "Processing") {
      return { color: "#0726ff" };
    } else if (type === "Posted") {
      return { color: "#007bff" };
    } else if (type === "Archives") {
      return { color: "#01bcd4" };
    } else if (type === "DRAFT") {
      return { color: "#fadb14" };
    }
    return { color: "" };
  };

  const {
    title,
    listData,
    columns,
    showList,
    rowClick,
    icon,
    showSearchField,
    searchString,
    selectedID,
  } = state;
  return (
    <>
      <div className="normal-heading">
        {title}
        {icon ? (
          <SearchOutlined onClick={onIconClick} className="float-right mr-3" />
        ) : undefined}
      </div>
      {showSearchField && (
        <div>
          <Tooltip
            title={`Please enter a value for ${searchString} and hit enter key to search.`}
          >
            <Input
              placeholder="Please Enter"
              onPressEnter={(ev) =>
                onClickButton(ev.target.value, "searchValue")
              }
            />
          </Tooltip>
        </div>
      )}
      <div className="fieldscroll-wraps-scroll">
        <div className="fieldscroll-wraps-list">
          {showList === true ? (
            listData && listData.length > 0 ? (
              listData.map((e, i) => {
                return (
                  <article
                    key={"list-" + e.id + "-" + i}
                    className="article"
                    onClick={() => rowClick(e)}
                  >
                    <div
                      className="box box-default"
                      style={
                        selectedID && selectedID === e.id
                          ? {
                              backgroundColor: "#1890ff",
                              color: "#fff",
                              borderColor: "#1890ff",
                            }
                          : {}
                      }
                    >
                      <div className="bunkerInvoiceWrapper pl-2">
                        <p className="heading mb-0">
                          {columns[0] &&
                          typeof columns[0] === "object" &&
                          columns[0].length > 0 ? (
                            <strong>
                              {e.hasOwnProperty(columns[0][0]) &&
                              e[columns[0][0]]
                                ? e[columns[0][0]]
                                : ""}{" "}
                              ,{" "}
                              <span style={defaultSetStyle(e[columns[0][1]])}>
                                {e.hasOwnProperty(columns[0][1]) &&
                                e[columns[0][1]]
                                  ? e[columns[0][1]]
                                  : ""}
                              </span>
                            </strong>
                          ) : (
                            <strong>{e[columns[0]]}</strong>
                          )}
                        </p>
                        <p
                          className="sub-heading mb-0"
                          style={defaultSetStyle(e[columns[1]])}
                        >
                          {e.hasOwnProperty(columns[1]) && e[columns[1]] !== ""
                            ? e[columns[1]]
                            : ""}
                        </p>
                        <p
                          className="value m-0"
                          style={defaultSetStyle(e[columns[2]])}
                        >
                          {e.hasOwnProperty(columns[2]) ? e[columns[2]] : ""}
                        </p>
                      </div>
                    </div>
                  </article>
                );
              })
            ) : (
              <>List is Empty.</>
            )
          ) : (
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          )}
        </div>
      </div>
    </>
  );
};

export default SideList;
