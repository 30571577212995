import * as types from "../constants/actionTypes";

export const setRestrictionData = (data) => ({
  type: types.RESTRICTION_DATA,
  payload: data,
});

export const setVmRestrictionData = (data) => ({
  type: types.VM_RESTRICTION_DATA,
  payload: data,
});

export const composeNewMail = (data) => ({
  type: types.COMPOSE_NEW_MAIL,
  payload: data,
});
export const customNotification = (data) => ({
  type: types.NOTIFICATION_CUSTOM,
  payload: data,
});
export const resetStore = () => ({
  type: types.RESET_STATE,
});
export const setGroupName = (data) => ({
  type: types.FETCH_GROUP_NAME,
  payload: data
});
export const deleteComposeMail = (id) => ({
  type: types.DELETE_COMPOSE_MAIL,
  payload: id,
});
export const updateComposeMailField = (id, field, value) => ({
  type: types.UPDATE_COMPOSE_MAIL_FIELD,
  payload: { id, field, value },
});
export const updateComposeMailWithIdNull = (uid, field, value) => ({
  type: types.UPDATE_COMPOSE_MAIL_ID,
  payload: { uid, field, value },
});

export const toggleComposeMailMin = (id) => ({
  type: types.TOGGLE_COMPOSE_MAIL_MIN,
  payload: id,
});

export const toggleComposeMailMax = (id) => ({
  type: types.TOGGLE_COMPOSE_MAIL_MAX,
  payload: id,
});

export const setAllEmails = (data) => ({
  type: types.FETCH_ALL_EMAILS,
  payload: data,
});
export const setShowAiMails = (data) => ({
  type: types.SHOW_AI_MAILS,
  payload: data,
});
export const setAllGroupEmails = (data) => {
  // console.log("data in reduxxx",data)
  return ({

    type: types.FETCH_ALL_GROUP_EMAILS,
    payload: data,
  })
};
export const setDraftMail = (data) => ({
  type: types.FETCH_DRAFT_EMAILS,
  payload: data,
});

export const setSentMail = (data) => ({
  type: types.FETCH_SENT_EMAILS,
  payload: data,
});

export const setArchivedMail = (data) => ({
  type: types.FETCH_ARCHIVED_EMAILS,
  payload: data,
});

export const setBinMail = (data) => ({
  type: types.FETCH_BIN_EMAILS,
  payload: data,
});

export const setImpMail = (data) => ({
  type: types.FETCH_IMP_EMAILS,
  payload: data,
});

export const setSpamMail = (data) => ({
  type: types.FETCH_SPAM_EMAILS,
  payload: data,
});
export const setMailLabels = (data) => ({
  type: types.FETCH_EMAIL_LABELS,
  payload: data,
});
export const setGroups = (data) => ({
  type: types.FETCH_EMAIL_GROUPS,
  payload: data,
});
export const setMailFolders = (data) => ({
  type: types.FETCH_EMAIL_FOLDERS,
  payload: data,
});
export const setLabelMail = (data) => ({
  type: types.FETCH_LABELS_EMAIL,
  payload: data,
});
export const setFolderMail = (data) => ({
  type: types.FETCH_FOLDER_EMAILS,
  payload: data,
});
export const setImportedMailInbox = (data) => ({
  type: types.FETCH_IMPORTED_EMAILS_INBOX,
  payload: data,
});
export const setCargoChildren = (data) => ({
  type: types.FETCH_CARGO_CHILD,
  payload: data,
});
export const setTonnageChildren = (data) => ({
  type: types.FETCH_TONNAGE_CHILD,
  payload: data,
});
export const setEmailImported = (data) => ({
  type: types.FETCH_IMPORTED_MAILS,
  payload: data,
});

export const setIsSearcihng = (data) => ({
  type: types.IS_SEARCHING,
  payload: data,
});

export const setIsFiltering = (data) => ({
  type: types.IS_FILTERING,
  payload: data,
});

export const setFilterData = (data) => ({
  type: types.FILTER_DATA,
  payload: data,
});

export const setFilterPayload = (data) => ({
  type: types.FILTER_PAYLOAD,
  payload: data,
});

export const setHasMore = (data) => ({
  type: types.HAS_MORE,
  payload: data,
});

export const setMailContainer = (data) => ({
  type: types.MAIL_CONTAINER,
  payload: data,
});

export const setResizeMail = (data) => ({
  type: types.RESIZE_MAIL,
  payload: data,
});

// export const getUserSignanture = (data) =>({
//   type: types.GET_USER_SIGNATURE,
//   payload:data
// })

export const getUserSignature = () => async (dispatch) => {
  try {
    // Assuming static signature is already in the state
    const signature = `<br><br><br><br><br>Best regards,<br><b>Your Name</b><br>Your Company`;
    dispatch({ type: types.GET_USER_SIGNATURE, payload: signature });
    return signature;
  } catch (error) {
    console.error("Error fetching user signature:", error);
  }
};
// });

export const setAllMailCount = (data) => ({
  type: types.ALL_MAIL_COUNT,
  payload: data,
});

export const setTonnageList = (data) => ({
  type: types.TONNAGE_LIST,
  payload: data,
});

export const setCargoList = (data) => ({
  type: types.CARGO_LIST,
  payload: data,
});

export const setAccountMail = (data) => ({
  type: types.ALL_ACCOUNT_MAIL,
  payload: data,
});
export const setEmailReplySuggestions = (data) => ({
  type: types.SET_REPLY_SUGGESTIONS,
  payload: data,
});
export const setEmailReplyOriginalBody = (data) => ({
  type: types.SET_REPLY_ORIGINAL_BODY,
  payload: data,
});
export const setallTagdata = (data) => ({
  type: types.SHOW_TAG_DATA,
  payload: data,

});
export const setVesselName = (data) => ({
  type: types.SET_CURRENT_VESSEL_NAME,
  payload: {
    vesselName: data?.vesselName,
    isVisible: data?.isVisible,
  },
});
