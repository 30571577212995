import React, { memo, useEffect, useMemo, useState } from "react";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import ReactEcharts from "echarts-for-react";
import { DatePicker, Modal, Select, Spin } from "antd";
import dayjs from "dayjs";
import { Icon } from "@iconify/react/dist/iconify.js";

const TonnageAnalysis = ({ type }) => {
  const [options, setOptions] = useState({});
  const [data, setData] = useState({});
  const [predictionData, setPredictionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [vesselNames, setVesselNames] = useState([]);
  const [dropDownState, setDropdownState] = useState({
    vesselData: [],
    openAreaData: [],
    vesselTypeData: [],
    vesselSubTypeData: [],
  });
  const [showVesselListModal, setShowVesselListModal] = useState(false);
  const [selections, setSelections] = useState({
    vessel: null,
    openAreas: null,
    vesselType: null,
    vesselSubType: null,
    dateRange: null,
  });

  const getGraphData = async () => {
    const token = localStorage.getItem("oceanToken");
    try {
      let url = `https://aiml.azurewebsites.net/plot_data`;
      let payload = {};

      //   if (type == 'global') {
      //     payload = {
      //       type: 'tonnage',
      //       client: 'theoceann'
      //     }
      //   } else {
      //     payload = {
      //       type: 'tonnage'
      //     }
      //   }
      setIsLoading(true);
      if (type == "global") {
        payload = {
          type: "tonnage",
          vessel_name: selections?.vessel || "",
          vessel_type: selections?.vesselType || "",
          sub_vessel_type: selections?.vesselSubType || "",
          new_open_port: selections?.openAreas || "",
          new_open_date: selections?.dateRange || "",
          client: "theoceann",
        };
      } else {
        payload = {
          type: "tonnage",
          vessel_name: selections?.vessel || "",
          vessel_type: selections?.vesselType || "",
          sub_vessel_type: selections?.vesselSubType || "",
          new_open_port: selections?.openAreas || "",
          new_open_date: selections?.dateRange || "",
          token: token,
        };
      }

      const response = await postAPICall(url, payload, "post");
      if (response?.status) {
        setData(response.data);
        setPredictionData(response?.predictions);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData({});
        // openNotificationWithIcon("info", response?.message);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getDropdownData = async () => {
    const token = localStorage.getItem("oceanToken");
    try {
      let url = `https://aiml.azurewebsites.net/plot_data_filter`;
      let payload = {};

      if (type == "global") {
        payload = {
          type: "tonnage",
          client: "theoceann",
        };
      } else {
        payload = {
          type: "tonnage",
          token: token,
        };
      }

      const response = await postAPICall(url, payload, "post");
      if (response?.status) {
        setDropdownState((prev) => ({
          ...prev,
          vesselData: response?.vessel_names,
          openAreaData: response?.ports,
          vesselTypeData: response?.vessel_types,
          vesselSubTypeData: response?.sub_vessel_types,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // async function getDropdownData() {
  //   const token = (localStorage.getItem('oceanMailToken'))
  //   try {
  //     let url = `https://aiml.azurewebsites.net/plot_data_filter`
  //     const isToken = true
  //     let payload = {}

  //     if (type == 'global') {
  //       payload = {
  //         type: 'tonnage',
  //         client: 'theoceann'
  //       }
  //     } else {
  //       payload = {
  //         type: 'tonnage',
  //         token:token
  //       }
  //     }
  //     const response = await postApiService({ url, isToken, payload, showMessage: false })
  //     console.log('response', response)
  //     const responseData = response.data
  //     setDropdownState(prev => ({
  //       ...prev,
  //       vesselData: responseData?.vessel_names,
  //       openAreaData: responseData?.ports,
  //       vesselTypeData: responseData?.vessel_types,
  //       vesselSubTypeData: responseData?.sub_vessel_types
  //     }))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    getGraphData();
  }, [selections]);

  useEffect(() => {
    getDropdownData();

    return () => {
      setSelections({
        vessel: null,
        openAreas: null,
        vesselTypes: null,
        vesselSubType: null,
        dateRange: null,
      });
    };
  }, []);

  const dates = useMemo(() => (data ? Object.keys(data) : []), [data]);

  const dwtBins = useMemo(() => {
    if (!data) return [];
    const allBins = dates.flatMap((date) => Object.keys(data[date]));
    return Array.from(new Set(allBins));
  }, [dates, data]);

  const seriesData = useMemo(() => {
    if (!data) return [];
    return dwtBins.map((bin) => ({
      name: bin,
      type: "bar",
      stack: "total",
      data: dates.map(
        (date) =>
          data[date][bin]?.reduce((sum, vessel) => sum + vessel.dwt, 0) || 0
      ),
    }));
  }, [dwtBins, dates, data]);

  const vesselCounts = useMemo(() => {
    if (!data) return [];
    return dates.map((date) => {
      return dwtBins.reduce(
        (count, bin) => count + (data[date][bin]?.length || 0),
        0
      );
    });
  }, [dates, dwtBins, data]);

  const cumulativeData = useMemo(() => {
    if (!data) return [];
    return dates.map((date) => {
      return dwtBins.reduce(
        (total, bin) =>
          total +
          (data[date][bin]?.reduce((sum, vessel) => sum + vessel.dwt, 0) || 0),
        0
      );
    });
  }, [dates, dwtBins, data]);

  useEffect(() => {
    if (seriesData.length === 0) return;

    // Add a line series for the cumulative sum
    const updatedSeriesData = [
      ...seriesData,
      {
        name: "Cumulative Line",
        type: "line",
        data: cumulativeData,
        smooth: false,
      },
    ];

    let allDates = [...dates];

    // Conditionally add predictions if predictionData exists
    if (predictionData && Object.keys(predictionData).length > 0) {
      const predictionDates = Object.keys(predictionData);
      const predictionValues = Object.values(predictionData);

      updatedSeriesData.push({
        name: "Predictions",
        type: "line",
        data: [...new Array(dates.length).fill(null), ...predictionValues],
        lineStyle: {
          color: "#FF0000",
          type: "dashed",
        },
        smooth: true,
      });

      allDates = [...dates, ...predictionDates];
    }

    // ECharts option
    const chartOptions = {
      tooltip: {
        trigger: "axis",
        triggerOn: "mousemove",
        enterable: true,
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          if (!params || params.length === 0) {
            return "";
          }

          const date = params[0].axisValue;
          const linkElement = document.createElement("div");
          linkElement.id = "custom-tooltip";
          linkElement.innerText = "See Vessels";
          linkElement.style.cursor = "pointer";
          linkElement.style.color = "blue";

          let tooltipText = `<div style="text-align: left;">${linkElement.outerHTML}</div><br/>`;
          const totalVessels = vesselCounts[dates.indexOf(date)];

          if (Object.keys(predictionData).length <= 0) {
            tooltipText += `Total Vessels: ${totalVessels}<br/>`;
          }

          setVesselNames([]);

          params.forEach((item) => {
            tooltipText += `${item.marker} ${item.seriesName}: ${item.value}<br/>`;
            const vesselsInBin = data?.[date]?.[item?.seriesName] || [];

            if (vesselsInBin.length > 0) {
              setVesselNames(vesselsInBin.map((vessel) => vessel.vessel_name));
            }
          });

          return tooltipText;
        },
      },
      legend: {
        data: dwtBins,
      },
      xAxis: {
        type: "category",
        data: allDates,
        axisLabel: {
          formatter: (value) => {
            const date = new Date(value);
            const options = { day: "numeric", month: "short" };
            return date.toLocaleDateString("en-GB", options).replace(".", "");
          },
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        name: "Sum of DWTs",
        axisLabel: {
          formatter: (value) => {
            return (value / 100).toFixed(0) + " MT";
          },
        },
      },
      series: updatedSeriesData,
    };

    setOptions(chartOptions);
  }, [
    seriesData,
    cumulativeData,
    dates,
    dwtBins,
    vesselCounts,
    predictionData,
    setOptions,
    setVesselNames,
  ]);

  const openVesselModal = () => {
    setShowVesselListModal(true);
  };

  const tooltipss = document.getElementById("custom-tooltip");
  if (tooltipss) {
    tooltipss.addEventListener("click", openVesselModal);
  }

  const handleChange = (type, value) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [type]: value,
    }));
  };

  return (
    <>
      <div style={{ minHeight: "400px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            padding: "10px 5px",
          }}
        >
          <div style={{ color: "#8080c5", fontSize: "28px" }}>
            <Icon icon="mingcute:filter-fill" />
          </div>
          <div>
            <Select
              style={{ width: "200px" }}
              placeholder="Vessel Name"
              onChange={(value) => handleChange("vessel", value)}
              optionLabelProp="label"
              allowClear
              showSearch
              filterOption={(input, option) =>
                typeof option?.value === "string" &&
                option.value.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dropDownState?.vesselData?.map((vessel) => (
                <Select.Option key={vessel.trim()} value={vessel.trim()}>
                  {vessel.trim()}
                </Select.Option>
              )) || []}
            </Select>
          </div>
          <div>
            <Select
              style={{ width: "200px" }}
              placeholder="Vessel type"
              onChange={(value) => handleChange("vesselType", value)}
              optionLabelProp="label"
              allowClear
              showSearch
              filterOption={(input, option) =>
                typeof option?.value === "string" &&
                option.value.toLowerCase().includes(input.toLowerCase())
              }
              // size='small'
            >
              {dropDownState?.vesselTypeData?.map((vessel) => (
                <Select.Option key={vessel.trim()} value={vessel.trim()}>
                  {vessel.trim()}
                </Select.Option>
              )) || []}
            </Select>
          </div>
          <div>
            <Select
              style={{ width: "200px" }}
              placeholder="Vessel sub type"
              onChange={(value) => handleChange("vesselSubType", value)}
              optionLabelProp="label"
              allowClear
              showSearch
              filterOption={(input, option) =>
                typeof option?.value === "string" &&
                option.value.toLowerCase().includes(input.toLowerCase())
              }
              // size='small'
            >
              {dropDownState?.vesselSubTypeData?.map((subType) => (
                <Select.Option key={subType.trim()} value={subType.trim()}>
                  {subType.trim()}
                </Select.Option>
              )) || []}
            </Select>
          </div>
          <div>
            <DatePicker.RangePicker
              style={{ width: "200px" }}
              onChange={(dates) => {
                if (dates) {
                  const formattedDates = `${dayjs(dates[0]).format(
                    "YYYY-MM-DD"
                  )}/${dayjs(dates[1]).format("YYYY-MM-DD")}`;
                  handleChange("dateRange", formattedDates);
                } else {
                  handleChange("dateRange", null); // Handle case when no date is selected
                }
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <div style={{ marginTop: "10%" }}>
            <Spin tip="Analyzing..." spinning={isLoading} size="large">
              <div></div>
            </Spin>
          </div>
        ) : Object.keys(data).length > 0 ? (
          <ReactEcharts
            option={options}
            style={{ height: "400px", width: "100%" }}
          />
        ) : (
          <div style={{ textAlign: "center", marginTop: "10%" }}>
            No data available
          </div> // Optionally, you can render a message or nothing here
        )}
      </div>
      <Modal
        centered
        title={
          <div>
            Vessel List{" "}
            <span style={{ fontStyle: "italic" }}>
              (Total Vessels: {vesselNames?.length})
            </span>
          </div>
        }
        open={showVesselListModal}
        closable={true}
        onCancel={() => setShowVesselListModal(false)}
        footer={null}
        // width={"85vw"}
        wrapClassName=""
        // style={{ top: "20px" }}
      >
        {/* <div style={{ maxHeight: "500px", overflowY: "auto" }}> */}
        {/* <TonnageAnalysis /> */}
        {/* </div> */}
        <div style={{ overflowY: "auto" }}>
          {vesselNames?.map((item, index) => (
            <div
              style={{
                display: "inline-flex",
                textTransform: "capitalize",
                border: "1px solid",
                padding: "5px 15px",
                borderRadius: "5px",
                margin: "10px 5px",
              }}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default TonnageAnalysis;
