import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs, Typography, Divider, Card, Space, Button } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import { postAPICall } from "../../shared";

const { TabPane } = Tabs;

const SuggestionBox = (props) => {
  const { suggestionLists } = props;
  const [suggestionList, setSuggestionList] = useState(suggestionLists);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  const handleTabChange = (key) => {
    setValue(Number(key));
  };

  const handleSuggestionClick = async (queryId) => {
    const url = `${process.env.REACT_APP_MT_URL}analytic/advance-search?id=${queryId}`;
    const payload = {};
    try {
      const resp = await postAPICall(url, payload, "post");
      console.log("response", resp);
        if (resp.status == true) {
          const record = resp?.data?.[0];
        //   dispatch(setCurrentMail(record));
          props.close();
          props.setMailDetailsOpen(record);
        } else {
          console.log("Some error occurred");
        }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card
      style={{
        boxShadow: "1px 1px 2px 2px",
        position: "relative",
        // top: 94,
        // left: 66,
        width: "600px",
        height: "300px",
        // maxWidth: 740,
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: "24px",
        overflow: "auto",
      }}
    >
      <Typography.Title
        level={4}
        style={{ color: "#1890ff", marginBottom: 16 }}
      >
        Suggestion
      </Typography.Title>

      <Tabs activeKey={String(value)} onChange={handleTabChange} type="card">
        <TabPane tab="All" key="0">
          {suggestionList[5]?.All?.length > 0 ? (
            suggestionList[5]?.All?.map((el, index) => (
              <Card
                key={index}
                className="suggestion-mail-list"
                hoverable
                onClick={() => handleSuggestionClick(el?._id)}
                style={{
                  marginBottom: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Space>
                  <Icon icon="material-symbols:mail" />
                  <div>
                    <Typography.Text strong>
                      Subject: {el?.Subject}
                    </Typography.Text>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography.Text type="secondary">
                        From: {el?.Sender_mail}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        To: {el?.Sender_mail}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                <Divider style={{ margin: "12px 0" }} />
              </Card>
            ))
          ) : (
            <Typography.Text type="secondary">No mails found.</Typography.Text>
          )}
        </TabPane>
        <TabPane tab="Attachments" key="1">
          {suggestionList[0]?.Attachments?.length > 0 ? (
            suggestionList[0]?.Attachments?.map((el, index) => (
              <Card
                key={index}
                className="suggestion-mail-list"
                hoverable
                onClick={() => handleSuggestionClick(el?._id)}
                style={{
                  marginBottom: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Space>
                  <Icon icon="material-symbols:mail" />
                  <div>
                    <Typography.Text strong>
                      Subject: {el?.Subject}
                    </Typography.Text>
                    <div style={{ display: "flex", gap: "30px" }}>
                      <Typography.Text type="secondary">
                        From:{" "}
                        {el?.Sender_mail
                          ? el?.Sender_mail
                          : el?.sender?.emailAddress?.address}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        To:{" "}
                        {el?.toRecipients?.map((recipient) => recipient?.emailAddress?.address)
                          .join(", ")}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                <Divider style={{ margin: "12px 0" }} />
              </Card>
            ))
          ) : (
            <Typography.Text type="secondary">
              No Attachments found.
            </Typography.Text>
          )}
        </TabPane>
        <TabPane tab="Subject" key="2">
          {suggestionList[1]?.Subjects?.length > 0 ? (
            suggestionList[1]?.Subjects?.map((el, index) => (
              <Card
                key={index}
                className="suggestion-mail-list"
                hoverable
                onClick={() => handleSuggestionClick(el?._id)}
                style={{
                  marginBottom: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Space>
                  <Icon icon="material-symbols:mail" />
                  <div>
                    <Typography.Text strong>
                      Subject: {el?.Subject}
                    </Typography.Text>
                    <div style={{ display: "flex", gap: "30px" }}>
                      <Typography.Text type="secondary">
                        From:{" "}
                        {el?.Sender_mail
                          ? el?.Sender_mail
                          : el?.sender?.emailAddress?.address}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        To:{" "}
                        {el?.toRecipients?.map((recipient) => recipient?.emailAddress?.address)
                          .join(", ")}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                <Divider style={{ margin: "12px 0" }} />
              </Card>
            ))
          ) : (
            <Typography.Text type="secondary">
              No Attachments found.
            </Typography.Text>
          )}
        </TabPane>
        <TabPane tab="From" key="3">
          {suggestionList[2]?.Sender_mails?.length > 0 ? (
            suggestionList[2]?.Sender_mails?.map((el, index) => (
              <Card
                key={index}
                className="suggestion-mail-list"
                hoverable
                onClick={() => handleSuggestionClick(el?._id)}
                style={{
                  marginBottom: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Space>
                  <Icon icon="material-symbols:mail" />
                  <div>
                    <Typography.Text strong>
                      Subject: {el?.Subject}
                    </Typography.Text>
                    <div style={{ display: "flex", gap: "30px" }}>
                      <Typography.Text type="secondary">
                        From:{" "}
                        {el?.Sender_mail
                          ? el?.Sender_mail
                          : el?.sender?.emailAddress?.address}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        To:{" "}
                        {el?.toRecipients?.map((recipient) => recipient?.emailAddress?.address)
                          .join(", ")}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                <Divider style={{ margin: "12px 0" }} />
              </Card>
            ))
          ) : (
            <Typography.Text type="secondary">
              No Attachments found.
            </Typography.Text>
          )}
        </TabPane>
        <TabPane tab="To" key="4">
          {suggestionList[3]?.ToRecipients?.length > 0 ? (
            suggestionList[3]?.ToRecipients?.map((el, index) => (
              <Card
                key={index}
                className="suggestion-mail-list"
                hoverable
                onClick={() => handleSuggestionClick(el?._id)}
                style={{
                  marginBottom: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Space>
                  <Icon icon="material-symbols:mail" />
                  <div>
                    <Typography.Text strong>
                      Subject: {el?.Subject}
                    </Typography.Text>
                    <div style={{ display: "flex", gap: "30px" }}>
                      <Typography.Text type="secondary">
                        From:{" "}
                        {el?.Sender_mail
                          ? el?.Sender_mail
                          : el?.sender?.emailAddress?.address}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        To:{" "}
                        {el?.toRecipients?.map((recipient) => recipient?.emailAddress?.address)
                          .join(", ")}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                <Divider style={{ margin: "12px 0" }} />
              </Card>
            ))
          ) : (
            <Typography.Text type="secondary">
              No Attachments found.
            </Typography.Text>
          )}
        </TabPane>
        <TabPane tab="Body" key="5">
          {suggestionList[4]?.Body?.length > 0 ? (
            suggestionList[4]?.Body?.map((el, index) => (
              <Card
                key={index}
                className="suggestion-mail-list"
                hoverable
                onClick={() => handleSuggestionClick(el?._id)}
                style={{
                  marginBottom: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Space>
                  <Icon icon="material-symbols:mail" />
                  <div>
                    <Typography.Text strong>
                      Subject: {el?.Subject}
                    </Typography.Text>
                    <div style={{ display: "flex", gap: "30px" }}>
                      <Typography.Text type="secondary">
                        From:{" "}
                        {el?.Sender_mail
                          ? el?.Sender_mail
                          : el?.sender?.emailAddress?.address}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        To:{" "}
                        {el?.toRecipients?.map((recipient) => recipient?.emailAddress?.address)
                          .join(", ")}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                <Divider style={{ margin: "12px 0" }} />
              </Card>
            ))
          ) : (
            <Typography.Text type="secondary">
              No Attachments found.
            </Typography.Text>
          )}
        </TabPane>
        {/* Repeat similar structure for other tabs: Subject, From, To, Body */}
      </Tabs>
    </Card>
  );
};

export default SuggestionBox;
