import React, { useEffect, useState } from "react";
import { Input, Checkbox, Button, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getAllLabel } from "../../services/email_utils";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import { setMailLabels } from "../../actions/emailActions";
import { useDispatch } from "react-redux";

const LabelManager = ({
  setIsLabelOpen,
  popoverRef,
  isLabelModalVisible,
  setIsLabelModalVisible,
  mail,
  handleIconClick,
  handleSocketEvent,
}) => {
  const [labels, setLabels] = useState([]);
  const [labelID, setLabelID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [labeLoading, setLabelLoading] = useState(false);
  const [labelName, setLabelName] = useState("");

  const dispatch = useDispatch();

  const fetchLabels = async () => {
    setLabelLoading(true);
    const labelResponse = await getAllLabel("*");
    console.log({ labelResponse });
    const { response, allLabels } = labelResponse;
    if (response?.status) {
      setLabelLoading(false);
      setLabels(allLabels);
      dispatch(setMailLabels(allLabels));
    } else {
      setLabelLoading(false);
    }
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  const handleAddLabel = async () => {
    if (labelName.trim()) {
      try {
        const url = `${process.env.REACT_APP_MT_URL}mail/addLabel`;

        const payload = {
          name: labelName?.trim(),
          key: labelName?.trim()?.replace(/\s+/g, "")?.toLowerCase(),
        };
        const response = await postAPICall(url, payload, "post");
        if (response?.id) {
          openNotificationWithIcon("success",response?.msg);
          fetchLabels();
          setLabelName("");
          handleModalClose();
        } else {
          openNotificationWithIcon("error",response?.msg || "Some error occcured");
        }
      } catch (err) {
        console.log(err);
        openNotificationWithIcon("error",err?.message);
      }
    }
  };

  const handleUpdateLabel = async () => {
    if (labelName.trim()) {
      try {
        const url = `${process.env.REACT_APP_MT_URL}mail/RenameLabel`;

        const payload = {
          name: labelName?.trim(),
          labelID,
        };
        const response = await postAPICall(url, payload, "post");
        if (response?.id) {
         openNotificationWithIcon("success",response?.msg);
          fetchLabels();
          setLabelName("");
          handleModalClose();
          setLabelID(null);
        } else {
          openNotificationWithIcon("error",response?.msg || "Some error occcured");
        }
      } catch (err) {
        console.log(err);
        openNotificationWithIcon("error",err?.message);
      }
    }
  };

  const handleDeleteLabel = async (labelId) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/deleteLabel`;

      const payload = {
        labelID: labelId,
      };
      const response = await postAPICall(url, payload, "post");
      if (response?.msg === "Label Deleted Successfully") {
        message.success(response?.msg);
        fetchLabels();
      } else {
        message.error(response?.msg || "Some error occcured");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message);
    }
  };

  const handleLableSubmit = () => {
    const selectedLabels = labels?.filter((label) => label?.checked);
    const payLoad = {
      _id: mail?._id,
      event: "applyLabel",
      labels:
        selectedLabels?.length > 0
          ? selectedLabels?.map((label) => label?.labelName)
          : [],
      context: mail?.client,
    };
    if (mail?.CUSTOM_LABELS?.length > 0) {
      payLoad.labels = [...mail?.CUSTOM_LABELS, ...payLoad.labels];
    }

    handleSocketEvent(payLoad);
    // handleLableApply(payLoad);
    // handleIconClick(e, currentMailIndex, "applyLabel", mail, mailDetails);
    setTimeout(() => {
      setIsLabelOpen();
      setLabels(
        labels?.map((label) => {
          return { ...label, checked: false };
        })
      );
    }, 300);
  };

  const handleModalClose = () => {
    setLabelName(""); // Clear the input value
    setIsLabelModalVisible(false);
    setLabelID(null)
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheck = (id) => {
    setLabels(
      labels.map((label) =>
        label.id === id ? { ...label, checked: !label.checked } : label
      )
    );
  };

  const filteredLabels =
    (labels?.length &&
      labels?.filter((label) =>
        label?.labelName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )) ||
    [];

  return (
    <>
      {labeLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          onClick={(e) => e.stopPropagation()}
          ref={popoverRef}
          style={{
            padding: "10px",
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
          }}
        >
          <Input
            placeholder="Search Labels"
            value={searchTerm}
            onChange={handleSearch}
            style={{ marginBottom: "10px" }}
          />
          <div
            style={{
              maxHeight: "90px",
              overflowY: "auto",
              padding: "5px 10px 5px 0",
            }}
          >
            {filteredLabels?.map((label) => (
              <div
                key={label.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Checkbox
                  checked={label.checked}
                  onChange={() => handleCheck(label?.id)}
                >
                  {label?.labelName}
                </Checkbox>
                <DeleteOutlined
                  style={{
                    marginLeft: "auto",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeleteLabel(label?.id)}
                />
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsLabelModalVisible(true);
                    setLabelID(label.id);
                    setLabelName(label?.labelName)
                  }}
                />
              </div>
            ))}
          </div>
          <Button
            type="primary"
            disabled={!labels.some((label) => label?.checked)}
            style={{ width: "100%", marginTop: "10px" }}
            onClick={handleLableSubmit}
          >
            Apply
          </Button>
          <Button
            type="link"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => setIsLabelModalVisible(true)}
          >
            Create label
          </Button>
        </div>
      )}
      <div ref={popoverRef} onClick={(e) => e.stopPropagation()}>
        <Modal
          title="Add New Label"
          open={isLabelModalVisible}
          closable={true}
          maskClosable={false}
          onCancel={handleModalClose}
          footer={[
            !labelID ? (
              <Button key="submit" type="primary" onClick={handleAddLabel}>
                Add Label
              </Button>
            ) : (
              <Button key="submit" type="primary" onClick={handleUpdateLabel}>
                Update Label
              </Button>
            ),
          ]}
        >
          <Input
            placeholder="Enter label name"
            value={labelName}
            onChange={(e) => setLabelName(e.target.value)}
          />
        </Modal>
      </div>
    </>
  );
};

export default LabelManager;
