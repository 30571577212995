import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import { chipStyles } from "./constants";
import moment from "moment";
import { setallTagdata } from "../../actions/emailActions";
import { useDispatch } from "react-redux";
// import { TAGS } from "./tagValue";
// import Tags from "./Tags";

const ApplyTagsModal = ({
  showApplyTagsModal,
  setShowApplyTagsModal,
  mail,
  setCurrentMail,
  allMails,
  setMailData,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createdTags, setCreatedTags] = useState([]);
  const [tagdata, setTagdata] = useState({
    opentagModal: false,
    newtag: "",
    id: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (mail?.["tags"]) {
      setSelectedLabels([...mail["tags"]]);
    } else {
      setSelectedLabels([]);
    }
    return () => {
      setSelectedLabels([]);
    };
  }, [mail]);


  const handleCancel = () => {
    setTagdata((prevState) => ({
      ...prevState,
      opentagModal: false,
      newtag: "",
      id: null,
    }));
  };


  const getTags = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/get-tags-by-company`;
      const response = await getAPICall(url);
      if (response.status == 1) {
        const alltags = response?.data?.map((el) => {
          let obj = {
            id: el.id,
            tags: el.tags,
          };
          return obj;
        });
        setCreatedTags([...alltags]);
        dispatch(setallTagdata([...alltags]))
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  const handleSearchChange = () => { };

  const handleLabelCheck = (event) => {
    const { value } = event.target;
    const isChecked = event.target.checked;
    if (selectedLabels.includes(value)) {
      setSelectedLabels(selectedLabels.filter((label) => label !== value));
    } else {
      setSelectedLabels([...selectedLabels, value]);
      // setSelectedLabels([value])
    }
  };
  const handleDeleteChip = async (id, tagName) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/delete-tag/${id}`;
      const response = await apiDeleteCall(url);
      if (response?.status) {
        openNotificationWithIcon("success", response?.msg);
        let remtaglist = mail?.tags?.filter((tag) => tag != tagName);
        const payload = {
          context: mail?.client,
          _id: mail?._id,
          event: "applyTag",
          tags: remtaglist,
        };
        setIsLoading(true);
        handleSocketEvent(payload);
        getTags();
      } else {
        openNotificationWithIcon("success", response?.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateTag = async () => {
    try {
      let tagid = tagdata?.id ? tagdata.id : null;
      let method = "POST";
      let url = "";
      if (tagid) {
        url = `${process.env.REACT_APP_MT_URL}accounts/update-tags/${tagid}`;
        method = "PUT";
      } else {
        url = `${process.env.REACT_APP_MT_URL}accounts/add-tags`;
      }
      const payload = {
        tags: tagdata.newtag,
        date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      };

      const response = await postAPICall(url, payload, method);
      if (response.status) {
        openNotificationWithIcon("success", response?.msg);
        getTags();
        setTagdata((prevState) => ({
          ...prevState,
          opentagModal: false,
          newtag: "",
          id: null,
        }));
      } else {
        openNotificationWithIcon("error", response?.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSocketEvent = async (payLoad) => {
    try {
      // Check if webSocketManager is available and WebSocket is open
      const webSocketManager = window?.webSocketManager;
      if (
        webSocketManager &&
        webSocketManager.socket.readyState === WebSocket.OPEN
      ) {
        // Create a unique responseId to track this request
        const responseId = Date.now();

        // Create a promise to handle the delete response
        const responsePromise = new Promise((resolve, reject) => {
          // Define a temporary event handler
          const handleResponse = (response) => {
            if (response?.content?.AcknowledgmentID) {
              openNotificationWithIcon("success", "Tags applied successfully.");
              let _currentMail = { ...mail };
              _currentMail["tags"] = [...(payLoad?.tags || [])];
              // setCurrentMail(_currentMail);
              setCurrentMail({ ...mail, tags: payLoad?.tags });
              const updatedAllMails = allMails?.map((item) => {
                if (item._id === mail?._id) {
                  return {
                    ...item,
                    tags: payLoad?.tags,
                  };
                } else {
                  return item;
                }
              });
              setMailData(updatedAllMails);
              setShowApplyTagsModal(false);
              setIsLoading(false);
            } else {
              message.error("Please try again.");
              setIsLoading(false);
            }
            resolve(response);
            // Remove the event listener after handling the response
            webSocketManager.off(payLoad?.event, handleResponse);
          };

          // Register the temporary event handler
          webSocketManager.on(payLoad?.event, handleResponse);
        });

        // Send payload with the unique responseId
        await webSocketManager.send({ ...payLoad, responseId });

        // Await the response promise
        await responsePromise;
      } else {
        setIsLoading(false);
        message.error("Please try again.");
        console.error("WebSocket is not open or not initialized.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("WebSocket error:", error);
      message.error("WebSocket error");
    }
  };

  const handleTagApply = async () => {
    // const payLoad = {
    //   GmailMessageId: mailDetails?.GmailMessageId,
    //   _id: mailDetails?._id,
    //   context: mailDetails?.client,
    //   Spam: mailDetails?.Spam ? false : true,
    //   event: "spamMail",
    // };
    const payload = {
      context: mail?.client,
      _id: mail?._id,
      event: "applyTag",
      tags: [...(selectedLabels || [])],
    };
    setIsLoading(true);
    handleSocketEvent(payload);
  };

  const TAGS = [
    "Vessel name",
    "open area",
    "open date",
    "owner",
    "dwt",
    "draft",
    "build year",
    "flag",
    "bod",
    "cranes",
    "hatches",
    "teamWork",
    "Comments",
    "Payable",
    "Receivable",
    "Invoice",
    "Invoice no",
    "Hire",
    "Bank Name",
    "Balance",
    "Agent",
    "Supplier",
    "Charterer",
    "Owner",
    "Broker",
    "PDA",
    "FDA",
    "Due date",
    "Freight Invoice",
    "Hire Invoice",
    "SOF (Statement Of Fact)",
    "NOR (Notice of Readiness)",
    "Loading Start",
    "Loading Stop",
    "Discharging Start",
    "Discharging Stop",
    "Vessel Moored",
    "Vessel Anchored",
    "Vessel Delivered",
    "Delivery Date",
    "Redelivery Date",
    "Noon Report",
    "ETA",
    "ETD",
    "Arrival",
    "Departure",
    "Date & time",
    "Bunker Invoice",
    "Port DA",
    "Port Disbursement",
    "Portcall",
    "FDA Due",
    "Survey Invoice",
    "Delivery Report",
    "Redelivery Report",
    "PFHS",
    "FHS",
    "Port agent",
    "Master",
    "IFO",
    "VLSFO",
    "ULSFO",
    "LSMGO",
    "MGO",
    "Dry Vessel",
    "Tanker Vessel",
  ];
  const Taglist = [...TAGS];
  return (
    <>
      <Modal
        title="Apply Tags"
        open={showApplyTagsModal}
        closable={true}
        onCancel={() => setShowApplyTagsModal(false)}
        footer={null}
        width={1000}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
      >
        <div style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "1rem 0",
            }}
          >
            <Input
              style={{ width: "70%" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search tags"
            />
            <div
              style={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}
            >
              <Button
                type="primary"
                onClick={handleTagApply}
              // disabled={selectedLabels.length === 0}
              >
                Apply
              </Button>
              <Button
                onClick={() => setTagdata({ ...tagdata, opentagModal: true })}
              >
                Create Tag
              </Button>
            </div>
          </div>
          <Row gutter={[16, 16]}>
            {Taglist.filter((tag) =>
              tag.toLowerCase().includes(searchTerm.toLowerCase())
            ).map((tag, index) => (
              <Col xs={12} sm={8} key={tag}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    style={{ padding: "0px" }}
                    onChange={handleLabelCheck}
                    name={tag}
                    value={tag}
                    disabled={true}
                    checked={true}
                  />
                  {/* <span style={{ paddingLeft: "8px" }}>{tag}</span> */}
                  <Tag
                    style={{
                      marginLeft: "8px",
                      backgroundColor:
                        chipStyles[index % chipStyles.length].backgroundColor,
                      color: chipStyles[index % chipStyles.length].color,
                      border: "1px solid",
                      borderColor: chipStyles[index % chipStyles.length].color,
                      fontSize: "12px",
                    }}
                  >
                    {tag}
                  </Tag>
                </div>
              </Col>
            ))}
            {createdTags
              .filter((tag) =>
                tag?.tags?.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((tag, index) => (
                <Col xs={12} sm={8} key={tag.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      style={{ padding: "0px" }}
                      onChange={handleLabelCheck}
                      name={tag.tags}
                      value={tag.tags}
                      checked={selectedLabels.includes(tag?.tags)}
                    />
                    {/* <span style={{ paddingLeft: "8px" }}>
                    {tag.tags}
                    <Tooltip title="Delete">
                      <DeleteOutlined
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => handleDeleteChip(tag.id, tag.tags)}
                      />
                    </Tooltip>
                  </span> */}
                    <Tag
                      // closable
                      // closeIcon={<CloseOutlined style={{ borderRadius:"50%", backgroundColor:"lightgrey", padding:"3px" }} />}
                      // onClose={() => handleDeleteChip(tag.id, tag.tags)}
                      style={{
                        marginLeft: "8px",
                        paddingBottom: "2px",
                        backgroundColor:
                          chipStyles[index % chipStyles.length].backgroundColor,
                        color: chipStyles[index % chipStyles.length].color,
                        border: "1px solid",
                        borderColor:
                          chipStyles[index % chipStyles.length].color,
                        fontSize: "12px",
                      }}
                    >
                      <Tooltip title="Click to edit">
                        <span
                          onClick={() =>
                            setTagdata((prevState) => ({
                              ...prevState,
                              opentagModal: true,
                              newtag: tag?.tags,
                              id: tag?.id,
                            }))
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {tag?.tags}
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <CloseOutlined
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "lightgrey",
                            padding: "4px",
                            marginLeft: "5px",
                            fontSize: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDeleteChip(tag.id, tag.tags)}
                        />
                      </Tooltip>
                    </Tag>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
        <Spin fullscreen spinning={isLoading} />
      </Modal>
      <Modal
        title="New Tag"
        visible={tagdata.opentagModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<CloseOutlined />}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: 16,
          }}
        >
          <Input
            placeholder="Enter tag name"
            value={tagdata.newtag}
            onChange={(e) =>
              setTagdata((prevState) => ({
                ...prevState,
                newtag: e.target.value,
              }))
            }
          />
          <Button
            type="primary"
            onClick={handleCreateTag}
            style={{ marginLeft: 8 }}
          >
            {tagdata?.id !== null ? "Update" : "Create"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ApplyTagsModal;
