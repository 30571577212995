import React from 'react';
import { Row, Col, Input, DatePicker } from 'antd';

const QuickVessel = (props) => {
    console.log({ props })
    const { quickVessel: data } = props.data;
    const { quickCon: contactdata } = props?.data;
    const type = props?.type
    const { handleChange } = props;


    return (
        <>{
            type == 'vessel' ?
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="Vessel name"
                            value={data.vessel_name}
                            onChange={(e) => props.handleShortName(e.target.value, 'vessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="Vessel Code"
                            value={data.vessel_code}
                            disabled
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="IMO No."
                            value={data.imo_no}
                            onChange={(e) => props.handleChange(e.target.value, 'imo_no', 'quickVessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="Summer DWT"
                            value={data.summer_dwt}
                            onChange={(e) => props.handleChange(e.target.value, 'summer_dwt', 'quickVessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="GRT"
                            value={data.GRT}
                            onChange={(e) => props.handleChange(e.target.value, 'GRT', 'quickVessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="NRT"
                            value={data.NRT}
                            onChange={(e) => props.handleChange(e.target.value, 'NRT', 'quickVessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="LOA"
                            value={data.LOA}
                            onChange={(e) => props.handleChange(e.target.value, 'LOA', 'quickVessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            size="small"
                            placeholder="Vessel Type"
                            value={data.TPC}
                            onChange={(e) => props.handleChange(e.target.value, 'TPC', 'quickVessel')}
                        />
                    </Col>
                    <Col span={8}>
                        <DatePicker
                            picker="year"
                            style={{ width: '100%' }}
                            // value={data.built_year || null}
                            // onChange={(date) => props.handleYearChange(date)}
                            placeholder="Built (Year)"
                        />
                    </Col>
                </Row>
                : <>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Input
                                size="small"
                                placeholder="Company Name"
                                value={contactdata.company_name}
                                onChange={(e) => handleChange(e.target.value, 'company_name', 'quickCon')}
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                size="small"
                                placeholder="Person Name (PIC)"
                                value={contactdata.person_name}
                                onChange={(e) => handleChange(e.target.value, 'person_name', 'quickCon')}
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                size="small"
                                placeholder="Company Short Name"
                                value={contactdata.company_short_name}
                                disabled
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                size="small"
                                placeholder="Email Id"
                                value={contactdata.email_id}
                                onChange={(e) => handleChange(e.target.value, 'email_id', 'quickCon')}
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                size="small"
                                placeholder="Skype Id"
                                value={contactdata.skype_id}
                                onChange={(e) => handleChange(e.target.value, 'skype_id', 'quickCon')}
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                country={'us'}
                                value={contactdata.mobile_number}
                                onChange={(value) => handleChange(value, 'mobile_number', 'quickCon')}
                                inputStyle={{
                                    width: '100%',
                                    fontSize: '14px',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    borderColor: '#d9d9d9',
                                }}
                                placeholder="000-000-000"
                            />
                        </Col>
                    </Row>
                </>}
        </>
    );
};

export default QuickVessel;
