import React, { Component } from "react";
import { Upload, Modal, Spin, message, Alert } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import { uploadFile } from 'react-s3';
import URL_WITH_VERSION, {
  openNotificationWithIcon,
  postAPICallFormData,
} from "../../index";

class Attachment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadType: this.props.uploadType || "",
      // directory: this.props.directory || "",
      fileList: this.props.fileList || [],
      previewVisible: false,
      uploading: false,
      previewImage: undefined,
      fileUploadedList: [],
      uploadedFiles: this.props.onCloseUploadFileArray,
      showButtons: this.props.showButtons || true,
      attachments: props.attachments,
      tableId: props.tableId,
      singleSelection: this.props.singleSelection,
    };
  }

  // componentDidUpdate(prevProps) {
  //   console.log('@@@ Here', this.state.fileList);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tableId !== this.props.tableId) this.assembleAttachments();
  }

  componentDidMount = () => {
    this.assembleAttachments();
  };

  // componentDidMount = async () => {
  //   console.log('@@@@@@');
  //   if (this.state.) {
  //     const files = this.state.fileList;
  //     files.push(this.state.attachments.share_link);
  //     this.setState({ ...this.state, fileList: [...files] })
  //   }
  //   // let folderName = ["Attachments", this.state.uploadType, this.state.directory].join('::');
  //   // const response = await awaitPostAPICall(`${URL_WITH_VERSION}/s3/list`, { "bucket_name": folderName });
  //   // const respData = await response['data']
  //   // if (respData === false) {
  //   //   folderName = ["Attachments", this.state.uploadType].join('::');
  //   // }
  // }

  handleChange = ({ fileList }) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const filteredFileList = fileList.filter(
      (file) => !file.size || file.size <= maxSizeInBytes
    );
    this.setState({ fileList: filteredFileList });
  };

  handleCancel = () => {
    this.setState({
      fileUploadedList: [],
      fileList: [],
      previewVisible: false,
    });
  };

  assembleAttachments = (rowindex) => {
    const { attachments, singleSelection } = this.state;
    if (Array.isArray(attachments) && attachments.length > 0) {
      let files = [];
      attachments.forEach((item, index) => {
        files.push({
          uid: item.id || index,
          name: item.attachment,
          status: "done",
          url: item.share_link,
        });
      });

      if (singleSelection) files = files.slice(0, 1);
      this.setState({ ...this.state, fileList: [...files] });
    }
  };

  handleUpload = async (file) => {
    this.setState({ ...this.state, uploading: true });
    const formData = new FormData();
    const { fileList, fileUploadedList, uploadType } = this.state;
    formData.append("user_file", file);

    try {
      const response = await postAPICallFormData(
        `${process.env.REACT_APP_ATTACHMENT}/s3/upload`,
        formData
      );
      if (response) {
        openNotificationWithIcon("success", "Uploaded successfully", 3);
        this.setState({ ...this.state, uploading: false });
        const existing = fileUploadedList;
        const uploaded = {
          fileName: file.name,
          url: response["data"],
          contentType: file.type,
        };
        fileList[fileList.length - 1].url = response["data"];
        existing.push(uploaded);
        this.setState(
          { ...this.state, fileUploadedList: existing, uploading: false },
          () => {
            if (
              uploadType === "vessel" ||
              uploadType === "bankattachment" ||
              uploadType === "contactattachment"
            ) {
              this.state.uploadedFiles(uploaded);
            } else {
              this.state.uploadedFiles(fileUploadedList);
            }
          }
        );
      } else {
        openNotificationWithIcon(
          "info",
          "Sorry, Files Can't be uploaded.Please try again after sometime.",
          3
        );
      }
    } catch (err) {
      openNotificationWithIcon("error", "something went wrong.", 3);
      this.setState({ ...this.state, uploading: false });
    }
  };

  onDelete = (item) => {
    const data = this.state.fileList.filter(
      (element) => element.url != item.url
    );

    //this.setState({...this.state,fileList:data})

    this.props.deleteAttachment(item);
    if (this.props.uploadType === "Letter Head") {
      this.props.onDeleteAttachment();
    }
  };
  // onDelete = (item) => {
  //   const data = this.state.fileList.find(
  //     (element) => element.url == item.url
  //   );
  //   this.props.deleteAttachment(item);
  // };

  beforeUpload = (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      message.error("File size exceeds 10 MB limit.", 3);
      return false;
    }
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));

    setTimeout(() => {
      // const { uploadType, fileUploadedList, uploadedFiles } = this.state;
      // const formData = new FormData();
      // formData.append('file', file);
      // formData.append('bucket', ['OSERP', 'Attachments', uploadType, directory].join('::'));
      this.handleUpload(file);
      // this.setState({ ...this.state, uploading: true });
    }, 10);

    return false;
  };

  render() {
    const {
      fileList,
      previewVisible,
      previewImage,
      singleSelection,
      uploading,
    } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <>
        {!uploading ? (
          <div className="clearfix">
            <Upload
              // type="file"
              // accept=".jpg, .jpeg, .png,.pdf,"
              listType="picture-card"
              fileList={fileList}
              onChange={this.handleChange}
              beforeUpload={this.beforeUpload}
              onRemove={this.onDelete}
            >
              {fileList.length >= 8 ||
              (singleSelection && fileList.length === 1)
                ? null
                : uploadButton}
            </Upload>
            <Modal
              open={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
        ) : (
          <div className="col col-lg-12">
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          </div>
        )}
      </>
    );
  }
}

export default Attachment;
