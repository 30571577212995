import React, { useEffect, useState } from "react";

export const VoyageEstimateCalcArr = [
  {
    title: "Freight",
    formula: "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum",
  },
  {
    title: "Freight Commission",
    formula: "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum",
  },
  {
    title: "Misc. Revenue",
    formula: "Sum of (Extra Rev)",
  },
  {
    title: "Demurrage Amt",
    formula: "Sum of (Demurrage in Port Itinerary)",
  },
  {
    title: "Demm Comm Amt",
    formula: "Sum of (Total Demurrage* B.comm%)",
  },
  {
    title: "Despatch Amt",
    formula: "Sum of (Despatch in Port Itinerary)",
  },
  {
    title: "Gross Revenue",
    formula: "Freight + Misc. Revenue + Despatch - Demurrage",
  },

  {
    title: "Net Revenue",
    formula: "Gross Revenue - Freight Commission - Demm Comm Amt",
  },

  {
    title: "Revenue",
    formula: `Gross Revenue  - Freight Commission - Demm Comm Amt`,
  },

  {
    title: "Expenses",
    formula: "Gross. Expenses - (TCI Address Commission + TCI Bro Commission)",
  },

  {
    title: "Vessel expenses",
    formula: "TCI Hire Rate * Total Voyage Days",
  },

  {
    title: "TCI Address Commission",
    formula: "Vessel Expenses *Add Comm.%",
  },

  {
    title: "TCI Bro Commission",
    formula: "Vessel Expenses *Broker Comm.%",
  },

  {
    title: "Port Expenses",
    formula: "Sum of (Port Expense in Port Itinerary)",
  },

  {
    title: "Emmission Expenses",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "Misc. Expenses",
    formula: "(Sum of Othercost) + Ballast Bonus",
  },

  //Bunker Expenses

  {
    title: "Bunker Expenses",
    formula:
      "Sea Consp Non ECA + Sea Consp ECA + Port Consp +TC Bunker Adjustment",
  },

  {
    title: "Gross Expenses",
    formula:
      "Vessel Expenses + Misc. Expenses + Port Expenses + Bunker Expenses +Emmission Expenses",
  },

  {
    title: "Net Expenses",
    formula: "Gross. Expenses - (TCI Address Commission + TCI Bro Commission)",
  },

  {
    title: "Voyage Result",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Profit (Loss)",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue - Net Expenses)/Total Voyage Days ",
  },

  {
    title: "TCE Hire ( Net Daily )",
    formula:
      "(Net Revenue - Gross Expenses + Vessel Expenses + Misc. Expenses + TCI Add. Comm.) / Total Voyage Days",
  },

  {
    title: "Gross TCE",
    formula: "TCE Hire/(1-Add Comm.%)",
  },

  {
    title: "Average Freight rate ($/T)",
    formula: "Net Expenses/ sum of (Cp qty + cp qty* opt %)",
  },

  {
    title: "Breakeven & Freight rate ($/T)",
    formula: "Net Expenses/ sum of (Cp qty + cp qty* opt %)",
  },

  // Co2 Cost

  {
    title: "Co2 Cost",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "CO2 Adjusted Profit (Loss)",
    formula: "Profit (Loss)- Co2 Cost",
  },

  {
    title: "CO2 Adjusted (Net) TCE",
    formula: "CO2 Adjusted Profit (Loss)/ Total Voyage Days",
  },
  {
    title: "Sea Consp Non ECA",
    formula: "Sum of(IFO + MGO + LSMGO + VLSFO + ULSFO)",
  },
  {
    title: "IFO",
    formula: "Sum of(non eca sea consp of IFO) * cp$",
  },
  {
    title: "Sea Consp ECA",
    formula: "Sum of(eca sea consp of all five fuels)",
  },
  {
    title: "Port Consp",
    formula: "Sum of(eca port consp of all five fuels)",
  },
];

const TcovVmPnl = ({ desc, tooltipData, title, formData }) => {
  const [caluculation, setCalculation] = useState("");

  useEffect(() => {
    if (title === "Revenue") {
      setCalculation(
        `${
          tooltipData?.grossRevenue
        } - ${tooltipData?.freightCommission?.toFixed(
          2
        )} - ${tooltipData?.dem_com_amt?.toFixed(
          2
        )} = ${tooltipData.netRevenue?.toFixed(2)}`
      );
    } else if (title == "Freight") {
      const freightCalculations = formData?.cargos?.map((cargo) => {
        const cpQty = parseFloat(cargo.cp_qty) || 0;
        const optPercentage = parseFloat(cargo.opt_percentage) || 0;
        const fratRate = parseFloat(cargo.frat_rate) || 0;
        const worldScale = parseFloat(cargo.world_scale) || 0;
        const lumsum = parseFloat(cargo.lumsum) || 0;

        let calculationDetails;

        if (cargo.frt_type == "38") {
          // Calculation using `frat_rate`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * fratRate;
          calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${fratRate} = ${freightValue.toFixed(
            2
          )} -- Flat Rate`;
        } else if (cargo.frt_type == "259") {
          // Calculation using `world_scale`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * worldScale;
          calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${worldScale} = ${freightValue.toFixed(
            2
          )}  -- World Scale`;
        } else {
          // Use `lumsum` directly
          calculationDetails = `${lumsum.toFixed(2)}  -- Lumpsum`;
        }

        return `${calculationDetails}`;
      });
      freightCalculations.push(`Total Freight = ${tooltipData?.fr}`);
      setCalculation(freightCalculations.join("\n"));
    } else if (title == "Freight Commission") {
      const freightCalculations = formData?.cargos?.map((cargo) => {
        const cpQty = parseFloat(cargo.cp_qty) || 0;
        const optPercentage = parseFloat(cargo.opt_percentage) || 0;
        const fratRate = parseFloat(cargo.frat_rate) || 0;
        const worldScale = parseFloat(cargo.world_scale) || 0;
        const lumsum = parseFloat(cargo.lumsum) || 0;
        let bComm = parseFloat(cargo.b_commission) || 0;

        let calculationDetails;

        if (cargo.frt_type == "38") {
          // Calculation using `frat_rate`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * fratRate;
          const frRow = (freightValue * bComm) / 100;
          calculationDetails = `(${freightValue} % ${bComm} = ${frRow.toFixed(
            2
          )} -- Flat Rate`;
        } else if (cargo.frt_type == "259") {
          // Calculation using `world_scale`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * worldScale;
          const frRow = (freightValue * bComm) / 100;
          calculationDetails = `(${freightValue} % ${bComm} } = ${frRow.toFixed(
            2
          )}  -- World Scale`;
        } else {
          // Use `lumsum` directly
          calculationDetails = `${
            (lumsum.toFixed(2) * bComm) / 100
          }  -- Lumpsum`;
        }

        return `${calculationDetails}`;
      });
      freightCalculations.push(
        `Total Freight Commission = ${tooltipData?.freightCommission}`
      );
      setCalculation(freightCalculations.join("\n"));
    } else if (title == "Misc. Revenue") {
      const miscreveCalculations = formData?.cargos?.map((ele) => {
        const extra_rev = parseFloat(ele?.extra_rev) || 0;
        const calculationDetails = `${extra_rev}`;

        return calculationDetails;
      });
      setCalculation(
        `${miscreveCalculations.join(" + ")} = ${tooltipData?.mr}`
      );
    } else if (title == "Demurrage Amt") {
      let demmCalculations;

      // Filter for entries with `dem_disp` equal to "Demmurage" and then map to get the amount
      demmCalculations = formData?.portitinerary
        .filter((ele) => ele?.dem_disp === "Demmurage")
        .map((ele) => {
          const demmAmt = parseFloat(ele?.dem_disp_amt) || 0;
          return `${demmAmt}`;
        });

      setCalculation(
        demmCalculations.length > 1
          ? `${demmCalculations.join(" + ")} = ${
              tooltipData?.demmurage_amt * -1
            }`
          : `${demmCalculations[0] || 0} = ${tooltipData?.demmurage_amt * -1}`
      );
    } else if (title == "Demm Comm Amt") {
      const demmCalculations = formData?.portitinerary
        .filter((ele) => ele?.dem_disp === "Demmurage")
        .reduce((total, ele) => {
          const demmAmt = parseFloat(ele?.dem_disp_amt) || 0;
          return total + demmAmt;
        }, 0);

      const total2 = formData?.cargos?.map((ele) => {
        const calculationDetails = `${demmCalculations} * ${ele?.b_commission}`;
        return calculationDetails;
      });
      setCalculation(
        `${total2.join(" + ")} = ${tooltipData?.dem_com_amt?.toFixed(2)}`
      );
    } else if (title == "Despatch Amt") {
      let demmCalculations;

      // Filter for entries with `dem_disp` equal to "Demmurage" and then map to get the amount
      demmCalculations = formData?.portitinerary
        .filter((ele) => ele?.dem_disp === "Despatch")
        .map((ele) => {
          const demmAmt = parseFloat(ele?.dem_disp_amt) || 0;
          return `${demmAmt}`;
        });

      setCalculation(
        demmCalculations.length > 1
          ? `${demmCalculations.join(" + ")} = ${tooltipData?.desmurage_amt}`
          : `${demmCalculations[0] || 0} = ${tooltipData?.desmurage_amt}`
      );
    } else if (title == "Gross Revenue") {
      setCalculation(
        `${tooltipData?.fr} + ${tooltipData?.mr} + ${tooltipData?.desmurage_amt} + ${tooltipData?.demmurage_amt} = ${tooltipData?.grossRevenue}`
      );
    } else if (title == "Net Revenue") {
      setCalculation(
        `${tooltipData?.grossRevenue} - ${
          tooltipData?.freightCommission
        } - ${tooltipData?.dem_com_amt?.toFixed(
          2
        )} = ${tooltipData?.netRevenue?.toFixed(2)}`
      );
    } else if (title == "Expenses") {
      setCalculation(
        `${
          tooltipData?.grossExpenses
        } - (${tooltipData?.amt_add_percentage?.toFixed(2)} + ${
          tooltipData?.amt_bro_percentage
        }) = ${tooltipData?.netExpenses}`
      );
    } else if (title == "Vessel expenses") {
      setCalculation(
        `${tooltipData?.hire} * ${tooltipData?.totalVoyageDays} = ${tooltipData?.VesselExpense}`
      );
    } else if (title == "TCI Address Commission") {
      setCalculation(
        `(${tooltipData?.VesselExpense} * ${
          tooltipData?.addPercentage
        })/100 = ${tooltipData?.amt_add_percentage?.toFixed(2)}`
      );
    } else if (title == "TCI Bro Commission") {
      setCalculation(
        `(${tooltipData?.VesselExpense} * ${tooltipData?.broPercentage})/100 = ${tooltipData?.amt_bro_percentage}`
      );
    } else if (title == "Port Expenses") {
      const portExpenses = formData?.portitinerary?.map((ele) => {
        let p_exp = parseFloat(ele?.p_exp) || 0;
        return `${p_exp}`;
      });
      setCalculation(
        portExpenses.length > 1
          ? `${portExpenses.join(" + ")} = ${tooltipData?.pi}`
          : `${portExpenses[0]} = ${tooltipData?.pi}`
      );
    } else if (title == "Emmission Expenses" || title == "Co2 Cost") {
      let co2Price = parseFloat(formData["-----"]?.co2_price_per);
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      const calculationDetails = formData?.euets?.map((ele) => {
        return `${ele.ttl_eu_ets}`;
      });
      setCalculation(
        `(${calculationDetails.join(" + ")}) * ${co2Price} = ${total}`
      );
    } else if (title == "Misc. Expenses") {
      const otherCost = parseFloat(formData?.other_cost);
      const bb = parseFloat(formData?.bb);
      const total = otherCost + bb;
      setCalculation(`${otherCost} + ${bb} = ${total}`);
    } else if (title == "Bunker Expenses") {
      setCalculation(
        `${tooltipData?.totalecaSeacons} + ${tooltipData?.totalnonEcaSeacons} + ${tooltipData?.totalecaPortCons} + ${tooltipData?.totalnonecaPortcons} = ${tooltipData?.bunkerExpense}`
      );
    } else if (title == "Gross Expenses") {
      const otherCost = parseFloat(formData?.other_cost);
      const bb = parseFloat(formData?.bb);
      const total1 = otherCost + bb;
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      setCalculation(
        `${tooltipData?.VesselExpense} + ${total1} + ${tooltipData?.pi} + ${total} = ${tooltipData?.grossExpenses}`
      );
    } else if (title == "Net Expenses") {
      setCalculation(
        `${
          tooltipData?.grossExpenses
        } - (${tooltipData?.amt_add_percentage?.toFixed(
          2
        )} + ${tooltipData?.amt_bro_percentage?.toFixed(2)}) = ${
          tooltipData?.netExpenses
        }`
      );
    } else if (title == "Voyage Result") {
      setCalculation(
        `${tooltipData?.netRevenue?.toFixed(2)} - ${
          tooltipData?.netExpenses
        } = ${tooltipData?.itemValue?.toFixed(2)}`
      );
    } else if (title == "Profit (Loss)") {
      setCalculation(
        `${tooltipData?.netRevenue?.toFixed(
          2
        )} - ${tooltipData?.netExpenses?.toFixed(
          2
        )} = ${tooltipData?.itemValue?.toFixed(2)}`
      );
    } else if (title == "Daily Profit (Loss)") {
      let total =
        (tooltipData?.netRevenue - tooltipData?.netExpenses) /
        tooltipData?.totalVoyageDays;
      setCalculation(
        `(${tooltipData?.netRevenue?.toFixed(
          2
        )} - ${tooltipData?.netExpenses?.toFixed(2)})/${
          tooltipData?.totalVoyageDays
        } = ${total?.toFixed(2)}`
      );
    } else if (title == "TCE Hire ( Net Daily )") {
      const otherCost = parseFloat(formData?.other_cost);
      const bb = parseFloat(formData?.bb);
      const total = otherCost + bb;
      setCalculation(
        `(${tooltipData?.netRevenue?.toFixed(2)} - ${
          tooltipData?.grossExpenses
        } + ${
          tooltipData?.VesselExpense
        } + ${total} - ${tooltipData?.amt_add_percentage?.toFixed(2)})/${
          tooltipData?.totalVoyageDays
        } = ${tooltipData?.tceHire}`
      );
    } else if (title == "Gross TCE") {
      let total =
        tooltipData?.tceHire / (1 - tooltipData?.addPercentage * 0.01);
      setCalculation(
        `${tooltipData?.tceHire}/(1-${
          tooltipData?.addPercentage
        }%) = ${total?.toFixed(2)}`
      );
    } else if (
      title == "Average Freight rate ($/T)" ||
      title == "Breakeven & Freight rate ($/T)"
    ) {
      const break_even_frtrate = (
        (tooltipData?.netExpenses / tooltipData?.cpQtyOpt) *
        1
      ).toFixed(2);
      const calculationDetails = formData?.cargos?.map((ele) => {
        return `(${ele.cp_qty} + ${ele.cp_qty} * ${ele.opt_percentage})`;
      });
      setCalculation(
        `${tooltipData?.netExpenses}/${calculationDetails.join(
          " + "
        )} = ${break_even_frtrate}`
      );
    } else if (title == "CO2 Adjusted Profit (Loss)") {
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      setCalculation(
        `${tooltipData?.itemValue?.toFixed(2)} - ${total?.toFixed(
          2
        )} = ${tooltipData?.co2_adjusted_profit?.toFixed(2)}`
      );
    } else if (title == "CO2 Adjusted (Net) TCE") {
      setCalculation(
        `${tooltipData?.co2_adjusted_profit?.toFixed(2)}/${
          tooltipData?.totalVoyageDays
        } = ${tooltipData?.co2_adjusted_tce?.toFixed(2)}`
      );
    } else if (title == "Sea Consp Non ECA") {
      // console.log("data56", tooltipData?.nonEcaSeacons);
      const data = tooltipData?.nonEcaSeacons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonEcaSeacons}`
      );
    } else if (title === "IFO") {
      const data = formData?.["."]?.filter((ele) => ele.fuel_code === "IFO");
      const cpPrice = data?.[0]?.cp_price || 0; // Assume cp_price is in the first object or default to 0

      // Sum up all 'ifo' values from bunkerdetails
      const totalIfo = formData?.bunkerdetails?.reduce((acc, item) => {
        return acc + parseFloat(item.ifo || "0"); // Parse each ifo value as a float and add it to accumulator
      }, 0);

      // Calculate the final result
      const calculation = totalIfo * cpPrice;
      setCalculation(`${totalIfo} * ${cpPrice} = ${calculation}`);
    } else if (title == "Sea Consp ECA") {
      const data = tooltipData?.ecaSeacons;
      setCalculation(
        `${data?.ifo} + ${data.mgo} + ${data.vlsfo} + ${data.ulsfo} + ${data.lsmgo} = ${tooltipData?.totalecaSeacons}`
      );
    } else if (title == "Port Consp") {
      const data = tooltipData?.nonecaPortcons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonecaPortcons}`
      );
    }
  }, [title]);

  return (
    <>
      <div style={{ top: 100 }}>
        {/* <p>{desc}</p> */}
        <p>Actual Data</p>
        <pre>{caluculation}</pre>
      </div>
    </>
  );
};

export default TcovVmPnl;
