import React, { useEffect, useMemo, useState } from "react";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import ReactEcharts from "echarts-for-react";
import { DatePicker, Modal, Select, Spin } from "antd";
import dayjs from "dayjs";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FixedSizeList as List } from "react-window";

const Row = ({ index, style, data }) => (
  <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
    {data?.map((item, i) => (
      <span>{item}</span>
    ))}
  </div>
);

const CargoAnalysis = ({ type }) => {
  const [options, setOptions] = useState({});
  const [data, setData] = useState({});
  const [predictionData, setPredictionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [cargoNames, setCargoNames] = useState([]);
  const [dropDownState, setDropdownState] = useState({
    cargoName: [],
    cargoType: [],
    loadPort: [],
  });
  const [selectedCargo, setSelectedCargo] = useState(null);
  const [selectedCargoType, setSelectedCargoType] = useState(null);
  const [selectedLoadPort, setSelectedLoadPort] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const [showVesselListModal, setShowVesselListModal] = useState(false);
  const [selections, setSelections] = useState({
    cargoName: null,
    cargoType: null,
    loadPort: null,
    laycan: null,
  });

  const getGraphData = async () => {
    const token = localStorage.getItem("oceanToken");
    try {
      let url = `https://aiml.azurewebsites.net/plot_data`;
      let payload = {};
      setIsLoading(true);
      if (type == "global") {
        payload = {
          type: "cargo",
          cargo_name: selections.cargoName || "",
          cargo_type: selections.cargoType || "",
          load_port: selections.loadPort || "",
          laycan: selections.laycan || "",
          client: "theoceann",
        };
      } else {
        payload = {
          type: "cargo",
          cargo_name: selections.cargoName || "",
          cargo_type: selections.cargoType || "",
          load_port: selections.loadPort || "",
          laycan: selections.laycan || "",
          token: token,
        };
      }

      const response = await postAPICall(url, payload, "post");
      if (response?.status) {
        setData(response.data);
        setPredictionData(response?.predictions);
        setIsLoading(false);
      }else{
        setIsLoading(false);
        setData({});
        // openNotificationWithIcon("info", "Data not available.")
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getDropdownData = async () => {
    const token = localStorage.getItem("oceanToken");
    try {
      let url = `https://aiml.azurewebsites.net/plot_data_filter`;
      let payload = {};

      if (type == "global") {
        payload = {
          type: "cargo",
          client: "theoceann",
        };
      } else {
        payload = {
          type: "cargo",
          token: token,
        };
      }

      const response = await postAPICall(url, payload, "post");
      if (response?.status) {
        setDropdownState((prev) => ({
          ...prev,
          cargoName: response?.cargo,
          cargoType: response?.cargo_types,
          loadPort: response?.ports,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };


  // async function getDropdownData() {
  //   const token = (localStorage.getItem('oceanMailToken'))
  //   try {
  //     let url = `https://aiml.azurewebsites.net/plot_data_filter`
  //     const isToken = true
  //     let payload = {}

  //     if (type == 'global') {
  //       payload = {
  //         type: 'tonnage',
  //         client: 'theoceann'
  //       }
  //     } else {
  //       payload = {
  //         type: 'tonnage',
  //         token:token
  //       }
  //     }
  //     const response = await postApiService({ url, isToken, payload, showMessage: false })
  //     console.log('response', response)
  //     const responseData = response.data
  //     setDropdownState(prev => ({
  //       ...prev,
  //       vesselData: responseData?.vessel_names,
  //       openAreaData: responseData?.ports,
  //       vesselTypeData: responseData?.vessel_types,
  //       vesselSubTypeData: responseData?.sub_vessel_types
  //     }))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    getGraphData();
  }, [selections]);

  useEffect(() => {
    getDropdownData();

    return () => {
      setSelections({
        cargoName: null,
        cargoType: null,
        loadPort: null,
        laycan: null
      });
    };
  }, []);

  const dates = useMemo(() => (data ? Object.keys(data) : []), [data]);

  const cargoBins = useMemo(() => {
    if (!data) return [];
    const allBins = dates.flatMap((date) => Object.keys(data[date]));
    return Array.from(new Set(allBins));
  }, [dates, data]);

  const seriesData = useMemo(() => {
    if (!data) return [];
    return cargoBins.map((bin) => ({
      name: bin,
      type: "bar",
      stack: "total",
      data: dates.map((date) => {
        // If the bin exists for the current date, sum the cargo size values for all cargos in that bin
        if (data[date] && data[date][bin]) {
          return data[date][bin].reduce(
            (sum, cargo) => sum + cargo.cargo_size,
            0
          );
        }
        return 0; // Return 0 if no cargos exist in this bin for the date
      }),
    }));
  }, [cargoBins, dates, data]);

  const vesselCounts = useMemo(() => {
    if (!data) return [];
    return dates.map((date) => {
      return cargoBins.reduce(
        (count, bin) => count + (data[date][bin]?.length || 0),
        0
      );
    });
  }, [dates, cargoBins, data]);

  const cumulativeData = useMemo(() => {
    if (!data) return [];
    return dates.map((date) => {
      return cargoBins.reduce((total, bin) => {
        if (data[date][bin]) {
          return (
            total +
            data[date][bin].reduce((sum, cargo) => sum + cargo.cargo_size, 0)
          );
        }
        return total;
      }, 0);
    });
  }, [dates, cargoBins, data]);

  useEffect(() => {
    if (seriesData.length === 0) return;

    // Add a line series for the cumulative sum
    const updatedSeriesData = [
      ...seriesData,
      {
        name: "Cumulative Line",
        type: "line",
        data: cumulativeData,
        smooth: false,
      },
    ];

    let allDates = [...dates];

    // Conditionally add predictions if predictionData exists
    if (predictionData && Object.keys(predictionData).length > 0) {
      const predictionDates = Object.keys(predictionData);
      const predictionValues = Object.values(predictionData);

      updatedSeriesData.push({
        name: "Predictions",
        type: "line",
        data: [...new Array(dates.length).fill(null), ...predictionValues],
        lineStyle: {
          color: "#FF0000",
          type: "dashed",
        },
        smooth: true,
      });

      allDates = [...dates, ...predictionDates];
    }

    // ECharts option
    const chartOptions = {
      tooltip: {
        trigger: "axis",
        triggerOn: "mousemove",
        enterable: true,
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          const linkElement = document.createElement("div");
          linkElement.id = "custom-tooltip";
          linkElement.innerText = "See All Cargos";
          linkElement.style.cursor = "pointer"; // Change cursor to pointer
          linkElement.style.color = "blue";

          // Initialize tooltip text
          let tooltipText = `<div style="text-align: left;">${linkElement.outerHTML}</div>`;
          const date = params[0].axisValue; // Extract the date from the axis value
          tooltipText += `<strong>${date}</strong><br/>`; // Make date bold

          setCargoNames([]); // Reset cargoNames before updating it

          // Display the ranges and their corresponding values
          params.forEach((item) => {
            tooltipText += `${item.marker} ${item.seriesName}: ${item.value}<br/>`;

            // Collect vessels for the specific DWT range (only for the clicked bar)
            const cargosInBin =
              data[date] && data[date][item.seriesName]
                ? data[date][item.seriesName]
                : [];

            if (cargosInBin.length > 0) {
              // Update the vessel names state with only the vessels of the clicked bin
              setCargoNames(cargosInBin.map((cargo) => cargo.cargo));
            }
          });

          return tooltipText;
        },
      },
      legend: {
        data: cargoBins,
      },
      xAxis: {
        type: "category",
        data: allDates,
        axisLabel: {
          formatter: (value) => {
            const date = new Date(value);
            const options = { day: "numeric", month: "short" };
            return date.toLocaleDateString("en-GB", options).replace(".", "");
          },
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        name: "Sum of Cargo size",
        axisLabel: {
          formatter: (value) => {
            return (value / 100).toFixed(0) + " MT";
          },
        },
      },
      series: updatedSeriesData,
    };

    setOptions(chartOptions);
  }, [
    seriesData,
    cumulativeData,
    dates,
    cargoBins,
    vesselCounts,
    predictionData,
    setOptions,
    setCargoNames,
  ]);

  const openVesselModal = () => {
    setShowVesselListModal(true);
  };

  const tooltipss = document.getElementById("custom-tooltip");
  if (tooltipss) {
    tooltipss.addEventListener("click", openVesselModal);
  }

  // const handleChange = (type, value) => {
  //   switch (type) {
  //     case "vessel":
  //       setSelectedVessel(value);
  //       break;
  //     case "openArea":
  //       setSelectedOpenAreas(value);
  //       break;
  //     case "vesselType":
  //       setSelectedVesselTypes(value);
  //       break;
  //     case "vesselSubType":
  //       setSelectedVesselSubTypes(value);
  //       break;
  //     case "dateRange":
  //       setSelectedDateRange(value);
  //       break;

  //     default:
  //       break;
  //   }

  //   // Here you can call your API with the selected values
  // };

  const handleChange = (type, value) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [type]: value,
    }));
  };

  return (
    <>
      <div style={{ minHeight: "400px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            padding: "10px 5px",
          }}
        >
          <div style={{ color: "#8080c5", fontSize: "28px" }}>
            <Icon icon="mingcute:filter-fill" />
          </div>
          <div>
            <Select
              style={{ width: "200px" }}
              placeholder="Cargo Name"
              onChange={(value) => handleChange("cargoName", value)}
              optionLabelProp="label"
              allowClear
              showSearch
              filterOption={(input, option) =>
                typeof option?.value === "string" &&
                option.value.toLowerCase().includes(input.toLowerCase())
              }
              // size='small'
            >
              {dropDownState?.cargoName?.map((vessel) => (
                <Select.Option key={vessel.trim()} value={vessel.trim()}>
                  {vessel.trim()}
                </Select.Option>
              )) || []}
            </Select>
          </div>
          <div>
            <Select
              style={{ width: "200px" }}
              placeholder="Cargo Type"
              onChange={(value) => handleChange("cargoType", value)}
              optionLabelProp="label"
              allowClear
              showSearch
              filterOption={(input, option) =>
                typeof option?.value === "string" &&
                option.value.toLowerCase().includes(input.toLowerCase())
              }
              // size='small'
            >
              {dropDownState?.cargoType?.map((vessel) => (
                <Select.Option key={vessel.trim()} value={vessel.trim()}>
                  {vessel.trim()}
                </Select.Option>
              )) || []}
            </Select>
          </div>
          <div>
            <Select
              style={{ width: "200px" }}
              placeholder="Load port"
              onChange={(value) => handleChange("loadPort", value)}
              optionLabelProp="label"
              allowClear
              showSearch
              filterOption={(input, option) =>
                typeof option?.value === "string" &&
                option.value.toLowerCase().includes(input.toLowerCase())
              }
              // size='small'
            >
              {dropDownState?.loadPort?.map((subType) => (
                <Select.Option key={subType.trim()} value={subType.trim()}>
                  {subType.trim()}
                </Select.Option>
              )) || []}
            </Select>
          </div>
          <div>
            <DatePicker.RangePicker
              style={{ width: "200px" }}
              onChange={(dates) => {
                if (dates) {
                  const formattedDates = `${dayjs(dates[0]).format(
                    "YYYY-MM-DD"
                  )}/${dayjs(dates[1]).format("YYYY-MM-DD")}`;
                  handleChange("laycan", formattedDates);
                } else {
                  handleChange("laycan", null);
                }
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <div style={{ marginTop: "10%" }}>
            <Spin tip="Analyzing..." spinning={isLoading} size="large">
              <div></div>
            </Spin>
          </div>
        ) : Object.keys(data).length > 0 ? (
          <ReactEcharts
            option={options}
            style={{ height: "400px", width: "100%" }}
          />
        ) : (
          <div style={{textAlign:'center', marginTop:"10%"}}>No data available</div> // Optionally, you can render a message or nothing here
        )}
      </div>
      <Modal
        // centered
        title={
          <div>
            Cargo List{" "}
            <span style={{ fontStyle: "italic" }}>
              (Total Cargos: {cargoNames?.length})
            </span>
          </div>
        }
        open={showVesselListModal}
        closable={true}
        onCancel={() => setShowVesselListModal(false)}
        footer={null}
        // width={"85vw"}
        wrapClassName=""
        style={{ top: "20px" }}
      >
        {/* <div style={{ maxHeight: "500px", overflowY: "auto" }}> */}
        {/* <CargoAnalysis /> */}
        {/* </div> */}
        {/* <div>
          
          <List
            className="List"
            height={400}
            itemCount={cargoNames?.length}
            itemSize={35}
            width={300}
          >
            {({ index, style }) => (
              <Row index={index} style={style} data={cargoNames} />
            )}
          </List>
        </div> */}
        <div style={{ overflowY: "auto" }}>
          {cargoNames?.map((item, index) => (
            <div
              style={{
                display: "inline-flex",
                textTransform: "capitalize",
                border: "1px solid",
                padding: "5px 15px",
                borderRadius: "5px",
                margin: "10px 5px",
              }}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default CargoAnalysis;
