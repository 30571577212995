import React, { useState, useEffect } from 'react';
import {
    Modal,
    Table,
    Tooltip,
    Checkbox,
    Button,
    Spin,
    notification,
    Card, Input,
    Form,
    Select,
    Popconfirm
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Icon } from "@iconify-icon/react";
import { postAPICall, getAPICall, openNotificationWithIcon } from '../../shared';
// import { method } from 'lodash';
import ContactForm from './addOutlookContact';




const ImportContacts = () => {
    const { Option } = Select;
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [selectedMails, setSelectedMails] = useState([]);
    const [importedMail, setImportedMail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contact, setContacts] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [createForm, setCreateForm] = useState(false)
    const [data, setData] = useState(null)
    const [title, setTitle] = useState('Add Contact')

    // Fetch email data
    const callMails = async () => {
        try {
            // const userData: any = localStorage.getItem('userData')
            let url = `${process.env.REACT_APP_MT_URL}accounts/outlook-mails`
            const response = await getAPICall(url)
            if (response) {
                let mails = response?.data?.map((item) => item.email)
                setImportedMail(mails);
                setSelectedMails(mails);
                getContact(mails);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const getContact = async (email) => {
        try {

            let url = `${process.env.REACT_APP_MT_URL}mail/get-outlook-contact`
            const response = await postAPICall(
                url,
                { emails: email },
                'post',

            )
            setContacts(response.data);
        } catch (error) {
            console.log(error)
        }

    };

    // Open modal and fetch emails
    const handleOpenModal = () => {
        setOpenEmailModal(true);
        // fetchEmails();
    };
    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSelectedProviders([...selectedProviders, value]);
        } else {
            setSelectedProviders(selectedProviders.filter((item) => item !== value));
        }
    };

    // Handle row selection
    const rowSelection = {
        selectedRowKeys: selectedMails,
        onChange: (selectedRowKeys) => {
            setSelectedMails(selectedRowKeys);
        },
    };

    // Handle import
    const handleImport = () => {
        setImportedMail(selectedMails);
        notification.success({
            message: 'Import Successful',
            description: `${selectedMails.length} contacts have been imported.`,
        });
        setOpenEmailModal(false);
        setSelectedMails([]);
    };
    const capitaliseFirstLetter = (userInput) => {
        return userInput
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }


    // Define table columns
    const coloums = [
        {
            title: 'S No.',
            align: 'center',
            render: (text, record, index) => index + 1,
            width: 80
        },
        {
            title: 'Person Name',
            dataIndex: "displayName",
            key: "displayName",
            render: (row) => (
                <Tooltip title={row ? capitaliseFirstLetter(row) : 'N/A'}>
                    <div className='ellipsisText' style={{ color: row ? '' : 'grey', fontWeight: row ? '500' : '300', paddingLeft: '4px' }}>
                        {row ? capitaliseFirstLetter(row) : 'N/A'}
                    </div>
                </Tooltip>
            )
        },
        {
            title: 'Company Name',
            dataIndex: "companyName",
            key: "companyName",
            // selector: (row: any) => row,
            render: (row) => (
                <Tooltip title={row ? capitaliseFirstLetter(row) : 'N/A'}>
                    <div className='ellipsisText' style={{ color: row ? '#003e78' : 'grey', fontWeight: row ? '600' : '300', paddingLeft: '4px' }}>
                        {row ? capitaliseFirstLetter(row) : 'N/A'}
                    </div>
                </Tooltip>
            ),
        },

        {
            title: "Email",
            dataIndex: "emailAddresses",
            key: "email",
            render: (emailAddresses) => <div style={{ paddingLeft: '4px' }}>{emailAddresses[0]?.address}</div>,
        },
        {
            title: "Phone No",
            dataIndex: "mobilePhone",
            key: "mobilePhone",
            render: (mobilePhone) => <div style={{ paddingLeft: '4px' }}>{mobilePhone}</div>,
        },

        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-around' }}>
                    <Button onClick={() => { handelView(row) }} className='Button-bg'>
                        <Icon icon='tabler:eye' className='bg-icon-color' fontSize={16} />
                    </Button>
                    <Popconfirm
                        title='Are you sure you want to remove this contact?'
                        onConfirm={() => handleDelete(row)}
                        onCancel={() => console.log("cancle")}
                        okText='Yes'
                        cancelText='No'
                        placement='topLeft'
                    >
                        <Button className='Button-bg'>
                            <Icon icon='material-symbols-light:delete-outline' className='bg-icon-color' fontSize={16} />
                        </Button>
                    </Popconfirm >
                </div >

            ),
            width: '90px'
        }
    ]
    useEffect(() => {
        callMails()
    }, [createForm])

    const handleImportContact = async (e) => {
        try {
            e.preventDefault();
            if (selectedProviders.length > 0) {
                let url = `${process.env.REACT_APP_MT_URL}mail/import-outlook-contact`
                const response = await postAPICall(
                    url,
                    { emails: selectedProviders },
                    'post',)

                if (response) {
                    openNotificationWithIcon('info', response.msg)
                }

                setOpenEmailModal(false);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const handelView = (fromData) => {
        setTitle('Update Contact');
        setData(fromData);
        setCreateForm(true)
    }
    const handleDelete = async (data) => {
        try {
            let url = `${process.env.REACT_APP_MT_URL}mail/remove-outlook-contact`
            let payload = {
                owner_email: data?.owner_email,
                id: data?.id
            }
            const response = await postAPICall(
                url,
                payload, 'post'
            )
            if (response) {
                // .success(response?.msg)
                openNotificationWithIcon('success', response?.msg)
                callMails();
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleDataFromChild = (data) => {
        setCreateForm(data);
    };
    const handleCreateNewContact = () => {
        setTitle("Add New Contact");
        setData(null)
        setCreateForm(true);
    }
    const handleSelectChange = (value) => {
        try {
            if (value.includes('all')) {
                if (value.length === importedMail.length + 1) {
                    setSelectedMails([]);
                    handleTableContact([]);
                } else {
                    setSelectedMails([...importedMail]);
                    handleTableContact(importedMail);
                }
            } else {
                setSelectedMails(value);
                handleTableContact(value);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTableContact = (value) => {
        setSelectedMails(value);
        getContact(value);
    };


    return (
        <div>

            <Card>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: "flex" }}>
                        <Button
                            type="primary"
                            onClick={() => { handleCreateNewContact() }}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Icon icon='bitcoin-icons:contacts-filled' style={{ fontSize: "20px" }} />{" "}
                            <span className="ms-2">Create Outlook Contact</span>
                        </Button>

                        <Button
                            type="primary"
                            onClick={handleOpenModal}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Icon icon='bitcoin-icons:contacts-filled' style={{ fontSize: "20px" }} />{" "}
                            <span className="ms-2"> Import Contacts</span>
                        </Button>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '25rem' }}>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            value={selectedMails}
                            onChange={handleSelectChange}
                            placeholder="Select Mail"
                            maxTagCount="responsive"
                        >
                            <Option value="all">
                                <Checkbox
                                    checked={selectedMails.includes('all') || selectedMails.length === importedMail.length}
                                >
                                    All
                                </Checkbox>
                            </Option>
                            {importedMail.map((mail, index) => (
                                <Option key={index} value={mail}>
                                    <Checkbox checked={selectedMails.includes(mail)}>{mail}</Checkbox>
                                </Option>
                            ))}
                        </Select>
                    </div>

                </div>
            </Card>


            <Modal
                title="Import Contacts"
                visible={openEmailModal}
                onCancel={() => setOpenEmailModal(false)}
                onOk={handleImportContact}
                okButtonProps={{ disabled: !selectedMails.length }}
                okText="Submit"
            >
                <Form>
                    {importedMail.map((ele) => (
                        <Form.Item key={ele} style={{ marginBottom: '8px' }}>
                            <Checkbox
                                checked={selectedProviders.includes(ele)}
                                onChange={(e) => handleCheckboxChange(e)}
                                value={ele}
                            >
                                {ele}
                            </Checkbox>
                        </Form.Item>
                    ))}

                </Form>
            </Modal>
            <Card>

                <div>
                    <Table
                        className="inlineTable"
                        // rowKey="id"
                        columns={coloums}
                        dataSource={contact}
                        pagination={true}
                    />

                </div>
            </Card>
            {
                createForm &&
                <ContactForm mail={importedMail} open={createForm} sendDataToParent={handleDataFromChild} title={title} data={data} />
            }
        </div>
    );
};

export default ImportContacts;
