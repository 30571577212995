import React, { useEffect, useRef, useState } from "react";
import URL_WITH_VERSION, {
  getAPICall,
  URL_WITHOUT_VERSION,
  openNotificationWithIcon,
  //hasErrors,
  CALL_MASTER_API,
  useStateCallback,
  postAPICallService,
  putAPICallService,
} from "../../../shared";
import { Form, Modal } from "antd";
//import NormalForm from '../../../shared/NormalForm';
import EditableTable from "../../../shared/EditableTable";
import ModalAlertBox from "../../../shared/ModalAlertBox";
import { CARGO_GROUP, CARGO_LIST } from "../../../shared/constants";
import NormalFormIndex from "../../../shared/NormalForm/normal_from_index";
import AddCargoReport from "../../data-center-reports/AddCargoReport";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  SaveOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

const INITIAL_MODAL = {
  modalStatus: false,
  modalHeader: null,
  modalBody: null,
  modalFooter: null,
};

const CargoModal = (props) => {
  return (
    <div className="body-wrapper">
      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <div className="row">
              <EditableTable tableKey={props.tableKey} showFullWidth={true} />
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

/*
const ViewCargoPage = (props) => {
  const { responseData, formData, cargoGroups } = props;
  let index = cargoGroups.findIndex((v) => v.id === formData.group);
  let groupName = index > -1 ? cargoGroups[index]["type"] : "";

  return (
    <div className="row">
      {responseData.frm.length > 0 ? (
        responseData.frm.map((e, i) => {
          return (
            <div className="col-md-4" key={i}>
              <Form.Item label={e.name}>
                {e.f_name === "group" ? groupName : formData[e.f_name]}
              </Form.Item>
            </div>
          );
        })
      ) : (
        <div className="col col-lg-12">
          <Spin tip="Loading...">
            <Alert message=" " description="Please wait..." type="info" />
          </Spin>
        </div>
      )}
    </div>
  );
};

*/

const AddCargoName = (props) => {

  const [form] = Form.useForm();
  const modalref = useRef(null);
  const [response, setResponse] = useState([]);
  const [state, setState] = useStateCallback({
    frmName: "cargo_form",
    responseData: { frm: [], tabs: [], active_tab: {} },
    formData: props.formData || {},
    editFieldData: props.formData ? true : false,
    isView: props.isView,
    modal: INITIAL_MODAL,
    loading: false,
    cargoModal: "cargo-groups",
    cargoGroups: [],
    isShowAddCargoReport: false,
  });

  useEffect(() => {
    (async () => {
      const response = await getAPICall(
        `${URL_WITHOUT_VERSION}get/${state.frmName}`
      );
      let responseData = await response["data"];

      loadCargoGroup();

      setState({ ...state, responseData }, () => {
        setTimeout(() => {
          form.validateFields();
          if (Object.keys(state.formData).length > 0) {
            responseData.frm.map((e, i) => {
              form.setFieldsValue({
                [e.f_name]: state.formData[e.f_name],
              });
              return true;
            });
          }
        }, 1000);
      });
    })();
    modalref.current = "add-cargo-name";
  }, [state.formData]);

  const loadCargoGroup = () => {
    if (CARGO_GROUP) {
      CALL_MASTER_API("get", CARGO_GROUP, null, null, (data) => {
        let cargoGroups = data && data.data ? data.data : [];
        if (cargoGroups.length > 0) {
          setState({
            ...state,
            cargoGroups,
          });
        }
      });
    }
  };

  const onResetForm = () => form.resetFields();

  const onCancel = (e) => setState({ ...state, modal: INITIAL_MODAL });

  const showCargoDetail = () => {
    let modalBody = () => <CargoModal tableKey={state.cargoModal} />;
    let modal = {
      ...state.modal,
      modalStatus: true,
      modalHeader: "Cargo Group",
      modalBody,
    };
    setState({ ...state, modal });
  };

  const AddCargoReport1 = async (id) => {
    let headers = {
      where: {
        id: props?.formData?.id,
      },
    };

    headers = JSON.stringify(headers);
    setState((prev) => ({
      ...prev,
      // loading: true,
      responseData: [],
    }));

    let _url = `${URL_WITH_VERSION}/master/report?t=${CARGO_LIST}`;

    try {
      const response = await getAPICall(_url, JSON.parse(headers));

      const data = response?.data;

      data &&
        data.map((elem) => {
          setResponse(elem);
        });

      setState((prev) => ({
        ...prev,
        isShowAddCargoReport: true,
      }));
    } catch (error) {
      // Handle errors as needed
    }
  };

  const _onDeleteFormData = (data) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => onCargoDeletedClick(data),
    });
  };

  const editTableData = async (id) => {
    let headers = { where: { id: id } };

    if (CARGO_LIST) {
      setState((prevState) => ({ ...prevState, loading: true }));

      CALL_MASTER_API("get", CARGO_LIST, null, null, headers, (respData) => {
        let _state = { loading: false },
          edit = false;

        if (respData.total_rows > 0) {
          const updatedFormDataValues = respData["data"][0];
          edit = true;

          setState((prev) => ({
            ...prev,
            loading: false,
            edit: true,
            formData: updatedFormDataValues,
          }));
        }
      });
    }
  };

  function callEditForm() {}

  const validateFormData = (postData) => {
    if (postData.full_name && postData.full_name.startsWith(" ")) {
      return {
        isValid: false,
        message: "Cargo Name can't start with a space.",
      };
    }

    return { isValid: true };
  };

  const onSaveFormData = async (postData) => {
    const { cargoGroups, loading } = state;
    const validation = validateFormData(postData);
    if (!validation.isValid) {
      openNotificationWithIcon("info", validation.message, 3);
      return;
    }
    let method =
      postData.hasOwnProperty("id") && parseInt(postData["id"]) > 0
        ? "put"
        : "post";
    setState({ ...state, loading: true });
    if (cargoGroups.length > 0) {
      let cargoIndex = cargoGroups.findIndex((e) => e.id === postData.group);
      if (cargoIndex > -1) {
        postData["type"] = cargoGroups[cargoIndex]["description"];
        if (cargoGroups[cargoIndex]["class"] === "LIQUID") {
          postData["units"] = "API";
          postData["density"] = parseFloat(postData["api"]).toFixed(2);
        } else if (cargoGroups[cargoIndex]["class"] === "DRY") {
          postData["units"] = "M3/MT";
          postData["density"] = parseFloat(postData["m3_mt"]).toFixed(2);
        }
      }
    }

    // if(postData?.dangerous){

    // }

    let headers = {
      "Content-Type": "application/json",
    };

    CALL_MASTER_API(
      method,
      CARGO_LIST,
      {
        ...postData,
        dangerous: postData?.dangerous ? 1 : 0,
        pre_clearance_uscn: postData?.pre_clearance_uscn ? 1 : 0,
        special_handling: postData?.special_handling ? 1 : 0,
      },
      null,
      headers,
      (data) => {
        if (data.data) {
          openNotificationWithIcon("success", data.message);
          // if (typeof props.modalCloseEvent === "function")

          //   props.modalCloseEvent();
          setState({ ...state, loading: false });
          // onResetForm();
          // props.editTableData();
          // editTableData(data?.row?.id); // 1st time save
          if (method === "post") {
            editTableData(data?.row?.id); //1st time save
          } else {
            editTableData(state.formData.id);
          }
          // setState({ ...state, loading: false, modal: { ...state.modal, modalStatus: true } });
        } else {
          let dataMessage = data.message;
          let msg = "<div class='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg += "<div class='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }
          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          );
          setState({ ...state, loading: false });
        }
      }
    );
  };

  const onCargoDeletedClick = (data) => {
    let postData = {};
    if (data.id) {
      postData["id"] = data.id;
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      CALL_MASTER_API("delete", CARGO_LIST, postData, null, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message);
          setState((prev) => ({
            ...prev,
            formData: {},
            loading: false,
          }));
          if (props.hasOwnProperty("modalCloseEvent")) {
            props.modalCloseEvent();
          }
        } else {
          openNotificationWithIcon("error", data.message);
        }
      });
    }
  };

  const { frmName, formData, modal, isShowAddCargoReport, loading } = state;
  const { modalStatus, modalBody, modalHeader, modalFooter, modalWidth } =
    modal;

  return (
    <div className="body-wrapper">
      <article className="article">
        <div className="box box-default">
          {loading === false ? (
            <div className="box-body">
              <NormalFormIndex
                key={"key_" + state.frmName + "_0"}
                formClass="label-min-height"
                showForm={true}
                frmCode={state.frmName}
                formData={state.formData}
                addForm={true}
                showButtons={[]}
                showToolbar={[
                  {
                    isLeftBtn: [
                      {
                        key: "s1",
                        isSets: [
                          {
                            id: "7",
                            key: "menu-fold",
                            type: <MenuFoldOutlined />,
                            withText: "List",
                            showToolTip: true,
                            event: "menu-fold",
                          },

                          {
                            id: "1",
                            key: "save",
                            type: <SaveOutlined />,
                            withText: "",
                            event: (key, data) => onSaveFormData(data),
                          },
                          formData && !props?.showDeleteIcon &&
                          props.showDeleteIcon !== "noShow" &&
                            formData["id"] && {
                              id: "6",
                              key: "delete",
                              type: <DeleteOutlined />,
                              withText: "Delete",
                              showToolTip: true,
                              event: (key, data) => _onDeleteFormData(data),
                            },
                        ],
                      },
                    ],
                    isRightBtn: [
                      {
                        isSets: [
                          {
                            id: "7",
                            isDropdown: 0,
                            key: "cargo-group",
                            withText: "Cargo Group",
                            event: (key) => showCargoDetail(),
                          },
                          {
                            id: "5",
                            key: "report",
                            isDropdown: 0,
                            withText: "Report",
                            menus: null,
                            event: (key) => AddCargoReport1(),
                          },
                        ],
                      },
                    ],
                    isResetOption: false,
                  },
                ]}
                showSideListBar={false}
                sideList={{
                  selectedID: state?.formData?.id,
                  showList:true,
                  title: "Cargo List",
                  uri: "/master/list?t=carli&l=0",
                  columns: ["full_name", "group", "short_name", "un_number"],
                  icon: true,
                  // searchString:
                  //   '"vessel Name","Invoice No", "TCI ID", "Invoice Status" ',
                  rowClickEvent: (evt) => editTableData(evt.id),
                }}
                inlineLayout={true}
              />
            </div>
          ) : undefined}
        </div>
      </article>
      {modalStatus ? (
        <ModalAlertBox
          ref={modalref}
          modalStatus={modalStatus}
          modalHeader={modalHeader}
          modalBody={modalBody}
          modalFooter={modalFooter}
          modalWidth={modalWidth}
          onCancelFunc={(e) => onCancel(e)}
        />
      ) : null}

      {isShowAddCargoReport ? (
        <Modal
          style={{ top: "2%" }}
          title="Report"
          open={isShowAddCargoReport}
          // onOk={handleOk}
          onCancel={() =>
            setState((pre) => ({ ...pre, isShowAddCargoReport: false }))
          }
          width="95%"
          footer={null}
        >
          <AddCargoReport response={response} />
        </Modal>
      ) : undefined}
    </div>
  );
};

const AddCargoNameForm = AddCargoName;
export default AddCargoNameForm;
