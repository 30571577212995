import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import TagsModal from "./TagsModal";
import VesselInfoDrawer from "./VesselInfoDrawer";
import AddVessel from "./AddVesselModal";
import AddVesselModal from "./AddVesselModal";
import BestMatchDrawer from "./BestMatchDrawer";
import { Button, Input, message, Popover, Tooltip } from "antd";
import { SendOutlined } from "@ant-design/icons";
import EmailComments from "./EmailComments";
import { useSelector } from "react-redux";
import { openNotificationWithIcon, postAPICall } from "../../../shared";
import BunkerSpotPriceModal from "./BunkerSpotPriceModal";
import VoyageEstimateModal from "./VoyageEstimateModal";
import EmailTemplateModal from "./emailtemplateModal";
import ChatModal from "./chatmodal";

const MailRightbar = ({ currentMail }) => {
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showAddVesselModal, setShowAddVesselModal] = useState(false);
  const [showAddChatModal, setShowAddChatModal] = useState(false);
  const [showVesselInfoDrawer, setShowVesselInfoDrawer] = useState(false);
  const [showBestMatchDrawer, setShowBestMatchDrawer] = useState(false);
  const [showBunkerPriceModal, setShowBunkerPriceModal] = useState(false);
  const [showVoyageEstimateModal, setShowVoyageEstimateModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [marineData, setMarineData] = useState(null);
  const [showtemplatelModal, setShowtemplateModal] = useState(false)

  const vesselInfoDrawer = useSelector(
    (state) => state?.email?.vesselInfoDrawer
  );

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const getVesselInfo = async (vessel_name) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${vessel_name}`;
      const response = await postAPICall(url, {}, "get", (data) => {
        if (data.length > 0) {
          setMarineData(data[0]);
          if (vesselInfoDrawer?.isVisible) {
            setShowVesselInfoDrawer(true);
          }
        } else {
          if (vesselInfoDrawer?.isVisible) {
            openNotificationWithIcon("info", "Vessel Info not found.");
          }
        }
      });
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  useEffect(() => {
    if (vesselInfoDrawer?.vesselName) {
      getVesselInfo(vesselInfoDrawer?.vesselName);
    } else {
      setMarineData(null);
    }
  }, [vesselInfoDrawer]);

  const handleIconClick = (name) => {
    if (name === "tags") {
      setShowTagsModal(true);
    } else if (name === "bestMatch") {
      setShowBestMatchDrawer(true);
    } else if (name === "bunkerPrice") {
      setShowBunkerPriceModal(true);
    } else if (name === "vesselInfo") {
      if (marineData) {
        setShowVesselInfoDrawer(true);
      } else {
        // message.error("Vessel Info not found.");
        openNotificationWithIcon("info", "Vessel Info not found.");
      }
    } else if (name === "addVessel") {
      setShowAddVesselModal(true);
    } else if (name === "voyageEstimate") {
      setShowVoyageEstimateModal(true);
    }
  };

  return (
    <>
      <div className="current_mail_extra_features_tab">
        <Tooltip title="Tags" placement="left">
          <div
            className="mail_header_icons"
            onClick={() => handleIconClick("tags")}
          >
            <Icon className="current_mail_icons" icon={"tabler:bookmark"} />
          </div>
        </Tooltip>
        <Tooltip
          title="Click to find the best match for your search or criteria."
          placement="left"
        >
          <div
            className="mail_header_icons"
            onClick={() => handleIconClick("bestMatch")}
          >
            <Icon className="current_mail_icons" icon={"ri:shake-hands-line"} />
          </div>
        </Tooltip>
        <Popover
          content={<EmailComments currentMail={currentMail} />}
          title={null}
          trigger="click"
          placement="leftTop"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <Tooltip
            title="Click to view or add comments related to this email or task."
            placement="left"
          >
            <div className="mail_header_icons">
              <Icon className="current_mail_icons" icon={"ei:comment"} />
            </div>
          </Tooltip>
        </Popover>
        {/* <div className="mail_header_icons">
          <Icon className="current_mail_icons" icon={"tabler:message-dots"} />
        </div> */}
        <Tooltip
          title="Click to view the location on the map related to the email or task."
          placement="left"
        >
          <div
            className="mail_header_icons"
            onClick={() => handleIconClick("vesselInfo")}
          >
            <Icon className="current_mail_icons" icon={"tabler:map"} />
          </div>
        </Tooltip>
        <Tooltip
          title="Click to open the chat window and communicate with your contacts."
          placement="left"
        >
          <div className="mail_header_icons" onClick={() => { setShowAddChatModal(true) }}>
            <Icon className="current_mail_icons" icon='tabler:brand-hipchat' />
          </div>
        </Tooltip>
        <Popover
          placement="left"
          trigger="click"
          title={<div>Who viewed this mail.</div>}
          content={
            <div>
              {Array.isArray(currentMail?.readBy)
                ? currentMail.readBy.length > 0
                  ? currentMail.readBy.map((data, index) => (
                    <div key={index}>
                      <span style={{ margin: "5px" }}>{index + 1}.</span>{" "}
                      {data}
                    </div>
                  ))
                  : currentMail?.client
                : currentMail?.readBy || currentMail?.client}
            </div>
          }
        >
          <Tooltip title="Click to see who viewed this email." placement="left">
            <div className="mail_header_icons">
              <Icon
                className="current_mail_icons"
                icon={"material-symbols:mark-email-read-outline-sharp"}
              />
            </div>
          </Tooltip>
        </Popover>
        {/* <Tooltip title="Click to see who viewed this email." placement="left">
          <div className="mail_header_icons">
            <Icon
              className="current_mail_icons"
              icon={"material-symbols:mark-email-read-outline-sharp"}
            />
          </div>
        </Tooltip> */}

        <Tooltip
          title="Click to add a new vessel to your database."
          placement="left"
        >
          <div
            className="mail_header_icons"
            onClick={() => handleIconClick("addVessel")}
          >
            <Icon className="current_mail_icons" icon={"tabler:ship"} />
          </div>
        </Tooltip>
        <Tooltip
          title="Click to view or update bunker fuel prices."
          placement="left"
        >
          <div
            className="mail_header_icons"
            onClick={() => handleIconClick("bunkerPrice")}
          >
            <Icon
              className="current_mail_icons"
              icon={"tabler:droplet-dollar"}
            />
          </div>
        </Tooltip>
        <Tooltip
          title="Click to estimate the voyage cost and details."
          placement="left"
        >
          <div
            className="mail_header_icons"
            onClick={() => handleIconClick("voyageEstimate")}
          >
            <Icon className="current_mail_icons" icon={"tabler:calculator"} />
          </div>
        </Tooltip>
        <Tooltip title="Click to check matching position." placement="left">
          <div className="mail_header_icons">
            <Icon
              className="current_mail_icons"
              icon={"lets-icons:setting-vert"}
            />
          </div>
        </Tooltip>
        <Tooltip

          title="Click to access and manage email templates."
          placement="left"
        >
          <div className="mail_header_icons">
            <Icon
              onClick={() => { setShowtemplateModal(true) }}
              className="current_mail_icons"
              icon={"fluent:mail-template-20-regular"}
            />
          </div>
        </Tooltip>
        {/* <div className="mail_header_icons">
                    <Icon
                      className="current_mail_icons"
                      icon={"tabler:alert-octagon"}
                    />
                  </div>
                  <div className="mail_header_icons">
                    <Icon
                      className="current_mail_icons"
                      icon={"tabler:alert-octagon"}
                    />
                  </div> */}
      </div>
      <TagsModal
        showTagsModal={showTagsModal}
        setShowTagsModal={setShowTagsModal}
      />
      <AddVesselModal
        showAddVesselModal={showAddVesselModal}
        setShowAddVesselModal={setShowAddVesselModal}
      />
      <BunkerSpotPriceModal
        showBunkerPriceModal={showBunkerPriceModal}
        setShowBunkerPriceModal={setShowBunkerPriceModal}
      />
      <VoyageEstimateModal
        showVoyageEstimateModal={showVoyageEstimateModal}
        setShowVoyageEstimateModal={setShowVoyageEstimateModal}
      />
      < EmailTemplateModal
        showtemplateModal={showtemplatelModal}
        setShowtemplateModal={setShowtemplateModal}
      />
      {marineData && (
        <VesselInfoDrawer
          showVesselInfoDrawer={showVesselInfoDrawer}
          setShowVesselInfoDrawer={setShowVesselInfoDrawer}
          marineData={marineData}
        />
      )}
      <BestMatchDrawer
        showBestMatchDrawer={showBestMatchDrawer}
        setShowBestMatchDrawer={setShowBestMatchDrawer}
        currentMail={currentMail}
      />
      <ChatModal showAddChatlModal={showAddChatModal}
        setShowAddChatModal={setShowAddChatModal} />
    </>

  );
};

export default MailRightbar;
