import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";

// const DefaultVesselModal = ({ info }) => {
//     const navigate = useNavigate();
//     const showDetails = () => {
//         navigate("/show-all-vessel-details", { state: { vesselPositionDetails : info, liveSearchList : [info]} });
//       };
//     return (
//         <div style={{marginTop: '5px'}}>
//             <Button onClick={showDetails} style={{marginBottom:'5px'}}>More Detail</Button>
//             <table className="defaultVesselInfoBox">
//                 <tbody>
//                     <tr style={{background: '#c5f3f3'}}>
//                         <td>Ship Name :</td>
//                         <td>{info.SHIPNAME || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Ship Type :</td>
//                         <td>{info.SHIPTYPE || 'N/A'}</td>
//                     </tr>
//                     <tr style={{background: '#f3c5e9'}}>
//                         <td>IMO No. :</td>
//                         <td>{info.IMO || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>MMSI No. :</td>
//                         <td>{info.MMSI || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Speed :</td>
//                         <td>{(parseFloat(info.SPEED) / 10).toFixed(2) || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Average Speed :</td>
//                         <td>{info.AVG_SPEED || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Max Speed :</td>
//                         <td>{info.MAX_SPEED || 'N/A'}</td>
//                     </tr>
//                     <tr style={{background: 'rgb(227 243 197)'}}>
//                         <td>Status :</td>
//                         <td>{info.STATUS.toUpperCase() || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>DWT :</td>
//                         <td>{info.DWT || 'N/A'}</td>
//                     </tr>
//                     <tr style={{background: '#f3c5c5'}}>
//                         <td>Latitude :</td>
//                         <td>{info.LAT || 'N/A'}</td>
//                     </tr>
//                     <tr style={{background: '#f3c5c5'}} >
//                         <td>Longitude :</td>
//                         <td>{info.LON || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Last Port :</td>
//                         <td>{info.LAST_PORT && info.LAST_PORT_COUNTRY ? info.LAST_PORT + " " + '(' + info.LAST_PORT_COUNTRY + ')' : 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Next Port :</td>
//                         <td>
//                             {info.NEXT_PORT_NAME && info.NEXT_PORT_COUNTRY
//                                 ? info.NEXT_PORT_NAME + " " + '(' + info.NEXT_PORT_COUNTRY+ ')'
//                                 : 'N/A'}
//                         </td>
//                     </tr>
//                     <tr>
//                         <td>Market :</td>
//                         <td>{info.MARKET || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Call Sign :</td>
//                         <td>{info.CALLSIGN || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Flag :</td>
//                         <td>{info.FLAG || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                         <td>Built Year :</td>
//                         <td>{info.YEAR_BUILT || 'N/A'}</td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     )
// }

// export default DefaultVesselModal

const DefaultVesselModal = ({ info }) => {
  //   console.log("info....", info);

  const navigate = useNavigate();
  const showDetails = () => {
    navigate("/show-all-vessel-details", {
      state: { vesselPositionDetails: info, vesselDetails: info },
    });
  };
  return (
    <div style={{ marginTop: "5px" }}>
      <Button onClick={showDetails} style={{ marginBottom: "5px" }}>
        More Detail
      </Button>
      <table className="defaultVesselInfoBox">
        <tbody>
          <tr style={{ background: "#c5f3f3" }}>
            <td>Ship Name :</td>
            <td>{info?.SHIPNAME || "N/A"}</td>
          </tr>
          <tr>
            <td>Ship Type :</td>
            <td>{info?.SHIPTYPE || "N/A"}</td>
          </tr>
          <tr style={{ background: "#f3c5e9" }}>
            <td>IMO No. :</td>
            <td>{info?.IMO || "N/A"}</td>
          </tr>
          <tr>
            <td>MMSI No. :</td>
            <td>{info?.MMSI || "N/A"}</td>
          </tr>
          <tr>
            <td>Speed :</td>
            <td>{(parseFloat(info?.SPEED) / 10).toFixed(2) || "N/A"}</td>
          </tr>
          <tr>
            <td>Average Speed :</td>
            <td>{info?.AVG_SPEED || "N/A"}</td>
          </tr>
          <tr>
            <td>Max Speed :</td>
            <td>{info?.MAX_SPEED || "N/A"}</td>
          </tr>
          <tr style={{ background: "rgb(227 243 197)" }}>
            <td>Status :</td>
            <td>{info?.STATUS.toUpperCase() || "N/A"}</td>
          </tr>
          <tr>
            <td>DWT :</td>
            <td>{info?.DWT || "N/A"}</td>
          </tr>
          <tr style={{ background: "#f3c5c5" }}>
            <td>Latitude :</td>
            <td>{info?.LAT || "N/A"}</td>
          </tr>
          <tr style={{ background: "#f3c5c5" }}>
            <td>Longitude :</td>
            <td>{info?.LON || "N/A"}</td>
          </tr>
          <tr>
            <td>Last Port :</td>
            <td>
              {info?.LAST_PORT && info?.LAST_PORT_COUNTRY
                ? info?.LAST_PORT + " " + "(" + info?.LAST_PORT_COUNTRY + ")"
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Next Port :</td>
            <td>
              {info?.NEXT_PORT_NAME && info?.NEXT_PORT_COUNTRY
                ? info?.NEXT_PORT_NAME +
                  " " +
                  "(" +
                  info?.NEXT_PORT_COUNTRY +
                  ")"
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>Market :</td>
            <td>{info?.MARKET || "N/A"}</td>
          </tr>
          <tr>
            <td>Call Sign :</td>
            <td>{info?.CALLSIGN || "N/A"}</td>
          </tr>
          <tr>
            <td>Flag :</td>
            <td>{info?.FLAG || "N/A"}</td>
          </tr>
          <tr>
            <td>Built Year :</td>
            <td>{info?.YEAR_BUILT || "N/A"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DefaultVesselModal;
