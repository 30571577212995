// import React, { useEffect, useState } from "react";
// import ReactECharts from "echarts-for-react";
// import dayjs from "dayjs";
// import { Skeleton } from "antd";

// const SpotPriceGraphModal = ({ graphData }) => {
//   const [data, setData] = useState([]);
//   console.log("graphData", graphData);

//   const formattedData = () => {
//     const portData = graphData?.reduce((acc, item) => {
//       if (!acc[item.port]) {
//         acc[item.port] = {
//           port_name: item.port,
//           fuel_type: [],
//         };
//       }

//       acc[item.port].fuel_type.push({
//         Grade: item.grade,
//         SubGrade: item.grade,
//         Price: item.price,
//       });

//       // setData({ ports, hsfoPrices, mgoPrices, vlsfoPrices });
//       return acc;
//     }, {});

//     const ports = data.map((item) => item.port_name);
//     const hsfoPrices = data.map(
//       (item) =>
//         item.fuel_type.find((fuel) => fuel.Grade === "HSFO")?.Price || "-"
//     );
//     const mgoPrices = data.map(
//       (item) =>
//         item.fuel_type.find((fuel) => fuel.Grade === "MGO")?.Price || "-"
//     );
//     const vlsfoPrices = data.map(
//       (item) =>
//         item.fuel_type.find((fuel) => fuel.Grade === "VLSFO")?.Price || "-"
//     );

//     // Convert the portData object to an array of objects
//     setData(Object.values(portData));
//     return Object.values(portData);
//   };
//   console.log("data", data);

//   useEffect(() => {
//     formattedData();
//   }, []);

//   const getOption = () => {
//     const todayDate = dayjs().format("ddd, Do MMMM YYYY");
//     return {
//       title: {
//         text: `Fuel Prices($) by Port (${todayDate})`,
//         textStyle: {
//           fontSize: 16, // Main title font size
//           rich: {
//             date: {
//               fontSize: 12, // Smaller font size for the date
//               color: "#005792", // Optional: Different color for the date
//             },
//           },
//         },
//       },
//       tooltip: {
//         trigger: "axis",
//       },
//       legend: {
//         data: ["HSFO", "MGO", "VLSFO"],
//         // top: '8%',
//         left: "right",
//         icon: "circle", // Optionally, change the icon shape for better aesthetics
//       },
//       color: ["#005792", "#00bbf0", "#fdb44b"],
//       grid: {
//         left: "3%",
//         right: "4%",
//         bottom: "3%",
//         containLabel: true,
//       },
//       // toolbox: {
//       //   feature: {
//       //     saveAsImage: {}
//       //   }
//       // },
//       xAxis: {
//         type: "category",
//         boundaryGap: true, // Bar charts generally have boundary gaps
//         data: data ? data.ports : Array(5).fill(""),
//         axisLabel: {
//           rotate: 45, // Rotate labels to fit all
//           interval: 0, // Ensure all labels are shown
//         },
//       },
//       yAxis: {
//         type: "value",
//         min: 0, // Adjust based on your data
//         max: 1000, // Adjust based on your data
//         interval: 100, // Adjust based on your data
//       },
//       series: [
//         {
//           name: "HSFO",
//           type: "bar",
//           data: data ? data.hsfoPrices : Array(5).fill("-"),
//           barWidth: "20%", // Adjust the width of the bars
//         },
//         {
//           name: "MGO",
//           type: "bar",
//           data: data ? data.mgoPrices : Array(5).fill("-"),
//           barWidth: "20%", // Adjust the width of the bars
//         },
//         {
//           name: "VLSFO",
//           type: "bar",
//           data: data ? data.vlsfoPrices : Array(5).fill("-"),
//           barWidth: "20%", // Adjust the width of the bars
//         },
//       ],
//     };
//   };

//   return (
//     <div>
//       {data == null ? (
//         <Skeleton
//           variant="rectangular"
//           width="100%"
//           height={300}
//           animation="pulse"
//           className="globalLoading"
//         >
//           Loading...
//         </Skeleton>
//       ) : (
//         <ReactECharts style={{ height: "350px" }} option={getOption()} />
//       )}
//     </div>
//   );
// };

// export default SpotPriceGraphModal;

//--------------------------------------------------------// Graph With Searching

import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import dayjs from "dayjs";
import { Input, Skeleton } from "antd"; // Import Skeleton from Ant Design
import { SearchOutlined } from "@ant-design/icons";

const SpotPriceGraphModal = ({ graphData, allData }) => {
  const [data, setData] = useState({
    ports: [],
    hsfoPrices: [],
    mgoPrices: [],
    vlsfoPrices: [],
  });
  const [filteredGraphData, setFilteredGraphData] = useState(graphData);
  const [searchQuery, setSearchQuery] = useState("");

  const formattedData = () => {
    const portData = filteredGraphData?.reduce((acc, item) => {
      if (!acc[item.port]) {
        acc[item.port] = {
          port_name: item.port,
          fuel_type: [],
        };
      }

      acc[item.port].fuel_type.push({
        Grade: item.grade,
        SubGrade: item.grade,
        Price: item.price,
      });

      return acc;
    }, {});

    const portArray = Object.values(portData);
    const ports = portArray.map((item) => item.port_name);
    const hsfoPrices = portArray.map(
      (item) =>
        item.fuel_type.find((fuel) => fuel.Grade === "HSFO")?.Price || "-"
    );
    const mgoPrices = portArray.map(
      (item) =>
        item.fuel_type.find((fuel) => fuel.Grade === "MGO")?.Price || "-"
    );
    const vlsfoPrices = portArray.map(
      (item) =>
        item.fuel_type.find((fuel) => fuel.Grade === "VLSFO")?.Price || "-"
    );

    setData({ ports, hsfoPrices, mgoPrices, vlsfoPrices });
  };

  useEffect(() => {
    setSearchQuery("");
    setFilteredGraphData(graphData);
  }, []);

  useEffect(() => {
    formattedData();
  }, [filteredGraphData]);

  const handleSearchFilter = (e) => {
    const searchValue = e?.target?.value.toLowerCase();
    setSearchQuery(searchValue);
    const filtered = allData?.filter((item) =>
      item?.port?.toLowerCase().includes(searchValue)
    );
    setFilteredGraphData(filtered?.slice(0, 30));
  };

  const getOption = () => {
    const todayDate = dayjs().format("ddd, Do MMMM YYYY");
    return {
      title: {
        text: `Fuel Prices($) by Port (${todayDate})`,
        textStyle: {
          fontSize: 16,
          rich: {
            date: {
              fontSize: 12,
              color: "#005792",
            },
          },
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["HSFO", "MGO", "VLSFO"],
        left: "right",
        icon: "circle",
      },
      color: ["#222831", "#5dacbd", "#ff5722"],
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: data.ports,
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 1000,
        interval: 100,
      },
      series: [
        {
          name: "HSFO",
          type: "bar",
          data: data.hsfoPrices,
          barWidth: "20%",
        },
        {
          name: "MGO",
          type: "bar",
          data: data.mgoPrices,
          barWidth: "20%",
        },
        {
          name: "VLSFO",
          type: "bar",
          data: data.vlsfoPrices,
          barWidth: "20%",
        },
      ],
    };
  };

  return (
    <>
      <div>
        <Input
          placeholder="Search"
          name="filter"
          value={searchQuery}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          style={{ width: "300px", margin: "0 auto", marginBottom: "0.5rem" }}
          onChange={handleSearchFilter}
        />
      </div>

      <div
        style={{
          backgroundColor: "#E6E6FA",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        {!data.ports.length ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          <ReactECharts style={{ height: "350px" }} option={getOption()} />
        )}
      </div>
    </>
  );
};

//--------------------------------------------------------------// Grpah without searching

// const SpotPriceGraphModal = ({ graphData, onSearchFilter, filteredData }) => {
//   const [data, setData] = useState({
//     ports: [],
//     hsfoPrices: [],
//     mgoPrices: [],
//     vlsfoPrices: [],
//   });

//   console.log("GraphData----------", graphData);
//   console.log("filteredData", filteredData);

//   const formattedData = () => {
//     const portData = graphData?.reduce((acc, item) => {
//       if (!acc[item.port]) {
//         acc[item.port] = {
//           port_name: item.port,
//           fuel_type: [],
//         };
//       }

//       acc[item.port].fuel_type.push({
//         Grade: item.grade,
//         SubGrade: item.grade,
//         Price: item.price,
//       });

//       return acc;
//     }, {});

//     // Convert the portData object to an array of objects
//     const portArray = Object.values(portData);

//     const ports = portArray.map((item) => item.port_name);
//     const hsfoPrices = portArray.map(
//       (item) =>
//         item.fuel_type.find((fuel) => fuel.Grade === "HSFO")?.Price || "-"
//     );
//     const mgoPrices = portArray.map(
//       (item) =>
//         item.fuel_type.find((fuel) => fuel.Grade === "MGO")?.Price || "-"
//     );
//     const vlsfoPrices = portArray.map(
//       (item) =>
//         item.fuel_type.find((fuel) => fuel.Grade === "VLSFO")?.Price || "-"
//     );

//     setData({ ports, hsfoPrices, mgoPrices, vlsfoPrices });
//   };

//   useEffect(() => {
//     if (graphData) {
//       formattedData();
//     }
//   }, [graphData]);

//   const getOption = () => {
//     const todayDate = dayjs().format("ddd, Do MMMM YYYY");
//     return {
//       title: {
//         text: `Fuel Prices($) by Port (${todayDate})`,
//         textStyle: {
//           fontSize: 16,
//           rich: {
//             date: {
//               fontSize: 12,
//               color: "#005792",
//             },
//           },
//         },
//       },
//       tooltip: {
//         trigger: "axis",
//       },
//       legend: {
//         data: ["HSFO", "MGO", "VLSFO"],
//         left: "right",
//         icon: "circle",
//       },
//       // color: ["#af1818", "#ccc", "#000"],
//       color: ["#222831", "#5dacbd", "#ff5722"],
//       grid: {
//         left: "3%",
//         right: "4%",
//         bottom: "3%",
//         containLabel: true,
//       },
//       xAxis: {
//         type: "category",
//         boundaryGap: true,
//         data: data.ports,
//         axisLabel: {
//           rotate: 45,
//           interval: 0,
//         },
//       },
//       yAxis: {
//         type: "value",
//         min: 0,
//         max: 1000,
//         interval: 100,
//       },
//       series: [
//         {
//           name: "HSFO",
//           type: "bar",
//           data: data.hsfoPrices,
//           barWidth: "20%",
//         },
//         {
//           name: "MGO",
//           type: "bar",
//           data: data.mgoPrices,
//           barWidth: "20%",
//         },
//         {
//           name: "VLSFO",
//           type: "bar",
//           data: data.vlsfoPrices,
//           barWidth: "20%",
//         },
//       ],
//     };
//   };

//   return (
//     <>
//       <div>
//         <Input
//           placeholder="Search"
//           name="filter"
//           prefix={<SearchOutlined className="site-form-item-icon" />}
//           style={{ width: "300px", margin: "0 auto", marginBottom: "0.5rem" }}
//           onChange={onSearchFilter}
//         />
//       </div>

//       <div
//         style={{
//           backgroundColor: "#E6E6FA",
//           // backgroundColor: "black",
//           padding: "10px",
//           borderRadius: "10px",
//         }}
//       >
//         {!data.ports.length ? (
//           <Skeleton active paragraph={{ rows: 4 }} />
//         ) : (
//           <ReactECharts style={{ height: "350px" }} option={getOption()} />
//         )}
//       </div>
//     </>
//   );
// };

export default SpotPriceGraphModal;
