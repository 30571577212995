import React, { useEffect, useState } from "react";
import { CalendarOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import {
  capitalizeFirstLetterOfEachWord,
  extractYearFromDate,
} from "../../services/email_utils";
import { Icon } from "@iconify/react/dist/iconify.js";
import Swal from "sweetalert2";
import moment from "moment";

const CargoFirmOffers = () => {
  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddCargoFirmModalOpen, setIsAddCargoFirmModalOpen] = useState(false);
  const pageSize = 6;
  const [formData, setFormData] = useState({
    cargoQuantity: "",
    templateName: "",
    loadPort: "",
    dischargePort: "",
    laycan: "",
    operationType: "",
    loadRate: "",
    dischargeRate: "",
    owner: "",
    disponentOwner: "",
    beneficiaryAccount: "",
    beneficiaryBankDetails: "",
    charterers: "",
    loa: "",
    beam: "",
    dwt: "",
    hatches: "",
    grt: "",
    nrt: "",
    seaConsumption: "",
    portConsumption: "",
    freightRate: "",
    demurrageRate: "",
    despatchRate: "",
    addressComm: "",
    brokerComm: "",
    otherRates: "",
  });
  const [isEditDataClicked, setIsEditDataClicked] = useState("save");

  const columns = [
    {
      title: "Id",
      sortable: true,
      fixed: "left",
      headerStyle: (selector, id) => {
        return { width: "8px", textAlign: "center" };
      },
      render: (text, row, index) => (
        <div className="text-center">
          {(currentPage - 1) * pageSize + index + 1}
        </div>
      ),
      width: "3rem", //RDT provides index by default,
    },

    {
      label: "Template Name",
      title: "Template Name",
      fixed: "left",
      selector: (row) => row.templateName,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.templateName}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Cargo Quantity",
      title: "Cargo Quantity",
      selector: (row) => row.cargoQuantity,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.cargoQuantity}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Load Port",
      title: "Load Port",
      selector: (row) => row.loadPort,
      sortable: true,
      reorder: true,
      render: (row) => (
        <Tooltip title={row.loadPort} arrow>
          <div
            className="overflowText2"
            style={{ padding: "10px", width: "10rem" }}
          >
            {row.loadPort}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      label: "Discharge Port",
      title: "Discharge Port",
      selector: (row) => row.dischargePort,
      sortable: true,
      reorder: true,
      render: (row) => (
        <Tooltip title={row.dischargePort} arrow>
          <div
            className="overflowText2"
            style={{ padding: "10px", width: "10rem" }}
          >
            {row.dischargePort}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      label: "Laycan",
      title: "Laycan",
      selector: (row) => row.laycan,
      sortable: true,
      reorder: true,
      render: (row) => (
        <Tooltip title={row.laycan} arrow placement="top">
          <div
            className="overflowText2"
            style={{ padding: "10px", width: "10rem" }}
          >
            {row.laycan}
          </div>
        </Tooltip>
      ),
      width: "10rem",
    },
    {
      label: "Operation Type",
      title: "Operation Type",
      selector: (row) => row.operationType,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.operationType}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Load Rate",
      title: "Load Rate",
      selector: (row) => row.loadRate,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.loadRate}</div>
      ),
      width: "10rem",
    },
    {
      label: "Discharge Rate",
      title: "Discharge Rate",
      selector: (row) => row.dischargeRate,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.dischargeRate}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Owner",
      title: "Owner",
      selector: (row) => row.owner,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.owner}</div>
      ),
      width: "10rem",
    },
    {
      label: "Disponent Owner",
      title: "Disponent Owner",
      selector: (row) => row.disponentOwner,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.disponentOwner}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "LOA",
      title: "LOA",
      selector: (row) => row.loa,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.loa}</div>
      ),
      width: "10rem",
    },
    {
      label: "Beam",
      title: "Beam",
      selector: (row) => row.beam,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.beam}</div>
      ),
      width: "10rem",
    },
    {
      label: "DWT",
      title: "DWT",
      selector: (row) => row.dwt,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.dwt}</div>
      ),
      width: "10rem",
    },
    {
      label: "Hatches",
      title: "Hatches",
      selector: (row) => row.hatches,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.hatches}</div>
      ),
      width: "10rem",
    },
    {
      label: "GRT",
      title: "GRT",
      selector: (row) => row.grt,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.grt}</div>
      ),
      width: "10rem",
    },
    {
      label: "NRT",
      title: "NRT",
      selector: (row) => row.nrt,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.nrt}</div>
      ),
      width: "10rem",
    },
    {
      label: "Charterers",
      title: "Charterers",
      selector: (row) => row.charterers,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.charterers}</div>
      ),
      width: "10rem",
    },
    {
      label: "Sea Consumption",
      title: "Sea Consumption",
      selector: (row) => row.seaConsumption,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.seaConsumption}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Port Consumption",
      title: "Port Consumption",
      selector: (row) => row.portConsumption,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.portConsumption}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Beneficiary Account",
      title: "Beneficiary Account",
      selector: (row) => row.beneficiaryAccount,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.beneficiaryAccount}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Beneficiary Bank Details",
      title: "Beneficiary Bank Details",
      selector: (row) => row.beneficiaryBankDetails,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.beneficiaryBankDetails}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Freight Rate",
      title: "Freight Rate",
      selector: (row) => row.freightRate,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.freightRate}</div>
      ),
      width: "10rem",
    },
    {
      label: "Demurrage Rate",
      title: "Demurrage Rate",
      selector: (row) => row.demurrageRate,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.demurrageRate}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Despatch Rate",
      title: "Despatch Rate",
      selector: (row) => row.despatchRate,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.despatchRate}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Address Comm",
      title: "Address Comm",
      selector: (row) => row.addressComm,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.addressComm}</div>
      ),
      width: "10rem",
    },
    {
      label: "Broker Comm",
      title: "Broker Comm",
      selector: (row) => row.brokerComm,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.brokerComm}</div>
      ),
      width: "10rem",
    },
    {
      label: "Other Rates",
      title: "Other Rates",
      selector: (row) => row.otherRates,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.otherRates}</div>
      ),
      width: "10rem",
    },
    {
      label: "Remittance File URL",
      title: "Remittance File URL",
      selector: (row) => row.remittanceFileUrl,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>
          {row.remittanceFileUrl}
        </div>
      ),
      width: "10rem",
    },
    {
      label: "Company",
      title: "Company",
      selector: (row) => row.company,
      sortable: true,
      reorder: true,
      render: (row) => (
        <div style={{ padding: "10px", width: "10rem" }}>{row.company}</div>
      ),
      width: "10rem",
    },
    {
      title: "Actions",
      label: "Actions",
      left: true,
      // width: '200',
      fixed: "right",
      reorder: true,
      minWidth: "150px",

      render: (row) => (
        // <Button onClick={() => handleRowDelete(row)}></Button>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <div
              style={{ padding: "4px", cursor: "pointer" }}
              onClick={() => handleRowDelete(row)}
            >
              <Icon
                className="bg-icon-color"
                icon="tabler:trash"
                fontSize={16}
              />
            </div>
            <div
             style={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                handleEditData(row);
              }}
            >
              <Icon
                icon="tabler:edit"
                fontSize={16}
                className="bg-icon-color"
              />
            </div>
            {/* <div
              style={{ padding: "4px" }}
              onClick={() => {
                // handleEditData(row)
              }}
            >
              <Icon icon="tabler:eye" fontSize={16} className="bg-icon-color" />
            </div> */}
          </div>
        </>
      ),
      width: "6rem",
    },
  ];

  useEffect(() => {
    getAllCargoFirm();
  }, []);

  const getAllCargoFirm = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/cargo_firm_offer`;
      const response = await getAPICall(url);
      //   if (response) {
      //     dispatch(setSpinner(false));
      //   }
      if (response.status) {
        setFormattedData(response.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.templateName)
      newErrors.templateName = "Template name is required";
    if (!formData.cargoQuantity)
      newErrors.cargoQuantity = "Cargo Quantity is required";
    if (!formData.loadPort) newErrors.loadPort = "Load Port is required";
    if (!formData.dischargePort)
      newErrors.dischargePort = "Discharge Port is required";

    return newErrors;
  };

  const handleSubmitData = async () => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/cargo_firm_offer`;
        const response = await postAPICall(url, formData, "post");
        if (response?.status) {
          openNotificationWithIcon('success',"Template created successfully.")
          setIsAddCargoFirmModalOpen(false);
          getAllCargoFirm();
          setFormData({});
          setErrors({});
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async() =>{
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/update_cargo_firm_offer/${formData.id}`;
        const response = await postAPICall(url, formData, "put");
        if (response?.status) {
          openNotificationWithIcon('success',"Template updated successfully.")
          setIsAddCargoFirmModalOpen(false);
          getAllCargoFirm();
          setFormData({});
          setErrors({});
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRowDelete = (rowIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this template!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/delete_cargo_firm_offer/${rowIndex?.id}`;
        const response = await postAPICall(url, {}, "delete");
        if (response.status) {
          openNotificationWithIcon("success", "Template deleted successfully.");
          getAllCargoFirm();
        }
      }
    });
  };

  const handleEditData = (row) => {
    setFormData(row);
    setIsEditDataClicked("edit");
    setIsAddCargoFirmModalOpen(true);
  };

  const handleClose = () => {};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChangeData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      handleInputChangeData("laycan", dateRangeString);
    }
  };

  const handleDateChange = (dates, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");
      const dateRangeString = `${formattedStartDate} - ${formattedEndDate}`;
      handleInputChangeData("laycan", dateRangeString);
      //   setDateRange(dateRangeString)
      // handleChange({
      //   target: { name: "laycan", value: dateRangeString },
      // });
    }
  };

  

  

  return (
    <>
      <div>
        <div style={{ textAlign: "end", marginBottom: "10px" }}>
          <Button
            onClick={() => {
              setFormData({});
              setIsEditDataClicked("save");
              setIsAddCargoFirmModalOpen(true);
            }}
          >
            <Icon
              icon="ic:baseline-plus"
              className="bg-icon-color"
              fontSize={20}
            />
            <span>Add New </span>
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={formattedData}
          pagination={{ pageSize }}
          scroll={{ x: "max-content" }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Cargo Firm Offer"
        footer={null}
        width={1200}
        style={{ top: "50px" }}
        open={isAddCargoFirmModalOpen}
        onCancel={() => setIsAddCargoFirmModalOpen(false)}
      >
        <Form layout="vertical">
          <Row gutter={[16, 0]} style={{ padding: "0 1.2rem" }}>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Template Name: <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="templateName"
                  value={formData.templateName}
                  onChange={handleInputChange}
                  placeholder="Enter template name"
                />
                {/* {errors.templateName && <p style={errorStyle}>{errors.templateName}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Cargo Quantity: <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="cargoQuantity"
                  value={formData.cargoQuantity}
                  onChange={handleInputChange}
                  placeholder="Enter value for Cargo Qty"
                />
                {/* {errors.vesselName && <p style={errorStyle}>{errors.vesselName}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Load Port: <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="loadPort"
                  value={formData.loadPort}
                  onChange={handleInputChange}
                  placeholder="Enter Load Port"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    Discharge Port: <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Input
                  name="dischargePort"
                  value={formData.dischargePort}
                  onChange={handleInputChange}
                  placeholder="Enter Discharge Port"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            {isEditDataClicked === "edit" ? (
              <Col span={8}>
                <Popover
                  content={
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      onChange={handleDateChange}
                    />
                  }
                  trigger="click"
                  placement="bottomRight"
                >
                  <Input
                    value={formData.laycan}
                    placeholder="Laycan"
                    onChange={handleInputChange}
                    suffix={<CalendarOutlined />}
                  />
                </Popover>
              </Col>
            ) : (
              <Col span={8}>
                <Form.Item label="Laycan">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  onChange={(event, dateString) => {
                    onChange(event, "laycan", dateString);
                  }}
                />
                {/* {errors.openDate && <p style={errorStyle}>{errors.openDate}</p>} */}
              </Form.Item>
              </Col>
            )}
            {/* <Col span={8}>
              <Form.Item label="Laycan">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  onChange={(event, dateString) => {
                    console.log({ dateString,formData });
                    onChange(event, "laycan", dateString);
                  }}
                />
                {errors.openDate && <p style={errorStyle}>{errors.openDate}</p>}
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item label={<span>Operation Type</span>}>
                <Input
                  name="operationType"
                  value={formData.operationType}
                  onChange={handleInputChange}
                  placeholder="Enter value for Operation Type"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<span>Load Rate</span>}>
                <Input
                  name="loadRate"
                  value={formData.loadRate}
                  onChange={handleInputChange}
                  placeholder="Enter value for Load Rate"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<span>Discharge Rate</span>}>
                <Input
                  name="dischargeRate"
                  value={formData.dischargeRate}
                  onChange={handleInputChange}
                  placeholder="Enter value for Discharge Rate"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<span>Owner</span>}>
                <Input
                  name="owner"
                  value={formData.owner}
                  onChange={handleInputChange}
                  placeholder="Enter Owner Name"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<span>Disponent Owner:</span>}>
                <Input
                  name="disponentOwner"
                  value={formData.disponentOwner}
                  onChange={handleInputChange}
                  placeholder="Enter Disponent Owner Name"
                />
                {/* {errors.openArea && <p style={errorStyle}>{errors.openArea}</p>} */}
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.2)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
              padding: "1rem 1.2rem",
              margin: "1rem 0",
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Typography.Title level={5}>
                  Vessel Particulars
                </Typography.Title>
              </Col>
              <Col span={8}>
                <Form.Item label="LOA">
                  <Input
                    name="loa"
                    type="number"
                    value={formData.loa}
                    onChange={handleInputChange}
                    placeholder="Enter Value For LOA"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="BEAM">
                  <Input
                    value={formData.beam}
                    type="number"
                    name="beam"
                    onChange={handleInputChange}
                    placeholder="Enter Value for BEAM"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="DWT">
                  <Input
                    value={formData.dwt}
                    type="number"
                    name="dwt"
                    onChange={handleInputChange}
                    placeholder="Enter Value for DWT"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Hatches">
                  <Input
                    value={formData.hatches}
                    type="number"
                    name="hatches"
                    onChange={handleInputChange}
                    placeholder="Enter No. Of HATCHES"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="GRT">
                  <Input
                    value={formData.grt}
                    name="grt"
                    onChange={handleInputChange}
                    placeholder="Enter Value For GRT"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="NRT">
                  <Input
                    value={formData.nrt}
                    name="nrt"
                    onChange={handleInputChange}
                    placeholder="Enter Value For NRT"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Sea Consumption">
                  <Input
                    value={formData.seaConsumption}
                    type="number"
                    name="seaConsumption"
                    onChange={handleInputChange}
                    placeholder="Enter value for Sea Consumption"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Port Consumption">
                  <Input
                    value={formData.portConsumption}
                    type="number"
                    name="portConsumption"
                    onChange={handleInputChange}
                    placeholder="Enter value for Port Consumption"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div style={{ padding: "1rem 1.2rem" }}>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <Form.Item label="Beneficiary Account">
                  <Input
                    name="beneficiaryAccount"
                    value={formData.beneficiaryAccount}
                    onChange={handleInputChange}
                    placeholder="Enter Beneficiary Account"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Beneficiary Bank Details">
                  <Input
                    name="beneficiaryBankDetails"
                    value={formData.beneficiaryBankDetails}
                    onChange={handleInputChange}
                    placeholder="Enter Beneficiary Details"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Charterers">
                  <Input
                    name="charterers"
                    value={formData.charterers}
                    onChange={handleInputChange}
                    placeholder="Enter Name of Charterers"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.2)",
              //   borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
              padding: "1rem 1.2rem",
              margin: "1rem 0",
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Typography.Title level={5}>Laytime Details</Typography.Title>
              </Col>
              <Col span={8}>
                <Form.Item label="Freight Rate">
                  <Input
                    name="freightRate"
                    // type="number"
                    value={formData.freightRate}
                    onChange={handleInputChange}
                    placeholder="Enter value for Freight"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Demmurage Rate">
                  <Input
                    value={formData.demurrageRate}
                    // type="number"
                    name="demurrageRate"
                    onChange={handleInputChange}
                    placeholder="Enter Value for Demmurage Rate"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Dispatch Rate">
                  <Input
                    value={formData.despatchRate}
                    // type="number"
                    name="despatchRate"
                    onChange={handleInputChange}
                    placeholder="Enter Value for Dispatch Rate"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Address Commm">
                  <Input
                    value={formData.addressComm}
                    // type="number"
                    name="addressComm"
                    onChange={handleInputChange}
                    placeholder="Enter value for Address Comm"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Broker Comm">
                  <Input
                    value={formData.brokerComm}
                    name="brokerComm"
                    onChange={handleInputChange}
                    placeholder="Enter Value For Broker Comm"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Other Rates">
                  <Input
                    value={formData.otherRates}
                    name="otherRates"
                    onChange={handleInputChange}
                    placeholder="Enter Value For Other Rates"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div style={{ textAlign: "right", marginTop: "16px" }}>
            {isEditDataClicked === "edit" ? (
              <Button type="primary" onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmitData}>
                Save
              </Button>
            )}

            {/* <Button onClick={handleClose} style={{ marginLeft: "8px" }}>
              Close
            </Button> */}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CargoFirmOffers;
