import React, { useState } from 'react';
import { Modal, Table } from 'antd';


const BunkerPriceModal = (props) => {
    const [open, setOpen] = useState(true);
    const { bunkerDetails } = props;

    const responseData = bunkerDetails.map((data) => ({
        key: data.Port,
        Port: data.Port,
        Grade: data.Grade,
        Date: data.Date,
        Price: data.Price,
        NetChange: data.NetChange,
    }));


    const columns = [
        {
            title: 'Port',
            dataIndex: 'Port',
            key: 'Port',
            sorter: (a, b) => a.Port.localeCompare(b.Port),
        },
        {
            title: 'Grade',
            dataIndex: 'Grade',
            key: 'Grade',
            sorter: (a, b) => a.Grade.localeCompare(b.Grade),
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
            sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
        },
        {
            title: 'Price',
            dataIndex: 'Price',
            key: 'Price',
            sorter: (a, b) => a.Price - b.Price,
        },
        {
            title: 'Net Change',
            dataIndex: 'NetChange',
            key: 'NetChange',
            sorter: (a, b) => a.NetChange - b.NetChange,
        },
    ];

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>

            <Table
                columns={columns}
                dataSource={responseData}
                pagination={{ pageSize: 5 }}
                loading={!responseData.length}
                bordered

            />

        </>
    );
};

export default BunkerPriceModal;
