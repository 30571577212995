import { Icon } from "@iconify/react/dist/iconify.js";
import {
  AutoComplete,
  Avatar,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Input,
  List,
  Menu,
  message,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import {
  composeNewMail,
  setIsSearcihng,
  setVesselName,
} from "../../actions/emailActions";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import {
  capitaliseFirstLetter,
  chipStyles,
  disabledDate,
  EMAILS_PER_PAGE,
  events,
  formatNumber,
  formatTimestamp,
  getInitial,
  returnccbcc,
  sendWebSocketMessage,
  timeHandler,
} from "../mail-folder/constants";

// import MailRightbar from "./mailRightbar/MailRightbar";
import SearchFilters from "./SearchFilter";
import { debounce } from "lodash";
import MailRightbar from "../mail-folder/mailRightbar/MailRightbar";
import CurrentMailDrawer from "./CurrentMailDrawer";

const ListviewDrawer = ({
  page,
  setPage,
  handleSearch,
  searchData,
  mailType,
  setRefreshCount,
  refreshCount,
  mailData,
  setMailData,
  totalMailCount,
  paginationHandler,
  setTotalMailCount,
  fetchEmails,
  fetchEmailCount,
  setFilterPayload,
  isFilters,
  setIsfilters,
  setFilterData,
  filterData,
}) => {
  const allMails = useSelector((state) => state.email.allEmails);
  const sentMails = useSelector((state) => state.email.sentList);
  const draftMails = useSelector((state) => state.email.draftList);
  const binMails = useSelector((state) => state.email.binList);
  const archivedMails = useSelector((state) => state.email.archivedList);
  const importantMails = useSelector((state) => state.email.importantList);
  const spamMails = useSelector((state) => state.email.spamList);
  const labelMails = useSelector((state) => state.email.labelMailList);
  const importedMailList = useSelector((state) => state.email.importedMailList);
  const filteredData = useSelector((state) => state.email.filterData);
  const composeMails = useSelector((state) => state.email.composeMails);
  const filterPayload = useSelector((state) => state.email.filterPayload);

  // console.log("searchData0000000000000", isFiltering);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});
  const [currentMail, setCurrentMail] = useState({});
  const [openCurrentMail, setOpenCurrentMail] = useState(false);
  // const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [unique, setUnique] = useState([]);
  // const [mailData, setMailData] = useState(allMails);
  // const [filterData, setFilterData] = useState({ emails: [] });
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  // const [isFilters, setIsfilters] = useState(false);
  const [filterSpin, setFilterSpin] = useState(false);
  const [query, setQuery] = useState("");
  const [notMore, setNotMore] = useState(true);
  const [filterPagination, setFilterPagination] = useState({
    page: 1,
    pageSize: 50,
  });
  const [filterText, setFilterText] = useState({
    subject: "",
    sender: ""
  })

  const hasMounted = useRef(false);

  // console.log({ mailData });

  const location = useLocation();
  useEffect(() => {
    setSelectedItems([]);
    setSelectAll(false);
    handleCurrentMailClose();
    setFilters({
      Invoice: [],
      Sender: [],
      attach: [],
      comments: [],
      date: [],
      mailType: [],
      reciver: [],
      subject: [],
      text: [],
      viewedBy: [],
      tags: [],
      date: [],
    });
  }, [location]);

  const [filters, setFilters] = useState({
    Invoice: [],
    Sender: [],
    attach: [],
    comments: [],
    date: [],
    mailType: [],
    reciver: [],
    subject: [],
    text: [],
    viewedBy: [],
    tags: [],
    date: [],
  });

  const { label_name, folder_name, imported_mail_identifier } = useParams();

  const handleDateSearch = async (range) => {
    try {
      setFilterSpin(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/get-date-filter?page=${filterPagination.page}&pageSize=${filterPagination.pageSize}`;
      const payload = {
        date_range: range,
        owner_email: "*",
      };

      const resp = await postAPICall(url, payload, "POST", (value) => {
        if (value?.data?.length > 0) {
          // console.log(value);
          setIsfilters(false);
          setMailData(value?.data);
          setFilterSpin(false);
        } else {
          openNotificationWithIcon(
            "info",
            "No data found for the choosen date."
          );
          setFilterSpin(false);
        }
      });
    } catch (err) {
      console.log(err);
      setFilterSpin(false);
    }
  };

  const handleDateFilters = (date) => {
    if (!date || !date[0] || !date[1]) {
      return fetchEmails(mailType);
    }
    handleDateSearch(
      dayjs(date?.[0]).format("YYYY/MM/DD") +
      "-" +
      dayjs(date?.[1]).format("YYYY/MM/DD")
    );
  };

  const menu = (
    <Menu>
      <RangePicker
        onChange={(value) => handleDateFilters(value)}
        allowClear
        showTime
        showSecond={false}
        disabledDate={disabledDate}
      />
    </Menu>
  );

  // useEffect(() => {
  //   console.log({searchData,mailType,imported_mail_identifier,})
  //   if (searchData.length > 0) {
  //     setMailData(searchData);
  //     return;
  //   } else if (mailType === "sent") {
  //     setMailData(sentMails);
  //     return;
  //   } else if (mailType === "draft") {
  //     setMailData(draftMails);
  //     return;
  //   } else if (mailType === "inbox" && imported_mail_identifier) {
  //     setMailData(importedMailList);
  //     return;
  //   } else if (mailType === "bin") {
  //     setMailData(binMails);
  //     return;
  //   } else if (mailType === "archived") {
  //     setMailData(archivedMails);
  //     return;
  //   } else if (mailType === "important") {
  //     setMailData(importantMails);
  //     return;
  //   } else if (mailType === "spam") {
  //     setMailData(spamMails);
  //     return;
  //   } else if (mailType === "labels") {
  //     setMailData(labelMails);
  //     return;
  //   } else if (isFilters) {
  //     setMailData(filterData.emails);
  //     return
  //   } else {
  //     setMailData(allMails);
  //   }
  // }, [searchData, allMails, mailType, filterPagination, filterData, isFilters,draftMails,importedMailList]);

  const rowClassName = (record, index) => {
    // Apply a different class for each index modulo 3
    return `row-hover-${(index % 3) + 1}`;
  };

  // const loadMoreData = () => {
  //   // if (loading) {
  //   //   return;
  //   // }

  //   if (isFilters) {
  //     setFilterPagination({
  //       ...filterPagination,
  //       page: filterPagination.page + 1,
  //     });
  //   } else {
  //     setPage(page + 1);
  //   }
  // };

  useEffect(() => {
    findUniqueData();
  }, []);

  const handleMailClick = (mail) => {
    if (mailType === "draft" && !mail.labelIds.includes("INBOX")) {
      const data = {
        composedData: {
          Date: mail.Date || "",
          to: mail.to || [],
          cc: mail.cc || [],
          bcc: mail.bcc || [],
          body: mail.body || "",
          subject: mail.subject || "",
          attachments: mail.attachments || [],
          //   editorStateKey: EditorState.createEmpty(),
          HTMLBody: undefined,
          owner_email: mail.owner_email || "",
          ThreadID: mail.ThreadID || "",
          GmailMessageId: mail.GmailMessageId || "",
          listOfFile: mail.listOfFile || [],
          totalSize: 0,
          uid: uuidv4(),
          _id: mail._id || null,
        },
        max: false,
        min: false,
      };
      const isPresent = composeMails?.some(
        (item) => item.composedData._id === mail._id
      );
      if (!isPresent) {
        dispatch(composeNewMail(data));
      }
    } else {
      setSelectedMail(mail);
      setCurrentMail(mail);
      setOpenCurrentMail(true);
      dispatch(setVesselName({ vesselName: "", isVisible: false }));
      if (!mail?.isRead) {
        const payload = {
          GmailMessageId: mail?.GmailMessageId,
          _id: mail?._id,
          context: mail?.client,
          isRead: true,
          event: "markRead",
        };
        handleMarkMailIsRead(payload);
      }
    }
  };

  const handleCurrentMailClose = () => {
    setOpenCurrentMail(false);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems(mailData?.map((item) => item));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (e, email) => {
    e.stopPropagation();
    // Use a functional update to ensure you're working with the latest state.
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(email)) {
        // Remove the email if it's already selected
        return prevSelectedItems.filter((item) => item !== email);
      } else {
        // Add the email if it's not selected
        return [...prevSelectedItems, email];
      }
    });
  };

  const markImportant = (userInpu1) => {
    // Destructure the userInput and event from the input object
    const { userInput, event } = userInpu1;
    event.stopPropagation();

    const { _id, important, client } = userInput;

    // Prepare the payload to be sent via WebSocket
    const payload = {
      context: client,
      _id,
      important: !important, // Toggle the important value
      event: "markImportant",
    };

    // Send the event to the WebSocket
    events.markImportant(payload);
    // Listening for WebSocket messages
    window.mySocketMail.onmessage = (event) => {
      events.handleAcknowledgment(event, (isSuccess) => {
        if (isSuccess) {
          openNotificationWithIcon("success", "Marked as Important!");
          const updatedMails = mailData.map((mail) => {
            if (mail._id === _id) {
              return {
                ...mail,
                important: !mail.important, // Toggle the important value
              };
            }
            return mail;
          });

          setMailData(updatedMails);
        } else {
          message.error("Something went wrong. Please try again!");
        }
      });
    };
  };

  const markSpam = (userInpu1) => {
    // Destructure the userInput and event from the input object
    const { userInput, event } = userInpu1;
    event.stopPropagation();

    const { _id, GmailMessageId, client } = userInput;

    // Prepare the payload to be sent via WebSocket
    const payload = {
      GmailMessageId,
      _id,
      Spam: true, // Mark as spam
      event: "spamMail",
    };

    // Send the event to the WebSocket and wait for acknowledgment
    events.markSpam(payload);

    // Listening for WebSocket messages
    window.mySocketMail.onmessage = (event) => {
      events.handleAcknowledgment(event, (isSuccess) => {
        if (isSuccess) {
          const updatedMails = mailData.filter((mail) => mail._id !== _id);
          setMailData(updatedMails);
          openNotificationWithIcon("success", "Moved to Spam!");
        } else {
          message.error("Something went wrong. Please try again!");
        }
      });
    };
  };

  const handleDeleteMail = async (GmailMessageId, _id, client) => {
    try {
      const payLoad = {
        GmailMessageId,
        _id,
        context: client,
        trashed: true,
        permanent: false,
        event: "deleteMail",
      };

      // Check if webSocketManager is available and WebSocket is open
      const webSocketManager = window?.webSocketManager;
      if (
        webSocketManager &&
        webSocketManager.socket.readyState === WebSocket.OPEN
      ) {
        // Create a unique responseId to track this request
        const responseId = Date.now();

        // Create a promise to handle the delete response
        const responsePromise = new Promise((resolve, reject) => {
          // Define a temporary event handler
          const handleResponse = (response) => {
            // console.log({ response });
            // if (response?.responseId === responseId) {
            if (response?.content?.AcknowledgmentID) {
              openNotificationWithIcon("success", "Mail successfully deleted");
              setCurrentMail({});
              handleCurrentMailClose();
              const filteredMails = mailData?.filter(
                (mail) => mail?._id !== _id
              );
              setMailData(filteredMails);
              setTotalMailCount(filteredMails?.length)
            } else {
              message.error("Failed to delete mail");
            }
            resolve(response);
            // Remove the event listener after handling the response
            webSocketManager.off("deleteMail", handleResponse);
            // }
          };

          // Register the temporary event handler
          webSocketManager.on("deleteMail", handleResponse);
        });

        // Send payload with the unique responseId
        await webSocketManager.send({ ...payLoad, responseId });

        // Await the response promise
        await responsePromise;
      } else {
        message.error("Please try again.");
        console.error("WebSocket is not open or not initialized.");
      }
    } catch (error) {
      console.error("WebSocket error:", error);
      message.error("Failed to delete mail due to WebSocket error");
    }
  };

  const handleDeleteConfirmation = (mailDetail) => {
    // console.log({mailDetail})
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMail(
          mailDetail.GmailMessageId,
          mailDetail._id,
          mailDetail.client
        );
        // handleDeleteMail(mailDetail: {})
      }
    });
  };

  const findUniqueData = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/find-unique`;
      const payload = { owner_email: "*" };
      await postAPICall(url, payload, "post", (data) => {
        if (data.data) {
          setUnique(data?.data);
        } else {
          openNotificationWithIcon('info', data?.msg)
        }
      });
    } catch (e) {
      console.error("Error fetching unique data:", e);
    }
  };

  // const handleViewedBy = async (value) => {
  //   try {
  //     const trimmedValue = value.trim();

  //     if (trimmedValue.length > 1) {
  //       const url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${trimmedValue}`;

  //       const response = await postAPICall(url, {}, "get", (data) => {
  //         const options = data?.emails?.map((mail) => ({
  //           label: mail,
  //           value: mail,
  //         }));
  //         setUnique((prev) => ({
  //           ...prev,
  //           viewMails: options,
  //         }));
  //       });
  //     }
  //   } catch (e) {
  //     console.error("Error fetching data:", e);
  //   }
  // };

  const handleViewedBy = debounce(async (value) => {
    try {
      const trimmedValue = value.trim();

      if (trimmedValue.length > 1) {
        const url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${trimmedValue}`;

        const response = await postAPICall(url, {}, "get", (data) => {
          const options = data?.emails?.map((mail) => ({
            label: mail,
            value: mail,
          }));
          setUnique((prev) => ({
            ...prev,
            viewMails: options,
          }));
        });
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  }, 400);

  useEffect(() => {
    return () => {
      handleViewedBy.cancel();
    };
  }, []);

  const handleChange = (name, value) => {
    if (value) {
      // setFilters((prevFilters) => ({
      //   ...prevFilters,
      //   [name]: [...prevFilters[name], value], // Update the specific filter dynamically
      // }));
      if (filters[name].includes(value)) {
        openNotificationWithIcon("info", "Value already exists in the filter.");
        return;
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: [...prevFilters[name], value], // Update the specific filter dynamically
        }));
        setIsfilters(true);
      }
      // setFilters((prevFilters) => {
      // //   // Check if the value already exists in the specific filter array
      // //   if (prevFilters[name].includes(value)) {
      // //     openNotificationWithIcon("info", "Value already exists in the filter.");
      // //     return prevFilters; 
      // //   }

      // return {
      //   ...prevFilters,
      //   [name]: [...prevFilters[name], value],
      // };
      // // });
    } else {
      openNotificationWithIcon("info", "Put Some Value For Filter.");
    }
  };

  // const handleChipClose = (item, name) => {

  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: prevFilters[name].filter((val) => val !== item), // Remove the specific filter value
  //   }));
  //   if (isFilters) {
  //     checkFilterIsEmpty(filters);
  //   }
  // };

  const handleChipClose = (item, name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: prevFilters[name].filter((val) => val !== item), // Remove the specific filter value
    }));
    const updatedFilterValues = {
      ...(filterPayload?.query?.[0] || []),
      [name]: filterPayload?.query?.[0]?.[name]?.filter((val) => val !== item),
    };
    dispatch(setFilterPayload({ owner_email: "*", updatedFilterValues }))
  };

  const mergeAndRemoveDuplicates = (arr1, arr2) => {
    return [...new Set([...(arr1 || []), ...(arr2 || [])])];
  };

  const checkFilterIsEmpty = (obj) => {
    const allEmpty = Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );



    if (allEmpty) {
      setPage(1);
      setIsfilters(false);
      dispatch(setFilterPayload({}));
      if (mailType?.trim().length > 0) {
        fetchEmails(mailType);
        fetchEmailCount(mailType);
      }
    } else {
      // const updatedFilters = { ...filters };
      const updatedFilters = {
        ...filterPayload?.query?.[0],
        ...filters,
        Sender: mergeAndRemoveDuplicates(filters.Sender, (filterPayload?.query?.[0]?.Sender || [])),
        date: mergeAndRemoveDuplicates(filters.date, (filterPayload?.query?.[0]?.date || [])),
      };
      const payload = {
        owner_email: "*",
        query: [updatedFilters], // Use the cloned filters object
      };
      dispatch(setFilterPayload(payload));
      // handleFilterSubmit();
    }
  };

  useEffect(() => {
    if (isFilters) {
      checkFilterIsEmpty(filters);
    }
  }, [isFilters, filters]);


  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = dayjs(new Date(dates[0])).format("YYYY/MM/DD");
      const formattedEndDate = dayjs(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      if (value) {
        setFilters((prev) => ({
          ...prev,
          [value]: [...(prev[value] || []), dateRangeString],
        }));
        setIsfilters(true);
      }
    }
  };

  const renderSelectedChips = (filterKey, filterValues) => {
    return filterValues.length ? (
      filterValues?.map((item) => (
        <Tag
          color="processing"
          key={item}
          closable
          onClose={() => handleChipClose(item, filterKey)}
        >
          {item}
        </Tag>
      ))
    ) : (
      <span style={{ fontSize: "12px", color: "lightgray" }}>
        No Active Filter
      </span>
    );
  };

  const handleSelectChange = (value, field) => {
    setFilters((prev) => ({
      ...prev,
      [field]: Array.isArray(value) ? value : [value], // Always store as array
    }));
    setIsfilters(true);
  };

  const handleFilterSubmit = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/getFIlterTonnageMails?page=${page}&pageSize=${filterPagination.pageSize}`;
      const updatedFilters = { ...filters };
      // dispatch(setFilterPayload(updatedFilters))
      const payload = {
        owner_email: "*",
        query: [updatedFilters], // Use the cloned filters object
      };
      dispatch(setFilterPayload(payload));
      setIsfilters(true);
      // Check if any filter has data
      const hasData = Object.values(payload.query[0]).some((field) =>
        Array.isArray(field) ? field.length > 0 : field
      );

      if (hasData) {
        setIsfilters(true);
        setFilterSpin(true);
        const response = await postAPICall(url, payload, "post", (data) => {
          // console.log("oapoapisadaoapoapisada", data);
          const newMails = data?.emails;

          if (newMails?.length > 0) {
            // setFilterData((prev) => ({
            //   ...prev,
            //   emails: [...(prev.emails || []), ...newMails],
            // }));
            dispatch(setFilterData(data));
            setMailData(newMails);
            setTotalMailCount(data?.count_after_filter);
            setFilterSpin(false);
          } else {
            setMailData([])
            setNotMore(false);
            // openNotificationWithIcon("info", "No data available");
            setFilterSpin(false);
          }
        });
      } else {
        setIsfilters(false);
        setMailData(mailData);
      }
    } catch (e) {
      console.error("Error filtering data:", e);
      setFilterSpin(false);
    }
  };

  // useEffect(() => {
  //   handleFilterSubmit();
  // }, [filters, filterPagination, isFilters]);

  const TagsPopover = ({ item }) => {
    const content = (
      <div
        className="color-even-odd"
        style={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "3px",
          maxWidth: "500px",
        }}
      >
        {item?.tags?.length > 0
          ? item?.tags?.map((tag, index) => (
            <span
              style={{
                textAlign: "center",
                marginBottom: "7px",
                backgroundColor:
                  chipStyles[index % chipStyles.length].backgroundColor,
                color: chipStyles[index % chipStyles.length].color,
                borderColor: chipStyles[index % chipStyles.length].color,
              }}
              className="list_view_tags"
              key={index}
            >
              {tag}
            </span>
          ))
          : "No Tags Available"}
      </div>
    );

    return (
      <Popover
        content={content}
        title="Tags"
        arrow
        trigger={["click", "hover"]}
      >
        {item?.tags?.length > 0 ? (
          <>
            {item?.tags.slice(0, 2).map((tag, index) => (
              <span
                className="list_view_tags"
                key={index}
                style={{
                  backgroundColor:
                    chipStyles[index % chipStyles.length].backgroundColor,
                  color: chipStyles[index % chipStyles.length].color,
                  borderColor: chipStyles[index % chipStyles.length].color,
                }}
              >
                {tag}
              </span>
            ))}
            {item?.tags.length > 2 && (
              <span style={{ color: "#12406a", fontWeight: "500" }}>
                +{item.tags.length - 2}{" "}
              </span>
            )}
          </>
        ) : (
          "No Tag"
        )}
      </Popover>
    );
  };
  const EmailViewedByPopover = ({ item }) => {
    const content = (
      <div
        className="color-even-odd"
        style={{
          columnGap: "3px",
          maxWidth: "500px",
          minWidth: "200px",
          maxHeight: "300px",
          overflow: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {Array.isArray(item?.readBy) && item?.readBy?.length > 0 ? (
          item?.readBy?.map((email, index) => (
            <div
              style={{
                textAlign: "start",
                marginBottom: "7px",
              }}
              className="list_view_tags"
              key={index}
            >
              {index + 1}. {email}
            </div>
          ))
        ) : item?.readBy ? (
          <div className="list_view_tags">1. {item?.readBy}</div>
        ) : (
          "N/A"
        )}
      </div>
    );

    return (
      <Popover
        content={content}
        title="Email Read By"
        arrow
        trigger={["click", "hover"]}
        onClick={(e) => e.stopPropagation()}
      >
        {Array.isArray(item?.readBy) && item?.readBy?.length > 0 ? (
          <span style={{ display: "flex", justifyContent: "center" }}>
            {item?.readBy?.slice(0, 2).map((email, index) => (
              <span
                className="list_view_tags"
                style={{ maxWidth: "125px" }}
                key={index}
              >
                {email}
              </span>
            ))}
            {item?.readBy.length > 1 && (
              <span style={{ color: "#12406a", fontWeight: "500" }}>
                +{item.readBy.length - 1}{" "}
              </span>
            )}
          </span>
        ) : item?.readBy ? (
          <span className="list_view_tags" style={{ maxWidth: "125px" }}>
            {String(item?.readBy)}
          </span>
        ) : (
          <span className="ReadBy-text">N/A</span>
        )}
      </Popover>
    );
  };

  const ListCheckbox = ({ item }) => {
    const content = <div>HIIII</div>;
    return (
      <Popover content={content} trigger={["click"]}>
        <div>
          <Checkbox
            key={item["_id"] || item?.id}
            checked={selectedItems.includes(item)}
            // onChange={(e) => {
            //   e.stopPropagation();
            // }}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(e, item);
            }}
            style={{ marginRight: "10px" }}
          />
        </div>
      </Popover>
    );
  };

  const handleBulkAction = (eventType) => {
    let payload = [];

    if (eventType === "markRead" || eventType === "markAsUnread") {
      payload = selectedItems?.map((email) => ({
        context: "fix@theoceann.ai", // This vlaue is static here because Backend dev(ARUN) Told me that it will not be a problem.
        event: "markRead",
        isRead: eventType === "markRead" ? true : false,
        _id: email["_id"] || email["id"],
      }));
    } else if (eventType === "deleteMail") {
      payload = selectedItems?.map((email) => ({
        GmailMessageId: email?.GmailMessageId,
        context: "inbox",
        event: eventType,
        inbox: "fix@theoceann.ai",
        _id: email["_id"] || email["id"],
      }));
    } else if (eventType === "spamMail") {
      payload = selectedItems?.map((email) => ({
        GmailMessageId: email?.GmailMessageId,
        Spam: true,
        context: "fix@theoceann.ai",
        event: eventType,
        _id: email["_id"] || email["id"],
      }));
    } else if (
      eventType === "markImportant" ||
      eventType === "markAsUnimportant"
    ) {
      payload = selectedItems?.map((email) => ({
        important: eventType === "markImportant" ? true : false,
        context: "fix@theoceann.ai",
        event: "markImportant",
        _id: email["_id"] || email["id"],
      }));
    }

    // console.log({payload})
    if (
      window.mySocketMail &&
      window.mySocketMail.readyState === WebSocket.OPEN
    ) {
      // Send the payload via WebSocket
      setFilterSpin(true);
      window.mySocketMail.send(JSON.stringify(payload));

      // Set up a listener for the acknowledgment
      window.mySocketMail.onmessage = function (event) {
        const message = JSON.parse(event.data);

        // console.log(" ==== >>>> ", message);
        if (message?.content?.AcknowledgmentID) {
          openNotificationWithIcon("info", "Operation Successful");

          // Update state based on eventType
          if (eventType === "deleteMail") {
            // Remove deleted emails from the state
            // console.log({selectedItems,mailData})
            const updatedEmails = mailData.filter((email) => {
              const selectedIds = new Set(
                selectedItems.map((selected) => selected._id)
              );
              const shouldKeep = !selectedIds.has(email._id);
              return shouldKeep;
            });
            setMailData(updatedEmails);
            // setMailData((prevEmails) =>
            //   prevEmails.filter(
            //     (email) =>
            //       !selectedItems.some(
            //         (selected) =>
            //           selected._id === email._id || selected.id === email.id
            //       )
            //   )
            // );
          } else if (eventType === "spamMail") {
            // Remove spammed emails from the state
            setMailData((prevEmails) =>
              prevEmails.filter(
                (email) =>
                  !selectedItems.some(
                    (selected) =>
                      selected._id === email._id || selected.id === email.id
                  )
              )
            );
          } else if (eventType === "markRead" || eventType === "markAsUnread") {
            // Update the isRead status of the emails
            setMailData((prevEmails) =>
              prevEmails.map((email) =>
                selectedItems.some(
                  (selected) =>
                    selected._id === email._id || selected.id === email.id
                )
                  ? { ...email, isRead: eventType === "markRead" }
                  : email
              )
            );
          } else if (eventType === "markImportant") {
            // Add important: true to the marked important emails
            setMailData((prevEmails) =>
              prevEmails.map((email) =>
                selectedItems.some(
                  (selected) =>
                    selected._id === email._id || selected.id === email.id
                )
                  ? { ...email, important: true }
                  : email
              )
            );
          } else if (eventType === "markAsUnimportant") {
            // Remove important key if unmarked as important
            setMailData((prevEmails) =>
              prevEmails.map((email) =>
                selectedItems.some(
                  (selected) =>
                    selected._id === email._id || selected.id === email.id
                )
                  ? { ...email, important: false }
                  : email
              )
            );
          }

          setSelectedItems([]);
          setFilterSpin(false);
        } else {
          console.error("Acknowledgment not received.");
          setFilterSpin(false);
        }
      };
    } else {
      console.error("WebSocket connection is not open");
      setFilterSpin(false);
    }
  };

  // Handler functions for different actions
  const handleMarkAsRead = () => handleBulkAction("markRead");
  const handleMarkAsUnread = () => handleBulkAction("markAsUnread");
  const handleDeleteMails = () => handleBulkAction("deleteMail");
  const handleMoveToSpam = () => handleBulkAction("spamMail");
  const handleMarkAsImportant = () => handleBulkAction("markImportant");
  const handleMarkAsUnimportant = () => handleBulkAction("markAsUnimportant");

  const handleFilterSearch = (query) => {
    const trimmedQuery = query.replace(/^\s+/, "");
    setQuery(trimmedQuery);
    if (trimmedQuery.length > 0) {
      handleSearch(trimmedQuery);
      dispatch(setIsSearcihng(true));
    } else {
      openNotificationWithIcon("info", "Please Enter some text to search.");
      setMailData(allMails);
    }
  };

  const handleSearchingTyping = (query) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery.length > 0) {
      dispatch(setIsSearcihng(true));
    } else {
      setMailData(allMails);
    }
  };

  const handleMarkMailIsRead = async (payload) => {
    const handleAcknowledgment = (response) => {
      if (response?.content?.AcknowledgmentID) {
        // message.success("Email Marked as Read.");
        const userData = localStorage.getItem("oceanToken")
          ? JSON.parse(
            atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
          )
          : null;
        const updatedMails = mailData.map((mail) => {
          if (mail._id === response?.content?.AcknowledgmentID) {
            return {
              ...mail,
              isRead: !mail.isRead, // Toggle the important value
              readBy: mail?.readBy
                ? [...mail?.readBy, userData?.email]
                : [userData?.email],
            };
          }
          return mail;
        });
        setMailData(updatedMails);
        // return response;
        return true;
      } else {
        // message.error("Failed to send mail");
        return false;
      }
    };

    await sendWebSocketMessage("markRead", payload, handleAcknowledgment);
  };
  const getTimestamp = (item) => {
    if (item.Threads && item.Threads.length > 0) {
      return item.Threads[item.Threads.length - 1].TIMESTAMP;
    }
    return item.Formatted_Date || item.TIMESTAMP;
  };

  const displayTime = (item) => {
    const timestamp = getTimestamp(item);
    return timestamp ? formatTimestamp(timestamp) : timeHandler(item);
  };

  // const paginationHandler = (action) => {
  //   if (action === "next" && page < totalMailCount) {
  //     setPage(page+1)
  //   } else if (action === "prev" && page > 1) {
  //     setPage(page-1)
  //   }
  // };

  // function checkFilterIsEmpty(obj) {
  //   const allEmpty = Object.values(obj).every(
  //     (arr) => Array.isArray(arr) && arr.length === 0
  //   );

  //   if (allEmpty) {
  //     setPage(1);
  //     setIsfilters(false);
  //     dispatch(setFilterPayload([]));
  //     if (mailType?.trim().length > 0) {
  //       fetchEmails(mailType);
  //       fetchEmailCount(mailType);
  //     }
  //   } else {
  //     handleFilterSubmit();
  //   }
  // }

  // useEffect(() => {
  //   if (hasMounted.current) {
  //     checkFilterIsEmpty(filters);
  //   } else {
  //     hasMounted.current = true;
  //   }
  // }, [filters]);

  return (
    <div className="list-view">
      {!openCurrentMail ? (
        <>
          {selectedItems?.length > 0 && (
            <div className="selected_items_box">
              <Tooltip title="Marked Read.">
                <div onClick={handleMarkAsRead}>
                  <Icon icon={"tabler:mail-opened"} />
                </div>
              </Tooltip>

              <Tooltip title="Marked as Unread.">
                <div onClick={handleMarkAsUnread}>
                  <Icon icon={"tabler:mail"} />
                </div>
              </Tooltip>

              <Tooltip title="Delete Selected Mails.">
                <Popconfirm
                  title="Delete Selected Mails"
                  description="Are you sure to delete these mail?"
                  onConfirm={handleDeleteMails}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <div>
                    <Icon
                      icon={"tabler:trash"}
                      style={{ color: "red" }}
                    // onClick={handleDeleteMails}
                    />
                  </div>
                </Popconfirm>
              </Tooltip>

              <Tooltip title="Move to Spam.">
                <div onClick={handleMoveToSpam}>
                  <Icon
                    icon={"tabler:alert-octagon"}
                    style={{ color: "gray" }}
                  />
                </div>
              </Tooltip>

              <Tooltip title="Mark as Unimportant.">
                <div onClick={handleMarkAsUnimportant}>
                  <Icon
                    icon={"tabler:star-filled"}
                    style={{ color: "goldenrod" }}
                  />
                </div>
              </Tooltip>

              <Tooltip title="Mark as Important.">
                <div onClick={handleMarkAsImportant}>
                  <Icon icon={"tabler:star"} style={{ color: "goldenrod" }} />
                </div>
              </Tooltip>
            </div>
          )}

          <div className="list-view-filter-table">
            <table>
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div className="mail_filter_row_box">
                        <div className="mail_filter_row">
                          <div
                            className="filter_icons"
                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                          >
                            <Icon icon={"tabler:filter"} />
                          </div>

                          <Drawer
                            mask={false}
                            className="mail_filter_drawer"
                            title="Search Filters :"
                            onClose={() => setIsFilterOpen(false)}
                            open={isFilterOpen}
                          >
                            <SearchFilters />
                          </Drawer>
                          <div style={{ width: "50%" }}>
                            <Input
                              type="text"
                              placeholder="Search for emails..."
                              onPressEnter={(e) =>
                                handleFilterSearch(e.target.value)
                              }
                              onChange={(e) =>
                                handleSearchingTyping(e.target.value)
                              }
                            />
                          </div>
                          <Dropdown
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomLeft"
                            arrow
                          >
                            <div className="filter_icons">
                              <Icon icon={"tabler:calendar"} />
                            </div>
                          </Dropdown>
                        </div>
                      </div>

                      <div className="mail-pagination">
                        <span>
                          {Math.min(
                            (page - 1) * EMAILS_PER_PAGE + 1,
                            totalMailCount
                          )}{" "}
                          to {Math.min(page * EMAILS_PER_PAGE, totalMailCount)}{" "}
                          of {formatNumber(totalMailCount || 0)}
                        </span>
                        <span
                          // className="mr-2"
                          style={{
                            // border: "1px solid gray",
                            // borderRadius: "10px",
                            // padding: "2px 5px",
                            cursor: "pointer",
                          }}
                          onClick={() => paginationHandler("prev")}
                        >
                          <Icon
                            style={{ color: "#12406a", fontSize: "29px" }}
                            className=""
                            icon={"ion:chevron-back-circle"}
                          />
                        </span>
                        <span
                          style={{
                            // border: "1px solid gray",
                            // borderRadius: "10px",
                            // padding: "2px 5px",
                            cursor: "pointer",
                          }}
                          onClick={() => paginationHandler("next")}
                        >
                          <Icon
                            style={{ color: "#12406a", fontSize: "29px" }}
                            className=""
                            icon={"fluent:chevron-circle-right-48-filled"}
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <td
                    style={{
                      paddingTop: "25px",
                      paddingLeft: "15px",
                      width: "4%",
                    }}
                  >
                    <Checkbox
                      id="selectall"
                      onChange={handleSelectAll}
                      checked={selectAll}
                    />
                  </td>
                  <td style={{ width: "10%" }}>
                    <div>
                      <b>Received Date</b>
                    </div>
                    <RangePicker
                      value={null}
                      disabledDate={disabledDate}
                      onChange={(event, dateString) =>
                        onChange(event, "date", dateString)
                      }
                    />
                  </td>
                  <td style={{ width: "16%" }}>
                    <div>
                      <b>Sender</b>
                    </div>
                    <AutoComplete
                      placeholder="Search Sender"
                      style={{ width: 200 }}
                      onSearch={handleViewedBy}
                      onSelect={(value) => handleChange("Sender", value)}
                      options={unique?.viewMails || []}
                      // onChange={(value)=>setFilterText({...filterText,sender:value})}
                      // value={filterText?.sender}
                    />
                  </td>
                  <td style={{ width: "21%" }}>
                    <div>
                      <b>Subject</b>
                    </div>
                    <Input
                      placeholder="Subject"
                      // onChange={(e)=>setFilterText({...filterText,subject:e.target.value})}
                      onPressEnter={(e) =>
                        handleChange("subject", e.target?.value)
                      }
                      // onBlur={(e) => handleChange("subject", e.target.value)}
                      // value={filters?.subject[0]}
                      // value={filterText?.subject}
                    />
                  </td>
                  <td style={{ width: "13%" }}>
                    <div>
                      <b>Viewed By</b>
                    </div>
                    <AutoComplete
                      placeholder="Search Viewed by"
                      onSearch={handleViewedBy}
                      allowClear
                      // value={filters?.viewedBy[0]}
                      onSelect={(value) => handleChange("viewedBy", value)}
                      options={unique?.viewMails || []}
                    />
                  </td>
                  <td style={{ width: "12%" }}>
                    <div>
                      <b>Tags</b>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      maxTagCount={0}
                      mode="multiple"
                      placeholder="Choose Tags"
                      value={filters?.tags}
                      onChange={(value) => handleSelectChange(value, "tags")}
                    >
                      {unique?.unique_tags_mails?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: "12%" }}>
                    <div>
                      <b>Mail type</b>
                    </div>
                    <Select
                      placeholder="Mail Type"
                      mode="multiple"
                      maxTagCount={0}
                      value={filters?.mailType}
                      onChange={(value) =>
                        handleSelectChange(value, "mailType")
                      }
                    >
                      <Select.Option value="cargo">Cargo</Select.Option>
                      <Select.Option value="tonnage">Tonnage</Select.Option>
                      <Select.Option value="fixture">Fixture</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </td>
                  <td style={{ width: "12%" }}>
                    <div>
                      <b>Receivers</b>
                    </div>
                    <Select
                      allowClear
                      maxTagCount={0}
                      value={filters?.reciver}
                      showSearch
                      mode="multiple"
                      placeholder="Choose Receivers"
                      onChange={(value) => handleSelectChange(value, "reciver")}
                    >
                      {unique?.unique_receiver_mails?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr> */}

                {/* Second row for displaying selected filters */}
                {/* <tr className="chips-table-row">
                  <td style={{ width: "89px" }}></td>
                  <td>{renderSelectedChips("date", filters.date)}</td>
                  <td>{renderSelectedChips("Sender", filters.Sender)}</td>
                  <td>{renderSelectedChips("subject", filters.subject)}</td>
                  <td>{renderSelectedChips("viewedBy", filters.viewedBy)}</td>
                  <td>{renderSelectedChips("tags", filters.tags)}</td>
                  <td>{renderSelectedChips("mailType", filters.mailType)}</td>
                  <td>{renderSelectedChips("reciver", filters.reciver)}</td>
                </tr> */}
              </tbody>
            </table>
          </div>

          {/* <div
            className="list-view-title-row-box"
            style={{ position: "sticky", top: "0" }}
          >
            <table className="list-view-title-row">
              <tbody>
                <tr>
                  <td style={{ width: "75px" }}>Select</td>
                  <td style={{ width: "95px" }}>Timestamp</td>
                  <td style={{ width: "250px" }}>Sender</td>
                  <td style={{ width: "300px" }}>Subject</td>

                  <td style={{ width: "199px" }}>Viewed By</td>
                  <td>Tags</td>
                  <td style={{ width: "199px" }}>Mail type/Vessels</td>
                  <td>Receivers</td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <div
            id="scrollableDiv"
            className="mail_scrolls"
            style={{
              height: "85vh",
              overflowY: "auto",
              //padding: "0 16px",
              //border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            {/* <InfiniteScroll
              dataLength={mailData?.length||0}
              next={loadMoreData}
              hasMore={notMore}
              // loader={
              //   <>
              //     {[...Array(5).keys()].map((item) => (
              //       <Skeleton
              //         avatar
              //         paragraph={{
              //           rows: 1,
              //         }}
              //         // active
              //       />
              //     ))}
              //   </>
              // }
              endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            > */}
            <List
              dataSource={isFilters ? filterData : mailData}
              split
              renderItem={(item, index) => {
                const ccBccList = returnccbcc(item);
                const colors = [
                  "tomato",
                  "violet",
                  "orange",
                  "blue",
                  "green",
                  "yellow",
                  "pink",
                ];
                return (
                  <List.Item
                    key={item.email}
                    onClick={() => handleMailClick(item)}
                    className={`list-item-hover-${(index % 3) + 1}`}
                    style={{
                      backgroundColor: item?.isRead
                        ? "#ecf2f54d"
                        : "hsl(204deg 91.42% 90.82% / 30%)",
                    }}
                  >
                    <div style={{ width: "3%" }}>
                      <ListCheckbox item={item} />
                    </div>
                    <List.Item.Meta
                      avatar={
                        <div
                          style={{
                            width: "80px",
                            fontWeight: "600",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: item?.isRead ? "normal" : "bold",
                            }}
                          >
                            {/* {item.Formatted_Date || item.TIMESTAMP
                              ? formatTimestamp(
                                  item.Formatted_Date || item.TIMESTAMP
                                )
                              : timeHandler(item)} */}
                            {displayTime(item)}
                          </div>
                          {/* Uncomment if you need to show sender initials */}
                          {/* <div className="user_mail_profile">
                          {getInitial(item?.Sender)}
                        </div> */}
                        </div>
                      }
                      // title={item.Sender}
                      description={
                        <>
                          <div className="mail_list_view_container">
                            <div className="mail_list_description">
                              <div className="mail_list_sub">
                                <span
                                  style={{
                                    fontWeight: item?.isRead
                                      ? "normal"
                                      : "bold",
                                  }}
                                >
                                  {capitaliseFirstLetter(
                                    item.Sender ||
                                    item?.sender?.emailAddress?.address ||
                                    item?.owner_email ||
                                    "N/A"
                                  )}
                                </span>
                                {item?.Threads && item?.Threads.length > 0 ? (
                                  <Tooltip
                                    title={`Thread count ${item?.Threads ? item?.Threads.length : ""
                                      }`}
                                  >
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        padding: "2px 6px",
                                        color: "green",
                                      }}
                                    >
                                      {item?.Threads
                                        ? item?.Threads.length
                                        : ""}
                                    </span>
                                  </Tooltip>
                                ) : null}
                              </div>
                              <Tooltip
                                title={
                                  Array.isArray(item?.readBy)
                                    ? item.readBy.map((data, index) => (
                                      <div key={index}>{String(data)}</div>
                                    ))
                                    : item?.readBy
                                      ? String(item.readBy)
                                      : "unread email"
                                }
                              >
                                <div style={{ marginLeft: "4px" }}>
                                  <Icon
                                    icon="mdi:email-outline"
                                    fontSize={14}
                                    style={{ color: "#1C5CE9" }}
                                  />
                                </div>
                              </Tooltip>
                              <div style={{ marginLeft: "4px" }}>
                                <Tooltip
                                  title={
                                    ccBccList.length > 0
                                      ? ccBccList?.map((ccItem, i) => (
                                        <div key={i}>
                                          {ccItem?.name || ccItem?.email}
                                        </div>
                                      ))
                                      : "N/A"
                                  }
                                >
                                  <div>
                                    <Icon
                                      icon="ph:users"
                                      fontSize={14}
                                      style={{ color: "#00712D" }}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                              {item?.labelIds?.includes("DRAFT") && (
                                <span style={{ color: "#dd4b39" }}>
                                  (Draft)
                                </span>
                              )}
                              <div
                                className="mail_list_snippet"
                                style={{
                                  fontWeight: item?.isRead ? "normal" : "bold",
                                }}
                              >
                                {item.subject || item.Subject}
                              </div>

                              <div
                                style={{ width: "15%", textAlign: "center" }}
                              >
                                <EmailViewedByPopover item={item} />
                                {/* {item?.readBy && Array.isArray(item?.readBy) ? (
                                  item?.readBy?.map((readByItem, i) => (
                                    <span key={i} className="ReadBy-text">
                                      {String(readByItem)}
                                    </span> // Convert to string
                                  ))
                                ) : item?.readBy ? (
                                  <span className="ReadBy-text">
                                    {String(item?.readBy)}
                                  </span> // Convert to string
                                ) : (
                                  <span className="ReadBy-text">N/A</span>
                                )} */}
                              </div>

                              <div className="list_view_tags_box">
                                <TagsPopover item={item} />
                              </div>

                              <div className="mail_list_labels mailType">
                                <Tooltip title="Mail Type">
                                  {item?.Processing ? <span
                                    className={`mail_list_badge ${item.MailType}`}
                                    style={{
                                      backgroundColor: "rgb(255, 227, 200)",
                                      color: "rgb(141, 75, 13)",
                                      borderColor:
                                        chipStyles[index % chipStyles.length]
                                          .color,
                                    }}
                                  >
                                    Analysing
                                  </span> :
                                    <span
                                      className={`mail_list_badge ${item.MailType}`}
                                      style={{
                                        backgroundColor:
                                          chipStyles[index % chipStyles.length]
                                            .backgroundColor,
                                        color:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                        borderColor:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                      }}
                                    >
                                      {item.MailType}
                                    </span>}
                                </Tooltip>
                              </div>
                              <div className="mail_list_labels mail_vessels">
                                {Array.isArray(item?.vessel_name) &&
                                  item?.vessel_name.length > 0 &&
                                  item?.vessel_name
                                    .slice(0, 1)
                                    .map((vessel, index) => (
                                      <Tooltip key={index} title="Vessel Name">
                                        <span
                                          className="mail_list_badge"
                                          style={{
                                            backgroundColor:
                                              chipStyles[
                                                index % chipStyles.length
                                              ].backgroundColor,
                                            color:
                                              chipStyles[
                                                index % chipStyles.length
                                              ].color,
                                            borderColor:
                                              chipStyles[
                                                index % chipStyles.length
                                              ].color,
                                          }}
                                        >
                                          {vessel}
                                        </span>
                                      </Tooltip>
                                    ))}
                              </div>

                              <Avatar.Group maxCount={3}>
                                {/* Map over toRecipients and assign colors dynamically */}
                                {Array.isArray(item?.toRecipients) &&
                                  item?.toRecipients.length > 0 &&
                                  item?.toRecipients.map((sender, index) => (
                                    <Tooltip
                                      key={`to-${index}`}
                                      title={
                                        sender?.name ||
                                        sender?.emailAddress?.name
                                      }
                                    >
                                      <Avatar
                                        size="small"
                                        style={{
                                          background:
                                            colors[index % colors.length], // Assign color based on index
                                        }}
                                      >
                                        {getInitial(
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        )}
                                      </Avatar>
                                    </Tooltip>
                                  ))}

                                {/* Map over bccRecipients and assign colors dynamically */}
                                {Array.isArray(item?.bccRecipients) &&
                                  item?.bccRecipients.length > 0 &&
                                  item?.bccRecipients.map((sender, index) => (
                                    <Tooltip
                                      key={`bcc-${index}`}
                                      title={
                                        sender?.name ||
                                        sender?.emailAddress?.name
                                      }
                                    >
                                      <Avatar
                                        size="small"
                                        style={{
                                          background:
                                            colors[index % colors.length], // Assign color based on index
                                        }}
                                      >
                                        {getInitial(
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        )}
                                      </Avatar>
                                    </Tooltip>
                                  ))}

                                {/* Map over ccRecipients and assign colors dynamically */}
                                {Array.isArray(item?.ccRecipients) &&
                                  item?.ccRecipients.length > 0 &&
                                  item?.ccRecipients.map((sender, index) => (
                                    <Tooltip
                                      key={`cc-${index}`}
                                      title={
                                        sender?.name ||
                                        sender?.emailAddress?.name
                                      }
                                    >
                                      <Avatar
                                        size="small"
                                        style={{
                                          background:
                                            colors[index % colors.length], // Assign color based on index
                                        }}
                                      >
                                        {getInitial(
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        )}
                                      </Avatar>
                                    </Tooltip>
                                  ))}
                              </Avatar.Group>
                            </div>

                            <div className="mail_list_attach_icon">
                              {item?.hasAttachments && (
                                <Tooltip title="This email has Attachments.">
                                  <div>
                                    <Icon
                                      fontSize={14}
                                      icon="mdi:attachment"
                                      style={{ color: "white" }}
                                    />
                                  </div>
                                </Tooltip>
                              )}

                              <Tooltip title="Click to Delete this Mail.">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteConfirmation({
                                      GmailMessageId: item?.GmailMessageId,
                                      _id: item?._id,
                                      client: item?.client,
                                    });
                                  }}
                                >
                                  <Icon
                                    fontSize={14}
                                    style={{ color: "red" }}
                                    icon="tabler:trash"
                                  />
                                </div>
                              </Tooltip>

                              <Tooltip title="Mark it as Spam">
                                <div

                                >
                                  <Popconfirm
                                    title="Report as Spam or Unsubscribe"
                                    onConfirm={(event) => { event.stopPropagation(); markSpam({ event, userInput: item }) }}
                                    okText="OK"
                                    cancelText="No"
                                  >
                                    <Icon
                                      fontSize={14}
                                      style={{ color: "white" }}
                                      icon={"tabler:alert-octagon"}
                                    />


                                  </Popconfirm>
                                </div>
                              </Tooltip>

                              <Tooltip title="Click to mark as important or unimportant.">
                                <div
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    markImportant({ event, userInput: item });
                                  }}
                                >
                                  <Icon
                                    fontSize={14}
                                    icon={
                                      item?.important
                                        ? "tabler:star-filled"
                                        : "tabler:star"
                                    }
                                    style={{ color: "orange" }}
                                  />
                                </div>
                              </Tooltip>

                              <Avatar.Group maxCount={3}>
                                {/* Check if bccRecipients is a valid array and map over it */}
                                {Array.isArray(item?.toRecipients) &&
                                  item?.toRecipients.length > 0 &&
                                  item?.toRecipients.map((sender, index) => (
                                    <Tooltip
                                      key={`bcc-${index}`}
                                      title={
                                        sender?.name ||
                                        sender?.emailAddress?.name
                                      }
                                    >
                                      <Avatar
                                        size="small"
                                        style={{
                                          background: "tomato",
                                        }}
                                      >
                                        {getInitial(
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        )}
                                      </Avatar>
                                    </Tooltip>
                                  ))}

                                {Array.isArray(item?.bccRecipients) &&
                                  item?.bccRecipients.length > 0 &&
                                  item?.bccRecipients.map((sender, index) => (
                                    <Tooltip
                                      key={`bcc-${index}`}
                                      title={
                                        sender?.name ||
                                        sender?.emailAddress?.name
                                      }
                                    >
                                      <Avatar
                                        size="small"
                                        style={{
                                          background: "violet",
                                        }}
                                      >
                                        {getInitial(
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        )}
                                      </Avatar>
                                    </Tooltip>
                                  ))}

                                {/* Check if ccRecipients is a valid array and map over it */}
                                {Array.isArray(item?.ccRecipients) &&
                                  item?.ccRecipients.length > 0 &&
                                  item?.ccRecipients.map((sender, index) => (
                                    <Tooltip
                                      key={`cc-${index}`}
                                      title={
                                        sender?.name ||
                                        sender?.emailAddress?.name
                                      }
                                    >
                                      <Avatar
                                        size="small"
                                        style={{
                                          background: "orange",
                                        }}
                                      >
                                        {getInitial(
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        )}
                                      </Avatar>
                                    </Tooltip>
                                  ))}
                              </Avatar.Group>
                            </div>
                          </div>
                        </>
                      }
                    />
                  </List.Item>
                );
              }}
            />
            {/* </InfiniteScroll> */}
          </div>
        </>
      ) : (
        <>
          <div style={{ padding: "0 55px 0 24px", minHeight: 280 }}>
            <CurrentMailDrawer
              mail={currentMail}
              setCurrentMail={setCurrentMail}
              threads={false}
              threadData={currentMail?.Threads}
              allMails={mailData}
              setMailData={setMailData}
              handleDeleteMail={handleDeleteConfirmation}
              handleCurrentMailClose={handleCurrentMailClose}
              openCurrentMail={openCurrentMail}
              totalMailCount={totalMailCount}
              page={page}
              paginationHandler={paginationHandler}
            />

            {(currentMail["_id"] ||
              currentMail["id"] ||
              currentMail?.GmailMessageId) && (
                <MailRightbar currentMail={currentMail} />
              )}
          </div>
        </>
      )}

      <Spin fullscreen spinning={filterSpin} />
    </div>
  );
};

export default ListviewDrawer;
