// import React, { useEffect, useState } from 'react';
// import { TextField, Button, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Modal, IconButton, } from 'antd';
// // import MuiPhoneNumber from 'material-ui-phone-number';
// // import { postApiService } from 'src/services';

// import { message, Input } from 'antd';
// import Icon from 'src/@core/components/icon';



// const ContactForm = ({ data, mail, open, sendDataToParent, title }) => {
//     const [formData, setFormData] = useState({
//         owner_email: '',
//         givenName: '',
//         surname: '',
//         emailAddresses: [{ address: '' }],
//         businessPhones: [''],
//         companyName: '',
//         mobilePhone: '',
//         id: ''
//     });
//     const [createForm, setCreateForm] = useState(false)
//     const [titleshow, setTitle] = useState('')
//     useEffect(() => {
//         console.log(open)
//         setTitle(title)
//         if (data) {
//             setFormData(data)
//         }
//         else {
//             setFormData({
//                 owner_email: '',
//                 givenName: '',
//                 surname: '',
//                 emailAddresses: [{ address: '' }],
//                 businessPhones: [''],
//                 companyName: '',
//                 mobilePhone: '',
//                 id: ''
//             })
//         }
//         setCreateForm(open)
//     }, [open])

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prev) => ({ ...prev, [name]: value }));
//     };

//     const handleEmailChange = (e, index) => {
//         const updatedEmails = [...formData?.emailAddresses];
//         updatedEmails[index][e.target.name] = e.target.value;
//         setFormData((prev) => ({ ...prev, emailAddresses: updatedEmails }));
//     };

//     function handleOnChange(value, key) {
//         setFormData((prev) => ({ ...prev, businessPhones: [value] }));

//     }

//     const handleSubmit = async (e) => {
//         try {
//             e.preventDefault();
//             if (formData?.givenName && formData?.owner_email && formData?.emailAddresses) {

//                 console.log('Form Submitted', formData);
//                 let payload = { ...formData, displayName: `${formData?.givenName} ${formData?.surname}`, mobilePhone: formData?.businessPhones[0] }
//                 let url = `${process.env.NEXT_PUBLIC_BASE_URL}/mail/add-outlook-contact`
//                 if (formData.id) {
//                     url = `${process.env.NEXT_PUBLIC_BASE_URL}/mail/update-outlook-contact`
//                     payload = {
//                         "owner_email": formData?.owner_email,
//                         "id": formData?.id,
//                         "givenName": formData?.givenName,
//                         "surname": formData?.surname,
//                         "emailAddresses": formData?.emailAddresses,
//                         "businessPhones": formData?.businessPhones,
//                         'mobilePhone': formData?.businessPhones[0],
//                         "displayName": `${formData?.givenName} ${formData?.surname}`,
//                         'companyName': formData?.companyName,
//                     }
//                 }

//                 const response = await postAPICall(
//                     url,
//                     payload,
//                     'post'
//                 )
//                 console.log(response)
//                 if (response.status === 200 && response?.data?.msg) {
//                     message.success(response?.msg)
//                     sendDataToParent(false)
//                 }
//             }
//             else {
//                 message.info("Please fill all feild")
//             }
//         } catch (error) {

//         }

//     };
//     const handelModalClose = () => {
//         setCreateForm(false);
//         sendDataToParent(false)
//     }


// };

// export default ContactForm;
import React, { useEffect, useState } from 'react';
import {
    Modal,
    Form,
    Input,
    Select,
    Button,
    Typography,
    Row,
    Col,
    message,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { postAPICall } from '../../shared';
// import MuiPhoneNumber from 'material-ui-phone-number';
// import { postApiService } from 'src/services';
// import toast from 'react-hot-toast';

const { Title } = Typography;
const { Option } = Select;

const ContactForm = ({ data, mail, open, sendDataToParent, title }) => {
    const [form] = Form.useForm();
    const [createForm, setCreateForm] = useState(false);

    useEffect(() => {
        setCreateForm(open);
        if (data) {
            form.setFieldsValue({
                ...data,
                businessPhones: data.businessPhones?.[0] || '',
            });
        } else {
            form.resetFields();
        }
        if (data?.id) {
            setFormData(data)
        }

    }, [open, data, form]);
    const [formData, setFormData] = useState({
        owner_email: '',
        givenName: '',
        surname: '',
        emailAddresses: [{ address: '' }],
        businessPhones: [''],
        companyName: '',
        mobilePhone: '',
        id: ''
    });

    const handleSubmit = async (e) => {

        try {
            // e.preventDefault();
            console.log(e)
            if (e?.givenName && e?.owner_email && e?.emailAddresses[0]?.address) {

                // console.log('Form Submitted', formData);
                let payload = { ...e, displayName: `${e?.givenName} ${e?.surname}`, mobilePhone: e?.businessPhones, businessPhones: [e?.businessPhones], id: "" }

                let url = `${process.env.REACT_APP_MT_URL}mail/add-outlook-contact`
                if (formData.id) {
                    url = `${process.env.REACT_APP_MT_URL}mail/update-outlook-contact`
                    payload = {
                        "owner_email": e?.owner_email,
                        "id": formData?.id,
                        "givenName": e?.givenName,
                        "surname": e?.surname,
                        "emailAddresses": e?.emailAddresses,
                        "businessPhones": [e?.businessPhones],
                        'mobilePhone': e?.businessPhones,
                        "displayName": `${e?.givenName} ${e?.surname}`,
                        'companyName': e?.companyName,
                    }
                }

                const response = await postAPICall(
                    url,
                    payload,
                    'post'
                )
                console.log(response)
                if (response?.msg) {
                    message.success(response?.msg)
                    sendDataToParent(false)
                }
            }
            else {
                message.info("Please fill all feild")
            }
        } catch (error) {

        }

    };

    const handleModalClose = () => {
        setCreateForm(false);
        sendDataToParent(false);
    };

    return (
        <Modal
            visible={createForm}
            onCancel={handleModalClose}
            footer={null}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title level={5} style={{ margin: 0 }}>{title}</Title>
                    {/* <Button type="text" icon={<CloseOutlined />} onClick={handleModalClose} /> */}
                </div>
            }
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    owner_email: '',
                    givenName: '',
                    surname: '',
                    emailAddresses: [''],
                    businessPhones: [''],
                    companyName: '',
                }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Owner Email"
                            name="owner_email"
                            rules={[{ required: true, message: 'Please select an owner email' }]}
                        >
                            <Select placeholder="Select an email">
                                {mail.map((ele) => (
                                    <Option key={ele} value={ele}>{ele}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="givenName"
                            rules={[{ required: true, message: 'Please enter a first name' }]}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Last Name"
                            name="surname"
                            rules={[{ required: true, message: 'Please enter a last name' }]}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[{ required: true, message: 'Please enter a company name' }]}
                        >
                            <Input placeholder="Company Name" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.List name="emailAddresses">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Form.Item
                                            {...restField}
                                            key={key}
                                            label="Email Address"
                                            name={[name, 'address']}
                                            fieldKey={[fieldKey, 'address']}
                                            rules={[{ required: true, message: 'Please enter an email address' }]}
                                        >
                                            <Input placeholder="Email Address" />
                                        </Form.Item>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="Business Phone"
                            name="businessPhones"
                            rules={[{ required: true, message: 'Please enter a business phone number' }]}
                        >
                            <Input placeholder="Business Phone" />
                        </Form.Item>
                        {/* <Form.Item
                            label="Business Phone"
                            name="businessPhones"
                            rules={[{ required: true, message: 'Please enter a business phone number' }]}
                        >
                            <Input
                                // defaultCountry="us"
                                onChange={(value) => form.setFieldsValue(value)}
                                variant="outlined"
                            />
                        </Form.Item> */}
                    </Col>
                </Row>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ContactForm;
