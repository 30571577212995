import React, { useEffect, useState } from "react";
import VesselMap from "./VesselMap";
import {
  Tabs,
  Card,
  Grid,
  Typography,
  Accordion,
  Row,
  Col,
  Collapse,
  Spin
} from "antd";
import { getAPICall, openNotificationWithIcon } from "../../../shared";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const ParsedInfo = ({marineData}) => {

  let {SHIPNAME, IMO} = marineData
  const [activeTab, setActiveTab] = useState("live_position");
  const [center, setCenter] = useState(null)
  const [vesseldata, setVesselData] = useState({
    vesselinfo: {
      shipname: "N/A",
      dwt: "N/A",
      imo: "N/A",
      passage_type: "N/A",
    },
    vessel_particulars: {
      shipname: "N/A",
      dwt: "N/A",
      mmsi: "N/A",
      imo: "2020-10-14T12:00:00.000Z",
      ship_id: "N/A",
      speed: "N/A",
      status: "N/A",
      ship_type: "N/A",
      call_sign: "N/A",
      flag: "N/A",
      length: "N/A",
      breadth: "N/A",
      grt: "N/A",
      year_built: "N/A",
      ship_yard: "N/A",
      eedi: "N/A",
      rightship_safety_score: "N/A",
      rightship_ghg_rating: "N/A",
    },
    live_position: {
      latitude: "N/A",
      longitude: "N/A",
      speed: "N/A",
      avg_speed: "N/A",
      max_speed: "N/A",
      heading: "N/A",
      course: "N/A",
      status: "N/A",
      destination: "N/A",
      eta: "N/A",
      distance_to_go: "N/A",
      distance_travelled: "N/A",
      lastPort : "N/A",
      nextPort : "N/A",
    },
    port_congestion: {
      port: "N/A",
      country: "N/A",
      un_locode: "N/A",
      vessels_in_port: "N/A",
      departures_last_24_hrs: "N/A",
      arrivals_last_24_hrs: "N/A",
      global_area: "N/A",
      time_anch: "N/A",
      time_port: "N/A",
      vessels: "N/A",
      calls: "N/A",
      time_anch_stdev: "N/A",
      time_anch_diff: "N/A",
      time_port_stdev: "N/A",
      time_port_diff: "N/A",
      shipname: "N/A",
      ship_type: "N/A",
      capacity: "N/A",
      reported_eta: "N/A",
    },
  });



  
  const handleChange = (newValue) => {
    if(newValue === 'vessel_particulars'){
      // getVesselParticular(marineData.MMSI)
    }
    setActiveTab(newValue);
  };

  useEffect(() => {
    const token = localStorage.getItem("oceanToken");
    let vesselParticulars = {
      shipname: marineData.SHIPNAME,
      dwt: marineData.DWT,
      mmsi: marineData.MMSI,
      imo: marineData.IMO,
      ship_id: marineData.SHIP_ID,
      speed: "N/A",
      status: "N/A",
      ship_type: "N/A",
      call_sign: marineData.CALLSIGN,
      flag: marineData.FLAG,
      length: "N/A",
      breadth: "N/A",
      grt: "N/A",
      year_built: marineData.YEAR_BUILT,
      ship_yard: "N/A",
      eedi: "N/A",
      rightship_safety_score: "N/A",
      distance_travelled: "N/A",
    };
    let vesselPosition = {
      latitude: "N/A",
      longitude: "N/A",
      speed: "N/A",
      avg_speed: "N/A",
      max_speed: "N/A",
      heading: "N/A",
      course: "N/A",
      status: "N/A",
      destination: "N/A",
      eta: "N/A",
      distance_to_go: "N/A",
      distance_travelled: "N/A",
    };

    getLivePosition(marineData.MMSI, marineData.SHIP_ID,vesselParticulars);
  }, [marineData.MMSI, marineData.SHIP_ID]);

 

  const getLivePosition = async (mmsi, shipId, vesselParticularData) => {
    const token = localStorage.getItem("oceanToken");
    const urlVesselPosition = `${process.env.REACT_APP_MT_URL}marine/vessel-position-imo/${mmsi}/${shipId}/${SHIPNAME}/${IMO}`;
  
    try {
      const vesselPositionResponse = await fetch(urlVesselPosition, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
  
      const vesselPositionDataFetched = await vesselPositionResponse.json();
      if (!vesselPositionResponse.ok) {
        openNotificationWithIcon("info",vesselPositionDataFetched?.error)
        throw new Error(`Error: ${vesselPositionResponse.status}`);
      }
  
      const {
        SPEED,
        LENGTH,
        WIDTH,
        GRT,
        SHIPTYPE,
        LAT,
        LON,
        AVG_SPEED,
        MAX_SPEED,
        HEADING,
        COURSE,
        STATUS,
        DESTINATION,
        ETA,
        DISTANCE_TO_GO,
        DISTANCE_TRAVELLED,
        SHIPNAME,
        DWT,
        IMO,
        NEXT_PORT_NAME,
        LAST_PORT,
      } = vesselPositionDataFetched.data;
  
      // Update vessel particular data
      vesselParticularData.speed = SPEED;
      vesselParticularData.length = LENGTH;
      vesselParticularData.breadth = WIDTH;
      vesselParticularData.grt = GRT;
      vesselParticularData.ship_type = SHIPTYPE;
  
      // Create vessel position object
      const vesselPosition = {
        latitude: LAT[LAT.length - 1] === 'N' ? LAT.substring(0, LAT.length - 1) : LAT,

        longitude: LON[LON.length - 1] === 'E' ? LON.substring(0, LON.length - 1) : LON,
        speed: SPEED,
        avg_speed: AVG_SPEED,
        max_speed: MAX_SPEED,
        heading: HEADING,
        course: COURSE,
        status: STATUS,
        destination: DESTINATION,
        eta: ETA,
        distance_to_go: DISTANCE_TO_GO,
        distance_travelled: DISTANCE_TRAVELLED,
        lastPort : LAST_PORT,
        nextPort: NEXT_PORT_NAME
      };
  
      // Update the map center position
      setCenter([LON, LAT]);
  
      // Update vessel data
      setVesselData((prev) => ({
        ...prev,
        vesselinfo: { shipname: SHIPNAME, dwt: DWT, imo: IMO },
        live_position: vesselPosition,
        vessel_particulars: vesselParticularData,
      }));
  
    } catch (error) {
      console.error("Failed to fetch vessel position data:", error);
    }
  };

  return (
    <>
      <div
        style={{
         
          background: "inherit",
          height: "400px",
          overflow: "hidden",
          width : '100%'
        }}
      >
        {
          center ? (<VesselMap center={center}  shipName = {vesseldata?.vessel_particulars?.shipname} log = {vesseldata?.live_position?.longitude}
          lat = {vesseldata?.live_position?.latitude}
          Imo = {vesseldata?.vessel_particulars?.imo} 
          vesselData = {vesseldata} /> ) : ( <Spin />)
        }
        
        <hr />
      </div>

      <div style={{ background: "inherit" }}>
        <div>
          <Card style={{ maxWidth: "100%", marginTop: "20px" }}>
            <h3>Vessel Information</h3>
            <div>
              <Row gutter={[10, 10]}>
                <Col span={5}>Vessel Name</Col>
                <Col span={5}>----------</Col>
                <Col span={10}>{vesseldata?.vesselinfo?.shipname ?? SHIPNAME}</Col>

                <Col span={5}>Dwt</Col>
                <Col span={5}>----------</Col>
                <Col span={10}>{vesseldata?.vesselinfo?.dwt ?? "N/A"}</Col>

                <Col span={5}>Imo</Col>
                <Col span={5}>----------</Col>
                <Col span={10}>{vesseldata?.vesselinfo?.imo ?? "N/A"}</Col>
                <Col span={5}>Passage type</Col>
                <Col span={5}>----------</Col>
                <Col span={10}>
                  {vesseldata?.vesselinfo?.passage_type ?? "N/A"}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={handleChange}
        tabBarGutter={50}
        tabBarStyle={{ fontWeight: "bold" }}
      >
         <TabPane tab="Live Position" key="live_position">
          <div style={{ background: "inherit" }}>
            <div>
              <Card style={{ maxWidth: "100%", marginTop: "20px" }}>
                <h3>Live Position</h3>
                <div>
                  <Row gutter={[10, 10]}>
                    <Col span={5}>Latitude:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.latitude ?? "N/A"}
                    </Col>

                    <Col span={5}>Longitude:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.longitude ?? "N/A"}
                    </Col>
                    <Col span={5}>Speed:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {(vesseldata?.live_position?.speed * 0.1).toFixed(2) ?? "N/A"} kn
                    </Col>
                    <Col span={5}>Average speed:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.avg_speed ?? "N/A"}
                    </Col>
                    <Col span={5}>Max speed:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.max_speed ?? "N/A"}
                    </Col>
                    <Col span={5}>Heading:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.heading ?? "N/A"}
                    </Col>
                    <Col span={5}>Course:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.course ?? "N/A"}
                    </Col>
                    <Col span={5}>Status:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.status ?? "N/A"}
                    </Col>
                    <Col span={5}>Destination:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.destination ?? "N/A"}
                    </Col>
                    <Col span={5}>ETA:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.eta ?? "N/A"}
                    </Col>
                    <Col span={5}>Distance to go:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.distance_to_go ?? "N/A"}
                    </Col>
                    <Col span={5}>Distance travelled:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.live_position?.distance_travelled ?? "N/A"}
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Particulars" key="vessel_particulars">
          <div style={{ background: "inherit" }}>
            {true ? (  <div>
              <Card style={{ maxWidth: "100%", marginTop: "20px" }}>
                <h3>Particulars</h3>
                <div>
                  <Row gutter={[10, 10]}>
                    <Col span={5}>Vessel Name:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.shipname ?? "N/A"}
                    </Col>

                    <Col span={5}>Dwt:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.dwt ?? "N/A"}
                    </Col>
                    <Col span={5}>Mmsi:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.mmsi ?? "N/A"}
                    </Col>
                    <Col span={5}>Imo:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.imo ?? "N/A"}
                    </Col>
                    <Col span={5}>Ship id:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.ship_id ?? "N/A"}
                    </Col>
                    <Col span={5}>Speed:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {(vesseldata?.live_position?.speed * 0.1).toFixed(2) ?? "N/A"} kn
                    </Col>
                    <Col span={5}>Status:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.status ?? "N/A"}
                    </Col>
                    <Col span={5}>Ship type:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.ship_type ?? "N/A"}
                    </Col>
                    <Col span={5}>Call sign:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.call_sign ?? "N/A"}
                    </Col>
                    <Col span={5}>Flag:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.flag ?? "N/A"}
                    </Col>
                    <Col span={5}>Length:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.length ?? "N/A"}
                    </Col>
                    <Col span={5}>Breadth:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.breadth ?? "N/A"}
                    </Col>
                    <Col span={5}>GRT:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.grt ?? "N/A"}
                    </Col>
                    <Col span={5}>Year built:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.year_built ?? "N/A"}
                    </Col>
                    <Col span={5}>Ship yard:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.ship_yard ?? "N/A"}
                    </Col>
                    <Col span={5}>EEDI:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.eedi ?? "N/A"}
                    </Col>
                    <Col span={5}>Right ship safety score:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.rightship_safety_score ??
                        "N/A"}
                    </Col>
                    <Col span={5}>Right ship ghg rating:</Col>
                    <Col span={5}>----------</Col>
                    <Col span={10}>
                      {vesseldata?.vessel_particulars?.rightship_ghg_rating ??
                        "N/A"}
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>): <>Loading......</>}
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default ParsedInfo;
