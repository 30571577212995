import { EyeOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import URL_WITH_VERSION, {
  getAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../shared";
import NormalFormIndex from "../../../shared/NormalForm/normal_from_index";
import CommissionPayment from "../CommissionPayment";

const CommissionSummary = (props) => {
  const [state, setState] = useStateCallback({
    responseData: { frm: [], tabs: [], active_tab: {} },
    frmKey: "frm_key_" + props.frmCode,
    isVisiblePayment: false,
    frmName: "tc_commission_summary",
    formData: props.formData || {},
    commissionData: props.commissionData || {},
    frmOptions: props.frmOptions || [],
    isEdit: false,
    editData: null,
    frmOption: null,
    oldFromData: props.oldFromData || {},
    frmVisible: true,
  });

  const saveOpenPayment = async (e) => {
    if (e === "new_payment") {
      const makeCommissionEntry = {
        id: 0,
        vessel_id: state.commissionData.vessel_id,
        tc_code: state.formData.tc_code,
      };
      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/accounts/borker/commission?t=tci&e=${state.formData.tci_id}`
      );
      const respSData = await response1["data"];
      makeCommissionEntry["tc_code"] = state.formData.tc_code;
      if (!makeCommissionEntry.tci_id) {
        makeCommissionEntry["tci_id"] = state.formData.tci_id;
      }
      const frmOptions = [
        { key: "broker", data: respSData["brokers"] },
        { key: "remmitance_bank", data: respSData["banks"] },
      ];
      state.commissionData.mycompny_id = respSData["mycompny_id"];

      setState((prevstate) => ({
        ...prevstate,
        isVisiblePayment: true,
        isEdit: false,
        editData: null,
        commissionData: makeCommissionEntry,
        frmOption: frmOptions,
      }));
    }
  };

  const onCancelPayment = (e) => {
    setState((prevstate) => ({ ...prevstate, isVisiblePayment: false }));
  };

  const editCommisionData = async (invoice_no) => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/commission/edit?e=${invoice_no}`
      );
      const respData = await response["data"];

      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/accounts/borker/commission?t=tci&e=${respData.tci_id}`
      );
      const respSData = await response1["data"];
      respData["mycompny_id"] = respSData["mycompny_id"];

      respData["."] = {
        ...respData["."],
        total: respData["inv_total"],
      };
      const frmOptions = [
        { key: "broker", data: respSData["brokers"] },
        { key: "remmitance_bank", data: respSData["banks"] },
      ];
      // let data1 = {
      //   'broker': respData.inv_type,
      //   'tci_id': respData.tci_id
      //  }
      //  let suURL = `${URL_WITH_VERSION}/accounts/borker/commission`;
      //  let suMethod = 'POST';
      //  postAPICall(suURL, data1, suMethod, data => {
      //   if (data && data.data) {
      //     Object.assign(respData, data.data)
      //   }});
      //   setTimeout(() => {
      //     setState({ isEdit: true, editData: respData, isVisiblePayment:true ,frmOption : frmOptions})
      //   }, 2000, this);
      Object.assign(respData, { disablefield: ["broker"] });
      setState((prevstate) => ({
        ...prevstate,
        isEdit: true,
        editData: respData,
        isVisiblePayment: true,
        frmOption: frmOptions,
      }));
    } catch (err) {
      openNotificationWithIcon("info", "No Data Found", 5);
    }
  };

  const editCommision = async (action, data) => {
    setState((prevstate) => ({ ...prevstate, fullformdata: { ...data } }));
    data &&
      data["-"].map((e, index) => {
        if (action.index === index) {
          editCommisionData(e.invoice_no);
        }
        return true;
      });
  };

  const oncloseModal = (invno) => {
    const { formData, fullformdata } = state;
    let _formdata = Object.assign({}, fullformdata);
    let arr = _formdata["-"].filter((el) => el.invoice_no != invno);
    _formdata["-"] = [...arr];

    setState(
      (prevstate) => ({
        ...prevstate,
        frmVisible: false,
        formData: { ..._formdata },
      }),
      () => {
        setState({
          ...state,
          frmVisible: true,
          isVisiblePayment: false,
        });
      }
    );
  };

  const {
    frmName,
    formData,
    commissionData,
    frmOptions,
    isEdit,
    editData,
    isVisiblePayment,
    frmOption,
    oldFromData,
  } = state;

  return (
    <div className="body-wrapper">
      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            {state.frmVisible ? (
              <NormalFormIndex
                key={"key_" + frmName + "_0"}
                formClass="label-min-height"
                frmCode={state.frmName}
                formData={formData}
                tableFormAddNewRow={true}
                showForm={true}
                addForm={true}
                showButtons={[]}
                showToolbar={[
                  {
                    isLeftBtn: [
                      {
                        key: "s1",
                        isSets: [
                          {
                            id: "1",
                            key: "new_payment",
                            // type: 'solution',
                            withText: "New Payment",
                            event: (key, data) => {
                              saveOpenPayment(key);
                            },
                          },
                        ],
                      },
                    ],
                    isRightBtn: [],
                    isResetOption: false,
                  },
                ]}
                extraTableButton={{
                  "-": [
                    {
                      icon: <EyeOutlined />,
                      onClickAction: (action, data) =>
                        editCommision(action, data),
                    },
                  ],
                }}
                inlineLayout={true}
                frmOptions={frmOptions}
              />
            ) : undefined}
          </div>
        </div>
      </article>

      {isVisiblePayment && (
        <Modal
          title="Time Charter Commission Payment"
          open={isVisiblePayment}
          width="80%"
          style={{ top: "2%" }}
          onCancel={onCancelPayment}
          footer={null}
        >
          <CommissionPayment
            isEdit={isEdit}
            oldFromData={oldFromData}
            frmOptions={frmOption}
            commissionData={isEdit === true ? editData : commissionData}
            onCancelModal={(data) => {
              oncloseModal(data);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default CommissionSummary;
