export const BunkerDetailsCalculation = (formData, index) => {
  let ecafuelGrade = formData.eca_fuel_grade;
  let bunkerobj = {};
  let consarr = formData?.["."] ?? [];
  let fuel_cons = 0;

  if (formData?.["bunkerdetails"]?.[index]?.["is_updated"] == 1) {
    bunkerobj = { ...formData["bunkerdetails"][index] };
    return bunkerobj;
  }

  formData?.portitinerary?.map((el, ind) => {
    if (ind == index) {
      let { tsd = 0, eca_days = 0, passage, funct, days = 0, xpd = 0 } = el;
      let nonEcadays = tsd - eca_days;
      let ifo_cons = findfuelcons("IFO", consarr, passage);
      let vlsfo_cons = findfuelcons("VLSFO", consarr, passage);
      let mgo_cons = findfuelcons("MGO", consarr, passage);
      let lsmgo_cons = findfuelcons("LSMGO", consarr, passage);
      let ulsfo_cons = findfuelcons("ULSFO", consarr, passage);
      if (ecafuelGrade === "7") {
        // LSMGO
        if (vlsfo_cons && ifo_cons) {
          fuel_cons = vlsfo_cons;
          bunkerobj["vlsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["ifo"] = "0.00";
        } else if (!vlsfo_cons && ifo_cons) {
          fuel_cons = ifo_cons;
          bunkerobj["ifo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["vlsfo"] = "0.00";
        } else if (vlsfo_cons && !ifo_cons) {
          fuel_cons = vlsfo_cons;
          bunkerobj["vlsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["ifo"] = "0.00";
        } else {
          bunkerobj["ifo"] = "0.00";
          bunkerobj["vlsfo"] = "0.00";
        }
        bunkerobj["lsmgo"] = "0.00";
        bunkerobj["mgo"] = "0.00";
        bunkerobj["ulsfo"] = "0.00";
      } else if (ecafuelGrade === "10") {
        //ULSFO
        let ifo_cons = findfuelcons("IFO", consarr, passage);
        let vlsfo_cons = findfuelcons("VLSFO", consarr, passage);
        let ulsfo_cons = findfuelcons("ULSFO", consarr, passage);
        if (ulsfo_cons) {
          fuel_cons = ulsfo_cons;
          bunkerobj["ulsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["vlsfo"] = "0.00";
          bunkerobj["ifo"] = "0.00";
        } else if (vlsfo_cons && ifo_cons) {
          fuel_cons = vlsfo_cons;
          bunkerobj["vlsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["ifo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
        } else if (ifo_cons) {
          fuel_cons = ifo_cons;
          bunkerobj["ifo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["vlsfo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
        } else {
          bunkerobj["ifo"] = "0.00";
          bunkerobj["vlsfo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
        }
        bunkerobj["lsmgo"] = "0.00";
        bunkerobj["mgo"] = "0.00";
      } else {
        if (
          ifo_cons > 0 &&
          vlsfo_cons > 0 &&
          mgo_cons > 0 &&
          lsmgo_cons > 0 &&
          ulsfo_cons > 0
        ) {
          bunkerobj["lsmgo"] = "0.00";
          bunkerobj["mgo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
          bunkerobj["ifo"] = "0.00";
          bunkerobj["vlsfo"] = parseFloat(vlsfo_cons * tsd).toFixed(2);
        } else {
          bunkerobj["ifo"] = ifo_cons
            ? parseFloat(ifo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["lsmgo"] = lsmgo_cons
            ? parseFloat(lsmgo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["mgo"] = mgo_cons
            ? parseFloat(mgo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["vlsfo"] = vlsfo_cons
            ? parseFloat(vlsfo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["ulsfo"] = ulsfo_cons
            ? parseFloat(ulsfo_cons * tsd).toFixed(2)
            : "0.00";
        }
      }
      bunkerobj["eca_consp"] = SeafuelConsECA(
        ecafuelGrade,
        passage,
        consarr,
        eca_days
      );
      bunkerobj["pc_ifo"] = portfuelCons("3", consarr, funct, days, xpd);
      bunkerobj["pc_vlsfo"] = portfuelCons("5", consarr, funct, days, xpd);
      bunkerobj["pc_lsmgo"] = portfuelCons("7", consarr, funct, days, xpd);
      bunkerobj["pc_mgo"] = portfuelCons("4", consarr, funct, days, xpd);
      bunkerobj["pc_ulsfo"] = portfuelCons("10", consarr, funct, days, xpd);
    }
  });

  return bunkerobj;
};

export const findfuelcons = (fuelType, consarr, passage) => {
  let fuelcons = 0;
  consarr.map((el) => {
    const { fuel_type, fuel_code } = el;
    if (el.fuel_code === fuelType) {
      fuelcons = passage === "1" ? el?.ballast_value : el.laden_value;
    }
  });
  return isNaN(fuelcons) || !fuelcons ? 0 : parseFloat(fuelcons);
};

export const findCpPassage = (fuelType, passageType, consArr) => {
  let fuel_cons = 0;
  consArr?.map((el) => {
    const { fuel_type, fuel_code } = el;
    if (fuel_type == fuelType) {
      if (passageType === "1") {
        fuel_cons = isNaN(el?.ballast_value) ? 0 : parseFloat(el.ballast_value);
      } else if (passageType === "2") {
        fuel_cons = isNaN(el?.laden_value) ? 0 : parseFloat(el.laden_value);
      }
    }
  });

  fuel_cons = isNaN(fuel_cons) ? 0 : parseFloat(fuel_cons);
  return fuel_cons;
};

export const findPortFunc = (fuelType, consArr, portFunc) => {
  let port_cons = 0;
  let idlePort_cons = 0;
  consArr?.map((el) => {
    const { fuel_type, fuel_code } = el;

    if (fuel_type == fuelType) {
      idlePort_cons = el.con_ideal_on;
      switch (portFunc) {
        case "2": // loading
          port_cons = el.con_loading;
          break;
        case "9": // Dischagring
          port_cons = el.con_disch;
          break;
        default: // ideal
          port_cons = el.con_ideal_on;
      }
    }
  });

  port_cons = isNaN(port_cons) ? 0 : parseFloat(port_cons);
  idlePort_cons = isNaN(idlePort_cons) ? 0 : parseFloat(idlePort_cons);
  return { port_cons, idlePort_cons };
};

export const SeafuelConsECA = (fuelType, passageType, consArr, days) => {
  let fuel_cons = findCpPassage(fuelType, passageType, consArr);
  let _days = isNaN(days) ? 0 : parseFloat(days);
  return parseFloat(fuel_cons * _days).toFixed(2);
};

export const portfuelCons = (fuelType, consArr, portFunc, pdays, xpd) => {
  let { port_cons, idlePort_cons } = findPortFunc(fuelType, consArr, portFunc);
  let _pdays = isNaN(pdays) ? 0 : parseFloat(pdays);
  let _xpd = isNaN(xpd) ? 0 : parseFloat(xpd);
  let totalportcons = port_cons * _pdays + idlePort_cons * _xpd;
  return parseFloat(totalportcons).toFixed(2);
};
