import { Modal } from 'antd';
import React from 'react'
import VesselSchedule from '../../../components/vessel-form';
import EmailTemplate from '../../../components/email-templates';

const EmailTemplateModal = ({ showtemplateModal, setShowtemplateModal }) => {
    return (
        <Modal
            title="Manage Email Template"
            open={showtemplateModal}
            closable={true}
            onCancel={() => setShowtemplateModal(false)}
            footer={null}
            style={{
                top: 20,
            }}
            width={"90%"}
            bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
            < EmailTemplate />
            {/* <VesselSchedule /> */}
        </Modal>
    );
};

export default EmailTemplateModal;