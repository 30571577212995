import { Drawer } from "antd";
import React, { useEffect } from "react";
import VesselMap from "../../voyage-list/components/VesselMap";
import ParsedInfo from "../../voyage-list/components/ParsedInfo";
import { useSelector } from "react-redux";
import NewVesselInfoDrawer from "./NewVesselInfoDrawer";

const VesselInfoDrawer = ({
  showVesselInfoDrawer,
  setShowVesselInfoDrawer,
  marineData,
}) => {
  // const marineData = {
  //   SHIPNAME: "MSC SIXIN",
  //   MMSI: "370711000",
  //   IMO: "9839301",
  //   SHIP_ID: "6047623",
  //   CALLSIGN: "HP2652",
  //   TYPE_NAME: "Container Ship",
  //   DWT: "228111",
  //   FLAG: "PA",
  //   COUNTRY: "Panama",
  //   YEAR_BUILT: "2019",
  //   MT_URL:
  //     "http://www.marinetraffic.com/en/ais/details/ships/shipid:6047623/mmsi:370711000",
  // };
  // let { SHIPNAME, IMO } = marineData;

  // const getLivePosition = async (marineData) => {
  //   const token = localStorage.getItem("oceanToken");
  //   const urlVesselPosition = `${process.env.REACT_APP_MT_URL}marine/vessel-position-imo/${marineData?.MMSI}/${marineData?.SHIP_ID}/${marineData?.SHIPNAME}/${marineData?.IMO}`;

  //   try {
  //     const vesselPositionResponse = await fetch(urlVesselPosition, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: token,
  //       },
  //     });

  //     if (!vesselPositionResponse.ok) {
  //       throw new Error(`Error: ${vesselPositionResponse.status}`);
  //     }

  //     const vesselPositionDataFetched = await vesselPositionResponse.json();
  //     console.log({ vesselPositionDataFetched });
  //     // const {
  //     //   SPEED,
  //     //   LENGTH,
  //     //   WIDTH,
  //     //   GRT,
  //     //   SHIPTYPE,
  //     //   LAT,
  //     //   LON,
  //     //   AVG_SPEED,
  //     //   MAX_SPEED,
  //     //   HEADING,
  //     //   COURSE,
  //     //   STATUS,
  //     //   DESTINATION,
  //     //   ETA,
  //     //   DISTANCE_TO_GO,
  //     //   DISTANCE_TRAVELLED,
  //     //   SHIPNAME,
  //     //   DWT,
  //     //   IMO,
  //     //   NEXT_PORT_NAME,
  //     //   LAST_PORT,
  //     // } = vesselPositionDataFetched.data;

  //     // // Update vessel particular data
  //     // vesselParticularData.speed = SPEED;
  //     // vesselParticularData.length = LENGTH;
  //     // vesselParticularData.breadth = WIDTH;
  //     // vesselParticularData.grt = GRT;
  //     // vesselParticularData.ship_type = SHIPTYPE;

  //     // // Create vessel position object
  //     // const vesselPosition = {
  //     //   latitude:
  //     //     LAT[LAT.length - 1] === "N" ? LAT.substring(0, LAT.length - 1) : LAT,

  //     //   longitude:
  //     //     LON[LON.length - 1] === "E" ? LON.substring(0, LON.length - 1) : LON,
  //     //   speed: SPEED,
  //     //   avg_speed: AVG_SPEED,
  //     //   max_speed: MAX_SPEED,
  //     //   heading: HEADING,
  //     //   course: COURSE,
  //     //   status: STATUS,
  //     //   destination: DESTINATION,
  //     //   eta: ETA,
  //     //   distance_to_go: DISTANCE_TO_GO,
  //     //   distance_travelled: DISTANCE_TRAVELLED,
  //     //   lastPort: LAST_PORT,
  //     //   nextPort: NEXT_PORT_NAME,
  //     // };

  //     // // Update the map center position
  //     // setCenter([LON, LAT]);

  //     // // Update vessel data
  //     // setVesselData((prev) => ({
  //     //   ...prev,
  //     //   vesselinfo: { shipname: SHIPNAME, dwt: DWT, imo: IMO },
  //     //   live_position: vesselPosition,
  //     //   vessel_particulars: vesselParticularData,
  //     // }));
  //   } catch (error) {
  //     console.error("Failed to fetch vessel position data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (marineData) {
  //     getLivePosition(marineData);
  //   }
  // }, [marineData]);
  const isVisisble = useSelector(
    (state) => state?.email?.vesselInfoDrawer?.isVisisble
  );

  return (
    <Drawer
      title="Vessel Information"
      className="mail-side-drawer"
      onClose={() => setShowVesselInfoDrawer(false)}
      open={showVesselInfoDrawer}
    >
      {/* {marineData && <ParsedInfo marineData={marineData} />} */}
      {marineData && <NewVesselInfoDrawer marineData={marineData} />}
    </Drawer>
  );
};

export default VesselInfoDrawer;
