import React, { useState, useEffect } from 'react';
import { Table, Button, Spin } from 'antd';

const AiVesselSuggestion = ({ vesselData,getVesselFromAI }) => {
  const [data, setData] = useState(vesselData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let timeout;

    if (vesselData && vesselData.length > 0) {
      setData(vesselData);;
      setLoading(false);
    } else {
      timeout = setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [vesselData]);

  const columns = [
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'Vessel Type',
      dataIndex: 'vessel_type',
      key: 'vessel_type',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'DWT',
      dataIndex: 'dwt',
      key: 'dwt',
      align: 'center',
    //   render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'New Open Port',
      dataIndex: 'new_open_port',
      key: 'new_open_port',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data||"-"}</p>,
    },
    {
      title: 'Open Date',
      dataIndex: 'open_date',
      key: 'open_date',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data||"-"}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleImport(record)}>Import</Button>
      ),
      align: 'center',
    },
  ];

  const handleImport = (record) => {
      getVesselFromAI(record);
    // console.log('Importing record:', record);
    // let data = {
    //     cargo_name:record?.cargo_name||"",
    //     cp_qty:record?.cargo_size||"",
    //     laycan_from:"",
    //     laycan_to:""
    // }
    // return record; 
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="_id"
      className="custom-table"
      loading={loading && <Spin />}
    />
  );
};

export default AiVesselSuggestion;
