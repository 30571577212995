import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import React, { Component } from "react";
//import ToolbarUI from "components/ToolbarUI";

import { SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import ToolbarUI from "../../../../components/ToolbarUI";

import URL_WITH_VERSION, {
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
} from "../../..";
import TctoVmPnl, { TCEstimateCalcArr } from "../plcomponents/TctoVmPnl";

const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const FormItem = Form.Item;

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    ellipsis: true,
  },

  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "8%",
  },
  {
    title: "Actual",
    dataIndex: "actual",
    key: "actual",
    width: "8%",
  },
  {
    title: "Posted",
    dataIndex: "posted",
    key: "posted",
    width: "8%",
  },
  {
    title: "Cash In",
    dataIndex: "cash_in",
    key: "cash_in",
    width: "8%",
  },
  {
    title: `Diff(Act.-Est.)`,
    dataIndex: "Diff",
    key: "Diff",
    width: "8%",
  },
  {
    title: "%Diff",
    dataIndex: "perDiff",
    key: "perDiff",
    width: "8%",
  },
  {
    title: "Post Vs Cash",
    dataIndex: "sec_variance",
    key: "sec_variance",
    width: "8%",
  },
  {
    title: "% Post Vs Cash",
    dataIndex: "sec_per",
    key: "sec_per",
    width: "8%",
  },
];

const columns2 = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    ellipsis: true,
  },
  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "12%",
    align: "right",
  },
  { title: "", dataIndex: "", key: "blank", width: "68%" },
];

class PL extends Component {
  callback = (evt) => {};
  constructor(props) {
    super(props);
    this.state = {
      dollarUSLocale: Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      formData: this.props.formData || {},
      estimateDatavalue: this.props.estimateData || {},
      showPL: false,
      showEstimatePl: this.props.showEstimatePl,
      vesselAmount: 0,
      viewTabs: this.props.viewTabs || ["Actual &  Operation View"],
      tooltipData: {},
      estimateData: [
        {
          key: "revenue",
          description: "Revenue",
          estimate: 0,
          children: [
            { key: "rev00", description: "Gross TCO Hire $", estimate: 0 },
            {
              key: "rev01",
              description: "Gross TCO Hire  B. Comm",
              estimate: 0,
            },
            {
              key: "rev02",
              description: "Gross TCO Hire Add Comm",
              estimate: 0,
            },
            { key: "rev03", description: "Gross TCO BB", estimate: 0 },
            { key: "rev04", description: "Gross TCO BB Comm", estimate: 0 },
            { key: "rev05", description: "Net Ballast Bonus", estimate: 0 },
            {
              key: "rev06",
              description: "Misc. Revenue",
              estimate: 0,
              children: [],
            },
            { key: "rev07", description: "Total TCO Net Hire", estimate: 0 },
            { key: "rev08", description: "Total Net TCO BB", estimate: 0 },
            { key: "rev09", description: "Gross Revenue", estimate: 0 },
            { key: "rev10", description: "Net Revenue", estimate: 0 },
          ],
        },
        {
          key: "expenses",
          description: "Expenses",
          estimate: 0,
          children: [
            {
              key: "ex10",
              description: "Vessel expenses",
              estimate: 0,
              children: [
                { key: "ex100", description: "Hire Expenses", estimate: 0 },
                { key: "ex101", description: "TCI Add. Comm.", estimate: 0 },
                { key: "ex102", description: "TCI Broker Comm.", estimate: 0 },
                { key: "ex103", description: "TCI Ballst Bonus", estimate: 0 },
                { key: "ex104", description: "TCI BB Comm", estimate: 0 },
              ],
            },
            {
              key: "ex11",
              description: "Port Expenses",
              estimate: 0,
              children: [],
            },

            { key: "ex12", description: "Emission Expense", estimate: 0 },

            {
              key: "ex13",
              description: "Bunker Expenses",
              estimate: 0,
              children: [
                {
                  key: "ex130",
                  description: "Sea Consumption Non ECA",
                  estimate: 0,
                  children: [
                    { key: "ex1300", description: "IFO", estimate: 0 },
                    { key: "ex1301", description: "MGO", estimate: 0 },
                    { key: "ex1302", description: "VLSFO", estimate: 0 },
                    { key: "ex1303", description: "LSMGO", estimate: 0 },
                    { key: "ex1304", description: "ULSFO", estimate: 0 },
                  ],
                },
                {
                  key: "ex131",
                  //description: "Port Consumption Non ECA",
                  description: "Port Consumption",
                  estimate: 0,
                  children: [
                    { key: "ex1310", description: "IFO", estimate: 0 },
                    { key: "ex1311", description: "MGO", estimate: 0 },
                    { key: "ex1312", description: "VLSFO", estimate: 0 },
                    { key: "ex1313", description: "LSMGO", estimate: 0 },
                    { key: "ex1314", description: "ULSFO", estimate: 0 },
                  ],
                },

                {
                  key: "ex132",
                  description: "Sea Consumption ECA",
                  estimate: 0,
                  children: [
                    { key: "ex1320", description: "IFO", estimate: 0 },
                    { key: "ex1321", description: "MGO", estimate: 0 },
                    { key: "ex1322", description: "VLSFO", estimate: 0 },
                    { key: "ex1323", description: "LSMGO", estimate: 0 },
                    { key: "ex1324", description: "ULSFO", estimate: 0 },
                  ],
                },
                {
                  key: "ex133",
                  description: "TC Bunker Adjustment",
                  actual: (
                    parseFloat(props?.formData?.tci_pl_tc_bunker || 0) +
                    parseFloat(props?.formData?.tco_pl_tc_bunker || 0)
                  )?.toFixed(2),
                  estimate: 0,
                  posted: 0,
                  cash_in: 0,
                  Diff: 0,
                  perDiff: 0,
                  sec_variance: 0,
                  sec_per: 0,
                  children: [
                    {
                      key: "ex1330",
                      description: "TCI Bunker Adjustment",
                      actual: props?.formData?.tci_pl_tc_bunker || "0.00",
                      estimate: 0,
                      posted: 0,
                      cash_in: 0,
                      Diff: 0,
                      perDiff: 0,
                      sec_variance: 0,
                      sec_per: 0,
                    },
                    // { key: "ex1341", description: "TCO Bunker Adjustment", actual: props?.formData?.tco_pl_tc_bunker },
                    {
                      key: "ex1331",
                      description: "TCO Bunker Adjustment",
                      actual: props?.formData?.tco_pl_tc_bunker || "0.00",
                      estimate: 0,
                      posted: 0,
                      cash_in: 0,
                      Diff: 0,
                      perDiff: 0,
                      sec_variance: 0,
                      sec_per: 0,
                    },
                  ],
                },
                // {
                //   key: "ex134",
                //   description: "Port Consumption ECA",
                //   estimate: 0,
                //   children: [
                //     { key: "ex1341", description: "IFO", estimate: 0 },
                //     { key: "ex1342", description: "MGO", estimate: 0 },
                //     { key: "ex1343", description: "VLSFO", estimate: 0 },
                //     { key: "ex1345", description: "LSMGO", estimate: 0 },
                //     { key: "ex1344", description: "ULSFO", estimate: 0 },
                //   ],
                // },
              ],
            },
            {
              key: "ex14",
              description: "Other Expenses",
              estimate: 0,
              children: [],
            },
            { key: "ex15", description: "Gross Expenses", estimate: 0 },
            { key: "ex16", description: "Net Expenses", estimate: 0 },
          ],
        },
        {
          key: "voyage-result",
          description: "Voyage Result",
          estimate: 0,
          children: [
            { key: "vr20", description: "Profit (Loss)", estimate: 0 },
            { key: "vr21", description: "Daily Profit (Loss)", estimate: 0 },
            { key: "vr22", description: "CO2 Cost", estimate: 0 },
            {
              key: "vr23",
              description: "CO2 Adjusted Profit (Loss)",
              estimate: 0,
            },
            { key: "vr24", description: "CO2 Adjusted (Net) TCE", estimate: 0 },
            { key: "vr25", description: "Total Sea Days", estimate: 0 },
            { key: "vr26", description: "Total Port Days", estimate: 0 },
            { key: "vr27", description: "Gross Voyage Days", estimate: 0 },
            { key: "vr28", description: "Off Hire Days", estimate: 0 },
            { key: "vr29", description: "Net Voyage Days", estimate: 0 },
          ],
        },
      ],
      selectOptions: [
        {
          value: "actual",
          label: "Actual",
        },
      ],
    };

    this.elementRef = React.createRef();
    this.loading = false;
    this.saveLoading = false;
  }

  findCpPassage = (fuelType, passageType, consArr) => {
    let cp_price = 0;
    let fuel_cons = 0;
    consArr?.map((el) => {
      const { fuel_type, fuel_code } = el;
      if (fuel_type == fuelType) {
        cp_price = parseFloat(el.cp_price);
        if (passageType == 1) {
          fuel_cons = isNaN(el?.ballast_value)
            ? 0
            : parseFloat(el.ballast_value);
        } else if (passageType == 2) {
          fuel_cons = isNaN(el?.laden_value) ? 0 : parseFloat(el.laden_value);
        }
      }
    });

    cp_price = isNaN(cp_price) ? 0 : parseFloat(cp_price);
    fuel_cons = isNaN(fuel_cons) ? 0 : parseFloat(fuel_cons);
    return { cp_price, fuel_cons };
  };
  EcaSeaconsCalculation = (formdata) => {
    let ecaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let fuelType = formdata?.["eca_fuel_grade"] ?? 7;
    let ecafuelConsPrice = 0;

    formdata?.portitinerary?.map((el) => {
      const { eca_days, passage } = el;
      const { cp_price, fuel_cons } = this.findCpPassage(
        fuelType,
        passage,
        formdata["."]
      );

      ecafuelConsPrice += cp_price * eca_days * fuel_cons;
    });

    switch (fuelType) {
      case "3": // IFO
        ecaSeaCons["ifo"] = ecafuelConsPrice;
        break;

      case "4": // MGO
        ecaSeaCons["mgo"] = ecafuelConsPrice;
        break;

      case "5": //Vlsfo
        ecaSeaCons["vlsfo"] = ecafuelConsPrice;
        break;

      case "7": // lsmgo
        ecaSeaCons["lsmgo"] = ecafuelConsPrice;
        break;

      case "10": // ulsfo
        ecaSeaCons["ulsfo"] = ecafuelConsPrice;
        break;
      case "11": // HFO
        ecaSeaCons["hfo"] = ecafuelConsPrice;
        break;
    }

    return ecaSeaCons;
  };

  nonEcaSeaconsCalculation = (formdata) => {
    let nonEcaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let nonifo;
    let nonmgo;
    let nonlsmgo;
    let nonvlsfo;
    let nonulsfo;

    let ecafuelType = formdata?.["eca_fuel_grade"];
    formdata?.portitinerary?.map((el) => {
      const { eca_days, passage, tsd } = el;
      let nonEcadays = tsd - eca_days;
      nonifo = this.findCpPassage(3, passage, formdata["."]);
      nonmgo = this.findCpPassage(4, passage, formdata["."]);
      nonlsmgo = this.findCpPassage(7, passage, formdata["."]);
      nonvlsfo = this.findCpPassage(5, passage, formdata["."]);
      nonulsfo = this.findCpPassage(10, passage, formdata["."]);

      if (ecafuelType == 7) {
        if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (!nonvlsfo.fuel_cons && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;
          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        } else if (nonvlsfo.fuel_cons > 0 && !nonifo.fuel_cons) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        }
      } else if (ecafuelType == 10) {
        // ULSFO

        if (nonulsfo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonulsfo;
          nonEcaSeaCons["ulsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;

          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        }
      } else {
        if (
          nonifo.fuel_cons > 0 &&
          nonmgo.fuel_cons > 0 &&
          nonlsmgo.fuel_cons > 0 &&
          nonvlsfo.fuel_cons > 0 &&
          nonulsfo.fuel_cons > 0
        ) {
          let { cp_price, fuel_cons } = nonvlsfo;

          nonEcaSeaCons["vlsfo"] += parseFloat(fuel_cons * tsd * cp_price);
        } else {
          nonEcaSeaCons["ifo"] += nonifo.fuel_cons
            ? parseFloat(nonifo.fuel_cons * tsd * nonifo.cp_price)
            : 0;
          nonEcaSeaCons["lsmgo"] += nonlsmgo.fuel_cons
            ? parseFloat(nonlsmgo.fuel_cons * tsd * nonlsmgo.cp_price)
            : 0;
          nonEcaSeaCons["mgo"] += nonmgo.fuel_cons
            ? parseFloat(nonmgo.fuel_cons * tsd * nonmgo.cp_price)
            : 0;
          nonEcaSeaCons["vlsfo"] += nonvlsfo.fuel_cons
            ? parseFloat(nonvlsfo.fuel_cons * tsd * nonvlsfo.cp_price)
            : 0;
          nonEcaSeaCons["ulsfo"] += nonulsfo.fuel_cons
            ? parseFloat(nonulsfo.fuel_cons * tsd * nonulsfo.cp_price)
            : 0;
        }
      }
    });
    return nonEcaSeaCons;
  };

  nonEcaPortConsCalculation = (formdata) => {
    let nonEcaPortCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    formdata?.bunkerdetails?.map((el) => {
      nonEcaPortCons["ifo"] += isNaN(el["pc_ifo"])
        ? 0
        : parseFloat(el["pc_ifo"]);
      nonEcaPortCons["mgo"] += isNaN(el["pc_mgo"])
        ? 0
        : parseFloat(el["pc_mgo"]);
      nonEcaPortCons["lsmgo"] += isNaN(el["pc_lsmgo"])
        ? 0
        : parseFloat(el["pc_lsmgo"]);
      nonEcaPortCons["vlsfo"] += isNaN(el["pc_vlsfo"])
        ? 0
        : parseFloat(el["pc_vlsfo"]);
      nonEcaPortCons["ulsfo"] += isNaN(el["pc_ulsfo"])
        ? 0
        : parseFloat(el["pc_ulsfo"]);
    });
    return nonEcaPortCons;
  };

  totalEcaSecafuelCons = (consObj) => {
    let fuelValue = Object.values(consObj);
    return fuelValue.reduce((ac, el) => ac + el, 0);
  };

  ecaPortConsCalculation = () => {
    let ecaPortCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };
    // till now we dont have any update on this, how to calculate.
    return ecaPortCons;
  };

  dollarFormatedValue = (value) => {
    const { dollarUSLocale } = this.state;
    return isNaN(value)
      ? "0.00"
      : dollarUSLocale.format(parseFloat(value).toFixed(2));
  };
  __getEstimatePL = (calData, colName) => {
    try {
      const { dollarUSLocale } = this.state;
      let totalSeaConsumption = 0,
        totalPortConsumption = 0,
        totalSeaConsumptionEca = 0,
        //totalPortConsumptionEca = 0,
        vesselExpenses = 0;
      let ballast_bonus = 0;
      let _estimateData = Object.assign([], this.state.estimateData);

      Object.keys(
        calData["estimate"]["expenses"]["bunker_expenses"][
          "total_noneca_sea_cons"
        ]
      ).map(
        (e) =>
          (totalSeaConsumption +=
            (
              "" +
              calData["estimate"]["expenses"]["bunker_expenses"][
                "total_noneca_sea_cons"
              ][e]
            ).replaceAll(",", "") * 1)
      );

      // Object.keys(calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"]).map((e) => (totalPortConsumption += ("" + calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"][e]).replaceAll(",", "") * 1));
      Object.keys(calData["estimate"]["expenses"]["vessel_expenses"]).map(
        (e) =>
          (vesselExpenses +=
            (
              "" + calData["estimate"]["expenses"]["vessel_expenses"][e]
            ).replaceAll(",", "") * 1)
      );

      //----
      Object.keys(
        calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_sea_cons"]
      ).map(
        (e) =>
          (totalSeaConsumptionEca +=
            (
              "" +
              calData["estimate"]["expenses"]["bunker_expenses"][
                "total_eca_sea_cons"
              ][e]
            ).replaceAll(",", "") * 1)
      );
      // Object.keys(calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"]).map((e) => (totalPortConsumptionEca += ("" + calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"][e]).replaceAll(",", "") * 1));
      Object.keys(
        calData["estimate"]["expenses"]["bunker_expenses"][
          "total_eca_port_cons"
        ]
      ).map(
        (e) =>
          (totalPortConsumption +=
            (
              "" +
              calData["estimate"]["expenses"]["bunker_expenses"][
                "port_consumption"
              ][e]
            ).replaceAll(",", "") * 1)
      );
      //----

      _estimateData[0][colName] = calData["estimate"]["revenue"]["net_rev"];
      _estimateData[0]["children"][0][colName] =
        calData["estimate"]["revenue"]["tco_hire"];
      _estimateData[0]["children"][1][colName] =
        calData["estimate"]["revenue"]["tco_hire_br_comm"];
      _estimateData[0]["children"][2][colName] =
        calData["estimate"]["revenue"]["tco_hire_add_comm"];
      _estimateData[0]["children"][3][colName] =
        calData["estimate"]["revenue"]["tco_bb"];
      _estimateData[0]["children"][4][colName] =
        calData["estimate"]["revenue"]["tco_b_comm"]; //TCO BB Comm
      _estimateData[0]["children"][5][colName] =
        calData["estimate"]["revenue"]["tco_net_blast_revenue"]; //Net Ballast Bonus
      _estimateData[0]["children"][6][colName] =
        calData["estimate"]["revenue"]["misc_rev"];
      _estimateData[0]["children"][7][colName] =
        calData["estimate"]["revenue"]["tco_hire"]; //Total TCO Net Hire
      _estimateData[0]["children"][8][colName] =
        calData["estimate"]["revenue"]["tco_bb"]; //Total Net TCO BB
      _estimateData[0]["children"][9][colName] =
        calData["estimate"]["revenue"]["gross_rev"];
      _estimateData[0]["children"][10][colName] =
        calData["estimate"]["revenue"]["net_rev"];

      _estimateData[1][colName] =
        calData["estimate"]["expenses"]["netExpenses"];
      let hire_expenses = calData["estimate"]["expenses"]["vessel_expenses"][
        "hire_expenses"
      ]
        ? calData["estimate"]["expenses"]["vessel_expenses"][
            "hire_expenses"
          ].replaceAll(",", "") * 1
        : 0;

      let totalvesselExpenses = hire_expenses + ballast_bonus;
      _estimateData[1]["children"][0][colName] =
        dollarUSLocale.format(totalvesselExpenses);

      _estimateData[1]["children"][0]["children"][0][colName] =
        calData["estimate"]["expenses"]["vessel_expenses"]["hire_expenses"];
      _estimateData[1]["children"][0]["children"][1][colName] =
        calData["estimate"]["expenses"]["tci_add_comm"];
      _estimateData[1]["children"][0]["children"][2][colName] =
        calData["estimate"]["expenses"]["tci_broker_comm"];
      _estimateData[1]["children"][0]["children"][3][colName] =
        calData["estimate"]["expenses"]["vessel_expenses"]["ballast_bonus"];
      _estimateData[1]["children"][0]["children"][4][colName] =
        calData["estimate"]["expenses"]["vessel_expenses"]["tci_bb_comm"];

      _estimateData[1]["children"][1][colName] =
        calData["estimate"]["expenses"]["port_expenses"];

      _estimateData[1]["children"][2][colName] =
        calData["estimate"]["expenses"]["vessel_expenses"]["emission_expense"];

      // _estimateData[1]["children"][2][colName] = dollarUSLocale.format(totalPortConsumption + totalSeaConsumption + totalPortConsumptionEca + totalSeaConsumptionEca);
      _estimateData[1]["children"][3][colName] = dollarUSLocale.format(
        totalPortConsumption + totalSeaConsumption + totalSeaConsumptionEca
      );
      _estimateData[1]["children"][3]["children"][0][colName] =
        dollarUSLocale.format(totalSeaConsumption);
      _estimateData[1]["children"][3]["children"][0]["children"][0][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_noneca_sea_cons"
          ]["ifo"]
        );

      _estimateData[1]["children"][3]["children"][0]["children"][1][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_noneca_sea_cons"
          ]["mgo"]
        );
      _estimateData[1]["children"][3]["children"][0]["children"][2][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_noneca_sea_cons"
          ]["vlsfo"]
        );
      _estimateData[1]["children"][3]["children"][0]["children"][3][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_noneca_sea_cons"
          ]["lsmgo"]
        );
      _estimateData[1]["children"][3]["children"][0]["children"][4][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_noneca_sea_cons"
          ]["ulsfo"]
        );

      // _estimateData[1]["children"][2]["children"][1][colName] = dollarUSLocale.format(totalPortConsumption);
      // _estimateData[1]["children"][2]["children"][1]["children"][0][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"]["ifo"]);
      // _estimateData[1]["children"][2]["children"][1]["children"][1][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"]["mgo"]);
      // _estimateData[1]["children"][2]["children"][1]["children"][2][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"]["vlsfo"]);
      // _estimateData[1]["children"][2]["children"][1]["children"][3][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"]["lsmgo"]);
      // _estimateData[1]["children"][2]["children"][1]["children"][4][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_noneca_port_cons"]["ulsfo"]);

      _estimateData[1]["children"][3]["children"][1][colName] =
        dollarUSLocale.format(totalPortConsumption);
      _estimateData[1]["children"][3]["children"][1]["children"][0][colName] =
        calData["estimate"]["expenses"]["bunker_expenses"]["port_consumption"][
          "ifo"
        ];
      _estimateData[1]["children"][3]["children"][1]["children"][1][colName] =
        calData["estimate"]["expenses"]["bunker_expenses"]["port_consumption"][
          "mgo"
        ];
      _estimateData[1]["children"][3]["children"][1]["children"][2][colName] =
        calData["estimate"]["expenses"]["bunker_expenses"]["port_consumption"][
          "vlsfo"
        ];
      _estimateData[1]["children"][3]["children"][1]["children"][3][colName] =
        calData["estimate"]["expenses"]["bunker_expenses"]["port_consumption"][
          "lsmgo"
        ];
      _estimateData[1]["children"][3]["children"][1]["children"][4][colName] =
        calData["estimate"]["expenses"]["bunker_expenses"]["port_consumption"][
          "ulsfo"
        ];

      //---------------------------------

      _estimateData[1]["children"][3]["children"][2][colName] =
        dollarUSLocale.format(totalSeaConsumptionEca);
      _estimateData[1]["children"][3]["children"][2]["children"][0][colName] =
        this.dollarFormatedValue(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_eca_sea_cons"
          ]["ifo"]
        );
      _estimateData[1]["children"][3]["children"][2]["children"][1][colName] =
        this.dollarFormatedValue(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_eca_sea_cons"
          ]["mgo"]
        );
      _estimateData[1]["children"][3]["children"][2]["children"][2][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_eca_sea_cons"
          ]["vlsfo"]
        );
      _estimateData[1]["children"][3]["children"][2]["children"][3][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_eca_sea_cons"
          ]["lsmgo"]
        );
      _estimateData[1]["children"][3]["children"][2]["children"][4][colName] =
        dollarUSLocale.format(
          calData["estimate"]["expenses"]["bunker_expenses"][
            "total_eca_sea_cons"
          ]["ulsfo"]
        );

      // _estimateData[1]["children"][2]["children"][3][colName] = dollarUSLocale.format(totalPortConsumptionEca);
      // _estimateData[1]["children"][2]["children"][3]["children"][0][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"]["ifo"]);
      // _estimateData[1]["children"][2]["children"][3]["children"][1][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"]["mgo"]);
      // _estimateData[1]["children"][2]["children"][3]["children"][2][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"]["vlsfo"]);
      // _estimateData[1]["children"][2]["children"][3]["children"][3][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"]["lsmgo"]);
      // _estimateData[1]["children"][2]["children"][3]["children"][4][colName] = dollarUSLocale.format(calData["estimate"]["expenses"]["bunker_expenses"]["total_eca_port_cons"]["ulsfo"]);
      //-----------------------------------

      // _estimateData[1]["children"][3]["children"][3]["children"][0][colName] =
      //   "0.00";
      // _estimateData[1]["children"][3]["children"][3]["children"][1][colName] =
      //   "0.00";

      _estimateData[1]["children"][4][colName] =
        calData["estimate"]["expenses"]["mis_expenses"];

      calData?.["estimate"]?.["othercostsummary"] &&
        calData?.["estimate"]?.["othercostsummary"]?.length > 0 &&
        calData?.["estimate"]["othercostsummary"].map((el, ind) => {
          _estimateData[1]["children"][4]["children"][ind] = {
            key: "ex14" + ind,
            description: el.description,
            estimate: this.dollarFormatedValue(el.amount),
          };
        });
      _estimateData[1]["children"][5][colName] =
        calData["estimate"]["expenses"]["gross_expenses"];
      _estimateData[1]["children"][6][colName] =
        calData["estimate"]["expenses"]["netExpenses"];

      _estimateData[2][colName] = this.dollarFormatedValue(
        calData["estimate"]["voyage_result"]["profit_loss"].replace(
          /[&\/\\#,+()$~%'":*?<>{}]/g,
          ""
        )
      );

      _estimateData[2]["children"][0][colName] = this.dollarFormatedValue(
        calData["estimate"]["voyage_result"]["profit_loss"].replace(
          /[&\/\\#,+()$~%'":*?<>{}]/g,
          ""
        )
      );

      _estimateData[2]["children"][1][colName] =
        calData["estimate"]["voyage_result"]["daily_profit_loss"];
      _estimateData[2]["children"][2][colName] =
        calData["estimate"]["voyage_result"]["co2expense"];
      _estimateData[2]["children"][3][colName] =
        calData["estimate"]["voyage_result"]["CO2_adj_profit"];
      _estimateData[2]["children"][4][colName] =
        calData["estimate"]["voyage_result"]["co2_adjusted_tce"];
      _estimateData[2]["children"][5][colName] =
        calData["estimate"]["voyage_result"]["total_sea_days"];
      _estimateData[2]["children"][6][colName] =
        calData["estimate"]["voyage_result"]["total_port_days"];
      _estimateData[2]["children"][7][colName] =
        calData["estimate"]["voyage_result"]["net_voyage_days"];
      _estimateData[2]["children"][8][colName] = "0.00";
      _estimateData[2]["children"][9][colName] = "0.00";

      return _estimateData;
    } catch (err) {
      console.log("err", err);
    }
  };

  actualRevenueCalculation = (formdata) => {
    let tco_hire = 0;
    let tco_hire_bcom = 0;
    let tco_hire_addcom = 0;
    let tco_bb = 0;
    let gross_tco_bb_com = 0;
    let tco_net_Blast_Revenue = 0;
    let total_net_tco_bb = 0;

    formdata.tcoterm.map((el) => {
      const { acom, bcom, bb, bbcomm, dailyhire, duration, ratetype } = el;

      let tcohire = parseFloat(dailyhire * duration).toFixed(2);
      let tcohirebcom = parseFloat(tcohire * bcom * 0.01).toFixed(2);
      let tcohireaddcom = parseFloat(tcohire * acom * 0.01).toFixed(2);
      let tcobbcom =
        bbcomm == 1
          ? parseFloat(bb * acom * 0.01 + bb * bcom * 0.01).toFixed(2)
          : 0;
      let tconetblastbonus = bb - tcobbcom;

      tco_hire += parseFloat(tcohire);
      tco_hire_bcom += parseFloat(tcohirebcom);
      tco_hire_addcom += parseFloat(tcohireaddcom);
      tco_bb += parseFloat(bb);

      gross_tco_bb_com += parseFloat(tcobbcom);
      tco_net_Blast_Revenue += parseFloat(tconetblastbonus);
      total_net_tco_bb += parseFloat(bb);
    });

    return {
      tco_hire,
      tco_hire_bcom,
      tco_hire_addcom,
      tco_bb,
      gross_tco_bb_com,
      tco_net_Blast_Revenue,
      total_net_tco_bb,
    };
  };

  checkNaN = (el) => {
    return isNaN(el) ? 0 : el * 1;
  };

  __pl = () => {
    let revenue;
    let {
      estimateData,
      dollarUSLocale,
      formData,
      estimateDatavalue,
      showEstimatePl,
    } = this.state;
    let netExpenses = 0;
    let grossExpenses = 0;
    let totalSeaConsumption = 0,
      totalPortConsumption = 0,
      totalArriveConsumption = 0,
      totalDepConsumption = 0,
      totalAdjConsumption = 0;
    let colName = "actual";

    const FirstVSSecond = (p, c) => {
      let diff = 0;
      if (p && c) {
        diff = (p - c).toFixed(2);
      } else if (p && !c) {
        diff = (p * 1).toFixed(2);
      } else if (!p && c) {
        diff = (c * -1).toFixed(2);
      } else {
        diff = "0.00";
      }
      return isFinite(diff) ? diff : "0.00";
    };

    const perFirstVSSecond = (p, c) => {
      let perdiff = 0;
      if (p && c) {
        perdiff = ((p * 1 - c * 1) / p) * 1 * 100;
      } else if (p && !c) {
        perdiff = 100;
      } else if (!p && c) {
        perdiff = 0;
      }
      return isFinite(perdiff) ? perdiff.toFixed(2) : "0.00";
    };

    try {
      if (showEstimatePl == true) {
        estimateData = this.__getEstimatePL(estimateDatavalue, "estimate");
      }

      let totalVoyageDays = formData["total_days"]
        ? isNaN(("" + formData["total_days"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["total_days"]).replaceAll(",", "") * 1
        : 0;
      let tsd = 0,
        tpd = 0,
        pi = 0,
        fr = 0,
        mr = 0,
        grossRevenue = 0,
        netRevenue = 0,
        demmurage = 0,
        MiscRevenue = 0,
        lumpsum = 0,
        freightCommission = 0,
        demmurageCommission = 0,
        dispatch = 0,
        totalExpenses = 0,
        tci_bb = 0,
        cpQty = 0;

      let posted = {};
      let cash = {};
      let co2cost = 0;
      if (formData["-----"]?.inc_pnl === true) {
        co2cost = formData["-----"]?.ttl_co2_cost
          ? isNaN(
              ("" + formData["-----"]?.ttl_co2_cost).replaceAll(",", "") * 1
            )
            ? 0
            : ("" + formData["-----"]?.ttl_co2_cost).replaceAll(",", "") * 1
          : 0;
      }
      let ecaSeacons = this.EcaSeaconsCalculation(formData);
      let nonEcaSeacons = this.nonEcaSeaconsCalculation(formData);
      // let nonecaPortcons = this.nonEcaPortConsCalculation(formData);
      let ecaPortCons = this.ecaPortConsCalculation(formData);
      let totalecaSeacons = this.totalEcaSecafuelCons(ecaSeacons);

      let totalnonEcaSeacons = this.totalEcaSecafuelCons(nonEcaSeacons);
      // let totalnonecaPortcons = this.totalEcaSecafuelCons(nonecaPortcons);
      let totalecaPortCons = this.totalEcaSecafuelCons(ecaPortCons);

      let {
        tcohireExpenses_posted,
        tcoHireBComm_posted,
        tcoHireAddComm_posted,
        tcoBB_posted,
        miscRev_posted,
        vesselExpenses_posted,
        hireExpenses_posted,
        tciaddcom_posted,
        tcibcom_posted,
        portExpenses_posted,
        bunkerExpenses_posted,
        miscExpenses_posted,
        grossExpenses_posted,
        netExpenses_posted,
      } = estimateDatavalue && estimateDatavalue.post;

      let tcoBB_com_posted = 0;
      let tcoBB_com_cash = 0;
      let tcoBB_net_posted = 0;
      let tcoBB_net_cash = 0;
      let tcoBB_ttl_tco_net_posted = 0;
      let tcoBB_ttl_tco_net_cash = 0;
      let tcoBB_ttl_net_posted = 0;
      let tcoBB_ttl_net_cash = 0;

      let {
        tcohireExpenses_cash,
        tcoHireBComm_cash,
        tcoHireAddComm_cash,
        tcoBB_cash,
        miscRev_cash,
        vesselExpenses_cash,
        hireExpenses_cash,
        tciaddcom_cash,
        tcibcom_cash,
        portExpenses_cash,
        bunkerExpenses_cash,
        miscExpenses_cash,
        grossExpenses_cash,
        netExpenses_cash,
      } = estimateDatavalue && estimateDatavalue.cash;

      let gross_revenue_posted = 0,
        gross_revenue_cash = 0,
        net_revenue_posted = 0,
        net_revenue_cash = 0;
      let gross_exp_posted = 0,
        gross_exp_cash = 0,
        net_exp_posted = 0,
        net_exp_cash = 0;

      const postVScash = (p, c) => {
        let diff = 0;
        if (p && c) {
          diff = p * 1 - c * 1;
        } else if (p && !c) {
          diff = p * 1;
        } else if (!p && c) {
          diff = c * -1;
        } else {
          diff = 0;
        }
        return diff.toFixed(2);
      };

      const perPostVScash = (p, c) => {
        let perdiff = 0;
        if (p && c) {
          perdiff = ((p * 1 - c * 1) / p) * 1 * 100;
        } else if (p && !c) {
          perdiff = 100;
        } else if (!p && c) {
          perdiff = 0;
        }
        return perdiff.toFixed(2);
      };

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portItinerary = formData["portitinerary"];
        portItinerary.map((e) => {
          tsd += (e.tsd + "").replaceAll(",", "") * 1;
          tpd += (e.t_port_days + "").replaceAll(",", "") * 1;
        });

        tsd = tsd * 1;
        tpd = tpd * 1;
      }
      totalVoyageDays =
        tpd + tsd > 0 && tpd + tsd > totalVoyageDays
          ? tpd + tsd
          : totalVoyageDays;
      // Revenue vm tcto---------------------------------------------

      let tco_bb_com = 0;
      let net_blast_bonus = 0;
      let ttl_tco_net_hire = 0;
      let ttl_net_tco_bb = 0;

      let otherCost = formData["other_cost"]
        ? isNaN(("" + formData["other_cost"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["other_cost"]).replaceAll(",", "") * 1
        : 0;
      let hire = formData["tci_d_hire"]
        ? (formData["tci_d_hire"] + "").replaceAll(",", "") * 1
        : 0;
      let addPercentage = formData["add_percentage"]
        ? (formData["add_percentage"] + "").replaceAll(",", "") * 1
        : 0;
      let amt_add_percentage = hire * totalVoyageDays * addPercentage * 0.01;
      let broPercentage = formData["bro_percentage"]
        ? (formData["bro_percentage"] + "").replaceAll(",", "") * 1
        : 0;
      let amt_bro_percentage = hire * totalVoyageDays * broPercentage * 0.01;
      MiscRevenue = formData["mis_cost"]
        ? (formData["mis_cost"] + "").replaceAll(",", "") * 1
        : 0;
      let tco_hire = 0;
      let tco_bb = 0;
      let dailyhire = 0;
      let duration = 0;

      const revenuecalc = this.actualRevenueCalculation(formData);

      //   ---------------Revenue Calculation---------------------------

      let tco_gross_revenue =
        this.checkNaN(revenuecalc.tco_hire) +
        this.checkNaN(revenuecalc.tco_bb) +
        MiscRevenue;

      let tco_net_revenue =
        tco_gross_revenue -
        (this.checkNaN(revenuecalc.tco_hire_addcom) +
          this.checkNaN(revenuecalc.tco_hire_bcom) +
          this.checkNaN(revenuecalc.gross_tco_bb_com));

      // Expenses vm tcto-----------------------------------------------------

      let portExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        seaExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivalrev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        deprev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivaladjuestmentrev = {
          ifo: 0,
          mgo: 0,
          vlsfo: 0,
          ulsfo: 0,
          lsmgo: 0,
        };
      tci_bb = formData["bb"]
        ? isNaN(("" + formData["bb"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["bb"]).replaceAll(",", "") * 1
        : 0;

      if (formData && formData.hasOwnProperty("bunkerdetails")) {
        let bunkerDetails = formData["bunkerdetails"];
        let i = 0;
        bunkerDetails.map((e, i, { length }) => {
          seaExpenses["ifo"] += e["ifo"]
            ? isNaN(("" + e["ifo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["ifo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["mgo"] += e["mgo"]
            ? isNaN(("" + e["mgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["mgo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["vlsfo"] += e["vlsfo"]
            ? isNaN(("" + e["vlsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["vlsfo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["lsmgo"] += e["lsmgo"]
            ? isNaN(("" + e["lsmgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["lsmgo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["ulsfo"] += e["ulsfo"]
            ? isNaN(("" + e["ulsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["ulsfo"]).replaceAll(",", "") * 1
            : 0;

          portExpenses["ifo"] += e["pc_ifo"]
            ? isNaN(("" + e["pc_ifo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_ifo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["mgo"] += e["pc_mgo"]
            ? isNaN(("" + e["pc_mgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_mgo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["vlsfo"] += e["pc_vlsfo"]
            ? isNaN(("" + e["pc_vlsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_vlsfo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["lsmgo"] += e["pc_lsmgo"]
            ? isNaN(("" + e["pc_lsmgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_lsmgo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["ulsfo"] += e["pc_ulsfo"]
            ? isNaN(("" + e["pc_ulsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_ulsfo"]).replaceAll(",", "") * 1
            : 0;

          if (i == 0) {
            arrivalrev["ifo"] += e["arob_ifo"]
              ? isNaN(("" + e["arob_ifo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ifo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["mgo"] += e["arob_mgo"]
              ? isNaN(("" + e["arob_mgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_mgo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["vlsfo"] += e["arob_vlsfo"]
              ? isNaN(("" + e["arob_vlsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_vlsfo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["lsmgo"] += e["arob_lsmgo"]
              ? isNaN(("" + e["arob_lsmgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_lsmgo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["ulsfo"] += e["arob_ulsfo"]
              ? isNaN(("" + e["arob_ulsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ulsfo"]).replaceAll(",", "") * 1
              : 0;
          }

          //last element
          if (i + 1 === length) {
            deprev["ifo"] += e["dr_ifo"]
              ? isNaN(("" + e["dr_ifo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ifo"]).replaceAll(",", "") * 1
              : 0;
            deprev["mgo"] += e["dr_mgo"]
              ? isNaN(("" + e["dr_mgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_mgo"]).replaceAll(",", "") * 1
              : 0;
            deprev["vlsfo"] += e["dr_vlsfo"]
              ? isNaN(("" + e["dr_vlsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_vlsfo"]).replaceAll(",", "") * 1
              : 0;
            deprev["lsmgo"] += e["dr_lsmgo"]
              ? isNaN(("" + e["dr_lsmgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_lsmgo"]).replaceAll(",", "") * 1
              : 0;
            deprev["ulsfo"] += e["dr_ulsfo"]
              ? isNaN(("" + e["dr_ulsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ulsfo"]).replaceAll(",", "") * 1
              : 0;
          }
        });
      }

      if (formData && formData.hasOwnProperty(".")) {
        let cpData = formData["."];
        let price_cp_eco =
          formData.hasOwnProperty("price_cp_eco") && formData["price_cp_eco"]
            ? 1
            : 0; // 1 is P$ else CP$
        cpData.map((e) => {
          let _price = 0;
          let _pprice = 0;
          if (
            e["cp_price"] &&
            !isNaN(("" + e["cp_price"]).replaceAll(",", "") * 1)
          ) {
            _price = ("" + e["cp_price"]).replaceAll(",", "") * 1;
          }
          if (
            e["purchase_price"] &&
            !isNaN(("" + e["purchase_price"]).replaceAll(",", "") * 1)
          ) {
            _pprice = ("" + e["purchase_price"]).replaceAll(",", "") * 1;
          }

          switch (e.fuel_code) {
            case "IFO":
              seaExpenses["ifo"] =
                price_cp_eco == 1
                  ? seaExpenses["ifo"] * _pprice
                  : seaExpenses["ifo"] * _price;
              portExpenses["ifo"] =
                price_cp_eco == 1
                  ? portExpenses["ifo"] * _pprice
                  : portExpenses["ifo"] * _price;
              arrivalrev["ifo"] = arrivalrev["ifo"] * _price;
              deprev["ifo"] = deprev["ifo"] * _pprice;
              arrivaladjuestmentrev["ifo"] = deprev["ifo"] - arrivalrev["ifo"];
              break;

            case "MGO":
              seaExpenses["mgo"] =
                price_cp_eco == 1
                  ? seaExpenses["mgo"] * _pprice
                  : seaExpenses["mgo"] * _price;
              portExpenses["mgo"] =
                price_cp_eco == 1
                  ? portExpenses["mgo"] * _pprice
                  : portExpenses["mgo"] * _price;
              arrivalrev["mgo"] = arrivalrev["mgo"] * _price;
              deprev["mgo"] = deprev["mgo"] * _pprice;
              arrivaladjuestmentrev["mgo"] = deprev["mgo"] - arrivalrev["mgo"];
              break;

            case "VLSFO":
              seaExpenses["vlsfo"] =
                price_cp_eco == 1
                  ? seaExpenses["vlsfo"] * _pprice
                  : seaExpenses["vlsfo"] * _price;
              portExpenses["vlsfo"] =
                price_cp_eco == 1
                  ? portExpenses["vlsfo"] * _pprice
                  : portExpenses["vlsfo"] * _price;
              arrivalrev["vlsfo"] = arrivalrev["vlsfo"] * _price;
              deprev["vlsfo"] = deprev["vlsfo"] * _pprice;
              arrivaladjuestmentrev["vlsfo"] =
                deprev["vlsfo"] - arrivalrev["vlsfo"];
              break;

            case "LSMGO":
              seaExpenses["lsmgo"] =
                price_cp_eco == 1
                  ? seaExpenses["lsmgo"] * _pprice
                  : seaExpenses["lsmgo"] * _price;
              portExpenses["lsmgo"] =
                price_cp_eco == 1
                  ? portExpenses["lsmgo"] * _pprice
                  : portExpenses["lsmgo"] * _price;
              arrivalrev["lsmgo"] = arrivalrev["lsmgo"] * _price;
              deprev["lsmgo"] = deprev["lsmgo"] * _pprice;
              arrivaladjuestmentrev["lsmgo"] =
                deprev["lsmgo"] - arrivalrev["lsmgo"];
              break;

            case "ULSFO":
              seaExpenses["ulsfo"] =
                price_cp_eco == 1
                  ? (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _pprice
                  : (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _price;
              portExpenses["ulsfo"] =
                price_cp_eco == 1
                  ? (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price
                  : (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price;
              arrivalrev["ulsfo"] =
                (isNaN(arrivalrev["ulsfo"]) ? 0 : arrivalrev["ulsfo"]) * _price;
              deprev["ulsfo"] =
                (isNaN(deprev["ulsfo"]) ? 0 : deprev["ulsfo"]) * _pprice;
              arrivaladjuestmentrev["ulsfo"] =
                deprev["ulsfo"] - arrivalrev["ulsfo"];
              break;
          }
        });
      }

      Object.keys(seaExpenses).map(
        (e) => (totalSeaConsumption += seaExpenses[e])
      );
      Object.keys(portExpenses).map(
        (e) => (totalPortConsumption += portExpenses[e])
      );
      let nonecaPortcons = portExpenses;
      let totalnonecaPortcons = this.totalEcaSecafuelCons(nonecaPortcons);
      let bunkerExpense =
        this.checkNaN(totalecaSeacons) +
        this.checkNaN(totalnonEcaSeacons) +
        this.checkNaN(totalnonecaPortcons) +
        this.checkNaN(totalecaPortCons);
      Object.keys(arrivalrev).map(
        (e) => (totalArriveConsumption += arrivalrev[e])
      );
      Object.keys(deprev).map((e) => (totalDepConsumption += deprev[e]));
      Object.keys(arrivaladjuestmentrev).map(
        (e) => (totalAdjConsumption += arrivaladjuestmentrev[e])
      );

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portitinerary = formData["portitinerary"];
        portitinerary.map(
          (e) =>
            (pi += isNaN(("" + e.p_exp).replaceAll(",", "") * 1)
              ? 0
              : ("" + e.p_exp).replaceAll(",", "") * 1)
        );
      }

      // grossExpenses =
      //   hire * totalVoyageDays +
      //   +totalSeaConsumption +
      //   totalPortConsumption +
      //   pi +
      //   otherCost;
      let vesselExpense = totalVoyageDays * hire;

      grossExpenses =
        this.checkNaN(vesselExpense) +
        this.checkNaN(pi) +
        this.checkNaN(bunkerExpense) +
        this.checkNaN(otherCost) +
        this.checkNaN(co2cost);

      netExpenses = grossExpenses - (amt_add_percentage + amt_bro_percentage);

      ///--------------------------------show the value as table on the Ui-----------------------

      estimateData[0][colName] = this.dollarFormatedValue(tco_net_revenue);
      let reves = estimateData[0]["estimate"]
        ? estimateData[0]["estimate"].replaceAll(",", "") * 1
        : 0;
      let revac = estimateData[0]["actual"]
        ? estimateData[0]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["Diff"] = (revac - reves).toFixed(2);
      estimateData[0]["perDiff"] =
        reves == 0 ? "0.00" : (((revac - reves) / reves) * 100).toFixed(2);
      estimateData[0]["posted"] = net_revenue_posted
        ? net_revenue_posted
        : "0.00";
      estimateData[0]["cash_in"] = net_revenue_cash ? net_revenue_cash : "0.00";
      estimateData[0]["sec_variance"] = postVScash(
        net_revenue_posted,
        net_revenue_cash
      );
      estimateData[0]["sec_per"] = perPostVScash(
        net_revenue_posted,
        net_revenue_cash
      );

      ///  GRoss Tco Hire-----------------------------------------------------------
      estimateData[0]["children"][0][colName] = this.dollarFormatedValue(
        revenuecalc.tco_hire
      );
      let tcohirees = estimateData[0]["children"][0]["estimate"]
        ? estimateData[0]["children"][0]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tcohireac = estimateData[0]["children"][0]["actual"]
        ? estimateData[0]["children"][0]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][0]["Diff"] = (tcohireac - tcohirees).toFixed(
        2
      );
      estimateData[0]["children"][0]["perDiff"] =
        tcohirees == 0
          ? "0.00"
          : (((tcohireac - tcohirees) / tcohirees) * 100).toFixed(2);
      estimateData[0]["children"][0]["posted"] = tcohireExpenses_posted
        ? tcohireExpenses_posted
        : "0.00";
      estimateData[0]["children"][0]["cash_in"] = tcohireExpenses_cash
        ? tcohireExpenses_cash
        : "0.00";
      estimateData[0]["children"][0]["sec_variance"] = postVScash(
        tcohireExpenses_posted,
        tcohireExpenses_cash
      );
      estimateData[0]["children"][0]["sec_per"] = perPostVScash(
        tcohireExpenses_posted,
        tcohireExpenses_cash
      );

      //  TCO Hire B. comm---------------------------------------------------------
      estimateData[0]["children"][1][colName] = this.dollarFormatedValue(
        revenuecalc.tco_hire_bcom
      );
      let tcobroperes = estimateData[0]["children"][1]["estimate"]
        ? estimateData[0]["children"][1]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tcobroperac = estimateData[0]["children"][1]["actual"]
        ? estimateData[0]["children"][1]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][1]["Diff"] = (
        tcobroperac - tcobroperes
      ).toFixed(2);
      estimateData[0]["children"][1]["perDiff"] =
        tcobroperes == 0
          ? "0.00"
          : (((tcobroperac - tcobroperes) / tcobroperes) * 100).toFixed(2);
      estimateData[0]["children"][1]["posted"] = tcoHireBComm_posted
        ? tcoHireBComm_posted
        : "0.00";
      estimateData[0]["children"][1]["cash_in"] = tcoHireBComm_cash
        ? tcoHireBComm_cash
        : "0.00";
      estimateData[0]["children"][1]["sec_variance"] = postVScash(
        tcoHireBComm_posted,
        tcoHireBComm_cash
      );
      estimateData[0]["children"][1]["sec_per"] = perPostVScash(
        tcoHireBComm_posted,
        tcoHireBComm_cash
      );

      // TCO Hire add com-------------------------------------------------------
      estimateData[0]["children"][2][colName] = this.dollarFormatedValue(
        revenuecalc.tco_hire_addcom
      );
      let tcoaddperes = estimateData[0]["children"][2]["estimate"]
        ? estimateData[0]["children"][2]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tcoaddperac = estimateData[0]["children"][2]["actual"]
        ? estimateData[0]["children"][2]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][2]["Diff"] = (
        tcoaddperac - tcoaddperes
      ).toFixed(2);
      estimateData[0]["children"][2]["perDiff"] =
        tcoaddperes == 0
          ? "0.00"
          : (((tcoaddperac - tcoaddperes) / tcoaddperes) * 100).toFixed(2);
      estimateData[0]["children"][2]["posted"] = tcoHireAddComm_posted
        ? tcoHireAddComm_posted
        : "0.00";
      estimateData[0]["children"][2]["cash_in"] = tcoHireAddComm_cash
        ? tcoHireAddComm_cash
        : "0.00";
      estimateData[0]["children"][2]["sec_variance"] = postVScash(
        tcoHireAddComm_posted,
        tcoHireAddComm_cash
      );
      estimateData[0]["children"][2]["sec_per"] = perPostVScash(
        tcoHireAddComm_posted,
        tcoHireAddComm_cash
      );

      /// TCO BB----------------------------------------------------------------

      estimateData[0]["children"][3][colName] = this.dollarFormatedValue(
        revenuecalc.tco_bb
      );
      let tco_bbes = estimateData[0]["children"][3]["estimate"]
        ? estimateData[0]["children"][3]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tco_bbac = estimateData[0]["children"][3]["actual"]
        ? estimateData[0]["children"][3]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][3]["Diff"] = (tco_bbac - tco_bbes).toFixed(2);
      estimateData[0]["children"][3]["perDiff"] =
        tco_bbes == 0
          ? "0.00"
          : (((tco_bbac - tco_bbes) / tco_bbes) * 100).toFixed(2);
      estimateData[0]["children"][3]["posted"] = tcoBB_posted
        ? tcoBB_posted
        : "0.00";
      estimateData[0]["children"][3]["cash_in"] = tcoBB_cash
        ? tcoBB_cash
        : "0.00";
      estimateData[0]["children"][3]["sec_variance"] = postVScash(
        tcoBB_posted,
        tcoBB_cash
      );
      estimateData[0]["children"][3]["sec_per"] = perPostVScash(
        tcoBB_posted,
        tcoBB_cash
      );

      //-----------------------
      //TCO BB Comm---------------------------------------------------------------
      estimateData[0]["children"][4][colName] = this.dollarFormatedValue(
        revenuecalc.gross_tco_bb_com
      );
      let tco_bb_com_es = estimateData[0]["children"][4]["estimate"]
        ? estimateData[0]["children"][4]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tco_bb_com_ac = estimateData[0]["children"][4]["actual"]
        ? estimateData[0]["children"][4]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][4]["Diff"] = (
        tco_bb_com_ac - tco_bb_com_es
      ).toFixed(2);
      estimateData[0]["children"][4]["perDiff"] =
        tco_bb_com_es == 0
          ? "0.00"
          : (((tco_bb_com_ac - tco_bb_com_es) / tco_bb_com_es) * 100).toFixed(
              2
            );
      estimateData[0]["children"][4]["posted"] = tcoBB_com_posted
        ? tcoBB_com_posted
        : "0.00";
      estimateData[0]["children"][4]["cash_in"] = tcoBB_com_cash
        ? tcoBB_com_cash
        : "0.00";
      estimateData[0]["children"][4]["sec_variance"] = postVScash(
        tcoBB_com_posted,
        tcoBB_com_cash
      );
      estimateData[0]["children"][4]["sec_per"] = perPostVScash(
        tcoBB_com_posted,
        tcoBB_com_cash
      );
      //-----------------------

      //---------Net Ballast Bonus---------------------------------------------------

      estimateData[0]["children"][5][colName] = this.dollarFormatedValue(
        revenuecalc.tco_net_Blast_Revenue
      );
      let tco_bb_net_es = estimateData[0]["children"][5]["estimate"]
        ? estimateData[0]["children"][5]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tco_bb_net_ac = estimateData[0]["children"][5]["actual"]
        ? estimateData[0]["children"][5]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][5]["Diff"] = (
        tco_bb_net_ac - tco_bb_net_es
      ).toFixed(2);
      estimateData[0]["children"][5]["perDiff"] =
        tco_bb_net_es == 0
          ? "0.00"
          : (((tco_bb_net_ac - tco_bb_net_es) / tco_bb_net_es) * 100).toFixed(
              2
            );
      estimateData[0]["children"][5]["posted"] = tcoBB_net_posted
        ? tcoBB_net_posted
        : "0.00";
      estimateData[0]["children"][5]["cash_in"] = tcoBB_net_cash
        ? tcoBB_net_cash
        : "0.00";
      estimateData[0]["children"][5]["sec_variance"] = postVScash(
        tcoBB_net_posted,
        tcoBB_net_cash
      );
      estimateData[0]["children"][5]["sec_per"] = perPostVScash(
        tcoBB_net_posted,
        tcoBB_net_cash
      );

      //---------Misc. Revenue-----------------------------------------------------------
      estimateData[0]["children"][6][colName] = dollarUSLocale.format(
        MiscRevenue.toFixed(2)
      );
      let mises = estimateData[0]["children"][6]["estimate"]
        ? estimateData[0]["children"][6]["estimate"].replaceAll(",", "") * 1
        : 0;
      let misvac = estimateData[0]["children"][6]["actual"]
        ? estimateData[0]["children"][6]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][6]["Diff"] = (misvac - mises).toFixed(2);
      estimateData[0]["children"][6]["perDiff"] =
        mises == 0 ? "0.00" : (((misvac - mises) / mises) * 100).toFixed(2);
      estimateData[0]["children"][6]["posted"] = miscRev_posted
        ? miscRev_posted
        : "0.00";
      estimateData[0]["children"][6]["cash_in"] = miscRev_cash
        ? miscRev_cash
        : "0.00";
      estimateData[0]["children"][6]["sec_variance"] = postVScash(
        miscRev_posted,
        miscRev_cash
      );
      estimateData[0]["children"][6]["sec_per"] = perPostVScash(
        miscRev_posted,
        miscRev_cash
      );

      //--------Total TCO Net Hire--------------------------------------------------------------

      estimateData[0]["children"][7][colName] = this.dollarFormatedValue(
        revenuecalc.tco_hire
      );
      let ttl_tco_net_hire_es = estimateData[0]["children"][7]["estimate"]
        ? estimateData[0]["children"][7]["estimate"].replaceAll(",", "") * 1
        : 0;
      let ttl_tco_net_hire_ac = estimateData[0]["children"][7]["actual"]
        ? estimateData[0]["children"][7]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][7]["Diff"] = (
        ttl_tco_net_hire_ac - ttl_tco_net_hire_es
      ).toFixed(2);
      estimateData[0]["children"][7]["perDiff"] =
        ttl_tco_net_hire_es == 0
          ? "0.00"
          : (
              ((ttl_tco_net_hire_ac - ttl_tco_net_hire_es) /
                ttl_tco_net_hire_es) *
              100
            ).toFixed(2);
      estimateData[0]["children"][7]["posted"] = tcoBB_ttl_tco_net_posted
        ? tcoBB_ttl_tco_net_posted
        : "0.00";
      estimateData[0]["children"][7]["cash_in"] = tcoBB_ttl_tco_net_cash
        ? tcoBB_ttl_tco_net_cash
        : "0.00";
      estimateData[0]["children"][7]["sec_variance"] = postVScash(
        tcoBB_ttl_tco_net_posted,
        tcoBB_ttl_tco_net_cash
      );
      estimateData[0]["children"][7]["sec_per"] = perPostVScash(
        tcoBB_ttl_tco_net_posted,
        tcoBB_ttl_tco_net_cash
      );

      //---------Total Net TCO BB-------------------------------------------------------------

      estimateData[0]["children"][8][colName] = this.dollarFormatedValue(
        revenuecalc.tco_bb
      );
      let ttl_net_tco_bb_es = estimateData[0]["children"][8]["estimate"]
        ? estimateData[0]["children"][8]["estimate"].replaceAll(",", "") * 1
        : 0;
      let ttl_net_tco_bb_ac = estimateData[0]["children"][8]["actual"]
        ? estimateData[0]["children"][8]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][8]["Diff"] = (
        ttl_net_tco_bb_ac - ttl_net_tco_bb_es
      ).toFixed(2);
      estimateData[0]["children"][8]["perDiff"] =
        ttl_net_tco_bb_es == 0
          ? "0.00"
          : (
              ((ttl_net_tco_bb_ac - ttl_net_tco_bb_es) / ttl_net_tco_bb_es) *
              100
            ).toFixed(2);
      estimateData[0]["children"][8]["posted"] = tcoBB_ttl_net_posted
        ? tcoBB_ttl_net_posted
        : "0.00";
      estimateData[0]["children"][8]["cash_in"] = tcoBB_ttl_net_cash
        ? tcoBB_ttl_net_cash
        : "0.00";
      estimateData[0]["children"][8]["sec_variance"] = postVScash(
        tcoBB_ttl_net_posted,
        tcoBB_ttl_net_posted
      );
      estimateData[0]["children"][8]["sec_per"] = perPostVScash(
        tcoBB_ttl_net_posted,
        tcoBB_ttl_net_posted
      );

      //------------Gross Revenue------------------------------------------------------------------
      estimateData[0]["children"][9][colName] =
        this.dollarFormatedValue(tco_gross_revenue);
      let grosses = estimateData[0]["children"][9]["estimate"]
        ? estimateData[0]["children"][9]["estimate"].replaceAll(",", "") * 1
        : 0;
      let grossac = estimateData[0]["children"][9]["actual"]
        ? estimateData[0]["children"][9]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][9]["Diff"] = (grossac - grosses).toFixed(2);
      estimateData[0]["children"][9]["perDiff"] =
        grosses == 0
          ? "0.00"
          : (((grossac - grosses) / grosses) * 100).toFixed(2);
      estimateData[0]["children"][9]["posted"] = gross_revenue_posted
        ? gross_revenue_posted
        : "0.00";
      estimateData[0]["children"][9]["cash_in"] = gross_revenue_cash
        ? gross_revenue_cash
        : "0.00";
      estimateData[0]["children"][9]["sec_variance"] = postVScash(
        gross_revenue_posted,
        gross_revenue_cash
      );
      estimateData[0]["children"][9]["sec_per"] = perPostVScash(
        gross_revenue_posted,
        gross_revenue_cash
      );

      // Net Revenue-----------------------------------------------------------------------
      estimateData[0]["children"][10][colName] =
        this.dollarFormatedValue(tco_net_revenue);
      let netreves = estimateData[0]["children"][10]["estimate"]
        ? estimateData[0]["children"][10]["estimate"].replaceAll(",", "") * 1
        : 0;
      let netrevac = estimateData[0]["children"][10]["actual"]
        ? estimateData[0]["children"][10]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][10]["Diff"] = (netrevac - netreves).toFixed(
        2
      );
      estimateData[0]["children"][10]["perDiff"] =
        netreves == 0
          ? "0.00"
          : (((netrevac - netreves) / netreves) * 100).toFixed(2);
      estimateData[0]["children"][10]["posted"] = net_revenue_posted
        ? net_revenue_posted
        : "0.00";
      estimateData[0]["children"][10]["cash_in"] = net_revenue_cash
        ? net_revenue_cash
        : "0.00";
      estimateData[0]["children"][10]["sec_variance"] = postVScash(
        net_revenue_posted,
        net_revenue_cash
      );
      estimateData[0]["children"][10]["sec_per"] = perPostVScash(
        net_revenue_posted,
        net_revenue_cash
      );

      //-------------------show expenses on the ui ---------------------------------------------------------
      estimateData[1][colName] = dollarUSLocale.format(netExpenses.toFixed(2));
      let expnses = estimateData[1]["estimate"]
        ? estimateData[1]["estimate"].replaceAll(",", "") * 1
        : 0;
      let expnsac = estimateData[1]["actual"]
        ? estimateData[1]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["Diff"] = (expnsac - expnses).toFixed(2);
      estimateData[1]["perDiff"] =
        expnses == 0
          ? "0.00"
          : (((expnsac - expnses) / expnses) * 100).toFixed(2);
      estimateData[1]["posted"] = net_exp_posted ? net_exp_posted : "0.00";
      estimateData[1]["cash_in"] = net_exp_cash ? net_exp_cash : "0.00";
      estimateData[1]["sec_variance"] = postVScash(
        net_exp_posted,
        net_exp_cash
      );
      estimateData[1]["sec_per"] = perPostVScash(net_exp_posted, net_exp_cash);

      estimateData[1]["children"][0][colName] =
        this.dollarFormatedValue(vesselExpense);

      let veselexpnses = estimateData[1]["children"][0]["estimate"]
        ? estimateData[1]["children"][0]["estimate"].replaceAll(",", "") * 1
        : 0;
      let veselexpnsac = estimateData[1]["children"][0]["actual"]
        ? estimateData[1]["children"][0]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][0]["Diff"] = (
        veselexpnsac - veselexpnses
      ).toFixed(2);
      estimateData[1]["children"][0]["perDiff"] =
        veselexpnses == 0
          ? "0.00"
          : (((veselexpnsac - veselexpnses) / veselexpnses) * 100).toFixed(2);

      estimateData[1]["children"][0]["posted"] = hireExpenses_posted
        ? hireExpenses_posted
        : "0.00";
      estimateData[1]["children"][0]["cash_in"] = hireExpenses_cash
        ? hireExpenses_cash
        : "0.00";
      estimateData[1]["children"][0]["sec_variance"] = postVScash(
        hireExpenses_posted,
        hireExpenses_cash
      );
      estimateData[1]["children"][0]["sec_per"] = perPostVScash(
        hireExpenses_posted,
        hireExpenses_cash
      );

      /// ------------hire Expenses----------------------------
      estimateData[1]["children"][0]["children"][0][colName] =
        this.dollarFormatedValue(vesselExpense);
      let hireexpnses = estimateData[1]["children"][0]["children"][0][
        "estimate"
      ]
        ? estimateData[1]["children"][0]["children"][0]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let hireexpnsac = estimateData[1]["children"][0]["children"][0]["actual"]
        ? estimateData[1]["children"][0]["children"][0]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][0]["children"][0]["Diff"] = (
        hireexpnsac - hireexpnses
      ).toFixed(2);
      estimateData[1]["children"][0]["children"][0]["perDiff"] =
        hireexpnses == 0
          ? "0.00"
          : (((hireexpnsac - hireexpnses) / hireexpnses) * 100).toFixed(2);
      estimateData[1]["children"][0]["children"][0]["posted"] =
        hireExpenses_posted ? hireExpenses_posted : "0.00";
      estimateData[1]["children"][0]["children"][0]["cash_in"] =
        hireExpenses_cash ? hireExpenses_cash : "0.00";
      estimateData[1]["children"][0]["children"][0]["sec_variance"] =
        postVScash(hireExpenses_posted, hireExpenses_cash);
      estimateData[1]["children"][0]["children"][0]["sec_per"] = perPostVScash(
        hireExpenses_posted,
        hireExpenses_cash
      );

      //------------------Tci Add Comm-------------------------------------
      estimateData[1]["children"][0]["children"][1][colName] =
        dollarUSLocale.format(amt_add_percentage);
      let tciaddcomes = estimateData[1]["children"][0]["children"][1][
        "estimate"
      ]
        ? estimateData[1]["children"][0]["children"][1]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let tciaddcomac = estimateData[1]["children"][0]["children"][1]["actual"]
        ? estimateData[1]["children"][0]["children"][1]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][0]["children"][1]["Diff"] = (
        tciaddcomac - tciaddcomes
      ).toFixed(2);
      estimateData[1]["children"][0]["children"][1]["perDiff"] =
        tciaddcomes == 0
          ? "0.00"
          : (((tciaddcomac - tciaddcomes) / tciaddcomes) * 100).toFixed(2);
      estimateData[1]["children"][0]["children"][1]["posted"] = "0.00";
      estimateData[1]["children"][0]["children"][1]["cash_in"] = "0.00";
      estimateData[1]["children"][0]["children"][1]["sec_variance"] = "0.00";
      estimateData[1]["children"][0]["children"][1]["sec_per"] = "0.00";

      ///-------------TCi Broker Comm.-----------------------------------
      estimateData[1]["children"][0]["children"][2][colName] =
        dollarUSLocale.format(amt_bro_percentage);
      let tcibrocomes = estimateData[1]["children"][0]["children"][2][
        "estimate"
      ]
        ? estimateData[1]["children"][0]["children"][2]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let tcibrocomac = estimateData[1]["children"][0]["children"][2]["actual"]
        ? estimateData[1]["children"][0]["children"][2]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][0]["children"][2]["Diff"] = (
        tcibrocomac - tcibrocomes
      ).toFixed(2);
      estimateData[1]["children"][0]["children"][2]["perDiff"] =
        tcibrocomes == 0
          ? "0.00"
          : (((tcibrocomac - tcibrocomes) / tcibrocomes) * 100).toFixed(2);
      estimateData[1]["children"][0]["children"][2]["posted"] = tcibcom_posted
        ? tcibcom_posted
        : "0.00";
      estimateData[1]["children"][0]["children"][2]["cash_in"] = tcibcom_cash
        ? tcibcom_cash
        : "0.00";
      estimateData[1]["children"][0]["children"][2]["sec_variance"] =
        postVScash(tcibcom_posted, tcibcom_cash);
      estimateData[1]["children"][0]["children"][2]["sec_per"] = perPostVScash(
        tcibcom_posted,
        tcibcom_cash
      );

      ///-----------------------TCI Ballast Bonus-------------------------
      estimateData[1]["children"][0]["children"][3][colName] =
        dollarUSLocale.format(tci_bb);
      let blstbnses = estimateData[1]["children"][0]["children"][3]["estimate"]
        ? estimateData[1]["children"][0]["children"][3]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let blstbnsac = estimateData[1]["children"][0]["children"][3]["actual"]
        ? estimateData[1]["children"][0]["children"][3]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][0]["children"][3]["Diff"] = (
        blstbnsac - blstbnses
      ).toFixed(2);
      estimateData[1]["children"][0]["children"][3]["perDiff"] =
        blstbnses == 0
          ? "0.00"
          : (((blstbnsac - blstbnses) / blstbnses) * 100).toFixed(2);
      estimateData[1]["children"][0]["children"][3]["posted"] = "0.00";
      estimateData[1]["children"][0]["children"][3]["cash_in"] = "0.00";
      estimateData[1]["children"][0]["children"][3]["sec_variance"] = "0.00";
      estimateData[1]["children"][0]["children"][3]["sec_per"] = "0.00";

      let tci_bb_comm = tci_bb * broPercentage * 0.01;

      //--------------------------TCi BB Comm.--------------------------------
      estimateData[1]["children"][0]["children"][4][colName] =
        dollarUSLocale.format(tci_bb_comm);
      let blstbncomses = estimateData[1]["children"][0]["children"][4][
        "estimate"
      ]
        ? estimateData[1]["children"][0]["children"][4]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let blstbcomnsac = estimateData[1]["children"][0]["children"][4]["actual"]
        ? estimateData[1]["children"][0]["children"][4]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][0]["children"][4]["Diff"] = (
        blstbcomnsac - blstbncomses
      ).toFixed(2);
      estimateData[1]["children"][0]["children"][4]["perDiff"] =
        blstbncomses == 0
          ? "0.00"
          : (((blstbcomnsac - blstbncomses) / blstbncomses) * 100).toFixed(2);
      estimateData[1]["children"][0]["children"][4]["posted"] = "0.00";
      estimateData[1]["children"][0]["children"][4]["cash_in"] = "0.00";
      estimateData[1]["children"][0]["children"][4]["sec_variance"] = "0.00";
      estimateData[1]["children"][0]["children"][4]["sec_per"] = "0.00";

      //************************************************ */
      // ---------------Port Expenses---------------------------------
      estimateData[1]["children"][1][colName] = dollarUSLocale.format(
        pi.toFixed(2)
      );
      let prtexpnses = estimateData[1]["children"][1]["estimate"]
        ? estimateData[1]["children"][1]["estimate"].replaceAll(",", "") * 1
        : 0;
      let prtexpnsac = estimateData[1]["children"][1]["actual"]
        ? estimateData[1]["children"][1]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][1]["Diff"] = (
        prtexpnsac - prtexpnses
      ).toFixed(2);
      estimateData[1]["children"][1]["perDiff"] =
        prtexpnses == 0
          ? "0.00"
          : (((prtexpnsac - prtexpnses) / prtexpnses) * 100).toFixed(2);
      estimateData[1]["children"][1]["posted"] = portExpenses_posted
        ? portExpenses_posted
        : "0.00";
      estimateData[1]["children"][1]["cash_in"] = portExpenses_cash
        ? portExpenses_cash
        : "0.00";
      estimateData[1]["children"][1]["sec_variance"] = postVScash(
        portExpenses_posted,
        portExpenses_cash
      );
      estimateData[1]["children"][1]["sec_per"] = perPostVScash(
        portExpenses_posted,
        portExpenses_cash
      );

      ///--------------------------Emission Expenses-----------------------

      let emission_expense = co2cost;
      estimateData[1]["children"][2][colName] =
        dollarUSLocale.format(emission_expense);
      let emission_expes = estimateData[1]["children"][2]["estimate"]
        ? estimateData[1]["children"][2]["estimate"].replaceAll(",", "") * 1
        : 0;
      let emission_expac = estimateData[1]["children"][2]["actual"]
        ? estimateData[1]["children"][2]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][2]["Diff"] = (
        emission_expac - emission_expes
      ).toFixed(2);
      estimateData[1]["children"][2]["perDiff"] =
        emission_expes == 0
          ? "0.00"
          : (
              ((emission_expac - emission_expes) / emission_expes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][2]["posted"] = "0.00";
      estimateData[1]["children"][2]["cash_in"] = "0.00";
      estimateData[1]["children"][2]["sec_variance"] = "0.00";
      estimateData[1]["children"][2]["sec_per"] = "0.00";

      ////-------------Bunker Expenses---------------------------------------------
      estimateData[1]["children"][3][colName] = dollarUSLocale.format(
        bunkerExpense.toFixed(2)
      );
      let bnkrexpnses = estimateData[1]["children"][3]["estimate"]
        ? estimateData[1]["children"][3]["estimate"].replaceAll(",", "") * 1
        : 0;
      let bnkrexpnsac = estimateData[1]["children"][3]["actual"]
        ? estimateData[1]["children"][3]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["Diff"] = (
        bnkrexpnsac - bnkrexpnses
      ).toFixed(2);
      estimateData[1]["children"][3]["perDiff"] =
        bnkrexpnses == 0
          ? "0.00"
          : (((bnkrexpnsac - bnkrexpnses) / bnkrexpnses) * 100).toFixed(2);
      estimateData[1]["children"][3]["posted"] = bunkerExpenses_posted
        ? bunkerExpenses_posted
        : "0.00";
      estimateData[1]["children"][3]["cash_in"] = bunkerExpenses_cash
        ? bunkerExpenses_cash
        : "0.00";
      estimateData[1]["children"][3]["sec_variance"] = postVScash(
        bunkerExpenses_posted,
        bunkerExpenses_cash
      );
      estimateData[1]["children"][3]["sec_per"] = perPostVScash(
        bunkerExpenses_posted,
        bunkerExpenses_cash
      );

      ///-------------Sea Cons. Non Eca-----------------------------
      estimateData[1]["children"][3]["children"][0][colName] =
        dollarUSLocale.format(totalnonEcaSeacons.toFixed(2));
      let seacnsmpes = estimateData[1]["children"][3]["children"][0]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let seacnsmpac = estimateData[1]["children"][3]["children"][0]["actual"]
        ? estimateData[1]["children"][3]["children"][0]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][3]["children"][0]["Diff"] = (
        seacnsmpac - seacnsmpes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["perDiff"] =
        seacnsmpes == 0
          ? "0.00"
          : (((seacnsmpac - seacnsmpes) / seacnsmpes) * 100).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["posted"] = "0.00";
      estimateData[1]["children"][3]["children"][0]["cash_in"] = "0.00";
      estimateData[1]["children"][3]["children"][0]["sec_variance"] = "0.00";
      estimateData[1]["children"][3]["children"][0]["sec_per"] = "0.00";

      // --------------sea Cons Non Eca Ifo----------------------------------------
      estimateData[1]["children"][3]["children"][0]["children"][0][colName] =
        dollarUSLocale.format(nonEcaSeacons["ifo"].toFixed(2));
      let seaexpnsifoes = estimateData[1]["children"][3]["children"][0][
        "children"
      ][0]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["children"][0][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsifoac = estimateData[1]["children"][3]["children"][0][
        "children"
      ][0]["actual"]
        ? estimateData[1]["children"][3]["children"][0]["children"][0][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][0]["children"][0]["Diff"] = (
        seaexpnsifoac - seaexpnsifoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][0]["perDiff"] =
        seaexpnsifoes == 0
          ? "0.00"
          : (((seaexpnsifoac - seaexpnsifoes) / seaexpnsifoes) * 100).toFixed(
              2
            );
      estimateData[1]["children"][3]["children"][0]["children"][0]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][0]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][0][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][0]["sec_per"] =
        "0.00";

      ///- sea cons non eca MGO-----------------------------------------------------

      estimateData[1]["children"][3]["children"][0]["children"][1][colName] =
        dollarUSLocale.format(nonEcaSeacons["mgo"].toFixed(2));
      let seaexpnsmgoes = estimateData[1]["children"][3]["children"][0][
        "children"
      ][1]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["children"][1][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsmgoac = estimateData[1]["children"][3]["children"][0][
        "children"
      ][1]["actual"]
        ? estimateData[1]["children"][3]["children"][0]["children"][1][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][0]["children"][1]["Diff"] = (
        seaexpnsmgoac - seaexpnsmgoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][1]["perDiff"] =
        seaexpnsmgoes == 0
          ? "0.00"
          : (((seaexpnsmgoac - seaexpnsmgoes) / seaexpnsmgoes) * 100).toFixed(
              2
            );
      estimateData[1]["children"][3]["children"][0]["children"][1]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][1]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][1][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][1]["sec_per"] =
        "0.00";

      ///---sea cons non eca vlsfo-------------------------------------------------
      estimateData[1]["children"][3]["children"][0]["children"][2][colName] =
        dollarUSLocale.format(nonEcaSeacons["vlsfo"].toFixed(2));
      let seaexpnsvlsfoes = estimateData[1]["children"][3]["children"][0][
        "children"
      ][2]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["children"][2][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsvlsfoac = estimateData[1]["children"][3]["children"][0][
        "children"
      ][2]["actual"]
        ? estimateData[1]["children"][3]["children"][0]["children"][2][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][0]["children"][2]["Diff"] = (
        seaexpnsvlsfoac - seaexpnsvlsfoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][2]["perDiff"] =
        seaexpnsvlsfoes == 0
          ? "0.00"
          : (
              ((seaexpnsvlsfoac - seaexpnsvlsfoes) / seaexpnsvlsfoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][2]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][2]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][2][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][2]["sec_per"] =
        "0.00";

      ///-----------------sea cons non eca lsmgo

      estimateData[1]["children"][3]["children"][0]["children"][3][colName] =
        dollarUSLocale.format(nonEcaSeacons["lsmgo"].toFixed(2));
      let seaexpnslsmgoes = estimateData[1]["children"][3]["children"][0][
        "children"
      ][3]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["children"][3][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnslsmgoac = estimateData[1]["children"][3]["children"][0][
        "children"
      ][3]["actual"]
        ? estimateData[1]["children"][3]["children"][0]["children"][3][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][0]["children"][3]["Diff"] = (
        seaexpnslsmgoac - seaexpnslsmgoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][3]["perDiff"] =
        seaexpnslsmgoes == 0
          ? "0.00"
          : (
              ((seaexpnslsmgoac - seaexpnslsmgoes) / seaexpnslsmgoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][3]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][3]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][3][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][3]["sec_per"] =
        "0.00";

      //--------------sea cons non eca ulsfo-----------------------------
      estimateData[1]["children"][3]["children"][0]["children"][4][colName] =
        dollarUSLocale.format(nonEcaSeacons["ulsfo"].toFixed(2));
      let seaexpnsulsfoes = estimateData[1]["children"][3]["children"][0][
        "children"
      ][4]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["children"][4][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsulsfoac = estimateData[1]["children"][3]["children"][0][
        "children"
      ][4]["actual"]
        ? estimateData[1]["children"][3]["children"][0]["children"][4][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][0]["children"][4]["Diff"] = (
        seaexpnsulsfoac - seaexpnsulsfoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][4]["perDiff"] =
        seaexpnsulsfoes == 0
          ? "0.00"
          : (
              ((seaexpnsulsfoac - seaexpnsulsfoes) / seaexpnsulsfoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][0]["children"][4]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][4]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][4][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][0]["children"][4]["sec_per"] =
        "0.00";

      //port cons non eca
      estimateData[1]["children"][3]["children"][1][colName] =
        dollarUSLocale.format(totalnonecaPortcons.toFixed(2));
      let prtcnspmes = estimateData[1]["children"][3]["children"][1]["estimate"]
        ? estimateData[1]["children"][3]["children"][1]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let prtcnsmpac = estimateData[1]["children"][3]["children"][1]["actual"]
        ? estimateData[1]["children"][3]["children"][1]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][3]["children"][1]["Diff"] = (
        prtcnsmpac - prtcnspmes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["perDiff"] =
        prtcnspmes == 0
          ? "0.00"
          : (((prtcnsmpac - prtcnspmes) / prtcnspmes) * 100).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["posted"] = "0.00";
      estimateData[1]["children"][3]["children"][1]["cash_in"] = "0.00";
      estimateData[1]["children"][3]["children"][1]["sec_variance"] = "0.00";
      estimateData[1]["children"][3]["children"][1]["sec_per"] = "0.00";

      //--------------------------------- ifo port cons non eca--------------------------------------------
      estimateData[1]["children"][3]["children"][1]["children"][0][colName] =
        dollarUSLocale.format(nonecaPortcons["ifo"].toFixed(2));
      let prtcnsmpifoes = estimateData[1]["children"][3]["children"][1][
        "children"
      ][0]["estimate"]
        ? estimateData[1]["children"][3]["children"][1]["children"][0][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let prtcnsmpifoac = estimateData[1]["children"][3]["children"][1][
        "children"
      ][0]["actual"]
        ? estimateData[1]["children"][3]["children"][1]["children"][0][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][1]["children"][0]["Diff"] = (
        prtcnsmpifoac - prtcnsmpifoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][0]["perDiff"] =
        prtcnsmpifoes == 0
          ? "0.00"
          : (((prtcnsmpifoac - prtcnsmpifoes) / prtcnsmpifoes) * 100).toFixed(
              2
            );
      estimateData[1]["children"][3]["children"][1]["children"][0]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][0]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][0][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][0]["sec_per"] =
        "0.00";

      ///------------------------port cons non eca mgo-------------------------------

      estimateData[1]["children"][3]["children"][1]["children"][1][colName] =
        dollarUSLocale.format(nonecaPortcons["mgo"].toFixed(2));
      let prtcnsmpmgoes = estimateData[1]["children"][3]["children"][1][
        "children"
      ][1]["estimate"]
        ? estimateData[1]["children"][3]["children"][1]["children"][1][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let prtcnsmpmgoac = estimateData[1]["children"][3]["children"][1][
        "children"
      ][1]["actual"]
        ? estimateData[1]["children"][3]["children"][1]["children"][1][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][1]["children"][1]["Diff"] = (
        prtcnsmpmgoac - prtcnsmpmgoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][1]["perDiff"] =
        prtcnsmpmgoes == 0
          ? "0.00"
          : (((prtcnsmpmgoac - prtcnsmpmgoes) / prtcnsmpmgoes) * 100).toFixed(
              2
            );
      estimateData[1]["children"][3]["children"][1]["children"][1]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][1]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][1][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][1]["sec_per"] =
        "0.00";

      ///-----------------------------------port cons non eca vlsfo------------------
      estimateData[1]["children"][3]["children"][1]["children"][2][colName] =
        dollarUSLocale.format(nonecaPortcons["vlsfo"].toFixed(2));
      let prtcnsmpvlsfoes = estimateData[1]["children"][3]["children"][1][
        "children"
      ][2]["estimate"]
        ? estimateData[1]["children"][3]["children"][1]["children"][2][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let prtcnsmpvlsfoac = estimateData[1]["children"][3]["children"][1][
        "children"
      ][2]["actual"]
        ? estimateData[1]["children"][3]["children"][1]["children"][2][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][1]["children"][2]["Diff"] = (
        prtcnsmpvlsfoac - prtcnsmpvlsfoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][2]["perDiff"] =
        prtcnsmpvlsfoes == 0
          ? "0.00"
          : (
              ((prtcnsmpvlsfoac - prtcnsmpvlsfoes) / prtcnsmpvlsfoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][2]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][2]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][2][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][2]["sec_per"] =
        "0.00";

      ///--------------------------  port cons non eca lsmgo---------------------------
      estimateData[1]["children"][3]["children"][1]["children"][3][colName] =
        dollarUSLocale.format(nonecaPortcons["lsmgo"].toFixed(2));
      let prtcnsmplsmgoes = estimateData[1]["children"][3]["children"][1][
        "children"
      ][3]["estimate"]
        ? estimateData[1]["children"][3]["children"][1]["children"][3][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let prtcnsmplsmgoac = estimateData[1]["children"][3]["children"][1][
        "children"
      ][3]["actual"]
        ? estimateData[1]["children"][3]["children"][1]["children"][3][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][1]["children"][3]["Diff"] = (
        prtcnsmplsmgoac - prtcnsmplsmgoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][3]["perDiff"] =
        prtcnsmplsmgoes == 0
          ? "0.00"
          : (
              ((prtcnsmplsmgoac - prtcnsmplsmgoes) / prtcnsmplsmgoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][3]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][3]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][3][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][3]["sec_per"] =
        "0.00";

      ///----------------port cons non eca ulsfo---------------------------------------
      estimateData[1]["children"][3]["children"][1]["children"][4][colName] =
        dollarUSLocale.format(nonecaPortcons["ulsfo"].toFixed(2));
      let prtcnsmpulsfoes = estimateData[1]["children"][3]["children"][1][
        "children"
      ][4]["estimate"]
        ? estimateData[1]["children"][3]["children"][1]["children"][4][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let prtcnsmpulsfoac = estimateData[1]["children"][3]["children"][1][
        "children"
      ][4]["actual"]
        ? estimateData[1]["children"][3]["children"][1]["children"][4][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][1]["children"][4]["Diff"] = (
        prtcnsmpulsfoac - prtcnsmpulsfoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][4]["perDiff"] =
        prtcnsmpulsfoes == 0
          ? "0.00"
          : (
              ((prtcnsmpulsfoac - prtcnsmpulsfoes) / prtcnsmpulsfoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][1]["children"][4]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][4]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][4][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][1]["children"][4]["sec_per"] =
        "0.00";

      //------------
      //Sea cons ECA-------------------------------------------------
      estimateData[1]["children"][3]["children"][2][colName] =
        dollarUSLocale.format(totalecaSeacons.toFixed(2));
      let seacnsmpecaes = estimateData[1]["children"][3]["children"][2][
        "estimate"
      ]
        ? estimateData[1]["children"][3]["children"][2]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let seacnsmpecaac = estimateData[1]["children"][3]["children"][2][
        "actual"
      ]
        ? estimateData[1]["children"][3]["children"][2]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][3]["children"][2]["Diff"] = (
        seacnsmpecaac - seacnsmpecaes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["perDiff"] =
        seacnsmpecaes == 0
          ? "0.00"
          : (((seacnsmpecaac - seacnsmpecaes) / seacnsmpecaes) * 100).toFixed(
              2
            );
      estimateData[1]["children"][3]["children"][2]["posted"] = "0.00";
      estimateData[1]["children"][3]["children"][2]["cash_in"] = "0.00";
      estimateData[1]["children"][3]["children"][2]["sec_variance"] = "0.00";
      estimateData[1]["children"][3]["children"][2]["sec_per"] = "0.00";

      /// -----------sea cons eca ifo--------------------------------------------
      estimateData[1]["children"][3]["children"][2]["children"][0][colName] =
        dollarUSLocale.format(ecaSeacons["ifo"].toFixed(2));
      let seaexpnsecaifoes = estimateData[1]["children"][3]["children"][2][
        "children"
      ][0]["estimate"]
        ? estimateData[1]["children"][3]["children"][0]["children"][0][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsecaifoac = estimateData[1]["children"][3]["children"][2][
        "children"
      ][0]["actual"]
        ? estimateData[1]["children"][3]["children"][2]["children"][0][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][2]["children"][0]["Diff"] = (
        seaexpnsecaifoac - seaexpnsecaifoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][0]["perDiff"] =
        seaexpnsecaifoes == 0
          ? "0.00"
          : (
              ((seaexpnsecaifoac - seaexpnsecaifoes) / seaexpnsifoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][0]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][0]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][0][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][0]["sec_per"] =
        "0.00";

      ///----------------------------------sea cons eca mgo---------------------------
      estimateData[1]["children"][3]["children"][2]["children"][1][colName] =
        dollarUSLocale.format(ecaSeacons["mgo"].toFixed(2));
      let seaexpnsecamgoes = estimateData[1]["children"][3]["children"][2][
        "children"
      ][1]["estimate"]
        ? estimateData[1]["children"][3]["children"][2]["children"][1][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsecamgoac = estimateData[1]["children"][3]["children"][2][
        "children"
      ][1]["actual"]
        ? estimateData[1]["children"][3]["children"][2]["children"][1][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][2]["children"][1]["Diff"] = (
        seaexpnsecamgoac - seaexpnsecamgoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][1]["perDiff"] =
        seaexpnsecamgoes == 0
          ? "0.00"
          : (
              ((seaexpnsecamgoac - seaexpnsecamgoes) / seaexpnsmgoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][1]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][1]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][1][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][1]["sec_per"] =
        "0.00";

      //----------------sea cons eca vlsfo---------------------------------------
      estimateData[1]["children"][3]["children"][2]["children"][2][colName] =
        dollarUSLocale.format(ecaSeacons["vlsfo"].toFixed(2));
      let seaexpnsecavlsfoes = estimateData[1]["children"][3]["children"][2][
        "children"
      ][2]["estimate"]
        ? estimateData[1]["children"][3]["children"][2]["children"][2][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsecavlsfoac = estimateData[1]["children"][3]["children"][2][
        "children"
      ][2]["actual"]
        ? estimateData[1]["children"][3]["children"][2]["children"][2][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][2]["children"][2]["Diff"] = (
        seaexpnsecavlsfoac - seaexpnsecavlsfoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][2]["perDiff"] =
        seaexpnsecavlsfoes == 0
          ? "0.00"
          : (
              ((seaexpnsecavlsfoac - seaexpnsvlsfoes) / seaexpnsecavlsfoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][2]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][2]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][2][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][2]["sec_per"] =
        "0.00";

      ///---------------------------sea cons eca ulsfo--------------------------------
      estimateData[1]["children"][3]["children"][2]["children"][4][colName] =
        dollarUSLocale.format(ecaSeacons["ulsfo"].toFixed(2));
      let seaexpnsecaulsfoes = estimateData[1]["children"][3]["children"][2][
        "children"
      ][4]["estimate"]
        ? estimateData[1]["children"][3]["children"][2]["children"][4][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsecaulsfoac = estimateData[1]["children"][3]["children"][2][
        "children"
      ][4]["actual"]
        ? estimateData[1]["children"][3]["children"][2]["children"][4][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][2]["children"][4]["Diff"] = (
        seaexpnsecaulsfoac - seaexpnsecaulsfoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][4]["perDiff"] =
        seaexpnsecaulsfoes == 0
          ? "0.00"
          : (
              ((seaexpnsecaulsfoac - seaexpnsecaulsfoes) / seaexpnsulsfoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][4]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][4]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][4][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][4]["sec_per"] =
        "0.00";

      //-----------------------------sea cons eca lsmgo-----------------------------------

      estimateData[1]["children"][3]["children"][2]["children"][3][colName] =
        dollarUSLocale.format(ecaSeacons["lsmgo"].toFixed(2));
      let seaexpnsecalsmgoes = estimateData[1]["children"][3]["children"][2][
        "children"
      ][3]["estimate"]
        ? estimateData[1]["children"][3]["children"][2]["children"][3][
            "estimate"
          ].replaceAll(",", "") * 1
        : 0;
      let seaexpnsecalsmgoac = estimateData[1]["children"][3]["children"][2][
        "children"
      ][3]["actual"]
        ? estimateData[1]["children"][3]["children"][2]["children"][3][
            "actual"
          ].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][3]["children"][2]["children"][3]["Diff"] = (
        seaexpnsecalsmgoac - seaexpnsecalsmgoes
      ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][3]["perDiff"] =
        seaexpnsecalsmgoes == 0
          ? "0.00"
          : (
              ((seaexpnsecalsmgoac - seaexpnsecalsmgoes) / seaexpnslsmgoes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][3]["children"][2]["children"][3]["posted"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][3]["cash_in"] =
        "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][3][
        "sec_variance"
      ] = "0.00";
      estimateData[1]["children"][3]["children"][2]["children"][3]["sec_per"] =
        "0.00";

      ///---------------tc bunker adjustment----------------

      //-------------other expenses-----------------
      estimateData[1]["children"][4][colName] = dollarUSLocale.format(
        otherCost.toFixed(2)
      );
      let miscstes = estimateData[1]["children"][4]["estimate"]
        ? estimateData[1]["children"][4]["estimate"].replaceAll(",", "") * 1
        : 0;
      let miscstac = estimateData[1]["children"][4]["actual"]
        ? estimateData[1]["children"][4]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][4]["Diff"] = (miscstac - miscstes).toFixed(2);
      estimateData[1]["children"][4]["perDiff"] =
        miscstes == 0
          ? "0.00"
          : (((miscstac - miscstes) / miscstes) * 100).toFixed(2);
      estimateData[1]["children"][4]["posted"] = miscExpenses_posted
        ? miscExpenses_posted
        : "0.00";
      estimateData[1]["children"][4]["cash_in"] = miscExpenses_cash
        ? miscExpenses_cash
        : "0.00";
      estimateData[1]["children"][4]["sec_variance"] = postVScash(
        miscExpenses_posted,
        miscExpenses_cash
      );
      estimateData[1]["children"][4]["sec_per"] = perPostVScash(
        miscExpenses_posted,
        miscExpenses_cash
      );

      //---------------other expense breakup----------------------

      formData?.["othercostsummary"] &&
        formData?.["othercostsummary"]?.length > 0 &&
        formData["othercostsummary"].map((el, ind) => {
          let est = estimateData[1]["children"][4]["children"][ind]?.[
            "estimate"
          ]
            ? parseFloat(
                estimateData[1]["children"][4]["children"][ind]?.[
                  "estimate"
                ].replaceAll(",", "")
              )
            : 0;
          let act = el.amount;

          estimateData[1]["children"][4]["children"][ind] = {
            ...estimateData[1]["children"][4]["children"][ind],
            key: "ex14" + ind,
            description: el.description,
            actual: this.dollarFormatedValue(el.amount),

            Diff: FirstVSSecond(act, est),
            perDiff: perFirstVSSecond(act, est),
            posted: "0.00",
            cash_in: "0.00",
            sec_variance: "0.00",
            sec_per: "0.00",
          };
        });

      /// ---------------gross expenses-------------------------------------------------------
      estimateData[1]["children"][5][colName] = dollarUSLocale.format(
        grossExpenses.toFixed(2)
      );
      let grossest = estimateData[1]["children"][5]["estimate"]
        ? estimateData[1]["children"][5]["estimate"].replaceAll(",", "") * 1
        : 0;
      let grossact = estimateData[1]["children"][5]["actual"]
        ? estimateData[1]["children"][5]["actual"].replaceAll(",", "") * 1
        : 0;

      estimateData[1]["children"][5]["Diff"] = (grossact - grossest).toFixed(2);
      estimateData[1]["children"][5]["perDiff"] =
        grossest == 0
          ? "0.00"
          : (((grossact - grossest) / grossest) * 100).toFixed(2);
      estimateData[1]["children"][5]["posted"] = "0.00";
      estimateData[1]["children"][5]["cash_in"] = "0.00";
      estimateData[1]["children"][5]["sec_variance"] = "0.00";
      estimateData[1]["children"][5]["sec_per"] = "0.00";

      //--------------- net expenses--------------------------------------
      estimateData[1]["children"][6][colName] = dollarUSLocale.format(
        netExpenses.toFixed(2)
      );
      let netexpest = estimateData[1]["children"][6]["estimate"]
        ? estimateData[1]["children"][6]["estimate"].replaceAll(",", "") * 1
        : 0;
      let netexpact = estimateData[1]["children"][6]["actual"]
        ? estimateData[1]["children"][6]["actual"].replaceAll(",", "") * 1
        : 0;

      estimateData[1]["children"][6]["Diff"] = (netexpact - netexpest).toFixed(
        2
      );
      estimateData[1]["children"][6]["perDiff"] =
        netexpest == 0
          ? "0.00"
          : (((netexpact - netexpest) / netexpest) * 100).toFixed(2);
      estimateData[1]["children"][6]["posted"] = "0.00";
      estimateData[1]["children"][6]["cash_in"] = "0.00";
      estimateData[1]["children"][6]["sec_variance"] = "0.00";
      estimateData[1]["children"][6]["sec_per"] = "0.00";

      //TC Bunker Adjustment
      estimateData[1]["children"][3]["children"][3]["estimate"] = "0.00";
      estimateData[1]["children"][3]["children"][3]["actual"] = estimateData[1][
        "children"
      ][3]["children"][3]["actual"]
        ? estimateData[1]["children"][3]["children"][3]["actual"]
        : "0.00";
      estimateData[1]["children"][3]["children"][3]["posted"] = "0.00";
      estimateData[1]["children"][3]["children"][3]["cash_in"] = "0.00";
      estimateData[1]["children"][3]["children"][3]["Diff"] = "0.00";
      estimateData[1]["children"][3]["children"][3]["perDiff"] = "0.00";
      estimateData[1]["children"][3]["children"][3]["sec_variance"] = "0.00";
      estimateData[1]["children"][3]["children"][3]["sec_per"] = "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][0]["estimate"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["estimate"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0][
              "estimate"
            ]
          : "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][0]["actual"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["actual"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0][
              "actual"
            ]
          : "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][0]["posted"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["posted"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0][
              "posted"
            ]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][0]["cash_in"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["cash_in"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0][
              "cash_in"
            ]
          : "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][0]["Diff"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["Diff"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0]["Diff"]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][0]["perDiff"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["perDiff"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0][
              "perDiff"
            ]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][0][
        "sec_variance"
      ] = estimateData[1]["children"][3]["children"][3]["children"][0][
        "sec_variance"
      ]
        ? estimateData[1]["children"][3]["children"][3]["children"][0][
            "sec_variance"
          ]
        : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][0]["sec_per"] =
        estimateData[1]["children"][3]["children"][3]["children"][0]["sec_per"]
          ? estimateData[1]["children"][3]["children"][3]["children"][0][
              "sec_per"
            ]
          : "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][1]["estimate"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["estimate"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1][
              "estimate"
            ]
          : "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][1]["actual"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["actual"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1][
              "actual"
            ]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][1]["posted"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["posted"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1][
              "posted"
            ]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][1]["cash_in"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["cash_in"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1][
              "cash_in"
            ]
          : "0.00";

      estimateData[1]["children"][3]["children"][3]["children"][1]["Diff"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["Diff"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1]["Diff"]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][1]["perDiff"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["perDiff"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1][
              "perDiff"
            ]
          : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][1][
        "sec_variance"
      ] = estimateData[1]["children"][3]["children"][3]["children"][1][
        "sec_variance"
      ]
        ? estimateData[1]["children"][3]["children"][3]["children"][1][
            "sec_variance"
          ]
        : "0.00";
      estimateData[1]["children"][3]["children"][3]["children"][1]["sec_per"] =
        estimateData[1]["children"][3]["children"][3]["children"][1]["sec_per"]
          ? estimateData[1]["children"][3]["children"][3]["children"][1][
              "sec_per"
            ]
          : "0.00";

      let itemValue = tco_net_revenue - netExpenses;

      let tceHire = (
        itemValue -
        (totalExpenses -
          (tco_bb +
            pi +
            0 +
            otherCost +
            amt_add_percentage +
            amt_bro_percentage)) /
          (itemValue / (totalVoyageDays - 0))
      ).toFixed(2);
      estimateData[2][colName] = this.dollarFormatedValue(itemValue);

      estimateData[2]["children"][0][colName] =
        this.dollarFormatedValue(itemValue);

      let vygreses = estimateData[2]["estimate"]
        ? estimateData[2]["estimate"].replaceAll(",", "") * 1
        : 0;
      let vygresac = estimateData[2]["actual"]
        ? estimateData[2]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["Diff"] = (vygresac - vygreses).toFixed(2);
      estimateData[2]["perDiff"] = (
        ((vygresac - vygreses) / vygreses) *
        100
      ).toFixed(2);
      estimateData[2]["posted"] = "0.00";
      estimateData[2]["cash_in"] = "0.00";
      estimateData[2]["sec_variance"] = "0.00";
      estimateData[2]["sec_per"] = "0.00";

      let profitlses = estimateData[2]["children"][0]["estimate"]
        ? estimateData[2]["children"][0]["estimate"].replaceAll(",", "") * 1
        : 0;
      let profitlsac = estimateData[2]["children"][0]["actual"]
        ? estimateData[2]["children"][0]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][0]["Diff"] = (
        profitlsac - profitlses
      ).toFixed(2);
      estimateData[2]["children"][0]["perDiff"] =
        profitlses == 0
          ? "0.00"
          : (((profitlsac - profitlses) / profitlses) * 100).toFixed(2);
      estimateData[2]["children"][0]["posted"] = "0.00";
      estimateData[2]["children"][0]["cash_in"] = "0.00";
      estimateData[2]["children"][0]["sec_variance"] = "0.00";
      estimateData[2]["children"][0]["sec_per"] = "0.00";

      estimateData[2]["children"][1][colName] = dollarUSLocale.format(
        (itemValue / (totalVoyageDays - 0)).toFixed(2)
      );
      let dlprofitlses = estimateData[2]["children"][1]["estimate"]
        ? estimateData[2]["children"][1]["estimate"].replaceAll(",", "") * 1
        : 0;
      let dlprofitlsac = estimateData[2]["children"][1]["actual"]
        ? estimateData[2]["children"][1]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][1]["Diff"] = (
        dlprofitlsac - dlprofitlses
      ).toFixed(2);
      estimateData[2]["children"][1]["perDiff"] =
        dlprofitlses == 0
          ? "0.00"
          : (((dlprofitlsac - dlprofitlses) / dlprofitlses) * 100).toFixed(2);
      estimateData[2]["children"][1]["posted"] = "0.00";
      estimateData[2]["children"][1]["cash_in"] = "0.00";
      estimateData[2]["children"][1]["sec_variance"] = "0.00";
      estimateData[2]["children"][1]["sec_per"] = "0.00";

      //--------- added by vikas

      let co2adjustedcost = vygresac - co2cost;
      let cO2_adjusted_net_tce =
        totalVoyageDays !== 0 ? co2adjustedcost / totalVoyageDays : 0;

      estimateData[2]["children"][2][colName] = dollarUSLocale.format(
        (co2cost - 0).toFixed(2)
      );
      let co2costes = estimateData[2]["children"][2]["estimate"]
        ? estimateData[2]["children"][2]["estimate"].replaceAll(",", "") * 1
        : 0;
      let co2costac = estimateData[2]["children"][2]["actual"]
        ? estimateData[2]["children"][2]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][2]["Diff"] = (co2costac - co2costes).toFixed(
        2
      );
      estimateData[2]["children"][2]["perDiff"] =
        co2costes == 0
          ? "0.00"
          : (((co2costac - co2costes) / co2costes) * 100).toFixed(2);
      estimateData[2]["children"][2]["posted"] = "0.00";
      estimateData[2]["children"][2]["cash_in"] = "0.00";
      estimateData[2]["children"][2]["sec_variance"] = "0.00";
      estimateData[2]["children"][2]["sec_per"] = "0.00";

      estimateData[2]["children"][3][colName] = dollarUSLocale.format(
        (co2adjustedcost - 0).toFixed(2)
      );
      let co2adjProfites = estimateData[2]["children"][3]["estimate"]
        ? estimateData[2]["children"][3]["estimate"].replaceAll(",", "") * 1
        : 0;
      let co2adjProfitac = estimateData[2]["children"][3]["actual"]
        ? estimateData[2]["children"][3]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][3]["Diff"] = (
        co2adjProfitac - co2adjProfites
      ).toFixed(2);
      estimateData[2]["children"][3]["perDiff"] =
        co2adjProfites == 0
          ? "0.00"
          : (
              ((co2adjProfitac - co2adjProfites) / co2adjProfites) *
              100
            ).toFixed(2);
      estimateData[2]["children"][3]["posted"] = "0.00";
      estimateData[2]["children"][3]["cash_in"] = "0.00";
      estimateData[2]["children"][3]["sec_variance"] = "0.00";
      estimateData[2]["children"][3]["sec_per"] = "0.00";

      estimateData[2]["children"][4][colName] = dollarUSLocale.format(
        (cO2_adjusted_net_tce - 0).toFixed(2)
      );
      let co2adjNetes = estimateData[2]["children"][4]["estimate"]
        ? estimateData[2]["children"][4]["estimate"].replaceAll(",", "") * 1
        : 0;
      let co2adjNetac = estimateData[2]["children"][4]["actual"]
        ? estimateData[2]["children"][4]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][4]["Diff"] = (
        co2adjNetac - co2adjNetes
      ).toFixed(2);
      estimateData[2]["children"][4]["perDiff"] =
        co2adjNetes == 0
          ? "0.00"
          : (((co2adjNetac - co2adjNetes) / co2adjNetes) * 100).toFixed(2);
      estimateData[2]["children"][4]["posted"] = "0.00";
      estimateData[2]["children"][4]["cash_in"] = "0.00";
      estimateData[2]["children"][4]["sec_variance"] = "0.00";
      estimateData[2]["children"][4]["sec_per"] = "0.00";

      estimateData[2]["children"][5][colName] = dollarUSLocale.format(
        (tsd * 1).toFixed(2)
      );
      let tsdes = estimateData[2]["children"][5]["estimate"]
        ? estimateData[2]["children"][5]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tsdac = estimateData[2]["children"][5]["actual"]
        ? estimateData[2]["children"][5]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][5]["Diff"] = (tsdac - tsdes).toFixed(2);
      estimateData[2]["children"][5]["perDiff"] =
        tsdes == 0 ? "0.00" : (((tsdac - tsdes) / tsdes) * 100).toFixed(2);
      estimateData[2]["children"][5]["posted"] = "0.00";
      estimateData[2]["children"][5]["cash_in"] = "0.00";
      estimateData[2]["children"][5]["sec_variance"] = "0.00";
      estimateData[2]["children"][5]["sec_per"] = "0.00";

      estimateData[2]["children"][6][colName] = dollarUSLocale.format(
        (tpd * 1).toFixed(2)
      );
      let tpdes = estimateData[2]["children"][6]["estimate"]
        ? estimateData[2]["children"][6]["estimate"].replaceAll(",", "") * 1
        : 0;
      let tpdac = estimateData[2]["children"][6]["actual"]
        ? estimateData[2]["children"][6]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][6]["Diff"] = (tpdac - tpdes).toFixed(2);
      estimateData[2]["children"][6]["perDiff"] =
        tpdes == 0 ? "0.00" : (((tpdac - tpdes) / tpdes) * 100).toFixed(2);
      estimateData[2]["children"][6]["posted"] = "0.00";
      estimateData[2]["children"][6]["cash_in"] = "0.00";
      estimateData[2]["children"][6]["sec_variance"] = "0.00";
      estimateData[2]["children"][6]["sec_per"] = "0.00";

      estimateData[2]["children"][7][colName] = dollarUSLocale.format(
        (totalVoyageDays - 0).toFixed(2)
      ); // OFF Hire Day => 0

      let netvygdses = estimateData[2]["children"][7]["estimate"]
        ? estimateData[2]["children"][7]["estimate"].replaceAll(",", "") * 1
        : 0;
      let netvygdsac = estimateData[2]["children"][7]["actual"]
        ? estimateData[2]["children"][7]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["children"][7]["Diff"] = (
        netvygdsac - netvygdses
      ).toFixed(2);
      estimateData[2]["children"][7]["perDiff"] =
        netvygdses == 0
          ? "0.00"
          : (((netvygdsac - netvygdses) / netvygdses) * 100).toFixed(2);
      estimateData[2]["children"][7]["posted"] = "0.00";
      estimateData[2]["children"][7]["cash_in"] = "0.00";
      estimateData[2]["children"][7]["sec_variance"] = "0.00";
      estimateData[2]["children"][7]["sec_per"] = "0.00";
      let tci_off_Hire_Days =
        estimateDatavalue?.off_hire_days?.tci_offhire_invoice;
      let tco_off_Hire_Days =
        estimateDatavalue?.off_hire_days?.tco_offhire_invoice;
      let off_Hire_Days = Number(tci_off_Hire_Days) + Number(tco_off_Hire_Days);
      estimateData[2]["children"][8][colName] =
        dollarUSLocale.format(off_Hire_Days);

      estimateData[2]["children"][8]["Diff"] = "0.00";
      estimateData[2]["children"][8]["perDiff"] = "0.00";
      estimateData[2]["children"][8]["posted"] = "0.00";
      estimateData[2]["children"][8]["cash_in"] = "0.00";
      estimateData[2]["children"][8]["sec_variance"] = "0.00";
      estimateData[2]["children"][8]["sec_per"] = "0.00";
      estimateData[2]["children"][9][colName] = dollarUSLocale.format(
        totalVoyageDays - off_Hire_Days
      );

      estimateData[2]["children"][9]["Diff"] = "0.00";
      estimateData[2]["children"][9]["perDiff"] = "0.00";
      estimateData[2]["children"][9]["posted"] = "0.00";
      estimateData[2]["children"][9]["cash_in"] = "0.00";
      estimateData[2]["children"][9]["sec_variance"] = "0.00";
      estimateData[2]["children"][9]["sec_per"] = "0.00";

      estimateData[0]["children"][6]["children"] = [
        ...estimateDatavalue["other_revenue_invoices"],
      ];

      // expenses invoices
      estimateData[1]["children"][1]["children"] = [
        ...estimateDatavalue["port_expense_invoices"],
      ];

      estimateData[1]["children"][3]["children"] = [
        ...estimateData[1]["children"][3]["children"],
        ...estimateDatavalue["bunker_expense_invoices"],
      ];

      estimateData[1]["children"][4]["children"] = [
        ...estimateData[1]["children"][4]["children"],
        ...estimateDatavalue["other_expense_invoices"],
      ];

      revenue = {
        tco_net_revenue,
        tco_gross_revenue,
        revenuecalc,
        tco_gross_revenue,
        totalExpenses,
        amt_add_percentage,
        amt_bro_percentage,
        // add_per_tci_amt,
        //   bro_per_tci_amt,
        grossExpenses,
        totalVoyageDays,
        tceHire,
        vesselExpense,
        tciaddcomac,
        broPercentage,
        tci_bb_comm,
        // tco_total_port_expense,
        bunkerExpense,
        totalecaSeacons,
        totalnonEcaSeacons,
        totalnonecaPortcons,
        totalecaPortCons,
        nonEcaSeacons,
        totalnonEcaSeacons,
        ecaSeacons,
        totalecaSeacons,
        totalnonecaPortcons,
        nonecaPortcons,
        totalExpenses,
        netExpenses,
        hire,
        totalVoyageDays,
        pi,
        broPercentage,
        itemValue,
        co2cost,
        cO2_adjusted_net_tce,
        co2adjustedcost,

        // daily_profit_loss,
        // // cO2Cost,
        // cO2_adjusted_profit_loss,
        // cO2_adjusted_net_tce
      };

      return { estimateData, revenue };
    } catch (err) {
      console.log("err", err);
    }
  };

  handleClick = async () => {
    // Fetch the element by class name
    const element = document.querySelector(
      ".ant-table-row-expand-icon-collapsed"
    );

    if (element) {
      await element.click();
    }
  };

  savePNL = async () => {
    const _id = this.state?.formData?.id;
    this.setState({ ...this.state, saveLoading: true, showPL: false });
    const currentUser = localStorage.getItem("currentUser");
    const token = localStorage.getItem("oceanToken");

    const currentTime = moment(new Date()).format("YYYY-MM-DD,HH:mm:ss");
    //const dropdownUser=currentUser.split(" ").join("")+currentTime;
    const dropdownUser =
      "|" + currentTime + "|" + _id.toString() + "|" + currentUser;

    const savePayload = {
      //pnl_data:[{pnl_est_data: this.state.estimateData, pnl_frm_data: this.state.formData}],
      pnl_data: this.state.formData,
      remark: this.state.remark,
      user: dropdownUser,
      created_at: currentTime,
      voyage_no: _id,
    };

    const myurl = `${URL_WITH_VERSION}/voyage-manager/voyage-pnl/save`;
    try {
      const response = await fetch(myurl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(savePayload),
      });

      const data = await response.json();
      if (data.data) {
        await this.getSavedPNLList(true);
        openNotificationWithIcon("info", data.message);
      } else {
        openNotificationWithIcon("error", data.message);
      }
    } catch (err) {
      console.log("error in savePNL :", err);
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  componentDidMount() {
    // this.setState(
    //   {
    //     ...this.state,
    //     showPL: false,
    //     estimateData: this.__pl(),
    //   },
    //   () => this.setState({ ...this.state, showPL: true })
    // );
    const pl = this.__pl();
    this.setState(
      (prevState) => ({
        ...prevState,
        showPL: false,
        estimateData: pl.estimateData,
        tooltipData: pl.revenue,
      }),
      async () => {
        await this.getSavedPNLList();
        this.setState((prevState) => ({
          ...prevState,
          showPL: true,
        }));
      }
    );
  }

  getSavedPNLList = async (calledAfterSave = false) => {
    const _id = this.props.formData.id;
    try {
      let qParamString = objectToQueryStringFunc({ ae: _id });
      let url = `${URL_WITH_VERSION}/voyage-manager/voyage-pnl/drop-list?l=0&p=1&${qParamString}`;
      const response = await getAPICall(url);
      if (response.data.length > 0) {
        const selectOptionsList = [
          {
            value: "actual",
            label: "Actual",
          },
          ...response.data.map((item) => ({
            label: item.pnl_label,
            value: item.id,
          })),
        ];

        // this.setState({...this.state,selectOptions:selectOptionsList})

        if (calledAfterSave) {
          this.setState(
            {
              ...this.state,
              showPL: false,
              saveLoading: false,
              selectOptions: selectOptionsList,
            },
            () => this.setState({ ...this.state, showPL: true })
          );
        } else {
          this.setState({ ...this.state, selectOptions: selectOptionsList });
        }
      }
    } catch (err) {
      console.log("error in dropdownlist api :", err);
    }
  };

  handleSelectChange = async (value) => {
    this.setState({ ...this.state, showPL: false });
    let changedFormData = {};
    let disableBtn = true;
    let updatedRemark = "";
    if (value === "actual") {
      changedFormData = this.props.formData;
      disableBtn = false;
    } else {
      const _id = this.props.formData.id;
      try {
        let qParamString = objectToQueryStringFunc({ ae: value });
        let url = `${URL_WITH_VERSION}/voyage-manager/pnl/edit?${qParamString}`;
        const response = await getAPICall(url);

        if (response.data.hasOwnProperty("pnl_data")) {
          changedFormData = response.data.pnl_data;
        }
        if (response.data.hasOwnProperty("remark")) {
          updatedRemark = response.data.remark;
        }
      } catch (err) {
        openNotificationWithIcon("error", "Something went wrong", 3);
      }
    }

    this.setState(
      {
        ...this.state,
        formData: changedFormData,
        actualSelection: disableBtn,
        selectedOption: value,
        remark: updatedRemark,
        //showPL: false,
      },
      () => {
        this.setState(
          {
            ...this.state,
            estimateData: this.__pl().estimateData,
          },
          () => {
            this.setState({ ...this.state, showPL: true });
          }
        );
      }
    );
  };

  remarkHandler = (e) => {
    const remarkValue = e.target.value;
    this.setState({ ...this.state, remark: remarkValue });
  };
  showcalculation = (title) => {
    if (title) {
      return (
        <>
          {Array.isArray(TCEstimateCalcArr) &&
            TCEstimateCalcArr.length > 0 &&
            TCEstimateCalcArr.map((el) => {
              if (
                el?.title?.toLowerCase().replace(/[^a-z0-9]+/g, "") ===
                title?.toLowerCase().replace(/[^a-z0-9]+/g, "")
              ) {
                return (
                  <TctoVmPnl
                    tooltipData={this.state.tooltipData}
                    desc={el.formula}
                    title={el.title}
                    formData={this.state.formData}
                  />
                );
              }
            })}
        </>
      );
    }
  };

  TableBodyCell = (props) => {
    const { title } = props;

    let totaldescarr = [
      "Revenue",
      "Gross TCO Hire $",
      "Gross TCO Hire  B. Comm",
      "Gross TCO Hire Add Comm",
      "Gross TCO BB",
      "Gross TCO BB Comm",
      "Net Ballast Bonus",

      "Total TCO Net Hire",
      "Total Net TCO BB",
      "Gross Revenue",
      "Net Revenue",
      "Expenses",
      "Vessel expenses",
      "Hire Expenses",
      "TCI Add. Comm.",
      "TCI Broker. Comm.",
      // "TCI Ballst Bonus",
      "TCI BB Comm",
      "Port Expenses",
      "Emission Expense",
      "Other Expenses",
      "Bunker Expenses",
      "Gross Expenses",
      "Net Expenses",
      "Voyage Result",
      "Profit (Loss)",
      "Daily Profit (Loss)",
      "CO2 Cost",
      "CO2 Adjusted Profit (Loss)",
      "CO2 Adjusted (Net) TCE",
      "TCI Broker Comm.",
      "Sea Consumption Non ECA",
      "Sea Consumption ECA",
      "Port Consumption",
    ];

    return (
      <>
        {totaldescarr.includes(title) ? (
          <Popover
            content={() => this.showcalculation(title)}
            placement="right"
            destroyTooltipOnHide={true}
            fresh={true}
          >
            <td
              {...props}
              style={{
                ...props.style,
              }}
            />
          </Popover>
        ) : (
          <td
            {...props}
            style={{
              ...props.style,
            }}
          />
        )}
      </>
    );
  };

  render() {
    const {
      estimateData,
      showPL,
      viewTabs,
      saveLoading,
      actualSelection,
      selectedOption,
      selectOptions,
      remark,
    } = this.state;
    return (
      <>
        <Button
          type="primary"
          shape="round"
          icon={<SaveOutlined />}
          loading={saveLoading}
          size="middle"
          onClick={this.savePNL}
          disabled={actualSelection}
        >
          Save PNL
        </Button>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            float: "right",
            marginRight: "40%",
          }}
        >
          <Select
            defaultValue="Actual"
            value={selectedOption}
            style={{
              width: 270,
            }}
            placement="Select saved PNL"
            loading={!showPL}
            disabled={!showPL}
            onChange={this.handleSelectChange}
            options={showPL ? selectOptions : []}
          />
        </div>
        <div>
          <ToolbarUI routeUrl={"pl-main"} callback={(e) => this.callback(e)} />
        </div>

        {showPL === true ? (
          <div ref={this.elementRef}>
            <Tabs defaultActiveKey="1">
              {viewTabs.map((e) => {
                if (e === "Actual &  Operation View") {
                  return (
                    <TabPane tab={e} key="ao2">
                      <Table
                        className="pl-summary-list-view"
                        bordered
                        columns={columns}
                        dataSource={estimateData}
                        components={{
                          body: {
                            cell: this.TableBodyCell,
                          },
                        }}
                        pagination={false}
                        rowClassName={(r, i) =>
                          i % 2 === 0
                            ? r.is_invoiced
                              ? "table-striped-listing colorthepl"
                              : "table-striped-listing"
                            : r.is_invoiced
                            ? "table-striped-listing colorthepl"
                            : "dull-color table-striped-listing"
                        }
                      />
                      <Row gutter={16} className="m-t-18">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <FormItem
                            label="Remark"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: "10px 0 0 9px" }}
                          >
                            <div
                              style={{ border: "1px solid blue" }}
                              className="vk"
                            >
                              <TextArea
                                placeholder="Remark"
                                value={remark}
                                onChange={this.remarkHandler}
                                autoSize={{ minRows: 6, maxRows: 6 }}
                              />
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                    </TabPane>
                  );
                } else if (e === "Estimate View") {
                  return (
                    <TabPane tab={e} key="ev1">
                      <Table
                        className="pl-summary-list-view"
                        bordered
                        columns={columns2}
                        dataSource={estimateData}
                        pagination={false}
                        rowClassName={(r, i) =>
                          i % 2 === 0
                            ? "table-striped-listing"
                            : "dull-color table-striped-listing"
                        }
                      />
                      <Row gutter={16} className="m-t-18">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <FormItem
                            label="Remark"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: "10px 0 0 9px" }}
                          >
                            <div
                              style={{ border: "1px solid blue" }}
                              className="vk"
                            >
                              <TextArea
                                placeholder="Remark"
                                autoSize={{ minRows: 6, maxRows: 6 }}
                              />
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                    </TabPane>
                  );
                } else if (e === "Account View") {
                  return (
                    <TabPane tab={e} key="av3">
                      Accounts
                    </TabPane>
                  );
                }
              })}
            </Tabs>
          </div>
        ) : (
          <div className="col col-lg-12">
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          </div>
        )}
      </>
    );
  }
}

export default PL;
