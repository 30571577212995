import React, { Component } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, Tooltip } from "antd";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import "../print-report.scss";
import moment from "moment";
import html2canvas from "html2canvas";
import Email from "../../../../components/Email";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceData: this.props.invoiceData || {},
      data: {},
      max: 0,
    };
  }

  componentDidMount() {
    const { invoiceData } = this.props;

    if (invoiceData) {
      let orderedData = [];

      for (let item of invoiceData.invoice) {
        if (item?.title === "GROSS HIRE") {
          orderedData[0] = item;
        } else if (item?.title === "Vessel Offhire Delay") {
          orderedData[1] = item;
        } else if (item?.title === "Voyage Offhire Delay") {
          orderedData[2] = item;
        } else if (item?.title === "HIRE COMMISSION") {
          orderedData[3] = item;
        } else {
          orderedData.push(item);
        }
      }

      // Filter out undefined entries in orderedData (if any)
      orderedData = orderedData.filter((item) => item !== undefined);

      this.setState({ ...this.setState, data: orderedData });

      let newAmount = 0;
      let netAmt = 0;
      let netTot = 0;

      for (let elm of invoiceData.invoice) {
        if (elm.title === "GROSS HIRE") {
          newAmount = elm.net_amount;
        }
        if (elm.title === "Vessel Offhire Delay") {
          netAmt = parseFloat(elm.net_amount);
        }
        if (elm.title === "Voyage Offhire Delay") {
          netTot = parseFloat(elm.net_amount);
        }
      }

      const amtNet = netAmt + netTot;
      this.setState({ max: Number(newAmount) + Number(amtNet) });
    }
  }

  render() {
    const { invoiceData, data, max } = this.state;
    // console.log("data", data);
    return (
      <article className="article">
        <div className="box box-default print-wrapper" id="divToPrint">
          <div className="box-body">
            <div className="invoice-wrapper">
              <section className="invoice-container hire" id="invoice">
                <div className="invoice-inner">
                  <div className="invoice-inner-download mt-3">
                    <div className="row">
                      <div className="col-12 text-center">
                        {invoiceData && invoiceData["my_company_logo"] ? (
                          <img
                            className="reportlogo"
                            src={invoiceData["my_company_logo"]}
                            crossOrigin="anonymous"
                            alt="no Img"
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="text-center invoice-top-address">
                        <p>
                          {invoiceData && invoiceData["my_company_name"]
                            ? invoiceData["my_company_name"]
                            : ""}
                        </p>
                        <p>
                          {" "}
                          {invoiceData && invoiceData["my_company_address"]
                            ? invoiceData["my_company_address"]
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row view-list">
                    <div className="col-12">
                      <div className="sub-heading text-right">
                        Hire Statement
                      </div>
                    </div>

                    <div className="col-12">
                      <div
                        className="wrap-data border-line"
                        // style={{ flexWrap: "wrap" }}
                      >
                        <div className="col-4 text-left">
                          <ul className="list-unstyled">
                            <li className="laytime-list">
                              <span>Vessel:</span>{" "}
                              <span>{invoiceData["vessel_name"]}</span>
                            </li>
                            <li className="laytime-list ">
                              <span>Delivery:</span>
                              <span>
                                {invoiceData["delivery_date"] === ""
                                  ? ""
                                  : invoiceData["delivery_date"]}
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4 text-left">
                          <ul className="list-unstyled">
                            <li className="laytime-list">
                              <span>CP Date:</span>
                              <span>
                                {!invoiceData["cp_date"]
                                  ? ""
                                  : invoiceData["cp_date"]}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span>Redelivery:</span>
                              <span>
                                {invoiceData["redelivery_date"] === ""
                                  ? ""
                                  : invoiceData["redelivery_date"]}
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-4 text-left">
                          <ul className="list-unstyled">
                            <li className="laytime-list">
                              <span>Charterered:</span>
                              <span>
                                {!invoiceData["counterparty_name"]
                                  ? ""
                                  : invoiceData["counterparty_name"]}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span>Printed On:</span>
                              <span>
                                {" "}
                                {invoiceData["printed"]
                                  ? moment(invoiceData["printed"]).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : " "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divider my-2" />

                  <div className="row laytime-heading">
                    {data && data.length > 0
                      ? data.map((e) => {
                          const hasVesselOffhireDelay = data.some(
                            (item) => item.title === "Vessel Offhire Delay"
                          );
                          const hasVoyageOffhireDelay = data.some(
                            (item) => item.title === "Voyage Offhire Delay"
                          );
                          return (
                            <>
                              <div className="col-md-12">
                                <div className="table-responsive custom-table">
                                  <table className="table th-withoutborder">
                                    <thead>
                                      <tr>
                                        <div
                                          scope="col"
                                          rowSpan="3"
                                          style={{
                                            paddingLeft: "0px",
                                          }}
                                        >
                                          {e.title}
                                        </div>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {e["description"].map((ed) => {
                                        return (
                                          <>
                                            <tr className="content">
                                              <th
                                                scope="row"
                                                className="no-padding"
                                              >
                                                {ed.details}
                                              </th>
                                              {
                                                <td className="text-right no-padding">
                                                  &nbsp;
                                                </td>
                                              }

                                              {
                                                <td className="text-right no-padding">
                                                  {ed["amount"] > 0
                                                    ? ed["amount"]
                                                    : ed["amount"] * -1}
                                                </td>
                                              }
                                            </tr>
                                          </>
                                        );
                                      })}
                                      <tr>
                                        <th scope="row">Net {e.title}</th>
                                        <td />
                                        <td
                                          className="text-right"
                                          style={{
                                            borderTop: "2px solid #000",
                                          }}
                                        >
                                          {e["net_amount"] < 0
                                            ? "(" + e["net_amount"] * -1 + ")"
                                            : e["net_amount"]}
                                        </td>
                                      </tr>

                                      {(e.title === "Vessel Offhire Delay" ||
                                        e.title === "Voyage Offhire Delay") && (
                                        <tr
                                          style={{ marginTop: "-3rem" }}
                                          className="col-md-12"
                                        >
                                          <td>
                                            {""}
                                            <p>
                                              {""}
                                              Net Hire
                                            </p>
                                          </td>
                                          <td></td>
                                          <td
                                            style={{
                                              borderTop: "2px solid black",

                                              textAlign: "right",
                                            }}
                                          >
                                            {" "}
                                            {max.toFixed(2)}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="spacer" />
                            </>
                          );
                        })
                      : undefined}
                  </div>

                  <div className="divider my-2" />

                  <div className="row">
                    <div className="col-md-12 hire_stament_report_bank">
                      <div className="table-responsive custom-table">
                        <table className="table th-withoutborder">
                          <thead>
                            <tr style={{ fontSize: "1.1rem" }}>
                              <div
                                style={{
                                  fontWeight: "600",
                                  borderTop: "2px solid #000",
                                }}
                              >
                                Total Invoice Amount
                              </div>
                              <td className="text-right" />
                              <td
                                className="text-right"
                                style={{ borderTop: "2px solid #000" }}
                              >
                                {invoiceData["vatgst_total_amount"]}
                              </td>
                            </tr>
                          </thead>
                          {/* {invoiceData["bank_ajent"] ? (
                            <tbody>
                              <tr style={{ borderTop: "1px solid #000" }}>
                                <td colSpan="3">
                                  <p style={{ marginBottom: "0px" }}>
                                    <b>Benificiary Name:</b>{" "}
                                    {
                                      invoiceData["bank_ajent"][
                                        "benificiary_name"
                                      ]
                                    }
                                  </p>
                                  <p style={{ marginBottom: "0px" }}>
                                    <b>Bank Name:</b>{" "}
                                    {
                                      invoiceData["bank_ajent"][
                                        "benificiary_bank_name"
                                      ]
                                    }
                                  </p>
                                  <p style={{ marginBottom: "0px" }}>
                                    <b>Account No:</b>{" "}
                                    {invoiceData["bank_ajent"]["account_no"]}
                                  </p>
                                  <p style={{ marginBottom: "0px" }}>
                                    <b>Branch:</b>{" "}
                                    {invoiceData["bank_ajent"]["branch"]}
                                  </p>

                                  <p style={{ marginBottom: "0px" }}>
                                    <b>IBAN:</b>{" "}
                                    {invoiceData["bank_ajent"]["iban"]}
                                  </p>

                                  <p style={{ marginBottom: "0px" }}>
                                    <b>Swift Code:</b>{" "}
                                    {invoiceData["bank_ajent"]["swift_code"]}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          ) : undefined} */}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

class HireStatementReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Printer",
      invoiceData: this.props.invData || {},
    };
  }

  printReceipt() {
    window.print();
  }

  printDocument() {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 20;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 20,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("HireStatement.pdf");
    });
  }

  // printDocument() {
  //   htmlToImage
  //     .toPng(document.getElementById("divToPrint"), { quality: 0.95 })
  //     .then(function (dataUrl) {
  //       var link = document.createElement("a");
  //       link.download = "my-image-name.jpeg";
  //       const pdf = new jsPDF();

  //       const imgProps = pdf.getImageProperties(dataUrl);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = pdf.internal.pageSize.getHeight();
  //       pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
  //       pdf.save("HireStatement.pdf");
  //     });
  // }

  sendEmailReportModal = async () => {
    try {
      this.setState({ loading: true });

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      this.setState({
        loading: false,
        pdfData: blob,
        emailModal: true,
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { invoiceData } = this.state;
    return (
      <div className="body-wrapper modalWrapper modal-report-wrapper">
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                {/* <div className="leftsection">
                  <span key="first" className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li>
                        <Tooltip title="Back">
                          <Button
                            type="dashed"
                            shape="circle"
                            icon="double-left"
                            size={'default'}
                            onClick={() => this.props.changeState(true)}
                          />
                        </Tooltip>
                      </li>
                      <li>
                        <span className="text-bt">Hire Statement</span>
                      </li>
                    </ul>
                  </span>
                </div> */}
                <div className="rightsection">
                  <span key="first" className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li onClick={this.sendEmailReportModal}>Send Mail</li>
                      <li>
                        <span className="text-bt" onClick={this.printDocument}>
                          {" "}
                          Download
                        </span>
                      </li>
                      <li>
                        <ReactToPrint
                          documentTitle="TC-Hire Statement-Invoiced"
                          trigger={() => (
                            <span className="text-bt">
                              <PrinterOutlined />
                              Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body print-scroll">
              <ComponentToPrint
                invoiceData={invoiceData}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
          </div>
        </article>

        {this.state.emailModal && (
          <Modal
            title="New Message"
            visible={this.state.emailModal}
            onOk={() => {
              this.setState({ emailModal: false });
            }}
            onCancel={() => {
              this.setState({ emailModal: false });
            }}
            footer={null}
          >
            {this.state.pdfData && (
              <Email
                handleClose={() => {
                  this.setState({ emailModal: false });
                }}
                attachmentFile={this.state.pdfData}
                title={window.corrector(
                  `Hire_Statement_Report||${this.props.invData.vessel_name}||${this.props.invData.my_company_name}||${this.props.invData.invoice_no}`
                )}
              />
            )}
          </Modal>
        )}
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }
}

export default HireStatementReport;
