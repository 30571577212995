import React, { Component } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Spin,
  Alert,
  Radio,
  Select,
  Row,
  Col,
  Divider,
} from "antd";
import URL_WITH_VERSION, {
  getAPICall,
  awaitPostAPICall,
  openNotificationWithIcon,
} from "../../shared/index";
import { europeanCountryCodes } from "../../constants/countrycode";

const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const piracyoptions = [
  {
    value: "000",
    label: "Latest Piracy Area with JWLA027",
    key: "1",
  },
  {
    value: "001",
    label: "The shortest path",
    key: "2",
  },
  {
    value: "002",
    label: "250nm outer route from Somalia east coast",
    key: "3",
  },
  {
    value: "003",
    label: "600NM outer route from Somalia east coast",
    key: "4",
  },
  {
    value: "004",
    label: "JWLA015(2nd Aug 2010)",
    key: "5",
  },
  {
    value: "005",
    label: "JWLA016(16th Dec 2010)",
    key: "6",
  },
  {
    value: "006",
    label: "JWLA016 Up to Mumbai",
    key: "7",
  },
  {
    value: "007",
    label: "JWLA016 Max Avoid Route",
    key: "8",
  },
  {
    value: "008",
    label: "JWLA023",
    key: "9",
  },
  {
    value: "009",
    label: "JWLA027",
    key: "10",
  },
];

const canalPassoptions = [
  {
    value: "000",
    label: "Exclude all canals",
    key: "1",
  },
  {
    value: "001",
    label: "Exclude Suez and Panama",
    key: "2",
  },
  {
    value: "010",
    label: "Exclude Suez and Kiel",
    key: "3",
  },
  {
    value: "011",
    label: "Exclude Suez",
    key: "4",
  },
  {
    value: "100",
    label: "Exclude Panama and Kiel",
    key: "5",
  },
  {
    value: "101",
    label: "Exclude Panama",
    key: "6",
  },
  {
    value: "110",
    label: "Exclude Kiel ",
    key: "7",
  },
  {
    value: "111",
    label: "Not exclude canals",
    key: "8",
  },
];

class PortSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      distanceData: undefined,
      tableData: [],
      fromPortID: this.props.fromPortID || "",
      referenceElement: this.props.referenceElement || undefined,
      fromPortData: {},
      toPortName: "",
      toPort: undefined,
      loading: false,
      disableImportButton: true,
      columns: [
        { title: "# ID", dataIndex: "id" },
        { title: "Port ID", dataIndex: "PortID" },
        { title: "Port Name", dataIndex: "port_name" },
        { title: "Latitude", dataIndex: "latitude" },
        { title: "Longitude", dataIndex: "longitude" },
        { title: "Country", dataIndex: "country_name" },
        {
          title: "European Countries",
          dataIndex: "european_status",
          render: (el) => (el == 1 ? <span>True</span> : <span>False</span>),
        },
        {
          title: "Action",
          dataIndex: "action",
          render: (evt, row) => (
            <Button
              className="btn ant-btn-secondary btn-sm"
              onClick={() => {
                let re = this.props.referenceElement;
                let showFromToField = re
                  ? JSON.parse(re["f_label_display"])
                  : undefined;

                if (
                  showFromToField &&
                  showFromToField.hasOwnProperty("isDistance") &&
                  showFromToField["isDistance"] === false
                ) {
                  this.props.modalCloseEvent({ port: row, distance: {} });
                } else {
                  this.doAPICall(row);
                }
              }}
            >
              Select
            </Button>
          ),
        },
      ],
      index: 0,
      formdata: {
        localEca: 1,
        seca: 1,
        canalOptions: "000",
        piracyArea: "000",
      },
    };
  }

  componentDidMount = () => {
    this._loadTable();
  };

  _loadTable = async (filterVal) => {
    try {
      this.setState({
        loading: true,
      });
      const { fromPortID } = this.state;

      let _url = `${process.env.REACT_APP_URL}v1/port/list?t=port&p=1&l=0`;
      let header = {};
      if (filterVal) {
        header = {
          order_by: { port_name: "ASC" },
          where: {
            OR: {
              id: { eq: "'" + filterVal + "'" },
              PortID: { l: filterVal },
              port_name: { l: filterVal },
              latitude: { l: filterVal },
              longitude: { l: filterVal },
              country_name: { l: filterVal },
            },
          },
        };
      }

      const response = await getAPICall(_url, header);
      const respData = await response["data"];

      if (fromPortID) {
        // const response = await getAPICall(_url, { 'where': { 'AND': { 'port_name': fromPortID } } })
        // const portData=await response["data"];
        const portData = [{ port_name: fromPortID }];
        if (portData && portData.length === 1) {
          this.setState(
            { ...this.state, tableData: respData, fromPortData: portData[0] },
            () =>
              this.setState({ ...this.state, showTable: true, loading: false })
          );
        } else {
          this.setState({ ...this.state, tableData: respData }, () =>
            this.setState({ ...this.state, showTable: true, loading: false })
          );
        }
      } else {
        this.setState({ ...this.state, tableData: respData }, () =>
          this.setState({ ...this.state, showTable: true, loading: false })
        );
      }
    } catch (err) {
      console.log("err", err);
      this.setState({
        loading: false,
      });
    }
  };

  searchEvt = (evt) =>
    evt.target.value.length > 3 || evt.target.value.length === 0
      ? this._loadTable(evt.target.value)
      : "";

  doAPICall = async (row) => {
    try {
      const { fromPortID, fromPortData, formdata } = this.state;
      let fromPortName =
        fromPortData && fromPortData["port_name"]
          ? fromPortData["port_name"]
          : "";
      let toPortName = row && row.port_name ? row.port_name : "";
      this.setState({ ...this.state, toPortName: row.port_name });
      const request = await awaitPostAPICall(
        `${URL_WITH_VERSION}/port/distance`,
        { from: fromPortName, to: toPortName, ...formdata }
      );
      const response = await request;

      this.setState(
        {
          ...this.state,
          showTable: false,
          distanceData: response["data"],
          toPort: row,
        },
        () => this.setState({ disableImportButton: false })
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  canalOnChange = (e) => {
    this.setState({
      ...this.state,
      formdata: { ...this.state.formdata, canalOptions: e },
    });
  };

  piracyOnChange = (e) => {
    this.setState({
      ...this.state,
      formdata: { ...this.state.formdata, piracyArea: e },
    });
  };

  onChangelocalEcaRadio = (e) => {
    this.setState({
      ...this.state,
      formdata: { ...this.state.formdata, localEca: e.target.value },
    });
  };

  onChangeEcaRadio = (e) => {
    this.setState({
      ...this.state,
      formdata: { ...this.state.formdata, seca: e.target.value },
    });
  };

  render() {
    const {
      tableData,
      showTable,
      formdata,
      toPortName,
      columns,
      fromPortData,
      distanceData,
      toPort,
      referenceElement,
      loading,
      disableImportButton,
    } = this.state;
    let showFromToField = referenceElement
      ? JSON.parse(referenceElement["f_label_display"])
      : undefined;

    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <Row className="ant-form-item-label">
                <Col span={24}>
                  <Row className="row-padding">
                    <Col span={12}>
                      <Row className="row-padding">
                        <Col span={5}>
                          <label style={{ fontWeight: "bold" }}>
                            Canal Options:{" "}
                          </label>
                        </Col>
                        <Col span={17} className="ml-2">
                          <Select
                            // mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select Canal Options"
                            defaultValue="000"
                            onChange={this.canalOnChange}
                          >
                            {canalPassoptions.map((el) => {
                              return (
                                <Option key={el.key} value={el.value}>
                                  {el.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row className="row-padding">
                        <Col span={5}>
                          <label
                            style={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            Piracy Area{" "}
                            <span style={{ color: "#ff0000" }}>
                              <b />
                            </span>
                            :
                          </label>
                        </Col>
                        <Col span={17} className="ml-2">
                          <Select
                            // mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select piracy area"
                            defaultValue="000"
                            onChange={this.piracyOnChange}
                          >
                            {piracyoptions.map((el) => {
                              return (
                                <Option key={el.key} value={el.value}>
                                  {el.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="row-padding">
                    <Col span={14}>
                      <Row style={{ alignItems: "center" }}>
                        <Col style={{ fontWeight: "bold" }}>SECA:</Col>
                        <Col>
                          <Row
                            span={21}
                            style={{ textAlign: "left", marginLeft: "21px" }}
                          >
                            <Radio.Group
                              name="secaGroup"
                              onChange={this.onChangeEcaRadio}
                              value={formdata.seca}
                            >
                              <Radio value={1}>None</Radio>
                              <Radio value={2}>Normal</Radio>
                              <Radio value={3}>Shortest</Radio>
                              <Radio value={4}>Optimized</Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row style={{ alignItems: "center" }}>
                        <Col style={{ fontWeight: "bold" }}>Local ECA:</Col>
                        <Col span={17}>
                          <Row
                            style={{ textAlign: "left", marginLeft: "21px" }}
                          >
                            <Radio.Group
                              name="localecaGroup"
                              onChange={this.onChangelocalEcaRadio}
                              value={formdata.localEca}
                            >
                              <Radio value={1}>True</Radio>
                              <Radio value={0}>False</Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              <Row className="ant-form-item-label">
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <FormItem>
                        <Input
                          size="default"
                          defaultValue=""
                          placeholder="Search Port Or Country Name"
                          onChange={this.searchEvt}
                        />
                      </FormItem>
                    </Col>

                    {!showFromToField ||
                    (showFromToField &&
                      showFromToField.hasOwnProperty("isDistance") ===
                        false) ? (
                      <>
                        <Col span={8}>
                          <FormItem {...formItemLayout} label="Previous Port">
                            <Input
                              size="default"
                              defaultValue={
                                fromPortData &&
                                fromPortData.hasOwnProperty("port_name")
                                  ? fromPortData["port_name"]
                                  : this.state.fromPortID
                              }
                              value={
                                fromPortData &&
                                fromPortData.hasOwnProperty("port_name")
                                  ? fromPortData["port_name"]
                                  : this.state.fromPortID
                              }
                              readOnly
                            />
                          </FormItem>
                        </Col>

                        <Col span={8}>
                          <FormItem {...formItemLayout} label="To Port">
                            <Input
                              size="default"
                              defaultValue={toPortName}
                              value={toPortName}
                              readOnly
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : undefined}
                  </Row>
                </Col>
              </Row>

              {/* {
                showTable === true ? */}
              <Table
                className="mt-3"
                bordered
                columns={columns}
                dataSource={tableData}
                rowKey="id"
                pagination={false}
                scroll={{ x: "max-content" }}
                size="small"
                footer={null}
                loading={loading}
                rowClassName={(r, i) =>
                  i % 2 === 0
                    ? "table-striped-listing"
                    : "dull-color table-striped-listing"
                }
              />
              {/* : undefined
              } */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "96%",
                }}
              >
                {distanceData &&
                !distanceData.hasOwnProperty("total_length") &&
                distanceData["features"] ? (
                  distanceData["features"].map((e, i) => (
                    <div className="before" key={"distance-" + i}>
                      <p key={"length-" + i}>
                        <strong>Distance: </strong>{" "}
                        {(e["properties"]["total_length"] * 1).toFixed(2)}
                      </p>
                      <p key={"seca-" + i}>
                        <strong>SECA Length: </strong>{" "}
                        {(e["properties"]["seca_length"] * 1).toFixed(2)}
                      </p>
                      <p key={"crossed-" + i}>
                        <strong>Crossed: </strong>{" "}
                        {Array.isArray(e["properties"]["crossed"])
                          ? e["properties"]["crossed"].join(", ")
                          : ""}{" "}
                      </p>
                    </div>
                  ))
                ) : distanceData &&
                  distanceData.hasOwnProperty("total_length") ? (
                  <div className="after" key={"distance-0"}>
                    <p key={"length-0"}>
                      <strong>Distance: </strong>{" "}
                      {(distanceData["total_length"] * 1).toFixed(2)}
                    </p>
                    <p key={"seca-0"}>
                      <strong>SECA Length: </strong> N/A
                    </p>
                    <p key={"crossed-0"}>
                      <strong>Crossed: </strong> N/A
                    </p>
                  </div>
                ) : undefined}
                {!showFromToField ||
                (showFromToField &&
                  showFromToField.hasOwnProperty("isDistance") === false) ? (
                  <Button
                    className="btn ant-btn-secondary mt-3"
                    disabled={disableImportButton}
                    onClick={() => {
                      if (typeof this.props.modalCloseEvent == "function") {
                        this.props.modalCloseEvent({
                          port: toPort,
                          distance: distanceData,
                        });
                      } else {
                        openNotificationWithIcon(
                          "info",
                          "Please select the port in each row from above.",
                          3
                        );
                      }
                    }}
                  >
                    Import
                  </Button>
                ) : undefined}
              </div>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default PortSelection;
