import { Card, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { getAPICall } from "../../shared";

const DashboardNotification = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllNotification();
  }, []);

  const colorArray = [
    "rgb(250, 90, 125)",
    "rgb(255, 148, 122)",
    "rgb(60, 216, 86)",
    "rgb(191, 131, 255)",
    "#1dad9b",
    "#ffdd00",
    "#2772db",
  ];
  const getAllNotification = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}contact/get-broad-notification`;
      const requestData = {
        url: url,
        isToken: true,
        payload: {},
        header: {},
        showMessage: false,
      };

      let response = await getAPICall(url);

      const responseData = response?.data;

      const responseData2 = responseData.map((item, index) => {
        return {
          ...item,
          color: colorArray[index % colorArray.length], // Cycle through the colors array
        };
      });
      setData(responseData2);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <h2 style={{ textAlign: 'start', marginTop: '-5px' }}>Latest Notification</h2> */}
      <div
        className="custom-timeline"
        style={{ maxHeight: "305px", overflowY: "auto" }}
      >
        <Timeline className="thick-timeline">
          {data?.map((ele, index) => (
            <Timeline.Item key={index} color={ele.color}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span
                  style={{
                    fontSize: "16px",
                    color: "black",
                    textAlign: "left",
                    display: "block",
                    wordWrap: "break-word",
                    fontWeight: "400",
                  }}
                >
                  {ele.content.msg}
                </span>
                <span style={{ marginRight: "12px", color: ele.color }}>
                  {new Date(ele.content.time)
                    .toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .replace(/\//g, "-")}
                </span>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </>
  );
};

export default DashboardNotification;
