import {
    ConfigProvider,
    Layout,
    Tooltip,
    DatePicker,
    Input,
    Dropdown,
    Menu,
    Drawer,
    Flex,
    Typography,
    message,
    Empty,
    Spin,
  } from "antd";
  import {
    chipStyles,
    EMAILS_PER_PAGE,
    events,
    formatNumber,
    formatTimestamp,
    returnccbcc,
    sendWebSocketMessage,
    timeHandler,
  } from "../mail-folder/constants";
  import { useCallback, useEffect, useState } from "react";
//   import "./mails.css";
  import { Icon } from "@iconify/react/dist/iconify.js";
  import Searchfilters from "./SearchFilter";
  import { useDispatch, useSelector } from "react-redux";
  import { openNotificationWithIcon, postAPICall } from "../../shared";
  import {
    composeNewMail,
    setAllEmails,
    setIsSearcihng,
    setResizeMail,
    setVesselName,
  } from "../../actions/emailActions";
  import Swal from "sweetalert2";
  import MailRightbar from "../mail-folder/mailRightbar/MailRightbar";
  import dayjs from "dayjs";
  import { v4 as uuidv4 } from "uuid";
  import { useLocation, useParams } from "react-router";
import CurrentMailDrawer from "./CurrentMailDrawer";
  
  const SpiltViewDrawer = ({
    page,
    setPage,
    handleSearch,
    searchData,
    mailType,
    mailData,
    setMailData,
    totalMailCount,
    paginationHandler,
  }) => {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [currentMail, setCurrentMail] = useState({});
    const [isFilterOpen, setIsFilterOpen] = useState(false);
  
    // GLOBAL STATES IMPORTS
    const allMails = useSelector((state) => state.email.allEmails);
    const sentMails = useSelector((state) => state.email.sentList);
    const draftMails = useSelector((state) => state.email.draftList);
  
    const binMails = useSelector((state) => state.email.binList);
    const archivedMails = useSelector((state) => state.email.archivedList);
    const importantMails = useSelector((state) => state.email.importantList);
    const spamMails = useSelector((state) => state.email.spamList);
    const filteredData = useSelector((state) => state.email.filterData);
    const labelMails = useSelector((state) => state.email.labelMailList);
  
    const isSearching = useSelector((state) => state.email.isSearching);
    const isFiltering = useSelector((state) => state.email.isFiltering);
    const HasMore = useSelector((state) => state.email.hasMore);
    const totalCount = useSelector((state) => state.email.allEmailCount);
    const mailContainer = useSelector((state) => state.email.mailContainer);
    const composeMails = useSelector((state) => state.email.composeMails);
    const importedMailList = useSelector((state) => state.email.importedMailList);
    const [isResizing, setIsResizing] = useState(false);
    const [width, setWidth] = useState("30%");
    // const [mailData, setMailData] = useState([]);
    const [query, setQuery] = useState("");
    const [filterSpin, setFilterSpin] = useState(false);
  
    const { label_name, folder_name, imported_mail_identifier } = useParams();
  
    const { Content, Sider } = Layout;
  
    const handleCollapse = () => {
      setCollapsed(!collapsed);
    };
  
    useEffect(() => {
      setCurrentMail({});
    }, [location]);
  
    useEffect(() => {
      dispatch(setResizeMail(null));
    }, [location.pathname]);
  
    const handleMarkMailIsRead = async (payload) => {
      const handleAcknowledgment = (response) => {
        if (response?.content?.AcknowledgmentID) {
          // message.success("Email Marked as Read.");
          const userData = localStorage.getItem("oceanToken")
            ? JSON.parse(
                atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
              )
            : null
          const updatedMails = mailData.map((mail) => {
            if (mail._id === response?.content?.AcknowledgmentID) {
              return {
                ...mail,
                isRead: !mail.isRead, // Toggle the important value
                readBy: mail?.readBy
                  ? [...mail?.readBy, userData?.email]
                  : [userData?.email],
              };
            }
            return mail;
          });
          setMailData(updatedMails);
          // return response;
          return true;
        } else {
          // message.error("Failed to send mail");
          return false;
        }
      };
  
      await sendWebSocketMessage("markRead", payload, handleAcknowledgment);
    };
  
    const handleRenderMail = async (mail) => {
      if (mailType === "draft" && !mail.labelIds.includes("INBOX")) {
        const data = {
          composedData: {
            Date: mail.Date || "",
            to: mail.to || [],
            cc: mail.cc || [],
            bcc: mail.bcc || [],
            body: mail.body || "",
            subject: mail.subject || "",
            attachments: mail.attachments || [],
            //   editorStateKey: EditorState.createEmpty(),
            HTMLBody: undefined,
            owner_email: mail.owner_email || "",
            ThreadID: mail.ThreadID || "",
            GmailMessageId: mail.GmailMessageId || "",
            listOfFile: mail.listOfFile || [],
            totalSize: 0,
            uid: uuidv4(),
            _id: mail._id || null,
          },
          max: false,
          min: false,
        };
        const isPresent = composeMails?.some(
          (item) => item.composedData._id === mail._id
        );
        if (!isPresent) {
          dispatch(composeNewMail(data));
        }
      } else {
        setCurrentMail(mail);
        dispatch(setVesselName({ vesselName: "", isVisible: false }));
        if (!mail?.isRead) {
          const payload = {
            GmailMessageId: mail?.GmailMessageId,
            _id: mail?._id,
            context: mail?.client,
            isRead: true,
            event: "markRead",
          };
          handleMarkMailIsRead(payload);
        }
      }
    };
  
    const handleMouseMove = useCallback(
      (e) => {
        if (!isResizing) return;
    
        // Calculate the new width as a percentage value
        let newWidthPercent = (100 * e.clientX) / window.innerWidth;
        const maxWidthPercent = 55;
        const minWidthPercent = 25;
    
        // Enforce the maximum and minimum constraints
        if (newWidthPercent > maxWidthPercent) {
          newWidthPercent = maxWidthPercent;
        }
        if (newWidthPercent < minWidthPercent) {
          newWidthPercent = minWidthPercent;
        }
    
        // Convert the percentage to a string with '%' for CSS
        const newWidth = `${newWidthPercent}%`;
    
        // Update the state and dispatch the action with the integer width percentage
        setWidth(newWidth);
        dispatch(setResizeMail(Math.floor(newWidthPercent)));
      },
      [isResizing]
    );
    
  
    const handleMouseUp = useCallback(() => {
      if (!isResizing) return;
      setIsResizing(false);
    }, [isResizing]);
  
    useEffect(() => {
      if (isResizing) {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      } else {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
  
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [isResizing, handleMouseMove, handleMouseUp]);
  
    const markImportant = (userInpu1) => {
      // Destructure the userInput and event from the input object
      const { userInput, event } = userInpu1;
      event.stopPropagation();
  
      const { _id, important, client } = userInput;
  
      // Prepare the payload to be sent via WebSocket
      const payload = {
        context: client,
        _id,
        important: !important, // Toggle the important value
        event: "markImportant",
      };
  
      // Send the event to the WebSocket
      events.markImportant(payload);
  
      // Listening for WebSocket messages
      window.mySocketMail.onmessage = (event) => {
        events.handleAcknowledgment(event, (isSuccess) => {
          if (isSuccess) {
            openNotificationWithIcon("success", "Marked as Important!");
            const updatedMails = mailData.map((mail) => {
              if (mail._id === _id) {
                return {
                  ...mail,
                  important: !mail.important, // Toggle the important value
                };
              }
              return mail;
            });
  
            setMailData(updatedMails);
          } else {
            message.error("Something went wrong. Please try again!");
          }
        });
      };
    };
  
    // useEffect(() => {
    //   if (searchData.length > 0) {
    //     setMailData(searchData);
    //   } else if (mailType === "sent") {
    //     setMailData(sentMails);
    //   } else if (mailType === "draft") {
    //     setMailData(draftMails);
    //   } else if (mailType === "bin") {
    //     setMailData(binMails);
    //   } else if (mailType === "inbox" && imported_mail_identifier) {
    //     setMailData(importedMailList);
    //     return;
    //   } else if (mailType === "archived") {
    //     setMailData(archivedMails);
    //   } else if (mailType === "important") {
    //     setMailData(importantMails);
    //   } else if (mailType === "spam") {
    //     setMailData(spamMails);
    //   } else if (mailType === "labels") {
    //     setMailData(labelMails);
    //   } else if (isFiltering) {
    //     setMailData(filteredData);
    //   } else {
    //     setMailData(allMails);
    //   }
    // }, [
    //   searchData,
    //   allMails,
    //   mailType,
    //   filteredData,
    //   draftMails,
    //   importedMailList,
    //   draftMails,
    //   importedMailList,
    // ]);
  
    const handleDateSearch = async (range) => {
      try {
        setFilterSpin(true);
        const url = `${process.env.REACT_APP_MT_URL}mail/get-date-filter?pageSize=200&page=1`;
        const payload = {
          date_range: range,
          owner_email: "*",
        };
  
        await postAPICall(url, payload, "POST", (value) => {
          if (value?.data?.length > 0) {
            setMailData(value?.data);
            setFilterSpin(false);
          } else {
            openNotificationWithIcon(
              "info",
              "No data found for the choosen date."
            );
            setFilterSpin(false);
          }
        });
      } catch (err) {
        console.log(err);
        setFilterSpin(false);
      }
    };
  
    const handleDateFilters = (date) => {
      handleDateSearch(
        dayjs(date?.[0]).format("YYYY/MM/DD") +
          "-" +
          dayjs(date?.[1]).format("YYYY/MM/DD")
      );
    };
  
    const menu = (
      <Menu>
        <RangePicker
          onChange={(value) => handleDateFilters(value)}
          allowClear
          showTime
          showSecond={false}
        />
      </Menu>
    );
  
    const loadMoreData = () => {
      if (isSearching) {
        handleSearch(query);
        setPage(page + 1);
      } else {
        setPage(page + 1);
      }
    };
  
    const handleFilterSearch = (query) => {
      const trimmedQuery = query.replace(/^\s+/, "");
      setQuery(trimmedQuery);
      if (trimmedQuery.length > 0) {
        handleSearch(trimmedQuery);
        dispatch(setIsSearcihng(true));
      } else {
        openNotificationWithIcon("info", "Please Enter some text to search.");
        setMailData(allMails);
      }
    };
  
    const handleSearchingTyping = (query) => {
      const trimmedQuery = query.trim();
      if (trimmedQuery.length > 0) {
        dispatch(setIsSearcihng(true));
      } else {
        setMailData(allMails);
      }
    };
  
    // const handleDeleteMail = (GmailMessageId, _id, client) => {
    //   console.log({ GmailMessageId, _id, client });
    // const payLoad = {
    //   GmailMessageId,
    //   _id,
    //   context: client,
    //   trashed: true,
    //   parmanent: false,
    //   event: "deleteMail",
    // };
  
    //   const eventResponse = events.deleteMail(payLoad);
  
    //   if (eventResponse) {
    // setCurrentMail({});
    // const filteredMails = mailData?.filter((mail) => mail?._id !== _id);
    // setMailData(filteredMails);
    //   }
  
    //   // fetchEmailsAfterDelete(filteredMails, currentPage + 1)
    // };
  
    // const handleDeleteMail = async (GmailMessageId, _id, client) => {
    //   try {
    //     const payLoad = {
    //       GmailMessageId,
    //       _id,
    //       context: client,
    //       trashed: true,
    //       permanent: false,
    //       event: "deleteMail",
    //     };
  
    //     // Check if webSocketManager is available and WebSocket is open
    //     const webSocketManager = window?.webSocketManager;
    //     if (
    //       webSocketManager &&
    //       webSocketManager.socket.readyState === WebSocket.OPEN
    //     ) {
    //       console.log("DELETE SOCKET", { payLoad });
  
    //       // Create a promise to handle the delete response
    //       const responsePromise = new Promise((resolve, reject) => {
    //         webSocketManager.on("deleteMail", (response) => {
    //           if (response?.content?.AcknowledgmentID) {
    //             message.success("Mail successfully deleted");
    //             setCurrentMail({});
    //             const filteredMails = mailData?.filter(
    //               (mail) => mail?._id !== _id
    //             );
    //             setMailData(filteredMails);
    //           } else {
    //             message.error("Failed to delete mail");
    //           }
    //           resolve(response);
    //         });
    //       });
  
    //       // Send payload and await response
    //       const response = await webSocketManager.send(payLoad);
    //       console.log({response})
    //       await responsePromise(); // Ensure event handling is complete
    //     } else {
    //       message.error("WebSocket is not open or not initialized.");
    //       console.error("WebSocket is not open or not initialized.");
    //     }
    //   } catch (error) {
    //     console.error("WebSocket error:", error);
    //     message.error("Failed to delete mail due to WebSocket error");
    //   }
    // };
  
    const handleDeleteMail = async (GmailMessageId, _id, client) => {
      try {
        const payLoad = {
          GmailMessageId,
          _id,
          context: client,
          trashed: true,
          permanent: false,
          event: "deleteMail",
        };
  
        // Check if webSocketManager is available and WebSocket is open
        const webSocketManager = window?.webSocketManager;
        if (
          webSocketManager &&
          webSocketManager.socket.readyState === WebSocket.OPEN
        ) {
          // Create a unique responseId to track this request
          const responseId = Date.now();
  
          // Create a promise to handle the delete response
          const responsePromise = new Promise((resolve, reject) => {
            // Define a temporary event handler
            const handleResponse = (response) => {
              // if (response?.responseId === responseId) {
              if (response?.content?.AcknowledgmentID) {
                message.success("Mail successfully deleted");
                setCurrentMail({});
                const filteredMails = mailData?.filter(
                  (mail) => mail?._id !== _id
                );
                setMailData(filteredMails);
              } else {
                message.error("Failed to delete mail");
              }
              resolve(response);
              // Remove the event listener after handling the response
              webSocketManager.off("deleteMail", handleResponse);
              // }
            };
  
            // Register the temporary event handler
            webSocketManager.on("deleteMail", handleResponse);
          });
  
          // Send payload with the unique responseId
          await webSocketManager.send({ ...payLoad, responseId });
  
          // Await the response promise
          await responsePromise;
        } else {
          message.error("Please try again.");
          console.error("WebSocket is not open or not initialized.");
        }
      } catch (error) {
        console.error("WebSocket error:", error);
        message.error("Failed to delete mail due to WebSocket error");
      }
    };
  
    const handleDeleteConfirmation = (mailDetail) => {
      // console.log({mailDetail})
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteMail(
            mailDetail.GmailMessageId,
            mailDetail._id,
            mailDetail.client
          );
          // handleDeleteMail(mailDetail: {})
        }
      });
    };
  
    const handleMouseDown = useCallback(() => {
      setIsResizing(true);
    }, []);
    return (
      <div className="layout_container">
        <ConfigProvider
          theme={{
            components: {
              Layout: {
                headerBg: "#fff",
              },
            },
          }}
        >
          <Layout>
            {/* <Header>Header</Header> */}
  
            <Layout hasSider style={{ height: "100%" }}>
              <Sider
                theme="light"
                width={width}
                collapsed={collapsed}
                onCollapse={handleCollapse}
              >
                <div className="mail_list_container">
                  {/* //////////// MAIL LIST FILTERS //////////////// */}
                  <div className="mail_filter_row_box">
                    <div className="mail_filter_row">
                      <div
                        className="filter_icons"
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                      >
                        <Icon icon={"tabler:filter"} />
                      </div>
  
                      <Drawer
                        mask={false}
                        className="mail_filter_drawer"
                        title="Search Filters :"
                        onClose={() => {
                          // setMailData(allMails);
                          setIsFilterOpen(false);
                        }}
                        open={isFilterOpen}
                      >
                        <Searchfilters />
                      </Drawer>
  
                      <Input
                        type="text"
                        onPressEnter={(e) => handleFilterSearch(e.target.value)}
                        onChange={(e) => handleSearchingTyping(e.target.value)}
                        placeholder="Search for emails..."
                      />
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomLeft"
                        arrow
                      >
                        <div className="filter_icons">
                          <Icon icon={"tabler:calendar"} />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                  {/* <div className="mail_list_title">
                    <h5>Email List</h5>
                    </div> */}
                  <div
                    style={{
                      fontWeight: "700",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    {/* <span>{`1 to ${mailData?.length} of ${formatNumber(
                      totalCount || 0
                    )}`}</span> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px",
                      }}
                    >
                      <span>
                        {Math.min(
                          (page - 1) * EMAILS_PER_PAGE + 1,
                          totalMailCount
                        )}{" "}
                        to {Math.min(page * EMAILS_PER_PAGE, totalMailCount)} of{" "}
                        {formatNumber(totalMailCount || 0)}
                      </span>
                      <div>
                        <span
                          // className="mr-2"
                          style={{
                            // border: "1px solid gray",
                            // borderRadius: "10px",
                            // padding: "2px 5px",
                            cursor: "pointer",
                          }}
                          onClick={() => paginationHandler("prev")}
                        >
                          <Icon
                            style={{ color: "#12406a", fontSize: "24px" }}
                            className=""
                            icon={"ion:chevron-back-circle"}
                          />
                        </span>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => paginationHandler("next")}
                        >
                          <Icon
                            style={{ color: "#12406a", fontSize: "24px" }}
                            className=""
                            icon={"fluent:chevron-circle-right-48-filled"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    id="scrollableDiv"
                    className="mail_scrolls"
                    style={{
                      height: "85vh",
                      overflowY: "scroll",
                      // padding: "0 7px",
                      // marginTop: "20px",
                      border: "1px solid rgba(140, 140, 140, 0.35)",
                      position: "relative",
                    }}
                  >
                    {/* <InfiniteScroll
                      dataLength={mailData.length}
                      next={loadMoreData}
                      hasMore={HasMore}
                      loader={
                        <>
                          {[...Array(3).keys()].map((item) => (
                            <Skeleton
                              hasMore={HasMore}
                              avatar
                              paragraph={{
                                rows: 1,
                              }}
                              active
                            />
                          ))}
                        </>
                      }
                      // endMessage={
                      //   <Divider plain>It is all, nothing more 🤐</Divider>
                      // }
                      scrollableTarget="scrollableDiv"
                    > */}
                    {/* ///////////////// Email List //////////////// */}
                    {/* {console.log({ mailData })} */}
                    {mailData?.length > 0 ? (
                      mailData?.map((item, index) => {
                        const ccBccList = returnccbcc(item);
                        let modifiedMail = { ...item };
                        return (
                          <div
                            key={item["_id"] || item.id}
                            style={{
                              backgroundColor: item?.isRead
                              ? "#ecf2f54d"
                              : "hsl(204deg 91.42% 90.82% / 30%)",
                            }}
                            className={`mail_list_item ${
                              item === currentMail && "current_active"
                            } ${item?.isRead ? "already-read" : "not-read"}`}
                            onClick={() => handleRenderMail(item)}
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              style={{ marginBottom: "5px" }}
                            >
                              {item?.labelIds &&
                              item?.labelIds?.includes("DRAFT") ? (
                                <span
                                  className="mr-1"
                                  style={{ color: "#dd4b39" }}
                                >
                                  Draft
                                </span>
                              ) : (
                                ""
                              )}
                              <p className="sender_name">
                                {item?.mailClient === "outlook" ? (
                                  <>
                                    <>
                                      <span
                                        style={{
                                          display: "block",
                                          fontWeight: item?.isRead
                                            ? "600"
                                            : "bold",
                                          fontSize: "14px",
                                          // color: item?.isRead
                                          //   ? "inherit"
                                          //   : "black",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "150px",
                                          maxWidth: "150px",
                                          width: "100%",
                                        }}
                                      >
                                        {item?.from?.emailAddress?.name?.slice(
                                          0,
                                          20
                                        ) ?? ""}{" "}
                                      </span>
  
                                      {item?.Threads &&
                                      item?.Threads.length > 0 ? (
                                        <Tooltip
                                          title={`Thread count ${
                                            item?.Threads
                                              ? item?.Threads.length
                                              : ""
                                          }`}
                                        >
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              padding: "2px 6px",
                                              color: "green",
                                            }}
                                          >
                                            {item?.Threads
                                              ? item?.Threads.length
                                              : ""}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </>
  
                                    <Tooltip
                                      title={
                                        Array.isArray(item?.readBy)
                                          ? item.readBy.map((data, index) => (
                                              <div key={index}>{data}</div>
                                            ))
                                          : item?.readBy
                                          ? item.readBy
                                          : "unread email"
                                      }
                                    >
                                      <div style={{ marginLeft: "4px" }}>
                                        <Icon
                                          icon="mdi:email-outline"
                                          fontSize={14}
                                          style={{ color: "#1C5CE9" }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <div style={{ marginLeft: "4px" }}>
                                      {/* {ccBccList.length > 0 && */}
                                      <Tooltip
                                        // title={'mashu testing'}
                                        title={
                                          ccBccList.length > 0
                                            ? ccBccList?.map((item, i) => (
                                                <div>
                                                  <div>
                                                    {item?.name || item?.email}
                                                  </div>
                                                </div>
                                              ))
                                            : "N/A"
                                        }
                                      >
                                        <div>
                                          <Icon
                                            icon="ph:users"
                                            fontSize={14}
                                            style={{ color: "#00712D" }}
                                          />
                                        </div>
                                      </Tooltip>
                                      {/* } */}
                                    </div>
                                  </>
                                ) : item?.mailClient == "custom" ? (
                                  <div
                                    style={{
                                      display: "block",
                                      fontWeight: item?.isRead ? "600" : "700",
                                      fontSize: "14px",
                                      // color: item?.isRead ? "inherit" : "black",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      width: "100%",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: isSearching
                                        ? // item.Sender
                                          item?.Sender?.includes("<")
                                          ? item?.From[0]
                                              ?.split("<")[0]
                                              .replace(/[^a-zA-Z0-9 ]/g, "")
                                              .slice(0, 20)
                                          : item?.From[0]
                                              ?.replace(/[^a-zA-Z0-9 ]/g, "")
                                              .slice(0, 20)
                                        : modifiedMail?.From[0]?.split("<")[0] ||
                                          modifiedMail?.owner_email
                                            ?.replace(/[^a-zA-Z0-9 ]/g, "")
                                            .slice(0, 20),
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    style={{
                                      display: "block",
                                      fontWeight: item?.isRead ? "600" : "700",
                                      fontSize: "14px",
                                      // color: item?.isRead ? "inherit" : "black",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      width: "100%",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: isSearching
                                        ? // mail.Sender
                                          item?.Sender?.includes("<")
                                          ? item?.Sender?.split("<")[0]
                                              .replace(/[^a-zA-Z0-9 ]/g, "")
                                              .slice(0, 20)
                                          : item?.Sender?.replace(
                                              /[^a-zA-Z0-9 ]/g,
                                              ""
                                            ).slice(0, 20)
                                        : modifiedMail?.Sender?.split("<")[0] ||
                                          modifiedMail?.owner_email
                                            ?.replace(/[^a-zA-Z0-9 ]/g, "")
                                            .slice(0, 20),
                                    }}
                                  ></div>
                                )}
                              </p>
                              <span className="mail_list_time">
                                {timeHandler(
                                  item,
                                  item.Formatted_Date ||
                                    item.TIMESTAMP ||
                                    item.Date
                                )}
                              </span>
                            </Flex>
  
                            <p className="subject">
                              {item.Subject || item.subject ? (
                                <span
                                  style={{
                                    fontWeight: item?.isRead ? "600" : "bold",
                                  }}
                                >
                                  {item.Subject || item.subject}{" "}
                                </span>
                              ) : (
                                `(no subject)`
                              )}
                            </p>
  
                            <p className="snippet">{item.snippet}</p>
  
                            <div className="mail_list_label_box">
                              <div className="mail_list_labels mailType">
                                <Tooltip title="Mail Type">
                                {item?.Processing?<span
                                      className={`mail_list_badge ${item.MailType}`}
                                      style={{
                                        backgroundColor:"rgb(255, 227, 200)",
                                        color:"rgb(141, 75, 13)",
                                        borderColor:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                      }}
                                    >
                                      Analysing
                                    </span>:<span
                                    className={`mail_list_badge ${item.MailType}`}
                                    style={{
                                      backgroundColor:
                                        chipStyles[index % chipStyles.length]
                                          .backgroundColor,
                                      color:
                                        chipStyles[index % chipStyles.length]
                                          .color,
                                      borderColor:
                                        chipStyles[index % chipStyles.length]
                                          .color,
                                    }}
                                  >
                                    {item.MailType}
                                  </span>}
                                </Tooltip>
                              </div>
  
                              <div className="mail_list_labels vessels">
                                {item.vessel_name?.length > 0 && (
                                  // item.vessel_name?.map((vessel, index) => (
                                  <Tooltip title="Vessel Name">
                                    <span className="mail_list_badge">
                                      {item.vessel_name[0]}
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
  
                            <div className="mail_list_attach_icon">
                              <Icon
                                fontSize={14}
                                icon="mdi:attachment"
                                style={{ color: "white" }}
                              />
  
                              <Tooltip title="Click to Delete this Mail.">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteConfirmation({
                                      GmailMessageId: item?.GmailMessageId,
                                      _id: item?._id,
                                      client: item?.client,
                                    });
                                  }}
                                >
                                  <Icon
                                    fontSize={14}
                                    style={{ color: "red" }}
                                    icon="tabler:trash"
                                  />
                                </div>
                              </Tooltip>
  
                              {item.vessel_name?.length > 1 && (
                                <Tooltip
                                  title={
                                    "Attached Vessels : " +
                                    item.vessel_name.join(", ").toUpperCase()
                                  }
                                >
                                  <Icon
                                    fontSize={14}
                                    style={{ color: "green" }}
                                    icon="tabler:tag"
                                  />
                                </Tooltip>
                              )}
  
                              <Tooltip title="Click to mark as important or unimportant.">
                                <div
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    markImportant({ event, userInput: item });
                                  }}
                                >
                                  <Icon
                                    fontSize={14}
                                    icon={
                                      item?.important
                                        ? "tabler:star-filled"
                                        : "tabler:star"
                                    }
                                    style={{ color: "orange" }}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      //  [...Array(5).keys()].map((item) => (
                      //     <div style={{ padding: "14px 16px" }}>
                      //       <Skeleton
                      //         avatar
                      //         hasMore={HasMore}
                      //         paragraph={{
                      //           rows: 1,
                      //         }}
                      //         active
                      //       />
                      //     </div>
                      //   ))
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Empty />
                      </div>
                    )}
                    {/* </InfiniteScroll> */}
                  </div>
                </div>
              </Sider>
              <div className="resizing-grip" onMouseDown={handleMouseDown} />
              <Layout>
                <Content style={{ padding: "0 55px 0 24px", minHeight: 280 }}>
                  <CurrentMailDrawer
                    mail={currentMail}
                    setCurrentMail={setCurrentMail}
                    threads={false}
                    threadData={currentMail?.Threads}
                    allMails={mailData}
                    setMailData={setMailData}
                    handleDeleteMail={handleDeleteConfirmation}
                    totalMailCount={totalMailCount}
                    page={page}
                    paginationHandler={paginationHandler}
                  />
                </Content>
                {(currentMail["_id"] ||
                  currentMail["id"] ||
                  currentMail?.GmailMessageId) && (
                  <MailRightbar currentMail={currentMail} />
                )}
              </Layout>
            </Layout>
          </Layout>
        </ConfigProvider>
        <Spin fullscreen spinning={filterSpin} />
      </div>
    );
  };
  
  export default SpiltViewDrawer;
  