import { PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import URL_WITH_VERSION, { awaitPostAPICall, openNotificationWithIcon, postAPICall, postAPICallFormData } from "../../shared";
import { deleteAttachment, getAttachments, uploadAttachment } from "../../shared/attachments";
import { CustomSpinner } from "../../routes/port-to-port/portUtil";
import { method } from "lodash";

const VesselUploadImage = (props) => {
  const [fileList, setFileList] = useState([]);
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getImage();
  }, []);
  
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const getImage = () => {

    //const image =await getAttachments(props.formData.vessel_id, "VESSEL");
    let uniq_id = uuidv4()
    let images = [{
      name: "vessel image",
      status: 1,
      uid: uniq_id,
      url: props.formData.vessel_img }]
      if(!props.formData.hasOwnProperty('vessel_img') || props.formData?.vessel_img === ' ' || props.formData?.vessel_img === ''){
        images = []
      }
    setLoading(false)
    setImages(images)
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("user_file", file);
    setLoading(true)
    try {
      const response = await postAPICallFormData(
        `${process.env.REACT_APP_ATTACHMENT}/s3/upload`,
        formData
      );

      if (response) {
        //uploadImage(file,response.data);  // Pass the response or file data here
        props.getVesselImage(response.data);
        let newObj = {name: formData.filename, status: 1, uid:uuidv4(), url: response.data}
        setLoading(false)
        setImages([newObj, ...images])
        openNotificationWithIcon("success", "Uploaded successfully", 3);
      }
    } catch (err) {
      openNotificationWithIcon("error", "something went wrong.", 3);
    }
  };

  const uploadImage = async (fileData,urlData) => {
    const type="VESSEL"
    const bucket="VESSEL_FORM"
    const item={
        contentType:fileData.type,
        fileName:fileData.name,
        url:urlData

    }
    // Implement your logic using fileData here
    // const url = `${process.env.REACT_APP_ATTACHMENT}/importexport/save?id=${props.vessel_id}&type=${type}&content-type=${fileData?.type}&bucket=${bucket}`
    // const response=await awaitPostAPICall(url, item)
    // const respData = response.data
    // let newObj = {name: respData.attachment, status: respData.status, uid:uuidv4(), url: respData.share_link}
    // setLoading(false)
    // setImages([newObj, ...images])
  };

  const beforeUpload = (file) => {
    const isFileTooLarge = file.size > 5 * 1024 * 1024;

    if (isFileTooLarge) {
      message.error("File must be smaller than 5MB!");
      return false; // Reject the file
    }

    setTimeout(() => {
      handleUpload(file);
    }, 10);
    return false;
  };

  const handleDelete=async(file)=>{
    setLoading(true)
    //let res = await deleteAttachment(file.url,  file.name, 'file', 'vessel')
    let res = await postAPICall(`${URL_WITH_VERSION}/vessel/img-delete`,{'id':props.formData.id},"delete")

    if(res.data){
      openNotificationWithIcon("success", res.message);
      setImages([])
      props.getVesselImage("")
      
    }else{ 
      openNotificationWithIcon('error', res.message, 1)
    }
    setLoading(false)
  }
  
  return (
    <>
      <div className="clearfix" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        {
          loading ? <CustomSpinner/> : (<Upload
            listType="picture-card"
            // type="file"
            // accept=".jpg, .jpeg, .png, .gif,"
            fileList={images}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            onRemove={handleDelete}
          >
            {images.length >= 1 ? null : <PlusOutlined />}
          </Upload>)
        }
      </div>
    </>
  );
};

export default VesselUploadImage;
