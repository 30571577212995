import { EditOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React from "react";
const suggestionColsLoad = [
  {
    title: "Vessel Name",
    dataIndex: "vessel_name",
    key: "vessel_name",
    width: 150,
    align: "center",
  },
  {
    title: "DWT",
    dataIndex: "dwt",
    key: "dwt",
    width: 150,
    align: "center",
  },
  {
    title: "Estimated Arrival",
    dataIndex: "estimated_arrival",
    key: "estimated_arrival",
    width: 150,
    align: "center",
  },
  {
    title: "Current Port",
    dataIndex: "current_port",
    key: "current_port",
    width: 150,
    align: "center",
  },
  {
    title: "Area",
    dataIndex: "area",
    key: "area",
    width: 150,
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: 150,
    align: "center",
    render: (text, record) => {
      return (
        <div className="editable-row-operations">
          <Tooltip title="Edit">
            <span
              className="iconWrapper"
              // onClick={(e) => redirectToAdd(e, record)}
            >
              <EditOutlined />
            </span>
          </Tooltip>
        </div>
      );
    },
  },
];
const UpComingVesselSuggestion = ({ data }) => {
  return (
    <div style={{ display: "flex" }}>
      <Table
        title={() => <h3 style={{ margin: 0 }}>Up Coming Vessels</h3>} // Add the title here
        className="inlineTable resizeableTable"
        size="small"
        bordered
        columns={suggestionColsLoad}
        dataSource={data}
      />
    </div>
  );
};

export default UpComingVesselSuggestion;
