import React, { useEffect, useState } from "react";

export const TCEstimateCalcArr = [
  {
    title: "Revenue",
    formula:
      "Gross Revenue -(Gross TCO Hire add Com +Gross TCO Hire B. Comm +Gross TCO BB Comm);",
  },

  {
    title: "Gross TCO Hire $",
    formula: "Sum of (Daily Hire * Duration) in TCO Term",
  },

  {
    title: "Gross TCO Hire B. Comm",
    formula: "Sum of (Daily Hire * Duration*B.comm.%) in TCO Term",
  },

  {
    title: "Gross TCO Hire Add Comm",
    formula: "Sum of (Daily Hire * Duration*A.comm.%) in TCO Term",
  },

  {
    title: "Gross TCO BB",
    formula: "Sum of (Ballast Bonus) in TCO Term",
  },

  {
    title: "Gross TCO BB Comm",
    formula: "Sum of (Ballast Bonus*(A.comm% + B.Comm%)) in TCO Term",
  },

  {
    title: "Net Ballast Bonus",
    formula: "Gross TCO BB-Gross TCO BB Comm",
  },

  {
    title: "Total TCO Net Hire",
    formula: "Sum of (Daily Hire * Duration) in TCO Term",
  },

  {
    title: "Total Net TCO BB",
    formula: "Sum of (Ballast Bonus) in TCO Term",
  },

  {
    title: "Gross Revenue",
    formula: "Gross TCO Hire $ + Gross TCO BB + Misc. Revenue",
  },

  {
    title: "Net Revenue",
    formula:
      "Gross Revenue -(Gross TCO Hire add Com +Gross TCO Hire B. Comm +Gross TCO BB Comm);",
  },

  /// expenses---

  {
    title: "Expenses",
    formula: "Gross Expenses - (TCI Add. Comm. + TCI Bro. Comm.)",
  },

  {
    title: "Vessel expenses",
    formula: "TCI Hire Rate *Total Voyage Days",
  },

  {
    title: "Hire Expenses",
    formula: "TCI Hire Rate *Total Voyage Days",
  },

  {
    title: "TCI Add. Comm.",
    formula: "TCI Hire Expenses *Add Comm.% ",
  },

  {
    title: "TCI Broker. Comm.",
    formula: "TCI Hire Expenses *Broker% ",
  },

  {
    title: "TCI BB Comm",
    formula: "Ballast Bonus*Broker%",
  },

//   {
//     title: "TCI Ballst Bonus",
//     formula: "Ballast Bonus",
//   },

  {
    title: "Port Expenses",
    formula: "Sum of (P.EXP) in Port Itinerary",
  },

  {
    title: "Emission Expense",
    formula: "Sum of (Co2 Price Per MT * Total EU ETS (MT)) in Euets",
  },

  {
    title: "Other Expenses",
    formula: "Other Cost",
  },

  {
    title: "Bunker Expenses",
    formula:
      "Sea Consp Non ECA + Sea Consp ECA +Port Consp +TC Bunker Adjustment",
  },

  // Gross Expenses

  {
    title: "Gross Expenses",
    formula:
      "Vessel expenses +Port Expenses +Bunker Expenses +Other Expenses + Emission Expense",
  },

  {
    title: "Net Expenses",
    formula: "Gross Expenses-(TCI Add. Comm. + TCI Bro. Comm.)",
  },

  //

  {
    title: "Voyage Result",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Profit (Loss)",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue - Net Expenses)/Total Voyage Days ",
  },

  // Co2 Cost

  {
    title: "Co2 Cost",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "CO2 Adjusted Profit (Loss)",
    formula: "Profit (Loss)- Co2 Cost",
  },

  {
    title: "CO2 Adjusted (Net) TCE",
    formula: "CO2 Adjusted Profit (Loss)/ Total Voyage Days",
  },
  {
    title: "Sea Consumption Non ECA",
    formula: "Sum of(IFO + MGO + LSMGO + VLSFO + ULSFO)",
  },

  {
    title: "Sea Consumption ECA",
    formula: "Sum of(eca sea consp of all five fuels)",
  },
  {
    title: "Port Consumption",
    formula: "Sum of(eca port consp of all five fuels)",
  },
];

const TctoVmPnl = ({ desc, tooltipData, title, formData }) => {
  const [caluculation, setCalculation] = useState("");
  useEffect(() => {
    if (title === "Revenue") {
      //   console.log("revenuecalc", tooltipData?.revenuecalc);
      setCalculation(
        `${tooltipData?.tco_gross_revenue} - (${tooltipData?.revenuecalc.tco_hire_addcom} + ${tooltipData?.revenuecalc.tco_hire_bcom} + ${tooltipData?.revenuecalc.gross_tco_bb_com}) = ${tooltipData?.tco_net_revenue}`
      );
    } else if (title === "Gross TCO Hire $") {
      const calculationDetails = formData?.tcoterm?.map((ele) => {
        return `${ele.dailyhire} * ${ele.duration} = ${
          ele.dailyhire * ele.duration
        }`;
      });
      calculationDetails.push(
        `Total gross tco hire = ${tooltipData?.revenuecalc?.tco_hire}`
      );
      setCalculation(`${calculationDetails.join("\n")}`);
    } else if (title === "Gross TCO Hire B. Comm") {
      const calculationDetails = formData?.tcoterm?.map((ele) => {
        return `${ele.dailyhire} * ${ele.duration} * ${ele?.bcom}% = ${
          ele.dailyhire * ele.duration * ele.bcom * 0.01
        }`;
      });
      calculationDetails.push(
        `Total gross tco hire b.comm = ${tooltipData?.revenuecalc?.tco_hire_bcom}`
      );
      setCalculation(`${calculationDetails.join("\n")}`);
    } else if (title === "Gross TCO Hire Add Comm") {
      const calculationDetails = formData?.tcoterm?.map((ele) => {
        return `${ele.dailyhire} * ${ele.duration} * ${ele?.acom}% = ${
          ele.dailyhire * ele.duration * ele.acom * 0.01
        }`;
      });
      calculationDetails.push(
        `Total gross tco hire a.comm = ${tooltipData?.revenuecalc?.tco_hire_addcom}`
      );
      setCalculation(`${calculationDetails.join("\n")}`);
    } else if (title === "Gross TCO BB") {
      const calculationDetails = formData?.tcoterm.map((ele) => {
        return `${ele?.bb}`;
      });
      setCalculation(
        `${calculationDetails.join(" + ")} = ${
          tooltipData?.revenuecalc?.tco_bb
        }`
      );
    } else if (title === "Gross TCO BB Comm") {
      const calculationDetails = formData?.tcoterm?.map((ele) => {
        return `(${ele.bb} * ${ele.acom}%) + (${ele.bb} + ${ele.bcom}%) = ${
          (ele.bb * ele.acom + ele.bb * ele.bcom) * 0.01
        }`;
      });
      calculationDetails.push(
        `Gross tco bb comm = ${tooltipData?.revenuecalc?.gross_tco_bb_com}`
      );
      setCalculation(calculationDetails.join("\n"));
    } else if (title === "Net Ballast Bonus") {
      setCalculation(
        `${tooltipData?.revenuecalc?.tco_bb} - ${tooltipData?.revenuecalc?.gross_tco_bb_com} = ${tooltipData?.revenuecalc?.tco_net_Blast_Revenue}`
      );
      // Calculate Net Ballast Bonus
      // Formula: "Gross TCO BB - Gross TCO BB Comm"
    } else if (title === "Total TCO Net Hire") {
      const calculationDetails = formData?.tcoterm?.map((ele) => {
        return `${ele.dailyhire} * ${ele.duration} = ${
          ele.dailyhire * ele.duration
        }`;
      });
      calculationDetails.push(
        `Total tco net hire = ${tooltipData?.revenuecalc?.tco_hire}`
      );
      setCalculation(`${calculationDetails.join("\n")}`);
    } else if (title === "Total Net TCO BB") {
      const calculationDetails = formData?.tcoterm.map((ele) => {
        return `${ele?.bb}`;
      });
      setCalculation(
        `${calculationDetails.join(" + ")} = ${
          tooltipData?.revenuecalc?.tco_bb
        }`
      );
    } else if (title === "Gross Revenue") {
      setCalculation(
        `${tooltipData?.revenuecalc?.tco_hire} + ${
          tooltipData?.revenuecalc?.tco_bb
        } + ${0} = ${tooltipData?.tco_gross_revenue}`
      );
    } else if (title === "Net Revenue") {
      setCalculation(
        `${tooltipData?.tco_gross_revenue} - (${tooltipData?.revenuecalc?.tco_hire_addcom} + ${tooltipData?.revenuecalc?.tco_hire_bcom} + ${tooltipData?.revenuecalc?.gross_tco_bb_com}) = ${tooltipData?.tco_net_revenue} `
      );
    } else if (title === "Expenses") {
      setCalculation(
        `${tooltipData?.grossExpenses?.toFixed(2)} - (${
          tooltipData?.amt_add_percentage
        } + ${
          tooltipData?.amt_bro_percentage
        }) = ${tooltipData?.netExpenses?.toFixed(2)}`
      );
    } else if (title === "Vessel expenses") {
      setCalculation(
        `${tooltipData?.hire} * ${tooltipData?.totalVoyageDays?.toFixed(
          2
        )} = ${tooltipData?.vesselExpense?.toFixed(2)}`
      );
    } else if (title === "Hire Expenses") {
      setCalculation(
        `${tooltipData?.hire?.toFixed(2)} * ${tooltipData?.totalVoyageDays?.toFixed(2)} = ${tooltipData?.vesselExpense?.toFixed(2)}`
      );
    } else if (title === "TCI Add. Comm.") {
      setCalculation(
        `${tooltipData?.vesselExpense?.toFixed(2)} * ${
          tooltipData?.tciaddcomac
        }% = ${tooltipData?.amt_add_percentage}`
      );
    } else if (title === "TCI Broker. Comm.") {
      setCalculation(
        `${tooltipData?.vesselExpense?.toFixed(2)} * ${tooltipData?.broPercentage?.toFixed(2)}% = ${tooltipData?.amt_bro_percentage?.toFixed(2)}`
      );
    } else if (title === "TCI BB Comm") {
      setCalculation(
        `${formData?.bb} * ${tooltipData?.broPercentage}% = ${tooltipData?.tci_bb_comm}`
      );
    } else if (title === "Port Expenses") {
      const calculationDetails = formData?.portitinerary?.map((ele) => {
        return `${ele.p_exp}`;
      });

      setCalculation(`${calculationDetails.join(" + ")} = ${tooltipData?.pi}`);
    } else if (title === "Emission Expense") {
      let co2Price = parseFloat(formData["-----"]?.co2_price_per);
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      const calculationDetails = formData?.euets?.map((ele) => {
        return `${ele.ttl_eu_ets}`;
      });
      setCalculation(
        `(${calculationDetails.join(" + ")}) * ${co2Price} = ${total}`
      );
    } else if (title === "Other Expenses") {
      setCalculation(`${formData?.other_cost}`);
    } else if (title === "Bunker Expenses") {
      setCalculation(
        `${tooltipData?.totalecaSeacons} + ${tooltipData?.totalnonEcaSeacons} + ${tooltipData?.totalnonecaPortcons} + ${tooltipData?.totalecaPortCons} = ${tooltipData?.bunkerExpense}`
      );
    } else if (title == "Sea Consp Non ECA") {
      //   console.log("data56", tooltipData?.nonEcaSeacons);
      const data = tooltipData?.nonEcaSeacons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonEcaSeacons}`
      );
    } else if (title === "IFO") {
      const data = formData?.["."]?.filter((ele) => ele.fuel_code === "IFO");
      const cpPrice = data?.[0]?.cp_price || 0; // Assume cp_price is in the first object or default to 0

      // Sum up all 'ifo' values from bunkerdetails
      const totalIfo = formData?.bunkerdetails?.reduce((acc, item) => {
        return acc + parseFloat(item.ifo || "0"); // Parse each ifo value as a float and add it to accumulator
      }, 0);

      // Calculate the final result
      const calculation = totalIfo * cpPrice;
      setCalculation(`${totalIfo} * ${cpPrice} = ${calculation}`);
    } else if (title == "Sea Consp ECA") {
      const data = tooltipData?.ecaSeacons;
      setCalculation(
        `${data?.ifo} + ${data.mgo} + ${data.vlsfo} + ${data.ulsfo} + ${data.lsmgo} = ${tooltipData?.totalecaSeacons}`
      );
    } else if (title == "Port Consp") {
      const data = tooltipData?.nonecaPortcons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonecaPortcons}`
      );
    } else if (title === "Gross Expenses") {
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      setCalculation(
        `${tooltipData?.vesselExpense?.toFixed(2)} + ${tooltipData?.pi} + ${
          tooltipData?.bunkerExpense
        } + ${
          formData?.other_cost
        } + ${total} = ${tooltipData?.grossExpenses?.toFixed(2)}`
      );
    } else if (title === "Net Expenses") {
      setCalculation(
        `${tooltipData?.grossExpenses?.toFixed(2)} - (${
          tooltipData?.amt_add_percentage
        } + ${
          tooltipData?.amt_bro_percentage
        }) = ${tooltipData?.netExpenses?.toFixed(2)}`
      );
    } else if (title === "Voyage Result") {
      setCalculation(
        `${tooltipData?.tco_net_revenue} - ${tooltipData?.netExpenses?.toFixed(
          2
        )} = ${tooltipData?.itemValue?.toFixed(2)}`
      );
    } else if (title === "Profit (Loss)") {
      setCalculation(
        `${tooltipData?.tco_net_revenue} - ${tooltipData?.netExpenses?.toFixed(
          2
        )} = ${tooltipData?.itemValue?.toFixed(2)}`
      );
    } else if (title === "Daily Profit (Loss)") {
      setCalculation(
        `(${tooltipData?.tco_net_revenue} - ${tooltipData?.netExpenses?.toFixed(
          2
        )})/${tooltipData?.totalVoyageDays?.toFixed(2)} = ${(
          tooltipData?.itemValue / tooltipData?.totalVoyageDays
        ).toFixed(2)}`
      );
    } else if (title === "Co2 Cost") {
      let co2Price = parseFloat(formData["-----"]?.co2_price_per);
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      const calculationDetails = formData?.euets?.map((ele) => {
        return `${ele.ttl_eu_ets}`;
      });
      setCalculation(
        `(${calculationDetails.join(" + ")}) * ${co2Price} = ${total}`
      );
    } else if (title == "CO2 Adjusted Profit (Loss)") {
      let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      setCalculation(
        `${tooltipData?.itemValue?.toFixed(
          2
        )} - ${total} = ${tooltipData?.co2adjustedcost?.toFixed(2)}`
      );
    } else if (title == "CO2 Adjusted (Net) TCE") {
      setCalculation(
        `${tooltipData?.itemValue.toFixed(
          2
        )}/${tooltipData?.totalVoyageDays?.toFixed(
          2
        )} = ${tooltipData?.cO2_adjusted_net_tce.toFixed(2)}`
      );
    } else if (title == "Sea Consumption Non ECA") {
      //   console.log("data56", tooltipData?.nonEcaSeacons);
      const data = tooltipData?.nonEcaSeacons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonEcaSeacons}`
      );
    } else if (title == "Sea Consumption ECA") {
      const data = tooltipData?.ecaSeacons;
      setCalculation(
        `${data?.ifo} + ${data.mgo} + ${data.vlsfo} + ${data.ulsfo} + ${data.lsmgo} = ${tooltipData?.totalecaSeacons}`
      );
    } else if (title == "Port Consumption") {
      const data = tooltipData?.nonecaPortcons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonecaPortcons}`
      );
    }
  }, [title]);

  return (
    <div style={{ top: 100 }}>
      {/* <p>{desc}</p> */}
      <p>Actual Data</p>
      <pre>{caluculation}</pre>
    </div>
  );
};

export default TctoVmPnl;
