import { Modal, Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TCI from "../routes/chartering/routes/tci";
import TCO from "../routes/chartering/routes/tco";
import URL_WITH_VERSION, {
  getAPICall,
  openNotificationWithIcon,
} from "../shared";
import { TABLE_FIELDS } from "./EditableTable/fields";

const sortTheNumbers = (firstel, secondel) => {
  // firstel= firstel? firstel.toString():false;
  // secondel= secondel?secondel.toString():false;

  return firstel && secondel
    ? parseFloat(firstel.replace(/,/g, "")) -
        parseFloat(secondel.replace(/,/g, ""))
    : firstel && !secondel
    ? -1
    : secondel && !firstel
    ? 1
    : 0;
};

const sortTheDates = (firstel, secondel) => {
  return firstel && secondel
    ? moment(firstel).unix() - moment(secondel).unix()
    : null;
};

const onVmClick = (text) => {
  if (/^(TCOV|TCTO)/.test(text)) {
    window.open(`${window.location.origin}/#/voyage-manager/${text}`, "_blank");
  } else {
    return;
  }
};

const TableModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [tcData, setTcData] = useState({});

  useEffect(() => {
    if (visible && Object.keys(tcData).length === 0) {
      getData();
    }
  }, [visible]);

  const getData = async () => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/${props.type}/edit?${
          props.type === "tco" ? "a" : ""
        }e=${props.id}`
      );
      let resp = await response;
      if (resp.data) {
        setTcData(resp.data);
      } else {
        openNotificationWithIcon("error", resp.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {visible === true && Object.keys(tcData).length > 0 ? (
        <Modal
          title={props.type === "tco" ? "TCO" : "TCI"}
          open={true}
          onCancel={() => setVisible(false)}
          width="90%"
          style={{ height: "auto" }}
        >
          {props.type === "tco" ? (
            <TCO formData={tcData} />
          ) : (
            <TCI formData={tcData} />
          )}
        </Modal>
      ) : (
        <></>
      )}
      <span onClick={() => setVisible(true)}>
        <div>{props.text}</div>
      </span>
    </>
  );
};
export const FIELDS = {
  "report-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a?.vessel_name && b?.vessel_name
            ? a?.vessel_name?.localeCompare(b?.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        key: "vessel_code",
        width: 120,
        sorter: (a, b) =>
          a?.vessel_code && b?.vessel_code
            ? a?.vessel_code?.localeCompare(b?.vessel_code)
            : null,
        showSorterTooltip: false,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a?.vessel_type && b?.vessel_type
            ? a?.vessel_type?.localeCompare(b?.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a?.vessel_sub_type && b?.vessel_sub_type
            ? a?.vessel_sub_type?.localeCompare(b?.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Report Type",
        dataIndex: "report_type",
        key: "report_type",
        width: 120,
        render: (text) =>
          text === "VoyagePerformanceReport"
            ? "Voyage Performance Report"
            : "EmissionReport"
            ? "Emission Report"
            : "ConsolidateReport"
            ? "Consolidate Voyage Report"
            : text,
      },
      {
        title: "Passage Type",
        dataIndex: "passage_type",
        key: "passage_type",
        width: 130,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Voyage No.",
        dataIndex: "voy_no",
        key: "voy_no",
        width: 120,
        sorter: (a, b) =>
          a?.voyage_no && b?.voyage_no
            ? a?.voyage_no?.localeCompare(b?.voyage_no)
            : null,
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Completed Date",
        dataIndex: "completing_date",
        key: "completing_date",
        render: (text) => (text === "" ? "-" : text),
      },
    ],
  },

  "cargo-enquiry-templates-list": {
    tableheads: [
      {
        title: "Account For",
        dataIndex: "account_for",
        key: "account_for",
        width: 150,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo Details",
        dataIndex: "cargo_details",
        key: "cargo_details",
        width: 120,
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Cargo Name",
        dataIndex: "cargoname",
        key: "cargoname",
        width: 120,
      },
      {
        title: "CP QTY",
        dataIndex: "cp_qty",
        key: "cp_qty",
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Freight Rate",
        dataIndex: "freight_type_name",
        key: "freight_type_name",
        width: 130,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "L Port",
        dataIndex: "load_port_options",
        key: "load_port_options",
        width: 120,
        sorter: (a, b) =>
          a.voyage_no && b.voyage_no
            ? a.voyage_no.localeCompare(b.voyage_no)
            : null,
      },
      {
        title: "D Port",
        dataIndex: "discharge_port_options",
        key: "ddischarge_port_options",
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Demmurage",
        dataIndex: "dem",
        key: "dem",
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Despatch",
        dataIndex: "des",
        key: "des",
        render: (text) => (text === "" ? "-" : text),
      },
    ],
  },
  "tonnage-enquiry-templates-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessels_name",
        key: "vessels_name",
        width: 150,
        sorter: (a, b) => {
          // If both vessel names are present, compare them
          if (a?.vessels_name && b?.vessels_name) {
            return a?.vessels_name?.localeCompare(b?.vessels_name);
          }

          // Handle missing values: place rows with missing vessel_name at the end
          if (!a?.vessels_name) return 1;
          if (!b?.vessels_name) return -1;

          // Default return for equal values
          return 0;
        },
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "DWT",
        dataIndex: "dwt",
        key: "dwt",
        width: 120,
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "LOA",
        dataIndex: "loa",
        key: "loa",
        width: 120,
      },
      {
        title: "Speed",
        dataIndex: "speed",
        key: "speed",
        width: 130,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Consumption",
        dataIndex: "cons",
        key: "cons",
        width: 130,
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Open from",
        dataIndex: "open_from",
        key: "open_from",
        width: 120,
        sorter: (a, b) =>
          a.voyage_no && b.voyage_no
            ? a.voyage_no.localeCompare(b.voyage_no)
            : null,
      },
      {
        title: "Open to",
        dataIndex: "open_to",
        key: "open_to",
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Open Position",
        dataIndex: "open_position",
        key: "open_position",
        render: (text) => (text === "" ? "-" : text),
      },
      {
        title: "Bunker on Delivery",
        dataIndex: "bunker_on_delivery_name",
        key: "bunker_on_delivery_name",
        render: (text) => (text === "" ? "-" : text),
      },
    ],
  },

  "vessel-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        key: "vessel_code",
        width: 120,
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Vessel Type Code",
        dataIndex: "type_code",
        key: "type_code",
        width: 160,
        sorter: (a, b) =>
          a.type_code && b.type_code
            ? a.type_code.localeCompare(b.type_code)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Ownership",
        dataIndex: "owner_ship_name",
        key: "owner_ship_name",
        width: 150,
        sorter: (a, b) =>
          a.owner_ship_name && b.owner_ship_name
            ? a.owner_ship_name.localeCompare(b.owner_ship_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        width: 130,
        sorter: (a, b) =>
          a.vessel_type_name && b.vessel_type_name
            ? a.vessel_type_name.localeCompare(b.vessel_type_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Daily Cost",
        dataIndex: "daily_cost",
        width: 120,
        key: "daily_cost",
        sorter: (a, b) =>
          a.daily_cost && b.daily_cost
            ? Number(a.daily_cost) - Number(b.daily_cost)
            : null,
        showSorterTooltip: false,

        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Laden Speed",
        dataIndex: "spd_laden",
        width: 120,
        key: "spd_laden",
        sorter: (a, b) =>
          a.spd_laden && b.spd_laden
            ? Number(a.spd_laden) - Number(b.spd_laden)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Ballast Speed",
        dataIndex: "spd_ballast",
        key: "spd_ballast",
        width: 120,
        sorter: (a, b) =>
          a.spd_ballast && b.spd_ballast
            ? Number(a.spd_ballast) - Number(b.spd_ballast)
            : 1,
        showSorterTooltip: false,
      },
    ],
  },

  "vm-list-tci-tco": {
    tableheads: [
      {
        title: "Voyage No.",
        dataIndex: "voyage_number",
        key: "voyage_number",
        width: 150,
        render: (text, record) => ({
          props: {
            style: { color: "#1d565c", cursor: "pointer" },
            onClick: () => {
              window.open(
                `/#/voyage-manager/${record.voyage_number}`,
                "_blank"
              );
            },
          },
          children: <div>{text}</div>,
        }),
      },
      {
        title: "Status",
        dataIndex: "vm_status",
        key: "vm_status",
        width: 130,
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 130,
      },
      {
        title: "Completing Date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 130,
      },
    ],
  },

  "vm-list": {
    tableheads: [
      {
        title: "Voyage No.",
        dataIndex: "voyage_number",
        key: "voyage_number",
        width: 150,
        render: (text, record) => ({
          props: {
            style: { color: "#009dc4", cursor: "pointer" },
            onClick: () => {
              window.open(
                `/#/voyage-manager/${record.voyage_number}`,
                "_blank"
              );
            },
          },
          children: <div>{text}</div>,
        }),
      },

      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 120,
        render: (text, record) => {
          return {
            props: {
              style: { color: "#009dc4", cursor: "pointer" },
            },
            children: (
              <TableModal
                text={text}
                type={"tci"}
                record={record}
                id={record.tci_code}
              />
            ),
          };
        },
      },
      {
        title: "TCO Code",
        dataIndex: "tco_code",
        key: "tco_code",
        width: 160,
        render: (text, record) => ({
          props: {
            style: { color: "#009dc4", cursor: "pointer" },
          },
          children: (
            <TableModal
              text={text}
              type={"tco"}
              record={record}
              id={record.tco_code}
            />
          ),
        }),
      },

      {
        title: "My Company",
        dataIndex: "my_company_name",
        key: "my_company_name",
        width: 130,
      },

      {
        title: "Status",
        dataIndex: "vm_status",
        key: "vm_status",
        width: 130,
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 130,
      },
      {
        title: "Completing Date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 130,
      },
    ],
  },
  "vessel-file-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "v_status_name",
        key: "v_status_name",
        width: 120,
        sorter: (a, b) =>
          a.v_status_name && b.v_status_name
            ? a.v_status_name.localeCompare(b.v_status_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "My Company",
        dataIndex: "my_company_name",
        key: "my_company_name",
        width: 160,
        sorter: (a, b) =>
          a.my_company_name && b.my_company_name
            ? a.my_company_name.localeCompare(b.my_company_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: "Vessel File Code",
      //   dataIndex: "vessel_code",
      //   key: "vessel_code",
      //   width: 120,
      //   sorter: (a, b) =>
      //     a.vessel_code && b.vessel_code
      //       ? a.vessel_code.localeCompare(b.vessel_code)
      //       : null,
      //       showSorterTooltip:false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      {
        title: "Reg./Desp. owner",
        dataIndex: "owner_name",
        key: "owner_name",
        width: 150,
        sorter: (a, b) =>
          a.owner_name && b.owner_name
            ? a.owner_name.localeCompare(b.owner_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Commence Date",
        dataIndex: "commence",
        key: "commence",
        width: 150,
        sorter: (a, b) => sortTheDates(a.commence, b.commence),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"}
              </div>
            ),
          };
        },
      },

      {
        title: "Release Date",
        dataIndex: "release_date",
        key: "release_date",
        width: 150,
        sorter: (a, b) => sortTheDates(a.release_date, b.release_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"}
              </div>
            ),
          };
        },
      },

      {
        title: "Fixed by",
        dataIndex: "fixed_by_name",
        key: "fixed_by_name",
        width: 130,
        sorter: (a, b) =>
          a.fixed_by_name && b.fixed_by_name
            ? a.fixed_by_name.localeCompare(b.fixed_by_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Contract Type",
        dataIndex: "con_type_name",
        width: 120,
        key: "con_type_name",
        sorter: (a, b) =>
          a.con_type_name && b.con_type_name
            ? Number(a.con_type_name) - Number(b.con_type_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "advance-payment-list": {
    tableheads: [
      {
        title: "TDE NO",
        dataIndex: "tde_no",
        key: "tde_no",
        width: 150,

        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "My Company",
        dataIndex: "my_company_name",
        key: "my_company_name",
        width: 160,
        sorter: (a, b) =>
          a.my_company_name && b.my_company_name
            ? a.my_company_name.localeCompare(b.my_company_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vendor Company",
        dataIndex: "vendor_company_name",
        key: "vendor_company_name",
        width: 150,
        sorter: (a, b) =>
          a.vendor_company_name && b.vendor_company_name
            ? a.vendor_company_name.localeCompare(b.vendor_company_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Payment Date",
        dataIndex: "date",
        key: "date",
        width: 150,
        sorter: (a, b) => sortTheDates(a.payment_date, b.payment_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text}
                {/* {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"} */}
              </div>
            ),
          };
        },
      },

      {
        title: "Payment Mode",
        dataIndex: "pay_mode_name",
        key: "pay_mode_name",
        width: 130,
        sorter: (a, b) =>
          a.payment_mode && b.payment_mode
            ? a.payment_mode.localeCompare(b.payment_mode)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Page Status",
        dataIndex: "page_status",
        width: 120,
        key: "page_status",
        sorter: (a, b) =>
          a.page_status && b.page_status
            ? Number(a.page_status) - Number(b.page_status)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Value Date",
        dataIndex: "value_date",
        key: "value_date",
        width: 150,
        sorter: (a, b) => sortTheDates(a.value_date, b.value_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text}
                {/* {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"} */}
              </div>
            ),
          };
        },
      },

      {
        title: "Total pay/OutStanding",
        dataIndex: "total_pay",
        width: 120,
        key: "total_pay",
        sorter: (a, b) =>
          a.total_pay && b.total_pay
            ? Number(a.total_pay) - Number(b.total_pay)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "advance-payment-receipt-list": {
    tableheads: [
      {
        title: "Vendor Name",
        dataIndex: "vendro_name",
        key: "vendor",
        width: 150,

        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        width: 160,
        // sorter: (a, b) =>
        //   a.my_company_name && b.my_company_name
        //     ? a.my_company_name.localeCompare(b.my_company_name)
        //     : null,
        // showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Invoice_Type",
        dataIndex: "invoice_type",
        key: "invoice_type",
        width: 150,
        // sorter: (a, b) =>
        //   a.vendor_company_name && b.vendor_company_name
        //     ? a.vendor_company_name.localeCompare(b.vendor_company_name)
        //     : null,
        // showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Transaction No",
        dataIndex: "transaction_no",
        key: "transaction_no",
        width: 150,
        // sorter: (a, b) => sortTheDates(a.payment_date, b.payment_date),
        // showSorterTooltip: false,
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: (
        //       <div>
        //         {text && text !== "0000-00-00 00:00:00"
        //           ? moment(text).format("YYYY-MM-DD HH:mm")
        //           : "N/A"}
        //       </div>
        //     ),
        //   };
        // },
      },

      {
        title: "AP/AR Account",
        dataIndex: "AP/AR_account",
        key: "AP/AR_account",
        width: 130,
        // sorter: (a, b) =>
        //   a.payment_mode && b.payment_mode
        //     ? a.payment_mode.localeCompare(b.payment_mode)
        //     : null,
        // showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Advance Amount",
        dataIndex: "advance_amount",
        width: 120,
        key: "advance_amount",
        // sorter: (a, b) =>
        //   a.page_status && b.page_status
        //     ? Number(a.page_status) - Number(b.page_status)
        //     : null,
        // showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Allocated Amount",
        dataIndex: "allocated_amount",
        key: "allocated_amount",
        width: 150,
        // sorter: (a, b) => sortTheDates(a.value_date, b.value_date),
        // showSorterTooltip: false,
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: (
        //       <div>
        //         {text && text !== "0000-00-00 00:00:00"
        //           ? moment(text).format("YYYY-MM-DD HH:mm")
        //           : "N/A"}
        //       </div>
        //     ),
        //   };
        // },
      },

      {
        title: "Available Amount",
        dataIndex: "available_amount",
        key: "available_amount",
        width: 150,
        // sorter: (a, b) => sortTheDates(a.value_date, b.value_date),
        // showSorterTooltip: false,
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: (
        //       <div>
        //         {text && text !== "0000-00-00 00:00:00"
        //           ? moment(text).format("YYYY-MM-DD HH:mm")
        //           : "N/A"}
        //       </div>
        //     ),
        //   };
        // },
      },

      {
        title: "Allocated Date",
        dataIndex: "allocated_date",
        key: "allocated_date",
        width: 150,
        // sorter: (a, b) => sortTheDates(a.value_date, b.value_date),
        // showSorterTooltip: false,
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: (
        //       <div>
        //         {text && text !== "0000-00-00 00:00:00"
        //           ? moment(text).format("YYYY-MM-DD HH:mm")
        //           : "N/A"}
        //       </div>
        //     ),
        //   };
        // },
      },

      // {
      //   title: "Total pay/OutStanding",
      //   dataIndex: "total_pay",
      //   width: 120,
      //   key: "total_pay",
      //   sorter: (a, b) =>
      //     a.total_pay && b.total_pay
      //       ? Number(a.total_pay) - Number(b.total_pay)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#28a745" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
    ],
  },

  "vessel-dropdown-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        key: "vessel_code",
        width: 120,
        sorter: (a, b) => a.vessel_code.length - b.vessel_code.length,
        showSorterTooltip: false,
      },
      {
        title: "Vessel DWT",
        dataIndex: "vessel_dwt",
        key: "vessel_dwt",
        width: 120,
        sorter: (a, b) => a.vessel_dwt.length - b.vessel_dwt.length,
        showSorterTooltip: false,
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,
        sorter: (a, b) => a.vessel_type.length - b.vessel_type.length,
        showSorterTooltip: false,
      },
      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 120,
        sorter: (a, b) => a.tci_code.length - b.tci_code.length,
        showSorterTooltip: false,
      },
      {
        title: "TCI Status",
        dataIndex: "tci_status",
        key: "tci_status",
        width: 120,
        sorter: (a, b) => a.tci_status.length - b.tci_status.length,
        showSorterTooltip: false,
      },
      {
        title: "TCO Code",
        dataIndex: "tco_code",
        key: "tco_code",
        width: 120,
        sorter: (a, b) => a.tco_code.length - b.tco_code.length,
        showSorterTooltip: false,
      },
      {
        title: "TCO Status",
        dataIndex: "tco_status",
        key: "tco_status",
        width: 120,
        sorter: (a, b) => a.tco_status.length - b.tco_status.length,
        showSorterTooltip: false,
      },
      {
        title: "Voy. Number",
        dataIndex: "voyage_number",
        key: "voyage_number",
        width: 120,
        sorter: (a, b) => a.voyage_number.length - b.voyage_number.length,
        showSorterTooltip: false,
      },
      {
        title: "Voy. Status",
        dataIndex: "vm_status",
        key: "vm_status",
        width: 120,
        sorter: (a, b) => a.vm_status.length - b.vm_status.length,
        showSorterTooltip: false,
      },
    ],
  },
  "address-list-type": {
    tableheads: [
      {
        title: "Short Name",
        dataIndex: "short_name",
        key: "short_name",
        width: 150,
        sorter: (a, b) =>
          a.short_name && b.short_name
            ? a.short_name.localeCompare(b.short_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Company Name",
        dataIndex: "full_name",
        key: "full_name",
        width: 200,
        sorter: (a, b) =>
          a.full_name && b.full_name
            ? a.full_name.localeCompare(b.full_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Address Type",
        dataIndex: "address_type",
        key: "address_type",
        width: 150,
        sorter: (a, b) =>
          a.address_type && b.address_type
            ? a.address_type.localeCompare(b.address_type)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 150,
        sorter: (a, b) =>
          a.country && b.country ? a.country.localeCompare(b.country) : null,
        showSorterTooltip: false,
      },
      {
        title: "Country Code",
        dataIndex: "country_code",
        key: "country_code",
        width: 150,
        sorter: (a, b) =>
          a.country_code && b.country_code
            ? a.country_code.localeCompare(b.country_code)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Phone Number",
        dataIndex: "phone_number",
        key: "phone_number",
        width: 150,
        sorter: (a, b) =>
          a.phone_number && b.phone_number
            ? Number(a.phone_number) - Number(b.phone_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Currency",
        dataIndex: "currency_id",
        key: "currency_id",
        width: 150,
        sorter: (a, b) =>
          a.currency_id && b.currency_id
            ? a.currency_id.localeCompare(b.currency_id)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },
  "contact-list": {
    tableheads: [
      {
        title: "Name",
        dataIndex: "company_name",
        key: "company_name",
        width: 180,
        sorter: (a, b) => a?.company_name?.length - b?.company_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Direct No.",
        dataIndex: "director_no",
        key: "director_no",
        width: 180,
        sorter: (a, b) => a?.director_no?.length - b?.director_no?.length,
        showSorterTooltip: false,
      },
      {
        title: "Fax No.",
        dataIndex: "fax_no",
        key: "fax_no",
        width: 200,
        sorter: (a, b) => a?.fax_no?.length - b?.fax_no?.length,
        showSorterTooltip: false,
      },
      {
        title: "Home No.",
        dataIndex: "home_no",
        key: "home_no",
        width: 200,
        sorter: (a, b) => a?.home_no?.length - b?.home_no?.length,
        showSorterTooltip: false,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile_no",
        key: "mobile_no",
        // width: 50
        sorter: (a, b) => a?.mobile_no?.length - b?.mobile_no?.length,
        showSorterTooltip: false,
      },
    ],
  },
  "remittance-bank": {
    tableheads: [
      {
        title: "Benificiary Name",
        dataIndex: "benificiary_name",
        key: "benificiary_name",
        width: 150,
        sorter: (a, b) =>
          a?.benificiary_name?.length - b?.benificiary_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Benificiary Bank",
        dataIndex: "benificiary_bank",
        key: "benificiary_bank",
        width: 150,
        sorter: (a, b) =>
          a?.benificiary_bank?.length - b?.benificiary_bank?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Branch",
        dataIndex: "branch",
        key: "branch",
        width: 100,
        sorter: (a, b) => a?.branch?.length - b?.branch?.length,
        showSorterTooltip: false,
      },
      {
        title: "Acc. No..",
        dataIndex: "account_no",
        key: "account_no",
        width: 150,
        sorter: (a, b) => a?.account_no?.length - b?.account_no?.length,
        showSorterTooltip: false,
      },
      {
        title: "Swift Code",
        dataIndex: "swift_code",
        key: "swift_code",
        width: 120,
        sorter: (a, b) => a?.swift_code?.length - b?.swift_code?.length,
        showSorterTooltip: false,
      },
      {
        title: "Correspondent Bank",
        dataIndex: "correspondent_bank",
        key: "correspondent_bank",
        width: 180,
        sorter: (a, b) =>
          a?.correspondent_bank?.length - b?.correspondent_bank?.length,
        showSorterTooltip: false,
      },
      {
        title: "CB Swift Code",
        dataIndex: "cb_swift_code",
        key: "cb_swift_code",
        width: 150,
        sorter: (a, b) => a?.cb_swift_code?.length - b?.cb_swift_code?.length,
        showSorterTooltip: false,
      },
      {
        title: "IBAN",
        dataIndex: "iban",
        key: "iban",
        width: 100,
        sorter: (a, b) => a?.iban?.length - b?.iban?.length,
        showSorterTooltip: false,
      },
      {
        title: "International Bank Name",
        dataIndex: "ib_name",
        key: "ib_name",
        width: 200,
        sorter: (a, b) => a?.ib_name?.length - b?.ib_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Country",
        dataIndex: "b_country",
        key: "b_country",
        width: 100,
        sorter: (a, b) => a?.b_country?.length - b?.b_country?.length,
        showSorterTooltip: false,
      },
      {
        title: "Currency",
        dataIndex: "currency_name",
        key: "currency_name",
        width: 100,
        sorter: (a, b) => a?.currency_name?.length - b?.currency_name?.length,
        showSorterTooltip: false,
      },
      //  {
      //    title: "Payment Method",
      //    dataIndex: "pay_method",
      //    key: "pay_method",
      //    width: 150,
      //    sorter: (a, b) => a.pay_method.length - b.pay_method.length,
      //  },
      //  {
      //    title: "Account Type",
      //    dataIndex: "account_type",
      //    key: "account_type",
      //    width: 150,
      //    sorter: (a, b) => a.account_type.length - b.account_type.length,
      //  },
    ],
  },
  "cargo-name": {
    tableheads: [
      {
        title: "Short Name",
        dataIndex: "short_name",
        key: "short_name",
        width: 150,
        sorter: (a, b) =>
          a.short_name && b.short_name
            ? a.short_name.localeCompare(b.short_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Full Name",
        dataIndex: "full_name",
        key: "full_name",
        width: 200,
        sorter: (a, b) =>
          a.full_name && b.full_name
            ? a.full_name.localeCompare(b.full_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo Group",
        dataIndex: "group",
        key: "group",
        width: 120,
        sorter: (a, b) =>
          a.group && b.group ? a.group.localeCompare(b.group) : null,
        showSorterTooltip: false,
      },
      {
        title: "Pre Clearance Uscn",
        dataIndex: "pre_clearance_uscn",
        key: "pre_clearance_uscn",
        width: 100,
        sorter: (a, b) =>
          a.pre_clearance_uscn.length - b.pre_clearance_uscn.length,
        showSorterTooltip: false,
        render: (text) => (text === 1 ? "True" : "False"),
      },
      {
        title: "Special Handling",
        dataIndex: "special_handling",
        key: "special_handling",
        width: 100,
        sorter: (a, b) => a.special_handling.length - b.special_handling.length,
        showSorterTooltip: false,
        render: (text) => (text === 1 ? "True" : "False"),
      },
      {
        title: "UN Class",
        dataIndex: "un_number",
        key: "un_number",
        width: 100,
        sorter: (a, b) =>
          a.un_number && b.un_number
            ? Number(a.un_number) - Number(b.un_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Is Dangerous",
        dataIndex: "dangerous",
        key: "dangerous",
        width: 120,
        sorter: (a, b) => a.dangerous - b.dangerous,
        showSorterTooltip: false,
        render: (text) => (text === 1 ? "True" : "False"),
      },
      {
        title: "API",
        dataIndex: "c_api",
        key: "c_api",
        width: 100,
        sorter: (a, b) => a.c_api.length - b.c_api.length,
        showSorterTooltip: false,
      },
      {
        title: "Product Code",
        dataIndex: "product_code",
        key: "product_code",
        width: 150,
        sorter: (a, b) => a.product_code.length - b.product_code.length,
        showSorterTooltip: false,
      },
    ],
  },

  "offhire-deviation-list": {
    tableheads: [
      {
        title: "Voyage No",
        dataIndex: "voyage",
        key: "voyage",
        width: 150,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        key: "vessel_code",
        width: 150,
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Invoice No",
        dataIndex: "inv_no",
        key: "inv_no",
        width: 150,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Company Name",
        dataIndex: "my_company_name",
        key: "my_company_name",
        width: 150,
        sorter: (a, b) =>
          a.my_company_name && b.my_company_name
            ? a.my_company_name.localeCompare(b.my_company_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Counter party",
        dataIndex: "counter_party_name",
        key: "counter_party_name",
        width: 150,
        sorter: (a, b) =>
          a.counter_party_name && b.counter_party_name
            ? a.counter_party_name.localeCompare(b.counter_party_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Commence Date",
        dataIndex: "commence",
        key: "commence",
        width: 150,
        sorter: (a, b) => sortTheDates(a.commence, b.commence),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"}
              </div>
            ),
          };
        },
      },

      {
        title: "Completed  Date",
        dataIndex: "complate_date",
        key: "complate_date",
        width: 150,
        sorter: (a, b) => sortTheDates(a.complate_date, b.complate_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"}
              </div>
            ),
          };
        },
      },
      {
        title: "Invoiced Date",
        dataIndex: "inv_date",
        key: "inv_date",
        width: 150,
        sorter: (a, b) => moment(a.inv_date).unix() - moment(b.inv_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text}
                {/* {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : "N/A"} */}
              </div>
            ),
          };
        },
      },
      {
        title: "Invoiced Type",
        dataIndex: "inv_type_name",
        key: "inv_type_name",
        width: 150,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "PNL Display",
        dataIndex: "pnl_display",
        key: "pnl_display",
        width: 150,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text === 1 ? "YES" : "NO"}</div>,
          };
        },
      },
      {
        title: "Account Type",
        dataIndex: "account_type_name",
        key: "account_type_name",
        width: 150,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        width: 150,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },

  "tc-in-list": {
    tableheads: [
      // {
      //   title: "TCI Code",
      //   dataIndex: "tc_code",
      //   key: "tc_code",
      //   width: 100,

      //   sorter: (a, b) =>
      //     a.tc_code && b.tc_code ? a.tc_code.localeCompare(b.tc_code) : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "TCI Code",
      //   dataIndex: "tc_code",
      //   key: "tc_code",
      //   width: 100,

      //   sorter: (a, b) =>
      //     a.tc_code && b.tc_code ? a.tc_code.localeCompare(b.tc_code) : null,
      //   showSorterTooltip: false,
      //   render: (text, record) => {
      //     let clr =
      //       record.tci_status_name === "FIX"
      //         ? "#e11010"
      //         : record.tci_status_name === "SCHEDULED"
      //         ? "#6ecb0c"
      //         : "blue";

      //     return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
      //   },
      // },

      {
        title: "TCI Code",
        dataIndex: "tc_code",
        key: "tc_code",
        width: 100,
        sorter: (a, b) =>
          a?.tc_code && b?.tc_code ? a?.tc_code.localeCompare(b?.tc_code) : 0,
        showSorterTooltip: false,
        render: (text, record) => {
          const color =
            record.tci_status_name === "FIX"
              ? "#e11010"
              : record.tci_status_name === "SCHEDULED"
              ? "#6ecb0c"
              : "blue";

          return text ? (
            <a
              href={`#/TC-IN/${text}`}
              target="_blank"
              rel="noopener noreferrer" // Prevent security issues
              style={{
                color: color,
                textDecoration: "underline", // Indicate it's a clickable link
                cursor: "pointer",
              }}
            >
              {text}
            </a>
          ) : (
            "N/A"
          );
        },
      },

      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 120,

        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "TCI Status",
        dataIndex: "tci_status_name",
        key: "tci_status_name",
        width: 120,
        sorter: (a, b) => a.tci_status_name.length - b.tci_status_name.length,
        showSorterTooltip: false,
        render: (text) => {
          let clr;

          if (text === "FIX") {
            clr = "#e11010";
          }
          if (text === "DRAFT") {
            clr = "blue";
          }
          if (text === "SCHEDULED") {
            clr = "#6ecb0c";
          }

          const style = {
            fontWeight: "600",
            color: clr,
          };

          return <span style={style}>{text}</span>;
        },

        // render: (tags) => (
        //   <span>
        //     {tags ? (
        //       <Tag
        //         color={
        //           tags === "SCHEDULED"
        //             ? "#28a745"
        //             : tags === "DELIVERED"
        //             ? "#01bcd4"
        //             : tags === "REDELIVERED"
        //             ? "#0726ff"
        //             : tags === "COMPLETED"
        //             ? "#ff0000"
        //             : tags === "FIX"
        //             ? "#81d742"
        //             : "#9e9e9e"
        //         }
        //         key={tags}
        //       >
        //         {tags}
        //       </Tag>
        //     ) : (
        //       "N/A"
        //     )}
        //   </span>
        // ),
      },

      {
        title: "Contract Type",
        dataIndex: "contract_type",
        key: "contract_type",
        width: 130,
        sorter: (a, b) =>
          a.contract_type && b.contract_type
            ? a.contract_type.localeCompare(b.contract_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Delivery Port",
        dataIndex: "delivery_port",
        key: "delivery_port",
        width: 100,
        sorter: (a, b) =>
          a.delivery_port && b.delivery_port
            ? a.delivery_port.localeCompare(b.delivery_port)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Delivery Date",
        dataIndex: "delivery_date",
        key: "delivery_date",
        width: 120,
        sorter: (a, b) => sortTheDates(a.delivery_date, b.delivery_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Redelivery Port",
        dataIndex: "redelivery_port",
        key: "redelivery_port",
        width: 100,
        sorter: (a, b) =>
          a.redelivery_port && b.redelivery_port
            ? a.redelivery_port.localeCompare(b.redelivery_port)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Redelivery Date",
        dataIndex: "redelivery_date",
        key: "redelivery_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.redelivery_date).unix() - moment(b.redelivery_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Hire",
        dataIndex: "gross_hire",
        key: "gross_hire",
        width: 100,
        sorter: (a, b) =>
          a.gross_hire && b.gross_hire
            ? parseFloat(a.gross_hire.replace(",", "")) -
              parseFloat(b.gross_hire.replace(",", ""))
            : a.gross_hire.replace(",", "") && !b.gross_hire.replace(",", "")
            ? -1
            : b.gross_hire.replace(",", "") && !a.gross_hire.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        // sorter: (a, b) =>
        //   a.gross_hire && b.gross_hire
        //     ? parseFloat(a.gross_hire.replace(",", "")) -
        //       parseFloat(b.gross_hire.replace(",", ""))
        //     : a.gross_hire.replace(",", "") && !b.gross_hire.replace(",", "")
        //       ? -1
        //       : b.gross_hire.replace(",", "") && !a.gross_hire.replace(",", "")
        //         ? 1
        //         : 0,
      },

      {
        title: "Fixed By",
        dataIndex: "cp_fixed_by",
        key: "cp_fixed_by",
        sorter: (a, b) =>
          a.cp_fixed_by && b.cp_fixed_by
            ? a.cp_fixed_by.length - b.cp_fixed_by.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Ops User",
        dataIndex: "ops_user",
        key: "ops_user",
        sorter: (a, b) =>
          a.ops_user && b.ops_user ? a.ops_user.length - b.ops_user.length : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Laycan From",
        dataIndex: "laycan_from",
        key: "laycan_from",
        sorter: (a, b) =>
          moment(a.laycan_from).unix() - moment(b.laycan_from).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Laycan To",
        dataIndex: "laycan_to",
        key: "laycan_to",

        sorter: (a, b) =>
          moment(a.laycan_to).unix() - moment(b.laycan_to).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Owner",
        dataIndex: "owner_name",
        key: "owner_name",
        sorter: (a, b) =>
          a.owner_name && b.owner_name
            ? a.owner_name.localeCompare(b.owner_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Company",
        dataIndex: "company_fixed_with_name",
        key: "company_fixed_with_name",

        sorter: (a, b) =>
          a.company_fixed_with_name && b.company_fixed_with_name
            ? a.company_fixed_with_name.localeCompare(b.company_fixed_with_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Year",
        dataIndex: "age",
        key: "age",

        sorter: (a, b) =>
          new Date(a.age).getFullYear() - new Date(b.age).getFullYear(),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "IMO No",
        dataIndex: "imo_no",
        key: "imo_no",
        sorter: (a, b) =>
          a.imo_no && b.imo_no ? Number(a.imo_no) - Number(b.imo_no) : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Trade Area",
        dataIndex: "trade_area_name",
        key: "trade_area_name",

        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "DWT",
        dataIndex: "vessel_dwt",
        key: "vessel_dwt",
        sorter: (a, b) =>
          a.vessel_dwt && b.vessel_dwt
            ? Number(a.vessel_dwt) - Number(b.vessel_dwt)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "tcov-quick-list": {
    tableheads: [
      {
        title: "Est. Type",
        dataIndex: "estimate_type_name",
        key: "estimate_type_name",
        width: 100,
        sorter: (a, b) =>
          a.estimate_type_name.length - b.estimate_type_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Qucik Est. ID",
        dataIndex: "estimate_id",
        key: "estimate_id",
        width: 150,
        sorter: (a, b) => a.estimate_id.length - b.estimate_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.length - b.vessel_name.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 150,
        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.length - b.vessel_type.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 150,
        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.length - b.vessel_sub_type.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "TCI D/Hire",
        dataIndex: "vessel_hire",
        key: "vessel_hire",
        width: 150,
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },

            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) => sortTheNumbers(a.vessel_hire, b.vessel_hire),
        showSorterTooltip: false,
      },

      {
        title: "Ballast Bonus",
        dataIndex: "blast_bonus",
        key: "blast_bonus",
        width: 150,
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) => sortTheNumbers(a.blast_bonus, b.blast_bonus),
        showSorterTooltip: false,
      },
      {
        title: "Other Cost",
        dataIndex: "other_cost",
        key: "other_cost",
        width: 150,
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },

            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) => sortTheNumbers(a.other_cost, b.other_cost),
        showSorterTooltip: false,
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) => sortTheDates(a.commence_date, b.commence_date),
        showSorterTooltip: false,
        render(el) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{moment(el).format("YYYY-MM-DD HH:mm:ss")}</div>,
          };
        },
      },
      {
        title: "Completed Date",
        dataIndex: "completed_date",
        key: "completed_date",
        width: 150,
        sorter: (a, b) => sortTheDates(a.completed_date, b.completed_date),
        showSorterTooltip: false,
        render(el) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{moment(el).format("YYYY-MM-DD HH:mm:ss")}</div>,
          };
        },
      },
      {
        title: "Ballast Port",
        dataIndex: "ballast_port",
        key: "ballast_port",
        width: 150,
        sorter: (a, b) =>
          a.ballast_port && b.ballast_port
            ? a.ballast_port.length - b.ballast_port.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Reposition Port",
        dataIndex: "repos_port",
        key: "reposition_port",
        width: 150,
        sorter: (a, b) =>
          a.reposition_port && b.reposition_port
            ? a.reposition_port.length - b.reposition_port.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Sea Days",
        dataIndex: "sea_days",
        key: "sea_days",
        width: 150,
        sorter: (a, b) =>
          a.sea_days && b.sea_days ? a.sea_days.length - b.sea_days.length : 1,
        showSorterTooltip: false,
      },
      {
        title: "VLSFO ($/MT)",
        dataIndex: "bunker_oil_price",
        key: "bunker_oil_price",
        width: 150,
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) => sortTheNumbers(a.vlsfo_price, b.vlsfo_price),
        showSorterTooltip: false,
      },
      {
        title: "MGO ($/MT)",
        dataIndex: "bunker_gas_price",
        key: "bunker_gas_price",
        width: 150,
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) => sortTheNumbers(a.mgo_price, b.mgo_price),
        showSorterTooltip: false,
      },
    ],
  },
  "chat-dash-list": {
    tableheads: [
      {
        title: "Voyage No",
        dataIndex: "voyage_number",
        key: "voyage_no",
        width: 170,

        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#009dc4" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
        render(text) {
          return {
            props: {
              style: {
                color: "#009dc4",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
            // children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) => a?.vessel_name?.length - b?.vessel_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        width: 150,
        sorter: (a, b) => {
          const aLength = a?.vessel_type_name ? a?.vessel_type_name?.length : 0;
          const bLength = b?.vessel_type_name ? b?.vessel_type_name?.length : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      {
        title: "Cargo Name",
        dataIndex: "cargo_name",
        key: "cargo_name",
        width: 150,
        sorter: (a, b) => a?.dwt - b?.dwt,
        showSorterTooltip: false,
      },

      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) => a?.tcov_status?.length - b?.tcov_status?.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "FIX"
                  ? "#28a745"
                  : tags === "PENDING"
                  ? "#01bcd4"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags.toUpperCase()}
            </Tag>
          </span>
        ),
      },

      {
        title: "Completed Date",
        dataIndex: "completing_date",
        key: "completed_date",
        width: 150,
      },

      {
        title: "Total Voyage Days",
        dataIndex: "total_days",
        key: "total_days",
        width: 150,
        sorter: (a, b) =>
          a?.my_company_name && b?.my_company_name
            ? a?.my_company_name.length - b?.my_company_name.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Total Cargo Quantity  ",
        dataIndex: "cp_qty",
        key: "cp_qty",
        width: 150,
        sorter: (a, b) =>
          a?.charter_party && b?.charter_party
            ? a?.charter_party?.length - b?.charter_party?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Total Sea Cons.",
        dataIndex: "sea_cons",
        key: "sea_cons",
        width: 150,

        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Total Eca Sea Cons.",
        dataIndex: "eca_seca_cons",
        key: "eca_seca_cons",
        width: 200,
        sorter: (a, b) =>
          a?.load_port && b?.load_port
            ? a?.load_port?.length - b?.load_port?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Total Port Cons.",
        dataIndex: "port_cons",
        key: "port_cons",
        width: 200,
        sorter: (a, b) =>
          a?.discharge_port && b?.discharge_port
            ? a?.discharge_port?.length - b?.discharge_port?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Total Bunker Cons.",
        dataIndex: "bunker_cons",
        key: "bunker_cons",
        width: 220,
        sorter: (a, b) =>
          a?.eca_fuel_grade && b?.eca_fuel_grade
            ? a?.eca_fuel_grade?.length - b?.eca_fuel_grade?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Hire Payable Amt",
        dataIndex: "hire_paybale_amount",
        key: "hire_paybale_amount",
        width: 220,
        sorter: (a, b) =>
          a?.other_cost && b?.other_cost
            ? a?.other_cost?.length - b?.other_cost?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Hire Receivable Amt",
        dataIndex: "ballast_bonus",
        key: "ballast_bonus",
        width: 220,
        sorter: (a, b) =>
          a?.ballast_bonus && b?.ballast_bonus
            ? a?.ballast_bonus?.length - b?.ballast_bonus?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Total Port Expenses",
        dataIndex: "port_expenses",
        key: "port_expenses",
        width: 150,
        sorter: (a, b) =>
          moment(a?.commence_date).unix() - moment(b?.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Total Revenue",
        dataIndex: "net_revenue",
        key: "net_revenue",
        width: 150,
        sorter: (a, b) =>
          moment(a?.created_on).unix() - moment(b?.created_on).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total Expenses",
        dataIndex: "grossexpensee",
        key: "grossexpensee",
        width: 150,
      },
      {
        title: "Net Result",
        dataIndex: "profit_loss",
        key: "profit_loss",
        width: 150,
      },
      {
        title: "Daily Profit/Loss",
        dataIndex: "daily_profit_loss",
        key: "daily_profit_loss",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "vm_status1",
        key: "vm_status1",
        width: 150,
      },
    ],
  },
  "finance-dash-list": {
    tableheads: [
      {
        title: "Voyage No",
        dataIndex: "voyage_number",
        key: "voyage_number",
        width: 170,

        render(text) {
          return {
            props: {
              style: {
                color: "#009dc4",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
            // children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) => a?.vessel_name.length - b?.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Voyage Status",
        dataIndex: "vm_status1",
        key: "vm_status1",
        width: 150,
        sorter: (a, b) => {
          const aLength = a?.vessel_type_name ? a?.vessel_type_name?.length : 0;
          const bLength = b?.vessel_type_name ? b?.vessel_type_name?.length : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },
      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 150,
        sorter: (a, b) => a?.tcov_status?.length - b?.tcov_status?.length,
        showSorterTooltip: false,
      },
      {
        title: "TCO Code",
        dataIndex: "tco_code",
        key: "tco_code",
        width: 150,
        sorter: (a, b) => a?.tcov_status?.length - b?.tcov_status?.length,
        showSorterTooltip: false,
        // render: (tags) => (
        //   <span>
        //     <Tag
        //       color={
        //         tags === "FIX"
        //           ? "#28a745"
        //           : tags === "PENDING"
        //           ? "#01bcd4"
        //           : "#9e9e9e"
        //       }
        //       key={tags}
        //     >
        //       {tags.toUpperCase()}
        //     </Tag>
        //   </span>
        // ),
      },

      {
        title: "Hire Receivable Amt",
        dataIndex: "hire_rec_amount",
        key: "hire_rec_amount",
        width: 150,
      },

      {
        title: "TCO Comm Amt",
        dataIndex: "tco_com_amount",
        key: "tco_com_amount",
        width: 150,
        sorter: (a, b) =>
          a?.my_company_name && b?.my_company_name
            ? a?.my_company_name?.length - b?.my_company_name?.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Hire Payable Amt",
        dataIndex: "hire_pay_amount",
        key: "hire_pay_amount",
        width: 150,

        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Total Frt. Amount",
        dataIndex: "freight_amount",
        key: "freight_amount",
        width: 200,
        sorter: (a, b) =>
          a?.load_port && b?.load_port
            ? a?.load_port?.length - b?.load_port?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Total Frt. Comm.",
        dataIndex: "freight_comm_amount",
        key: "freight_comm_amount",
        width: 200,
        sorter: (a, b) =>
          a?.discharge_port && b?.discharge_port
            ? a?.discharge_port?.length - b?.discharge_port?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "OtherRev. Amount",
        dataIndex: "other_rev_amount",
        key: "other_rev_amount",
        width: 220,
        sorter: (a, b) =>
          a?.eca_fuel_grade && b?.eca_fuel_grade
            ? a?.eca_fuel_grade?.length - b?.eca_fuel_grade?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Other Exp. Amount",
        dataIndex: "other_exp_amount",
        key: "other_exp_amount",
        width: 220,
        sorter: (a, b) =>
          a?.other_cost && b?.other_cost
            ? a?.other_cost?.length - b?.other_cost?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Claim Amount",
        dataIndex: "clm_amount",
        key: "clm_amount",
        width: 220,
        sorter: (a, b) =>
          a.ballast_bonus && b.ballast_bonus
            ? a.ballast_bonus?.length - b.ballast_bonus?.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "OffHire Amount",
        dataIndex: "of_hire_amount",
        key: "of_hire_amount",
        width: 150,
        sorter: (a, b) =>
          moment(a?.commence_date).unix() - moment(b?.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Bunker Amount",
        dataIndex: "bunk_amount",
        key: "bunk_amount",
        width: 150,
        sorter: (a, b) =>
          moment(a?.created_on).unix() - moment(b?.created_on).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "FD Amount",
        dataIndex: "port_exp_amt",
        key: "port_exp_amt",
        width: 150,
      },
      {
        title: "Port Exp. ",
        dataIndex: "p_exp",
        key: "p_exp",
        width: 150,
      },
      {
        title: "Commence date",
        dataIndex: "commence_date",
        key: "commence_date",

        width: 150,
      },
      {
        title: "Completed date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 150,
      },
      {
        title: "Total voy days",
        dataIndex: "net_voyage_days",
        key: "net_voyage_days",
        width: 150,
      },
      {
        title: "Net Revenue",
        dataIndex: "net_revenue",
        key: "net_revenue",
        width: 150,
      },
      {
        title: "Net Expense",
        dataIndex: "grossexpensee",
        key: "grossexpensee",
        width: 150,
      },
      {
        title: "Profit/Loss",
        dataIndex: "profit_loss",
        key: "profit_loss",
        width: 150,
      },
      {
        title: "OPS Type",
        dataIndex: "ops_type",
        key: "ops_type",
        width: 150,
      },
    ],
  },

  "tcov-list": {
    tableheads: [
      // {
      //   title: "Voyage Estimate ID",
      //   dataIndex: "estimate_id",
      //   key: "estimate_id",
      //   width: 170,

      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      {
        title: "Voyage Estimate ID",
        dataIndex: "estimate_id",
        key: "estimate_id",
        width: 170,
        render: (text, record) => {
          let clr =
            record.tcov_status === "FIX"
              ? "#e11010"
              : record.tcov_status === "SCHEDULE"
              ? "#6ecb0c"
              : "blue";

          return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
        },
      },

      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        width: 150,
        sorter: (a, b) => {
          const aLength = a.vessel_type_name ? a.vessel_type_name.length : 0;
          const bLength = b.vessel_type_name ? b.vessel_type_name.length : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 150,
        sorter: (a, b) => {
          const aLength = a.vessel_sub_type ? a.vessel_sub_type.length : 0;
          const bLength = b.vessel_sub_type ? b.vessel_sub_type.length : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      {
        title: "Dwt",
        dataIndex: "dwt",
        key: "dwt",
        width: 150,
        sorter: (a, b) => a.dwt - b.dwt,
        showSorterTooltip: false,
      },

      {
        title: "Status",
        dataIndex: "tcov_status",
        key: "tcov_status",
        width: 150,
        sorter: (a, b) => a.tcov_status.length - b.tcov_status.length,
        showSorterTooltip: false,

        render: (text) => {
          let clr;

          if (text === "FIX") {
            clr = "#e11010";
          }
          if (text === "DRAFT") {
            clr = "blue";
          }
          if (text === "SCHEDULE") {
            clr = "#6ecb0c";
          }

          const style = {
            fontWeight: "600",
            color: clr,
          };

          // if (text === "PENDING") {
          //   return <span style={style}>{"DRAFT"}</span>;
          // }

          return <span style={style}>{text}</span>;
        },

        // render: (tags) => (
        //   <span>
        //     <Tag
        //       color={
        //         tags === "FIX"
        //           ? "#28a745"
        //           : tags === "PENDING"
        //           ? "#01bcd4"
        //           : "#9e9e9e"
        //       }
        //       key={tags}
        //     >
        //       {tags.toUpperCase()}
        //     </Tag>
        //   </span>
        // ),
      },

      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 150,
      },

      {
        title: "My Company",
        dataIndex: "my_company_name",
        key: "my_company_name",
        width: 150,
        sorter: (a, b) =>
          a.my_company_name && b.my_company_name
            ? a.my_company_name.length - b.my_company_name.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Counter Party",
        dataIndex: "charter_party",
        key: "charter_party",
        width: 150,
        sorter: (a, b) =>
          a.charter_party && b.charter_party
            ? a.charter_party.length - b.charter_party.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Cargo",
        dataIndex: "cargoname",
        key: "cargoname",
        width: 150,

        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Load Port",
        dataIndex: "load_port",
        key: "load_port",
        width: 200,
        sorter: (a, b) =>
          a.load_port && b.load_port
            ? a.load_port.length - b.load_port.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Discharge Port",
        dataIndex: "discharge_port",
        key: "discharge_port",
        width: 200,
        sorter: (a, b) =>
          a.discharge_port && b.discharge_port
            ? a.discharge_port.length - b.discharge_port.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Eca Fuel Grade",
        dataIndex: "eca_fuel_grade",
        key: "eca_fuel_grade",
        width: 220,
        sorter: (a, b) =>
          a.eca_fuel_grade && b.eca_fuel_grade
            ? a.eca_fuel_grade.length - b.eca_fuel_grade.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Other Cost",
        dataIndex: "other_cost",
        key: "other_cost",
        width: 220,
        sorter: (a, b) =>
          a.other_cost && b.other_cost
            ? a.other_cost.length - b.other_cost.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Ballast Bonus",
        dataIndex: "ballast_bonus",
        key: "ballast_bonus",
        width: 220,
        sorter: (a, b) =>
          a.ballast_bonus && b.ballast_bonus
            ? a.ballast_bonus.length - b.ballast_bonus.length
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.commence_date).unix() - moment(b.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Completing Days",
        dataIndex: "created_on",
        key: "created_on",
        width: 150,
        sorter: (a, b) =>
          moment(a.created_on).unix() - moment(b.created_on).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total Voyage Days",
        dataIndex: "total_voyage_days",
        key: "total_voyage_days",
        width: 150,
      },
      {
        title: "Fixed By",
        dataIndex: "user_name",
        key: "user_name",
        width: 150,
      },
    ],
  },
  "ports-list": {
    tableheads: [
      {
        title: "Port ID",
        dataIndex: "PortID",
        key: "PortID",
        invisible: "false",
        width: 200,
        sorter: (a, b) =>
          a.PortID && b.PortID ? a.PortID.localeCompare(b.PortID) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port Name",
        dataIndex: "port_name",
        key: "port_name",
        invisible: "false",
        width: 300,

        sorter: (a, b) =>
          a.port_name && b.port_name
            ? a.port_name.localeCompare(b.port_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port Type",
        dataIndex: "port_type",
        key: "port_type",
        invisible: "false",
        width: 200,
        sorter: (a, b) =>
          a.port_type && b.port_type
            ? a.port_type.localeCompare(b.port_type)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Country ID",
        dataIndex: "country_id",
        key: "country_id",
        invisible: "false",
        width: 200,
        sorter: (a, b) =>
          a.country_id && b.country_id
            ? a.country_id.length - b.country_id.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Latitude",
        dataIndex: "latitude",
        key: "latitude",
        invisible: "false",
        width: 200,
        sorter: (a, b) =>
          a.latitude && b.latitude
            ? Number(a.latitude) - Number(b.latitude)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Longitude",
        dataIndex: "longitude",
        key: "longitude",
        invisible: "false",
        width: 200,
        sorter: (a, b) =>
          a.longitude && b.longitude
            ? Number(a.longitude) - Number(b.longitude)
            : 1,
        showSorterTooltip: false,
      },
    ],
  },
  "coas-list": {
    tableheads: [
      {
        title: "COA(cargo) ID",
        dataIndex: "contract_id",
        key: "contract_id",
        width: 100,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Master Contract ID",
        dataIndex: "mst_contract",
        key: "mst_contract",
        width: 170,
        sorter: (a, b) => a.mst_contract.length - b.mst_contract.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Cargo Status",
        dataIndex: "status_name",
        key: "status_name",
        width: 120,
        sorter: (a, b) => a.status_name.length - b.status_name.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            {tags ? (
              <Tag
                color={
                  tags === "SCHEDULE"
                    ? "#28a745"
                    : tags === "DELIVERED"
                    ? "#01bcd4"
                    : tags === "REDELIVERED"
                    ? "#0726ff"
                    : tags === "COMPLETED"
                    ? "#ff0000"
                    : tags === "FIXED"
                    ? "#81d742"
                    : "#9e9e9e"
                }
                key={tags}
              >
                {tags}
              </Tag>
            ) : (
              "N/A"
            )}
          </span>
        ),
      },
      {
        title: "Charterer",
        dataIndex: "charterer_name",
        key: "charterer_name",
        width: 120,
        sorter: (a, b) => a.charterer_name.length - b.charterer_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "COA Qty",
        dataIndex: "coa_qty",
        key: "coa_qty",
        width: 100,
        sorter: (a, b) =>
          a.coa_qty && b.coa_qty ? Number(a.coa_qty) - Number(b.coa_qty) : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "COA Unit",
        dataIndex: "coa_qty_unit_name",
        key: "coa_qty_unit_name",
        width: 120,
        sorter: (a, b) =>
          a.coa_qty_unit_name.length - b.coa_qty_unit_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Start Date',
      //   dataIndex: 'start_date',
      //   key: 'start_date',
      //   width: 100
      // },

      {
        title: "End Date",
        dataIndex: "end",
        key: "end",
        width: 120,
        sorter: (a, b) => moment(a.end).unix() - moment(b.end).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Cargo",
        dataIndex: "cargo_name1",
        key: "cargo_name1",
        width: 100,
        sorter: (a, b) =>
          a.cargo_name1 && b.cargo_name1
            ? a.cargo_name1.localeCompare(b.cargo_name1)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "CP Date",
        dataIndex: "cp_date",
        key: "cp_date",
        width: 100,
        sorter: (a, b) => moment(a.cp_date).unix() - moment(b.cp_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "My Company",
        dataIndex: "my_company",
        key: "my_company",
        width: 150,
        sorter: (a, b) =>
          a.my_company && b.my_company
            ? a.my_company.localeCompare(b.my_company)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "LOB",
        dataIndex: "lob",
        key: "lob",
        width: 100,
        sorter: (a, b) => (a.lob && b.lob ? a.lob.length - b.lob.length : 1),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Freight Type",
        dataIndex: "freight_type",
        key: "freight_type",
        width: 130,
        sorter: (a, b) =>
          a.freight_type && b.freight_type
            ? a.freight_type.localeCompare(b.freight_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Frt Rate",
        dataIndex: "freight_rate",
        key: "freight_rate",
        width: 100,
        sorter: (a, b) =>
          a.freight_rate && b.freight_rate
            ? parseFloat(a.freight_rate.replace(",", "")) -
              parseFloat(b.freight_rate.replace(",", ""))
            : a.freight_rate.replace(",", "") &&
              !b.freight_rate.replace(",", "")
            ? -1
            : b.freight_rate.replace(",", "") &&
              !a.freight_rate.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Trade Area",
        dataIndex: "trade_area",
        key: "trade_area",
        width: 120,
        sorter: (a, b) =>
          a.trade_area && b.trade_area
            ? a.trade_area.localeCompare(b.trade_area)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "No of Lifting",
        dataIndex: "cp_qty_per_lift",
        key: "cp_qty_per_lift",
        width: 150,
        sorter: (a, b) =>
          a.cp_qty_per_lift && b.cp_qty_per_lift
            ? Number(a.cp_qty_per_lift) - Number(b.cp_qty_per_lift)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Fixed By",
        dataIndex: "fixed_by_user_name",
        key: "fixed_by_user_name",
        width: 100,
        sorter: (a, b) =>
          a?.fixed_by_user_name?.length - b?.fixed_by_user_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },
  "tcto-list": {
    tableheads: [
      // {
      //   title: "TC Est. code",
      //   dataIndex: "estimate_id",
      //   key: "estimate_id",
      //   width: 120,

      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      {
        title: "TC Est. code",
        dataIndex: "estimate_id",
        key: "estimate_id",
        width: 170,
        render: (text, record) => {
          let clr =
            record.tcto_status === "FIX"
              ? "#e11010"
              : record.tcto_status === "SCHEDULE"
              ? "#6ecb0c"
              : "blue";

          return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 130,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 130,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 130,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "DWT",
        dataIndex: "dwt",
        key: "dwt",
        width: 130,
        sorter: (a, b) => (a.dwt && b.dwt ? Number(a.dwt) - Number(b.dwt) : 1),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Voyage status",
        dataIndex: "tcto_status_name",
        key: "tcto_status_name",
        width: 150,
        sorter: (a, b) => a.tcto_status_name.length - b.tcto_status_name.length,
        showSorterTooltip: false,

        render: (text) => {
          let clr;

          if (text === "FIX") {
            clr = "#e11010";
          }
          if (text === "DRAFT") {
            clr = "blue";
          }
          if (text === "SCHEDULE") {
            clr = "#6ecb0c";
          }

          const style = {
            fontWeight: "600",
            color: clr,
          };

          // if (text === "PENDING") {
          //   return <span style={style}>{"DRAFT"}</span>;
          // }

          return <span style={style}>{text}</span>;
        },

        // render: (text) => (
        //   <span>
        //     <Tag
        //       color={
        //         text === "FIX"
        //           ? "#e11010"
        //           : text === "PENDING"
        //           ? "blue"
        //           : text === "SCHEDULE"
        //           ? "#6ecb0c"
        //           : ""
        //       }
        //       key={text}
        //     ></Tag>
        //   </span>
        // ),
      },
      {
        title: "My company",
        dataIndex: "my_company",
        key: "my_company",
        width: 120,
        sorter: (a, b) =>
          (typeof a.my_company !== "undefined" ? a.my_company.length : 0) -
          (typeof b.my_company !== "undefined" ? b.my_company.length : 0),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.commence_date).unix() - moment(b.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Completed Date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.completing_date).unix() - moment(b.completing_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0721ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Total Voyage Days",
        dataIndex: "tot_voy_days",
        key: "tot_voy_days",
        width: 120,
        sorter: (a, b) =>
          a.tot_voy_days && b.tot_voy_days
            ? Number(a.tot_voy_days) - Number(b.tot_voy_days)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 130,
        sorter: (a, b) =>
          a.tci_code && b.tci_code
            ? a.tci_code.localeCompare(b.tci_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "TCO Code",
        dataIndex: "tco_code",
        key: "tco_code",
        width: 130,
        sorter: (a, b) =>
          a.tco_code && b.tco_code
            ? a.tco_code.localeCompare(b.tco_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "TCI BB",
        dataIndex: "bb",
        key: "bb",
        sorter: (a, b) => (a.bb && b.bb ? Number(a.bb) - Number(b.bb) : 1),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "TCO BB",
        dataIndex: "tco_bb",
        key: "tco_bb",
        sorter: (a, b) =>
          a.tco_bb && b.tco_bb ? Number(a.tco_bb) - Number(b.tco_bb) : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Created date",
        dataIndex: "created_on",
        key: "created_on",
        width: 150,
        sorter: (a, b) =>
          moment(a.created_on).unix() - moment(b.created_on).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Other Costs",
        dataIndex: "mis_cost",
        key: "mis_cost",
        sorter: (a, b) =>
          a.mis_cost && b.mis_cost
            ? Number(a.mis_cost) - Number(b.mis_cost)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Eca/Sea Fuel Grade",
        dataIndex: "eca_fuel_g",
        key: "eca_fuel_g",
        width: 130,
        sorter: (a, b) =>
          a.eca_sea_fuel_grade && b.eca_sea_fuel_grade
            ? a.eca_sea_fuel_grade.localeCompare(b.eca_sea_fuel_grade)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Fixed By User",
        dataIndex: "fixed_by_user",
        key: "fixed_by_user",
        width: 130,
        sorter: (a, b) =>
          a.fixed_by_user && b.fixed_by_user
            ? a.fixed_by_user.localeCompare(b.fixed_by_user)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Ops User",
        dataIndex: "ops_user",
        key: "ops_user",
        width: 130,
        sorter: (a, b) =>
          a.ops_user && b.ops_user
            ? a.ops_user.localeCompare(b.ops_user)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: "Ballast Speed",
      //   dataIndex: "ballast_spd",
      //   key: "ballast_spd",
      //   width: 120,
      //   sorter: (a, b) =>
      //     a.ballast_spd && b.ballast_spd
      //       ? Number(a.ballast_spd) - Number(b.ballast_spd)
      //       : 1,

      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Daily Profit",
      //   dataIndex: "daily_profit",
      //   key: "daily_profit",
      //   width: 170,

      //   sorter: (a, b) =>
      //     a.daily_profit && b.daily_profit
      //       ? parseFloat(a.daily_profit.replace(",", "")) -
      //         parseFloat(b.daily_profit.replace(",", ""))
      //       : a.daily_profit.replace(",", "") &&
      //         !b.daily_profit.replace(",", "")
      //         ? -1
      //         : b.daily_profit.replace(",", "") &&
      //           !a.daily_profit.replace(",", "")
      //           ? 1
      //           : 0,
      //   render: (text) => {
      //     return {
      //       props: {
      //         style: { color: "#28a745" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Laden Speed",
      //   dataIndex: "laden_spd",
      //   key: "laden_spd",
      //   width: 150,
      //   sorter: (a, b) =>
      //     a.laden_spd && b.laden_spd
      //       ? Number(a.laden_spd) - Number(b.laden_spd)
      //       : 1,

      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Charterer",
      //   dataIndex: "charterer_name",
      //   key: "charterer_name",
      //   width: 120,
      //   sorter: (a, b) =>
      //     (typeof a.charterer_name !== "undefined"
      //       ? a.charterer_name.length
      //       : 0) -
      //     (typeof b.charterer_name !== "undefined"
      //       ? b.charterer_name.length
      //       : 0),
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#3bb812" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
    ],
  },
  "single-cargo-list": {
    tableheads: [
      // {
      //   title: "Cargo Contract ID",
      //   dataIndex: "cargo_contract_id",
      //   key: "cargo_contract_id",
      //   width: 160,
      //   //  sorter: (a, b) =>
      //   //    a.cargo_contract_id && b.cargo_contract_id
      //   //      ? a.cargo_contract_id.length - b.cargo_contract_id.length
      //   //      : 1,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      {
        title: "Cargo Contract ID",
        dataIndex: "cargo_contract_id",
        key: "cargo_contract_id",
        width: 160,
        //  sorter: (a, b) =>
        //    a.cargo_contract_id && b.cargo_contract_id
        //      ? a.cargo_contract_id.length - b.cargo_contract_id.length
        //      : 1,
        render: (text, record) => {
          let clr =
            record.status_name === "FIXED"
              ? "#e11010"
              : record.status_name === "SCHEDULE"
              ? "#6ecb0c"
              : "blue";

          return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
        },
      },

      {
        title: "COA(cargo) id",
        dataIndex: "cargo_coa",
        key: "cargo_coa",
        width: 150,
        sorter: (a, b) =>
          a.cargo_contract_id.length - b.cargo_contract_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: 'VC-Purchage ID',
      //   dataIndex: 'vc_purchage_id',
      //   key: 'vc_purchage_id',
      //   width: 150
      // },

      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        width: 130,
        sorter: (a, b) =>
          a.status_name && b.status_name
            ? a.status_name.length - b.status_name.length
            : 1,
        showSorterTooltip: false,
        render: (text) => {
          let clr;

          if (text === "FIXED") {
            clr = "#e11010";
          }
          if (text === "DRAFT") {
            clr = "blue";
          }
          if (text === "SCHEDULE") {
            clr = "#6ecb0c";
          }

          const style = {
            fontWeight: "600",
            color: clr,
          };

          return <span style={style}>{text}</span>;
        },
        // render: (tags) => (
        //   <span>
        //     {tags ? (
        //       <Tag
        //         color={
        //           tags === "SCHEDULE"
        //             ? "#28a745"
        //             : tags === "FIXED"
        //             ? "#81d742"
        //             : tags === "ENQUIRY"
        //             ? "#9e9e9e"
        //             : "#9e9e9e"
        //         }
        //         key={tags}
        //       >
        //         {tags}
        //       </Tag>
        //     ) : (
        //       "N/A"
        //     )}
        //   </span>
        // ),
      },

      {
        title: "Charterer",
        dataIndex: "charterer_name",
        key: "charterer_name",
        width: 150,
        sorter: (a, b) =>
          a.charterer_name && b.charterer_name
            ? a.charterer_name.localeCompare(b.charterer_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Contact Type',
      //   dataIndex: 'contact_type',
      //   key: 'contact_type',
      //   width: 150
      // },

      // {
      //   title: 'Cargo',
      //   dataIndex: 'cargo',
      //   key: 'cargo',
      //   width: 150
      // },

      // {
      //   title: 'Status',
      //   dataIndex: 'status_name',
      //   key: 'status_name',
      //   width: 150
      // },
      {
        title: "CP Date",
        dataIndex: "cp_date",
        key: "cp_date",
        width: 150,
        sorter: (a, b) => moment(a.cp_date).unix() - moment(b.cp_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
        width: 150,
        sorter: (a, b) =>
          a.company && b.company ? a.company.localeCompare(b.company) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Freight Via",
        dataIndex: "freight_via",
        key: "freight_via",
        width: 150,
        sorter: (a, b) =>
          a.freight_via && b.freight_via
            ? a.freight_via.localeCompare(b.freight_via)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Freight Type",
        dataIndex: "freight_type",
        key: "freight_type",
        width: 150,
        sorter: (a, b) =>
          a.freight_type && b.freight_type
            ? a.freight_type.localeCompare(b.freight_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Frt rate",
        dataIndex: "freight_rate",
        key: "freight_rate",
        width: 150,
        sorter: (a, b) =>
          a.freight_rate && b.freight_rate
            ? parseFloat(a.freight_rate.replace(",", "")) -
              parseFloat(b.freight_rate.replace(",", ""))
            : a.freight_rate.replace(",", "") &&
              !b.freight_rate.replace(",", "")
            ? -1
            : b.freight_rate.replace(",", "") &&
              !a.freight_rate.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "CP Qty",
        dataIndex: "cp_qty",
        key: "cp_qty",
        width: 150,
        sorter: (a, b) =>
          a.cp_qty && b.cp_qty
            ? parseFloat(a.cp_qty.replace(",", "")) -
              parseFloat(b.cp_qty.replace(",", ""))
            : a.cp_qty.replace(",", "") && !b.cp_qty.replace(",", "")
            ? -1
            : b.cp_qty.replace(",", "") && !a.cp_qty.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Unit',
      //   dataIndex: 'unit_name',
      //   key: 'unit_name',
      //   width: 150
      // },

      {
        title: "Trade Area",
        dataIndex: "trade_area",
        key: "trade_area",
        width: 150,
        sorter: (a, b) =>
          a.trade_area && b.trade_area
            ? a.trade_area.localeCompare(b.trade_area)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Load Port',
      //   dataIndex: 'load_port',
      //   key: 'load_port',
      //   width: 150
      // },

      // {
      //   title: 'L Term',
      //   dataIndex: 'l_term',
      //   key: 'l_term',
      //   width: 150
      // },

      // {
      //   title: 'Disch port',
      //   dataIndex: 'disch_port',
      //   key: 'disch_port',
      //   width: 150
      // },

      // {
      //   title: 'D. Term',
      //   dataIndex: 'd_term',
      //   key: 'd_term',
      //   width: 150
      // },

      // {
      //   title: 'Nominated Vessel',
      //   dataIndex: 'nominated_vessel',
      //   key: 'nominated_vessel',
      //   width: 150
      // },

      {
        title: "Voyage No",
        dataIndex: "voyage",
        key: "voyage",
        width: 150,
        sorter: (a, b) =>
          a.voyage && b.voyage ? a.voyage.length - b.voyage.length : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Laycan From",
        dataIndex: "laycan_from",
        key: "laycan_from",
        width: 150,
        sorter: (a, b) =>
          moment(a.laycan_from).unix() - moment(b.laycan_from).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Laycan to",
        dataIndex: "laycan_to",
        key: "laycan_to",
        width: 150,
        sorter: (a, b) =>
          moment(a.laycan_to).unix() - moment(b.laycan_to).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Option%',
      //   dataIndex: 'option',
      //   key: 'option',
      //   width: 150
      // },

      // {
      //   title: 'Option Type',
      //   dataIndex: 'option_type',
      //   key: 'option_type',
      //   width: 150
      // },

      {
        title: "Load Dem",
        dataIndex: "load_dem",
        key: "load_dem",
        width: 150,
        sorter: (a, b) =>
          a.load_dem && b.load_dem
            ? parseFloat(a.load_dem.replace(",", "")) -
              parseFloat(b.load_dem.replace(",", ""))
            : a.load_dem.replace(",", "") && !b.load_dem.replace(",", "")
            ? -1
            : b.load_dem.replace(",", "") && !a.load_dem.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Discharge Dem",
        dataIndex: "discharge_dem",
        key: "discharge_dem",
        width: 150,
        sorter: (a, b) =>
          a.discharge_dem && b.discharge_dem
            ? parseFloat(a.discharge_dem.replace(",", "")) -
              parseFloat(b.discharge_dem.replace(",", ""))
            : a.discharge_dem.replace(",", "") &&
              !b.discharge_dem.replace(",", "")
            ? -1
            : b.discharge_dem.replace(",", "") &&
              !a.discharge_dem.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },
  "voyage-fix-list": {
    tableheads: [
      {
        title: "Fixture ID",
        dataIndex: "fixture_no",
        key: "fixture_no",
        width: 100,
        sorter: (a, b) => a.id.length - b.id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Fixture date",
        dataIndex: "fixture_date",
        key: "fixture_date",
        width: 120,
        sorter: (a, b) => a.fixture_date.length - b.fixture_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Estimate ID",
        dataIndex: "estimate_id",
        key: "estimate_id",
        width: 120,
        sorter: (a, b) => a.estimate_id.length - b.estimate_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 130,
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Ops User",
        dataIndex: "ops_user",
        key: "ops_user",
        width: 100,
        sorter: (a, b) => a.ops_user.length - b.ops_user.length,
        showSorterTooltip: false,
      },
      {
        title: "Contract type",
        dataIndex: "contract_type",
        key: "contract_type",
        width: 130,
        sorter: (a, b) => a.contract_type.length - b.contract_type.length,
        showSorterTooltip: false,
      },
      {
        title: "Cargo Name",
        dataIndex: "cargo_name",
        key: "cargo_name",
        width: 120,
        sorter: (a, b) => a.cargo_name.length - b.cargo_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "CP quantity",
        dataIndex: "c_p_qty",
        key: "c_p_qty",
        width: 130,
        sorter: (a, b) => a.c_p_qty.length - b.c_p_qty.length,
        showSorterTooltip: false,
      },
      {
        title: "Counterparty",
        dataIndex: "counter_party",
        key: "counter_party",
        width: 130,
        sorter: (a, b) => a.counter_party.length - b.counter_party.length,
        showSorterTooltip: false,
      },
      {
        title: "My company",
        dataIndex: "my_company_name",
        key: "my_company_name",
        width: 130,
        sorter: (a, b) => a.my_company_name.length - b.my_company_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Voyage Status",
        dataIndex: "voyage_status",
        key: "voyage_status",
        width: 130,
        sorter: (a, b) => a.voyage_status.length - b.voyage_status.length,
        showSorterTooltip: false,
      },
    ],
  },
  "voyage-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "DWF (%)",
        dataIndex: "dwf",
        key: "dwf",
        width: 120,
        sorter: (a, b) => a.dwf.length - b.dwf.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "vm_status1",
        key: "vm_status1",
        width: 130,
        sorter: (a, b) => a.vm_status1.length - b.vm_status1.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            {tags ? (
              <Tag
                color={
                  tags === "SCHEDULED"
                    ? "#28a745"
                    : tags === "DELIVERED"
                    ? "#01bcd4"
                    : tags === "REDELIVERED"
                    ? "#0726ff"
                    : tags === "COMPLETED"
                    ? "#ff0000"
                    : tags === "FIX"
                    ? "#81d742"
                    : tags === "COMMENCED"
                    ? "#faad14"
                    : tags === "DRAFT"
                    ? "#fadb14"
                    : "#9e9e9e"
                }
                key={tags}
              >
                {tags}
              </Tag>
            ) : (
              "N/A"
            )}
          </span>
        ),
      },
      {
        title: "Voyage manager Type",
        dataIndex: "ops_type",
        key: "ops_type",
        width: 120,
        sorter: (a, b) => a.ops_type.length - b.ops_type.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 130,
        sorter: (a, b) => a.tci_code.length - b.tci_code.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) => a.commence_date.length - b.commence_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Completing Date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 150,
        sorter: (a, b) => a.completing_date.length - b.completing_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "voyage-manager-list": {
    tableheads: [
      {
        title: "Ops Type",
        dataIndex: "ops_type",
        key: "ops_type",
        width: 120,
        sorter: (a, b) => a.ops_type.length - b.ops_type.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Code / Voy No.",
        dataIndex: "vessel_code",
        key: "vessel_code",

        children: [
          {
            title: "Vessel Code",
            dataIndex: "vessel_code",
            key: "vessel_code",
            width: 130,
            sorter: (a, b) =>
              a.vessel_code && b.vessel_code
                ? a.vessel_code.localeCompare(b.vessel_code)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                props: {
                  style: { color: "#009dc4" },
                },
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "Voy No.",
            dataIndex: "voyage_number",
            key: "voyage_number",
            width: 120,
            sorter: (a, b) =>
              a.voyage_number && b.voyage_number
                ? a.voyage_number.localeCompare(b.voyage_number)
                : null,
            showSorterTooltip: false,
            render: (text, record) => {
              let clr =
                record.vm_status1 === "SCHEDULED"
                  ? "#6ecb0c"
                  : record.vm_status1 === "COMMENCED"
                  ? "blue"
                  : record.vm_status1 === "CLOSED"
                  ? "#e11010"
                  : "orange";
              return (
                <span style={{ color: clr, fontWeight: "600" }}>{text}</span>
              );
            },
            // render(text) {
            //   return {
            //     children: <div>{text ? text : "N/A"}</div>,
            //   };
            // },
          },
        ],
      },
      {
        title: "TCI Code",
        dataIndex: "tci_code",
        key: "tci_code",
        width: 130,
        sorter: (a, b) =>
          a.tci_code && b.tci_code
            ? a.tci_code.localeCompare(b.tci_code)
            : null,
        showSorterTooltip: false,
        render: (text, record) => {
          return {
            props: {
              style: { color: "#6ecb0c", cursor: "pointer" },
            },
            children: (
              <TableModal
                text={text}
                type={"tci"}
                record={record}
                id={record.tci_code}
              />
            ),
            // children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Fixture Number',
      //   dataIndex: 'fixture_num',
      //   key: 'fixture_num',
      //   width: 150,
      //   sorter: (a, b) => a.fixture_num.length - b.fixture_num.length,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : 'N/A'}</div>,
      //     };
      //   },
      // },
      {
        title: "Daily Rate",
        dataIndex: "tci_d_hire",
        key: "tci_d_hire",
        width: 120,
        sorter: (a, b) =>
          a.tci_d_hire && b.tci_d_hire
            ? parseFloat(a.tci_d_hire.replace(",", "")) -
              parseFloat(b.tci_d_hire.replace(",", ""))
            : a.tci_d_hire.replace(",", "") && !b.tci_d_hire.replace(",", "")
            ? -1
            : b.tci_d_hire.replace(",", "") && !a.tci_d_hire.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "DWF (%)",
        dataIndex: "dwf",
        key: "dwf",
        width: 120,
        sorter: (a, b) =>
          a.dwf && b.dwf
            ? parseFloat(a.dwf.replace(",", "")) -
              parseFloat(b.dwf.replace(",", ""))
            : a.dwf.replace(",", "") && !b.dwf.replace(",", "")
            ? -1
            : b.dwf.replace(",", "") && !a.dwf.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Add / Bro(%)",
        dataIndex: "add_percentage",
        key: "add_percentage",

        children: [
          {
            title: "Add(%)",
            dataIndex: "add_percentage",
            key: "add_percentage",
            width: 100,
            sorter: (a, b) =>
              a.add_percentage && b.add_percentage
                ? Number(a.add_percentage) - Number(b.add_percentage)
                : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "Bro(%)",
            dataIndex: "bro_percentage",
            key: "bro_percentage",
            width: 100,
            sorter: (a, b) =>
              a.bro_percentage && b.bro_percentage
                ? Number(a.bro_percentage) - Number(b.bro_percentage)
                : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },

      {
        title: "Trade Area",
        dataIndex: "trade_area_name",
        key: "trade_area_name",
        width: 120,
        sorter: (a, b) =>
          a.trade_area_name && b.trade_area_name
            ? a.trade_area_name.localeCompare(b.trade_area_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Routing Type",
      //   dataIndex: "routing_type_name",
      //   key: "routing_type_name",
      //   width: 150,
      //   sorter: (a, b) =>
      //     a.routing_type_name && b.routing_type_name
      //       ? a.routing_type_name.localeCompare(b.routing_type_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "C/P Date",
        dataIndex: "cp_date",
        key: "cp_date",
        width: 120,
        sorter: (a, b) => moment(a.cp_date).unix() - moment(b.cp_date).unix(),
        showSorterTooltip: false,
        // render(text) {
        //   return {
        //     children: <div>{text ? text : "N/A"}</div>,
        //   };
        // },
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "vm_status1",
        key: "vm_status1",
        width: 130,
        sorter: (a, b) => a.vm_status1.length - b.vm_status1.length,
        showSorterTooltip: false,
        render: (text) => {
          let clr;

          if (text === "CLOSED") {
            clr = "#e11010";
          }
          if (text === "COMMENCED") {
            clr = "blue";
          }
          if (text === "SCHEDULED") {
            clr = "#6ecb0c";
          }
          if (text === "COMPLETED") {
            clr = "orange";
          }

          const style = {
            fontWeight: "600",
            color: clr,
          };

          return <span style={style}>{text}</span>;
        },
        // render: (tags) => (
        //   <span>
        //     {tags ? (
        //       <Tag
        //         color={
        //           tags === "SCHEDULED"
        //             ? "#28a745"
        //             : tags === "DELIVERED"
        //             ? "#01bcd4"
        //             : tags === "REDELIVERED"
        //             ? "#0726ff"
        //             : tags === "COMPLETED"
        //             ? "#ff0000"
        //             : tags === "FIX"
        //             ? "#81d742"
        //             : tags === "COMMENCED"
        //             ? "#faad14"
        //             : tags === "DRAFT"
        //             ? "#fadb14"
        //             : "#9e9e9e"
        //         }
        //         key={tags}
        //       >
        //         {tags}
        //       </Tag>
        //     ) : (
        //       "N/A"
        //     )}
        //   </span>
        // ),
      },
      {
        title: "Name (Trader / Ops User)",
        dataIndex: "user_name",
        key: "user_name",

        children: [
          {
            title: "Trader",
            dataIndex: "user_name",
            key: "user_name",
            width: 100,
            sorter: (a, b) =>
              a.user_name && b.user_name
                ? a.user_name.localeCompare(b.user_name)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                props: {
                  style: { color: "#1d565c" },
                },
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "Ops User",
            dataIndex: "ops_user",
            key: "ops_user",
            width: 120,
            sorter: (a, b) =>
              a.ops_user && b.ops_user
                ? a.ops_user.length - b.ops_user.length
                : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },
      {
        title: "My Company / LOB",
        dataIndex: "my_company_name",
        key: "my_company_name",

        children: [
          {
            title: "My Company",
            dataIndex: "my_company_name",
            key: "my_company_name",
            width: 140,
            sorter: (a, b) =>
              a.my_company_name && b.my_company_name
                ? a.my_company_name.localeCompare(b.my_company_name)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                props: {
                  style: { color: "#3bb812" },
                },
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "LOB",
            dataIndex: "company_lob_name",
            key: "company_lob_name",
            width: 100,
            sorter: (a, b) =>
              a.company_lob_name && b.company_lob_name
                ? a.company_lob_name.localeCompare(b.company_lob_name)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.commence_date).unix() - moment(b.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Completing Date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.completing_date).unix() - moment(b.completing_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Total Days",
        dataIndex: "total_days",
        key: "total_days",
        width: 130,
        sorter: (a, b) =>
          a.total_days && b.total_days
            ? Number(a.total_days) - Number(b.total_days)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "port-performance-list": {
    tableheads: [
      {
        title: "Port Name",
        dataIndex: "port",
        key: "port",
        width: 120,
        sorter: (a, b) => a?.port?.length - b?.port?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 150,
        sorter: (a, b) =>
          a?.vessel_name && b?.vessel_name
            ? a?.vessel_name?.localeCompare(b?.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: "Vessel Type",
      //   dataIndex: "vessel_type",
      //   key: "vessel_type",
      //   width: 120,

      //   sorter: (a, b) =>
      //     a.vessel_type && b.vessel_type
      //       ? a.vessel_type.localeCompare(b.vessel_type)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Vessel Sub Type",
      //   dataIndex: "vessel_sub_type",
      //   key: "vessel_sub_type",
      //   width: 120,

      //   sorter: (a, b) =>
      //     a.vessel_sub_type && b.vessel_sub_type
      //       ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      {
        title: "Voyage No.",
        dataIndex: "estimate_id",
        key: "voyage_number",
        width: 120,
        sorter: (a, b) =>
          a?.voyage_number && b?.voyage_number
            ? a?.voyage_number?.localeCompare(b?.voyage_number)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#009dc4",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
            // children: <div>{text}</div>,
          };
        },
        // render(text) {
        //   return {
        //     children: <div>{text ? text : "N/A"}</div>,
        //   };
        // },
      },

      {
        title: "Port Bunker Received",
        dataIndex: "port_bunker",
        key: "port_bunker",

        children: [
          {
            title: "IFO",
            dataIndex: "r_ifo",
            key: "r_ifo",
            width: 100,
            // sorter: (a, b) =>
            //   a.r_ifo && b.r_ifo ? Number(a.r_ifo) - Number(b.r_ifo) : 1,
            // showSorterTooltip: false,
            render(text, row) {
              return {
                children: <div>{text}</div>,
              };
            },
          },

          {
            title: "VLSFO",
            dataIndex: "r_vlsfo",
            key: "vlsfo",
            width: 100,
            sorter: (a, b) =>
              a?.vlsfo && b.vlsfo ? Number(a?.vlsfo) - Number(b?.vlsfo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "LSMGO",
            dataIndex: "r_lsmgo",
            key: "r_lsmgo",
            width: 100,
            sorter: (a, b) =>
              a?.lsmgo && b?.lsmgo ? Number(a?.lsmgo) - Number(b?.lsmgo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "MGO",
            dataIndex: "r_mgo",
            key: "r_mgo",
            width: 100,
            sorter: (a, b) =>
              a?.mgo && b?.mgo ? Number(a?.mgo) - Number(b?.mgo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "ULSFO",
            dataIndex: "r_ulsfo",
            key: "r_ulsfo",
            width: 100,
            sorter: (a, b) =>
              a?.ulsfo && b?.ulsfo ? Number(a?.ulsfo) - Number(b?.ulsfo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },

      {
        title: "Port Cons. Fuel",
        dataIndex: "port_cons_fuel",
        key: "port_cons_fuel",

        children: [
          {
            title: "IFO",
            dataIndex: "pc_ifo",
            key: "ifo",
            width: 100,
            sorter: (a, b) =>
              a?.ifo && b?.ifo ? Number(a?.ifo) - Number(b?.ifo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "VLSFO",
            dataIndex: "pc_vlsfo",
            key: "vlsfo",
            width: 100,
            sorter: (a, b) =>
              a?.vlsfo && b?.vlsfo ? Number(a?.vlsfo) - Number(b?.vlsfo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "LSMGO",
            dataIndex: "pc_lsmgo",
            key: "lsmgo",
            width: 100,
            sorter: (a, b) =>
              a?.lsmgo && b?.lsmgo ? Number(a?.lsmgo) - Number(b?.lsmgo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "MGO",
            dataIndex: "pc_mgo",
            key: "mgo",
            width: 100,
            sorter: (a, b) =>
              a?.mgo && b?.mgo ? Number(a?.mgo) - Number(b?.mgo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },

          {
            title: "ULSFO",
            dataIndex: "pc_ulsfo",
            key: "ulsfo",
            width: 100,
            sorter: (a, b) =>
              a?.ulsfo && b?.ulsfo ? Number(a?.ulsfo) - Number(b?.ulsfo) : 1,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },

      {
        title: "P Days",
        dataIndex: "days",
        key: "p_days",
        width: 120,
        sorter: (a, b) => (a.p_days && b.p_days ? a.p_days - b.p_days : 1),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "XPD",
        dataIndex: "xpd",
        key: "xpd",
        width: 120,
        sorter: (a, b) => (a.xpd && b.xpd ? a.xpd - b.xpd : 1),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Ttl Port Days",
        dataIndex: "t_port_days",
        key: "t_port_days",
        width: 120,
        sorter: (a, b) =>
          a.total_port_days && b.total_port_days
            ? a.total_port_days - b.total_port_days
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Port Expense",
        dataIndex: "p_exp",
        key: "port_expense",
        width: 120,
        sorter: (a, b) => (a.p_exp && b.p_exp ? a.p_exp : null),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "PDA Amount",
        dataIndex: "pda_amount",
        key: "pda_amount",
        width: 120,
        sorter: (a, b) => {
          // Function to remove commas and convert the string to a number
          const parseAmount = (amount) => parseFloat(amount.replace(/,/g, ""));

          const amountA = a.pda_amount ? parseAmount(a.pda_amount) : 0;
          const amountB = b.pda_amount ? parseAmount(b.pda_amount) : 0;

          // Return the comparison
          return amountA - amountB;
        },
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "FDA Amount",
        dataIndex: "fda_amount",
        key: "fda_amount",
        width: 120,
        sorter: (a, b) => {
          // Convert the string to a floating-point number
          const amountA = a.fda_amount ? parseFloat(a.fda_amount) : 0;
          const amountB = b.fda_amount ? parseFloat(b.fda_amount) : 0;

          // Return the comparison
          return amountA - amountB;
        },
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "voyage-cargo-list": {
    tableheads: [
      // {
      //   title: 'Booking No.',
      //   dataIndex: 'booking_no',
      //   key: 'booking_no',
      //   width: 120,
      //   sorter: (a, b) => a.booking_no.length - b.booking_no.length,
      // },
      // {
      //   title: "Cargo COA",
      //   dataIndex: "cargo_coa",
      //   key: "cargo_coa",
      //   width: 120,
      //   //  sorter: (a, b) =>
      //   //    a.cargo_coa && b.cargo_coa
      //   //      ? a.cargo_coa.length - b.cargo_coa.length
      //   //      : 1,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "VC-Purchase ID",
        dataIndex: "vc_purchase_id",
        key: "vc_purchase_id",
        width: 160,
        sorter: (a, b) =>
          a.cargo_contract_name && b.cargo_contract_name
            ? a.cargo_contract_name.length - b.cargo_contract_name.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Cargo Contract ID",
        dataIndex: "cargo_contract_id",
        key: "cargo_contract_id",
        width: 160,
        sorter: (a, b) =>
          a.cargo_contract_name && b.cargo_contract_name
            ? a.cargo_contract_name.length - b.cargo_contract_name.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Cargo Status",
      //   dataIndex: "c_status_name",
      //   key: "c_status_name",
      //   width: 130,
      //   sorter: (a, b) =>
      //     a.c_status_name && b.c_status_name
      //       ? a.c_status_name.length - b.c_status_name.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render: (tags) => (
      //     <span>
      //       {tags ? (
      //         <Tag
      //           color={
      //             tags === "Enquiry"
      //               ? "#01bcd4"
      //               : tags === "FIXED"
      //               ? "#81d742"
      //               : "#9e9e9e"
      //           }
      //           key={tags}
      //         >
      //           {tags}
      //         </Tag>
      //       ) : (
      //         "N/A"
      //       )}
      //     </span>
      //   ),
      // },
      // {
      //   title: "Charterer",
      //   dataIndex: "charterer_name",
      //   key: "charterer_name",
      //   width: 110,
      //   sorter: (a, b) =>
      //     a.charterer_name && b.charterer_name
      //       ? a.charterer_name.localeCompare(b.charterer_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "Company",
        dataIndex: "my_company",
        key: "my_company",
        width: 110,
        sorter: (a, b) =>
          a.my_companyc_status && b.my_companyc_status
            ? a.my_companyc_status.localeCompare(b.my_companyc_status)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Conf Date",
      //   dataIndex: "conf_date_gmt",
      //   key: "conf_date_gmt",
      //   width: 130,
      //   sorter: (a, b) =>
      //     a.conf_date_gmt && b.conf_date_gmt
      //       ? a.conf_date_gmt.length - b.conf_date_gmt.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#0726ff" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "CP Date From",
      //   dataIndex: "cp_date_from",
      //   key: "cp_date_from",
      //   width: 150,
      //   sorter: (a, b) =>
      //     a.cp_date_from && b.cp_date_from
      //       ? a.cp_date_from.length - b.cp_date_from.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#0726ff" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "CP Date",
        dataIndex: "cp_date",
        key: "cp_date",
        width: 150,
        sorter: (a, b) =>
          a.cp_date && b.cp_date
            ? a.cp_date.length - b.cp_date.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "COA-VC Purch ID",
        dataIndex: "coa_vc_purchase_id",
        key: "coa_vc_purchase_id",
        width: 150,
        sorter: (a, b) =>
          a.cp_date_from && b.cp_date_from
            ? a.cp_date_from.length - b.cp_date_from.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "CP Date To",
      //   dataIndex: "cp_date_to",
      //   key: "cp_date_to",
      //   width: 120,
      //   sorter: (a, b) =>
      //     a.cp_date_to && b.cp_date_to
      //       ? a.cp_date_to.length - b.cp_date_to.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#0726ff" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      // {
      //   title: "CP Draft",
      //   dataIndex: "cp_draft_m",
      //   key: "cp_draft_m",
      //   width: 100,
      //   sorter: (a, b) =>
      //     a.cp_draft_m && b.cp_draft_m
      //       ? Number(a.cp_draft_m) - Number(b.cp_draft_m)
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "CP Place",
        dataIndex: "cp_place",
        key: "cp_place",
        width: 120,
        //  sorter: (a, b) =>
        //  a.cp_place && b.cp_place
        // ? a.cp_place.localeCompare(b.cp_place)
        // : null,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "CP Quantity",
        dataIndex: "cp_qty",
        key: "cp_qty",
        width: 130,
        sorter: (a, b) =>
          a.cp_qty && b.cp_qty
            ? parseFloat(a.cp_qty.replace(",", "")) -
              parseFloat(b.cp_qty.replace(",", ""))
            : a.cp_qty.replace(",", "") && !b.cp_qty.replace(",", "")
            ? -1
            : b.cp_qty.replace(",", "") && !a.cp_qty.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "CP Unit",
        dataIndex: "cp_unit",
        key: "cp_unit",
        width: 100,
        sorter: (a, b) =>
          a.cp_unit && b.cp_unit ? Number(a.cp_unit) - Number(b.cp_unit) : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
        width: 100,
        sorter: (a, b) =>
          a.currency && b.currency
            ? a.currency.localeCompare(b.currency)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Contract Type",
        dataIndex: "contract_type",
        key: "contract_type",
        width: 100,
        sorter: (a, b) =>
          a.currency && b.currency
            ? a.currency.localeCompare(b.currency)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        width: 100,
        sorter: (a, b) =>
          a.currency && b.currency
            ? a.currency.localeCompare(b.currency)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Demurrage Via",
      //   dataIndex: "demurrage_via",
      //   key: "demurrage_via",
      //   width: 150,
      //   sorter: (a, b) =>
      //     a.demurrage_via && b.demurrage_via
      //       ? a.demurrage_via.length - b.demurrage_via.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      // {
      //   title: "Estimate Id",
      //   dataIndex: "estimate_id",
      //   key: "estimate_id",
      //   width: 120,
      //   sorter: (a, b) =>
      //     a.estimate_id && b.estimate_id
      //       ? a.estimate_id.length - b.estimate_id.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "Exchange Rate",
        dataIndex: "exchange_rate",
        key: "exchange_rate",
        width: 150,
        sorter: (a, b) =>
          a.exchange_rate && b.exchange_rate
            ? a.exchange_rate.length - b.exchange_rate.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Freight Rate",
        dataIndex: "freight_rate",
        key: "freight_rate",
        width: 130,
        sorter: (a, b) =>
          a.freight_rate && b.freight_rate
            ? parseFloat(a.freight_rate.replace(",", "")) -
              parseFloat(b.freight_rate.replace(",", ""))
            : a.freight_rate.replace(",", "") &&
              !b.freight_rate.replace(",", "")
            ? -1
            : b.freight_rate.replace(",", "") &&
              !a.freight_rate.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Freight Type",
        dataIndex: "freight_type_name",
        key: "freight_type_name",
        width: 130,
        sorter: (a, b) => a.freight_type.length - b.freight_type.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Freight Via",
        dataIndex: "freight_bill_via_name",
        key: "freight_bill_via_name",
        width: 120,
        sorter: (a, b) =>
          a.freight_bill_via_name && b.freight_bill_via_name
            ? a.freight_bill_via_name.length - b.freight_bill_via_name.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "FT3_MT",
      //   dataIndex: "ft3_mt",
      //   key: "ft3_mt",
      //   width: 100,
      //   sorter: (a, b) => a.ft3_mt.length - b.ft3_mt.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      // {
      //   title: "Group Id",
      //   dataIndex: "group_id",
      //   key: "group_id",
      //   width: 100,
      //   sorter: (a, b) => a.group_id.length - b.group_id.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "Laycan From",
        dataIndex: "laycan_from",
        key: "laycan_from",
        width: 150,
        sorter: (a, b) =>
          moment(a.laycan_from).unix() - moment(b.laycan_from).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Laycan To",
        dataIndex: "laycan_to",
        key: "laycan_to",
        width: 130,
        sorter: (a, b) =>
          moment(a.laycan_to).unix() - moment(b.laycan_to).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Lob",
        dataIndex: "lob",
        key: "lob",
        width: 100,
        sorter: (a, b) => (a.lob && b.lob ? a.lob.localeCompare(b.lob) : null),
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "M3_MT",
      //   dataIndex: "m3_mt",
      //   key: "m3_mt",
      //   width: 100,
      //   sorter: (a, b) => a.m3_mt.length - b.m3_mt.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "Max Quantity",
        dataIndex: "max_qty",
        key: "max_qty",
        width: 150,
        sorter: (a, b) =>
          a.max_qty && b.max_qty
            ? parseFloat(a.max_qty.replace(",", "")) -
              parseFloat(b.max_qty.replace(",", ""))
            : a.max_qty.replace(",", "") && !b.max_qty.replace(",", "")
            ? -1
            : b.max_qty.replace(",", "") && !a.max_qty.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Min INV Quatity",
      //   dataIndex: "min_inv_qty",
      //   key: "min_inv_qty",
      //   width: 150,
      //   sorter: (a, b) => {
      //     const aQty = a.min_inv_qty
      //       ? parseFloat(a.min_inv_qty.replace(",", ""))
      //       : 0;
      //     const bQty = b.min_inv_qty
      //       ? parseFloat(b.min_inv_qty.replace(",", ""))
      //       : 0;

      //     return aQty - bQty;
      //   },
      //   showSorterTooltip: false,
      //   render: (text) => {
      //     return {
      //       props: {
      //         style: { color: "#28a745" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Min INV Quantity",
        dataIndex: "min_inv_qty",
        key: "min_inv_qty",
        width: 150,
        sorter: (a, b) => {
          const aQty = a.min_inv_qty
            ? parseFloat(a.min_inv_qty.replace(",", ""))
            : 0;
          const bQty = b.min_inv_qty
            ? parseFloat(b.min_inv_qty.replace(",", ""))
            : 0;

          return aQty - bQty;
        },
        showSorterTooltip: false,
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Mix Quantity",
        dataIndex: "mix_qty",
        key: "mix_qty",
        width: 150,
        sorter: (a, b) =>
          a.mix_qty && b.mix_qty ? a.mix_qty.length - b.mix_qty.length : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Nominated Vessel",
        dataIndex: "nomiated_vessel_nane",
        key: "nomiated_vessel_nane",
        width: 170,
        sorter: (a, b) =>
          a.nomiated_vessel_nane && b.nomiated_vessel_nane
            ? a.nomiated_vessel_nane.localeCompare(b.nomiated_vessel_nane)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Option Percentage",
        dataIndex: "option_percentage",
        key: "option_percentage",
        width: 170,
        sorter: (a, b) =>
          a.option_percentage && b.option_percentage
            ? a.option_percentage.length - b.option_percentage.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Option Type",
      //   dataIndex: "option_type",
      //   key: "option_type",
      //   width: 130,
      //   sorter: (a, b) => a.option_type.length - b.option_type.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      {
        title: "Trade Area",
        dataIndex: "trade_area",
        key: "trade_area",
        width: 130,
        sorter: (a, b) =>
          a.trade_area && b.trade_area
            ? a.trade_area.localeCompare(b.trade_area)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 130,
        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: "Voyage",
      //   dataIndex: "voyage_no",
      //   key: "voyage_no",
      //   width: 100,
      //   sorter: (a, b) =>
      //     a.voyage_number && b.voyage_number
      //       ? a.voyage_number.length - b.voyage_number.length
      //       : 1,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
      // {
      //   title: "Voyage CP In Id",
      //   dataIndex: "voyage_cp_in_id",
      //   key: "voyage_cp_in_id",
      //   width: 150,
      //   sorter: (a, b) => a.voyage_cp_in_id.length - b.voyage_cp_in_id.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },
    ],
  },

  "tco-manager-list": {
    tableheads: [
      // {
      //   title: "TCO Code",
      //   dataIndex: "tc_code",
      //   key: "tc_code",
      //   width: 120,
      //   //  sorter: (a, b) => a.tc_code.length - b.tc_code.length,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      {
        title: "TCO Code",
        dataIndex: "tc_code",
        key: "tc_code",
        width: 120,
        //  sorter: (a, b) => a.tc_code.length - b.tc_code.length,
        render: (text, record) => {
          let clr =
            record.tco_status_name === "FIX"
              ? "#e11010"
              : record.tco_status_name === "SCHEDULED"
              ? "#6ecb0c"
              : "blue";

          return text ? (
            <a
              href={`#/TC-OUT/${text}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: clr,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {text}
            </a>
          ) : (
            "N/A"
          );
        },
      },

      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        width: 100,
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        key: "vessel_code",
        width: 120,
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "type_code",
        key: "type_code",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: 'Type',
      //   dataIndex: 'type',
      //   key: 'type',
      //   width: 80,
      //   sorter: (a, b) => a.type.length - b.type.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      // {
      //   title: 'DWT',
      //   dataIndex: 'dwt',
      //   key: 'dwt',
      //   width: 80,
      //   sorter: (a, b) => a.dwt.length - b.dwt.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      {
        title: "Bill Via",
        dataIndex: "bill_via_name",
        key: "bill_via_name",
        width: 80,
        sorter: (a, b) =>
          a.bill_via_name && b.bill_via_name
            ? a.bill_via_name.localeCompare(b.bill_via_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Company Lob",
        dataIndex: "company_lob_name",
        key: "company_lob_name",
        width: 140,
        sorter: (a, b) =>
          a.company_lob_name && b.company_lob_name
            ? a.company_lob_name.localeCompare(b.company_lob_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "tco_status_name",
        key: "tco_status_name",
        width: 80,
        sorter: (a, b) => a.tco_status_name.length - b.tco_status_name.length,
        showSorterTooltip: false,

        render: (text) => {
          let clr;

          if (text === "FIX") {
            clr = "#e11010";
          }
          if (text === "DRAFT") {
            clr = "blue";
          }
          if (text === "SCHEDULED") {
            clr = "#6ecb0c";
          }

          const style = {
            fontWeight: "600",
            color: clr,
          };

          return <span style={style}>{text}</span>;
        },

        // render: (tags) => (
        //   <span>
        //     {tags ? (
        //       <Tag
        //         color={
        //           tags === "SCHEDULED"
        //             ? "#28a745"
        //             : tags === "DELIVERED"
        //             ? "#01bcd4"
        //             : tags === "REDELIVERED"
        //             ? "#0726ff"
        //             : tags === "COMPLETED"
        //             ? "#ff0000"
        //             : tags === "FIX"
        //             ? "#81d742"
        //             : "#9e9e9e"
        //         }
        //         key={tags}
        //       >
        //         {tags}
        //       </Tag>
        //     ) : (
        //       "N/A"
        //     )}
        //   </span>
        // ),
      },
      {
        title: "Contract Type",
        dataIndex: "contract_type_name",
        key: "contract_type_name",
        width: 140,
        sorter: (a, b) =>
          a.contract_type_name && b.contract_type_name
            ? a.contract_type_name.localeCompare(b.contract_type_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Currency",
        dataIndex: "currency_exchange_rate_name",
        key: "currency_exchange_rate_name",
        width: 100,
        sorter: (a, b) =>
          a.currency_exchange_rate_name && b.currency_exchange_rate_name
            ? a.currency_exchange_rate_name.localeCompare(
                b.currency_exchange_rate_name
              )
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Trade Area",
        dataIndex: "trade_area_name",
        key: "trade_area_name1",
        width: 120,
        sorter: (a, b) =>
          a.trade_area_name && b.trade_area_name
            ? a.trade_area_name.localeCompare(b.trade_area_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Delivery Port",
        dataIndex: "delivery_port",
        key: "delivery_port",
        width: 120,
        sorter: (a, b) =>
          a.delivery_port && b.delivery_port
            ? a.delivery_port.localeCompare(b.delivery_port)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Delivery Date",
        dataIndex: "delivery_date",
        key: "delivery_date",
        width: 130,
        sorter: (a, b) =>
          moment(a.delivery_date).unix() - moment(b.delivery_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Redev. Port",
        dataIndex: "redelivery_port",
        key: "redelivery_port",
        width: 120,
        sorter: (a, b) =>
          a.redelivery_port && b.redelivery_port
            ? a.redelivery_port.localeCompare(b.redelivery_port)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Redev. Date",
        dataIndex: "redelivery_date",
        key: "redelivery_date",
        width: 120,
        sorter: (a, b) =>
          moment(a.redelivery_date).unix() - moment(b.redelivery_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: 'TCO Hire',
      //   dataIndex: 'tco_hire',
      //   key: 'tco_hire',
      //   width: 100,
      //   sorter: (a, b) => a.tco_hire.length - b.tco_hire.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      // {
      //   title: 'Voyage Type',
      //   dataIndex: 'voyage_type',
      //   key: 'voyage_type',
      //   width: 120,
      //   sorter: (a, b) => a.voyage_type.length - b.voyage_type.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      {
        title: "Charterer",
        dataIndex: "charterer_name",
        key: "charterer_name",
        width: 100,
        sorter: (a, b) => a.charterer_name.length - b.charterer_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Company",
        dataIndex: "company_fixed_with_name",
        key: "company_fixed_with_name",
        width: 100,
        sorter: (a, b) =>
          a.company_fixed_with_name.length - b.company_fixed_with_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: 'Next hire due',
      //   dataIndex: 'next_hire_due',
      //   key: 'next_hire_due',
      //   width: 130,
      //   sorter: (a, b) => a.next_hire_due.length - b.next_hire_due.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      {
        title: "Laycan From",
        dataIndex: "laycan_from",
        key: "laycan_from",
        width: 130,
        sorter: (a, b) =>
          moment(a.laycan_from).unix() - moment(b.laycan_from).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Laycan To",
        dataIndex: "laycan_to",
        key: "laycan_to",
        width: 120,
        sorter: (a, b) =>
          moment(a.laycan_to).unix() - moment(b.llaycan_to).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      // {
      //   title: 'IMO No',
      //   dataIndex: 'imo_no',
      //   key: 'imo_no',
      //   width: 90,
      //   sorter: (a, b) => a.imo_no.length - b.imo_no.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },

      // {
      //   title: 'TPC',
      //   dataIndex: 'tpc',
      //   key: 'tpc',
      //   width: 80,
      //   sorter: (a, b) => a.tpc.length - b.tpc.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      // {
      //   title: 'Route',
      //   dataIndex: 'route',
      //   key: 'route',
      //   width: 80,
      //   sorter: (a, b) => a.route.length - b.route.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
      // {
      //   title: 'Ownership Type',
      //   dataIndex: 'owner_type',
      //   key: 'owner_type',
      //   width: 140,
      //   sorter: (a, b) => a.owner_type.length - b.owner_type.length,
      //   render(text) {
      //     return {
      //       children: <div>{text?text:'N/A'}</div>,
      //     };
      //   },
      // },
    ],
  },
  "cargo-search-listing": {
    tableheads: [
      {
        title: "Cargo",
        dataIndex: "cargo",
        key: "cargo",
        width: 150,
        sorter: (a, b) => a.cargo.length - b.cargo.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Charterer",
        dataIndex: "charterer",
        key: "charterer",
        width: 150,
        sorter: (a, b) => a.charterer.length - b.charterer.length,
        showSorterTooltip: false,
      },
      {
        title: "Loading",
        dataIndex: "loading",
        key: "loading",
        width: 150,
        sorter: (a, b) => a.loading.length - b.loading.length,
        showSorterTooltip: false,
      },
      {
        title: "Discharging",
        dataIndex: "discharging",
        key: "discharging",
        width: 150,
        sorter: (a, b) => a.discharging.length - b.discharging.length,
        showSorterTooltip: false,
      },
      {
        title: "CP Qty",
        dataIndex: "cp_qty",
        key: "cp_qty",
        width: 150,
        sorter: (a, b) => a.cp_qty.length - b.cp_qty.length,
        showSorterTooltip: false,
      },
      {
        title: "Laycan From",
        dataIndex: "laycan_from",
        key: "laycan_from",
        width: 150,
        sorter: (a, b) => a.laycan_from.length - b.laycan_from.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Laycan To",
        dataIndex: "laycan_to",
        key: "laycan_to",
        width: 150,
        sorter: (a, b) => a.laycan_to.length - b.laycan_to.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo ID",
        dataIndex: "cargo_id",
        key: "cargo_id",
        width: 150,
        sorter: (a, b) => a.cargo_id.length - b.cargo_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo COA",
        dataIndex: "cargo_coa",
        key: "cargo_coa",
        width: 150,
        sorter: (a, b) => a.cargo_coa.length - b.cargo_coa.length,
        showSorterTooltip: false,
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
        width: 150,
        sorter: (a, b) => a.company.length - b.company.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Order No.",
        dataIndex: "order_no",
        key: "order_no",
        width: 150,
        sorter: (a, b) => a.order_no.length - b.order_no.length,
        showSorterTooltip: false,
      },
      {
        title: "Booking No.",
        dataIndex: "booking_no",
        key: "booking_no",
        width: 150,
        sorter: (a, b) => a.booking_no.length - b.booking_no.length,
      },
      {
        title: "Nom Vessel",
        dataIndex: "nom_vessel",
        key: "nom_vessel",
        width: 150,
        sorter: (a, b) => a.nom_vessel.length - b.nom_vessel.length,
        showSorterTooltip: false,
      },
      {
        title: "Trade Area",
        dataIndex: "trade_area",
        key: "trade_area",
        width: 150,
        sorter: (a, b) => a.trade_area.length - b.trade_area.length,
        showSorterTooltip: false,
      },
    ],
  },

  "list-coa-vci": {
    tableheads: [
      {
        title: "COA(VC) ID",
        dataIndex: "contract_id",
        key: "contract_id",
        width: 150,
        //  sorter: (a, b) => a.contract_id.length - b.contract_id.length,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Cargo Group",
        dataIndex: "cargo_name1",
        key: "cargo_name1",
        width: 150,
        sorter: (a, b) =>
          a.cargo_name1 && b.cargo_name1
            ? a.cargo_name1.length - b.cargo_name1.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Master Contract ID",
        dataIndex: "mst_contract",
        key: "mst_contract",
        width: 180,
        sorter: (a, b) =>
          a.mst_contract && b.mst_contract
            ? a.mst_contract.length - b.mst_contract.length
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "COA Qty",
        dataIndex: "coa_qty",
        key: "coa_qty",
        width: 150,
        sorter: (a, b) =>
          a.coa_qty && b.coa_qty
            ? parseFloat(a.coa_qty.replace(",", "")) -
              parseFloat(b.coa_qty.replace(",", ""))
            : a.coa_qty.replace(",", "") && !b.coa_qty.replace(",", "")
            ? -1
            : b.coa_qty.replace(",", "") && !a.coa_qty.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Owner Name",
        dataIndex: "owner_name",
        key: "owner_name",
        width: 150,
        sorter: (a, b) =>
          a.owner_name && b.owner_name
            ? a.owner_name.localeCompare(b.owner_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.start_date).unix() - moment(b.start_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
        width: 150,
        sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Reference No/PO",
        dataIndex: "reference_no",
        key: "reference_no",
        width: 180,
        sorter: (a, b) =>
          a.reference_no && b.reference_no
            ? a.reference_no.localeCompare(b.reference_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Freight Rate",
        dataIndex: "freight_rate",
        key: "freight_rate",
        width: 150,
        sorter: (a, b) =>
          a.freight_rate && b.freight_rate
            ? parseFloat(a.freight_rate.replace(",", "")) -
              parseFloat(b.freight_rate.replace(",", ""))
            : a.freight_rate.replace(",", "") &&
              !b.freight_rate.replace(",", "")
            ? -1
            : b.freight_rate.replace(",", "") &&
              !a.freight_rate.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "C/P Date",
        dataIndex: "cp_date",
        key: "cp_date",
        width: 150,
        sorter: (a, b) => moment(a.cp_date).unix() - moment(b.cp_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
    ],
  },

  "port-activity-lists": {
    tableheads: [
      {
        title: "Activities",
        dataIndex: "activities",
        key: "activities",
        sorter: (a, b) => a.activities.length - b.activities.length,
        showSorterTooltip: false,
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        sorter: (a, b) => a.remarks.length - b.remarks.length,
        showSorterTooltip: false,
      },
      {
        title: "Act Code",
        dataIndex: "act_code",
        key: "act_code",
        sorter: (a, b) => a.act_code.length - b.act_code.length,
        showSorterTooltip: false,
      },
      {
        title: "Dem",
        dataIndex: "dem",
        key: "dem",
        sorter: (a, b) => a.dem.length - b.dem.length,
        showSorterTooltip: false,
      },
      {
        title: "Utl",
        dataIndex: "utl",
        key: "utl",
        sorter: (a, b) => a.utl.length - b.utl.length,
        showSorterTooltip: false,
      },
      {
        title: "Delay",
        dataIndex: "delay",
        key: "delay",
        sorter: (a, b) => a.delay.length - b.delay.length,
        showSorterTooltip: false,
      },
      {
        title: "Spec",
        dataIndex: "spec",
        key: "spec",
        sorter: (a, b) => a.spec.length - b.spec.length,
        showSorterTooltip: false,
      },
    ],
  },

  "port-expense-lists": {
    tableheads: [
      {
        title: "Initial Est. Expenses",
        dataIndex: "intial_est_expesnes",
        key: "intial_est_expesnes",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "0.00"}</div>,
          };
        },
        sorter: (a, b) =>
          sortTheNumbers(a.intial_est_expesnes, b.intial_est_expesnes),
        showSorterTooltip: false,
      },
      {
        title: "Agent Name",
        dataIndex: "agent_full_name",
        key: "agent_full_name",
        sorter: (a, b) => a.agent_full_name.length - b.agent_full_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Arrival Date",
        dataIndex: "arrival",
        key: "arrival",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>{text && text !== "0000-00-00 00:00:00" ? text : "N/A"}</div>
            ),
          };
        },
        sorter: (a, b) => sortTheDates(a.arrival, b.arrival),
        showSorterTooltip: false,
      },

      {
        title: "Departure date",
        dataIndex: "departure",
        key: "departure",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>{text && text !== "0000-00-00 00:00:00" ? text : "N/A"}</div>
            ),
          };
        },
        sorter: (a, b) => sortTheDates(a.departure, b.departure),
        showSorterTooltip: false,
      },
      {
        title: "Disburmnt INV",
        dataIndex: "disburmnt_inv",
        key: "disburmnt_inv",
      },

      //  {
      //    title: "Port Ref No",
      //    dataIndex: "port_ref_no",
      //    key: "port_ref_no",
      //  },
      {
        title: "portid",
        dataIndex: "portid",
        key: "portid",
      },

      {
        title: "Total Amount",
        dataIndex: "total_amt",
        key: "total_amt",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "0.00"}</div>,
          };
        },
        sorter: (a, b) => sortTheNumbers(a.total_amt, b.total_amt),
        showSorterTooltip: false,
      },
      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
        sorter: (a, b) =>
          sortTheNumbers(a.invoice_type_name, b.invoice_type_name),
        showSorterTooltip: false,
      },

      {
        title: "FDA Status",
        dataIndex: "fda_status_name",
        key: "fda_status_name",
        sorter: (a, b) => sortTheNumbers(a.fda_status_name, b.fda_status_name),
        showSorterTooltip: false,
      },
      {
        title: "PDA Status",
        dataIndex: "pda_adv_status_name",
        key: "pda_adv_status_name",
        sorter: (a, b) =>
          sortTheNumbers(a.pda_adv_status_name, b.pda_adv_status_name),
        showSorterTooltip: false,
      },

      {
        title: "Port",
        dataIndex: "port",
        key: "port",
      },
    ],
  },

  "port-function-lists": {
    tableheads: [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        sorter: (a, b) => a.code.length - b.code.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port Operation",
        dataIndex: "port_operation",
        key: "port_operation",
        sorter: (a, b) => a.port_operation.length - b.port_operation.length,
        showSorterTooltip: false,
      },
    ],
  },

  "myport-calls-list": {
    tableheads: [
      {
        title: "Created Date",
        dataIndex: "created_on",
        key: "created_on",
        invisible: "false",
        width: 150,
        sorter: (a, b) => a.created_on.length - b.created_on.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Portcall Ref. ID",
        dataIndex: "port_call_ref_id",
        key: "port_call_ref_id",
        invisible: "false",
        width: 150,
        sorter: (a, b) => a.port_call_ref_id.length - b.port_call_ref_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        invisible: "false",
        width: 150,
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        key: "vessel_code",
        invisible: "false",
        width: 150,
        sorter: (a, b) => a.vessel_code.length - b.vessel_code.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port",
        dataIndex: "portcall_port_name",
        key: "portcall_port_name",
        invisible: "false",
        width: 100,
        sorter: (a, b) =>
          a.portcall_port_name.length - b.portcall_port_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Activity",
        dataIndex: "port_cargo_activity",
        key: "port_cargo_activity",
        invisible: "false",
        width: 100,
        sorter: (a, b) =>
          a.port_cargo_activity.length - b.port_cargo_activity.length,
        showSorterTooltip: false,
      },
      {
        title: "Agent",
        dataIndex: "agent_name",
        key: "agent_name",
        invisible: "false",
        width: 150,
        sorter: (a, b) => a.agent_name.length - b.agent_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "p_status",
        key: "p_status",
        invisible: "false",
        width: 200,
        sorter: (a, b) => a.p_status.length - b.p_status.length,
        showSorterTooltip: false,
      },
      {
        title: "PDA Cost",
        dataIndex: "agreed_est_amt",
        key: "agreed_est_amt",
        invisible: "false",
        width: 100,
        sorter: (a, b) =>
          a.agreed_est_amt && b.agreed_est_amt
            ? a.agreed_est_amt.length - b.agreed_est_amt.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "FDA Cost",
        dataIndex: "total_amt",
        key: "total_amt",
        invisible: "false",
        width: 100,
        sorter: (a, b) =>
          a.total_amt && b.total_amt
            ? a.total_amt.length - b.total_amt.length
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "ETA Date",
        dataIndex: "eta",
        key: "eta",
        invisible: "false",
        width: 200,
        sorter: (a, b) => moment(a.eta).unix() - moment(b.eta).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "ETD Date",
        dataIndex: "etd",
        key: "etd",
        invisible: "false",
        width: 200,
        sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },

  laytime_summary_list: {
    tableheads: [
      {
        title: "Laytime ID",
        dataIndex: "id",
        key: "l_id",
        invisible: "false",

        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Counter Party",
        dataIndex: "charterer_name",
        key: "charterer_name",
        invisible: "false",
        sorter: (a, b) =>
          a.charterer_name && b.charterer_name
            ? a.charterer_name.localeCompare(b.charterer_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Vessel",
        dataIndex: "vessel",
        key: "vessel",
        invisible: "false",
        sorter: (a, b) =>
          a.vessel && b.vessel ? a.vessel.localeCompare(b.vessel) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Party",
        dataIndex: "cp_form",
        key: "cp_form",
        invisible: "false",
        sorter: (a, b) =>
          a.cp_form && b.cp_form ? a.cp_form.localeCompare(b.cp_form) : null,
        showSorterTooltip: false,
      },
      {
        title: "Dem/Desp",
        dataIndex: "demurrage_dispatch",
        key: "dem_desp",
        invisible: "false",
        //  sorter: (a, b) =>
        //    a.demurrage_dispatch.length - b.demurrage_dispatch.length,
      },
      {
        title: "Invoice Date",
        dataIndex: "created_on",
        key: "created_on",
        invisible: "false",
        sorter: (a, b) =>
          moment(a.created_on).unix() - moment(b.created_on).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Amount USD",
        dataIndex: "demurrage_amount",
        key: "demurrage_amount",
        invisible: "false",
        sorter: (a, b) =>
          sortTheNumbers(a.demurrage_amount, b.demurrage_amount),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "l_status",
        key: "l_status",
        invisible: "false",
        sorter: (a, b) =>
          a.l_status && b.l_status
            ? a.l_status.localeCompare(b.l_status)
            : null,
        showSorterTooltip: false,
      },
      // {
      //   title: 'Last Modify Date',
      //   dataIndex: 'modified_on',
      //   key: 'modified_on',
      //   invisible: 'false',
      //   sorter: (a, b) => a.modified_on.length - b.modified_on.length,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: '#0726ff' },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
    ],
  },

  "fda-list": {
    tableheads: [
      // {
      //   title: "Portcall ID",
      //   dataIndex: "costitems",
      //   // width: 150,
      //   sorter: (a, b) => a.costitems.length - b.costitems.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        // width: 150,
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port",
        dataIndex: "port",
        // width: 150,
        sorter: (a, b) => a.port.length - b.port.length,
        showSorterTooltip: false,
      },
      //  {
      //    title: "Activity",
      //    dataIndex: "activity",
      //    // width: 150,
      //    sorter: (a, b) => a.activity.length - b.activity.length,
      //  },
      {
        title: "FDA Received",
        dataIndex: "fda",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "fda"),
      },
      {
        title: "FDA Status",
        dataIndex: "fda_status_name",
        // width: 150,
        render: (text, record) =>
          this.renderColumns(text, record, "fda_status_name"),
      },
      {
        title: "DA Status",
        dataIndex: "dastatus",
        // width: 150,
        render: (text, record) =>
          this.renderColumns(text, record, "attachment"),
      },
      {
        title: "Agent",
        dataIndex: "agent",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
      },
      {
        title: "Bank Name",
        dataIndex: "bankname",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Account Details",
        dataIndex: "account",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
      },
      {
        title: "User",
        dataIndex: "user",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
      },
      {
        title: "Invoice No",
        dataIndex: "invoice",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#009dc4" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Due Date",
        dataIndex: "duedate",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#ff0000" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Paid Date",
        dataIndex: "paiddate",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#0726ff" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Account type(Payable/receivable)",
        dataIndex: "acctype",
        width: 200,
        render: (text, record) => this.renderColumns(text, record, "remark"),
      },
      {
        title: "Inv Type",
        dataIndex: "inv",
        // width: 150,
        render: (text, record) => this.renderColumns(text, record, "remark"),
      },
    ],
  },
  "bunker-invoice-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_no",
        key: "voyage_no",
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Port",
        dataIndex: "port_area_name",
        key: "port_area_name",
        sorter: (a, b) =>
          a.port_area_name && b.port_area_name
            ? a.port_area_name.localeCompare(b.port_area_name)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
      },

      {
        title: "Fuel type",
        dataIndex: "fuel_type",
        key: "fuel_type",
        sorter: (a, b) =>
          a.fuel_type && b.fuel_type
            ? a.fuel_type.localeCompare(b.fuel_type)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Inv Qty",
        dataIndex: "invoice_qty",
        key: "invoice_qty",
        sorter: (a, b) =>
          a.invoice_qty && b.invoice_qty
            ? Number(a.invoice_qty) - Number(b.invoice_qty)
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Inv amount",
        dataIndex: "grand_total",
        key: "grand_total",
        sorter: (a, b) =>
          a.grand_total && b.grand_total
            ? parseFloat(a.grand_total.replace(",", "")) -
              parseFloat(b.grand_total.replace(",", ""))
            : a.grand_total.replace(",", "") && !b.grand_total.replace(",", "")
            ? -1
            : b.grand_total.replace(",", "") && !a.grand_total.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Inv Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Inv Status",
        dataIndex: "invoice_status_name",
        key: "invoice_status_name",
        sorter: (a, b) =>
          a.invoice_status_name && b.invoice_status_name
            ? a.invoice_status_name.localeCompare(b.invoice_status_name)
            : null,
        showSorterTooltip: false,
      },
    ],
  },
  "bunker-requirement-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voyage No",
        dataIndex: "voyage_no",
        key: "voyage_no",
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
        // sorter: (a, b) =>
        // a.port_name && b.port_name
        // ? a.port_name.localeCompare(b.port_name)
        // : null,
      },

      {
        title: "Port",
        dataIndex: "port_name",
        key: "port_name",
        sorter: (a, b) =>
          a.port_name && b.port_name
            ? a.port_name.localeCompare(b.port_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: (a, b) =>
          a.agent_name && b.agent_name
            ? a.agent_name.localeCompare(b.agent_name)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        sorter: (a, b) => moment(a.eta).unix() - moment(b.eta).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "ETD",
        dataIndex: "etd",
        key: "etd",
        sorter: (a, b) => moment(a.etd).unix() - moment(b.etd).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Required Qty",
        dataIndex: "required_qty",
        key: "required_qty",
        sorter: (a, b) =>
          a.required_qty && b.required_qty
            ? Number(a.required_qty) - Number(b.required_qty)
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Received Qty",
        dataIndex: "recieved_qty",
        key: "recieved_qty",
        sorter: (a, b) =>
          a.recieved_qty && b.recieved_qty
            ? Number(a.recieved_qty) - Number(b.recieved_qty)
            : 1,
        showSorterTooltip: false,
      },

      {
        title: "Request Status",
        dataIndex: "request_status_name",
        key: "request_status_name",
        sorter: (a, b) =>
          a.request_status_name.length - b.request_status_name.length,
        showSorterTooltip: false,
      },

      {
        title: "Request Date",
        dataIndex: "request_date",
        key: "request_date",
        sorter: (a, b) =>
          moment(a.request_date).unix() - moment(b.request_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },
  "bunker-purchase-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voyage No",
        dataIndex: "voyage_no",
        key: "voyage_no",
        sorter: (a, b) => a.voyage_no.length - b.voyage_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },

      {
        title: "Port Name",
        dataIndex: "port_name",
        key: "port_name",
        sorter: (a, b) => {
          const aLength = a.port_name ? a.port_name.length : 0;
          const bLength = b.port_name ? b.port_name.length : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      {
        title: "Requirement ID",
        dataIndex: "requirement_id",
        key: "requirement_id",
        sorter: (a, b) => a.requirement_id.length - b.requirement_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Company Name",
        dataIndex: "my_company_name",
        key: "my_company_name",
        sorter: (a, b) => a.my_company_name.length - b.my_company_name.length,
        showSorterTooltip: false,
      },

      {
        title: "Purchase Fuel Qty",
        dataIndex: "purfuelqty",
        key: "purfuelqty",
        // sorter: (a, b) => a.purfuelqty.length - b.purfuelqty.length,
      },

      {
        title: "Delivery From",
        dataIndex: "delivary_from",
        key: "delivary_from",
        sorter: (a, b) => a.delivary_from.length - b.delivary_from.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Delivery To",
        dataIndex: "delivary_to",
        key: "delivary_to",
        sorter: (a, b) => a.delivary_to.length - b.delivary_to.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Request Status",
        dataIndex: "request_status_name",
        key: "request_status_name",
        sorter: (a, b) =>
          a.request_status_name.length - b.request_status_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Order Date",
        dataIndex: "order_date",
        key: "order_date",
        sorter: (a, b) => a.order_date.length - b.order_date.length,
        showSorterTooltip: false,
      },
      {
        title: "Invoice Amt",
        dataIndex: "invoice_amt",
        key: "invoice_amt",
        sorter: (a, b) => a.invoice_amt.length - b.invoice_amt.length,
        showSorterTooltip: false,
      },
    ],
  },
  "other-expense-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) => a?.vessel_name?.length - b?.vessel_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No.",
        dataIndex: "voyage_manager_name",
        key: "voyage_manager_name",
        sorter: (a, b) =>
          a.voyage_manager_name.length - b.voyage_manager_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Vendor",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) => a.vendor_name.length - b.vendor_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Rev/Exp",
        dataIndex: "rev_exp_name",
        key: "rev_exp_name",
        sorter: (a, b) => a.rev_exp_name.length - b.rev_exp_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Trans No.",
        dataIndex: "provision_trans_no",
        key: "provision_trans_no",
        sorter: (a, b) =>
          a?.provision_trans_no?.length - b?.provision_trans_no?.length,
        showSorterTooltip: false,
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) => a.invoice_date.length - b.invoice_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => a.due_date.length - b.due_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Service No",
        dataIndex: "service_no",
        key: "service_no",
        sorter: (a, b) => a.service_no.length - b.service_no.length,
        showSorterTooltip: false,
      },
      {
        title: "Service Date",
        dataIndex: "service_date",
        key: "service_date",
        sorter: (a, b) => a.service_date.length - b.service_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Referance",
        dataIndex: "referance",
        key: "referance",
        sorter: (a, b) => a.referance.length - b.referance.length,
        showSorterTooltip: false,
      },
      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) => a.po_number.length - b.po_number.length,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_terms_name",
        key: "payment_terms_name",
        sorter: (a, b) => {
          const aLength = a.payment_terms_name
            ? a.payment_terms_name.length
            : 0;
          const bLength = b.payment_terms_name
            ? b.payment_terms_name.length
            : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) => a.total_amount.length - b.total_amount.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
        sorter: (a, b) =>
          a.invoice_type_name.length - b.invoice_type_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Status",
        dataIndex: "invoice_status_name",
        key: "invoice_status_name",
        sorter: (a, b) =>
          a.invoice_status_name.length - b.invoice_status_name.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },
    ],
  },

  "voyage-efficiency-list": {
    tableheads: [
      {
        title: "Voyage No.",
        dataIndex: "voyage_number",
        key: "voyage_number",
        sorter: (a, b) => a?.vessel_name?.length - b?.vessel_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#009dc4",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
            // children: <div>{text}</div>,
          };
        },
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) => a?.vessel_name?.length - b?.vessel_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Voyage Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        sorter: (a, b) =>
          a?.vessel_type_name?.length - b?.vessel_type_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo Name",
        dataIndex: "cargos",
        key: "cargos",
        sorter: (a, b) => a?.cargos?.length - b?.cargos?.length,
        showSorterTooltip: false,
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        sorter: (a, b) => a?.commence_date?.length - b?.commence_date?.length,
        showSorterTooltip: false,
      },
      {
        title: "Completed Date",
        dataIndex: "completing_date",
        key: "completing_date",
        sorter: (a, b) =>
          a?.completing_date?.length - b?.completing_date?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total Voyage Days",
        dataIndex: "total_days",
        key: "total_days",
        sorter: (a, b) => a?.total_days?.length - b?.total_days?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total Sea Cons.",
        dataIndex: "sea_cons",
        key: "sea_cons",
        sorter: (a, b) => a?.sea_cons?.length - b?.sea_cons?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total Bunker Cons.",
        dataIndex: "bunker_cons",
        key: "bunker_cons",
        sorter: (a, b) => a?.bunker_cons?.length - b?.bunker_cons?.length,
        showSorterTooltip: false,
      },
      {
        title: "Total Port Expenses",
        dataIndex: "port_exp",
        key: "port_exp",
        sorter: (a, b) => a?.port_exp?.length - b?.port_exp?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Total Revenue",
        dataIndex: "net_revenue",
        key: "net_revenue",
        sorter: (a, b) => a?.net_revenue?.length - b?.net_revenue?.length,
        showSorterTooltip: false,
      },
      {
        title: "Total Expenses",
        dataIndex: "net_expense",
        key: "net_expense",
        sorter: (a, b) => a?.net_expense?.length - b?.net_expense?.length,
        showSorterTooltip: false,
      },
      {
        title: "Net Result",
        dataIndex: "net_amount",
        key: "net_amount",
        sorter: (a, b) => {
          const aLength = a?.net_amount ? a?.net_amount?.length : 0;
          const bLength = b?.net_amount ? b?.net_amount?.length : 0;
          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      {
        title: "Daily Profit/Loss",
        dataIndex: "daily_profit_loss",
        key: "daily_profit_loss",
        sorter: (a, b) =>
          a?.daily_profit_loss?.length - b?.daily_profit_loss?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "vm_status1",
        key: "vm_status1",
        sorter: (a, b) => a?.vm_status1?.length - b?.vm_status1?.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },
    ],
  },

  "cargo-list": {
    tableheads: [
      {
        title: "Voyage No.",
        dataIndex: "estimate_id",
        key: "estimate_id",
        sorter: (a, b) => a?.estimate_id?.length - b?.estimate_id?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#009dc4",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
            // children: <div>{text}</div>,
          };
        },
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) => a?.vessel_name?.length - b?.vessel_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Cargo ID",
        dataIndex: "cargo_contract_id",
        key: "cargo_contract_id",
        sorter: (a, b) =>
          a?.cargo_contract_id.length - b?.cargo_contract_id?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Charterer",
        dataIndex: "charterer_name",
        key: "charterer_name",
        sorter: (a, b) => a?.charterer_name?.length - b?.charterer_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo Name",
        dataIndex: "cargo_name",
        key: "cargo_name",
        sorter: (a, b) => a?.cargo_name?.length - b?.cargo_name?.length,
        showSorterTooltip: false,
      },
      {
        title: "Load Port",
        dataIndex: "load_port",
        key: "load_port",
        sorter: (a, b) => a?.load_port?.length - b?.load_port?.length,
        showSorterTooltip: false,
      },
      {
        title: "Discharge Port",
        dataIndex: "discharge_port",
        key: "discharge_port",
        sorter: (a, b) => a?.discharge_port?.length - b?.discharge_port?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "CP Qty",
        dataIndex: "cp_qty",
        key: "cp_qty",
        sorter: (a, b) => a?.cp_qty?.length - b?.cp_qty?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "OPT Type",
        dataIndex: "opt_type",
        key: "opt_type",
        sorter: (a, b) => a?.opt_type?.length - b?.opt_type?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Freight Type",
        dataIndex: "frt_type_name",
        key: "frt_type_name",
        sorter: (a, b) => a?.frt_type_name?.length - b?.frt_type_name?.length,
        showSorterTooltip: false,
      },
      {
        title: "Frt Rate",
        dataIndex: "frat_rate",
        key: "frat_rate",
        sorter: (a, b) => a?.frat_rate?.length - b?.frat_rate?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Lumpsum",
        dataIndex: "lumsum",
        key: "lumsum",
        sorter: (a, b) => a?.lumsum?.length - b?.lumsum?.length,
        showSorterTooltip: false,
      },
      {
        title: "World Scale",
        dataIndex: "world_scale",
        key: "world_scale",
        sorter: (a, b) => a?.world_scale?.length - b?.world_scale?.length,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "freight",
        key: "freight",
        // sorter: (a, b) => {
        //   const aLength = a.payment_terms_name
        //     ? a.payment_terms_name.length
        //     : 0;
        //   const bLength = b.payment_terms_name
        //     ? b.payment_terms_name.length
        //     : 0;
        //   return aLength - bLength;
        // },
        showSorterTooltip: false,
      },

      {
        title: "Comm. Amount",
        dataIndex: "b_commission",
        key: "b_commission",
        // sorter: (a, b) => a.total_amount.length - b.total_amount.length,
        // showSorterTooltip: false,
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#28a745" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Extra Revenue",
        dataIndex: "extra_rev",
        key: "extra_rev",
        // sorter: (a, b) =>
        //   a.invoice_status_name.length - b.invoice_status_name.length,
        // showSorterTooltip: false,
        // render: (tags) => (
        //   <span>
        //     <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
        //       {tags}
        //     </Tag>
        //   </span>
        // ),
      },
      {
        title: "Net Amount",
        dataIndex: "net_revenue",
        key: "net_revenue",
        sorter: (a, b) => a?.net_revenue?.length - b?.net_revenue?.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },
    ],
  },

  "bunker-list": {
    tableheads: [
      {
        title: "Voyage No.",
        dataIndex: "estimate_id",
        key: "vessel_no",
        sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#009dc4",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
            // children: <div>{text}</div>,
          };
        },
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#1d565c" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a?.vessel_name?.length - b?.voyage_manager_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        sorter: (a, b) =>
          a?.vessel_type_name?.length - b?.vessel_type_name?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "OPS Type",
        dataIndex: "ops_type_name",
        key: "ops_type_name",
        sorter: (a, b) => a?.ops_type_name?.length - b?.ops_type_name?.length,
        showSorterTooltip: false,
      },
      {
        title: "Total Sea Days",
        dataIndex: "total_sea_day",
        key: "total_sea_day",
        sorter: (a, b) => a?.total_sea_day?.length - b?.total_sea_day?.length,
        showSorterTooltip: false,
      },
      {
        title: "Total Port Days",
        dataIndex: "total_port_day",
        key: "total_port_day",
        sorter: (a, b) => a?.total_port_day?.length - b?.total_port_day?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "status_comm_date",
        sorter: (a, b) => a?.commence_date?.length - b?.commence_date?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Completing Date",
        dataIndex: "completing_date",
        key: "completing_date",
        sorter: (a, b) =>
          a?.completing_date?.length - b?.completing_date?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Sea Consp.",
        dataIndex: "sea_consp.",
        key: "sea_consp.",
        children: [
          {
            title: "IFO",
            dataIndex: "ifo",
            key: "ifo",
            width: 130,
            sorter: (a, b) =>
              a?.ifo && b?.ifo ? a.ifo.localeCompare(b.ifo) : null,
            showSorterTooltip: false,
            render(text) {
              return {
                props: {
                  style: { color: "#009dc4" },
                },
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "VLSFO",
            dataIndex: "vlsfo",
            key: "vlsfo",
            width: 120,
            sorter: (a, b) =>
              a?.vlsfo && b?.vlsfo ? a?.vlsfo.localeCompare(b?.vlsfo) : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "LSMGO",
            dataIndex: "lsmgo",
            key: "lsmgo",
            width: 120,
            sorter: (a, b) =>
              a?.lsmgo && b?.lsmgo ? a?.lsmgo?.localeCompare(b?.lsmgo) : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "MGO",
            dataIndex: "mgo",
            key: "mgo",
            width: 120,
            sorter: (a, b) =>
              a?.mgo && b?.mgo ? a?.mgo?.localeCompare(b?.mgo) : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "ULSFO",
            dataIndex: "ulsfo",
            key: "ulsfo",
            width: 120,
            sorter: (a, b) =>
              a?.ulsfo && b?.ulsfo ? a?.ulsfo?.localeCompare(b?.ulsfo) : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },
      {
        title: "ECA/Seca cons.",
        dataIndex: "sea_cons_eca",
        key: "sea_cons_eca",
        sorter: (a, b) => a?.sea_cons_eca?.length - b?.sea_cons_eca?.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port Consumption",
        dataIndex: "port_consp.",
        key: "port_consp.",
        children: [
          {
            title: "IFO",
            dataIndex: "pc_ifo",
            key: "pc_ifo",
            width: 130,
            sorter: (a, b) =>
              a?.pc_ifo && b?.pc_ifo
                ? a?.pc_ifo?.localeCompare(b?.pc_ifo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                props: {
                  style: { color: "#009dc4" },
                },
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "VLSFO",
            dataIndex: "pc_vlsfo",
            key: "pc_vlsfo",
            width: 120,
            sorter: (a, b) =>
              a?.pc_vlsfo && b?.pc_vlsfo
                ? a?.pc_vlsfo?.localeCompare(b?.pc_vlsfo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "LSMGO",
            dataIndex: "pc_lsmgo",
            key: "pc_lsmgo",
            width: 120,
            sorter: (a, b) =>
              a?.pc_lsmgo && b?.pc_lsmgo
                ? a?.pc_lsmgo?.localeCompare(b?.pc_lsmgo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "MGO",
            dataIndex: "pc_mgo",
            key: "pc_mgo",
            width: 120,
            sorter: (a, b) =>
              a?.pc_mgo && b?.pc_mgo
                ? a?.pc_mgo?.localeCompare(b?.pc_mgo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "ULSFO",
            dataIndex: "pc_ulsfo",
            key: "pc_ulsfo",
            width: 120,
            sorter: (a, b) =>
              a?.pc_ulsfo && b?.pc_ulsfo
                ? a?.pc_ulsfo.localeCompare(b?.pc_ulsfo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },
      {
        title: "Total Bunker Consumption",
        dataIndex: "total_bunker_consp.",
        key: "total_bunker_consp.",
        children: [
          {
            title: "IFO",
            dataIndex: "total_bunker_ifo",
            key: "total_bunker_ifo",
            width: 130,
            sorter: (a, b) =>
              a?.total_bunker_ifo && b?.total_bunker_ifo
                ? a?.total_bunker_ifo?.localeCompare(b?.total_bunker_ifo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                props: {
                  style: { color: "#009dc4" },
                },
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "VLSFO",
            dataIndex: "total_bunker_vlsfo",
            key: "total_bunker_vlsfo",
            width: 120,
            sorter: (a, b) =>
              a?.total_bunker_vlsfo && b?.total_bunker_vlsfo
                ? a?.total_bunker_vlsfo?.localeCompare(b?.total_bunker_vlsfo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "LSMGO",
            dataIndex: "total_bunker_lsmgo",
            key: "total_bunker_lsmgo",
            width: 120,
            sorter: (a, b) =>
              a?.total_bunker_lsmgo && b?.total_bunker_lsmgo
                ? a?.total_bunker_lsmgo?.localeCompare(b?.total_bunker_lsmgo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "MGO",
            dataIndex: "total_bunker_mgo",
            key: "total_bunker_mgo",
            width: 120,
            sorter: (a, b) =>
              a?.total_bunker_mgo && b?.total_bunker_mgo
                ? a?.total_bunker_mgo?.localeCompare(b?.total_bunker_mgo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
          {
            title: "ULSFO",
            dataIndex: "total_bunker_ulsfo",
            key: "total_bunker_ulsfo",
            width: 120,
            sorter: (a, b) =>
              a?.total_bunker_ulsfo && b?.total_bunker_ulsfo
                ? a?.total_bunker_ulsfo?.localeCompare(b?.total_bunker_ulsfo)
                : null,
            showSorterTooltip: false,
            render(text) {
              return {
                children: <div>{text ? text : "N/A"}</div>,
              };
            },
          },
        ],
      },
    ],
  },

  "tc-commision-invoice-list": {
    tableheads: [
      {
        title: "Vessel Code",
        dataIndex: "vessel_code",
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Invoice Type",
        dataIndex: "inv_type",
      },

      {
        title: "Tci Code",
        dataIndex: "tc_code",
        sorter: (a, b) =>
          a.tc_code && b.tc_code ? a.tc_code.localeCompare(b.tc_code) : null,
        showSorterTooltip: false,
      },

      {
        title: "Broker Name",
        dataIndex: "broker",
        sorter: (a, b) =>
          a.broker && b.broker ? a.broker.localeCompare(b.broker) : null,
        showSorterTooltip: false,
      },

      {
        title: "Currency",
        dataIndex: "currency",
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        render: (date) => {
          if (date === undefined) return;
          return moment.utc(date).utcOffset(0).format("YYYY-MM-DD HH:mm");
        },
        sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
        showSorterTooltip: false,
      },

      {
        title: "Comm Amt",
        dataIndex: "commission",
        sorter: (a, b) => sortTheNumbers(a.commission, b.commission),
        showSorterTooltip: false,
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
      },

      // {
      //   title: 'TDE No.',
      //   dataIndex: 'tde_no',
      // },

      {
        title: "Status",
        dataIndex: "inv_status_name",
      },
    ],
  },

  "address-commision-invoice-list": {
    tableheads: [
      {
        title: "Broker",
        dataIndex: "broker",
      },

      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
      },

      {
        title: "Cargo Id",
        dataIndex: "cargo_id",
      },

      {
        title: "Commision Amount",
        dataIndex: "commission_amount",
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
      },

      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (date) => moment(date).format("YYYY-MM-DD HH:MM"),
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        render: (date) => moment(date).format("YYYY-MM-DD HH:MM"),
      },

      {
        title: "Voyage No",
        dataIndex: "voy",
      },
    ],
  },

  "other-revenue-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No.",
        dataIndex: "voyage_manager_name",
        key: "voyage_manager_name",
        sorter: (a, b) =>
          a.voyage_manager_name && b.voyage_manager_name
            ? a.voyage_manager_name.localeCompare(b.voyage_manager_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Vendor",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Rev/Exp",
        dataIndex: "rev_exp_name",
        key: "rev_exp_name",
        sorter: (a, b) => a.rev_exp_name.length - b.rev_exp_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Trans No.",
        dataIndex: "provision_trans_no",
        key: "provision_trans_no",
        sorter: (a, b) =>
          a.provision_trans_no && b.provision_trans_no
            ? a.provision_trans_no.localeCompare(b.provision_trans_no)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Service No",
        dataIndex: "service_no",
        key: "service_no",
        sorter: (a, b) =>
          a.service_no && b.service_no
            ? Number(a.service_no) - Number(b.service_no)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Service Date",
        dataIndex: "service_date",
        key: "service_date",
        sorter: (a, b) =>
          moment(a.service_date).unix() - moment(b.service_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Referance",
        dataIndex: "referance",
        key: "referance",
        sorter: (a, b) => a.referance.length - b.referance.length,
        showSorterTooltip: false,
      },
      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_terms_name",
        key: "payment_terms_name",
        sorter: (a, b) =>
          a.payment_terms_name && b.payment_terms_name
            ? a.payment_terms_name.localeCompare(b.payment_terms_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) =>
          a.total_amount && b.total_amount
            ? parseFloat(a.total_amount.replace(",", "")) -
              parseFloat(b.total_amount.replace(",", ""))
            : a.total_amount.replace(",", "") &&
              !b.total_amount.replace(",", "")
            ? -1
            : b.total_amount.replace(",", "") &&
              !a.total_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
        sorter: (a, b) =>
          a.invoice_type_name.length - b.invoice_type_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Status",
        dataIndex: "invoice_status_name",
        key: "invoice_status_name",
        //key: "vessel",
        sorter: (a, b) =>
          a.invoice_status_name.length - b.invoice_status_name.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },
    ],
  },

  "verified-invoice-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Company_name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) =>
          a.voyage_manager_name && b.voyage_manager_name
            ? a.voyage_manager_name.localeCompare(b.voyage_manager_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },

      // {
      //   title: "Vendor",
      //   dataIndex: "vendor_name",
      //   key: "vendor_name",
      //   sorter: (a, b) =>
      //     a.vendor_name && b.vendor_name
      //       ? a.vendor_name.localeCompare(b.vendor_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#b00e77" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Status",
        dataIndex: "invoice_status",
        key: "invoice_status",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      // {
      //   title: "Invoice Status",
      //   dataIndex: "nvoice_status",
      //   key: "invoice_status",
      //   sorter: (a, b) =>
      //     a.vendor_name && b.vendor_name
      //       ? a.vendor_name.localeCompare(b.vendor_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#b00e77" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      {
        title: "Invoice Name.",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_term_name",
        key: "payment_term_name",
        sorter: (a, b) =>
          a.payment_term_name && b.payment_term_name
            ? a.payment_term_name.localeCompare(b.payment_term_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) =>
          a.total_amount && b.total_amount
            ? parseFloat(a.total_amount) - parseFloat(b.total_amount)
            : a.total_amount && !b.total_amount
            ? -1
            : b.total_amount && !a.total_amount
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Invoice Type",
      //   dataIndex: "invoice_type_name",
      //   key: "invoice_type_name",
      //   sorter: (a, b) => {
      //     // Ensure that invoice_type_name is defined before accessing length
      //     const aLength = a.invoice_type_name ? a.invoice_type_name.length : 0;
      //     const bLength = b.invoice_type_name ? b.invoice_type_name.length : 0;

      //     return aLength - bLength;
      //   },
      //   showSorterTooltip: false,
      // },

      // {
      //   title: " Tde Status",
      //   dataIndex: "tde_status_name",
      //   key: "tde_status_name",
      //   //key: "tde_status_name",
      //   sorter: (a, b) => a.tde_status_name.length - b.tde_status_name.length,
      //   showSorterTooltip: false,
      //   render: (tags) => (
      //     <span>
      //       <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },
    ],
  },

  "posted-invoice-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Name",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) =>
          a.voy_no && b.voy_no ? a.voy_no.localeCompare(b.voy_no) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      // {
      //   title: "Vendor",
      //   dataIndex: "vendor_name",
      //   key: "vendor_name",
      //   sorter: (a, b) =>
      //     a.vendor_name && b.vendor_name
      //       ? a.vendor_name.localeCompare(b.vendor_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#b00e77" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Invoice Status",
        dataIndex: "invoice_status",
        key: "invoice_status",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due_Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_term_name",
        key: "payment_term_name",
        sorter: (a, b) =>
          a.payment_term_name && b.payment_term_name
            ? a.payment_term_name.localeCompare(b.payment_term_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) =>
          a?.total_amount && b?.total_amount
            ? parseFloat(a.total_amount) - parseFloat(b.total_amount)
            : a.total_amount && !b.total_amount
            ? -1
            : b.total_amount && !a.total_amount
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Invoice Type",
      //   dataIndex: "invoice_type_name",
      //   key: "invoice_type_name",
      //   sorter: (a, b) => {
      //     const aLength = a.invoice_type_name ? a.invoice_type_name.length : 0;
      //     const bLength = b.invoice_type_name ? b.invoice_type_name.length : 0;

      //     return aLength - bLength;
      //   },
      //   showSorterTooltip: false,
      // },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: (a, b) => {
          const aLength = a.company_name ? a.company_name.length : 0;
          const bLength = b.company_name ? b.company_name.length : 0;

          return aLength - bLength;
        },
        showSorterTooltip: false,
      },
      // {
      //   title: " Invoice Status",
      //   dataIndex: "inv_status_name",
      //   key: "inv_status_name",

      //   sorter: (a, b) =>
      //     a.inv_status_name.length - b.inv_status_name.length,
      //   render: (tags) => (
      //     <span>
      //       <Tag
      //         color={tags === "Actual" ? "#28a745" : "#9e9e9e"}
      //         key={tags}
      //       >
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },
    ],
  },
  "deleted-invoice-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Name",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) =>
          a.voyage_manager_name && b.voyage_manager_name
            ? a.voyage_manager_name.localeCompare(b.voyage_manager_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      // {
      //   title: "Vendor",
      //   dataIndex: "vendor_name",
      //   key: "vendor_name",
      //   sorter: (a, b) =>
      //     a.vendor_name && b.vendor_name
      //       ? a.vendor_name.localeCompare(b.vendor_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#b00e77" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Invoice Status",
        dataIndex: "invoice_status",
        key: "invoice_status",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due_Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_term_name",
        key: "payment_term_name",
        sorter: (a, b) =>
          a.payment_term_name && b.payment_term_name
            ? a.payment_term_name.localeCompare(b.payment_term_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) =>
          a.total_amount && b.total_amount
            ? parseFloat(a.total_amount) - parseFloat(b.total_amount)
            : a.total_amount && !b.total_amount
            ? -1
            : b.total_amount && !a.total_amount
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Invoice Type",
      //   dataIndex: "invoice_type_name",
      //   key: "invoice_type_name",
      //   sorter: (a, b) => {
      //     const aLength = a.invoice_type_name ? a.invoice_type_name.length : 0;
      //     const bLength = b.invoice_type_name ? b.invoice_type_name.length : 0;

      //     return aLength - bLength;
      //   },
      //   showSorterTooltip: false,
      // },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: (a, b) => {
          const aLength = a.company_name ? a.company_name.length : 0;
          const bLength = b.company_name ? b.company_name.length : 0;

          return aLength - bLength;
        },
        showSorterTooltip: false,
      },
      // {
      //   title: " Invoice Status",
      //   dataIndex: "inv_status_name",
      //   key: "inv_status_name",

      //   sorter: (a, b) =>
      //     a.inv_status_name.length - b.inv_status_name.length,
      //   render: (tags) => (
      //     <span>
      //       <Tag
      //         color={tags === "Actual" ? "#28a745" : "#9e9e9e"}
      //         key={tags}
      //       >
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },
    ],
  },

  "Approved-invoice-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Company_name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) =>
          a.voyage_manager_name && b.voyage_manager_name
            ? a.voyage_manager_name.localeCompare(b.voyage_manager_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },

      // {
      //   title: "Vendor",
      //   dataIndex: "vendor_name",
      //   key: "vendor_name",
      //   sorter: (a, b) =>
      //     a.vendor_name && b.vendor_name
      //       ? a.vendor_name.localeCompare(b.vendor_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#b00e77" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Status",
        dataIndex: "invoice_status",
        key: "invoice_status",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Name.",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
      },

      // {
      //   title: "Trans No.",
      //   dataIndex: "trans_no",
      //   key: "trans_no",
      //   sorter: (a, b) =>
      //     a.trans_no && b.trans_no
      //       ? a.trans_no.localeCompare(b.trans_no)
      //       : null,
      //   showSorterTooltip: false,
      // },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_term_name",
        key: "payment_term_name",
        sorter: (a, b) =>
          a.payment_term_name && b.payment_term_name
            ? a.payment_term_name.localeCompare(b.payment_term_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) =>
          a?.paid_amount && b?.paid_amount
            ? parseFloat(a.paid_amount) - parseFloat(b.paid_amount)
            : a.paid_amount && !b.paid_amount
            ? -1
            : b.paid_amount && !a.paid_amount
            ? 1
            : 0,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Invoice Type",
      //   dataIndex: "invoice_type_name",
      //   key: "invoice_type_name",
      //   sorter: (a, b) => {
      //     const aLength = a.invoice_type_name ? a.invoice_type_name.length : 0;
      //     const bLength = b.invoice_type_name ? b.invoice_type_name.length : 0;

      //     return aLength - bLength;
      //   },
      //   showSorterTooltip: false,
      // },
      // {
      //   title: " Invoice Status",
      //   dataIndex: "inv_status_name",
      //   key: "inv_status_name",

      //   sorter: (a, b) =>
      //     a.inv_status_name.length - b.inv_status_name.length,
      //   render: (tags) => (
      //     <span>
      //       <Tag
      //         color={tags === "Actual" ? "#28a745" : "#9e9e9e"}
      //         key={tags}
      //       >
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },

      // {
      //   title: " Tde Status",
      //   dataIndex: "tde_status_name",
      //   key: "tde_status_name",
      //   //key: "tde_status_name",
      //   sorter: (a, b) => a.tde_status_name.length - b.tde_status_name.length,
      //   showSorterTooltip: false,
      //   render: (tags) => (
      //     <span>
      //       <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },
    ],
  },

  "prepared-invoice-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Invoice Name",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.vessel_code && b.vessel_code
            ? a.vessel_code.localeCompare(b.vessel_code)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) =>
          a.voyage_manager_name && b.voyage_manager_name
            ? a.voyage_manager_name.localeCompare(b.voyage_manager_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      // {
      //   title: "Vendor",
      //   dataIndex: "vendor_name",
      //   key: "vendor_name",
      //   sorter: (a, b) =>
      //     a.vendor_name && b.vendor_name
      //       ? a.vendor_name.localeCompare(b.vendor_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#b00e77" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      {
        title: "Invoice Status",
        dataIndex: "invoice_status",
        key: "invoice_status",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due_Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "P.O. Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Payment Terms",
        dataIndex: "payment_term_name",
        key: "payment_term_name",
        sorter: (a, b) =>
          a.payment_term_name && b.payment_term_name
            ? a.payment_term_name.localeCompare(b.payment_term_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) => {
          return a?.total_amount && b?.total_amount
            ? parseFloat(a.total_amount) - parseFloat(b.total_amount)
            : a?.total_amount && !b?.total_amount
            ? -1
            : b?.total_amount && !a?.total_amount
            ? 1
            : 0;
        },
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Invoice Type",
      //   dataIndex: "invoice_type_name",
      //   key: "invoice_type_name",
      //   sorter: (a, b) => {
      //     const aLength = a.invoice_type_name ? a.invoice_type_name.length : 0;
      //     const bLength = b.invoice_type_name ? b.invoice_type_name.length : 0;

      //     return aLength - bLength;
      //   },
      //   showSorterTooltip: false,
      // },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: (a, b) => {
          const aLength = a.company_name ? a.company_name.length : 0;
          const bLength = b.company_name ? b.company_name.length : 0;

          return aLength - bLength;
        },
        showSorterTooltip: false,
      },

      // {
      //   title: " Invoice Status",
      //   dataIndex: "inv_status_name",
      //   key: "inv_status_name",

      //   sorter: (a, b) =>
      //     a.inv_status_name.length - b.inv_status_name.length,
      //   render: (tags) => (
      //     <span>
      //       <Tag
      //         color={tags === "Actual" ? "#28a745" : "#9e9e9e"}
      //         key={tags}
      //       >
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },

      // {
      //   title: "Company Name",
      //   dataIndex: "company_name",
      //   key: "company_name",
      //   //key: "tde_status_name",
      //   sorter: (a, b) => a.tde_status_name.length - b.tde_status_name.length,
      //   showSorterTooltip: false,
      //   render: (tags) => (
      //     <span>
      //       <Tag color={tags === "Actual" ? "#28a745" : "#9e9e9e"} key={tags}>
      //         {tags}
      //       </Tag>
      //     </span>
      //   ),
      // },
    ],
  },
  "hire-receivable-trxn-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel",
        key: "vessel",
        sorter: (a, b) => a.vessel.length - b.vessel.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.length - b.type.length,
        showSorterTooltip: false,
      },
      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) => a.voy_no.length - b.voy_no.length,
        showSorterTooltip: false,
      },
      {
        title: "TC ID",
        dataIndex: "tc_id",
        key: "tc_id",
        sorter: (a, b) => a.tc_id.length - b.tc_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        sorter: (a, b) => a.payment_status.length - b.payment_status.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "ACTUAL"
                  ? "#0726ff"
                  : tags === "PAID"
                  ? "#28a745"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => a.due_date.length - b.due_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "INV AMT",
        dataIndex: "inv_amt",
        key: "inv_amt",
        sorter: (a, b) => a.inv_amt.length - b.inv_amt.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Curr.",
        dataIndex: "curr",
        key: "curr",
        sorter: (a, b) => a.curr.length - b.curr.length,
        showSorterTooltip: false,
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Type",
        dataIndex: "invoice_type",
        key: "invoice_type",
        sorter: (a, b) => a.invoice_type.length - b.invoice_type.length,
        showSorterTooltip: false,
      },

      {
        title: "PO Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) => a.po_number.length - b.po_number.length,
        showSorterTooltip: false,
      },

      {
        title: "Trans. Status",
        dataIndex: "trans_status",
        key: "trans_status",
        sorter: (a, b) => a.trans_status.length - b.trans_status.length,
        showSorterTooltip: false,
      },

      {
        title: "AP/AR",
        dataIndex: "ap_ar",
        key: "ap_ar",
        sorter: (a, b) => a.ap_ar.length - b.ap_ar.length,
        showSorterTooltip: false,
      },

      {
        title: "Owner",
        dataIndex: "owner",
        key: "owner",
        sorter: (a, b) => a.owner.length - b.owner.length,
        showSorterTooltip: false,
      },

      {
        title: "Charterer",
        dataIndex: "charterer",
        key: "charterer",
        sorter: (a, b) => a.charterer.length - b.charterer.length,
        showSorterTooltip: false,
      },

      {
        title: "My Company",
        dataIndex: "my_company",
        key: "my_company",
        sorter: (a, b) => a.my_company.length - b.my_company.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "AP/AR Account",
        dataIndex: "apar_account",
        key: "apar_account",
        sorter: (a, b) => a.apar_account.length - b.apar_account.length,
        showSorterTooltip: false,
      },
    ],
  },

  "hire-payable-trxn-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel && b.vessel ? a.vessel.localeCompare(b.vessel) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        sorter: (a, b) =>
          a.tc_id && b.tc_id ? a.tc_id.localeCompare(b.tc_id) : null,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <Link to={`/add-TC-IN`}>{text}</Link>,
          };
        },
      },

      {
        title: "Trans No",
        dataIndex: "trans_no",
        key: "trans_no",
        sorter: (a, b) => a?.trans_no?.length - b?.trans_no?.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "ACTUAL"
                  ? "#0726ff"
                  : tags === "PAID"
                  ? "#28a745"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
      },
      {
        title: "Invoice Status",
        dataIndex: "inv_status_name",
        key: "inv_status_name",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title:"Opening Balance",
      //   dataIndex: "opening_balance",
      //   key: "due_date",
      //   sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#ff0000" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      // {
      //   title:"Net Balance",
      //   dataIndex: "net_balance",
      //   key: "net_balance",
      //   sorter: (a, b) =>
      //     moment(a.issue_date).unix() - moment(b.issue_date).unix(),
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#0726ff" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        align: "right",
        sorter: (a, b) => {
          // Convert the date string to a timestamp
          const dateA = a.invoice_date ? Date.parse(a.invoice_date) : 0;
          const dateB = b.invoice_date ? Date.parse(b.invoice_date) : 0;

          // Return the comparison
          return dateA - dateB;
        },
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "TDE Status",
        dataIndex: "tde_status_name",
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "Paid"
                  ? "#28a745"
                  : tags === "Processing"
                  ? "#0726ff"
                  : tags === "Posted"
                  ? "#007bff"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),

        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'tde_status'),
      },
      {
        title: "AP/AR Type",
        dataIndex: "invoice_name",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'apar_type'),
      },
      {
        title: "AP/AR Account",
        dataIndex: "ar_pr_account_no",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'apar_account'),
      },
      {
        title: "Bill By",
        dataIndex: "bill_via_name",
        key: "bill_via_name",
        sorter: (a, b) =>
          a.bill_via_name && b.bill_via_name
            ? a.bill_via_name.localeCompare(b.bill_via_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'bill_by'),
      },
      {
        title: "Vendor",
        dataIndex: "vendor_name",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'my_company'),
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "INV Amount",
        dataIndex: "invoice_amount",
        key: "invoice_amount",
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) =>
          a.invoice_amount && b.invoice_amount
            ? parseFloat(a.invoice_amount.replace(",", "")) -
              parseFloat(b.invoice_amount.replace(",", ""))
            : a.invoice_amount.replace(",", "") &&
              !b.invoice_amount.replace(",", "")
            ? -1
            : b.invoice_amount.replace(",", "") &&
              !a.invoice_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
      },
      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'billing_account'),
        render(data) {
          return {
            props: {
              style: { color: "#28a745" },
            },

            children: <div>{data ? data.replace(",", "") : "N/A"}</div>,
          };
        },
        sorter: (a, b) =>
          a.paid_amount && b.paid_amount
            ? parseFloat(a.paid_amount.replace(",", "")) -
              parseFloat(b.paid_amount.replace(",", ""))
            : a.paid_amount.replace(",", "") && !b.paid_amount.replace(",", "")
            ? -1
            : b.paid_amount.replace(",", "") && !a.paid_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
      },
      {
        title: "PO Number",
        dataIndex: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'po_number'),
      },
      {
        title: "Send/Received Date",
        dataIndex: "received_date",
        sorter: (a, b) =>
          moment(a.received_date).unix() - moment(b.received_date).unix(),
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'send_received_date'),
      },
      {
        title: "INV No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'inv_no'),
      },

      {
        title: "Due Amount",
        dataIndex: "total_due",
        key: "total_due",
        sorter: (a, b) =>
          a.total_due && b.total_due
            ? a.total_due.localeCompare(b.total_due)
            : null,
        showSorterTooltip: false,
      },

      // {
      //   title: 'Status',
      //   dataIndex: 'payment_status',
      //   key: 'payment_status',
      //   align: 'center',
      //   sorter: (a, b) => a.payment_status.length - b.payment_status.length,
      // },

      // {
      //   title: 'Counter party,',
      //   dataIndex: 'counter_party',
      //   key: 'counter_party',
      //   sorter: (a, b) => a.counter_party.length - b.counter_party.length,
      // },

      // {
      //   title: 'Counterparty Bank',
      //   dataIndex: 'party_bank',
      //   key: 'party_bank',
      //   sorter: (a, b) => a.party_bank.length - b.party_bank.length,
      // },

      // {
      //   title: 'My Company',
      //   dataIndex: 'my_company',
      //   key: 'my_company',
      //   sorter: (a, b) => a.my_company.length - b.my_company.length,
      // },
      // {
      //   title: 'Due Date',
      //   dataIndex: 'due_date',
      //   key: 'due_date',
      //   sorter: (a, b) => a.due_date.length - b.due_date.length,
      // },

      // {
      //   title: 'TDE Status',
      //   dataIndex: 'tde_status',
      //   key: 'tde_status',
      //   sorter: (a, b) => a.tde_status.length - b.tde_status.length,
      // },

      // {
      //   title: 'AP/AR Account',
      //   dataIndex: 'aparaccount',
      //   key: 'aparaccount',
      //   sorter: (a, b) => a.aparaccount.length - b.aparaccount.length,
      // },

      // {
      //   title: 'Charterer',
      //   dataIndex: 'charterer',
      //   key: 'charterer',
      //   sorter: (a, b) => a.charterer.length - b.charterer.length,
      // },

      // {
      //   title: 'Owner',
      //   dataIndex: 'owner',
      //   key: 'owner',
      //   sorter: (a, b) => a.owner.length - b.owner.length,
      // },

      // {
      //   title: 'Bill Via',
      //   dataIndex: 'bill_via',
      //   key: 'bill_via',
      //   sorter: (a, b) => a.bill_via.length - b.bill_via.length,
      // },

      // {
      //   title: 'Fix No',
      //   dataIndex: 'fix_no',
      //   key: 'fix_no',
      //   sorter: (a, b) => a.fix_no.length - b.fix_no.length,
      // },

      // {
      //   title: 'Estimate ID',
      //   dataIndex: 'estimate_id',
      //   key: 'estimate_id',
      //   sorter: (a, b) => a.estimate_id.length - b.estimate_id.length,
      // },
    ],
  },

  "hire-receivable-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel",
        key: "vessel",
        sorter: (a, b) => a.vessel.length - b.vessel.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.length - b.type.length,
        showSorterTooltip: false,
      },
      {
        title: "Voy No.",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) => a.voy_no.length - b.voy_no.length,
        showSorterTooltip: false,
      },
      {
        title: "TC ID",
        dataIndex: "tc_id",
        key: "tc_id",
        sorter: (a, b) => a.tc_id.length - b.tc_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        sorter: (a, b) => a.payment_status.length - b.payment_status.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "ACTUAL"
                  ? "#0726ff"
                  : tags === "PAID"
                  ? "#28a745"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => a.due_date.length - b.due_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "INV AMT",
        dataIndex: "inv_amt",
        key: "inv_amt",
        sorter: (a, b) => a.inv_amt.length - b.inv_amt.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Curr.",
        dataIndex: "curr",
        key: "curr",
        sorter: (a, b) => a.curr.length - b.curr.length,
        showSorterTooltip: false,
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Type",
        dataIndex: "invoice_type",
        key: "invoice_type",
        sorter: (a, b) => a.invoice_type.length - b.invoice_type.length,
        showSorterTooltip: false,
      },

      {
        title: "PO Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) => a.po_number.length - b.po_number.length,
        showSorterTooltip: false,
      },

      {
        title: "Trans. Status",
        dataIndex: "trans_status",
        key: "trans_status",
        sorter: (a, b) => a.trans_status.length - b.trans_status.length,
        showSorterTooltip: false,
      },

      {
        title: "AP/AR",
        dataIndex: "ap_ar",
        key: "ap_ar",
        sorter: (a, b) => a.ap_ar.length - b.ap_ar.length,
        showSorterTooltip: false,
      },

      {
        title: "Owner",
        dataIndex: "owner",
        key: "owner",
        sorter: (a, b) => a.owner.length - b.owner.length,
        showSorterTooltip: false,
      },

      {
        title: "Charterer",
        dataIndex: "charterer",
        key: "charterer",
        sorter: (a, b) => a.charterer.length - b.charterer.length,
        showSorterTooltip: false,
      },

      {
        title: "My Company",
        dataIndex: "my_company",
        key: "my_company",
        sorter: (a, b) => a.my_company.length - b.my_company.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "AP/AR Account",
        dataIndex: "apar_account",
        key: "apar_account",
        sorter: (a, b) => a.apar_account.length - b.apar_account.length,
        showSorterTooltip: false,
      },
    ],
  },

  "hire-rectrxn-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel && b.vessel ? a.vessel.localeCompare(b.vessel) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        width: 120,

        sorter: (a, b) =>
          a.voyage_manager_id && b.voyage_manager_id
            ? a.voyage_manager_id.localeCompare(b.voyage_manager_id)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Trans No",
        dataIndex: "trans_no",
        key: "trans_no",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Status",
        dataIndex: "inv_status_name",
        key: "inv_status_name",
        sorter: (a, b) =>
          a.inv_status_name && b.inv_status_name
            ? a.inv_status_name.localeCompare(b.inv_status_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          a.invoice_date && b.invoice_date
            ? a.invoice_date.localeCompare(b.invoice_date)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "TDE Status",
        dataIndex: "tde_status_name",
        key: "tde_status_name",
        sorter: (a, b) =>
          a.tde_status_name && b.tde_status_name
            ? a.tde_status_name.localeCompare(b.tde_status_name)
            : null,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "Paid"
                  ? "#28a745"
                  : tags === "Processing"
                  ? "#0726ff"
                  : tags === "Posted"
                  ? "#007bff"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#009dc4" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
      },
      {
        title: "AP/AR Type",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.invoice_name && b.invoice_name
            ? a.invoice_name.localeCompare(b.invoice_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "AP/AR Account",
        dataIndex: "ar_pr_account_no",
        key: "ar_pr_account_no",
        sorter: (a, b) =>
          a.ar_pr_account_no && b.ar_pr_account_no
            ? a.ar_pr_account_no.localeCompare(b.ar_pr_account_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Bill By",
        dataIndex: "bill_via_name",
        key: "bill_via_name",
        sorter: (a, b) =>
          a.bill_via_name && b.bill_via_name
            ? a.bill_via_name.localeCompare(b.bill_via_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vendor",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        key: "invoice_amount",
        sorter: (a, b) =>
          a.invoice_amount && b.invoice_amount
            ? a.invoice_amount.localeCompare(b.invoice_amount)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Paid Aount",
        dataIndex: "paid_amount",
        key: "paid_amount",
        sorter: (a, b) =>
          a.paid_amount && b.paid_amount
            ? a.paid_amount.localeCompare(b.paid_amount)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Po Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? a.po_number.localeCompare(b.po_number)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Send/Received Date",
        dataIndex: "received_date",
        key: "received_date",
        sorter: (a, b) =>
          a.received_date && b.received_date
            ? a.received_date.localeCompare(b.received_date)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due Amount",
        dataIndex: "total_due",
        key: "total_due",
        sorter: (a, b) =>
          a.total_due && b.total_due
            ? a.total_due.localeCompare(b.total_due)
            : null,
        showSorterTooltip: false,
      },
    ],
  },

  "hire-payable-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel",
        key: "vessel",
        sorter: (a, b) =>
          a.vessel && b.vessel ? a.vessel.localeCompare(b.vessel) : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      // {
      //   title: "TC ID",
      //   dataIndex: "tc_id",
      //   key: "tc_id",
      //   sorter: (a, b) =>
      //     a.tc_id && b.tc_id ? a.tc_id.localeCompare(b.tc_id) : null,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <Link to={`/add-TC-IN`}>{text}</Link>,
      //     };
      //   },
      // },

      {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        sorter: (a, b) => a.payment_status.length - b.payment_status.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "ACTUAL"
                  ? "#0726ff"
                  : tags === "PAID"
                  ? "#28a745"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type",
        key: "invoice_type",
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Issue Date",
        dataIndex: "issue_date",
        key: "issue_date",
        sorter: (a, b) =>
          moment(a.issue_date).unix() - moment(b.issue_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "INV AMT",
        dataIndex: "inv_amt",
        key: "inv_amt",
        align: "right",
        sorter: (a, b) => {
          return a?.inv_amt && b?.inv_amt
            ? parseFloat(a.inv_amt.replace(",", "")) -
                parseFloat(b.inv_amt.replace(",", ""))
            : a.inv_amt.replace(",", "") && !b.inv_amt.replace(",", "")
            ? -1
            : b.inv_amt.replace(",", "") && !a.inv_amt.replace(",", "")
            ? 1
            : 0;
        },
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'payment_status',
      //   key: 'payment_status',
      //   align: 'center',
      //   sorter: (a, b) => a.payment_status.length - b.payment_status.length,
      // },

      // {
      //   title: 'Counter party,',
      //   dataIndex: 'counter_party',
      //   key: 'counter_party',
      //   sorter: (a, b) => a.counter_party.length - b.counter_party.length,
      // },

      // {
      //   title: 'Counterparty Bank',
      //   dataIndex: 'party_bank',
      //   key: 'party_bank',
      //   sorter: (a, b) => a.party_bank.length - b.party_bank.length,
      // },

      // {
      //   title: 'My Company',
      //   dataIndex: 'my_company',
      //   key: 'my_company',
      //   sorter: (a, b) => a.my_company.length - b.my_company.length,
      // },
      // {
      //   title: 'Due Date',
      //   dataIndex: 'due_date',
      //   key: 'due_date',
      //   sorter: (a, b) => a.due_date.length - b.due_date.length,
      // },

      // {
      //   title: 'TDE Status',
      //   dataIndex: 'tde_status',
      //   key: 'tde_status',
      //   sorter: (a, b) => a.tde_status.length - b.tde_status.length,
      // },

      // {
      //   title: 'AP/AR Account',
      //   dataIndex: 'aparaccount',
      //   key: 'aparaccount',
      //   sorter: (a, b) => a.aparaccount.length - b.aparaccount.length,
      // },

      // {
      //   title: 'Charterer',
      //   dataIndex: 'charterer',
      //   key: 'charterer',
      //   sorter: (a, b) => a.charterer.length - b.charterer.length,
      // },

      // {
      //   title: 'Owner',
      //   dataIndex: 'owner',
      //   key: 'owner',
      //   sorter: (a, b) => a.owner.length - b.owner.length,
      // },

      // {
      //   title: 'Bill Via',
      //   dataIndex: 'bill_via',
      //   key: 'bill_via',
      //   sorter: (a, b) => a.bill_via.length - b.bill_via.length,
      // },

      // {
      //   title: 'Fix No',
      //   dataIndex: 'fix_no',
      //   key: 'fix_no',
      //   sorter: (a, b) => a.fix_no.length - b.fix_no.length,
      // },

      // {
      //   title: 'Estimate ID',
      //   dataIndex: 'estimate_id',
      //   key: 'estimate_id',
      //   sorter: (a, b) => a.estimate_id.length - b.estimate_id.length,
      // },
    ],
  },

  port_expense_summary: {
    tableheads: [
      {
        title: "Vendor Short",
        dataIndex: "agent_full_name",
        key: "agent_full_name",
        sorter: (a, b) => a.agent_full_name.length - b.agent_full_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Inv Type",
        dataIndex: "inv_type",
        key: "inv_type",
        sorter: (a, b) => a.inv_type.length - b.inv_type.length,
        showSorterTooltip: false,
      },

      {
        title: "Inv Date",
        dataIndex: "inv_date",
        key: "inv_date",
        sorter: (a, b) => a.inv_date.length - b.inv_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Inv No",
        dataIndex: "disburmnt_inv",
        key: "disburmnt_inv",
        sorter: (a, b) => a.disburmnt_inv.length - b.disburmnt_inv.length,
        showSorterTooltip: false,
      },

      {
        title: "Status",
        dataIndex: "p_status",
        key: "p_status",
        sorter: (a, b) => a.p_status.length - b.p_status.length,
        showSorterTooltip: false,
      },
      {
        title: "Receivale/Paybale",
        dataIndex: "r_paybale",
        key: "r_paybale",
        sorter: (a, b) => a.r_paybale.length - b.r_paybale.length,
        showSorterTooltip: false,
      },
    ],
  },
  "voy-relet-list": {
    tableheads: [
      {
        title: "Voy Relet ID",
        dataIndex: "estimate_id",
        key: "estimate_id",
        sorter: (a, b) => a.estimate_id.length - b.estimate_id.length,
        // render(text) {
        //   return {
        //     props: {
        //       style: { color: "#009dc4" },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
        render: (text, record) => {
          let clr =
            record.vivo_status === "FIX"
              ? "#e11010"
              : record.vivo_status === "SCHEDULED"
              ? "#6ecb0c"
              : "blue";

          return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
        },
      },

      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
      },

      // {
      //   title: "Vessel Sub Type",
      //   dataIndex: "vessel_sub_type",
      //   key: "vessel_sub_type",
      //   width: 120,

      //   sorter: (a, b) =>
      //     a.vessel_sub_type && b.vessel_sub_type
      //       ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      {
        title: "DWT",
        dataIndex: "dwt",
        key: "dwt",
        sorter: (a, b) => (a.dwt && b.dwt ? Number(a.dwt) - Number(b.dwt) : 1),
        showSorterTooltip: false,
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "status_comm_date",
        sorter: (a, b) =>
          moment(a.commence_date).unix() - moment(b.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Completed Date",
        dataIndex: "completing_date",
        key: "completing_date",
        sorter: (a, b) =>
          moment(a.completing_date).unix() - moment(b.completing_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Voy Relet Status",
        dataIndex: "vivo_status",
        key: "vivo_status",
        sorter: (a, b) => a.vivo_status.length - b.vivo_status.length,
        showSorterTooltip: false,
        render: (text, record) => {
          let clr;
          if (record.vivo_status === "FIX") {
            clr = "#e11010";
          }
          if (record.vivo_status === "DRAFT") {
            clr = "blue";
          }
          if (record.vivo_status === "SCHEDULED") {
            clr = "#6ecb0c";
          }
          // record.vivo_status === "FIX"
          //   ? "#e11010"
          //   : record.vivo_status === "SCHEDULED"
          //   ? "#6ecb0c"
          //   : "blue";

          // if (record.vivo_status === "PENDING") {
          //   return (
          //     <span style={{ color: clr, fontWeight: "600" }}>{"DRAFT"}</span>
          //   );
          // }

          return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
        },
        // render: (tags) => (
        //   <span>
        //     <Tag
        //       color={
        //         tags === "FIX"
        //           ? "#28a745"
        //           : tags === "PENDING"
        //           ? "#01bcd4"
        //           : "#9e9e9e"
        //       }
        //       key={tags}
        //     >
        //       {tags.toUpperCase()}
        //     </Tag>
        //   </span>
        // ),
      },
      // {
      //   title: "First Port",
      //   dataIndex: "ballast_port",
      //   key: "ballast_port",
      //   sorter: (a, b) =>
      //     a.ballast_port && b.ballast_port
      //       ? a.ballast_port.localeCompare(b.ballast_port)
      //       : null,
      //   showSorterTooltip: false,
      // },
      // {
      //   title: "Last Port",
      //   dataIndex: "reposition_port",
      //   key: "reposition_port",
      //   sorter: (a, b) =>
      //     a.reposition_port && b.reposition_port
      //       ? a.reposition_port.localeCompare(b.reposition_port)
      //       : null,
      //   showSorterTooltip: false,
      // },
      {
        title: "Voyage Days",
        dataIndex: "total_days",
        key: "total_days",
        sorter: (a, b) =>
          a.total_days && b.total_days
            ? Number(a.total_days) - Number(b.total_days)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "C/P Date",
        dataIndex: "cp_date",
        key: "cp_date",
        sorter: (a, b) => moment(a.cp_date).unix() - moment(b.cp_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Fixed By",
        dataIndex: "user_name",
        key: "user_name",
        sorter: (a, b) =>
          (typeof a.user_name !== "undefined"
            ? a.user_name.length
            : 0) -
          (typeof b.user_name !== "undefined" ? b.user_name.length : 0),
        showSorterTooltip: false,
      },
      {
        title: "Ops User",
        dataIndex: "ops_user",
        key: "ops_user",
        sorter: (a, b) =>
          (typeof a.ops_user !== "undefined"
            ? a.ops_user.length
            : 0) -
          (typeof b.ops_user !== "undefined" ? b.ops_user.length : 0),
        showSorterTooltip: false,
      },
      // {
      //   title: 'Gross Revenue',
      //   dataIndex: 'gross_revenue',
      //   key: 'gross_revenue',
      //   sorter: (a, b) => a.gross_revenue.length - b.gross_revenue.length,
      // },
      // {
      //   title: 'Total Expense',
      //   dataIndex: 'total_expense',
      //   key: 'total_expense',
      //   sorter: (a, b) => a.total_expense.length - b.total_expense.length,
      // },
      // {
      //   title: 'Voyage Result (Profit/Loss)',
      //   dataIndex: 'voyage_result',
      //   key: 'voyage_result',
      //   sorter: (a, b) => a.voyage_result.length - b.voyage_result.length,
      // },
    ],
  },
  "freight-commission-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel",
        render(text) {
          return {
            props: {
              style: { color: "#1ca1e4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No.",
        dataIndex: "voy",
        key: "voy",
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },

      {
        title: "Cargo ID",
        dataIndex: "cargo_id",
        key: "cargo_id",
        sorter: (a, b) =>
          a.cargo_id && b.cargo_id
            ? a.cargo_id.localeCompare(b.cargo_id)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.account_type && b.account_type
            ? a.account_type.localeCompare(b.account_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Broker Name",
        dataIndex: "broker",
        key: "broker",
        sorter: (a, b) =>
          a.broker && b.broker
            ? a.broker.localeCompare(b.broker, undefined, {
                numeric: true,
                sensitivity: "base",
              })
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "INV Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) => sortTheDates(a.invoice_date, b.invoice_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => sortTheDates(a.due_date, b.due_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Received Date",
        dataIndex: "recieved_date",
        key: "recieved_date",
        sorter: (a, b) => sortTheDates(a.recieved_date, b.recieved_date),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Commission Amt",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) => sortTheNumbers(a.total_amount - b.total_amount),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "INV No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
      },

      {
        title: "Invoice Status",
        dataIndex: "com_status",
        key: "com_status",
        sorter: (a, b) => a.com_status.length - b.com_status.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "POSTED" ? "#007bff" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },

      {
        title: "TDE Status",
        dataIndex: "tde_status",
        key: "tde_status",
        sorter: (a, b) => a?.tde_status?.length - b?.tde_status?.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "POSTED" ? "#007bff" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },
    ],
  },
  "initial-freight-invoice-summary": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel",
        sorter: (a, b) => a.vessel.length - b.vessel.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1ca1e4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voy_no",
        key: "voy_no",
        sorter: (a, b) => a.voy_no.length - b.voy_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.account_type && b.account_type
            ? a.account_type.localeCompare(b.account_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Counter Party",
        dataIndex: "counterparty",
        key: "counterparty",
        sorter: (a, b) => a.counterparty.length - b.counterparty.length,
        showSorterTooltip: false,
      },

      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) => a.invoice_date.length - b.invoice_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: (a, b) => a.due_date.length - b.due_date.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type",
        key: "invoice_type",
        sorter: (a, b) => a.invoice_type.length - b.invoice_type.length,
        showSorterTooltip: false,
      },
      {
        title: "Status",
        dataIndex: "fia_status",
        key: "fia_status",
        sorter: (a, b) => a.fia_status.length - b.fia_status.length,
        showSorterTooltip: false,
        render: (tags) => (
          <span>
            <Tag color={tags === "POSTED" ? "#007bff" : "#9e9e9e"} key={tags}>
              {tags}
            </Tag>
          </span>
        ),
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        key: "total_amount",
        sorter: (a, b) => sortTheNumbers(a.total_amount, b.total_amount),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },

  "initial-bunker-invoice-summary": {
    tableheads: [
      {
        title: "Vessel",
        dataIndexL: "vessel_name",
        key: "vessel",
        sorter: (a, b) => a.vessel.length - b.vessel.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1ca1e4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Voyage No",
        dataIndex: "voyage_no",
        key: "voyage_no",

        sorter: (a, b) => a.voyage_no - b.voyage_no,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1ca1e4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      // {
      //   title: "Vessel Type",
      //   dataIndex: "vessel_type",
      //   key: "vessel_type",
      //   width: 120,

      //   sorter: (a, b) =>
      //     a.vessel_type && b.vessel_type
      //       ? a.vessel_type.localeCompare(b.vessel_type)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Vessel Sub Type",
      //   dataIndex: "vessel_sub_type",
      //   key: "vessel_sub_type",
      //   width: 120,

      //   sorter: (a, b) =>
      //     a.vessel_sub_type && b.vessel_sub_type
      //       ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Port",
      //   dataIndex: "port_area_name",
      //   key: "port_area_name",

      //   sorter: (a, b) =>
      //     a.port_area_name && b.port_area_name
      //       ? a.port_area_name.localeCompare(b.port_area_name)
      //       : null,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text ? text : "N/A"}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Invoice No",
      //   dataIndex: "invoice_no",
      //   key: "invoice_no",
      //   sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Fuel Type",
      //   dataIndex: "",
      //   key: "",
      //   sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Invoice Qty",
      //   dataIndex: "",
      //   key: "",
      //   sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Invoice Amount",
      //   dataIndex: "",
      //   key: "",
      //   sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Invoice Date",
      //   dataIndex: "invoice_date",
      //   key: "invoice_date",
      //   sorter: (a, b) => a.invoice_date.length - b.invoice_date.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Due Date",
      //   dataIndex: "invoice_date",
      //   key: "invoice_date",
      //   sorter: (a, b) => a.invoice_date.length - b.invoice_date.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      // {
      //   title: "Invoice Status",
      //   dataIndex: "invoice_status_name",
      //   key: "invoice_status_name",
      //   sorter: (a, b) =>
      //     a.invoice_status_name.length - b.invoice_status_name.length,
      //   showSorterTooltip: false,
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
    ],
  },
  "noon-verification-list": {
    tableheads: [
      {
        title: "VSPM ID",
        dataIndex: "vspmId",
        key: "vspmId",
        // sorter: (a, b) =>
        //   a.vspmId && b.vspmId ? a.vspmId.localeCompare(b.vspmId) : null,
        showSorterTooltip: false,
      },
      {
        title: "Vessel Name",
        dataIndex: "vesselName",
        key: "vesselName",
        sorter: (a, b) =>
          a.vesselName && b.vesselName
            ? a.vesselName.localeCompare(b.vesselName)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1ca1e4", fontWeight: "500" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "IMO No.",
        dataIndex: "imo",
        key: "imo",
        sorter: (a, b) => (a.imo && b.imo ? a.imo.localeCompare(b.imo) : null),
        showSorterTooltip: false,
        render: (text) => (
          <span style={{ fontWeight: "600" }}>{text ? text : "N/A"}</span>
        ),
      },
      {
        title: "Voyage No",
        dataIndex: "voyageNumber",
        key: "voyageNumber",
        sorter: (a, b) =>
          a.voyageNumber && b.voyageNumber
            ? a.voyageNumber.localeCompare(b.voyageNumber)
            : null,
        showSorterTooltip: false,
        render: (text) => (
          <span style={{ color: "#340964ad", fontWeight: "700" }}>
            {text ? text : "N/A"}
          </span>
        ),
      },
      {
        title: "Arrival Port",
        dataIndex: "arrivalPort",
        key: "arrivalPort",
        sorter: (a, b) => {
          const aLength = a.arrivalPort ? a.arrivalPort.length : 0;
          const bLength = b.arrivalPort ? b.arrivalPort.length : 0;

          return aLength - bLength;
        },
        showSorterTooltip: false,
        render: (text) => (
          <span style={{ color: "darkgoldenrod", fontWeight: "bold" }}>
            {text ? text.toUpperCase() : "N/A"}
          </span>
        ),
      },

      {
        title: "Departure Port",
        dataIndex: "departurePort",
        key: "departurePort",
        sorter: (a, b) => {
          const aLength = a.departurePort ? a.departurePort.length : 0;
          const bLength = b.departurePort ? b.departurePort.length : 0;

          return aLength - bLength;
        },
        showSorterTooltip: false,
        render: (text) => (
          <span style={{ color: "#0bb888", fontWeight: "bold" }}>
            {text ? text.toUpperCase() : "N/A"}
          </span>
        ),
      },

      {
        title: "Report Date",
        dataIndex: "reportDate",
        key: "reportDate",
        sorter: (a, b) =>
          moment(a.reportDate).unix() - moment(b.reportDate).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Report Type",
        dataIndex: "reportType",
        key: "reportType",
        sorter: (a, b) =>
          a.reportType && b.reportType
            ? a.reportType.localeCompare(b.reportType)
            : null,
        showSorterTooltip: false,
        render: (text) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // sorter: (a, b) =>
        //   a.status && b.status ? a.status.localeCompare(b.status) : null,
        showSorterTooltip: false,
        fixed: "right",
        render: (status) =>
          status === 0 ? (
            <Tooltip
              title="This report is waiting for your approval. Since it has been filled by Master."
              placement="left"
            >
              <Tag
                color="orange"
                style={{
                  margin: "0",
                  display: "block",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                PENDING
              </Tag>
            </Tooltip>
          ) : status === 1 ? (
            <Tooltip title="This report is Approved by Admin" placement="left">
              <Tag
                color="green"
                style={{
                  margin: "0",
                  display: "block",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                APPROVED
              </Tag>
            </Tooltip>
          ) : status === 2 ? (
            <Tooltip title="This report is Rejected by Admin" placement="left">
              <Tag
                color="red"
                style={{
                  margin: "0",
                  display: "block",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                REJECTED
              </Tag>
            </Tooltip>
          ) : status === 3 ? (
            <Tooltip title="This report is Deleted by Admin" placement="left">
              <Tag
                color="red"
                style={{
                  margin: "0",
                  display: "block",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                DELETED
              </Tag>
            </Tooltip>
          ) : (
            "N/A"
          ),
      },
    ],
  },

  "user-list": {
    tableheads: [
      {
        title: "Unique ID",
        dataIndex: "user_id",
        key: "user_id",
        sorter: (a, b) => a.user_id.length - b.user_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Username",
        dataIndex: "user_name",
        key: "user_name",
        sorter: (a, b) => a.user_name.length - b.user_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "First-Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Last-Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: (a, b) => a.last_name.length - b.last_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Initials",
        dataIndex: "initials",
        key: "initials",
        sorter: (a, b) => a.initials.length - b.initials.length,
        showSorterTooltip: false,
      },
      {
        title: "Email",
        dataIndex: "user_email",
        key: "user_email",
        sorter: (a, b) => a.user_email.length - b.user_email.length,
        showSorterTooltip: false,
      },
      {
        title: "Phone no.",
        dataIndex: "phone_number",
        key: "phone_number",
        sorter: (a, b) => a.phone_number.length - b.phone_number.length,
        showSorterTooltip: false,
      },
      {
        title: "Country",
        dataIndex: "country_name",
        key: "country_name",
        sorter: (a, b) => a.country_name.length - b.country_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Designation",
        dataIndex: "designation_name",
        key: "designation_name",
        sorter: (a, b) => a.designation_name.length - b.designation_name.length,
        showSorterTooltip: false,
      },
      {
        title: "Organization",
        dataIndex: "organization",
        key: "organization",
        sorter: (a, b) => a.organization.length - b.organization.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },
  "group-user-list": {
    tableheads: [
      {
        title: "Group Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1ca1e4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description.length - b.description.length,
        showSorterTooltip: false,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.length - b.status.length,
        showSorterTooltip: false,
      },
    ],
  },
  "orgnization-user-head-office-list": {
    tableheads: [
      {
        title: "My Company",
        dataIndex: "full_name",
        key: "full_name",
        sorter: (a, b) =>
          a.full_name && b.full_name
            ? a.full_name.localeCompare(b.full_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        sorter: (a, b) =>
          a.country && b.country ? a.country.localeCompare(b.country) : null,
        showSorterTooltip: false,
      },
      {
        title: "Main Contact",
        dataIndex: "main_contact",
        key: "main_contact",
        sorter: (a, b) =>
          a.main_contact && b.main_contact
            ? a.main_contact.length - b.main_contact.length
            : null,
        showSorterTooltip: false,
      },
    ],
  },
  "orgnization-user-head-office-details-list": {
    tableheads: [
      {
        title: "My Company",
        dataIndex: "full_name",
        key: "full_name",
        sorter: (a, b) =>
          a.full_name && b.full_name
            ? a.full_name.localeCompare(b.full_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        sorter: (a, b) =>
          a.country && b.country ? a.country.localeCompare(b.country) : null,
        showSorterTooltip: false,
      },
      {
        title: "Bank Name",
        dataIndex: "benificiary_name",
        key: "benificiary_name",
        sorter: (a, b) =>
          a.benificiary_name && b.benificiary_name
            ? a.benificiary_name.localeCompare(b.benificiary_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Account",
        dataIndex: "account_no",
        key: "account_no",
        sorter: (a, b) =>
          a.account_no && b.account_no
            ? a.account_no.length - b.account_no.length
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Account Type",
        dataIndex: "account_type",
        key: "account_type",
        sorter: (a, b) =>
          a.account_type && b.account_type
            ? a.account_type.localeCompare(b.account_type)
            : null,
        showSorterTooltip: false,
      },
    ],
  },

  "bunker-purchased-order-summery-list": {
    tableheads: [
      // {
      //   title: 'Vessel Name',
      //   dataIndex: 'vessel_name',
      //   key: 'vessel_name',
      //   sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,

      // },
      {
        title: "Voyage No",
        dataIndex: "voyage_no",
        key: "voyage_no",
        sorter: (a, b) =>
          a.voyage_no && b.voyage_no
            ? a.voyage_no.localeCompare(b.voyage_no)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Vendor Name",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Company Name",
        dataIndex: "my_company_name",
        key: "my_company_name",
        sorter: (a, b) => a.my_company_name.length - b.my_company_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port Name",
        dataIndex: "port_name",
        key: "port_name",
        sorter: (a, b) =>
          a.port_name && b.port_name
            ? a.port_name.localeCompare(b.port_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Requirement Id",
        dataIndex: "requirement_id",
        key: "requirement_id",
        sorter: (a, b) =>
          a.requirement_id && b.requirement_id
            ? Number(a.requirement_id) - Number(b.requirement_id)
            : 1,
        showSorterTooltip: false,
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Delivary From",
        dataIndex: "delivary_from",
        key: "delivary_from",
        sorter: (a, b) =>
          moment(a.delivary_from).unix() - moment(b.delivary_from).unix(),
        showSorterTooltip: false,
        render: (date) => moment(date).format("YYYY-MM-DD"),
      },
      {
        title: "Delivary To",
        dataIndex: "delivary_to",
        key: "delivary_to",
        sorter: (a, b) =>
          moment(a.delivary_to).unix() - moment(b.delivary_to).unix(),
        showSorterTooltip: false,
        render: (date) => moment(date).format("YYYY-MM-DD"),
      },
      {
        title: "Purchase Status",
        dataIndex: "purchase_status_name",
        key: "purchase_status_name",
        sorter: (a, b) =>
          a.purchase_status_name && b.purchase_status_name
            ? a.purchase_status_name.localeCompare(b.purchase_status_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Request Status",
        dataIndex: "request_status_name",
        key: "request_status_name",
        sorter: (a, b) =>
          a.request_status_name && b.request_status_name
            ? a.request_status_name.localeCompare(b.request_status_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Order Date",
        dataIndex: "order_date",
        key: "order_date",
        sorter: (a, b) =>
          moment(a.order_date).unix() - moment(b.order_date).unix(),
        showSorterTooltip: false,
      },
      {
        title: "Invoice Amt",
        dataIndex: "invoice_amt",
        key: "invoice_amt",
        sorter: (a, b) => a.invoice_amt.length - b.invoice_amt.length,
        showSorterTooltip: false,
      },
    ],
  },
  "agent-appoinment-list": {
    tableheads: [
      {
        title: "Ref. ID",
        dataIndex: "port_call_ref_id",
        key: "port_call_ref_id",
        sorter: (a, b) => a.port_call_ref_id.length - b.port_call_ref_id.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Port",
        dataIndex: "portcall_port_name",
        key: "portcall_port_name",
        sorter: (a, b) =>
          a.portcall_port_name.length - b.portcall_port_name.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Activity",
        dataIndex: "port_cargo_activity",
        key: "port_cargo_activity",
        //sorter: (a, b) => a.activity.length - b.activity.length,
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        sorter: (a, b) => a.eta.length - b.eta.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "ETD",
        dataIndex: "etd",
        key: "etd",
        sorter: (a, b) => a.etd.length - b.etd.length,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   key: 'status',
      //   sorter: (a, b) => a.status.length - b.status.length,
      // },
    ],
  },

  "vendor-transcation-list": {
    tableheads: [
      {
        title: "Vendor Name",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'vendor_name'),
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "AP/AR Type",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.invoice_name && b.invoice_name
            ? a.invoice_name.localeCompare(b.invoice_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'apar_type'),
      },
      {
        title: "Trans No",
        dataIndex: "trans_no",
        key: "trans_no",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'rev_exp'),
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
        sorter: (a, b) =>
          a.invoice_type_name && b.invoice_type_name
            ? a.invoice_type_name.localeCompare(b.invoice_type_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'invoice_type'),
      },
      {
        title: "PO Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'po_number'),
      },
      {
        title: "Inv No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'inv_no'),
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        key: "invoice_amount",
        sorter: (a, b) =>
          a.invoice_amount && b.invoice_amount
            ? a.invoice_amount.localeCompare(b.invoice_amount)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "Due Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'due_date'),
      },

      {
        title: "TDE Status",
        dataIndex: "tde_status_name",
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "Paid"
                  ? "#28a745"
                  : tags === "Processing"
                  ? "#0726ff"
                  : tags === "Posted"
                  ? "#007bff"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
        // width: 150,vessel
        // render: (text, record) => this.renderColumns(text, record, 'tde_status'),
      },

      {
        title: "Send/Receive Date",
        dataIndex: "received_date",
        key: "received_date",
        sorter: (a, b) =>
          moment(a.received_date).unix() - moment(b.received_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'send_received_date'),
      },

      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        key: "paid_amount",
        sorter: (a, b) =>
          a.paid_amount && b.paid_amount
            ? a.paid_amount.localeCompare(b.paid_amount)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Due Amount",
        dataIndex: "total_due",
        key: "total_due",
        sorter: (a, b) =>
          a.total_due && b.total_due
            ? a.total_due.localeCompare(b.total_due)
            : null,
        showSorterTooltip: false,
      },

      // {
      //   title: "Vessel",
      //   dataIndex: "vessel_name",
      //   key: "vessel_name",
      //   sorter: (a, b) =>
      //     a.vessel_name && b.vessel_name
      //       ? a.vessel_name.localeCompare(b.vessel_name)
      //       : null,
      //   showSorterTooltip: false,
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'vessel'),
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#1d565c" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        sorter: (a, b) =>
          a.voyage_manager_id && b.voyage_manager_id
            ? Number(a.voyage_manager_id) - Number(b.voyage_manager_id)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'voy_no'),
      },

      // {
      //   title: 'Voy Type',
      //   dataIndex: 'voy_type',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'voy_type'),
      // },

      // {
      //   title: 'TC Id',
      //   dataIndex: 'tc_id',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'tc_id'),
      // },

      // {
      //   title: "Fixture No",
      //   dataIndex: "fixture_no",
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'fixture_no'),
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
    ],
  },

  "bunker-vendor-transcation-list": {
    tableheads: [
      {
        title: "Vendor Name",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'vendor_name'),
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "AP/AR Type",
      //   dataIndex: "invoice_name",
      //   key: "invoice_name",
      //   sorter: (a, b) =>
      //   a.invoice_name && b.invoice_name
      //     ? a.invoice_name.localeCompare(b.invoice_name)
      //     : null,
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'apar_type'),
      // },
      {
        title: "Trans No",
        dataIndex: "trans_no",
        key: "trans_no",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'rev_exp'),
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
        sorter: (a, b) =>
          a.invoice_type_name && b.invoice_type_name
            ? a.invoice_type_name.localeCompare(b.invoice_type_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'invoice_type'),
      },
      {
        title: "PO Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'po_number'),
      },
      {
        title: "Inv No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'inv_no'),
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'due_date'),
      },

      {
        title: "TDE Status",
        dataIndex: "tde_status_name",
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "Paid"
                  ? "#28a745"
                  : tags === "Processing"
                  ? "#0726ff"
                  : tags === "Posted"
                  ? "#007bff"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
        // width: 150,vessel
        // render: (text, record) => this.renderColumns(text, record, 'tde_status'),
      },

      {
        title: "Receive Date",
        dataIndex: "received_date",
        key: "received_date",
        sorter: (a, b) =>
          moment(a.received_date).unix() - moment(b.received_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'send_received_date'),
      },

      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'vessel'),
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_name",
        key: "voyage_manager_name",
        sorter: (a, b) =>
          a.voyage_manager_name && b.voyage_manager_name
            ? Number(a.voyage_manager_name) - Number(b.voyage_manager_name)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'voy_no'),
      },

      {
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        key: "invoice_amount",
        sorter: (a, b) =>
          a.invoice_amount && b.invoice_amount
            ? a.invoice_amount.localeCompare(b.invoice_amount)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        key: "paid_amount",
        sorter: (a, b) =>
          a.paid_amount && b.paid_amount
            ? a.paid_amount.localeCompare(b.paid_amount)
            : null,
        showSorterTooltip: false,
      },

      {
        title: "Due Amount",
        dataIndex: "total_due",
        key: "total_due",
        sorter: (a, b) =>
          a.total_due && b.total_due
            ? a.total_due.localeCompare(b.total_due)
            : null,
        showSorterTooltip: false,
      },

      // {
      //   title: 'Voy Type',
      //   dataIndex: 'voy_type',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'voy_type'),
      // },

      // {
      //   title: 'TC Id',
      //   dataIndex: 'tc_id',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'tc_id'),
      // },

      // {
      //   title: "Fixture No",
      //   dataIndex: "fixture_no",
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'fixture_no'),
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
    ],
  },

  "agent-transcation-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'vessel'),
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        sorter: (a, b) =>
          a.voyage_manager_id && b.voyage_manager_id
            ? Number(a.voyage_manager_id) - Number(b.voyage_manager_id)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'voy_no'),
      },

      {
        title: "Vendor Name",
        dataIndex: "vendor_name",
        key: "vendor_name",
        sorter: (a, b) =>
          a.vendor_name && b.vendor_name
            ? a.vendor_name.localeCompare(b.vendor_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'vendor_name'),
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "AP/AR Type",
        dataIndex: "invoice_name",
        key: "invoice_name",
        sorter: (a, b) =>
          a.invoice_name && b.invoice_name
            ? a.invoice_name.localeCompare(b.invoice_name)
            : null,
        showSorterTooltip: false,

        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'apar_type'),
      },
      {
        title: "Trans No",
        dataIndex: "trans_no",
        key: "trans_no",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'rev_exp'),
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        key: "invoice_type_name",
        sorter: (a, b) =>
          a.invoice_type_name && b.invoice_type_name
            ? a.invoice_type_name.localeCompare(b.invoice_type_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'invoice_type'),
      },
      {
        title: "Invoice Status",
        dataIndex: "inv_status_name",
        key: "inv_status_name",
        sorter: (a, b) =>
          a.inv_status_name && b.inv_status_name
            ? a.inv_status_name.localeCompare(b.inv_status_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'invoice_status'),
      },
      {
        title: "PO Number",
        dataIndex: "po_number",
        key: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'po_number'),
      },
      {
        title: "Inv No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'inv_no'),
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Due Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'due_date'),
      },

      {
        title: "TDE Status",
        dataIndex: "tde_status_name",
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "Paid"
                  ? "#28a745"
                  : tags === "Processing"
                  ? "#0726ff"
                  : tags === "Posted"
                  ? "#007bff"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
        // width: 150,vessel
        // render: (text, record) => this.renderColumns(text, record, 'tde_status'),
      },

      {
        title: "Bill By",
        dataIndex: "bill_via_name",
        key: "bill_via_name",
        sorter: (a, b) =>
          a.bill_via_name && b.bill_via_name
            ? a.bill_via_name.localeCompare(b.bill_via_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'bill_by'),
      },

      {
        title: "INV Amount",
        dataIndex: "invoice_amount",
        key: "invoice_amount",
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) =>
          a.invoice_amount && b.invoice_amount
            ? parseFloat(a.invoice_amount.replace(",", "")) -
              parseFloat(b.invoice_amount.replace(",", ""))
            : a.invoice_amount.replace(",", "") &&
              !b.invoice_amount.replace(",", "")
            ? -1
            : b.invoice_amount.replace(",", "") &&
              !a.invoice_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
      },

      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'billing_account'),
        render(data) {
          return {
            props: {
              style: { color: "#28a745" },
            },

            children: <div>{data ? data.replace(",", "") : "N/A"}</div>,
          };
        },
        sorter: (a, b) =>
          a.paid_amount && b.paid_amount
            ? parseFloat(a.paid_amount.replace(",", "")) -
              parseFloat(b.paid_amount.replace(",", ""))
            : a.paid_amount.replace(",", "") && !b.paid_amount.replace(",", "")
            ? -1
            : b.paid_amount.replace(",", "") && !a.paid_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
      },

      {
        title: "Send/Receive Date",
        dataIndex: "received_date",
        key: "received_date",
        sorter: (a, b) =>
          moment(a.received_date).unix() - moment(b.received_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'send_received_date'),
      },

      {
        title: "Due Amount",
        dataIndex: "total_due",
        key: "total_due",
        sorter: (a, b) =>
          a.total_due && b.total_due
            ? a.total_due.localeCompare(b.total_due)
            : null,
        showSorterTooltip: false,
      },

      // {
      //   title: 'Voy Type',
      //   dataIndex: 'voy_type',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'voy_type'),
      // },

      // {
      //   title: 'TC Id',
      //   dataIndex: 'tc_id',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'tc_id'),
      // },

      // {
      //   title: "Fixture No",
      //   dataIndex: "fixture_no",
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'fixture_no'),
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#009dc4" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
    ],
  },

  "transaction-summary-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'vessel'),
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        sorter: (a, b) =>
          a.voyage_manager_id && b.voyage_manager_id
            ? Number(a.voyage_manager_id) - Number(b.voyage_manager_id)
            : 1,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'voy_no'),
      },
      {
        title: "Trans No",
        dataIndex: "trans_no",
        key: "trans_no",
        sorter: (a, b) =>
          a.trans_no && b.trans_no
            ? a.trans_no.localeCompare(b.trans_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'cargo_id'),
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",

        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'invoice_type'),
      },
      {
        title: "Invoice Status",
        dataIndex: "inv_status_name",
        key: "inv_status_name",
        sorter: (a, b) =>
          a.inv_status_name && b.inv_status_name
            ? a.inv_status_name.localeCompare(b.inv_status_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },

        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'invoice_status'),
      },
      // {
      //   title: "Opening Balance",
      //   dataIndex: "opening_balance",
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'invoice_status'),
      // },
      // {
      //   title: "Net Balance",
      //   dataIndex: "net_balance",
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'invoice_status'),
      // },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        sorter: (a, b) =>
          moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'due_date'),
      },
      {
        title: "TDE Status",
        dataIndex: "tde_status_name",
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "Paid"
                  ? "#28a745"
                  : tags === "Processing"
                  ? "#0726ff"
                  : tags === "Posted"
                  ? "#007bff"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'tde_status'),
      },
      {
        title: "AP/AR Type",
        dataIndex: "invoice_name",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'apar_type'),
      },
      {
        title: "AP/AR Account",
        dataIndex: "ar_pr_account_no",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'apar_account'),
      },
      {
        title: "Bill By",
        dataIndex: "bill_via_name",
        key: "bill_via_name",
        sorter: (a, b) =>
          a.bill_via_name && b.bill_via_name
            ? a.bill_via_name.localeCompare(b.bill_via_name)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'bill_by'),
      },
      {
        title: "Vendor",
        dataIndex: "vendor_name",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'my_company'),
        render(text) {
          return {
            props: {
              style: { color: "#b00e77" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: 'Counter Party Name',
      //   dataIndex: 'counter_party_name',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'counter_party_name'),
      // },
      {
        title: "INV Amount",
        dataIndex: "invoice_amount",
        key: "invoice_amount",
        render: (text) => {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
        sorter: (a, b) =>
          a.invoice_amount && b.invoice_amount
            ? parseFloat(a.invoice_amount.replace(",", "")) -
              parseFloat(b.invoice_amount.replace(",", ""))
            : a.invoice_amount.replace(",", "") &&
              !b.invoice_amount.replace(",", "")
            ? -1
            : b.invoice_amount.replace(",", "") &&
              !a.invoice_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
      },
      // {
      //   title: 'Bill To',
      //   dataIndex: 'bill_to',
      //   // width: 150,
      //   // render: (text, record) => this.renderColumns(text, record, 'bill_to'),
      // },
      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'billing_account'),
        render(data) {
          return {
            props: {
              style: { color: "#28a745" },
            },

            children: <div>{data ? data.replace(",", "") : "N/A"}</div>,
          };
        },
        sorter: (a, b) =>
          a.paid_amount && b.paid_amount
            ? parseFloat(a.paid_amount.replace(",", "")) -
              parseFloat(b.paid_amount.replace(",", ""))
            : a.paid_amount.replace(",", "") && !b.paid_amount.replace(",", "")
            ? -1
            : b.paid_amount.replace(",", "") && !a.paid_amount.replace(",", "")
            ? 1
            : 0,
        showSorterTooltip: false,
      },
      {
        title: "PO Number",
        dataIndex: "po_number",
        sorter: (a, b) =>
          a.po_number && b.po_number
            ? Number(a.po_number) - Number(b.po_number)
            : 1,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'po_number'),
      },
      {
        title: "Send/Received Date",
        dataIndex: "received_date",
        sorter: (a, b) =>
          moment(a.received_date).unix() - moment(b.received_date).unix(),
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'send_received_date'),
      },
      {
        title: "INV No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
        // width: 150,
        // render: (text, record) => this.renderColumns(text, record, 'inv_no'),
      },

      {
        title: "Due Amount",
        dataIndex: "total_due",
        key: "total_due",
        sorter: (a, b) =>
          a.invoice_no && b.invoice_no
            ? a.invoice_no.localeCompare(b.invoice_no)
            : null,
        showSorterTooltip: false,
      },

      //  {
      //    title: "Fixture No",
      //    dataIndex: "fixture_no",
      //    // width: 150,
      //    // render: (text, record) => this.renderColumns(text, record, 'fixture_no'),
      //  },
    ],
  },
  "port-info-list": {
    tableheads: [
      {
        title: "Port Name",
        dataIndex: "port_name",
      },

      // {
      //   title: 'Last Update',
      //   dataIndex: 'last_update',
      // },

      {
        title: "Agent By",
        dataIndex: "agent_by",
      },

      {
        title: "Draft Restriction",
        dataIndex: "draft_restriction",
      },
      {
        title: "Load Rate",
        dataIndex: "load_rate",
      },

      {
        title: "Discharge Rate",
        dataIndex: "discharge_rate",
      },
      {
        title: "SW Draft Limit",
        dataIndex: "sw_draft_limit",
      },

      {
        title: "FW Draft Limit",
        dataIndex: "fw_draft_limit",
      },
      {
        title: "No of Berth",
        dataIndex: "no_of_birth",
      },

      {
        title: "Type of Cargo",
        dataIndex: "type_of_cargo_load_discharge",
      },
    ],
  },
  "pda-list": {
    tableheads: [
      //  {
      //    title: "Portcall ID",
      //    dataIndex: "portcall_refer_no",
      //    // width: 150,
      //    key: "portcall_refer_no",
      //    //sorter: (a, b) => a.portcall_refer_no.length - b.portcall_refer_no.length,
      //  },
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        // width: 150,
        key: "vessel_name",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Port",
        dataIndex: "port",
        // width: 150,
        key: "port",
        //sorter: (a, b) => a.port_name.length - b.port_name.length,
      },
      //  {
      //    title: "Activity",
      //    dataIndex: "diff",
      //    // width: 150,
      //  },
      //  {
      //    title: "PDA Quoted",
      //    dataIndex: "total_quoted",
      //    // width: 150,
      //    key: "total_quoted",
      //    //sorter: (a, b) => a.total_quated.length - b.total_quated.length,
      //  },
      //  {
      //    title: "PDA Agreed",
      //    dataIndex: "total_agreed",
      //    // width: 150,
      //    key: "total_quated",
      //    //sorter: (a, b) => a.total_quated.length - b.total_quated.length,
      //  },
      {
        title: "PDA Adv. Status",
        dataIndex: "pda_adv_status_name",
        // width: 150
        key: "pda_adv_status_name",
        //sorter: (a, b) => a.pda_adv_status_name.length - b.pda_adv_status_name.length,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        // width: 150,
        key: "status_name",
        //sorter: (a, b) => a.p_status.length - b.p_status.length,
      },
      {
        title: "Agent",
        dataIndex: "agent_full_name",
        // width: 150,
        key: "agent_full_name",
        //sorter: (a, b) => a.agent_full_name1.length - b.agent_full_name1.length,
      },
      //  {
      //    title: "Bank Name",
      //    dataIndex: "benificiary_bank",
      //    // width: 150,
      //    key: "benificiary_bank",
      //    //sorter: (a, b) => a.benificiary_bank.length - b.benificiary_bank.length,
      //  },
      //  {
      //    title: "Account Details",
      //    dataIndex: "account_no",
      //    // width: 150,
      //    key: "account_no",
      //    //sorter: (a, b) => a.account_no.length - b.account_no.length,
      //  },
      {
        title: "Total Amt",
        dataIndex: "total_amt",
        // width: 150,
        key: "total_amt",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      },
      // {
      //   title: 'User',
      //   dataIndex: 'attachment',
      //   // width: 150,
      //   key: 'vessel_name',
      //   sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      // },
      {
        title: "Invoice No.",
        dataIndex: "disburmnt_inv",
        // width: 150,
        key: "disburmnt_inv",
        //sorter: (a, b) => a.disburmnt_inv.length - b.disburmnt_inv.length,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: 'Due Date',
      //   dataIndex: 'attachment',
      //   // width: 150,
      //   key: 'vessel_name',
      //   //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      // },
      {
        title: "Inv Type",
        dataIndex: "invoice_type_name",
        // width: 150,
        key: "invoice_type_name",
        //sorter: (a, b) => a.invoice_type.length - b.invoice_type.length,
      },
    ],
  },
  "fda-list": {
    tableheads: [
      {
        title: "Vessel",
        dataIndex: "vessel_name",
        // width: 150,
        key: "vessel_name",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Voy No",
        dataIndex: "voyage_manager_id",
        key: "voyage_manager_id",
        render(text) {
          return {
            props: {
              style: {
                color: "#424bf5",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
              },
            },
            children: (
              <div>
                {text ? (
                  <span onClick={() => onVmClick(text)}>{text}</span>
                ) : (
                  "N/A"
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "Port",
        dataIndex: "port",
        // width: 150,
        key: "port",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      },
      //  {
      //    title: "Activity",
      //    dataIndex: "activity",
      //    // width: 150,
      //    key: "activity",
      //    //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      //  },
      {
        title: "FDA Invoice",
        dataIndex: "fda_inv_date",
        // width: 150,
        key: "fda_inv_date",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      },
      {
        title: "FDA Status",
        dataIndex: "fda_status_name",
        // width: 150,
        key: "fda_status_name",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      },
      {
        title: "Status",
        dataIndex: "p_status",
        key: "p_status",
        // width: 150,
      },
      {
        title: "Agent",
        dataIndex: "agent_full_name",
        key: "agent_full_name",
        // width: 150,
      },
      //  {
      //    title: "Bank Name",
      //    dataIndex: "benificiary_bank",
      //    // width: 150,
      //    key: "benificiary_bank",
      //    //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      //  },
      //  {
      //    title: "Account Details",
      //    dataIndex: "account_no",
      //    // width: 150,
      //    key: "account_no",
      //    //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      //  },
      // {
      //   title: 'User',
      //   dataIndex: 'user',
      //   // width: 150,
      //   key: 'vessel_name',
      //   //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      // },
      {
        title: "Invoice No",
        dataIndex: "disburmnt_inv",
        // width: 150,
        key: "disburmnt_inv",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      //  {
      //    title: "Due Date",
      //    dataIndex: "invoice_date",
      //    // width: 150,
      //    key: "invoice_date",
      //    //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      //    render(text) {
      //      return {
      //        props: {
      //          style: { color: "#3bb812" },
      //        },
      //        children: <div>{text}</div>,
      //      };
      //    },
      //  },
      //  {
      //    title: "Paid Date",
      //    dataIndex: "paid_date",
      //    // width: 150,
      //    key: "paid_date",
      //    render(text) {
      //      return {
      //        props: {
      //          style: { color: "#3bb812" },
      //        },
      //        children: <div>{text}</div>,
      //      };
      //    },
      //    //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      //  },
      //  {
      //    title: "Account type(Payable/receivable)",
      //    dataIndex: "account_type",
      //    width: 200,
      //    key: "vessel_name",
      //    //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      //  },
      {
        title: "Total Amt",
        dataIndex: "total_amt",
        // width: 150,
        key: "total_amt",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      },
      {
        title: "Inv Type",
        dataIndex: "invoice_type_name",
        // width: 150,
        key: "invoice_type_name",
        //sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
      },
    ],
  },
  "archive-list": {
    tableheads: [
      {
        title: "Created Date",
        dataIndex: "created_on",
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Portcall Ref. ID",
        dataIndex: "port_call_ref_id",
        render: (text, row, index, data) => {
          return (
            <a
              href="preventDefault()"
              style={{ color: "#1890ff" }}
              onClick={(e) => this.edagenrowRedirect(e, row, data)}
            >
              {text}
            </a>
          );
        },
      },

      {
        title: "Vessel",
        dataIndex: "vessel",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Port",
        dataIndex: "portcall_port_name",
      },

      {
        title: "Activity",
        dataIndex: "activity",
      },

      {
        title: "Agent",
        dataIndex: "agent_name",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      // {
      //   title: 'Company',
      //   dataIndex: 'company',
      // },

      {
        title: "Status",
        dataIndex: "status",
        render: (tags) => (
          <span>
            <Tag
              color={
                tags === "SCHEDULED"
                  ? "#28a745"
                  : tags === "Archives"
                  ? "#01bcd4"
                  : tags === "REDELIVERED"
                  ? "#0726ff"
                  : tags === "COMPLETED"
                  ? "#ff0000"
                  : tags === "FIX"
                  ? "#81d742"
                  : "#9e9e9e"
              }
              key={tags}
            >
              {tags}
            </Tag>
          </span>
        ),
      },

      {
        title: "PDA Cost",
        dataIndex: "agreed_est_amt",
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "FDA Cost",
        dataIndex: "total_amt",
        render(text) {
          return {
            props: {
              style: { color: "#28a745" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "ETA Date",
        dataIndex: "eta",
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "ETD Date",
        dataIndex: "etd",
        render(text) {
          return {
            props: {
              style: { color: "#ff0000" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },
  "claim-list": {
    tableheads: [
      {
        title: "vessel Name",
        dataIndex: "vessel_name",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Vessel Type",
        dataIndex: "vessel_type",
        key: "vessel_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_type && b.vessel_type
            ? a.vessel_type.localeCompare(b.vessel_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "Vessel Sub Type",
        dataIndex: "vessel_sub_type",
        key: "vessel_sub_type",
        width: 120,

        sorter: (a, b) =>
          a.vessel_sub_type && b.vessel_sub_type
            ? a.vessel_sub_type.localeCompare(b.vessel_sub_type)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },

      {
        title: "voyage No",
        dataIndex: "voyage_no",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Laytime ID",
        dataIndex: "laytime_id",
      },

      {
        title: "Counter Party",
        dataIndex: "counter_Party_name",
      },

      {
        title: "My Company",
        dataIndex: "company_name",
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Type",
        dataIndex: "invoice_type_name",
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Invoice Due Date",
        dataIndex: "due_date",
        render(text) {
          return {
            props: {
              style: { color: "rgb(255 7 7)", fontWeight: "600" },
            },
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "Claim Send/Rec. date",
        dataIndex: "claim_date",
        render(text) {
          return {
            props: {
              style: { color: "rgb(204 217 0)", fontWeight: "600" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Claim Send/Rec. date",
      //   dataIndex: "claim_date",
      //   key: "claim_date",
      //   render(text) {
      //     return {
      //       props: {
      //         style: {
      //           color: "#424bf5",
      //           cursor: "pointer",
      //           transition: "color 0.3s ease-in-out",
      //         },
      //       },
      //       // children: (
      //       //   <div>
      //       //     {text ? (
      //       //       <span onClick={() => onVmClick(text)}>{text}</span>
      //       //     ) : (
      //       //       "N/A"
      //       //     )}
      //       //   </div>
      //       // ),
      //     };
      //   },
      // },
      {
        title: "Settle Date",
        dataIndex: "settle_date",
        render(text) {
          return {
            props: {
              style: { color: "rgb(50 161 22)", fontWeight: "600" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: "Final Dispatch Amount",
      //   dataIndex: "final_amount_usd",
      // },
      // {
      //   title: "Final Demurrage Amount",
      //   dataIndex: "company_nam",
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: "#3bb812" },
      //       },
      //       children: <div>{text}</div>,
      //     };
      //   },
      // },
      // {
      //   title: "Final Amount(USD)",
      //   dataIndex: "claim_status_nam",
      // },

      {
        title: "Final Dispatch Amt.",
        dataIndex: "total_amount2",
        // dataIndex: "demurrage_dispatch",
        render: (text) => (text ? text : "N/A"),
      },
      {
        title: "Final Demurrage Amt.",
        dataIndex: "total_amount1",
        // dataIndex: "demurrage_dispatch",
        render: (text) => (text ? text : "N/A"),
      },
      {
        title: "Final Amt. (USD)",
        dataIndex: "final_amount_usd",
      },
      {
        title: "Invoice Status",
        dataIndex: "invoice_status_name",
      },
      {
        title: "Claim Status",
        dataIndex: "claim_status_name",
        fixed: "right",
        render: (text) => {
          let clr =
            text === "Confirm"
              ? "green"
              : text === "Under Review"
              ? "rgb(204, 217, 0)"
              : text === "Disputed"
              ? "red"
              : "black";
          return <span style={{ color: clr, fontWeight: "600" }}>{text}</span>;
        },
      },
    ],
  },

  "hire-schedule-list": {
    tableheads: [
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        key: "vessel_name",
        sorter: (a, b) =>
          a.vessel_name && b.vessel_name
            ? a.vessel_name.localeCompare(b.vessel_name)
            : null,
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: " Vessel Type",
        dataIndex: "vessel_type_name",
        key: "vessel_type_name",
        sorter: (a, b) =>
          a.vessel_type_name && b.vessel_type_name
            ? a.vessel_type_name.localeCompare(b.vessel_type_name)
            : null,
        showSorterTooltip: false,
      },
      {
        title: "User",
        dataIndex: "owner_name",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Company",
        dataIndex: "company_name",
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Commence Date",
        dataIndex: "commence_date",
        key: "commence_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.commence_date).unix() - moment(b.commence_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      {
        title: "Completed Date",
        dataIndex: "completing_date",
        key: "completing_date",
        width: 150,
        sorter: (a, b) =>
          moment(a.completing_date).unix() - moment(b.completing_date).unix(),
        showSorterTooltip: false,
        render(text) {
          return {
            props: {
              style: { color: "#0721ff" },
            },
            children: <div>{text ? text : "N/A"}</div>,
          };
        },
      },
      //  {
      //    title: "From Date/To Date",
      //    dataIndex: "form_to_date",
      //    render(text) {
      //      return {
      //        props: {
      //          style: { color: "#0726ff" },
      //        },
      //        children: <div>{text}</div>,
      //      };
      //    },
      //  },
    ],
  },

  "CP date": {
    tableheads: [
      {
        title: "Fixture No.",
        dataIndex: "fixture_no",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "CP Date",
        dataIndex: "CP_date",
      },
      {
        title: "Vessel Name",
        dataIndex: "vessel_name",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "File No.",
        dataIndex: "file_no",
        render(text) {
          return {
            props: {
              style: { color: "#3bb812" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Cargo",
        dataIndex: "cargo",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Create Date/Last Update",
        dataIndex: "form_to_date",
        render(text) {
          return {
            props: {
              style: { color: "#0726ff" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Attachment",
        dataIndex: "attachment",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Sharing",
        dataIndex: "sharing",
        render(text) {
          return {
            props: {
              style: { color: "#009dc4" },
            },
            children: <div>{text}</div>,
          };
        },
      },
    ],
  },

  ...TABLE_FIELDS,
};
