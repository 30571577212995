import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Tag,
  Form,
  Input,
  Row,
  Col,
  Typography,
  DatePicker,
  Popover,
} from "antd";
import React, { useEffect, useState } from "react";
import { getAPICall, openNotificationWithIcon, postAPICall } from "../../shared";
import { Icon } from "@iconify/react/dist/iconify.js";
import Swal from "sweetalert2";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const CargoTemplate = () => {
  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddCargoModalOpen, setIsAddCargoModalOpen] = useState(false);
  const pageSize = 6;
  const [formData, setFormData] = useState({
    to: "",
    from: "",
    subject: "",
    id: "",
    option: "",
    accountFor: "",
    cargoDetails: "",
    cargoName: "",
    cpQty: "",
    minQty: "",
    maxQty: "",
    freightRate: "",
    brokerCommission: "",
    loadPortOptions: "",
    dischargePortOptions: "",
    loadTerm: "",
    dischargeLoadTerm: "",
    dem: "",
    des: "",
    otherDetails: "",
    templatename: "",
  });
  const [isEditDataClicked, setIsEditDataClicked] = useState("save");

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "8px",
      align: "center",
      fixed: "left",
      render: (text, row, index) => (
        <div className="text-center">
          {(currentPage - 1) * pageSize + index + 1}
        </div>
      ),
    },
    {
      title: "Template Name",
      dataIndex: "templatename",
      key: "templatename",
      width: "10rem",
      fixed: "left",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Account For",
      dataIndex: "accountFor",
      key: "accountFor",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Cargo Details",
      dataIndex: "cargoDetails",
      key: "cargoDetails",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Cargo Name",
      dataIndex: "cargoName",
      key: "cargoName",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "CP [MT]",
      dataIndex: "cpQty",
      key: "cpQty",
      render: (text) => <div style={{ padding: "10px" }}>{text} mt</div>,
    },
    {
      title: "Min Qty [MT]",
      dataIndex: "minQty",
      key: "minQty",
      render: (text) => <div style={{ padding: "10px" }}>{text} mt</div>,
    },
    {
      title: "Max Qty [MT]",
      dataIndex: "maxQty",
      key: "maxQty",
      render: (text) => <div style={{ padding: "10px" }}>{text} mt</div>,
    },
    {
      title: "Freight Rate",
      dataIndex: "freightRate",
      key: "freightRate",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Broker Commission",
      dataIndex: "brokerCommission",
      key: "brokerCommission",
      width: "9rem",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Loadport Options",
      dataIndex: "loadPortOptions",
      key: "loadPortOptions",
      width: "8rem",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Discharge Port Options",
      dataIndex: "dischargePortOptions",
      key: "dischargePortOptions",
      width: "10rem",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Load Term",
      dataIndex: "loadTerm",
      key: "loadTerm",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Discharge Load Term",
      dataIndex: "dischargeLoadTerm",
      key: "dischargeLoadTerm",
      width: "10rem",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "DEM",
      dataIndex: "dem",
      key: "dem",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "DES",
      dataIndex: "des",
      key: "des",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Other Details",
      dataIndex: "otherDetails",
      key: "otherDetails",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (row) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              padding: "0 5px",
            }}
          >
            <div
              style={{ padding: "4px", cursor: "pointer" }}
              onClick={() => handleRowDelete(row)}
            >
              <Icon
                className="bg-icon-color"
                icon="tabler:trash"
                fontSize={16}
              />
            </div>
            <div
              style={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                handleEditData(row)
              }}
            >
              <Icon
                icon="tabler:edit"
                fontSize={16}
                className="bg-icon-color"
              />
            </div>
            {/* <div
              style={{ padding: "4px" }}
              onClick={() => {
                // handleEditData(row)
              }}
            >
              <Icon icon="tabler:eye" fontSize={16} className="bg-icon-color" />
            </div> */}
          </div>
        </>
        // <Space size="middle">
        //   <Button
        //     type="primary"
        //     icon={<EyeOutlined />}
        //     onClick={() =>
        //       notification.info({
        //         message: "Row Details",
        //         description: JSON.stringify(row, null, 2),
        //       })
        //     }
        //   />
        //   <Button
        //     type="primary"
        //     icon={<EditOutlined />}
        //     // onClick={() => handleEditData(row)}
        //   />
        //   <Popconfirm
        //     title="Are you sure delete this task?"
        //     // onConfirm={() => handleRowDelete(row)}
        //     okText="Yes"
        //     cancelText="No"
        //   >
        //     <Button type="primary" danger icon={<DeleteOutlined />} />
        //   </Popconfirm>
        // </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllMarketOrderRecords();
  }, []);

  const getAllMarketOrderRecords = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/market-order`;
      const response = await getAPICall(url);
      //   if (response) {
      //     dispatch(setSpinner(false));
      //   }
      if (response.success) {
        const formattedData = response.data.map((tonnagedata) => ({
          accountFor: tonnagedata.account_for,
          templatename: tonnagedata.templatename,
          cargoDetails: tonnagedata.cargo_details,
          cargoName: tonnagedata.cargo_name,
          cpQty: tonnagedata.cp_qty,
          minQty: tonnagedata.min_qty,
          maxQty: tonnagedata.max_qty,
          id: tonnagedata.id,
          freightRate: tonnagedata.fright_rate,
          brokerCommission: tonnagedata.broker_commission,
          loadPortOptions: tonnagedata.load_port_options,
          dischargePortOptions: tonnagedata.discharge_port_options,
          loadTerm: tonnagedata.load_term,
          dischargeLoadTerm: tonnagedata.discharge_load_term,
          dem: tonnagedata.dem,
          des: tonnagedata.des,
          otherDetails: tonnagedata.other_details,
        }));
        setFormattedData(formattedData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

 
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      handleChange({
        target: { name: "openFromDate", value: dateRangeString },
      });
    }
  };

  const handelDate = (event) => {
    const dateValue = event.target.value;
    setFormData({ ...formData, builtYear: dateValue });
  };

  const handleDateChange = (dates, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = moment(new Date(dates[0])).format(
        "YYYY/MM/DD"
      );
      const formattedEndDate = moment(new Date(dates[1])).format("YYYY/MM/DD");
      const dateRangeString = `${formattedStartDate} - ${formattedEndDate}`;
      //   setDateRange(dateRangeString)
      handleChange({
        target: { name: "openFromDate", value: dateRangeString },
      });
    }
  };

  const handleSingleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(new Date(date)).format("YYYY/MM/DD");
      handleChange({ target: { name: "builtYear", value: formattedDate } });
    }
  };

  const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formData.templatename) newErrors.templatename = 'Template name is required';
        if (!formData.accountFor) newErrors.accountFor = 'Account For is required';
        if (!formData.cargoName) newErrors.cargoName = 'Cargo name is required';
        return newErrors;
    };

  const handleSubmitData = async() => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/market-order`;
        const payload = {
            "send_to": '',
            "send_from": '',
            "subject": '',
            "templatename": formData.templatename,
            'option': '',
            "account_for": formData.accountFor,
            "cargo_details": formData.cargoDetails,
            "cargo_name": formData.cargoName,
            "cp_qty": formData.cpQty,
            "max_qty": parseInt(formData.maxQty),
            "min_qty": parseInt(formData.minQty),
            "fright_rate": formData.freightRate,
            "broker_commission": formData.brokerCommission,
            "load_port_options": formData.loadPortOptions,
            "discharge_port_options": formData.dischargePortOptions,
            "load_term": formData.loadTerm,
            "discharge_load_term": formData.dischargeLoadTerm,
            "dem": parseInt(formData.dem),
            "des": formData.des,
            "other_details": formData.otherDetails,
        }
          const response = await postAPICall(url,payload,"post");
          if(response?.success){
            openNotificationWithIcon('success',"Template created successfully.")
            setIsAddCargoModalOpen(false);
            getAllMarketOrderRecords();
            setFormData({})
            setErrors({})
          }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDelete = (rowIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this template!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/market/${rowIndex?.id}`;
        const response = await postAPICall(url, {}, "delete");
        if (response.status === "1") {
          openNotificationWithIcon("success", "Template deleted successfully.");
          getAllMarketOrderRecords();
        }
      }
    });
  };

  const handleEditData = (row) =>{
    setFormData({
      to: '',
      from: '',
      subject: '',
      id: row?.id,
      templatename: row?.templatename,
      option: '',
      accountFor: row?.accountFor,
      cargoDetails: row?.cargoDetails,
      cargoName: row?.cargoName,
      cpQty: row?.cpQty,
      minQty: row?.minQty,
      maxQty: row?.maxQty,
      freightRate: row?.freightRate,
      brokerCommission: row?.brokerCommission,
      loadPortOptions: row?.loadPortOptions,
      dischargePortOptions: row?.dischargePortOptions,
      loadTerm: row?.loadTerm,
      dischargeLoadTerm: row?.dischargeLoadTerm,
      dem: row?.dem,
      des: row?.des,
      otherDetails: row?.otherDetails
  })
    setIsEditDataClicked("edit")
    setIsAddCargoModalOpen(true);
  }

  const handleClose = () => {};
  const handleUpdate = async() => {
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/market/${formData?.id}`;
        const payload = {
            "send_to": '',
            "send_from": '',
            "subject": '',
            "templatename": formData.templatename,
            'option': '',
            "account_for": formData.accountFor,
            "cargo_details": formData.cargoDetails,
            "cargo_name": formData.cargoName,
            "cp_qty": formData.cpQty,
            "max_qty": parseInt(formData.maxQty),
            "min_qty": parseInt(formData.minQty),
            "fright_rate": formData.freightRate,
            "broker_commission": formData.brokerCommission,
            "load_port_options": formData.loadPortOptions,
            "discharge_port_options": formData.dischargePortOptions,
            "load_term": formData.loadTerm,
            "discharge_load_term": formData.dischargeLoadTerm,
            "dem": parseInt(formData.dem),
            "des": formData.des,
            "other_details": formData.otherDetails,
        }
          const response = await postAPICall(url,payload,"put");
          if(response?.status=="1"){
            openNotificationWithIcon('success',"Template updated successfully.")
            setIsAddCargoModalOpen(false);
            getAllMarketOrderRecords();
            setFormData({})
            setErrors({})
          }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div style={{ textAlign: "end", marginBottom: "10px" }}>
          <Button onClick={() => {
            setFormData({})
            setIsEditDataClicked("save")
            setIsAddCargoModalOpen(true);
          }}>
            <Icon
              icon="ic:baseline-plus"
              className="bg-icon-color"
              fontSize={20}
            />
            <span>Add New </span>
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={formattedData}
          pagination={{ pageSize }}
          scroll={{ x: "max-content" }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Cargo Template Form"
        footer={null}
        open={isAddCargoModalOpen}
        onCancel={() => setIsAddCargoModalOpen(false)}
      >
        <Form layout="vertical" style={{ width: "100%" }}>
          <Row gutter={16} style={{ rowGap: "10px" }}>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>
                  Template Name:{" "}
                  <Typography.Text type="danger">*</Typography.Text>
                </Col>
                <Col span={14}>
                  <Input
                    name="templatename"
                    value={formData.templatename}
                    onChange={handleChange}
                    placeholder="Enter Template Name"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>
                  Account For:{" "}
                  <Typography.Text type="danger">*</Typography.Text>
                </Col>
                <Col span={14}>
                  <Input
                    name="accountFor"
                    value={formData.accountFor}
                    onChange={handleChange}
                    placeholder="Account for"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>
                  Cargo Name <Typography.Text type="danger">*</Typography.Text>
                </Col>
                <Col span={14}>
                  <Input
                    name="cargoName"
                    value={formData.cargoName}
                    onChange={handleChange}
                    placeholder="Enter Cargo Name"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>
                  Cargo Details{" "}
                  <Typography.Text type="danger">*</Typography.Text>
                </Col>
                <Col span={14}>
                  <Input
                    name="cargoDetails"
                    value={formData.cargoDetails}
                    onChange={handleChange}
                    placeholder="Enter Cargo Details"
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>CP Qty:</Col>
                <Col span={14}>
                  <Input
                    name="cpQty"
                    value={formData.cpQty}
                    onChange={handleChange}
                    placeholder="0.0"
                    addonAfter="MT"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Option%:</Col>
                <Col span={14}>
                  <Input
                    name="option"
                    value={formData.option}
                    onChange={handleChange}
                    placeholder="0.0"
                    addonAfter="MT"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Min Qty:</Col>
                <Col span={14}>
                  <Input
                    name="minQty"
                    value={formData.minQty}
                    onChange={handleChange}
                    placeholder="0.0"
                    addonAfter="MT"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Max Qty:</Col>
                <Col span={14}>
                  <Input
                    name="maxQty"
                    value={formData.maxQty}
                    onChange={handleChange}
                    placeholder="0.0"
                    addonAfter="MT"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Freight Rate:</Col>
                <Col span={14}>
                  <Input
                    name="freightRate"
                    value={formData.freightRate}
                    onChange={handleChange}
                    placeholder="Enter IMO number"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Broker Commission %</Col>
                <Col span={14}>
                  <Input
                    name="brokerCommission"
                    value={formData.brokerCommission}
                    onChange={handleChange}
                    placeholder="0.0"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Load Port Options:</Col>
                <Col span={14}>
                  <Input
                    name="loadPortOptions"
                    value={formData.loadPortOptions}
                    onChange={handleChange}
                    placeholder=""
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Discharge Port Options:</Col>
                <Col span={14}>
                  <Input
                    name="dischargePortOptions"
                    value={formData.dischargePortOptions}
                    onChange={handleChange}
                    placeholder=""
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Load Term:</Col>
                <Col span={14}>
                  <Input
                    name="loadTerm"
                    value={formData.loadTerm}
                    onChange={handleChange}
                    placeholder=""
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Discharge Load Term:</Col>
                <Col span={14}>
                  <Input
                    name="dischargeLoadTerm"
                    value={formData.dischargeLoadTerm}
                    onChange={handleChange}
                    placeholder=""
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>DEM:</Col>
                <Col span={14}>
                  <Input
                    name="dem"
                    value={formData.dem}
                    onChange={handleChange}
                    type="number"
                    placeholder="0.0"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>DES:</Col>
                <Col span={14}>
                  <Input
                    name="des"
                    value={formData.des}
                    onChange={handleChange}
                    type="number"
                    placeholder="0.0"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={10}>Other Details:</Col>
                <Col span={14}>
                  <Input
                    name="otherDetails"
                    value={formData.otherDetails}
                    onChange={handleChange}
                    placeholder="Enter Other Details"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ textAlign: "right", marginTop: "16px" }}>
            <Button
              type="primary"
              onClick={
                isEditDataClicked === "edit" ? handleUpdate : handleSubmitData
              }
            >
              {isEditDataClicked === "edit" ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CargoTemplate;
