 export const findspeed = (speedarr, spdType, passageType) => {
  let speed = 0;
  if (spdType === "1" && passageType === "1") {
    speed = speedarr[1]["ballast_spd"];
  } else if (spdType === "1" && passageType === "2") {
    speed = speedarr[1]["laden_spd"];
  } else if (spdType === "2" && passageType === "1") {
    speed = speedarr[0]["ballast_spd"];
  } else if (spdType === "2" && passageType === "2") {
    speed = speedarr[0]["laden_spd"];
  } else if (spdType === "3" && passageType === "1") {
    speed = speedarr[2]["ballast_spd"];
  } else if (spdType === "3" && passageType === "2") {
    speed = speedarr[2]["laden_spd"];
  }
  return speed;
};

export const calculateSeadays = (miles, speed) => {
  let _miles = isNaN(parseFloat(miles)) ? 0 : miles;
  let _speed = isNaN(parseFloat(speed)) ? 0 : speed;
  return speed > 0 ? parseFloat(_miles / (_speed * 24)).toFixed(2) : 0;
};
