import { Card, Col, Row, Skeleton } from "antd";
import DashboardNotification from "./DashboardNotification";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "chart.js/auto";
import { postAPICall } from "../../shared";
import moment from "moment";
import Slider from "react-slick";
import MailCount from "./MailCount";
import AllMailChart from "./AllMailChart";

const LineChart = memo(({ chartData }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(ctx, {
          type: "line",
          data: chartData,
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              tooltip: {
                caretPadding: 15,
                caretSize: 10,
                cornerRadius: 6,
                displayColors: false,
              },
            },
          },
        });
      }
    }
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <div style={{ height: "300px", display: "flex", justifyContent: "center" }}>
      <canvas ref={chartContainer} />
    </div>
  );
});

const PieChart = memo(({ pieChartData }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "pie",
          data: {
            labels: pieChartData.map((data) => data.field),
            datasets: [
              {
                data: pieChartData.map((data) => data.count),
                backgroundColor: [
                  "#ffdd00",
                  "#1dad9b",
                  "#2772db",
                  "pink",
                  "tomato",
                  "orange",
                  "voilet",
                  "lime",
                  "lightbrown",
                ],
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
                labels: {
                  usePointStyle: true, // Use point style to show icons
                },
              },
            },
          },
        });
      }
    }
  }, []);

  return (
    <div style={{ height: "300px", display: "flex", justifyContent: "center" }}>
      <canvas ref={chartContainer} style={{ height: "100% !important" }} />
    </div>
  );
});

const BarChart = memo(({ barChartData }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: barChartData.map((data) => data.date),
            datasets: [
              {
                label: "Other",
                data: barChartData.map((data) => data.other),
                backgroundColor: "#1dad9b",
              },
              {
                label: "Cargo",
                data: barChartData.map((data) => data.cargo),
                backgroundColor: "#ffdd00",
              },
              {
                label: "Tonnage",
                data: barChartData.map((data) => data.tonnage),
                backgroundColor: "#2772db",
              },
              {
                label: "Fixture",
                data: barChartData.map((data) => data.fixture || 0), // Handle optional field
                backgroundColor: "#4ef037",
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
                labels: {
                  usePointStyle: true, // Use point style to show icons
                },
              },
            },
            animations: {
              tension: {
                duration: 1000,
                easing: "linear",
                from: 1,
                to: 0,
                loop: true,
              },
            },
          },
        });
      }
    }
  }, [barChartData]);

  return (
    <div style={{ height: "300px", display: "flex", justifyContent: "center" }}>
      <canvas ref={chartContainer} />
    </div>
  );
});

const MailDashboard = () => {
  const [mailData, setMailData] = useState([]);
  const [lineChartLoading, setlineChartLoading] = useState(true);
  const [emailCountTypewiseData, setEmailCountTypewiseData] = useState();
  const [linechartData, setLineChartData] = useState([]);
  const [barChartLoading, setbarChartLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [countedData, setCountedData] = useState();

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function getDates() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 6);

    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
  }

  const { startDate, endDate } = getDates();

  // Aggregate data for pie chart
  const aggregatePieChartData = (data) => {
    const aggregatedData = {};

    data?.forEach((item) => {
      item.data.forEach((dataItem) => {
        const { field, count } = dataItem;
        if (aggregatedData[field]) {
          aggregatedData[field] += count;
        } else {
          aggregatedData[field] = count;
        }
      });
    });

    const aggregatedChartData = Object.keys(aggregatedData).map((field) => ({
      field,
      count: aggregatedData[field],
    }));

    return aggregatedChartData;
  };

  const getEmailCount = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/dashboard_total_email_counts`;
      const resp = await postAPICall(url, {}, "POST");

      if (resp.status && resp.data) {
        setCountedData(resp.data);
        setLoading(false);
      } else {
        console.error("Something went wrong");
        // setCountedData([]);
      }
    } catch (e) {
      console.error(e);
      // setCountedData([])
    }
  };

  const getEmailCountTypewise = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/dashboard_email_counts_typewise`;

      const payload = {
        start_date: startDate,
        end_date: endDate,
      };

      const resp = await postAPICall(url, payload, "POST");

      if (resp.status) {
        setEmailCountTypewiseData(resp.data);
        setbarChartLoading(false);
      } else {
        console.error("Something went wrong");
        setbarChartLoading(false);
        setEmailCountTypewiseData([]);
      }
    } catch (e) {
      console.error(e);
      setbarChartLoading(false);
      setEmailCountTypewiseData([]);
    }
  };

  const getEmailCountDaywise = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/dashboard_email_counts_daywise`;
      const payload = {
        start_date: startDate,
        end_date: endDate,
      };
      const resp = await postAPICall(url, payload, "POST");

      if (resp.status) {
        setLineChartData(resp.data);
        setlineChartLoading(false);
      } else {
        console.error(resp.msg || resp.error);
        setlineChartLoading(false);
        setLineChartData([]);
      }
    } catch (e) {
      console.error(e);
      setlineChartLoading(false);
      setLineChartData([]);
    }
  };

  useEffect(() => {
    getEmailCountTypewise();
    getEmailCountDaywise();
    getEmailCount();
  }, []);

  // Transform emailCountTypewiseData into a format suitable for bar chart
  const transformDataForBarChart = (data) => {
    const transformedData = [];

    data?.forEach((item) => {
      const date = item.date;
      const counts = {
        other: 0,
        cargo: 0,
        tonnage: 0,
        fixture: 0,
      };

      item.data.forEach((dataItem) => {
        if (dataItem.field === "other") counts.other = dataItem.count;
        else if (dataItem.field === "cargo") counts.cargo = dataItem.count;
        else if (dataItem.field === "tonnage") counts.tonnage = dataItem.count;
        else if (dataItem.field === "fixture") counts.fixture = dataItem.count;
      });

      transformedData.push({
        date: moment(date).format("DD/MM/YYYY"),
        ...counts,
      });
    });

    return transformedData;
  };

  // Prepare data for the bar chart

  const prepareLineChartData = (data, name) => {
    return {
      labels: data?.map((item) => moment(item.date).format("DD/MM/YYYY")),
      datasets: [
        {
          label: name,
          data: data?.map((item) => item.count),
          borderColor:
            name === "Received Mails"
              ? "rgb(250, 90, 125)"
              : "rgb(255, 148, 122)",
          fill: true,
          backgroundColor:
            name === "Received Mails"
              ? "rgb(255, 226, 229)"
              : "rgb(255, 244, 222)",
        },
      ],
    };
  };

  // Prepare data for sent emails
  const receivedChartData = useMemo(
    () =>
      prepareLineChartData(
        linechartData && linechartData[0]?.receivedEmails,
        "Received Mails"
      ),
    [linechartData]
  );
  const sentChartData = useMemo(
    () =>
      prepareLineChartData(
        linechartData && linechartData[0]?.sentEmails,
        "Sent Mails"
      ),
    [linechartData]
  );

  const pieChartData = useMemo(
    () => aggregatePieChartData(emailCountTypewiseData),
    [emailCountTypewiseData]
  );
  const barChartData = useMemo(
    () => transformDataForBarChart(emailCountTypewiseData),
    [emailCountTypewiseData]
  );

  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "4px" }}>
        Email Analytics Dashboard
      </h2>
      <span
        style={{
          fontSize: "20px",
          display: "block",
          textAlign: "center",
          textDecoration: "none",
          color: "#c3195d",
        }}
      >
        Empowering Businesses Through Email Data
      </span>

      <div className="dashboard-wrapper" style={{ padding: "14px" }}>
        <Row gutter={16}>
          <Col xs={24} md={10} lg={10} xl={10} style={{ padding: "18px" }}>
            <Card style={{ width: "100%", height: "100%" }}>
              <MailCount isShow={true} />
            </Card>
          </Col>

          <Col xs={24} md={14} lg={14} xl={14} style={{ padding: "18px" }}>
            <Slider arrows autoplay accessibility>
              <div>
                <Card title="Latest Notification">
                  <DashboardNotification />
                </Card>
              </div>
              <div>
                <Card style={{ textAlign: "center" }}>
                  <AllMailChart />
                </Card>
              </div>
            </Slider>
          </Col>

          <Col
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingBottom: "16px" }}
          >
            <Card
              title="Total Sent Mails (last 7 days)"
              style={{ textAlign: "center" }}
            >
              {lineChartLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  animation="pulse"
                  className="globalLoading"
                >
                  Loading...
                </Skeleton>
              ) : (
                <LineChart chartData={sentChartData} />
              )}
            </Card>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingBottom: "16px" }}
          >
            <Card
              title="Total Received Mails (last 7 days)"
              style={{ textAlign: "center" }}
            >
              {lineChartLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  animation="pulse"
                  className="globalLoading"
                >
                  Loading...
                </Skeleton>
              ) : (
                <LineChart chartData={receivedChartData} />
              )}
            </Card>
          </Col>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card
              title="Last 7 days Analytics based on Mail Types"
              style={{ textAlign: "center" }}
            >
              {barChartLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  animation="pulse"
                  className="globalLoading"
                >
                  Loading...
                </Skeleton>
              ) : (
                <PieChart pieChartData={pieChartData} />
              )}
            </Card>
          </Col>

          <Col xs={12} md={12} lg={12} xl={12}>
            <Card
              title="Last 7 days Analytics based on Date"
              style={{ textAlign: "center" }}
            >
              {barChartLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  animation="pulse"
                  className="globalLoading"
                >
                  Loading...
                </Skeleton>
              ) : (
                <BarChart barChartData={barChartData} />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MailDashboard;
