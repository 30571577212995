import React, { Component } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Button,
  Checkbox,
  Avatar,
  Badge,
  DatePicker,
  Select,
  Modal,
} from "antd";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { TABLE_FIELDS } from "./fields";
import URL_WITH_VERSION, {
  CALL_MASTER_API,
  getAPICall,
  GET_STATUS_BADGE,
  GET_STATUS_TEXT,
  openNotificationWithIcon,
  ResizeableTitle,
} from "..";
import ToolbarUI from "../../components/CommonToolbarUI/toolbar_index";
import SidebarColumnFilter from "../SidebarColumnFilter";
import moment from "moment";
import RightContextMenu from "../RightContextMenu";

const FormItem = Form.Item;
const { TextArea } = Input;
const EditableContext = React.createContext();
const Option = Select.Option;

class EditableCell extends React.Component {
  updateData = (value, field, options = undefined) => {
    const { record } = this.props;
    record[field] = value;
    if (options && options.length > 0) {
      let option = options.filter((e) => e.value === value);
      if (
        option &&
        option.length === 1 &&
        option[0].pv &&
        option[0].pv.length > 0 &&
        record.hasOwnProperty(option[0].pv[1])
      ) {
        record[option[0].pv[1]] = option[0].pv[0];
      }
    }
  };

  getInput = () => {
    let { inputType, title, dataIndex, f_type, record, f_dyc_extras } =
      this.props;
    switch (f_type) {
      case "boolean":
        return (
          <Checkbox
            name={dataIndex}
            key={dataIndex}
            onChange={(e) =>
              this.updateData(e.target.checked ? 1 : 0, dataIndex)
            }
            checked={record[dataIndex] ? true : false}
          />
        );
      case "textarea":
        return (
          <TextArea
            key={dataIndex}
            name={dataIndex}
            placeholder={"Enter " + title}
            onChange={(e) => this.updateData(e.target.value, dataIndex)}
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        );
      case "number":
        return (
          <InputNumber
            key={dataIndex}
            min={0}
            type={inputType}
            name={dataIndex}
            placeholder={"Enter " + title}
            onChange={(e) => this.updateData(e, dataIndex)}
          />
        );
      case "datetime":
        return (
          <DatePicker
            showTime
            key={dataIndex}
            name={dataIndex}
            placeholder={"Select " + title}
            onChange={(value, dateString) =>
              this.updateData(dateString, dataIndex)
            }
          />
        );
      case "dropdown":
        return (
          <Select
            key={dataIndex}
            showSearch
            placeholder={"Select " + title}
            onChange={(e) =>
              this.updateData(e, dataIndex, f_dyc_extras.options)
            }
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {f_dyc_extras &&
            f_dyc_extras.options &&
            f_dyc_extras.options.length > 0
              ? f_dyc_extras.options.map((e) => {
                  return (
                    <Option value={e.value.toString()} key={e.value}>
                      {e.label}
                    </Option>
                  );
                })
              : null}
          </Select>
        );
      default:
        return (
          <Input
            key={dataIndex}
            type="text"
            name={dataIndex}
            placeholder={"Enter " + title}
            onChange={(e) => this.updateData(e.target.value, dataIndex)}
          />
        );
    }
  };

  getText = () => {
    const { children, f_type, record, dataIndex, f_dyc_extras } = this.props;
    switch (f_type) {
      case "boolean": {
        return record[dataIndex] ? "True" : "False";
      }
      case "dropdown": {
        if (
          f_dyc_extras &&
          f_dyc_extras.options &&
          f_dyc_extras.options.length > 0
        ) {
          let index = f_dyc_extras.options.findIndex(
            (v) =>
              ("" + v.value).toLowerCase() ===
              ("" + record[dataIndex]).toLowerCase()
          );
          if (index > -1) {
            return f_dyc_extras.options[index].hasOwnProperty("show")
              ? f_dyc_extras.options[index]["show"]
              : f_dyc_extras.options[index]["label"];
          }
        }
        return children;
      }
      default:
        return children;
    }
  };

  getDefaultValue = () => {
    const { f_type, record, dataIndex, f_dyc_extras } = this.props;
    switch (f_type) {
      case "datetime": {
        record[dataIndex] = record[dataIndex]
          ? moment.utc(record[dataIndex]).format("YYYY-MM-DD HH:mm:ss")
          : undefined;
        return record[dataIndex] ? moment(record[dataIndex]) : undefined;
      }
      case "dropdown": {
        if (
          f_dyc_extras &&
          f_dyc_extras.options &&
          f_dyc_extras.options.length > 0
        ) {
          let index = f_dyc_extras.options.findIndex(
            (v) => v.value === record[dataIndex]
          );
          if (index > -1) {
            return f_dyc_extras.options[index]["label"];
          }
        }
        return record && (record[dataIndex] || record[dataIndex] === 0)
          ? record[dataIndex]
          : undefined;
      }
      default:
        return record && (record[dataIndex] || record[dataIndex] === 0)
          ? record[dataIndex]
          : undefined;
    }
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      f_req,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {/* {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: f_req,
                      message: `Please ${inputType === "dropdown" ? "Select" : "Input"} ${title}!`,
                    }],
                    initialValue: this.getDefaultValue(),
                  })(this.getInput())}
                </FormItem>
              ) : this.getText()}
            </td>
          );
        }} */}
      </EditableContext.Consumer>
    );
  }
}

class EditableTable extends Component {
  constructor(props) {
    super(props);
    const actionOptions = {
      title: "Actions",
      dataIndex: "action",
      invisible: "false",
      render: (text, record) => {
        const editable = this.isEditing(record);
        return (
          <div className="editable-row-operations">
            {editable ? (
              <span>
                <EditableContext.Consumer>
                  {(form) => {
                    return (
                      <span
                        className="iconWrapper save"
                        onClick={(e) => this.save(e, form, record.id)}
                      >
                        <SaveOutlined />
                      </span>
                    );
                  }}
                </EditableContext.Consumer>
                <span className="iconWrapper cancel">
                  <Popconfirm
                    title="Sure to cancel?"
                    onConfirm={() => this.cancel(record.id)}
                  >
                    <CloseOutlined />
                  </Popconfirm>
                </span>
              </span>
            ) : undefined}
          </div>
        );
      },
    };
    this.state = {
      data: [],
      dataNew: [],
      noShowList: this.props.noShowList || {},
      editingKey: null,
      tableColumn: [],
      actionOptions,
      tableKey: null,
      apiKey: null,
      pageOptions: { pageLimit: 20, pageIndex: 1, totalRows: 0 },
      loading: false,
      pageWidths: null,
      searchField: null,
      isColumnSelection: false,
      search: {},
      sidebarVisible: false,
      modalEvents: { visible: false },
      optionsData: [],
      addOption: false,
      typesearch: {},
      donloadArray: [],
      rightClickMenu: {
        visible: false,
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    // table = document.querySelector('.inlineTable .ant-table-body')
    this.getTableData();

    this.state.tableColumn.map((item, index) => {
      if (item.f_type === "dropdown") {
        this.generateOptions(item, index);
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tableKey && state.tableColumn.length === 0) {
      let currentProps =
        props && props.tableKey && TABLE_FIELDS
          ? TABLE_FIELDS[props.tableKey]
          : [];
      let tableColumn =
        TABLE_FIELDS && currentProps && currentProps["tableheads"]
          ? Object.assign([], currentProps["tableheads"])
          : [];

      //assigning actions in this fields
      if (tableColumn.length > 0) {
        tableColumn.map((item, index) => {
          if (item.dataIndex === "flag") {
            let renderFlag = {
              ...tableColumn[index],
              render: (text) => (
                <Avatar src={"data:image/png;base64," + text} />
              ),
            };
            tableColumn[index] = renderFlag;
          } else if (
            ["status", "is_active", "c_active"].includes(item.dataIndex)
          ) {
            let renderFlag = {
              ...tableColumn[index],
              render: (text) => (
                <Badge
                  count={GET_STATUS_TEXT(text)}
                  style={{ backgroundColor: GET_STATUS_BADGE(text) }}
                />
              ),
            };
            tableColumn[index] = renderFlag;
          }
        });

        tableColumn.push(state.actionOptions);
      }

      return {
        tableKey: props.tableKey,
        tableColumn,
        editingKey: null,
        searchField: null,
        //   addOption:currentProps["addOption"],
        apiKey:
          TABLE_FIELDS && currentProps && currentProps["key"]
            ? currentProps["key"]
            : null,
        pageWidths:
          TABLE_FIELDS && currentProps && currentProps["pageWidths"]
            ? currentProps["pageWidths"]
            : null,
        isColumnSelection:
          TABLE_FIELDS && currentProps && currentProps["isColumnSelection"]
            ? currentProps["isColumnSelection"]
            : false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    //alert("sdsdssdsds");
    let currentProps = this.props;

    if (prevProps.tableKey !== currentProps.tableKey) {
      //let currentProps = (currentProps && currentProps.tableKey ? TABLE_FIELDS[currentProps.tableKey] : []);

      let tableColumn =
        TABLE_FIELDS && currentProps && currentProps["tableheads"]
          ? currentProps["tableheads"]
          : [];

      tableColumn.map((item, index) => {
        if (item.f_type === "dropdown") {
          this.generateOptions(item, index);
        }
      });

      this.setState(
        {
          tableKey: currentProps.tableKey,
          data: [],
          dataNew: [],
          editingKey: null,
          apiKey:
            TABLE_FIELDS && currentProps && currentProps["key"]
              ? currentProps["key"]
              : TABLE_FIELDS[currentProps["tableKey"]] &&
                TABLE_FIELDS[currentProps["tableKey"]]["key"]
              ? TABLE_FIELDS[currentProps["tableKey"]]["key"]
              : undefined,
          pageWidths:
            TABLE_FIELDS && currentProps && currentProps["pageWidths"]
              ? currentProps["pageWidths"]
              : null,
          tableColumn,
          postSaveData: {},
          pageIndex: 1,
          searchField: null,
          isColumnSelection:
            TABLE_FIELDS && currentProps && currentProps["isColumnSelection"]
              ? currentProps["isColumnSelection"]
              : false,
        },
        () => {
          this.getTableData();
        }
      );
    }
  }

  generateOptions = async (item, index) => {
    let { f_dyc_extras } = item;

    if (
      f_dyc_extras &&
      f_dyc_extras.hasOwnProperty("url") &&
      f_dyc_extras.url
    ) {
      const orderBy =
        f_dyc_extras && f_dyc_extras.orderBy ? f_dyc_extras.orderBy : "id";
      const headers = {
        order_by: { [orderBy]: "desc" },
        where: f_dyc_extras.whereClause,
      };
      const selectCols = f_dyc_extras.selectCols;
      const _url = `${URL_WITH_VERSION}/${f_dyc_extras.url}`;
      const response = await getAPICall(_url, headers);
      const data = await response;

      let dataArr = data && data.data ? data.data : [];
      if (data.total_rows > 0) {
        let options = [],
          lobj = {};
        dataArr.map((e, i) => {
          if (e.hasOwnProperty(selectCols.value)) {
            lobj = {
              value: e[selectCols.value],
              pv:
                selectCols.pv && selectCols.pv.length > 0
                  ? [e[selectCols.pv[0]], selectCols.pv[1]]
                  : undefined,
            };
            if (selectCols.label && typeof selectCols.label === "string") {
              lobj["label"] = e[selectCols.label];
            } else if (
              selectCols.label &&
              typeof selectCols.label !== "string" &&
              selectCols.label.hasOwnProperty("length") &&
              selectCols.label.length > 0
            ) {
              selectCols.label.map(
                (sl) =>
                  (lobj["label"] =
                    sl && e.hasOwnProperty(sl)
                      ? lobj["label"] && lobj["label"] !== ""
                        ? lobj["label"] + "-" + e[sl]
                        : e[sl]
                      : "")
              );
            }
            lobj["show"] = e[selectCols.show]
              ? e[selectCols.show]
              : e[selectCols.value];
            options.push(lobj);
          }
        });

        item.f_dyc_extras.options = options;

        let { tableColumn } = this.state;
        tableColumn[index] = item;

        this.setState({
          ...this.state,
          tableColumn,
          optionsData: data,
        });
      }
    }
  };

  getTableData = (searchtype = {}) => {
    const { apiKey, pageOptions } = this.state;
    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };
    let headers = { order_by: { id: "desc" } };
    let search =
      searchtype &&
      searchtype.hasOwnProperty("searchOptions") &&
      searchtype.hasOwnProperty("searchValue")
        ? searchtype
        : this.state.typesearch;

    if (
      search &&
      search.hasOwnProperty("searchValue") &&
      search.hasOwnProperty("searchOptions") &&
      search["searchOptions"] !== "" &&
      search["searchValue"] !== ""
    ) {
      let wc = {};
      search["searchValue"] = search["searchValue"].trim();
      if (search["searchOptions"].indexOf(";") > 0) {
        let so = search["searchOptions"].split(";");
        wc = { OR: {} };
        so.map((e) => (wc["OR"][e] = { l: search["searchValue"] }));
      } else {
        wc[search["searchOptions"]] = { l: search["searchValue"] };
      }

      headers["where"] = wc;
      this.state.typesearch = {
        searchOptions: search.searchOptions,
        searchValue: search.searchValue,
      };
    }

    if (apiKey) {
      this.setState({ ...this.state, loading: true, data: [] });
      CALL_MASTER_API("get", apiKey, null, qParams, headers, (data) => {
        const totalRows = data && data.total_rows ? data.total_rows : 0;
        let dataArr = data && data.data ? data.data : [];
        let state = { loading: false };
        let donloadArr = [];
        if (dataArr.length > 0 && totalRows > this.state.data.length) {
          dataArr.forEach((d) => donloadArr.push(d["id"]));
          state["data"] = dataArr; // [...this.state.data, ...dataArr];
        }
        this.setState(
          {
            ...this.state,
            ...state,
            donloadArray: donloadArr,
            pageOptions: {
              pageIndex: pageOptions.pageIndex,
              pageLimit: pageOptions.pageLimit,
              totalRows: totalRows,
            },
          },
          () => {
            // console.log("aaa", this.state);
          }
        );
      });
    }
  };

  isEditing = (record) => {
    return record.id ? record.id === this.state.editingKey : true;
  };

  edit = (tag, key) => {
    if (tag) {
      this.setState({ editingKey: key, dataNew: [] });
    }
  };

  onSaveEdit = (method, postData) => {
    const { apiKey } = this.state;
    Object.keys(postData).forEach(
      (key) => postData[key] === null && delete postData[key]
    );

    CALL_MASTER_API(method, apiKey, postData, null, (data) => {
      if (data.data) {
        openNotificationWithIcon("success", data.message);
        this.setState(
          {
            ...this.state,
            data: [],
            dataNew: [],
            loading: true,
            editingKey: "",
          },
          () => {
            this.getTableData();
          }
        );
      } else {
        let dataMessage = data.message;
        let msg = "<div class='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div class='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }
        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
      }
    });
  };

  save = async (e, form, key) => {
    e.preventDefault();
    form?.validateFields(async (error, row) => {
      if (error) {
        let msg = "<div class='row'>";

        Object.keys(error).map((i) => {
          if (
            error[i].hasOwnProperty("errors") &&
            error[i]["errors"].length > 0
          ) {
            msg +=
              "<div class='col-sm-12'>" +
              error[i]["errors"][0]["message"] +
              "</div>";
          }
        });
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
        return;
      }

      const newData = [...this.state.data];
      const newEntry = this.state.dataNew;

      if (newEntry.length > 0) {
        const index = newEntry.findIndex((item) => key === item.id);
        if (index > -1) {
          const postData = newEntry[index];
          delete postData["id"];
          delete postData["isAddDelete"];
          delete postData["action"];
          this.onSaveEdit("post", postData);
          return;
        } else {
          this.setState({ data: newData, editingKey: "" });
        }
      } else {
        const index = newData.findIndex((item) => key === item.id);
        if (index > -1) {
          const item = newData[index];
          let postData = item;
          let isSave = false;
          if (postData.hasOwnProperty("isAddDelete")) {
            delete postData["id"];
            delete postData["isAddDelete"];
            delete postData["action"];
            isSave = true;
          }
          if (isSave) this.onSaveEdit("post", postData);
          if (!isSave) this.onSaveEdit("put", postData);
        } else {
          this.setState({ data: newData, editingKey: "" });
        }
      }
    });
  };

  onRowDeletedClick = (id) => {
    let postData = {},
      { data, apiKey } = this.state;

    if (data.length > 0) {
      postData["id"] = id;

      CALL_MASTER_API("delete", apiKey, postData, null, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message);
          this.setState(
            {
              ...this.state,
              data: [],
              loading: true,
              editingKey: null,
            },
            () => {
              this.getTableData();
            }
          );
        } else {
          openNotificationWithIcon("error", data.message);
        }
      });
    }
  };

  cancel = (id) => {
    setTimeout(() => {
      const { data } = this.state;
      let dataArr = [];
      data.map((v, i) => {
        if (v.id === id && v.hasOwnProperty("isAddDelete")) {
          delete data[i];
        } else {
          dataArr.push(v);
        }
      });
      this.setState({ editingKey: null, data: dataArr, dataNew: [] });
    }, 250);
  };

  getLastKey = () => {
    const totalRows = this.state?.pageOptions?.totalRows;

    console.log("totalrows", totalRows);
    return totalRows ? totalRows + 1 : 1;
  };

  getAddFieldData = (key) => {
    let { tableColumn } = this.state;

    let column = {};
    column["id"] = key;
    column["isAddDelete"] = true;
    column["editable"] = true;
    tableColumn.map((v) => {
      return (column[v.dataIndex] = null);
    });

    return column;
  };

  onChangeAddNew = () => {
    //const { data } = this.state;
    let key = "i" + this.getLastKey();

    let newData = this.getAddFieldData(key);
    console.log(key, newData);
    this.setState({
      ...this.state,
      dataNew: [newData],
      editingKey: key,
    });
    // Table.removeEventListener("scroll", this.handleScroll);
  };

  onFieldHide = (index) => {
    let tableColumn = Object.assign([], this.state.tableColumn);
    if (index || index === 0) {
      let table = tableColumn[index];
      table["invisible"] = table["invisible"] === "true" ? "false" : "true";
    }
    this.setState({
      ...this.state,
      tableColumn,
    });
  };

  callOptions = (evt) => {
    if (
      evt.hasOwnProperty("searchOptions") &&
      evt.hasOwnProperty("searchValue")
    ) {
      let pageOptions = this.state.pageOptions;
      let search = {
        searchOptions: evt["searchOptions"],
        searchValue: evt["searchValue"],
      };
      pageOptions["pageIndex"] = 1;
      this.setState(
        {
          ...this.state,
          search: search,
          pageOptions: pageOptions,
          editingKey: null,
        },
        () => {
          this.getTableData(evt);
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "reset-serach"
    ) {
      let pageOptions = this.state.pageOptions;
      pageOptions["pageIndex"] = 1;
      this.setState(
        {
          ...this.state,
          search: {},
          pageOptions: pageOptions,
          editingKey: null,
          typesearch: {},
        },
        () => {
          this.getTableData();
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "column-filter"
    ) {
      let responseData = this.state.data;
      let tableColumn = Object.assign([], this.state.tableColumn);
      if (responseData.length > 0) {
        for (var k in responseData[0]) {
          let index = tableColumn.some(
            (item) =>
              (item.hasOwnProperty("dataIndex") && item.dataIndex === k) ||
              (item.hasOwnProperty("key") && item.key === k)
          );
          if (!index) {
            let title = k
              .split("_")
              .map((snip) => {
                return snip[0].toUpperCase() + snip.substring(1);
              })
              .join(" ");
            let col = Object.assign(
              {},
              {
                title: title,
                dataIndex: k,
                key: k,
                invisible: "true",
                isReset: true,
              }
            );
            tableColumn.splice(tableColumn.length - 1, 0, col);
          }
        }
      }
      this.setState({
        ...this.state,
        sidebarVisible: evt.hasOwnProperty("sidebarVisible")
          ? evt.sidebarVisible
          : !this.state.sidebarVisible,
        editingKey: null,
        tableColumn: evt.hasOwnProperty("columns") ? evt.columns : tableColumn,
      });
    } else {
      let pageOptions = this.state.pageOptions;
      pageOptions[evt["actionName"]] = evt["actionVal"];

      if (evt["actionName"] === "pageLimit") {
        pageOptions["pageIndex"] = 1;
      }

      this.setState(
        { ...this.state, pageOptions: pageOptions, editingKey: null },
        () => {
          this.getTableData();
        }
      );
    }
  };

  //resizing function
  handleResize =
    (index) =>
    (e, { size }) => {
      this.setState(({ tableColumn }) => {
        const nextColumns = [...tableColumn];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { tableColumn: nextColumns };
      });
    };

  listenEvent = (event) => {
    if (event.eventType === "edit") {
      this.edit(event.rowAttr, event.record.id);
    } else if (event.eventType === "delete") {
      Modal.confirm({
        title: "Confirm",
        content: "Are you sure you want to delete?",
        okText: "Ok",
        cancelText: "Cancel",
        onOk: () => this.onRowDeletedClick(event.record.id),
      });
    }
  };

  onActionDonwload = async (downType, pageType) => {
    const token = localStorage.getItem("oceanToken");
    let params = `t=${pageType}`,
      cols = [];
    const { columns = [], pageOptions, donloadArray } = this.state;

    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };

    // Collect visible columns except those with key "action"
    if (columns.length > 0) {
      columns.forEach((e) => {
        if (e.invisible === "false" && e.key !== "action") {
          cols.push(e.dataIndex);
        }
      });
    } else {
      console.error("Columns are undefined or empty.");
    }

    if (cols.length > 0) {
      params = params + "&c=" + cols.join(",");
    }

    const filter = donloadArray.join(",");
    const url = `${process.env.REACT_APP_ATTACHMENT}/download/file/${downType}?${params}&l=${qParams.l}&ids=${filter}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlBlob = window.URL.createObjectURL(blob);
      link.href = urlBlob;
      link.download = `Data-Center.${downType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error("There was an error with the fetch operation:", error);
    }
  };

  render() {
    const components = {
      // body: {
      //   cell: EditableCell,
      // },
      header: {
        cell: ResizeableTitle,
      },
    };

    const {
      noShowList,
      addOption,
      tableKey,
      tableColumn,
      pageWidths,
      data,
      loading,
      totalRows,
      dataNew,
      apiKey,
      rightClickMenu,
      pageOptions,
      search,
      sidebarVisible /*, modalEvents */,
    } = this.state;

    const columns = tableColumn
      .filter((col) => (col && col.invisible !== "true" ? true : false))
      .map((col, i) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          ellipsis: true,
          onCell: (record) => ({
            ...col,
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: col.editable === "true" ? this.isEditing(record) : false,
          }),
          onHeaderCell: (column) => ({
            width: column.width,
            onResize: this.handleResize(i),
          }),
        };
      });

    return (
      <>
        <div
          className="section"
          style={{
            width: "100%",
            marginBottom: "10px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          {loading === false ? (
            <ToolbarUI
              routeUrl={"datacenter-toolbar"}
              optionValue={{
                pageOptions: pageOptions,
                columns: columns,
                search: search,
              }}
              masterkey={this.state.tableKey}
              callback={(e) => this.callOptions(e)}
              dowloadOptions={[
                {
                  title: "CSV",
                  event: () => this.onActionDonwload("csv", apiKey),
                },
                {
                  title: "PDF",
                  event: () => this.onActionDonwload("pdf", apiKey),
                },
                {
                  title: "XLS",
                  event: () => this.onActionDonwload("xlsx", apiKey),
                },
              ]}
            />
          ) : undefined}
        </div>
        <div
          className={`col-12 
                col-xs-${pageWidths && pageWidths.xs ? pageWidths.xs : "12"}
                col-sm-${pageWidths && pageWidths.sm ? pageWidths.sm : "12"}
                col-md-${pageWidths && pageWidths.md ? pageWidths.md : "12"}
                col-lg-${pageWidths && pageWidths.lg ? pageWidths.lg : "12"}
                `}
        >
          <div className="row">
            <div className="col-lg-12 abc">
              <EditableContext.Provider value={this.props.form}>
                <Table
                  className={
                    (tableColumn.length <= 6 ? "editableFixedHeader" : "") +
                    (dataNew.length > 0 ? " addNewTable" : "") +
                    " inlineTable resizeableTable"
                  }
                  components={components}
                  bordered
                  dataSource={data && data}
                  columns={columns && columns}
                  rowKey={(record, index) =>
                    (record.id ? record.id : "") + "-" + index
                  }
                  scroll={{ x: "max-content" }}
                  // scroll={{ y: (data.length > 0 ? 387 : 0), scrollToFirstRowOnChange: true, x: (tableColumn.length > 6 && dataNew.length === 0 ? '150%' : 0) }}
                  loading={loading}
                  pagination={false}
                  locale={
                    totalRows === 0 && dataNew.length > 0
                      ? { emptyText: " ", description: "" }
                      : null
                  }
                  rowClassName={(r, i) =>
                    i % 2 === 0
                      ? "table-striped-listing"
                      : "dull-color table-striped-listing"
                  }
                  onRow={(record) => ({
                    // onClick: (e) => { this.edit(e.target.getAttribute("editable"), record.id) },
                    onContextMenu: (e) => {
                      e.preventDefault();
                      let rightMenu = {
                        record,
                        visible: false,
                        x: e.clientX,
                        y: e.clientY,
                        rowAttr: e.target.getAttribute("editable"),
                      };

                      console.log(e.target.getAttribute("editable"));
                      if (e.target.getAttribute("editable") == "true") {
                        rightMenu = {
                          record,
                          visible: true,
                          x: e.clientX,
                          y: e.clientY,
                          rowAttr: e.target.getAttribute("editable"),
                        };
                      }

                      this.setState(
                        { ...this.state, rightClickMenu: { visible: false } },
                        () => {
                          let that = this;
                          document.addEventListener(
                            `click`,
                            function contextMenuShow() {
                              that.setState({
                                ...this.state,
                                rightClickMenu: { visible: false },
                              });
                              document.removeEventListener(
                                `click`,
                                contextMenuShow
                              );
                            }
                          );
                          this.setState({
                            ...this.state,
                            rightClickMenu: rightMenu,
                          });
                        }
                      );
                    },
                  })}
                />
                {tableColumn.length > 0 && apiKey ? (
                  <Table
                    rowKey={(record, index) =>
                      (record.id ? record.id : "") + "-" + index
                    }
                    // scroll={{ y: (data.length > 0 ? 387 : 0), scrollToFirstRowOnChange: true, x: (tableColumn.length > 6 && dataNew.length > 0 ? '150%' : 0) }}
                    className="addNewTable editableFixedHeader resizeableTable"
                    components={components}
                    bordered
                    dataSource={dataNew}
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    showHeader={false}
                    locale={{ emptyText: " ", description: "" }}
                    pagination={false}
                    footer={() => (
                      <div className="text-center">
                        {
                          <Button type="link" onClick={this.onChangeAddNew}>
                            Add New
                          </Button>
                        }
                      </div>
                    )}
                  />
                ) : null}
              </EditableContext.Provider>
              {rightClickMenu && rightClickMenu.visible ? (
                <RightContextMenu
                  tableName={tableKey}
                  menuProperties={noShowList}
                  {...rightClickMenu}
                  eventListener={(e) => this.listenEvent(e)}
                />
              ) : undefined}
            </div>
          </div>
        </div>

        {sidebarVisible ? (
          <SidebarColumnFilter
            columns={tableColumn}
            sidebarVisible={sidebarVisible}
            callback={(e) => this.callOptions(e)}
          />
        ) : null}
      </>
    );
  }
}

// const EditableFormTable = Form.create()(EditableTable);
const EditableFormTable = EditableTable;

export default EditableFormTable;
