import React from "react";
import { Form, Input } from "antd";

const TonnageViewForm = ({viewDetail}) => {

  console.log("viewDetail", viewDetail)

  const getValue = (value) => (value !== null && value !== undefined && value !== "" ? value : "N/A");

  return (
    <Form layout="vertical" className="view_tonnage_form">
      <Form.Item label="Date Time">
        <Input value={getValue(viewDetail.DateTime)} disabled />
      </Form.Item>
      <Form.Item label="Sender Mail">
        <Input value={getValue(viewDetail.Sender_mail)} disabled />
      </Form.Item>
      <Form.Item label="Sender Names">
        <Input value={getValue(viewDetail.Sender_names)} disabled />
      </Form.Item>
      <Form.Item label="Client">
        <Input value={getValue(viewDetail.Client)} disabled />
      </Form.Item>
      <Form.Item label="Vessel Name">
        <Input value={getValue(viewDetail.vessel_name)} disabled />
      </Form.Item>
      <Form.Item label="Vessel Info">
        <Input value={getValue(viewDetail.vessel_info?.join(", "))} disabled />
      </Form.Item>
      <Form.Item label="IMO">
        <Input value={getValue(viewDetail.imo)} disabled />
      </Form.Item>
      <Form.Item label="Open Area">
        <Input value={getValue(viewDetail.open_area)} disabled />
      </Form.Item>
      <Form.Item label="Open Region">
        <Input value={getValue(viewDetail.new_open_region)} disabled />
      </Form.Item>
      <Form.Item label="Open Port">
        <Input value={getValue(viewDetail.new_open_port)} disabled />
      </Form.Item>
      <Form.Item label="Open Country">
        <Input value={getValue(viewDetail.new_open_country)} disabled />
      </Form.Item>
      <Form.Item label="Nearby Countries">
        <Input value={getValue(viewDetail.new_near_by_countries?.join(", "))} disabled />
      </Form.Item>
      <Form.Item label="Open Date">
        <Input value={getValue(viewDetail.open_date)} disabled />
      </Form.Item>
      <Form.Item label="Build Year">
        <Input value={getValue(viewDetail.build_year)} disabled />
      </Form.Item>
      <Form.Item label="Flag">
        <Input value={getValue(viewDetail.flag)} disabled />
      </Form.Item>
      <Form.Item label="DWT">
        <Input value={getValue(viewDetail.dwt)} disabled />
      </Form.Item>
      <Form.Item label="Draft">
        <Input value={getValue(viewDetail.draft)} disabled />
      </Form.Item>
      <Form.Item label="Hold and Hatches">
        <Input value={getValue(viewDetail.hold_and_hatches)} disabled />
      </Form.Item>
      <Form.Item label="GRT/NRT">
        <Input value={getValue(viewDetail.grt_and_nrt)} disabled />
      </Form.Item>
      <Form.Item label="Grain/Bale">
        <Input value={getValue(viewDetail.grain_and_bale)} disabled />
      </Form.Item>
      <Form.Item label="Vessel Type">
        <Input value={getValue(viewDetail.vessel_type)} disabled />
      </Form.Item>
      <Form.Item label="Sub Vessel Type">
        <Input value={getValue(viewDetail.sub_vessel_type)} disabled />
      </Form.Item>
      <Form.Item label="Sender Phone Numbers">
        <Input value={getValue(viewDetail.sender_full_details?.phone_numbers?.join(", "))} disabled />
      </Form.Item>
      <Form.Item label="Sender Emails">
        <Input value={getValue(viewDetail.sender_full_details?.emails?.join(", "))} disabled />
      </Form.Item>
    </Form>
  );
};

export default TonnageViewForm;
