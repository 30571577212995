import dayjs from "dayjs";

export const findTheNextDate = (currentdate, days = 0) => {
  try {
    let sf = dayjs(currentdate);
    let ad = null;
    
    if(days==undefined || days=="N/A"){
      days=0;
    }
    if (sf.isValid()) {
      ad = sf.add(days * 24, "hour"); // Convert days to hours
    } else {
      // Handle invalid date
    }
    return dayjs(ad).format("YYYY-MM-DD HH:mm");
  } catch (err) {
    console.log("err", err);
  }
};

export const updateThetableBYDate = (tabledata, date, index) => {
  let _tabledata = [...tabledata];
  let firstdate = date;
  _tabledata.forEach((ele, ind) => {
    let obj = { ...ele };
    if (ind >= index) {
      let nextobj =
        ind + 1 < _tabledata.length ? { ..._tabledata[ind + 1] } : {};

      let arrivaldatetime = dayjs(firstdate).isValid()
        ? dayjs(firstdate)
        : null;

      let currentdeparture = findTheNextDate(
        arrivaldatetime,
        parseFloat(obj["pdays"])
      );
      let nextarrival = findTheNextDate(
        currentdeparture,
        parseFloat(nextobj["tsd"])
      );
      obj["day"] = arrivaldatetime ? arrivaldatetime.format("dddd") : "";
      obj["arrival_date_time"] = arrivaldatetime
        ? arrivaldatetime.format("YYYY-MM-DD HH:mm")
        : "";
      obj["departure"] = dayjs(currentdeparture).isValid()
        ? dayjs(currentdeparture).format("YYYY-MM-DD HH:mm")
        : "";
      _tabledata[ind] = obj;
      firstdate = nextarrival; // Update firstdate for the next iteration
    }
  });

  return _tabledata;
};
