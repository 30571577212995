import React, { useEffect, useState } from 'react';
// import dynamic from 'next/dynamic';
import {
    Button,
    Input,
    Modal,
    Popconfirm,
    Select,
    Menu,
    Dropdown,
    Space,
    message,
} from 'antd';
// import { getApiService, postApiService } from 'src/services';
import { getAPICall,postAPICall } from '../../shared';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { Icon } from '@iconify/react/dist/iconify.js';

const SignatureEditor = ({ selectedEmails, onSignatureSelect }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [signatureName, setSignatureName] = useState('');
    const [email, setEmail] = useState('');
    const [signatureData, setSignatureData] = useState([]);
    const [isEditing, setIsEditing] = useState(false); 
    const [currentSignatureId, setCurrentSignatureId] = useState(null); 
    const [editorHtml, setEditorHtml] = useState(''); // For storing the editor content

    const fetchData = async () => {
        try {
            let url = `${process.env.REACT_APP_MT_URL}mail/add-email-sign`;
            // console.log("URL=========",url);
            
            // if (selectedEmails) {
            //     url += `?email=${selectedEmails}`;
            // }
            const response = await getAPICall( url);
            // console.log("esponse",response);
            
            if (response?.data) {


                setSignatureData(response.data);
            }
        } catch (error) {
            console.error('Error fetching email signature:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedEmails]);

    const handleSave = async () => {
        const url = `${process.env.REACT_APP_MT_URL}mail/add-email-sign`;    

        let payload = {
            signature: editorHtml,
            signature_name: signatureName,
            email,
            
        };

        if(currentSignatureId){
            payload = {
                signature: editorHtml,
                signature_name: signatureName,
                email,
                id:currentSignatureId     
            };  
        }
        // const apiMethod = isEditing ? 'PUT' : 'POST';
        try {
            const response = await postAPICall(
                url,
                payload,
                 "POST",
            );
            if (response?.status) {
                message?.success(isEditing ? 'Signature updated successfully!' : 'Signature saved successfully!');
                setModalOpen(false);
                fetchData(); 
            } else {
                message.error('Failed to save signature. Please try again.');
            }
        } catch (error) {
            console.error('Error saving signature:', error);
            message.error('Error saving signature. Please check the console for details.');
        }
    };

    const handleDiscard = () => {
        setSignatureName('');
        setEmail('');
        setEditorHtml('');
        setModalOpen(false);
        setIsEditing(false);
        setCurrentSignatureId(null);
    };

    const handleOpenModal = (signature = null) => {
        if (signature) {
            setSignatureName(signature.signature_name);
            setEmail(signature.email);
            setEditorHtml(signature.signature);
            setIsEditing(true);
            setCurrentSignatureId(signature.id);
        } else {
            setSignatureName('');
            setEmail('');
            setEditorHtml('');
            setIsEditing(false);
            setCurrentSignatureId(null);
        }
        setModalOpen(true);
    };

    const handleDeleteSignature = async (data) => {
        try {
            const url = `${process.env.REACT_APP_MT_URL}mail/add-email-sign`;
            let payload = data;
            const response = await postAPICall(
                url,
                payload,
                'DELETE'
            );
            if (response?.status) {
                message.success('Signature deleted successfully');
                setSignatureData((prev) => prev.filter((item) => item.id !== data.id));
            } else {
                message.error('Failed to delete signature');
            }
        } catch (error) {
            console.error('Error deleting signature:', error);
            message.error('Error deleting signature. Please try again.');
        }
    };

    return (
        <>
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu style={{maxHeight:"18rem",overflow:"scroll",scrollBehavior:"smooth"}}> 
                        {signatureData?.map((data) => (
                            <Menu.Item key={data.id}>
                                <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div>
                                <span onClick={() => onSignatureSelect(data)} style={{ cursor: 'pointer' }}>
                                    {data.signature_name}
                                </span>
                                </div>
                                <div>
                                <Space>
                                    <Button   
                                        icon={<EditOutlined style={{backgroundColor:'transparent'}} />}
                                        size="small"
                                        type="link"
                                        onClick={() => handleOpenModal(data)}
                                    />
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={() => handleDeleteSignature(data)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            style={{backgroundColor:"black"}}
                                            icon={<DeleteOutlined />}
                                            size="small"
                                            type="link"
                                        />
                                    </Popconfirm>
                                </Space>
                                </div>
                                </div>
                            </Menu.Item>
                        ))}
                        <Menu.Item style={{fontWeight:"bold",color:"black"}} onClick={() => handleOpenModal()}>Add New Signature</Menu.Item>
                    </Menu>
                }
            >
                <div >
                {/* <EditOutlined /> */}
                <Icon style={{fontSize:"1.2rem",marginLeft: "20px" ,marginBottom:"0.4rem"}} type="link" icon="fluent:document-signature-32-regular"/>
                </div>
            </Dropdown>

            <Modal
                visible={modalOpen}
                title={isEditing ? 'Edit Signature' : 'Add New Signature'}
                onCancel={() => setModalOpen(false)}
                footer={null}
                width={700}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Input
                        placeholder="Signature Name"
                        value={signatureName}
                        onChange={(e) => setSignatureName(e.target.value)}
                    />
                    <Select
                        value={email}
                        onChange={(e) => setEmail(e)}
                        placeholder="Select an email"
                        style={{ width: '100%' }}
                    >
                        <Select.Option value="" disabled>
                            Select an email
                        </Select.Option>
                        {/* Add email options here */}
                    </Select>

                    <div style={{ border: '1px solid #ccc', borderRadius: 8, minHeight: 200, padding: 10 }}>
                        <ReactQuill
                            value={editorHtml}
                            onChange={setEditorHtml}
                            theme="snow"
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }],
                                    [{ 'font': [] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    ['link', 'image'],
                                    [{ 'align': [] }],
                                    ['clean']
                                ],
                            }}
                        />
                    </div>

                    <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                        <Button onClick={handleDiscard}>Discard</Button>
                        <Button type="primary" onClick={handleSave}>
                            {isEditing ? 'Update' : 'Save'}
                        </Button>
                    </Space>
                </Space>
            </Modal>
        </>
    );
};

export default SignatureEditor;
