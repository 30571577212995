import React, { useEffect, useRef } from "react";
import NormalFormIndex from "../../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../shared";
import Tde from "../../../routes/tde/index";
import { Modal } from "antd";
import CreateInvoice from "../../../routes/create-invoice/CreateInvoice";
import * as moment from "moment";
import InvoicePopup from "../../../routes/create-invoice/InvoicePopup";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import Attachment from "../../../shared/components/Attachment";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../../shared/attachments";

const CommissionPayment = (props) => {
  const [state, setState] = useStateCallback({
    responseData: { frm: [], tabs: [], active_tab: {} },
    frmKey: "frm_key_" + props.frmCode,
    frmName: "tc_commission_entry",
    formData: props.formData || {},
    commissionData: props.commissionData || {},
    frmOptions: props.frmOptions || [],
    showTDE: false,
    isEdit: props.isEdit || false,
    //frmVisible: false,
    frmVisible: true,
    TdeList: null,
    oldFromData: props.oldFromData || {},
    showInvoice: false,
    invoiceReport: null,
    p_dt_from: moment(),
    p_dt_to: moment(),
    showInvoicePopup: false,
    popupdata: null,
  });
  const initialCommissionData = props.commissionData || {};
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      formData: {},
      commissionData: initialCommissionData,
      isEdit: props?.isEdit ? isEdit : false,
      frmVisible: false,
    }));
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        frmVisible: true,
      }));
    }, 500);
  }, [props.closeCount]);

  let formdataref = useRef();
  const [billTo, setBillTo] = React.useState("");
  const getData = (billToValue) => {
    setBillTo(billToValue);
  };
  const saveFormData = (data) => {
    // console.log("data",data);
    if (data["-"] && data["-"].length > 0) {
      data["-"].forEach((e) => {
        delete e["final_amt_loc"];
      });
    }
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    const { frmName } = state;
    let suURL = `${URL_WITH_VERSION}/commission/save?frm=${frmName}`;
    let suMethod = "POST";
    let arr = [];
    formdataref.current = data;

    if (data["-"] && data["-"].length > 0) {
      data["-"].map((el) => {
        let obj = {};
        obj = {
          ...el,
          s: el.s == undefined ? false : el.s,
        };
        arr.push(obj);
      });
    }

    data["-"] = arr;

    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        if (data.row && data.row.invoice_no) {
          setCommissionData(data.row.invoice_no);
        }
        // setState({
        //   ...state,
        //   frmVisible: true,
        //   //  commissionData: formdataref,
        // });
      } else {
        setState((prevState) => ({
          ...prevState,
          commissionData: formdataref.current,
          frmVisible: true,
        }));
        openNotificationWithIcon(
          "error",
          data?.message ? data?.message : "Something went wrong !"
        );
      }
    });
  };

  const setCommissionData = async (invoice_no) => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/commission/edit?e=${invoice_no}`
      );

      const respData = await response["data"];

      //respData["."]={total: response?.data?.inv_total*1 || 0};
      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/accounts/borker/commission?t=tci&e=${respData.tci_id}`
      );
      const respSData = await response1["data"];
      const frmOptions = [
        { key: "broker", data: respSData["brokers"] },
        { key: "remmitance_bank", data: respSData["banks"] },
      ];
      Object.assign(respData, { disablefield: ["broker"] });

      respData["mycompny_id"] = respSData["mycompny_id"];

      //respData['.']['total']= commissionData['inv_total']
      setState((prevState) => ({
        ...prevState,
        commissionData: respData,
        isEdit: true,
        frmOptions: frmOptions,
        loading: true,
        frmVisible: true,
      }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, frmVisible: true }));
      openNotificationWithIcon("error", "Something went wrong.", 5);
    }
  };

  const updateFormData = (data) => {
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    const invoice_no = data.invoice_no;
    // if (data.disablefield) {
    //   delete data.disablefield;
    // }
    if (data["-"] && data["-"].length > 0) {
      data["-"].forEach((e) => {
        delete e["final_amt_loc"];
      });
    }

    if (data["."]?.vat_gst == "") {
      data.vat_gst = "0";
    }
    const { frmName, commissionData } = state;
    // delete data["inv_total"];
    // delete data["final_amt_loc"];
    // data["."]["invoice_total"] = Number(data["."]["invoice_total"]);
    // data["."]["vat_gst"] = Number(data["."]["vat_gst"]);
    // data["invoice_total"] = Number(data["."]["invoice_total"]);
    // data["vat_gst"] = Number(data["."]["vat_gst"]);

    let suURL = `${URL_WITH_VERSION}/commission/update?frm=${frmName}`;
    let suMethod = "PUT";
    formdataref.current = data;
    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);

        setCommissionData(commissionData["invoice_no"]);
        // setState({
        //   ...state,
        //   frmVisible: true,
        //   // commissionData: formdataref,
        // });

        // if (props.hasOwnProperty("closeModal")) {
        //   props.closeModal();   // After some time someone will say to uncomment this.
        // }
      } else {
        setState((prevState) => ({
          ...prevState,
          commissionData: formdataref.current,
          frmVisible: true,
        }));

        openNotificationWithIcon(
          "error",
          data?.message ? data?.message : "Something went wrong."
        );
      }
    });
  };

  const showTDEForm = async (boolVal) => {
    const { commissionData, oldFromData } = state;

    if (commissionData["inv_status"] == 241) {
      openNotificationWithIcon("info", "Please Change the Invoice Status.", 3);
      return;
    }

    let tde_id = 0;
    let new_array = [];
    if (boolVal) {
      const response = await getAPICall(`${URL_WITH_VERSION}/tde/list`);
      const tdeList = response["data"];
      let TdeList =
        tdeList && tdeList.length > 0
          ? tdeList.filter(
              (el) => commissionData["invoice_no"] === el.invoice_no
            )
          : [];
      if (TdeList && TdeList.length > 0) {
        tde_id = TdeList[0]["id"];
      }

      if (tde_id !== 0) {
        const editData = await getAPICall(
          `${URL_WITH_VERSION}/tde/edit?e=${tde_id}`
        );
        const tdeEditData = await editData["data"];

        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${commissionData["broker"]}`
        );
        const responseAddressData = responseData["data"];
        let account_no =
          responseAddressData &&
          responseAddressData["bank&accountdetails"] &&
          responseAddressData["bank&accountdetails"].length > 0
            ? responseAddressData["bank&accountdetails"][0] &&
              responseAddressData["bank&accountdetails"][0]["account_no"]
            : "";
        if (commissionData["-"] && commissionData["-"].length > 0) {
          commissionData["-"].map((e, index) => {
            let accounting_data = {
              vessel_name: tdeEditData["vessel"],
              voyage: oldFromData["voyage_number"],
              ap_ar_acct: tdeEditData["ar_pr_account_no"],
              vessel_code: oldFromData["vessel_code"],
              company: tdeEditData["bill_via"],
              invoice_type: commissionData["inv_type"],
              amount: e.tc_amount,
              account:
                responseAddressData["bank&accountdetails"][0]["swift_code"],
              description: e.description,
              lob: oldFromData.company_lob,
              voy_no: oldFromData["voyage_number"],
              id: -9e6 + index,
            };
            new_array.push(accounting_data);
            return true;
          });
          tdeEditData["accounting"] = new_array;
          tdeEditData["voyage_manager_id"] = oldFromData["voyage_number"];
        }
        tdeEditData["--"] = { ...tdeEditData?.["--"]?.[0] };
        tdeEditData["----"] = { ...tdeEditData?.["----"]?.[0] };
        if (tdeEditData["paymententrytable"][0]["payment_status"] === 208) {
          let diff = tdeEditData["--"]["total"] - tdeEditData["----"]["total"];
          tdeEditData["----"] = {
            ...tdeEditData["----"],
            total_due: diff.toFixed(2),
          };
        } else {
          tdeEditData["----"] = {
            ...tdeEditData["----"],
            total_due: tdeEditData["account_base"],
          };
        }
        setState((prevState) => ({
          ...prevState,
          TdeList: tdeEditData,
          showTDE: boolVal,
        }));
      } else {
        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${commissionData["broker"]}`
        );
        const responseAddressData = responseData["data"];
        let account_no =
          responseAddressData &&
          responseAddressData["bank&accountdetails"] &&
          responseAddressData["bank&accountdetails"].length > 0
            ? responseAddressData["bank&accountdetails"][0] &&
              responseAddressData["bank&accountdetails"][0]["account_no"]
            : "";
        let frmData = {};

        frmData = {
          bill_via:
            oldFromData.my_company_lob || oldFromData.company_fixed_with,
          invoice: commissionData["acc_type"],
          invoice_no: commissionData["invoice_no"],
          invoice_date: commissionData["invoice_date"],
          // invoice_type: commissionData["inv_type"],
          received_date: commissionData["recieved_date"],
          vessel: commissionData["vessel_id"],
          vendor: commissionData["broker"],
          voyage_manager_id: oldFromData["id"],
          inv_status: commissionData["inv_status"],
          invoice_amount: commissionData["inv_total"],
          account_base: commissionData["inv_total"],
          payment_term: commissionData["payment_terms"],
          ar_pr_account_no:
            responseAddressData["bank&accountdetails"][0]["account_no"],
          voyage: oldFromData["id"],
          po_number: commissionData["po_number"],
          accounting: [],
        };
        if (commissionData["-"] && commissionData["-"].length > 0) {
          commissionData["-"].map((e, index) => {
            let accounting_data = {
              vessel_name: commissionData["vessel_id"],
              voyage: oldFromData["voyage_number"],
              ap_ar_acct: account_no,
              vessel_code: oldFromData["vessel_code"],
              company: oldFromData.my_company_lob,
              account:
                responseAddressData["bank&accountdetails"][0]["swift_code"],
              amount: e.commission,
              description: e.description,
              lob: oldFromData.company_lob,
              port: oldFromData["b_port_name"],
              id: -9e6 + index,
            };
            new_array.push(accounting_data);
          });
          frmData["accounting"] = new_array;
        }
        frmData["----"] = {
          total_due: commissionData["inv_total"],
        };
        frmData["--"] = {
          total: commissionData["inv_total"],
        };
        setState((prevState) => ({
          ...prevState,
          TdeList: frmData,
          showTDE: boolVal,
        }));
      }
    } else {
      setState((prevState) => ({ ...prevState, showTDE: boolVal }));
    }
  };

  const invoiceModal = async (data = {}) => {
    let { formData, invoiceReport, commissionData } = state;
    try {
      if (Object.keys(data).length === 0) {
        const response = await getAPICall(
          `${URL_WITH_VERSION}/commission/report?e=${commissionData.invoice_no}&my_id=${commissionData.mycompny_id}`
        );
        const respData = await response["data"];
        if (respData) {
          setState((prevState) => ({
            ...prevState,
            invoiceReport: respData,
            popupdata: respData,
            showInvoicePopup: true,
          }));
          // showHideModal(true, "InvoicePopup");
        } else {
          openNotificationWithIcon(
            "error",
            "Sorry, Unable To Show Invoice.",
            3
          );
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          invoiceReport: { ...invoiceReport, ...data },
          //  showInvoice: true
        }));
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  const handleok = () => {
    const { invoiceReport } = state;
    if (invoiceReport["isSaved"]) {
      //  showHideModal(false, "InvoicePopup");
      setState((prevState) => ({ ...prevState, showInvoicePopup: false }));
      setTimeout(() => {
        // showHideModal(true, "InvoiceModal");
        setState((prevState) => ({ ...prevState, showInvoice: true }));
      }, 2000);
      setState((prevState) => ({ ...prevState, invoiceReport: invoiceReport }));
    } else {
      openNotificationWithIcon(
        "info",
        "Please click on Save to generate invoice.",
        3
      );
    }
  };

  const onClickExtraIcon = (action, data) => {
    let groupKey = action["gKey"];
    let frm_code = "tc_commission_entry";

    let delete_id = data && data.id;
    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey?.replace(/\s/g, "").toLowerCase(),
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  const _onDeleteFormData = (data, innerCB) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => deleteFormData(data, innerCB),
    });
  };

  const deleteFormData = async (data, innerCB) => {
    let delete_data = {
      id: innerCB.id,
    };
    postAPICall(
      `${URL_WITH_VERSION}/commission/delete`,
      delete_data,
      "delete",
      (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
          setState(
            (prevState) => ({
              ...prevState,
              formData: {},
              commissionData: initialCommissionData,
              // showSideListBar: false,
              isEdit: false,
              frmVisible: false,
              // addressId: addressId,
            }),
            () => setState((prevState) => ({ ...prevState, frmVisible: true }))
          );
          // props.setParentState((prevState) => ({
          //   ...prevState,
          //   modalStatus: false,
          // }));
          props.onCancelModal();
          // props.closeModal(innerCB.invoice_no);
        } else {
          openNotificationWithIcon("error", response.message);
        }
      }
    );
  };

  const {
    frmName,
    commissionData,
    showInvoicePopup,
    popupdata,
    frmOptions,
    showTDE,
    isEdit,
    frmVisible,
    TdeList,
    showInvoice,
    invoiceReport,
  } = state;

  const ShowAttachment = async (isShowAttachment) => {
    let loadComponent = undefined;
    //const { id } = state.formData;
    const id = state.commissionData?.invoice_no;
    if (id && isShowAttachment) {
      const attachments = await getAttachments(id, "INV");
      const callback = (fileArr) =>
        uploadAttachment(fileArr, id, "INV", "port-expense");
      loadComponent = (
        <Attachment
          uploadType="Estimates"
          attachments={attachments}
          onCloseUploadFileArray={callback}
          deleteAttachment={(file) =>
            deleteAttachment(file.url, file.name, "INV", "port-expense")
          }
          tableId={0}
        />
      );
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: loadComponent,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: undefined,
      }));
    }
  };

  return (
    <div className="body-wrapper">
      {frmName ? (
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              {frmVisible ? (
                <NormalFormIndex
                  key={"key_" + frmName + "_0"}
                  formClass="label-min-height"
                  frmCode={frmName}
                  formData={commissionData}
                  tableFormAddNewRow={true}
                  showForm={true}
                  addForm={true}
                  // showButtons={[
                  //   { id: 'cancel', title: 'Reset', type: 'danger' },
                  //   {
                  //     'id': isEdit === true ? 'update' : 'save',
                  //     'title': isEdit === true ? 'Update' : 'Save',
                  //     'type': 'primary',
                  //     'event': (data, innerCB) => { isEdit === true ? updateFormData(data, innerCB) : saveFormData(data, innerCB) },
                  //   },
                  // ]}
                  showToolbar={[
                    {
                      isLeftBtn: [
                        {
                          key: "a2",
                          isSets: [
                            {
                              id: "2",
                              key: "save",
                              type: <SaveOutlined />,
                              withText: "save",
                              showToolTip: true,
                              event: (key, data) =>
                                isEdit === true
                                  ? updateFormData(data)
                                  : saveFormData(data),
                            },
                            isEdit && {
                              id: "2",
                              key: "delete",
                              type: <DeleteOutlined />,
                              withText: "Delete",
                              showToolTip: true,
                              event: (data, innerCB) =>
                                _onDeleteFormData(data, innerCB),
                            },
                          ],
                        },
                      ],
                      isRightBtn: [
                        {
                          key: "a2",
                          isSets: [
                            isEdit && {
                              key: "invoice",
                              isDropdown: 0,
                              withText: "Create Invoice",
                              type: "",
                              menus: null,
                              event: () => {
                                invoiceModal();
                              },
                            },
                            isEdit && {
                              key: "tde",
                              isDropdown: 0,
                              withText: "TDE",
                              type: "",
                              menus: null,
                              event: () => showTDEForm(true),
                            },
                            {
                              id: "3",
                              key: "attachment",
                              isDropdown: 0,
                              withText: "Attachment",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                data &&
                                data.hasOwnProperty("id") &&
                                data["id"] > 0
                                  ? ShowAttachment(true)
                                  : openNotificationWithIcon(
                                      "info",
                                      "Please save the Invoice First.",
                                      3
                                    );
                              },
                            },
                          ],
                        },
                      ],
                      isResetOption: false,
                    },
                  ]}
                  inlineLayout={true}
                  isShowFixedColumn={["-"]}
                  frmOptions={frmOptions}
                  tableRowDeleteAction={(action, data) =>
                    onClickExtraIcon(action, data)
                  }
                  summary={[{ gKey: "-", showTotalFor: ["commission"] }]}
                />
              ) : undefined}
              {state.isShowAttachment ? (
                <Modal
                  style={{ top: "2%" }}
                  title="Upload Attachment"
                  open={state.isShowAttachment}
                  onCancel={() => ShowAttachment(false)}
                  width="50%"
                  footer={null}
                >
                  {state.loadComponent}
                </Modal>
              ) : undefined}
            </div>
          </div>
        </article>
      ) : undefined}

      {showTDE && (
        <Modal
          title="TDE"
          open={showTDE}
          width="90%"
          onCancel={() => showTDEForm(false)}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <Tde
            invoiceType="tc-commission"
            isEdit={
              TdeList != null && TdeList.id && TdeList.id > 0 ? true : false
            }
            formData={TdeList}
            modalCloseEvent={() => showTDEForm(false)}
            saveUpdateClose={() =>
              setState((prevState) => ({ ...prevState, showTDE: false }))
            }
          />
        </Modal>
      )}
      {showInvoice && (
        <Modal
          className="page-container"
          style={{ top: "2%" }}
          title="Download Invoice"
          open={showInvoice}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, showInvoice: false }))
          }
          width="95%"
          footer={null}
        >
          <CreateInvoice
            type="commissionInvoice"
            billTo={billTo}
            commissionInvoice={invoiceReport}
          />
        </Modal>
      )}

      {showInvoicePopup ? (
        <Modal
          style={{ top: "2%" }}
          title="Invoice"
          open={showInvoicePopup}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, showInvoicePopup: false }))
          }
          width="95%"
          okText="Create PDF"
          onOk={handleok}
        >
          <InvoicePopup
            data={popupdata}
            updatepopup={(data) => invoiceModal(data)}
            getData={getData}
          />
        </Modal>
      ) : undefined}
    </div>
  );
};

export default CommissionPayment;
