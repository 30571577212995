import { useEffect, useState } from "react";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  capitaliseFirstLetter,
  convertToCSV,
  disabledDate,
  formatArrayToString,
  formatDate121,
  formatNumber,
  rowDataFormatter,
  Tonnagecolumns,
} from "./constants";
import { Icon } from "@iconify/react/dist/iconify.js";
import MenuItem from "antd/es/menu/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setTonnageList } from "../../actions/emailActions";
import { values } from "lodash";
import dayjs from "dayjs";
import TonnageViewForm from "./ViewTonnageForm";
import CurrentMail from "./CurrentMail";
import BestMatchDrawerContent from "./BestMatchDrawerContent";
// import BestMatchDrawer from "./BestMatchDrawer";
import ParsedInfo from "../voyage-list/components/ParsedInfo";
import { useParams } from "react-router";
import TonnageAnalysis from "./TonnageAnalysis";
import NewVesselInfoDrawer from "./mailRightbar/NewVesselInfoDrawer";

const GlobalTonnageOrder = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [filterInputs, setFilterInputs] = useState({});
  const [IsFilter, setIsFilter] = useState(false);
  const [dwtFilter, setDwtFilter] = useState({
    dwt: { min: null, max: null, combined: null },
  });
  const { RangePicker, YearPicker } = DatePicker;
  const dispatch = useDispatch();
  const [isView, setIsView] = useState(false);
  const [viewDetail, setViewDetail] = useState({});
  const [tonnageMail, setTonnageMail] = useState({});
  const [tonnageMailOpen, setTonnageMailOpen] = useState(false);
  const [isBestMatch, setIsBestMatch] = useState(false);
  const [currentBestMatch, setCurrentBestMatch] = useState({});

  const [vesselInfo, setVesselInfo] = useState({});
  const [openVesselInfo, setOpenVesselInfo] = useState(false);
  const [assignedMails, setAssignedMails] = useState([]);

  const [isFilterSave, setIsFilterSave] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [showTonnageAnalysisModal, setShowTonnageAnalysisModal] =
    useState(false);

    const [wtMin, setwtMin] = useState('');
    const [wtMax, setwtMax] = useState('');

  const { slug } = useParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
  });

  const [filters, setFilters] = useState({
    date: [],
    vessel_name: [],
    vessel_type: [],
    vessel_sub_type: [],
    dwt: { min: null, max: null, combined: null },
    open_date: [],
    open_area: [],
    build_year: [],
    Sender: [],
    tags: [],
  });

  const handleView = (row) => {
    setViewDetail(row);
    setIsView(true);
  };

  const handleTonnagemail = async (id) => {
    try {
      setLoadingNew(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/get-mail/${id}`;
      const response = await postAPICall(url, {}, "get", (data) => {
        if (data.data.length > 0) {
          setTonnageMail(data.data[0]);
          setTonnageMailOpen(true);
          setLoadingNew(false);
        } else {
          openNotificationWithIcon("info", "Mail Not Found!");
          setLoadingNew(false);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ActionCell = ({ data }) => {
    const content = (
      <div>
        <div className="tonnage_dropdown">
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "6px",
            }}
            onClick={() => {
              setCurrentBestMatch(data);
              setIsBestMatch(true);
            }}
          >
            <Icon icon={"ri:shake-hands-line"} /> Best Match
          </Button>
        </div>

        {/* <div className="tonnage_dropdown">
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => handleTonnagemail(data["refid"])}
          >
            <Icon icon={"mdi:email"} /> View Mail
          </Button>
        </div> */}
      </div>
    );

    return (
      <>
        <Popover content={content} placement="leftBottom">
          <div style={{ padding: "4px" }}>
            <Icon
              icon={"fluent:tap-single-32-regular"}
              fontSize={20}
              className="bg-icon-color"
            />
          </div>
        </Popover>
      </>
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("oceanToken").split(".");
    if (token) {
      const userDetail = JSON.parse(atob(token[1]));
      setAssignedMails(userDetail.emailClients);
    }
  }, []);

  const emailAssignedContent = (
    <div>
      {assignedMails?.map((mail) => {
        return (
          <div style={{ padding: "4px", fontSize: "14px" }}>
            <Icon
              icon="mdi:email-outline"
              fontSize={16}
              style={{ color: "#1C5CE9" }}
            />
            <span style={{ paddingLeft: "4px" }}>{mail?.import_email}</span>
          </div>
        );
      })}
    </div>
  );

  const handleVesselInfo = async (row) => {
    try {
      setLoadingNew(true);
      const url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${row.vessel_name}`;
      const response = await postAPICall(url, {}, "get", (data) => {
        if (data.length > 0) {
          setVesselInfo(data[0]);
          setOpenVesselInfo(true);
          setLoadingNew(false);
        } else {
          openNotificationWithIcon("info", "Vessel Not Found!");
          setLoadingNew(false);
        }
      });
    } catch (err) {
      setLoadingNew(false);
      console.error("Error fetching data:", err);
    }
  };

  const Tonnagecolumns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      align: "center",
      key: "sNo",

      render: (text, row, index) => (
        <div
        //   onClick={() => handlePopoverOpen(row)}
        // onMouseLeave={() => handlePopoverClose()}
        >
          {/* {index + 1} */}
          {pageSize * (page - 1) + index + 1}
          {/* <Typography>{index + 1}</Typography> */}
        </div>
      ),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      width: "3rem",
    },
    {
      title: "Date Received",
      dataIndex: "Time",
      key: "DateReceived",
      sorter: (a, b) => moment(b.DateTime).unix() - moment(a.DateTime).unix(),
      sortDirections: ["descend", "ascend"],
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip
            title={capitaliseFirstLetter(
              formatDate121(row.Time, row.DateTime, true)
            )}
            arrow
          >
            <div style={{ width: "5rem" }} className="overflowText">
              {" "}
              {capitaliseFirstLetter(
                formatDate121(row.Time, row.DateTime, false)
              ) || "N/A"}
            </div>
          </Tooltip>

          {row.DateTime && (
            <div style={{ display: "flex", gap: "4px" }}>
              <div
                style={{ display: "flex", gap: "2px" }}
                className="allIconColor"
              >
                <Icon
                  icon="ph:users"
                  fontSize={16}
                  style={{ color: "green" }}
                />
                <span style={{ color: "green" }}>{row.New_From?.length}</span>
              </div>
              <Tooltip title={row.New_From[0]} arrow>
                <div
                  style={{ display: "flex", gap: "2px" }}
                  className="allIconColor"
                >
                  <Icon
                    icon="mdi:email-outline"
                    fontSize={16}
                    style={{ color: "#1C5CE9" }}
                  />
                  <span style={{ color: "#1C5CE9" }}>
                    {row.New_From?.length}
                  </span>
                </div>
              </Tooltip>
            </div>
          )}
        </span>
      ),
    },
    {
      title: "Vessel Name",
      dataIndex: "vessel_name",
      key: "vessel_name",
      //   sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={capitaliseFirstLetter(rowDataFormatter(row.vessel_name))}
          arrow
        >
          <span className="overflowTextWrap">
            <div style={{ width: "9rem" }} className="overflowText">
              {capitaliseFirstLetter(rowDataFormatter(row.vessel_name)) ||
                "N/A"}
            </div>

            <div
              style={{ padding: "2px", cursor: "pointer" }}
              onClick={() => handleVesselInfo(row)}
            >
              <Icon
                icon="ph:map-pin"
                fontSize={16}
                className="allIconColor"
                style={{ color: "red" }}
              />
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Vessel Type",
      dataIndex: "vessel_type",
      key: "vesselType",
      //   sorter: (a, b) => a.vessel_type.localeCompare(b.vessel_type),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip title={rowDataFormatter(row.vessel_type)} arrow>
          <span className="overflowTextWrap">
            <div style={{ width: "5rem" }}>
              {capitaliseFirstLetter(rowDataFormatter(row.vessel_type)) ||
                "N/A"}
            </div>
            <div
              style={{ padding: "2px" }}
              onClick={() => {
                //   dispatch(setVesselName(row.vessel_name))
                //   setShowMap(true)
                localStorage.setItem("tabChangeMap", "false");
              }}
            >
              {row.vessel_type === "dry" && (
                <Icon
                  icon="lucide:ship"
                  fontSize={16}
                  style={{ color: "#EC9C38" }}
                />
              )}
              {row.vessel_type === "tanker" && (
                <Icon
                  icon="lucide:ship"
                  fontSize={16}
                  style={{ color: "#0051F2" }}
                />
              )}
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Vessel Subtype",
      dataIndex: "sub_vessel_type",
      key: "vesselSubtype",
      //   sorter: (a, b) => a.sub_vessel_type.localeCompare(b.sub_vessel_type),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const formattedText =
          capitaliseFirstLetter(rowDataFormatter(row.sub_vessel_type)) || "N/A";
        const showTooltip = formattedText !== "N/A";

        return (
          <span className="overflowTextWrap">
            {showTooltip ? (
              <Tooltip title={formattedText} arrow>
                <div style={{ width: "12rem" }} className="overflowText">
                  {formattedText}
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: "12rem" }} className="overflowText">
                {formattedText}
              </div>
            )}
            <div
              style={{ padding: "2px" }}
              //   onClick={() => {
              //     dispatch(setVesselName(row.vessel_name));
              //     setShowMap(true);
              //   }}
            >
              <Icon
                icon="tabler:ship"
                fontSize={16}
                style={{ color: "#078244" }}
              />
            </div>
          </span>
        );
      },
    },
    {
      title: "DWT",
      dataIndex: "dwt",
      key: "dwt",
      sorter: (a, b) => a.dwt - b.dwt, // Sorting numbers in ascending order
      sortDirections: ["descend", "ascend"], // Default sorting direction
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip
            title={capitaliseFirstLetter(formatArrayToString(row.dwt))}
            arrow
          >
            <div style={{ width: "5rem" }} className="overflowText">
              {capitaliseFirstLetter(formatArrayToString(row.dwt)) || "N/A"}
            </div>
          </Tooltip>
        </span>
      ),
    },
    {
      title: "Open Date",
      dataIndex: "open_date",
      key: "openDate",
      sorter: (a, b) => moment(b.open_date).unix() - moment(a.open_date).unix(),
      sortDirections: ["descend", "ascend"],
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap">
          <Tooltip title={capitaliseFirstLetter(row.open_date)} arrow>
            <div style={{ width: "10rem" }} className="overflowText">
              {capitaliseFirstLetter(row.open_date) || "N/A"}
            </div>
          </Tooltip>
        </span>
      ),
    },
    {
      title: "Open Area",
      dataIndex: "open_area",
      key: "openArea",
      //   sorter: (a, b) => a.open_area.localeCompare(b.open_area),
      defaultSortOrder: "ascend",
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={capitaliseFirstLetter(rowDataFormatter(row.open_area))}
          arrow
        >
          <span className="overflowTextWrap">
            <div style={{ width: "7rem" }} className="overflowText">
              {capitaliseFirstLetter(rowDataFormatter(row.open_area)) || "N/A"}
            </div>
            {row.open_area && (
              <Icon
                icon="vaadin:anchor"
                fontSize={16}
                style={{ color: "green" }}
              />
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      title: "Build Year",
      dataIndex: "build_year",
      align: "center",
      //   defaultSortOrder: "ascend",
      //   sorter: (a, b) => a.build_year - b.build_year,
      //   sortDirections: ["ascend", "descend"],
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => {
        const formattedData = capitaliseFirstLetter(
          rowDataFormatter(row.build_year)
        );
        const showTooltip = formattedData && formattedData !== "N/A";

        return (
          <span className="overflowTextWrap">
            {showTooltip ? (
              <Tooltip title={formattedData} arrow>
                <div style={{ minWidth: "5rem" }} className="overflowText">
                  {formattedData || "N/A"}
                </div>
              </Tooltip>
            ) : (
              <div style={{ minWidth: "5rem" }} className="overflowText">
                {formattedData || "N/A"}
              </div>
            )}
            {row?.build_year?.length > 0 && (
              <Icon
                icon="iconoir:calendar"
                fontSize={16}
                className="allIconColor"
              />
            )}
          </span>
        );
      },
    },

    {
      title: "BOD/BOR",
      dataIndex: "bod",
      key: "bod",
      //   sorter: (a, b) => a.bod.localeCompare(b.bod),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={typeof row.bod === "string" ? row.bod.toUpperCase() : ""}
          arrow
        >
          <span className="overflowTextWrap">
            <div
              style={{
                width: "6rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="overflowText"
            >
              {typeof row.bod === "string"
                ? row.bod.toUpperCase()
                : "" || "N/A"}
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "CRANES",
      dataIndex: "cranes",
      key: "cranes",
      //   sorter: (a, b) => a.cranes.localeCompare(b.cranes),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip title={capitaliseFirstLetter(row.cranes)} arrow>
          <span className="overflowTextWrap">
            <div style={{ width: "3rem" }} className="overflowText">
              {capitaliseFirstLetter(row.cranes) || "N/A"}
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "HO/HA",
      dataIndex: "hold_and_hatches",
      key: "hoHa",
      //   sorter: (a, b) => a.hold_and_hatches.localeCompare(b.hold_and_hatches),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={row.hold_and_hatches !== null ? row.hold_and_hatches : ""}
          arrow
        >
          <span className="overflowTextWrap">
            <div
              style={{ width: "3rem", textAlign: "center" }}
              className="overflowText"
            >
              {row.hold_and_hatches === null ? (
                <Icon icon={"tabler:xbox-x"} style={{ color: "red" }} />
              ) : (
                <Icon icon={"tabler:circle-check"} style={{ color: "green" }} />
              )}
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Scrubber Fitted",
      dataIndex: "scrubber_fitted",
      key: "scrubberFitted",
      //   sorter: (a, b) => a.scrubber_fitted.localeCompare(b.scrubber_fitted),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={row.scrubber_fitted !== null ? row.scrubber_fitted : ""}
          arrow
        >
          <span className="overflowTextWrap">
            <div
              style={{ width: "7.5rem", textAlign: "center" }}
              className="overflowText"
            >
              {row.scrubber_fitted === null ? (
                <Icon icon={"tabler:xbox-x"} style={{ color: "red" }} />
              ) : (
                <Icon icon={"tabler:circle-check"} style={{ color: "green" }} />
              )}
            </div>
          </span>
        </Tooltip>
      ),

      //
    },

    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      //   sorter: (a, b) => a.tags[0].localeCompare(b.tags[0]),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      render: (text, row) => (
        <Tooltip
          title={row?.tags?.filter((tag) => tag !== null).join(", ")}
          arrow
        >
          <span className="overflowTextWrap">
            <div style={{ width: "3rem" }} className="overflowText">
              {(Array.isArray(row?.tags) &&
                row.tags.length > 0 &&
                capitaliseFirstLetter(rowDataFormatter(row.tags[0]))) ||
                "N/A"}
            </div>
          </span>
        </Tooltip>
      ),
    },

    {
      title: "Mail Status",
      dataIndex: "flag",
      key: "mailStatus",
      //   sorter: (a, b) => a.flag.localeCompare(b.flag),
      onCell: (row) => ({
        style: {
          backgroundColor:
            row.vessel_type === "dry" ? "rgb(236 156 56 / 20%)" : "#EBF1FD",
        },
      }),
      align: "center",
      render: (text, row) => (
        <span
          style={{
            color: "green",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          {rowDataFormatter("Processed") || "N/A"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "flag",
      key: "action",
      fixed: "right",
      sorter: true,
      onCell: (row) => ({
        style: {
          backgroundColor: row.vessel_type === "dry" ? "white" : "#fff",
        },
      }),
      render: (text, row) => (
        <span className="overflowTextWrap" style={{ cursor: "pointer" }}>
          <ActionCell data={row} />

          <Tooltip title={"Click to view tonnage in detail"}>
            <div onClick={() => handleView(row)}>
              <Icon
                icon={"tabler:eye"}
                fontSize={20}
                className="bg-icon-color"
              />
            </div>
          </Tooltip>
        </span>
      ),
      width: "5rem",
    },
  ];

  const handleTonnageFilter = async (number, size) => {
    try {
      const url = `${
        process.env.REACT_APP_MT_URL
      }mail/global-tonnage-filter?page=${number || page}&pageSize=${
        size || pageSize
      }`;

      // Clone the filters state to avoid direct mutation
      const updatedFilters = { ...filters };

      // Update cloned filters object immutably
      updatedFilters.dwt = filters.dwt.combined ? [filters.dwt.combined] : [];
      updatedFilters.build_year = filters.build_year || [];

      const payload = {
        owner_email: "*",
        query: [updatedFilters], // Use the cloned filters object
      };

      // Check if any filter has data
      const hasData = Object.values(payload.query[0]).some((field) =>
        Array.isArray(field) ? field.length > 0 : field
      );

      if (hasData) {
        setIsFilter(true);
        // API call to fetch filtered data
        const responseData = await postAPICall(
          url,
          payload,
          "post",
          (value) => {
            if (value) {
              setFilteredData(value?.emails);
              setAllData(value?.emails);
              setCount(value?.totalCount);
            }
          }
        );
      } else {
        // Reset to unfiltered data if no filters are applied
        setAllData(data);
        fetchTonnageData(page, pageSize);
        setIsFilter(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange = (value, field) => {
    setPage(1);

    setFilters((prev) => ({
      ...prev,
      [field]: Array.isArray(value) ? value : [value], // Always store as array
    }));
  };

  const handleMultipleRange = (value, field) => {
    setPage(1);

    setFilters((prev) => ({
      ...prev,
      [field]: [...prev, value], // Always store as array
    }));
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = dayjs(new Date(dates[0])).format("YYYY/MM/DD");
      const formattedEndDate = dayjs(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;

      if (value) {
        setFilters((prev) => ({
          ...prev,
          [value]: [...(prev[value] || []), dateRangeString],
        }));
      }
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    // Update the dwtFilter state based on user input
    setDwtFilter((prev) => ({
      ...prev,
      dwt: { ...prev.dwt, [field]: value },
    }));

    // Real-time clearing of filter if both min and max are empty
    if (
      (field === "min" && value === "") ||
      (field === "max" && value === "")
    ) {
      const { min, max } = {
        ...dwtFilter.dwt,
        [field]: value, // ensure we capture the current input change
      };

      if (!min && !max) {
        setFilters((prev) => ({
          ...prev,
          dwt: [],
        }));
      }
    }  if (field === "min") {
      setwtMin(e.target.value)
    }
    if (field === "max") {
      setwtMax(e.target.value)
    }

    setPage(1);
  };

  const handleDWTEnter = () => {
    const { min, max } = dwtFilter.dwt;

    // If both min and max are empty, clear the filter
    if (!min && !max) {
      setFilters((prev) => ({
        ...prev,
        dwt: [],
      }));
      return;
    }

    // Validation checks for missing values
    if (!min) {
      return openNotificationWithIcon(
        "info",
        "Please Fill the Min value first."
      );
    }

    if (!max) {
      return openNotificationWithIcon(
        "info",
        "Please Fill the Max value also."
      );
    }
    setwtMin('')
    setwtMax('')
    // Set filters when both min and max are filled
    setPage(1);
    setFilters((prev) => ({
      ...prev,
      dwt: {
        min,
        max,
        combined: `${min} - ${max}`,
      },
    }));
  };

  const renderChip = (value, name) => {
    // Check if the value is an array and has items
    if (Array.isArray(value)) {
      return value.length ? (
        value.map((item) => (
          <Tag
            color="processing"
            key={item}
            closable
            onClose={() => handleRangeChipClose(item, name)}
          >
            {item}
          </Tag>
        ))
      ) : (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    }

    // If value is a string and not empty, render it as a single tag
    if (typeof value === "string" && value.trim()) {
      return (
        <Tag color="processing" key={value}
        closable
        onClose={() => handleRangeChipClose(value, name)}>
          {value}
        </Tag>
      );
    }

    if (filters.dwt.min && filters.dwt.max && filters.dwt.combined) {
      return (
        <Tag color="processing" key={filters.dwt.combined} closable>
          {filters.dwt.combined}
        </Tag>
      );
    }

    // If value is empty, render a fallback message
    return (
      <span style={{ fontSize: "12px", color: "lightgray" }}>
        No Active Filter
      </span>
    );
  };

  const handleRangeChipClose = (range, name) => {
    if (name == 'dwt') {
      setFilters((prev) => ({
        ...prev,
        [name]: { min: null, max: null, combined: null },
      }));
    }
    else {
    
    setFilters((prev) => ({
      ...prev,
      [name]: prev[name].filter((item) => item !== range),
    }));
  }
  };

  const multipleRangeChip = (range, name) => {
    if (Array.isArray(range) && range.length > 0) {
      return range.map((rangeValue, index) => (
        <Tag
          color="processing"
          key={index}
          closable
          onClose={() => handleRangeChipClose(rangeValue, name)}
        >
          {rangeValue}
        </Tag>
      ));
    } else {
      return (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    }
  };

  const getFilterValue = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/globalTonnagemailtype`;
      const payload = {
        owner_email: "*",
      };
      const responseData = await postAPICall(url, payload, "post", (value) => {
        if (value) {
          setFilterInputs(value);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTonnageData = async (userPage, pageSize) => {
    setLoading(true);
    try {
      const userInput = {
        isToken: true,
        url: `${process.env.REACT_APP_MT_URL}mail/global-cargo-tonnage-mails-sorted?page=${userPage}&pageSize=${pageSize}`,
        payload: {
          Mailtype: "tonnage",
        },
        showMessage: false,
      };
      const responseData = await postAPICall(
        userInput.url,
        userInput.payload,
        "POST",
        (value) => {
          if (value?.data) {
            setData(value?.data);
            setAllData(value?.data);
            dispatch(setTonnageList(value?.data));
            setCount(value?.totalCount);
            setLoading(false);
          } else {
            setData([]);
            setCount(0);
          }
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTonnageData(page, pageSize);
    getFilterValue();
  }, []);

  useEffect(() => {
    handleTonnageFilter();
  }, [filters]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    if (IsFilter) {
      handleTonnageFilter(pagination.current, pagination.pageSize);
    } else {
      fetchTonnageData(pagination.current, pagination.pageSize);
    }
  };

  const handleResetFilter = () => {
    setFilters({
      date: [],
      vessel_name: [],
      vessel_type: [],
      vessel_sub_type: [],
      dwt: { min: null, max: null, combined: null },
      open_date: [],
      open_area: [],
      build_year: [],
      Sender: [],
      tags: [],
    });
    setPage(1);
    fetchTonnageData(page, pageSize);
  };

  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownload = () => {
    const csv = convertToCSV(allData);
    const csvName =
      typeof slug === "undefined" || slug === null ? "Tonnage" : slug;
    downloadCSV(csv, `${csvName}.csv`);
  };

  const handleSaveFilter = async () => {
    try {
      if (allData.length === 0) {
        openNotificationWithIcon("info", "No data to save filter.");
        return;
      }

      if (filterName === "") {
        openNotificationWithIcon("info", "Please enter a filter name.");
        return;
      }

      const updatedData = allData.map((obj) => {
        return {
          ...obj, // Spread existing object properties
          tonnage_category_name: filterName,
          url: filterName,
        };
      });

      const url = `${process.env.REACT_APP_MT_URL}voyage-estimate/add-tonnage`;
      const payload = {
        filter_data: updatedData,
        filter_set: filters,
      };
      const responseData = await postAPICall(url, payload, "post", (value) => {
        if (value.status === "1") {
          openNotificationWithIcon("success", value.msg);
          setIsFilterSave(false);
        }
      });
    } catch (e) {
      console.error("Error saving filter:", e);
      openNotificationWithIcon("error", "Failed to Save Filter. Try again...");
    }
  };

  const onFiltername = (value) => {
    setFilterName(value);
  };

  return (
    <div className="tonnage-cargo-table" style={{ padding: "10px" }}>
      <div className="tonnage-table-filter-box">
        <div className="table-responsive">
          <table className="tonnage-filter-table">
            <thead>
              <tr>
                <th>Date Received</th>
                <th>Vessel Name</th>
                <th>Vessel Type</th>
                <th>Vessel Subtype</th>
                <th>Dead Weight</th>
                <th>Open Date</th>
                <th>Open Area</th>
                <th>Build Year</th>
                <th>Sender</th>
                <th>Tags</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <RangePicker
                    disabledDate={disabledDate}
                    value={null}
                    // onChange={(dates) => handleMultipleRange(dates, "date")}
                    onChange={(event, dateString) =>
                      onChange(event, "date", dateString)
                    }
                  />
                </td>
                <td>
                  <Select
                    style={{ width: "150px" }}
                    popupMatchSelectWidth={false}
                    showSearch
                    placeholder="Choose Vessel"
                    mode="multiple"
                    autoClearSearchValue
                    maxTagCount={0}
                    allowClear
                    value={filters?.vessel_name}
                    onChange={(value) =>
                      handleSelectChange(value, "vessel_name")
                    }
                  >
                    {filterInputs?.unique_vessel_names?.map((vessel) => (
                      <Select.Option key={vessel}>
                        {vessel?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: "150px" }}
                    popupMatchSelectWidth={false}
                    allowClear
                    maxTagCount={0}
                    placeholder="Choose Type"
                    mode="multiple"
                    showSearch
                    value={filters?.vessel_type}
                    onChange={(value) => {
                      value
                        ? handleSelectChange(value, "vessel_type")
                        : setFilters((prev) => ({
                            ...prev,
                            vessel_type: [],
                          }));
                    }}
                  >
                    {filterInputs?.vessel_type?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: "150px" }}
                    placeholder="Choose Subtype"
                    popupMatchSelectWidth={false}
                    mode="multiple"
                    showSearch
                    value={filters?.vessel_sub_type}
                    maxTagCount={0}
                    onChange={(value) =>
                      handleSelectChange(value, "vessel_sub_type")
                    }
                  >
                    {filters?.vessel_type[0] === "dry"
                      ? filterInputs?.vessel_sub_type_for_dry?.map((type) => (
                          <Select.Option key={type}>
                            {type?.toUpperCase()}
                          </Select.Option>
                        ))
                      : filterInputs?.vessel_sub_type_for_tanker?.map(
                          (type) => (
                            <Select.Option key={type}>
                              {type?.toUpperCase()}
                            </Select.Option>
                          )
                        )}
                  </Select>
                </td>
                <td>
                  <div style={{ display: "flex", gap: "7px" }}>
                    <Input
                      type="number"
                      bordered
                      placeholder="Min"
                      onChange={(e) => handleInputChange(e, "min")}
                      // onPressEnter={handleDWTEnter}
                      style={{ width: "80px" }}
                      value={wtMin}
                    />
                    <Input
                      bordered
                      type="number"
                      placeholder="Max"
                      onChange={(e) => handleInputChange(e, "max")}
                      onPressEnter={handleDWTEnter}
                      onBlur={handleDWTEnter}
                      style={{ width: "80px" }}
                      value={wtMax}
                    />
                  </div>
                </td>
                <td>
                  <RangePicker
                    // disabledDate={disabledDate}
                    // onChange={(dates) => handleSelectChange(dates, "open_date")}
                    value={null}
                    onChange={(event, dateString) =>
                      onChange(event, "open_date", dateString)
                    }
                  />
                </td>
                <td>
                  <Select
                    style={{ width: "150px" }}
                    popupMatchSelectWidth={false}
                    placeholder="Choose Open Area"
                    mode="multiple"
                    maxTagCount={0}
                    value={filters?.open_area}
                    showSearch
                    onChange={(value) => handleSelectChange(value, "open_area")}
                  >
                    {filterInputs?.unique_open_area_names?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <YearPicker
                    style={{ width: "150px" }}
                    picker="year"
                    onChange={(date) => {
                      date
                        ? handleSelectChange(
                            dayjs(date).format("YYYY"),
                            "build_year"
                          )
                        : setFilters((prev) => ({
                            ...prev,
                            build_year: [],
                          }));
                    }}
                    allowClear
                  />
                </td>
                <td>
                  <Select
                    style={{ width: "150px" }}
                    placeholder="Choose Sender"
                    mode="multiple"
                    maxTagCount={0}
                    value={filters?.Sender}
                    popupMatchSelectWidth={false}
                    onChange={(value) => handleSelectChange(value, "Sender")}
                  >
                    {filterInputs?.unique_emails?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: "150px" }}
                    placeholder="Choose Tags"
                    mode="multiple"
                    value={filters?.tags}
                    maxTagCount={0}
                    popupMatchSelectWidth={false}
                    onChange={(value) => handleSelectChange(value, "tags")}
                  >
                    {filterInputs?.unique_tags_emails?.map((type) => (
                      <Select.Option key={type}>
                        {type?.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
              </tr>

              <tr>
                <td className="filter-results text-center">
                  {multipleRangeChip(filters.date, "date")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.vessel_name, "vessel_name")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.vessel_type, "vessel_type")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.vessel_sub_type, "vessel_sub_type")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters?.dwt?.combined, "dwt")}
                </td>
                <td className="filter-results text-center">
                  {multipleRangeChip(filters.open_date, "open_date")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.open_area, "open_area")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.build_year, "build_year")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.Sender, "Sender")}
                </td>
                <td className="filter-results text-center">
                  {renderChip(filters.tags, "tags")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* MAIN TABLE */}

      <div className="tonnage-table-box">
        <Flex justify="space-between" align="center">
          <h1 style={{ fontSize: "22px", padding: "10px 16px" }}>
            Global Tonnage Order
          </h1>
          <Flex align="center" className="tonnage-table-upper-box">
            <Tooltip title="Email Assigned">
              <Popover
                trigger={["click", "hover"]}
                content={emailAssignedContent}
                placement="bottomRight"
              >
                <Button>
                  <Icon
                    icon="ph:users"
                    className="mail-icon-color"
                    fontSize={20}
                  />
                </Button>
              </Popover>
            </Tooltip>

            {IsFilter && (
              <>
                <Tooltip title="Reset Filter">
                  <Button onClick={() => handleResetFilter()}>
                    <Icon
                      icon="system-uicons:reset"
                      fontSize={20}
                      className="mail-icon-color"
                    />
                  </Button>
                </Tooltip>
                {/* 
                <Tooltip title="Save this Filter">
                  <Button
                  onClick={() => setIsFilterSave(true)}
                  >
                    <Icon
                      icon="prime:save"
                      className="mail-icon-color"
                      fontSize={20}
                    />
                  </Button>
                </Tooltip> */}
              </>
            )}

            <Tooltip title="Download List">
              <Button onClick={handleDownload}>
                <Icon
                  icon="system-uicons:download"
                  fontSize={20}
                  className="mail-icon-color"
                />
              </Button>
            </Tooltip>
            <Tooltip title="All Tonnage Analysis">
              <span
                className="analysis-button"
                onClick={() => {
                  setShowTonnageAnalysisModal(true);
                }}
              >
                <Icon
                  icon="carbon:text-link-analysis"
                  fontSize={16}
                  className="mail-icon-color"
                />
                Analysis
              </span>
            </Tooltip>
            <div>
              <span style={{ fontWeight: "600", paddingLeft: "10px" }}>
                {formatNumber(count) + " results"}
              </span>
            </div>
          </Flex>
        </Flex>
        <Table
          columns={Tonnagecolumns}
          dataSource={allData}
          scroll={{ x: "max-content" }}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            current: page,
            pageSize: pageSize, // Number of items per page
            showSizeChanger: true, // Allow users to change page size
            pageSizeOptions: ["10", "20", "30", "50", "100"], // Page size options
            total: count, // Total number of items
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </div>

      {/* VIEW IN FORM MODAL */}
      <Modal
        width={700}
        footer={false}
        open={isView}
        onCancel={() => setIsView(false)}
        centered
        title={viewDetail?.vessel_name?.toUpperCase() || "Tonnage Details"}
      >
        <TonnageViewForm viewDetail={viewDetail} />
      </Modal>

      {/* Tonnage Mail */}
      <Modal
        width={800}
        open={tonnageMailOpen}
        onCancel={() => setTonnageMailOpen(false)}
        centered
        footer={false}
      >
        <CurrentMail mail={tonnageMail} countShow={false} />
      </Modal>

      <Drawer
        open={isBestMatch}
        className="bestmatch-drawer"
        style={{
          height: "100vh",
          overflowY: "hidden",
          minWidth: "600px",
          maxWidth: "600px",
          marginLeft: "auto",
        }}
        onClose={() => setIsBestMatch(false)}
        title={
          <>
            <span style={{ paddingRight: "8px" }}>Best Match</span>
            <Icon icon={"ri:shake-hands-line"} />
          </>
        }
      >
        <BestMatchDrawerContent currentMatch={currentBestMatch} cargo={false} />
      </Drawer>

      <Spin fullscreen spinning={loadingNew} />

      {openVesselInfo && (
        <Drawer
          open={openVesselInfo}
          onClose={() => setOpenVesselInfo(false)}
          title={
            <>
              <span style={{ paddingRight: "8px" }}>Vessel Information</span>
              <Icon icon={"ri:ship"} />
            </>
          }
        >
          <NewVesselInfoDrawer marineData={vesselInfo} />
        </Drawer>
      )}

      <Modal
        open={isFilterSave}
        centered
        title="Save Filter with a label."
        okText="Save"
        onOk={() => handleSaveFilter()}
        onCancel={() => {
          setFilterName("");
          setIsFilterSave(false);
        }}
      >
        <Input onChange={(e) => onFiltername(e.target.value)} type="text" />
      </Modal>

      {showTonnageAnalysisModal && (
        <Modal
          // centered
          title="Global Tonnage Analysis"
          open={showTonnageAnalysisModal}
          closable={true}
          onCancel={() => setShowTonnageAnalysisModal(false)}
          footer={null}
          width={"85vw"}
          wrapClassName=""
          style={{ top: "20px" }}
        >
          <TonnageAnalysis type={"global"} />
        </Modal>
      )}
    </div>
  );
};

export default GlobalTonnageOrder;
